@use 'abstracts/variables';

@each $name, $colors in variables.$colors {
  @each $value in $colors {
    .color-#{'' + $name} {
      color: $value;
    }

    .background-#{'' + $name} {
      background-color: $value;
    }
  }
}
