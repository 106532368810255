@use 'abstracts/functions';
@use 'abstracts/variables';

// Background colors
$button-bg-color: functions.color('primary500');
$button-bg-color-hover: functions.color('primary400');
$button-bg-color-focus: functions.color('primary500');
$button-bg-color-active: functions.color('primary600');
$button-bg-color-disabled: functions.color('text200');

// Border colors
$button-border-color: transparent;
$button-border-color-hover: $button-bg-color-hover;
$button-border-color-focus: functions.color('primary700');
$button-border-color-active: $button-bg-color-active;
$button-border-color-disabled: $button-bg-color-disabled;

// Text colors
$button-color: functions.color('text100');
$button-color-hover: $button-color;
$button-color-focus: $button-color;
$button-color-active: $button-color;
$button-color-disabled: functions.color('text300');

.btn-primary {
  background-color: $button-bg-color;
  border-color: $button-border-color;
  color: $button-color;
  transition:
    background-color 0.15s ease,
    border 0.15s ease;

  &.btn-inverse {
    background-color: $button-color;
    border-color: $button-color;
    color: $button-bg-color;

    &:focus-visible {
      border-color: $button-bg-color;
    }
  }

  &.active,
  &:hover {
    background-color: $button-bg-color-hover;
    border-color: $button-border-color-hover;
    color: $button-color-hover;
  }

  &:focus-visible {
    background-color: $button-bg-color-focus;
    border-color: $button-border-color-focus;
    color: $button-color-focus;
  }

  &:active {
    background-color: $button-bg-color-active;
    border-color: $button-border-color-active;
    color: $button-color-active;
  }

  &:disabled,
  &.btn-disabled {
    background-color: $button-bg-color-disabled;
    border-color: $button-border-color-disabled;
    color: $button-color-disabled;
  }
}
