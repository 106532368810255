html,
body {
  height: 100%;
}

img,
video {
  display: block;
  max-inline-size: 100%;
  inline-size: 100%;
  block-size: auto;
  object-fit: contain;
}

svg {
  // Bootstrap Reboot sets `vertical-align: middle` which messes up SVG icons for us.
  vertical-align: initial;
}

svg-icon {
  /* stylelint-disable-next-line declaration-no-important */
  display: inline-flex !important;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-size: unset;
  font-weight: unset;
}

*,
*::before,
*::after {
  // We don't need this anywhere.
  background-repeat: no-repeat;
}
