@use 'abstracts/shared';

.offset-border {
  --offset-size: 1rem;

  position: relative;
  margin: 0 var(--offset-size) var(--offset-size) 0;
  z-index: 0;

  &::after {
    content: '';
    position: absolute;
    border: 1px solid shared.color('neutral500');
    border-radius: inherit;
    right: calc(var(--offset-size) * -1);
    bottom: calc(var(--offset-size) * -1);
    width: 100%;
    height: 100%;
    pointer-events: none;
  }

  &.offset-border-behind::after {
    z-index: -1;
  }

  &.offset-border-left {
    margin: 0 0 var(--offset-size) var(--offset-size);

    &::after {
      left: calc(var(--offset-size) * -1);
      bottom: calc(var(--offset-size) * -1);
    }
  }
}

.offset-border-lg {
  @include shared.media-breakpoint-up(md) {
    --offset-size: 1.5rem;
  }
}

.offset-border-sm {
  @include shared.media-breakpoint-down(sm) {
    --offset-size: 0.5rem;
  }
}
