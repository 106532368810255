@use 'abstracts/functions';
@use 'abstracts/variables';

@mixin shadow-normal {
  box-shadow: 0 7px 48px -8px rgb(8 13 37 / 11%);
}

@mixin form-input {
  background-color: variables.$input-bg-color;
  caret-color: variables.$input-color;
  color: variables.$input-color;
  outline: none;
  border: variables.$input-border-width solid variables.$input-border-color;
  width: 100%;
  border-radius: variables.$input-border-radius;

  &::placeholder {
    color: variables.$input-placeholder-color;
  }

  &:hover {
    border-color: variables.$input-border-color-hover;
  }

  &:focus {
    border-color: variables.$input-border-color-focus;
  }

  &:disabled {
    cursor: not-allowed;
    border-color: variables.$input-border-color-disabled;
    color: variables.$input-placeholder-color;
  }

  .success & {
    border-color: functions.color('success600');
  }

  .warning & {
    border-color: functions.color('warning600');
  }

  .error &,
  &.error {
    border-color: functions.color('error600');
    color: functions.color('error600');

    &::placeholder {
      color: functions.color('error400');
    }
  }

  .input-sm & {
    padding: variables.$input-sm-padding-y variables.$input-sm-padding-x;
  }

  .input-md & {
    padding: variables.$input-padding-y variables.$input-padding-x;
  }

  .input-lg & {
    padding: variables.$input-lg-padding-y variables.$input-lg-padding-x;
  }

  .input-has-prefix & {
    padding-left: variables.$input-padding-x + variables.$input-prefix-size + 0.5rem;
  }

  .input-has-suffix & {
    padding-right: variables.$input-padding-x + variables.$input-prefix-size + 0.5rem;
  }
}
