/* stylelint-disable scss/at-extend-no-missing-placeholder */
@use 'abstracts/shared';

.container {
  @include shared.make-container(shared.$container-padding-x * 2);

  max-width: shared.$content-max-width + shared.$container-padding-x * 2;
}

.layout-title {
  margin-top: shared.spacing(10);
  margin-bottom: shared.spacing(4);
  font-weight: shared.$font-weight-bold;

  // TODO: Create placeholder instead.
  @extend .header-h6;
  @extend .header-h5-md;
  @extend .header-h4-lg;

  @include shared.media-breakpoint-up(md) {
    width: 75%;
  }
}

.layout-text {
  max-width: 787px;

  h2 {
    // TODO: Create placeholder instead.
    @extend .body-large-bold;
  }

  :is(h1, h2, h3, h4, h5, h6) {
    color: shared.color('text500');
  }
}

.layout-bg {
  position: absolute;
  pointer-events: none;
  height: 100%;
  z-index: -1;
  inset: 0 0 auto;
}

.layout-bg-full-width {
  width: calc(100vw - 4px);
  left: 50%;
  right: 50%;
  margin-left: -50vw;
  margin-right: -50vw;
}

.layout-top-bg-large {
  // cspell:disable-next-line
  background-image: url("data:image/svg+xml,%3Csvg fill='none' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 1440 956'%3E %3Cpath d='M436.756 713.442-321 28.782 436.459-656.18c363.465-328.678 921.961-323.024 1278.561 12.943 388.09 365.641 387.96 978.264-.29 1343.745-356.51 335.613-914.595 341.263-1277.974 12.935Z' fill='%23FFF6ED'/%3E %3C/svg%3E");
  background-position: center top;
  background-size: auto 956px;

  @include shared.media-breakpoint-up(lg) {
    background-size: 100% 956px;
  }
}

.layout-top-bg-small {
  // cspell:disable-next-line
  background-image: url("data:image/svg+xml,%3Csvg fill='none' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 883 674'%3E %3Cpath d='M98.715 552.786-280 210.641 98.567-131.656c181.654-164.25 460.782-161.425 639.003 6.468 193.962 182.722 193.898 488.868-.141 671.51-178.182 167.716-457.102 170.539-638.714 6.464Z' fill='%23FFF1E3'/%3E %3C/svg%3E");
  background-position: left top;
  background-size: max(684px, 80%);

  &.layout-bg-light {
    background-image: url("data:image/svg+xml,%3Csvg fill='none' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 883 674'%3E %3Cpath d='M98.715 552.786-280 210.641 98.567-131.656c181.654-164.25 460.782-161.425 639.003 6.468 193.962 182.722 193.898 488.868-.141 671.51-178.182 167.716-457.102 170.539-638.714 6.464Z' fill='%23FFF6ED'/%3E %3C/svg%3E");
  }

  @include shared.media-breakpoint-up(lg) {
    background-position: -235px -151px;
    background-size: 100% 833px;
  }
}

.layout-bottom-bg {
  // cspell:disable-next-line
  background-image: url("data:image/svg+xml,%3Csvg fill='none' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 1440 1023'%3E %3Cpath d='m1355.83 1425.75 603.99-759.213-816.15-524.472C752.042-109.602 229.485-17.675-52.611 352.512-359.623 755.395-264.479 1329.54 156.082 1611.85c386.191 259.25 910.108 177.98 1199.748-186.1Z' fill='%23FFF1E3'/%3E %3C/svg%3E");
  background-size: cover;
  background-position: center top;
  bottom: 0;
}

.layout-bottom-bg-2 {
  // cspell:disable-next-line
  background-image: url("data:image/svg+xml,%3Csvg width='1440' height='1145' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='m964.729 1340.16 567.801-713.722L765.277 133.39C397.113-103.198-94.135-16.778-359.328 331.228-647.946 709.973-558.503 1249.71-163.14 1515.11c363.053 243.72 855.583 167.31 1127.869-174.95Z' fill='%23FFF6ED'/%3E%3C/svg%3E");
  background-size: cover;
  background-position: center top;
  bottom: 0;
}

.layout-bottom-bg-3 {
  // cspell:disable-next-line
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='805' height='704' fill='none'%3E%3Cpath fill='%23FFF6ED' d='m1154.83 1035.95 438.67-551.413-592.77-380.92C716.298-79.1659 336.769-12.3994 131.885 256.464-91.0952 549.074-21.9933 966.069 283.457 1171.11c280.487 188.29 661.007 129.26 871.373-135.16Z'/%3E%3C/svg%3E");
  background-position: right bottom;
  background-size: 704px 850px;
  bottom: 0;
}

.layout-bg-two-blobs {
  // cspell:disable-next-line
  background-image: url("data:image/svg+xml,%3Csvg width='1187' height='857' fill='none' xmlns='http://www.w3.org/2000/svg'%3E %3Cpath d='M721 477 497 274 721 72c108-97 273-96 379 4a272 272 0 0 1 0 397 280 280 0 0 1-379 4Z' fill='%23FFF6ED'/%3E %3Cpath d='M330 751 0 453l330-299a412 412 0 0 1 558 6 402 402 0 0 1 0 586 412 412 0 0 1-558 5Z' fill='%23FFE5CC'/%3E %3C/svg%3E");
  background-position: center top;
  background-size: contain;
  left: -500px;
  top: -50px;

  @include shared.media-breakpoint-up(lg) {
    left: -1200px;
  }
}

.inner-html-paragraph {
  p {
    margin-bottom: 0;
  }

  ::ng-deep p {
    margin-bottom: 0;
  }
}

.whitespace-break-spaces {
  white-space: break-spaces;
}
