@use 'abstracts/functions';

// Background colors
$button-tertiary-bg-color: transparent;
$button-tertiary-bg-color-hover: $button-tertiary-bg-color;
$button-tertiary-bg-color-focus: $button-tertiary-bg-color;
$button-tertiary-bg-color-active: $button-tertiary-bg-color;
$button-tertiary-bg-color-disabled: functions.color('text200');

// Border colors
$button-tertiary-border-color: transparent;
$button-tertiary-border-color-hover: transparent;
$button-tertiary-border-color-focus: functions.color('primary500');
$button-tertiary-border-color-active: transparent;
$button-tertiary-border-color-disabled: transparent;

// Text colors
$button-tertiary-color: functions.color('primary500');
$button-tertiary-color-hover: functions.color('primary600');
$button-tertiary-color-focus: $button-tertiary-color;
$button-tertiary-color-active: functions.color('primary700');
$button-tertiary-color-disabled: functions.color('text300');

.btn-tertiary {
  background-color: $button-tertiary-bg-color;
  border-color: $button-tertiary-border-color;
  color: $button-tertiary-color;

  &:hover {
    background-color: $button-tertiary-bg-color-hover;
    border-color: $button-tertiary-border-color-hover;
    color: $button-tertiary-color-hover;
  }

  &:focus-visible {
    background-color: $button-tertiary-bg-color-focus;
    border-color: $button-tertiary-border-color-focus;
    color: $button-tertiary-color-focus;
  }

  &:active {
    background-color: $button-tertiary-bg-color-active;
    border-color: $button-tertiary-border-color-active;
    color: $button-tertiary-color-active;
  }

  &:disabled,
  &.btn-disabled {
    background-color: $button-tertiary-bg-color-disabled;
    border-color: $button-tertiary-border-color-disabled;
    color: $button-tertiary-color-disabled;
  }
}
