@use 'abstracts/variables';

html {
  // Styles for Firefox.
  scrollbar-color: variables.$scrollbar-thumb-color variables.$scrollbar-track-color;
  scrollbar-width: variables.$scrollbar-width;
}

// Styles for Webkit browsers.
::-webkit-scrollbar {
  width: variables.$scrollbar-width;
  height: variables.$scrollbar-width;
}

::-webkit-scrollbar-thumb {
  background-color: variables.$scrollbar-thumb-color;
  border-radius: variables.$scrollbar-radius;
}

::-webkit-scrollbar,
::-webkit-scrollbar-track,
::-webkit-scrollbar-track-piece {
  background-color: variables.$scrollbar-track-color;
  border-radius: variables.$scrollbar-radius;
}

::-webkit-scrollbar-corner {
  background: 0;
}

::-webkit-scrollbar-button {
  display: none;
}

::-webkit-scrollbar-thumb:hover {
  background-color: variables.$scrollbar-thumb-hover-color;
}

::-webkit-scrollbar:hover {
  background-color: variables.$scrollbar-track-hover-color;
}

body::-webkit-scrollbar-track-piece {
  border-radius: 0;
}
