/* stylelint-disable max-nesting-depth */
@use 'abstracts/variables';
@use 'abstracts/functions';
@use 'abstracts/bootstrap-variables';
@use 'sass:map';

// Loop over each breakpoint
@each $breakpoint in map-keys($grid-breakpoints) {
  // Generate media query if needed
  @include media-breakpoint-up($breakpoint) {
    $infix: breakpoint-infix($breakpoint, $grid-breakpoints);

    // Loop over each utility property
    @each $key, $utility in $utilities {
      // The utility can be disabled with `false`, thus check if the utility is a map first
      // Only proceed if responsive media queries are enabled or if it's the base media query
      @if type-of($utility) == 'map' and (map.get($utility, responsive) or $infix == '') {
        @include generate-utility($utility, $infix);
      }
    }
  }
}

@each $breakpoint in map-keys(bootstrap-variables.$grid-breakpoints) {
  $class-appendix: functions.get-responsive-appendix($breakpoint);

  @each $font in variables.$font-config {
    @each $font-weight in variables.$font-weights {
      $font-name: map.get($font, 'name');
      $font-weight-name: map.get($font-weight, 'name');
      $default-font-weight-name: map.get($font, 'default-font-weight');

      .#{$font-name}-#{$font-weight-name}#{$class-appendix} {
        @include media-breakpoint-up($breakpoint) {
          font-size: map.get($font, 'font-size');
          font-weight: map.get($font-weight, 'font-weight');
          line-height: map.get($font, 'line-height');
        }
      }

      // generate italic fonts
      .#{$font-name}-#{$font-weight-name}-italic#{$class-appendix} {
        @include media-breakpoint-up($breakpoint) {
          font-size: map.get($font, 'font-size');
          font-style: italic;
          font-weight: map.get($font-weight, 'font-weight');
          line-height: map.get($font, 'line-height');
        }
      }

      // generate font name with the default font-weight so it doesn't postfix
      @if $font-weight-name == $default-font-weight-name {
        .#{$font-name}#{$class-appendix} {
          @include media-breakpoint-up($breakpoint) {
            font-size: map.get($font, 'font-size');
            font-weight: map.get($font-weight, 'font-weight');
            line-height: map.get($font, 'line-height');
          }
        }
      }

      // italic - generate font name with the default font-weight so it doesn't postfix
      @if $font-weight-name == $default-font-weight-name {
        .#{$font-name}-italic#{$class-appendix} {
          @include media-breakpoint-up($breakpoint) {
            font-size: map.get($font, 'font-size');
            font-style: italic;
            font-weight: map.get($font-weight, 'font-weight');
            line-height: map.get($font, 'line-height');
          }
        }
      }
    }
  }
}

// Font weight utility classes.
// TODO: Use Bootstrap instead.
@each $font-weight in variables.$font-weights {
  $font-weight-name: map.get($font-weight, 'name');

  .font-weight-#{$font-weight-name} {
    font-weight: map.get($font-weight, 'font-weight');
  }

  @each $breakpoint in map-keys(bootstrap-variables.$grid-breakpoints) {
    @if $font-weight-name != xs {
      @include media-breakpoint-up($breakpoint) {
        .font-weight-#{$breakpoint}-#{$font-weight-name} {
          font-weight: map.get($font-weight, 'font-weight');
        }
      }
    }
  }
}
