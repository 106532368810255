@use 'abstracts/functions';

.btn-scroll {
  width: 48px;
  height: 72px;

  &.btn-secondary {
    background-color: transparent;

    &:hover {
      border-color: functions.color('primary600');
      color: functions.color('primary600');
    }

    &:active {
      border-color: functions.color('primary700');
      color: functions.color('primary700');
    }

    &:focus-visible {
      background-color: functions.color('neutral200');
      border-color: functions.color('primary500');
      color: functions.color('primary500');
    }

    &:disabled,
    &.btn-disabled {
      background-color: functions.color('bg100');
      border-color: functions.color('text300');
      color: functions.color('text300');
    }
  }
}
