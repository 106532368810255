@use 'abstracts/shared';

.divider {
  display: flex;
  flex-direction: row;
  align-items: center;

  &::before {
    content: '';
    align-self: stretch;
    border: 1px solid shared.color('text200');
  }
}
