@use 'sass:map';

$base-spacing: 0.5rem;
$colors: (
  // Primary
  primary100: #f2f4ff,
  primary200: #e7eaff,
  primary300: #b3bdf9,
  primary400: #6a7ffc,
  primary500: #556cf2,
  primary600: #4359d9,
  primary700: #293a9c,
  // Background
  bg100: #fff,
  bg200: #fffbf7,
  bg300: #fff6ed,
  bg400: #fff1e3,
  bg500: #ffe5cc,
  bg600: #fddbbb,
  bg700: #ffc195,
  // Neutral
  neutral100: #f3f7fd,
  neutral200: #e8f0fd,
  neutral250: #cdddf7,
  neutral300: #b5cbee,
  neutral400: #6289ca,
  neutral500: #42649b,
  neutral600: #35517d,
  neutral700: #293f61,
  neutral800: #1d2c44,
  neutral900: #111927,
  // Text
  text100: #fff,
  text200: #e8eaed,
  text300: #778192,
  text400: #45546e,
  text500: #0e2852,
  // States
  success300: #adf3c1,
  success400: #98eaae,
  success500: #74d68f,
  success600: #67c180,
  warning300: #faddb9,
  warning400: #facb91,
  warning500: #faba6b,
  warning600: #f0a64c,
  error300: #f6dfdd,
  error400: #f6bab6,
  error500: #f67971,
  error600: #ec5a51
);

// Font weights.
$font-weight-thin: 100;
$font-weight-extralight: 200;
$font-weight-light: 300;
$font-weight-regular: 400;
$font-weight-medium: 500;
$font-weight-semibold: 600;
$font-weight-bold: 700;
$font-weight-extrabold: 800;
$font-weight-black: 900;
$font-weights: (
  (
    name: 'thin',
    font-weight: $font-weight-thin
  ),
  (
    name: 'extra-light',
    font-weight: $font-weight-extralight
  ),
  (
    name: 'light',
    font-weight: $font-weight-light
  ),
  (
    name: 'regular',
    font-weight: $font-weight-regular
  ),
  (
    name: 'medium',
    font-weight: $font-weight-medium
  ),
  (
    name: 'semibold',
    font-weight: $font-weight-semibold
  ),
  (
    name: 'bold',
    font-weight: $font-weight-bold
  ),
  (
    name: 'extra-bold',
    font-weight: $font-weight-extrabold
  ),
  (
    name: 'black',
    font-weight: $font-weight-black
  )
);

// Font variants.
$font-config: (
  (
    name: 'header-h1',
    font-size: 4.75rem,
    line-height: 5rem,
    default-font-weight: 'bold'
  ),
  (
    name: 'header-h2',
    font-size: 3.813rem,
    line-height: 4.188rem,
    default-font-weight: 'bold'
  ),
  (
    name: 'header-h3',
    font-size: 3.063rem,
    line-height: 3.438rem,
    default-font-weight: 'bold'
  ),
  (
    name: 'header-h4',
    font-size: 2.438rem,
    line-height: 2.875rem,
    default-font-weight: 'bold'
  ),
  (
    name: 'header-h5',
    font-size: 1.938rem,
    line-height: 2rem,
    default-font-weight: 'bold'
  ),
  (
    name: 'header-h6',
    font-size: 1.563rem,
    line-height: 2rem,
    default-font-weight: 'bold'
  ),
  (
    name: 'body-large',
    font-size: 1.25rem,
    line-height: 1.75rem,
    default-font-weight: 'regular'
  ),
  (
    name: 'body-normal',
    font-size: 1rem,
    line-height: 1.5rem,
    default-font-weight: 'regular'
  ),
  (
    name: 'body-small',
    font-size: 0.875rem,
    line-height: 1.313rem,
    default-font-weight: 'regular'
  ),
  (
    name: 'body-tiny',
    font-size: 0.75rem,
    line-height: 1rem,
    default-font-weight: 'regular'
  ),
  (
    name: 'body-xs',
    font-size: 0.625rem,
    line-height: 1.313rem,
    default-font-weight: 'regular'
  )
);

// Form inputs
$input-border-width: 2px;
$input-border-color: map.get($colors, 'primary300');
$input-border-color-hover: map.get($colors, 'primary400');
$input-border-color-focus: map.get($colors, 'primary600');
$input-border-color-disabled: map.get($colors, 'text200');
$input-color: map.get($colors, 'text500');
$input-placeholder-color: map.get($colors, 'text300');
$input-hint-color: map.get($colors, 'text300');
$input-bg-color: map.get($colors, 'bg100');
$input-sm-padding-x: 1.5rem;
$input-sm-padding-y: 8px - $input-border-width;
$input-padding-x: 1.5rem;
$input-padding-y: 12px - $input-border-width;
$input-lg-padding-x: 1.5rem;
$input-lg-padding-y: 16px - $input-border-width;
$input-prefix-size: 1.5rem;
$input-border-radius: 60px;

// Header
$header-height-mobile: 64px;
$header-height: 88px;

// Footer
$footer-margin-top: 80px;

// Scrollbar
$scrollbar-width: 11px;
$scrollbar-thumb-color: map.get($colors, 'primary600');
$scrollbar-thumb-hover-color: map.get($colors, 'primary700');
$scrollbar-track-color: map.get($colors, 'primary300');
$scrollbar-track-hover-color: $scrollbar-track-color;
$scrollbar-radius: $scrollbar-width;

// Page width
$content-max-width: 1200px;
