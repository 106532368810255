@use 'abstracts/shared';

.shadow-normal {
  @include shared.shadow-normal;
}

.shadow-sm-normal {
  @include shared.media-breakpoint-up(sm) {
    @include shared.shadow-normal;
  }
}

.shadow-orange {
  // This line uses SCSS's rgba function.
  // stylelint-disable-next-line color-function-notation
  box-shadow: 0 40px 32px -32px rgba(shared.color('bg700'), 0.6);
}
