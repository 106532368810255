.btn-icon {
  &.btn-extra-small {
    width: 32px;
    height: 32px;
  }

  &.btn-small {
    width: 40px;
    height: 40px;
  }

  &.btn-normal {
    width: 48px;
    height: 48px;
  }

  &.btn-large {
    width: 56px;
    height: 56px;
  }
}
