/* stylelint-disable plugin/no-unsupported-browser-features */
/* stylelint-disable no-invalid-position-at-import-rule */
@use 'abstracts/variables';

// Sizes
$button-border-width: 2px;
$button-border-radius: 60px;
$button-xs-padding-x: 48px;
$button-xs-padding-y: 4px - $button-border-width;
$button-md-padding-x: 48px;
$button-md-padding-y: 8px - $button-border-width;
$button-padding-x: 48px;
$button-padding-y: 12px - $button-border-width;
$button-lg-padding-x: 48px;
$button-lg-padding-y: 16px - $button-border-width;
$button-icon-size: 24px;

.btn {
  border-width: $button-border-width;
  border-style: solid;
  outline: none;
  border-radius: $button-border-radius;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
  font-weight: variables.$font-weight-bold;
  line-height: $button-icon-size;
  user-select: none;
  padding: 0;
  white-space: nowrap;
  transition:
    background-color 0.15s ease,
    border 0.15s ease;
  appearance: none;
  /* stylelint-disable-next-line property-no-vendor-prefix */
  -webkit-appearance: none;
  opacity: 1;

  &:disabled,
  &.btn-disabled {
    cursor: not-allowed;
  }

  svg-icon {
    flex-shrink: 0;
  }
}

a.btn {
  text-decoration: none;

  &.btn-disabled {
    pointer-events: none;
  }
}

.btn-unstyled {
  background: 0;
  border: 0;
  padding: 0;
  font: inherit;
  color: inherit;
}

// Extra styles should come after .btn for specificity.
@import 'primary';
@import 'secondary';
@import 'tertiary';
@import 'text';
@import 'icon';
@import 'scroll';
@import 'nav';
@import 'fab';
