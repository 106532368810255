@charset "UTF-8";
:root {
  --bs-primary100: #f2f4ff;
  --bs-primary200: #e7eaff;
  --bs-primary300: #b3bdf9;
  --bs-primary400: #6a7ffc;
  --bs-primary500: #556cf2;
  --bs-primary600: #4359d9;
  --bs-primary700: #293a9c;
  --bs-bg100: #fff;
  --bs-bg200: #fffbf7;
  --bs-bg300: #fff6ed;
  --bs-bg400: #fff1e3;
  --bs-bg500: #ffe5cc;
  --bs-bg600: #fddbbb;
  --bs-bg700: #ffc195;
  --bs-neutral100: #f3f7fd;
  --bs-neutral200: #e8f0fd;
  --bs-neutral250: #cdddf7;
  --bs-neutral300: #b5cbee;
  --bs-neutral400: #6289ca;
  --bs-neutral500: #42649b;
  --bs-neutral600: #35517d;
  --bs-neutral700: #293f61;
  --bs-neutral800: #1d2c44;
  --bs-neutral900: #111927;
  --bs-text100: #fff;
  --bs-text200: #e8eaed;
  --bs-text300: #778192;
  --bs-text400: #45546e;
  --bs-text500: #0e2852;
  --bs-success300: #adf3c1;
  --bs-success400: #98eaae;
  --bs-success500: #74d68f;
  --bs-success600: #67c180;
  --bs-warning300: #faddb9;
  --bs-warning400: #facb91;
  --bs-warning500: #faba6b;
  --bs-warning600: #f0a64c;
  --bs-error300: #f6dfdd;
  --bs-error400: #f6bab6;
  --bs-error500: #f67971;
  --bs-error600: #ec5a51;
  --bs-gray-100: #f8f9fa;
  --bs-gray-200: #e9ecef;
  --bs-gray-300: #dee2e6;
  --bs-gray-400: #ced4da;
  --bs-gray-500: #adb5bd;
  --bs-gray-600: #6c757d;
  --bs-gray-700: #495057;
  --bs-gray-800: #343a40;
  --bs-gray-900: #212529;
  --bs-primary: #0d6efd;
  --bs-secondary: #6c757d;
  --bs-success: #198754;
  --bs-info: #0dcaf0;
  --bs-warning: #ffc107;
  --bs-danger: #dc3545;
  --bs-light: #f8f9fa;
  --bs-dark: #212529;
  --bs-primary-rgb: 13, 110, 253;
  --bs-secondary-rgb: 108, 117, 125;
  --bs-success-rgb: 25, 135, 84;
  --bs-info-rgb: 13, 202, 240;
  --bs-warning-rgb: 255, 193, 7;
  --bs-danger-rgb: 220, 53, 69;
  --bs-light-rgb: 248, 249, 250;
  --bs-dark-rgb: 33, 37, 41;
  --bs-white-rgb: 255, 255, 255;
  --bs-black-rgb: 0, 0, 0;
  --bs-body-color-rgb: 14, 40, 82;
  --bs-body-bg-rgb: 255, 255, 255;
  --bs-font-sans-serif: system-ui, -apple-system, "Segoe UI", Roboto, "Helvetica Neue", "Noto Sans", "Liberation Sans", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  --bs-font-monospace: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
  --bs-gradient: linear-gradient(180deg, rgba(255, 255, 255, 0.15), rgba(255, 255, 255, 0));
  --bs-root-font-size: 16px;
  --bs-body-font-family: Poppins, sans-serif;
  --bs-body-font-size: 1rem;
  --bs-body-font-weight: 400;
  --bs-body-line-height: 1.5;
  --bs-body-color: #0e2852;
  --bs-body-bg: #fff;
  --bs-border-width: 1px;
  --bs-border-style: solid;
  --bs-border-color: #dee2e6;
  --bs-border-color-translucent: rgba(0, 0, 0, 0.175);
  --bs-border-radius: 1rem;
  --bs-border-radius-sm: 0.5rem;
  --bs-border-radius-lg: 1.5rem;
  --bs-border-radius-xl: 2rem;
  --bs-border-radius-2xl: 3rem;
  --bs-border-radius-pill: 50rem;
  --bs-link-color: #556cf2;
  --bs-link-hover-color: #4456c2;
  --bs-code-color: #0e2852;
  --bs-highlight-bg: #fff3cd;
}

*,
*::before,
*::after {
  box-sizing: border-box;
}

:root {
  font-size: var(--bs-root-font-size);
}

body {
  margin: 0;
  font-family: var(--bs-body-font-family);
  font-size: var(--bs-body-font-size);
  font-weight: var(--bs-body-font-weight);
  line-height: var(--bs-body-line-height);
  color: var(--bs-body-color);
  text-align: var(--bs-body-text-align);
  background-color: var(--bs-body-bg);
  -webkit-text-size-adjust: 100%;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

hr {
  margin: 1rem 0;
  color: inherit;
  border: 0;
  border-top: 1px solid;
  opacity: 1;
}

h6, h5, h4, h3, h2, h1 {
  margin-top: 0;
  margin-bottom: 0.5rem;
  font-weight: 500;
  line-height: 1.2;
}

h1 {
  font-size: calc(1.375rem + 1.5vw);
}
@media (min-width: 1200px) {
  h1 {
    font-size: 2.5rem;
  }
}

h2 {
  font-size: calc(1.325rem + 0.9vw);
}
@media (min-width: 1200px) {
  h2 {
    font-size: 2rem;
  }
}

h3 {
  font-size: calc(1.3rem + 0.6vw);
}
@media (min-width: 1200px) {
  h3 {
    font-size: 1.75rem;
  }
}

h4 {
  font-size: calc(1.275rem + 0.3vw);
}
@media (min-width: 1200px) {
  h4 {
    font-size: 1.5rem;
  }
}

h5 {
  font-size: 1.25rem;
}

h6 {
  font-size: 1rem;
}

p {
  margin-top: 0;
  margin-bottom: 1rem;
}

abbr[title] {
  text-decoration: underline dotted;
  cursor: help;
  text-decoration-skip-ink: none;
}

address {
  margin-bottom: 1rem;
  font-style: normal;
  line-height: inherit;
}

ol,
ul {
  padding-left: 2rem;
}

ol,
ul,
dl {
  margin-top: 0;
  margin-bottom: 1rem;
}

ol ol,
ul ul,
ol ul,
ul ol {
  margin-bottom: 0;
}

dt {
  font-weight: 700;
}

dd {
  margin-bottom: 0.5rem;
  margin-left: 0;
}

blockquote {
  margin: 0 0 1rem;
}

b,
strong {
  font-weight: bolder;
}

small {
  font-size: 0.875em;
}

mark {
  padding: 0.1875em;
  background-color: var(--bs-highlight-bg);
}

sub,
sup {
  position: relative;
  font-size: 0.75em;
  line-height: 0;
  vertical-align: baseline;
}

sub {
  bottom: -0.25em;
}

sup {
  top: -0.5em;
}

a {
  color: var(--bs-link-color);
  text-decoration: none;
}
a:hover {
  color: var(--bs-link-hover-color);
}

a:not([href]):not([class]), a:not([href]):not([class]):hover {
  color: inherit;
  text-decoration: none;
}

pre,
code,
kbd,
samp {
  font-family: var(--bs-font-monospace);
  font-size: 1em;
}

pre {
  display: block;
  margin-top: 0;
  margin-bottom: 1rem;
  overflow: auto;
  font-size: 0.875em;
}
pre code {
  font-size: inherit;
  color: inherit;
  word-break: normal;
}

code {
  font-size: 0.875em;
  color: var(--bs-code-color);
  word-wrap: break-word;
}
a > code {
  color: inherit;
}

kbd {
  padding: 0.1875rem 0.375rem;
  font-size: 0.875em;
  color: var(--bs-body-bg);
  background-color: var(--bs-body-color);
  border-radius: 0.5rem;
}
kbd kbd {
  padding: 0;
  font-size: 1em;
}

figure {
  margin: 0 0 1rem;
}

img,
svg {
  vertical-align: middle;
}

table {
  caption-side: bottom;
  border-collapse: collapse;
}

caption {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  color: #6c757d;
  text-align: left;
}

th {
  text-align: inherit;
  text-align: -webkit-match-parent;
}

thead,
tbody,
tfoot,
tr,
td,
th {
  border-color: inherit;
  border-style: solid;
  border-width: 0;
}

label {
  display: inline-block;
}

button {
  border-radius: 0;
}

button:focus:not(:focus-visible) {
  outline: 0;
}

input,
button,
select,
optgroup,
textarea {
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
}

button,
select {
  text-transform: none;
}

[role=button] {
  cursor: pointer;
}

select {
  word-wrap: normal;
}
select:disabled {
  opacity: 1;
}

[list]:not([type=date]):not([type=datetime-local]):not([type=month]):not([type=week]):not([type=time])::-webkit-calendar-picker-indicator {
  display: none !important;
}

button,
[type=button],
[type=reset],
[type=submit] {
  -webkit-appearance: button;
}
button:not(:disabled),
[type=button]:not(:disabled),
[type=reset]:not(:disabled),
[type=submit]:not(:disabled) {
  cursor: pointer;
}

::-moz-focus-inner {
  padding: 0;
  border-style: none;
}

textarea {
  resize: vertical;
}

fieldset {
  min-width: 0;
  padding: 0;
  margin: 0;
  border: 0;
}

legend {
  float: left;
  width: 100%;
  padding: 0;
  margin-bottom: 0.5rem;
  font-size: calc(1.275rem + 0.3vw);
  line-height: inherit;
}
@media (min-width: 1200px) {
  legend {
    font-size: 1.5rem;
  }
}
legend + * {
  clear: left;
}

::-webkit-datetime-edit-fields-wrapper,
::-webkit-datetime-edit-text,
::-webkit-datetime-edit-minute,
::-webkit-datetime-edit-hour-field,
::-webkit-datetime-edit-day-field,
::-webkit-datetime-edit-month-field,
::-webkit-datetime-edit-year-field {
  padding: 0;
}

::-webkit-inner-spin-button {
  height: auto;
}

[type=search] {
  outline-offset: -2px;
  -webkit-appearance: textfield;
}

/* rtl:raw:
[type="tel"],
[type="url"],
[type="email"],
[type="number"] {
  direction: ltr;
}
*/
::-webkit-search-decoration {
  -webkit-appearance: none;
}

::-webkit-color-swatch-wrapper {
  padding: 0;
}

::file-selector-button {
  font: inherit;
  -webkit-appearance: button;
}

output {
  display: inline-block;
}

iframe {
  border: 0;
}

summary {
  display: list-item;
  cursor: pointer;
}

progress {
  vertical-align: baseline;
}

[hidden] {
  display: none !important;
}

html,
body {
  height: 100%;
}

img,
video {
  display: block;
  max-inline-size: 100%;
  inline-size: 100%;
  block-size: auto;
  object-fit: contain;
}

svg {
  vertical-align: initial;
}

svg-icon {
  /* stylelint-disable-next-line declaration-no-important */
  display: inline-flex !important;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-size: unset;
  font-weight: unset;
}

*,
*::before,
*::after {
  background-repeat: no-repeat;
}

@font-face {
  font-family: "Poppins";
  src: local("Poppins Thin"), local("Poppins-Thin"), url("/assets/fonts/Poppins-Thin.woff2") format("woff2");
  font-weight: 100;
  font-display: swap;
}
@font-face {
  font-family: "Poppins";
  src: local("Poppins ExtraLight"), local("Poppins-ExtraLight"), url("/assets/fonts/Poppins-ExtraLight.woff2") format("woff2");
  font-weight: 200;
  font-display: swap;
}
@font-face {
  font-family: "Poppins";
  src: local("Poppins Light"), local("Poppins-Light"), url("/assets/fonts/Poppins-Light.woff2") format("woff2");
  font-weight: 300;
  font-display: swap;
}
@font-face {
  font-family: "Poppins";
  src: local("Poppins Regular"), local("Poppins-Regular"), url("/assets/fonts/Poppins-Regular.woff2") format("woff2");
  font-weight: 400;
  font-display: swap;
}
@font-face {
  font-family: "Poppins";
  src: local("Poppins Medium"), local("Poppins-Medium"), url("/assets/fonts/Poppins-Medium.woff2") format("woff2");
  font-weight: 500;
  font-display: swap;
}
@font-face {
  font-family: "Poppins";
  src: local("Poppins Bold"), local("Poppins-Bold"), url("/assets/fonts/Poppins-Bold.woff2") format("woff2");
  font-weight: 700;
  font-display: swap;
}
@font-face {
  font-family: "Poppins";
  src: local("Poppins ExtraBold"), local("Poppins-ExtraBold"), url("/assets/fonts/Poppins-ExtraBold.woff2") format("woff2");
  font-weight: 800;
  font-display: swap;
}
@font-face {
  font-family: "Poppins";
  src: local("Poppins Black"), local("Poppins-Black"), url("/assets/fonts/Poppins-Black.woff2") format("woff2");
  font-weight: 900;
  font-display: swap;
}
@font-face {
  font-family: "Poppins";
  src: local("Poppins Thin Italic"), local("Poppins-ThinItalic"), url("/assets/fonts/Poppins-ThinItalic.woff2") format("woff2");
  font-weight: 100;
  font-style: italic;
  font-display: swap;
}
@font-face {
  font-family: "Poppins";
  src: local("Poppins ExtraLight Italic"), local("Poppins-ExtraLightItalic"), url("/assets/fonts/Poppins-ExtraLightItalic.woff2") format("woff2");
  font-weight: 200;
  font-style: italic;
  font-display: swap;
}
@font-face {
  font-family: "Poppins";
  src: local("Poppins Light Italic"), local("Poppins-LightItalic"), url("/assets/fonts/Poppins-LightItalic.woff2") format("woff2");
  font-weight: 300;
  font-style: italic;
  font-display: swap;
}
@font-face {
  font-family: "Poppins";
  src: local("Poppins Italic"), local("Poppins-Italic"), url("/assets/fonts/Poppins-Italic.woff2") format("woff2");
  font-weight: 400;
  font-style: italic;
  font-display: swap;
}
@font-face {
  font-family: "Poppins";
  src: local("Poppins Medium Italic"), local("Poppins-MediumItalic"), url("/assets/fonts/Poppins-MediumItalic.woff2") format("woff2");
  font-weight: 500;
  font-style: italic;
  font-display: swap;
}
@font-face {
  font-family: "Poppins";
  src: local("Poppins Bold Italic"), local("Poppins-BoldItalic"), url("/assets/fonts/Poppins-BoldItalic.woff2") format("woff2");
  font-weight: 700;
  font-style: italic;
  font-display: swap;
}
@font-face {
  font-family: "Poppins";
  src: local("Poppins ExtraBold Italic"), local("Poppins-ExtraBoldItalic"), url("/assets/fonts/Poppins-ExtraBoldItalic.woff2") format("woff2");
  font-weight: 800;
  font-style: italic;
  font-display: swap;
}
@font-face {
  font-family: "Poppins";
  src: local("Poppins Black Italic"), local("Poppins-BlackItalic"), url("/assets/fonts/Poppins-BlackItalic.woff2") format("woff2");
  font-weight: 900;
  font-style: italic;
  font-display: swap;
}
.cdk-overlay-container, .cdk-global-overlay-wrapper {
  pointer-events: none;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
}

.cdk-overlay-container {
  position: fixed;
  z-index: 1055;
}
.cdk-overlay-container:empty {
  display: none;
}

.cdk-global-overlay-wrapper {
  display: flex;
  position: absolute;
  z-index: 1055;
}

.cdk-overlay-pane {
  position: absolute;
  pointer-events: auto;
  box-sizing: border-box;
  z-index: 1055;
  display: flex;
  max-width: 100%;
  max-height: 100%;
}

.cdk-overlay-backdrop {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 1050;
  pointer-events: auto;
  -webkit-tap-highlight-color: transparent;
  transition: opacity 400ms cubic-bezier(0.25, 0.8, 0.25, 1);
  opacity: 0;
}
.cdk-overlay-backdrop.cdk-overlay-backdrop-showing {
  opacity: 1;
}
.cdk-high-contrast-active .cdk-overlay-backdrop.cdk-overlay-backdrop-showing {
  opacity: 0.6;
}

.cdk-overlay-dark-backdrop {
  background: rgba(0, 0, 0, 0.32);
}

.cdk-overlay-transparent-backdrop {
  transition: visibility 1ms linear, opacity 1ms linear;
  visibility: hidden;
  opacity: 1;
}
.cdk-overlay-transparent-backdrop.cdk-overlay-backdrop-showing {
  opacity: 0;
  visibility: visible;
}

.cdk-overlay-backdrop-noop-animation {
  transition: none;
}

.cdk-overlay-connected-position-bounding-box {
  position: absolute;
  z-index: 1055;
  display: flex;
  flex-direction: column;
  min-width: 1px;
  min-height: 1px;
}

.cdk-global-scrollblock {
  position: fixed;
  width: 100%;
  overflow-y: scroll;
}

@keyframes cdk-text-field-autofill-start { /*!*/ }
@keyframes cdk-text-field-autofill-end { /*!*/ }
.cdk-text-field-autofill-monitored:-webkit-autofill {
  animation: cdk-text-field-autofill-start 0s 1ms;
}

.cdk-text-field-autofill-monitored:not(:-webkit-autofill) {
  animation: cdk-text-field-autofill-end 0s 1ms;
}

.mat-ripple {
  overflow: hidden;
  position: relative;
}
.mat-ripple:not(:empty) {
  transform: translateZ(0);
}

.mat-ripple.mat-ripple-unbounded {
  overflow: visible;
}

.mat-ripple-element {
  position: absolute;
  border-radius: 50%;
  pointer-events: none;
  transition: opacity, transform 0ms cubic-bezier(0, 0, 0.2, 1);
  transform: scale3d(0, 0, 0);
  background-color: var(--mat-ripple-color, rgba(0, 0, 0, 0.1));
}
.cdk-high-contrast-active .mat-ripple-element {
  display: none;
}

.cdk-visually-hidden {
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
  white-space: nowrap;
  outline: 0;
  -webkit-appearance: none;
  -moz-appearance: none;
  left: 0;
}
[dir=rtl] .cdk-visually-hidden {
  left: auto;
  right: 0;
}

.cdk-overlay-container, .cdk-global-overlay-wrapper {
  pointer-events: none;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
}

.cdk-overlay-container {
  position: fixed;
  z-index: 1055;
}
.cdk-overlay-container:empty {
  display: none;
}

.cdk-global-overlay-wrapper {
  display: flex;
  position: absolute;
  z-index: 1055;
}

.cdk-overlay-pane {
  position: absolute;
  pointer-events: auto;
  box-sizing: border-box;
  z-index: 1055;
  display: flex;
  max-width: 100%;
  max-height: 100%;
}

.cdk-overlay-backdrop {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 1050;
  pointer-events: auto;
  -webkit-tap-highlight-color: transparent;
  transition: opacity 400ms cubic-bezier(0.25, 0.8, 0.25, 1);
  opacity: 0;
}
.cdk-overlay-backdrop.cdk-overlay-backdrop-showing {
  opacity: 1;
}
.cdk-high-contrast-active .cdk-overlay-backdrop.cdk-overlay-backdrop-showing {
  opacity: 0.6;
}

.cdk-overlay-dark-backdrop {
  background: rgba(0, 0, 0, 0.32);
}

.cdk-overlay-transparent-backdrop {
  transition: visibility 1ms linear, opacity 1ms linear;
  visibility: hidden;
  opacity: 1;
}
.cdk-overlay-transparent-backdrop.cdk-overlay-backdrop-showing {
  opacity: 0;
  visibility: visible;
}

.cdk-overlay-backdrop-noop-animation {
  transition: none;
}

.cdk-overlay-connected-position-bounding-box {
  position: absolute;
  z-index: 1055;
  display: flex;
  flex-direction: column;
  min-width: 1px;
  min-height: 1px;
}

.cdk-global-scrollblock {
  position: fixed;
  width: 100%;
  overflow-y: scroll;
}

textarea.cdk-textarea-autosize {
  resize: none;
}

textarea.cdk-textarea-autosize-measuring {
  padding: 2px 0 !important;
  box-sizing: content-box !important;
  height: auto !important;
  overflow: hidden !important;
}

textarea.cdk-textarea-autosize-measuring-firefox {
  padding: 2px 0 !important;
  box-sizing: content-box !important;
  height: 0 !important;
}

@keyframes cdk-text-field-autofill-start { /*!*/ }
@keyframes cdk-text-field-autofill-end { /*!*/ }
.cdk-text-field-autofill-monitored:-webkit-autofill {
  animation: cdk-text-field-autofill-start 0s 1ms;
}

.cdk-text-field-autofill-monitored:not(:-webkit-autofill) {
  animation: cdk-text-field-autofill-end 0s 1ms;
}

.mat-focus-indicator {
  position: relative;
}
.mat-focus-indicator::before {
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  position: absolute;
  box-sizing: border-box;
  pointer-events: none;
  display: var(--mat-focus-indicator-display, none);
  border: var(--mat-focus-indicator-border-width, 3px) var(--mat-focus-indicator-border-style, solid) var(--mat-focus-indicator-border-color, transparent);
  border-radius: var(--mat-focus-indicator-border-radius, 4px);
}
.mat-focus-indicator:focus::before {
  content: "";
}

.cdk-high-contrast-active {
  --mat-focus-indicator-display: block;
}

.mat-mdc-focus-indicator {
  position: relative;
}
.mat-mdc-focus-indicator::before {
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  position: absolute;
  box-sizing: border-box;
  pointer-events: none;
  display: var(--mat-mdc-focus-indicator-display, none);
  border: var(--mat-mdc-focus-indicator-border-width, 3px) var(--mat-mdc-focus-indicator-border-style, solid) var(--mat-mdc-focus-indicator-border-color, transparent);
  border-radius: var(--mat-mdc-focus-indicator-border-radius, 4px);
}
.mat-mdc-focus-indicator:focus::before {
  content: "";
}

.cdk-high-contrast-active {
  --mat-mdc-focus-indicator-display: block;
}

.mat-app-background {
  background-color: var(--mat-app-background-color, transparent);
  color: var(--mat-app-text-color, inherit);
}

html {
  --mat-ripple-color: rgba(0, 0, 0, 0.1);
}

html {
  --mat-option-selected-state-label-text-color: #3f51b5;
  --mat-option-label-text-color: rgba(0, 0, 0, 0.87);
  --mat-option-hover-state-layer-color: rgba(0, 0, 0, 0.04);
  --mat-option-focus-state-layer-color: rgba(0, 0, 0, 0.04);
  --mat-option-selected-state-layer-color: rgba(0, 0, 0, 0.04);
}

.mat-accent {
  --mat-option-selected-state-label-text-color: #3f51b5;
  --mat-option-label-text-color: rgba(0, 0, 0, 0.87);
  --mat-option-hover-state-layer-color: rgba(0, 0, 0, 0.04);
  --mat-option-focus-state-layer-color: rgba(0, 0, 0, 0.04);
  --mat-option-selected-state-layer-color: rgba(0, 0, 0, 0.04);
}

.mat-warn {
  --mat-option-selected-state-label-text-color: #f44336;
  --mat-option-label-text-color: rgba(0, 0, 0, 0.87);
  --mat-option-hover-state-layer-color: rgba(0, 0, 0, 0.04);
  --mat-option-focus-state-layer-color: rgba(0, 0, 0, 0.04);
  --mat-option-selected-state-layer-color: rgba(0, 0, 0, 0.04);
}

html {
  --mat-optgroup-label-text-color: rgba(0, 0, 0, 0.87);
}

.mat-primary {
  --mat-full-pseudo-checkbox-selected-icon-color: #3f51b5;
  --mat-full-pseudo-checkbox-selected-checkmark-color: #fafafa;
  --mat-full-pseudo-checkbox-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mat-full-pseudo-checkbox-disabled-selected-checkmark-color: #fafafa;
  --mat-full-pseudo-checkbox-disabled-unselected-icon-color: #b0b0b0;
  --mat-full-pseudo-checkbox-disabled-selected-icon-color: #b0b0b0;
  --mat-minimal-pseudo-checkbox-selected-checkmark-color: #3f51b5;
  --mat-minimal-pseudo-checkbox-disabled-selected-checkmark-color: #b0b0b0;
}

html {
  --mat-full-pseudo-checkbox-selected-icon-color: #3f51b5;
  --mat-full-pseudo-checkbox-selected-checkmark-color: #fafafa;
  --mat-full-pseudo-checkbox-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mat-full-pseudo-checkbox-disabled-selected-checkmark-color: #fafafa;
  --mat-full-pseudo-checkbox-disabled-unselected-icon-color: #b0b0b0;
  --mat-full-pseudo-checkbox-disabled-selected-icon-color: #b0b0b0;
  --mat-minimal-pseudo-checkbox-selected-checkmark-color: #3f51b5;
  --mat-minimal-pseudo-checkbox-disabled-selected-checkmark-color: #b0b0b0;
}

.mat-accent {
  --mat-full-pseudo-checkbox-selected-icon-color: #3f51b5;
  --mat-full-pseudo-checkbox-selected-checkmark-color: #fafafa;
  --mat-full-pseudo-checkbox-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mat-full-pseudo-checkbox-disabled-selected-checkmark-color: #fafafa;
  --mat-full-pseudo-checkbox-disabled-unselected-icon-color: #b0b0b0;
  --mat-full-pseudo-checkbox-disabled-selected-icon-color: #b0b0b0;
  --mat-minimal-pseudo-checkbox-selected-checkmark-color: #3f51b5;
  --mat-minimal-pseudo-checkbox-disabled-selected-checkmark-color: #b0b0b0;
}

.mat-warn {
  --mat-full-pseudo-checkbox-selected-icon-color: #f44336;
  --mat-full-pseudo-checkbox-selected-checkmark-color: #fafafa;
  --mat-full-pseudo-checkbox-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mat-full-pseudo-checkbox-disabled-selected-checkmark-color: #fafafa;
  --mat-full-pseudo-checkbox-disabled-unselected-icon-color: #b0b0b0;
  --mat-full-pseudo-checkbox-disabled-selected-icon-color: #b0b0b0;
  --mat-minimal-pseudo-checkbox-selected-checkmark-color: #f44336;
  --mat-minimal-pseudo-checkbox-disabled-selected-checkmark-color: #b0b0b0;
}

html {
  --mat-app-background-color: #fafafa;
  --mat-app-text-color: rgba(0, 0, 0, 0.87);
}

.mat-elevation-z0, .mat-mdc-elevation-specific.mat-elevation-z0 {
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z1, .mat-mdc-elevation-specific.mat-elevation-z1 {
  box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z2, .mat-mdc-elevation-specific.mat-elevation-z2 {
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z3, .mat-mdc-elevation-specific.mat-elevation-z3 {
  box-shadow: 0px 3px 3px -2px rgba(0, 0, 0, 0.2), 0px 3px 4px 0px rgba(0, 0, 0, 0.14), 0px 1px 8px 0px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z4, .mat-mdc-elevation-specific.mat-elevation-z4 {
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z5, .mat-mdc-elevation-specific.mat-elevation-z5 {
  box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 5px 8px 0px rgba(0, 0, 0, 0.14), 0px 1px 14px 0px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z6, .mat-mdc-elevation-specific.mat-elevation-z6 {
  box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z7, .mat-mdc-elevation-specific.mat-elevation-z7 {
  box-shadow: 0px 4px 5px -2px rgba(0, 0, 0, 0.2), 0px 7px 10px 1px rgba(0, 0, 0, 0.14), 0px 2px 16px 1px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z8, .mat-mdc-elevation-specific.mat-elevation-z8 {
  box-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z9, .mat-mdc-elevation-specific.mat-elevation-z9 {
  box-shadow: 0px 5px 6px -3px rgba(0, 0, 0, 0.2), 0px 9px 12px 1px rgba(0, 0, 0, 0.14), 0px 3px 16px 2px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z10, .mat-mdc-elevation-specific.mat-elevation-z10 {
  box-shadow: 0px 6px 6px -3px rgba(0, 0, 0, 0.2), 0px 10px 14px 1px rgba(0, 0, 0, 0.14), 0px 4px 18px 3px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z11, .mat-mdc-elevation-specific.mat-elevation-z11 {
  box-shadow: 0px 6px 7px -4px rgba(0, 0, 0, 0.2), 0px 11px 15px 1px rgba(0, 0, 0, 0.14), 0px 4px 20px 3px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z12, .mat-mdc-elevation-specific.mat-elevation-z12 {
  box-shadow: 0px 7px 8px -4px rgba(0, 0, 0, 0.2), 0px 12px 17px 2px rgba(0, 0, 0, 0.14), 0px 5px 22px 4px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z13, .mat-mdc-elevation-specific.mat-elevation-z13 {
  box-shadow: 0px 7px 8px -4px rgba(0, 0, 0, 0.2), 0px 13px 19px 2px rgba(0, 0, 0, 0.14), 0px 5px 24px 4px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z14, .mat-mdc-elevation-specific.mat-elevation-z14 {
  box-shadow: 0px 7px 9px -4px rgba(0, 0, 0, 0.2), 0px 14px 21px 2px rgba(0, 0, 0, 0.14), 0px 5px 26px 4px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z15, .mat-mdc-elevation-specific.mat-elevation-z15 {
  box-shadow: 0px 8px 9px -5px rgba(0, 0, 0, 0.2), 0px 15px 22px 2px rgba(0, 0, 0, 0.14), 0px 6px 28px 5px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z16, .mat-mdc-elevation-specific.mat-elevation-z16 {
  box-shadow: 0px 8px 10px -5px rgba(0, 0, 0, 0.2), 0px 16px 24px 2px rgba(0, 0, 0, 0.14), 0px 6px 30px 5px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z17, .mat-mdc-elevation-specific.mat-elevation-z17 {
  box-shadow: 0px 8px 11px -5px rgba(0, 0, 0, 0.2), 0px 17px 26px 2px rgba(0, 0, 0, 0.14), 0px 6px 32px 5px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z18, .mat-mdc-elevation-specific.mat-elevation-z18 {
  box-shadow: 0px 9px 11px -5px rgba(0, 0, 0, 0.2), 0px 18px 28px 2px rgba(0, 0, 0, 0.14), 0px 7px 34px 6px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z19, .mat-mdc-elevation-specific.mat-elevation-z19 {
  box-shadow: 0px 9px 12px -6px rgba(0, 0, 0, 0.2), 0px 19px 29px 2px rgba(0, 0, 0, 0.14), 0px 7px 36px 6px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z20, .mat-mdc-elevation-specific.mat-elevation-z20 {
  box-shadow: 0px 10px 13px -6px rgba(0, 0, 0, 0.2), 0px 20px 31px 3px rgba(0, 0, 0, 0.14), 0px 8px 38px 7px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z21, .mat-mdc-elevation-specific.mat-elevation-z21 {
  box-shadow: 0px 10px 13px -6px rgba(0, 0, 0, 0.2), 0px 21px 33px 3px rgba(0, 0, 0, 0.14), 0px 8px 40px 7px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z22, .mat-mdc-elevation-specific.mat-elevation-z22 {
  box-shadow: 0px 10px 14px -6px rgba(0, 0, 0, 0.2), 0px 22px 35px 3px rgba(0, 0, 0, 0.14), 0px 8px 42px 7px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z23, .mat-mdc-elevation-specific.mat-elevation-z23 {
  box-shadow: 0px 11px 14px -7px rgba(0, 0, 0, 0.2), 0px 23px 36px 3px rgba(0, 0, 0, 0.14), 0px 9px 44px 8px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z24, .mat-mdc-elevation-specific.mat-elevation-z24 {
  box-shadow: 0px 11px 15px -7px rgba(0, 0, 0, 0.2), 0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 9px 46px 8px rgba(0, 0, 0, 0.12);
}

.mat-theme-loaded-marker {
  display: none;
}

html {
  --mat-datepicker-calendar-date-selected-state-text-color: white;
  --mat-datepicker-calendar-date-selected-state-background-color: #3f51b5;
  --mat-datepicker-calendar-date-selected-disabled-state-background-color: rgba(63, 81, 181, 0.4);
  --mat-datepicker-calendar-date-today-selected-state-outline-color: white;
  --mat-datepicker-calendar-date-focus-state-background-color: rgba(63, 81, 181, 0.3);
  --mat-datepicker-calendar-date-hover-state-background-color: rgba(63, 81, 181, 0.3);
  --mat-datepicker-toggle-active-state-icon-color: #3f51b5;
  --mat-datepicker-calendar-date-in-range-state-background-color: rgba(63, 81, 181, 0.2);
  --mat-datepicker-calendar-date-in-comparison-range-state-background-color: rgba(249, 171, 0, 0.2);
  --mat-datepicker-calendar-date-in-overlap-range-state-background-color: #a8dab5;
  --mat-datepicker-calendar-date-in-overlap-range-selected-state-background-color: #46a35e;
  --mat-datepicker-toggle-icon-color: rgba(0, 0, 0, 0.54);
  --mat-datepicker-calendar-body-label-text-color: rgba(0, 0, 0, 0.54);
  --mat-datepicker-calendar-period-button-icon-color: rgba(0, 0, 0, 0.54);
  --mat-datepicker-calendar-navigation-button-icon-color: rgba(0, 0, 0, 0.54);
  --mat-datepicker-calendar-header-divider-color: rgba(0, 0, 0, 0.12);
  --mat-datepicker-calendar-header-text-color: rgba(0, 0, 0, 0.54);
  --mat-datepicker-calendar-date-today-outline-color: rgba(0, 0, 0, 0.38);
  --mat-datepicker-calendar-date-today-disabled-state-outline-color: rgba(0, 0, 0, 0.18);
  --mat-datepicker-calendar-date-text-color: rgba(0, 0, 0, 0.87);
  --mat-datepicker-calendar-date-outline-color: transparent;
  --mat-datepicker-calendar-date-disabled-state-text-color: rgba(0, 0, 0, 0.38);
  --mat-datepicker-calendar-date-preview-state-outline-color: rgba(0, 0, 0, 0.24);
  --mat-datepicker-range-input-separator-color: rgba(0, 0, 0, 0.87);
  --mat-datepicker-range-input-disabled-state-separator-color: rgba(0, 0, 0, 0.38);
  --mat-datepicker-range-input-disabled-state-text-color: rgba(0, 0, 0, 0.38);
  --mat-datepicker-calendar-container-background-color: white;
  --mat-datepicker-calendar-container-text-color: rgba(0, 0, 0, 0.87);
}

.mat-datepicker-content.mat-accent {
  --mat-datepicker-calendar-date-selected-state-text-color: white;
  --mat-datepicker-calendar-date-selected-state-background-color: #3f51b5;
  --mat-datepicker-calendar-date-selected-disabled-state-background-color: rgba(63, 81, 181, 0.4);
  --mat-datepicker-calendar-date-today-selected-state-outline-color: white;
  --mat-datepicker-calendar-date-focus-state-background-color: rgba(63, 81, 181, 0.3);
  --mat-datepicker-calendar-date-hover-state-background-color: rgba(63, 81, 181, 0.3);
  --mat-datepicker-calendar-date-in-range-state-background-color: rgba(63, 81, 181, 0.2);
  --mat-datepicker-calendar-date-in-comparison-range-state-background-color: rgba(249, 171, 0, 0.2);
  --mat-datepicker-calendar-date-in-overlap-range-state-background-color: #a8dab5;
  --mat-datepicker-calendar-date-in-overlap-range-selected-state-background-color: #46a35e;
}
.mat-datepicker-content.mat-warn {
  --mat-datepicker-calendar-date-selected-state-text-color: white;
  --mat-datepicker-calendar-date-selected-state-background-color: #f44336;
  --mat-datepicker-calendar-date-selected-disabled-state-background-color: rgba(244, 67, 54, 0.4);
  --mat-datepicker-calendar-date-today-selected-state-outline-color: white;
  --mat-datepicker-calendar-date-focus-state-background-color: rgba(244, 67, 54, 0.3);
  --mat-datepicker-calendar-date-hover-state-background-color: rgba(244, 67, 54, 0.3);
  --mat-datepicker-calendar-date-in-range-state-background-color: rgba(244, 67, 54, 0.2);
  --mat-datepicker-calendar-date-in-comparison-range-state-background-color: rgba(249, 171, 0, 0.2);
  --mat-datepicker-calendar-date-in-overlap-range-state-background-color: #a8dab5;
  --mat-datepicker-calendar-date-in-overlap-range-selected-state-background-color: #46a35e;
}

.mat-datepicker-toggle-active.mat-accent {
  --mat-datepicker-toggle-active-state-icon-color: #3f51b5;
}
.mat-datepicker-toggle-active.mat-warn {
  --mat-datepicker-toggle-active-state-icon-color: #f44336;
}

.mat-calendar-controls .mat-mdc-icon-button.mat-mdc-button-base {
  --mdc-icon-button-state-layer-size: 40px;
  width: var(--mdc-icon-button-state-layer-size);
  height: var(--mdc-icon-button-state-layer-size);
  padding: 8px;
}
.mat-calendar-controls .mat-mdc-icon-button.mat-mdc-button-base .mat-mdc-button-touch-target {
  display: none;
}

html {
  --mat-datepicker-calendar-text-font: Roboto, sans-serif;
  --mat-datepicker-calendar-text-size: 13px;
  --mat-datepicker-calendar-body-label-text-size: 14px;
  --mat-datepicker-calendar-body-label-text-weight: 500;
  --mat-datepicker-calendar-period-button-text-size: 14px;
  --mat-datepicker-calendar-period-button-text-weight: 500;
  --mat-datepicker-calendar-header-text-size: 11px;
  --mat-datepicker-calendar-header-text-weight: 400;
}

html {
  scrollbar-color: #4359d9 #b3bdf9;
  scrollbar-width: 11px;
}

::-webkit-scrollbar {
  width: 11px;
  height: 11px;
}

::-webkit-scrollbar-thumb {
  background-color: #4359d9;
  border-radius: 11px;
}

::-webkit-scrollbar,
::-webkit-scrollbar-track,
::-webkit-scrollbar-track-piece {
  background-color: #b3bdf9;
  border-radius: 11px;
}

::-webkit-scrollbar-corner {
  background: 0;
}

::-webkit-scrollbar-button {
  display: none;
}

::-webkit-scrollbar-thumb:hover {
  background-color: #293a9c;
}

::-webkit-scrollbar:hover {
  background-color: #b3bdf9;
}

body::-webkit-scrollbar-track-piece {
  border-radius: 0;
}

/* stylelint-disable scss/at-extend-no-missing-placeholder */
.container {
  --bs-gutter-x: 32px;
  --bs-gutter-y: 0;
  width: 100%;
  padding-right: calc(var(--bs-gutter-x) * 0.5);
  padding-left: calc(var(--bs-gutter-x) * 0.5);
  margin-right: auto;
  margin-left: auto;
  max-width: 1232px;
}

.layout-title {
  margin-top: 5rem;
  margin-bottom: 2rem;
  font-weight: 700;
}
@media (min-width: 768px) {
  .layout-title {
    width: 75%;
  }
}

.layout-text {
  max-width: 787px;
}
.layout-text :is(h1, h2, h3, h4, h5, h6) {
  color: #0e2852;
}

.layout-bg {
  position: absolute;
  pointer-events: none;
  height: 100%;
  z-index: -1;
  inset: 0 0 auto;
}

.layout-bg-full-width {
  width: calc(100vw - 4px);
  left: 50%;
  right: 50%;
  margin-left: -50vw;
  margin-right: -50vw;
}

.layout-top-bg-large {
  background-image: url("data:image/svg+xml,%3Csvg fill='none' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 1440 956'%3E %3Cpath d='M436.756 713.442-321 28.782 436.459-656.18c363.465-328.678 921.961-323.024 1278.561 12.943 388.09 365.641 387.96 978.264-.29 1343.745-356.51 335.613-914.595 341.263-1277.974 12.935Z' fill='%23FFF6ED'/%3E %3C/svg%3E");
  background-position: center top;
  background-size: auto 956px;
}
@media (min-width: 992px) {
  .layout-top-bg-large {
    background-size: 100% 956px;
  }
}

.layout-top-bg-small {
  background-image: url("data:image/svg+xml,%3Csvg fill='none' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 883 674'%3E %3Cpath d='M98.715 552.786-280 210.641 98.567-131.656c181.654-164.25 460.782-161.425 639.003 6.468 193.962 182.722 193.898 488.868-.141 671.51-178.182 167.716-457.102 170.539-638.714 6.464Z' fill='%23FFF1E3'/%3E %3C/svg%3E");
  background-position: left top;
  background-size: max(684px, 80%);
}
.layout-top-bg-small.layout-bg-light {
  background-image: url("data:image/svg+xml,%3Csvg fill='none' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 883 674'%3E %3Cpath d='M98.715 552.786-280 210.641 98.567-131.656c181.654-164.25 460.782-161.425 639.003 6.468 193.962 182.722 193.898 488.868-.141 671.51-178.182 167.716-457.102 170.539-638.714 6.464Z' fill='%23FFF6ED'/%3E %3C/svg%3E");
}
@media (min-width: 992px) {
  .layout-top-bg-small {
    background-position: -235px -151px;
    background-size: 100% 833px;
  }
}

.layout-bottom-bg {
  background-image: url("data:image/svg+xml,%3Csvg fill='none' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 1440 1023'%3E %3Cpath d='m1355.83 1425.75 603.99-759.213-816.15-524.472C752.042-109.602 229.485-17.675-52.611 352.512-359.623 755.395-264.479 1329.54 156.082 1611.85c386.191 259.25 910.108 177.98 1199.748-186.1Z' fill='%23FFF1E3'/%3E %3C/svg%3E");
  background-size: cover;
  background-position: center top;
  bottom: 0;
}

.layout-bottom-bg-2 {
  background-image: url("data:image/svg+xml,%3Csvg width='1440' height='1145' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='m964.729 1340.16 567.801-713.722L765.277 133.39C397.113-103.198-94.135-16.778-359.328 331.228-647.946 709.973-558.503 1249.71-163.14 1515.11c363.053 243.72 855.583 167.31 1127.869-174.95Z' fill='%23FFF6ED'/%3E%3C/svg%3E");
  background-size: cover;
  background-position: center top;
  bottom: 0;
}

.layout-bottom-bg-3 {
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='805' height='704' fill='none'%3E%3Cpath fill='%23FFF6ED' d='m1154.83 1035.95 438.67-551.413-592.77-380.92C716.298-79.1659 336.769-12.3994 131.885 256.464-91.0952 549.074-21.9933 966.069 283.457 1171.11c280.487 188.29 661.007 129.26 871.373-135.16Z'/%3E%3C/svg%3E");
  background-position: right bottom;
  background-size: 704px 850px;
  bottom: 0;
}

.layout-bg-two-blobs {
  background-image: url("data:image/svg+xml,%3Csvg width='1187' height='857' fill='none' xmlns='http://www.w3.org/2000/svg'%3E %3Cpath d='M721 477 497 274 721 72c108-97 273-96 379 4a272 272 0 0 1 0 397 280 280 0 0 1-379 4Z' fill='%23FFF6ED'/%3E %3Cpath d='M330 751 0 453l330-299a412 412 0 0 1 558 6 402 402 0 0 1 0 586 412 412 0 0 1-558 5Z' fill='%23FFE5CC'/%3E %3C/svg%3E");
  background-position: center top;
  background-size: contain;
  left: -500px;
  top: -50px;
}
@media (min-width: 992px) {
  .layout-bg-two-blobs {
    left: -1200px;
  }
}

.inner-html-paragraph p {
  margin-bottom: 0;
}
.inner-html-paragraph ::ng-deep p {
  margin-bottom: 0;
}

.whitespace-break-spaces {
  white-space: break-spaces;
}

@media (max-width: 991.98px) {
  .dd-fullscreen-overlay-lg {
    height: 100%;
  }
}

.dd-fullscreen-dropdown {
  overflow: auto;
  margin-top: 90px;
}
.dd-fullscreen-dropdown::-webkit-scrollbar {
  width: 0;
  background: transparent; /* make scrollbar transparent */
}
@media (max-width: 991.98px) {
  .dd-fullscreen-dropdown {
    width: 100vw;
  }
}
@media (max-width: 767.98px) {
  .dd-fullscreen-dropdown {
    margin-top: 66px;
  }
}

.dd-transparent-backdrop {
  background-color: transparent;
}

.dd-transparent-language-backdrop {
  background-color: transparent;
}

.dd-dark-backdrop {
  background-color: rgba(0, 0, 0, 0.5);
}

.dd-blur-backdrop {
  background-color: rgba(14, 40, 82, 0.15);
}
@supports (backdrop-filter: none) {
  .dd-blur-backdrop {
    backdrop-filter: blur(6px);
  }
}

.speech-bubble-portal {
  width: 220px;
}

/* stylelint-disable plugin/no-unsupported-browser-features */
.form-label {
  color: #0e2852;
  background-color: #fff;
  position: absolute;
  margin-top: -0.45rem;
  margin-left: 1.5rem;
  margin-right: 1.5rem;
  padding: 0 0.2rem;
  display: block;
  max-width: calc(100% - 3rem);
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  cursor: pointer;
  z-index: 1;
}
.disabled .form-label {
  color: #778192;
  cursor: not-allowed;
}

.form-input,
.hs-input {
  background-color: #fff;
  caret-color: #0e2852;
  color: #0e2852;
  outline: none;
  border: 2px solid #b3bdf9;
  width: 100%;
  border-radius: 60px;
}
.form-input::placeholder,
.hs-input::placeholder {
  color: #778192;
}
.form-input:hover,
.hs-input:hover {
  border-color: #6a7ffc;
}
.form-input:focus,
.hs-input:focus {
  border-color: #4359d9;
}
.form-input:disabled,
.hs-input:disabled {
  cursor: not-allowed;
  border-color: #e8eaed;
  color: #778192;
}
.success .form-input,
.success .hs-input {
  border-color: #67c180;
}
.warning .form-input,
.warning .hs-input {
  border-color: #f0a64c;
}
.error .form-input, .form-input.error,
.error .hs-input,
.hs-input.error {
  border-color: #ec5a51;
  color: #ec5a51;
}
.error .form-input::placeholder, .form-input.error::placeholder,
.error .hs-input::placeholder,
.hs-input.error::placeholder {
  color: #f6bab6;
}
.input-sm .form-input,
.input-sm .hs-input {
  padding: 6px 1.5rem;
}
.input-md .form-input,
.input-md .hs-input {
  padding: 10px 1.5rem;
}
.input-lg .form-input,
.input-lg .hs-input {
  padding: 14px 1.5rem;
}
.input-has-prefix .form-input,
.input-has-prefix .hs-input {
  padding-left: 3.5rem;
}
.input-has-suffix .form-input,
.input-has-suffix .hs-input {
  padding-right: 3.5rem;
}

.form-input[type=date] {
  background-color: #fff;
  caret-color: #0e2852;
  color: #0e2852;
  outline: none;
  border: 2px solid #b3bdf9;
  width: 100%;
  border-radius: 60px;
  display: flex;
  appearance: none;
  min-height: 56px;
  min-width: 100%;
}
.form-input[type=date]::placeholder {
  color: #778192;
}
.form-input[type=date]:hover {
  border-color: #6a7ffc;
}
.form-input[type=date]:focus {
  border-color: #4359d9;
}
.form-input[type=date]:disabled {
  cursor: not-allowed;
  border-color: #e8eaed;
  color: #778192;
}
.success .form-input[type=date] {
  border-color: #67c180;
}
.warning .form-input[type=date] {
  border-color: #f0a64c;
}
.error .form-input[type=date], .form-input[type=date].error {
  border-color: #ec5a51;
  color: #ec5a51;
}
.error .form-input[type=date]::placeholder, .form-input[type=date].error::placeholder {
  color: #f6bab6;
}
.input-sm .form-input[type=date] {
  padding: 6px 1.5rem;
}
.input-md .form-input[type=date] {
  padding: 10px 1.5rem;
}
.input-lg .form-input[type=date] {
  padding: 14px 1.5rem;
}
.input-has-prefix .form-input[type=date] {
  padding-left: 3.5rem;
}
.input-has-suffix .form-input[type=date] {
  padding-right: 3.5rem;
}

.no-list {
  list-style: none;
  padding: 0;
  margin: 0;
  font-size: 0.75rem;
  font-weight: 400;
  line-height: 1rem;
}

.hs-error-msg {
  display: block;
  padding: 0 1.5rem;
  font-weight: 400;
  margin-top: 0.25rem;
  color: #ec5a51;
}

.form-hint {
  display: block;
  color: #778192;
  padding: 0 1.5rem;
  font-weight: 400;
  margin-top: 0.25rem;
}
.success .form-hint {
  color: #67c180;
}
.warning .form-hint {
  color: #f0a64c;
}
.error .form-hint {
  color: #ec5a51;
}

.input-suffix {
  right: 1.5rem;
}

.input-prefix {
  left: 1.5rem;
}

.input-prefix,
.input-suffix {
  pointer-events: none;
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.5rem;
  color: #778192;
}
.input-sm .input-prefix,
.input-sm .input-suffix {
  top: 8px;
}
.input-md .input-prefix,
.input-md .input-suffix {
  top: 12px;
}
.input-lg .input-prefix,
.input-lg .input-suffix {
  top: 16px;
}
.disabled .input-prefix,
.disabled .input-suffix {
  color: #e8eaed;
}

.input-suffix-button {
  pointer-events: auto;
  background: 0;
  color: inherit;
  border: 0;
  width: 1.5rem;
  height: 1.5rem;
  padding: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}
.input-suffix-button:hover {
  color: #0e2852;
}
.disabled .input-suffix-button {
  cursor: not-allowed;
}
.disabled .input-suffix-button:hover {
  color: inherit;
}

::-webkit-calendar-picker-indicator,
::-webkit-search-cancel-button {
  display: none;
}

/* stylelint-disable plugin/no-unsupported-browser-features */
/* stylelint-disable no-invalid-position-at-import-rule */
.btn {
  border-width: 2px;
  border-style: solid;
  outline: none;
  border-radius: 60px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
  font-weight: 700;
  line-height: 24px;
  user-select: none;
  padding: 0;
  white-space: nowrap;
  transition: background-color 0.15s ease, border 0.15s ease;
  appearance: none;
  /* stylelint-disable-next-line property-no-vendor-prefix */
  -webkit-appearance: none;
  opacity: 1;
}
.btn:disabled, .btn.btn-disabled {
  cursor: not-allowed;
}
.btn svg-icon {
  flex-shrink: 0;
}

a.btn {
  text-decoration: none;
}
a.btn.btn-disabled {
  pointer-events: none;
}

.btn-unstyled {
  background: 0;
  border: 0;
  padding: 0;
  font: inherit;
  color: inherit;
}

.btn-primary {
  background-color: #556cf2;
  border-color: transparent;
  color: #fff;
  transition: background-color 0.15s ease, border 0.15s ease;
}
.btn-primary.btn-inverse {
  background-color: #fff;
  border-color: #fff;
  color: #556cf2;
}
.btn-primary.btn-inverse:focus-visible {
  border-color: #556cf2;
}
.btn-primary.active, .btn-primary:hover {
  background-color: #6a7ffc;
  border-color: #6a7ffc;
  color: #fff;
}
.btn-primary:focus-visible {
  background-color: #556cf2;
  border-color: #293a9c;
  color: #fff;
}
.btn-primary:active {
  background-color: #4359d9;
  border-color: #4359d9;
  color: #fff;
}
.btn-primary:disabled, .btn-primary.btn-disabled {
  background-color: #e8eaed;
  border-color: #e8eaed;
  color: #778192;
}

.btn-secondary {
  background-color: transparent;
  border-color: #556cf2;
  color: #556cf2;
}
.btn-secondary.btn-inverse {
  border-color: #fff;
  color: #fff;
}
.btn-secondary:hover {
  background-color: #556cf2;
  border-color: #556cf2;
  color: #fff;
}
.btn-secondary:focus-visible {
  background-color: #b3bdf9;
  border-color: #556cf2;
  color: #293a9c;
}
.btn-secondary:active {
  background-color: #4359d9;
  border-color: #4359d9;
  color: #fff;
}
.btn-secondary:disabled, .btn-secondary.btn-disabled {
  background-color: #e8eaed;
  border-color: #778192;
  color: #778192;
}

.btn-tertiary {
  background-color: transparent;
  border-color: transparent;
  color: #556cf2;
}
.btn-tertiary:hover {
  background-color: transparent;
  border-color: transparent;
  color: #4359d9;
}
.btn-tertiary:focus-visible {
  background-color: transparent;
  border-color: #556cf2;
  color: #556cf2;
}
.btn-tertiary:active {
  background-color: transparent;
  border-color: transparent;
  color: #293a9c;
}
.btn-tertiary:disabled, .btn-tertiary.btn-disabled {
  background-color: #e8eaed;
  border-color: transparent;
  color: #778192;
}

.btn-text.btn-extra-small {
  padding: 2px 48px;
}
.btn-text.btn-small {
  padding: 6px 48px;
}
.btn-text.btn-normal {
  padding: 10px 48px;
}
.btn-text.btn-large {
  padding: 14px 48px;
}

.btn-icon.btn-extra-small {
  width: 32px;
  height: 32px;
}
.btn-icon.btn-small {
  width: 40px;
  height: 40px;
}
.btn-icon.btn-normal {
  width: 48px;
  height: 48px;
}
.btn-icon.btn-large {
  width: 56px;
  height: 56px;
}

.btn-scroll {
  width: 48px;
  height: 72px;
}
.btn-scroll.btn-secondary {
  background-color: transparent;
}
.btn-scroll.btn-secondary:hover {
  border-color: #4359d9;
  color: #4359d9;
}
.btn-scroll.btn-secondary:active {
  border-color: #293a9c;
  color: #293a9c;
}
.btn-scroll.btn-secondary:focus-visible {
  background-color: #e8f0fd;
  border-color: #556cf2;
  color: #556cf2;
}
.btn-scroll.btn-secondary:disabled, .btn-scroll.btn-secondary.btn-disabled {
  background-color: #fff;
  border-color: #778192;
  color: #778192;
}

.btn-nav {
  border-width: 1px;
  background-color: #fff;
  border-color: #556cf2;
  color: #556cf2;
}
.btn-nav.btn-text {
  padding: 11px 16px;
}
@media (min-width: 768px) {
  .btn-nav.btn-text {
    padding: 11px 24px;
  }
}
.btn-nav:hover {
  background-color: #e7eaff;
  border-color: transparent;
  color: #4359d9;
}
.btn-nav.opened, .btn-nav:focus-visible {
  background-color: #f2f4ff;
  border-color: transparent;
  color: #556cf2;
}
.btn-nav:active {
  background-color: #b3bdf9;
  border-color: transparent;
  color: #293a9c;
}
.btn-nav:disabled, .btn-nav.btn-disabled {
  background-color: #e8eaed;
  border-color: transparent;
  color: #778192;
  filter: grayscale(0.5);
}

.btn-fab {
  height: 72px;
  width: 72px;
  border-width: 1.5px;
  background-color: #fff;
  border-color: #556cf2;
  color: #556cf2;
  box-shadow: 0 13px 21px -13px rgba(23, 32, 82, 0.6);
}
.btn-fab:hover {
  background-color: #f2f4ff;
  border-color: #556cf2;
  color: #556cf2;
}
.btn-fab:focus-visible {
  background-color: #fff;
  border-color: #293a9c;
  color: #556cf2;
  border-width: 2px;
}
.btn-fab:active {
  background-color: #556cf2;
  border-color: transparent;
  color: #fff;
}
.btn-fab:disabled, .btn-fab.btn-disabled {
  background-color: #e8eaed;
  border-color: transparent;
  color: #778192;
}

.form-check {
  display: block;
  min-height: 1rem;
  line-height: 1.5em;
  padding-left: 2em;
}
.form-check .form-hint {
  font-size: 0.875em;
  line-height: 1.25em;
  padding: 0;
}
.form-check.form-check-normal {
  font-size: 1rem;
}
.form-check.form-check-small {
  font-size: 0.9rem;
}
.form-check.form-check-tiny {
  font-size: 0.75rem;
}

.form-check-label {
  cursor: pointer;
}

.form-check-input {
  float: left;
  width: 1.5em;
  height: 1.5em;
  vertical-align: top;
  background-position: center;
  /* stylelint-disable-next-line plugin/no-unsupported-browser-features */
  appearance: none;
  print-color-adjust: exact;
  outline: 0;
  cursor: pointer;
  margin-left: -2em;
  background-size: 1em;
  background-color: #fff;
  border: 2px solid #b3bdf9;
  /* stylelint-disable-next-line no-descending-specificity */
}
.form-check-normal .form-check-input[type=checkbox] {
  border-radius: 6px;
}
.form-check-small .form-check-input[type=checkbox] {
  border-radius: 4px;
}
.form-check-tiny .form-check-input[type=checkbox] {
  border-radius: 2px;
}
.form-check-input[type=radio] {
  border-radius: 50%;
}
.error .form-check-input {
  border-color: #ec5a51;
}
.form-check-input:hover {
  border-color: #556cf2;
}
.form-check-input:active {
  border-color: #293a9c;
}
.form-check-input:focus-visible {
  border-color: #293a9c;
  border-width: 3px;
}
.form-check-input[disabled], .form-check-input:disabled {
  background-color: #e8eaed;
  border-color: transparent;
  cursor: not-allowed;
}
.form-check-input[disabled] ~ .form-check-label, .form-check-input:disabled ~ .form-check-label {
  cursor: not-allowed;
  color: #778192;
}
.form-check-input:checked {
  background-color: #556cf2;
  border-color: transparent;
}
.form-check-input:checked[type=checkbox] {
  background-image: url("data:image/svg+xml,%3csvg width='16' height='16' fill='none' xmlns='http://www.w3.org/2000/svg'%3e%3cpath d='m3.75 9.75 2.5 2.5 6-8.5' stroke='%23fff' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'/%3e%3c/svg%3e");
}
.form-check-input:checked[type=checkbox]:hover {
  background-color: #4359d9;
}
.form-check-input:checked[type=checkbox]:active {
  background-color: #293a9c;
}
.form-check-input:checked[type=checkbox]:focus-visible {
  border-color: #293a9c;
}
.form-check-input:checked[type=checkbox]:disabled, .form-check-input:checked[type=checkbox][disabled] {
  background-color: #e8eaed;
  background-image: url("data:image/svg+xml,%3csvg width='16' height='16' fill='none' xmlns='http://www.w3.org/2000/svg'%3e%3cpath d='m3.75 9.75 2.5 2.5 6-8.5' stroke='%23778192' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'/%3e%3c/svg%3e");
}
.form-check-input:checked[type=radio] {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%23556cf2'/%3e%3c/svg%3e");
  background-color: #fff;
  border-color: #b3bdf9;
}
.form-check-input:checked[type=radio]:hover {
  border-color: #556cf2;
}
.form-check-input:checked[type=radio]:active {
  border-color: #293a9c;
}
.form-check-input:checked[type=radio]:focus-visible {
  border-color: #293a9c;
}
.form-check-input:checked[type=radio]:disabled, .form-check-input:checked[type=radio][disabled] {
  border-color: transparent;
  background-color: #e8eaed;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%23778192'/%3e%3c/svg%3e");
}
.form-check-input[type=checkbox]:indeterminate {
  background-color: #556cf2;
  border-color: transparent;
  background-image: url("data:image/svg+xml,%3csvg width='16' height='16' fill='none' xmlns='http://www.w3.org/2000/svg'%3e%3cpath d='M3.75 7.25a.75.75 0 0 0 0 1.5v-1.5Zm8.5 1.5a.75.75 0 0 0 0-1.5v1.5Zm-8.5 0h8.5v-1.5h-8.5v1.5Z' fill='%23fff'/%3e%3c/svg%3e");
}
.form-check-input[type=checkbox]:indeterminate:hover {
  background-color: #4359d9;
}
.form-check-input[type=checkbox]:indeterminate:active {
  background-color: #293a9c;
}
.form-check-input[type=checkbox]:indeterminate:focus-visible {
  border-color: #293a9c;
}
.form-check-input[type=checkbox]:indeterminate:disabled, .form-check-input[type=checkbox]:indeterminate[disabled] {
  background-color: #e8eaed;
  background-image: url("data:image/svg+xml,%3csvg width='16' height='16' fill='none' xmlns='http://www.w3.org/2000/svg'%3e%3cpath d='M3.75 7.25a.75.75 0 0 0 0 1.5v-1.5Zm8.5 1.5a.75.75 0 0 0 0-1.5v1.5Zm-8.5 0h8.5v-1.5h-8.5v1.5Z' fill='%23778192'/%3e%3c/svg%3e");
}

.form-switch {
  position: relative;
  display: flex;
  flex-direction: column;
}
.form-switch.form-check-normal {
  padding-left: 48px;
}
.form-switch.form-check-normal .form-check-label {
  line-height: 24px;
}
.form-switch.form-check-small {
  padding-left: 40px;
}
.form-switch.form-check-small .form-check-label {
  line-height: 16px;
}
.form-switch.form-check-tiny {
  padding-left: 24px;
}
.form-switch.form-check-tiny .form-check-label {
  line-height: 8px;
}
.form-switch .form-check-input {
  pointer-events: none;
  opacity: 0;
  position: absolute;
}

.form-switch-button {
  border: 2px solid transparent;
  background-color: #b3bdf9;
  position: absolute;
  left: 0;
  display: flex;
  align-items: center;
  cursor: pointer;
  print-color-adjust: exact;
}
.form-check-normal .form-switch-button {
  width: 40px;
  height: 24px;
  border-radius: 40px;
}
.form-check-small .form-switch-button {
  width: 32px;
  height: 16px;
  border-radius: 32px;
}
.form-check-tiny .form-switch-button {
  width: 16px;
  height: 8px;
  border-radius: 16px;
}
.form-check-input:focus-visible ~ .form-check-label .form-switch-button {
  border-color: #293a9c;
}
.form-check-input:disabled ~ .form-check-label .form-switch-button {
  border-color: #778192;
  border-width: 1px;
  background-color: #fff;
  cursor: not-allowed;
}
.form-check-input:checked ~ .form-check-label .form-switch-button {
  background-color: #556cf2;
}
.form-check-input:checked:hover ~ .form-check-label .form-switch-button {
  background-color: #4359d9;
}
.form-check-input:checked:active ~ .form-check-label .form-switch-button {
  background-color: #293a9c;
}
.form-check-input:checked:disabled ~ .form-check-label .form-switch-button {
  background-color: #fff;
}

.form-switch-knob {
  background-color: #fff;
  transition: transform 0.2s;
}
.form-check-normal .form-switch-knob {
  width: 20px;
  height: 20px;
  border-radius: 20px;
}
.form-check-small .form-switch-knob {
  width: 12px;
  height: 12px;
  border-radius: 12px;
}
.form-check-tiny .form-switch-knob {
  width: 4px;
  height: 4px;
  border-radius: 4px;
}
.form-check-input:hover ~ .form-check-label .form-switch-knob {
  background-color: #f3f7fd;
}
.form-check-input:active ~ .form-check-label .form-switch-knob {
  background-color: #6a7ffc;
}
.form-check-input:focus-visible ~ .form-check-label .form-switch-knob {
  background-color: #fff;
}
.form-check-input:disabled ~ .form-check-label .form-switch-knob {
  background-color: #e8eaed;
  margin: 1px;
}
.form-check-input:checked ~ .form-check-label .form-switch-knob {
  transform: translateX(16px);
}
.form-check-input:checked:hover ~ .form-check-label .form-switch-knob {
  background-color: #fff;
}
.form-check-input:checked:active ~ .form-check-label .form-switch-knob {
  background-color: #fff;
}
.form-check-input:checked:disabled ~ .form-check-label .form-switch-knob {
  background-color: #e8eaed;
}

.fib, .fi {
  background-size: contain;
  background-position: 50%;
  background-repeat: no-repeat;
}

.fi {
  position: relative;
  display: inline-block;
  width: 1.333333em;
  line-height: 1em;
}
.fi:before {
  content: " ";
}
.fi.fis {
  width: 1em;
}

.fi-xx {
  background-image: url(../flags/4x3/xx.svg);
}
.fi-xx.fis {
  background-image: url(../flags/1x1/xx.svg);
}

.fi-ad {
  background-image: url(../flags/4x3/ad.svg);
}
.fi-ad.fis {
  background-image: url(../flags/1x1/ad.svg);
}

.fi-ae {
  background-image: url(../flags/4x3/ae.svg);
}
.fi-ae.fis {
  background-image: url(../flags/1x1/ae.svg);
}

.fi-af {
  background-image: url(../flags/4x3/af.svg);
}
.fi-af.fis {
  background-image: url(../flags/1x1/af.svg);
}

.fi-ag {
  background-image: url(../flags/4x3/ag.svg);
}
.fi-ag.fis {
  background-image: url(../flags/1x1/ag.svg);
}

.fi-ai {
  background-image: url(../flags/4x3/ai.svg);
}
.fi-ai.fis {
  background-image: url(../flags/1x1/ai.svg);
}

.fi-al {
  background-image: url(../flags/4x3/al.svg);
}
.fi-al.fis {
  background-image: url(../flags/1x1/al.svg);
}

.fi-am {
  background-image: url(../flags/4x3/am.svg);
}
.fi-am.fis {
  background-image: url(../flags/1x1/am.svg);
}

.fi-ao {
  background-image: url(../flags/4x3/ao.svg);
}
.fi-ao.fis {
  background-image: url(../flags/1x1/ao.svg);
}

.fi-aq {
  background-image: url(../flags/4x3/aq.svg);
}
.fi-aq.fis {
  background-image: url(../flags/1x1/aq.svg);
}

.fi-ar {
  background-image: url(../flags/4x3/ar.svg);
}
.fi-ar.fis {
  background-image: url(../flags/1x1/ar.svg);
}

.fi-as {
  background-image: url(../flags/4x3/as.svg);
}
.fi-as.fis {
  background-image: url(../flags/1x1/as.svg);
}

.fi-at {
  background-image: url(../flags/4x3/at.svg);
}
.fi-at.fis {
  background-image: url(../flags/1x1/at.svg);
}

.fi-au {
  background-image: url(../flags/4x3/au.svg);
}
.fi-au.fis {
  background-image: url(../flags/1x1/au.svg);
}

.fi-aw {
  background-image: url(../flags/4x3/aw.svg);
}
.fi-aw.fis {
  background-image: url(../flags/1x1/aw.svg);
}

.fi-ax {
  background-image: url(../flags/4x3/ax.svg);
}
.fi-ax.fis {
  background-image: url(../flags/1x1/ax.svg);
}

.fi-az {
  background-image: url(../flags/4x3/az.svg);
}
.fi-az.fis {
  background-image: url(../flags/1x1/az.svg);
}

.fi-ba {
  background-image: url(../flags/4x3/ba.svg);
}
.fi-ba.fis {
  background-image: url(../flags/1x1/ba.svg);
}

.fi-bb {
  background-image: url(../flags/4x3/bb.svg);
}
.fi-bb.fis {
  background-image: url(../flags/1x1/bb.svg);
}

.fi-bd {
  background-image: url(../flags/4x3/bd.svg);
}
.fi-bd.fis {
  background-image: url(../flags/1x1/bd.svg);
}

.fi-be {
  background-image: url(../flags/4x3/be.svg);
}
.fi-be.fis {
  background-image: url(../flags/1x1/be.svg);
}

.fi-bf {
  background-image: url(../flags/4x3/bf.svg);
}
.fi-bf.fis {
  background-image: url(../flags/1x1/bf.svg);
}

.fi-bg {
  background-image: url(../flags/4x3/bg.svg);
}
.fi-bg.fis {
  background-image: url(../flags/1x1/bg.svg);
}

.fi-bh {
  background-image: url(../flags/4x3/bh.svg);
}
.fi-bh.fis {
  background-image: url(../flags/1x1/bh.svg);
}

.fi-bi {
  background-image: url(../flags/4x3/bi.svg);
}
.fi-bi.fis {
  background-image: url(../flags/1x1/bi.svg);
}

.fi-bj {
  background-image: url(../flags/4x3/bj.svg);
}
.fi-bj.fis {
  background-image: url(../flags/1x1/bj.svg);
}

.fi-bl {
  background-image: url(../flags/4x3/bl.svg);
}
.fi-bl.fis {
  background-image: url(../flags/1x1/bl.svg);
}

.fi-bm {
  background-image: url(../flags/4x3/bm.svg);
}
.fi-bm.fis {
  background-image: url(../flags/1x1/bm.svg);
}

.fi-bn {
  background-image: url(../flags/4x3/bn.svg);
}
.fi-bn.fis {
  background-image: url(../flags/1x1/bn.svg);
}

.fi-bo {
  background-image: url(../flags/4x3/bo.svg);
}
.fi-bo.fis {
  background-image: url(../flags/1x1/bo.svg);
}

.fi-bq {
  background-image: url(../flags/4x3/bq.svg);
}
.fi-bq.fis {
  background-image: url(../flags/1x1/bq.svg);
}

.fi-br {
  background-image: url(../flags/4x3/br.svg);
}
.fi-br.fis {
  background-image: url(../flags/1x1/br.svg);
}

.fi-bs {
  background-image: url(../flags/4x3/bs.svg);
}
.fi-bs.fis {
  background-image: url(../flags/1x1/bs.svg);
}

.fi-bt {
  background-image: url(../flags/4x3/bt.svg);
}
.fi-bt.fis {
  background-image: url(../flags/1x1/bt.svg);
}

.fi-bv {
  background-image: url(../flags/4x3/bv.svg);
}
.fi-bv.fis {
  background-image: url(../flags/1x1/bv.svg);
}

.fi-bw {
  background-image: url(../flags/4x3/bw.svg);
}
.fi-bw.fis {
  background-image: url(../flags/1x1/bw.svg);
}

.fi-by {
  background-image: url(../flags/4x3/by.svg);
}
.fi-by.fis {
  background-image: url(../flags/1x1/by.svg);
}

.fi-bz {
  background-image: url(../flags/4x3/bz.svg);
}
.fi-bz.fis {
  background-image: url(../flags/1x1/bz.svg);
}

.fi-ca {
  background-image: url(../flags/4x3/ca.svg);
}
.fi-ca.fis {
  background-image: url(../flags/1x1/ca.svg);
}

.fi-cc {
  background-image: url(../flags/4x3/cc.svg);
}
.fi-cc.fis {
  background-image: url(../flags/1x1/cc.svg);
}

.fi-cd {
  background-image: url(../flags/4x3/cd.svg);
}
.fi-cd.fis {
  background-image: url(../flags/1x1/cd.svg);
}

.fi-cf {
  background-image: url(../flags/4x3/cf.svg);
}
.fi-cf.fis {
  background-image: url(../flags/1x1/cf.svg);
}

.fi-cg {
  background-image: url(../flags/4x3/cg.svg);
}
.fi-cg.fis {
  background-image: url(../flags/1x1/cg.svg);
}

.fi-ch {
  background-image: url(../flags/4x3/ch.svg);
}
.fi-ch.fis {
  background-image: url(../flags/1x1/ch.svg);
}

.fi-ci {
  background-image: url(../flags/4x3/ci.svg);
}
.fi-ci.fis {
  background-image: url(../flags/1x1/ci.svg);
}

.fi-ck {
  background-image: url(../flags/4x3/ck.svg);
}
.fi-ck.fis {
  background-image: url(../flags/1x1/ck.svg);
}

.fi-cl {
  background-image: url(../flags/4x3/cl.svg);
}
.fi-cl.fis {
  background-image: url(../flags/1x1/cl.svg);
}

.fi-cm {
  background-image: url(../flags/4x3/cm.svg);
}
.fi-cm.fis {
  background-image: url(../flags/1x1/cm.svg);
}

.fi-cn {
  background-image: url(../flags/4x3/cn.svg);
}
.fi-cn.fis {
  background-image: url(../flags/1x1/cn.svg);
}

.fi-co {
  background-image: url(../flags/4x3/co.svg);
}
.fi-co.fis {
  background-image: url(../flags/1x1/co.svg);
}

.fi-cr {
  background-image: url(../flags/4x3/cr.svg);
}
.fi-cr.fis {
  background-image: url(../flags/1x1/cr.svg);
}

.fi-cu {
  background-image: url(../flags/4x3/cu.svg);
}
.fi-cu.fis {
  background-image: url(../flags/1x1/cu.svg);
}

.fi-cv {
  background-image: url(../flags/4x3/cv.svg);
}
.fi-cv.fis {
  background-image: url(../flags/1x1/cv.svg);
}

.fi-cw {
  background-image: url(../flags/4x3/cw.svg);
}
.fi-cw.fis {
  background-image: url(../flags/1x1/cw.svg);
}

.fi-cx {
  background-image: url(../flags/4x3/cx.svg);
}
.fi-cx.fis {
  background-image: url(../flags/1x1/cx.svg);
}

.fi-cy {
  background-image: url(../flags/4x3/cy.svg);
}
.fi-cy.fis {
  background-image: url(../flags/1x1/cy.svg);
}

.fi-cz {
  background-image: url(../flags/4x3/cz.svg);
}
.fi-cz.fis {
  background-image: url(../flags/1x1/cz.svg);
}

.fi-de {
  background-image: url(../flags/4x3/de.svg);
}
.fi-de.fis {
  background-image: url(../flags/1x1/de.svg);
}

.fi-dj {
  background-image: url(../flags/4x3/dj.svg);
}
.fi-dj.fis {
  background-image: url(../flags/1x1/dj.svg);
}

.fi-dk {
  background-image: url(../flags/4x3/dk.svg);
}
.fi-dk.fis {
  background-image: url(../flags/1x1/dk.svg);
}

.fi-dm {
  background-image: url(../flags/4x3/dm.svg);
}
.fi-dm.fis {
  background-image: url(../flags/1x1/dm.svg);
}

.fi-do {
  background-image: url(../flags/4x3/do.svg);
}
.fi-do.fis {
  background-image: url(../flags/1x1/do.svg);
}

.fi-dz {
  background-image: url(../flags/4x3/dz.svg);
}
.fi-dz.fis {
  background-image: url(../flags/1x1/dz.svg);
}

.fi-ec {
  background-image: url(../flags/4x3/ec.svg);
}
.fi-ec.fis {
  background-image: url(../flags/1x1/ec.svg);
}

.fi-ee {
  background-image: url(../flags/4x3/ee.svg);
}
.fi-ee.fis {
  background-image: url(../flags/1x1/ee.svg);
}

.fi-eg {
  background-image: url(../flags/4x3/eg.svg);
}
.fi-eg.fis {
  background-image: url(../flags/1x1/eg.svg);
}

.fi-eh {
  background-image: url(../flags/4x3/eh.svg);
}
.fi-eh.fis {
  background-image: url(../flags/1x1/eh.svg);
}

.fi-er {
  background-image: url(../flags/4x3/er.svg);
}
.fi-er.fis {
  background-image: url(../flags/1x1/er.svg);
}

.fi-es {
  background-image: url(../flags/4x3/es.svg);
}
.fi-es.fis {
  background-image: url(../flags/1x1/es.svg);
}

.fi-et {
  background-image: url(../flags/4x3/et.svg);
}
.fi-et.fis {
  background-image: url(../flags/1x1/et.svg);
}

.fi-fi {
  background-image: url(../flags/4x3/fi.svg);
}
.fi-fi.fis {
  background-image: url(../flags/1x1/fi.svg);
}

.fi-fj {
  background-image: url(../flags/4x3/fj.svg);
}
.fi-fj.fis {
  background-image: url(../flags/1x1/fj.svg);
}

.fi-fk {
  background-image: url(../flags/4x3/fk.svg);
}
.fi-fk.fis {
  background-image: url(../flags/1x1/fk.svg);
}

.fi-fm {
  background-image: url(../flags/4x3/fm.svg);
}
.fi-fm.fis {
  background-image: url(../flags/1x1/fm.svg);
}

.fi-fo {
  background-image: url(../flags/4x3/fo.svg);
}
.fi-fo.fis {
  background-image: url(../flags/1x1/fo.svg);
}

.fi-fr {
  background-image: url(../flags/4x3/fr.svg);
}
.fi-fr.fis {
  background-image: url(../flags/1x1/fr.svg);
}

.fi-ga {
  background-image: url(../flags/4x3/ga.svg);
}
.fi-ga.fis {
  background-image: url(../flags/1x1/ga.svg);
}

.fi-gb {
  background-image: url(../flags/4x3/gb.svg);
}
.fi-gb.fis {
  background-image: url(../flags/1x1/gb.svg);
}

.fi-gd {
  background-image: url(../flags/4x3/gd.svg);
}
.fi-gd.fis {
  background-image: url(../flags/1x1/gd.svg);
}

.fi-ge {
  background-image: url(../flags/4x3/ge.svg);
}
.fi-ge.fis {
  background-image: url(../flags/1x1/ge.svg);
}

.fi-gf {
  background-image: url(../flags/4x3/gf.svg);
}
.fi-gf.fis {
  background-image: url(../flags/1x1/gf.svg);
}

.fi-gg {
  background-image: url(../flags/4x3/gg.svg);
}
.fi-gg.fis {
  background-image: url(../flags/1x1/gg.svg);
}

.fi-gh {
  background-image: url(../flags/4x3/gh.svg);
}
.fi-gh.fis {
  background-image: url(../flags/1x1/gh.svg);
}

.fi-gi {
  background-image: url(../flags/4x3/gi.svg);
}
.fi-gi.fis {
  background-image: url(../flags/1x1/gi.svg);
}

.fi-gl {
  background-image: url(../flags/4x3/gl.svg);
}
.fi-gl.fis {
  background-image: url(../flags/1x1/gl.svg);
}

.fi-gm {
  background-image: url(../flags/4x3/gm.svg);
}
.fi-gm.fis {
  background-image: url(../flags/1x1/gm.svg);
}

.fi-gn {
  background-image: url(../flags/4x3/gn.svg);
}
.fi-gn.fis {
  background-image: url(../flags/1x1/gn.svg);
}

.fi-gp {
  background-image: url(../flags/4x3/gp.svg);
}
.fi-gp.fis {
  background-image: url(../flags/1x1/gp.svg);
}

.fi-gq {
  background-image: url(../flags/4x3/gq.svg);
}
.fi-gq.fis {
  background-image: url(../flags/1x1/gq.svg);
}

.fi-gr {
  background-image: url(../flags/4x3/gr.svg);
}
.fi-gr.fis {
  background-image: url(../flags/1x1/gr.svg);
}

.fi-gs {
  background-image: url(../flags/4x3/gs.svg);
}
.fi-gs.fis {
  background-image: url(../flags/1x1/gs.svg);
}

.fi-gt {
  background-image: url(../flags/4x3/gt.svg);
}
.fi-gt.fis {
  background-image: url(../flags/1x1/gt.svg);
}

.fi-gu {
  background-image: url(../flags/4x3/gu.svg);
}
.fi-gu.fis {
  background-image: url(../flags/1x1/gu.svg);
}

.fi-gw {
  background-image: url(../flags/4x3/gw.svg);
}
.fi-gw.fis {
  background-image: url(../flags/1x1/gw.svg);
}

.fi-gy {
  background-image: url(../flags/4x3/gy.svg);
}
.fi-gy.fis {
  background-image: url(../flags/1x1/gy.svg);
}

.fi-hk {
  background-image: url(../flags/4x3/hk.svg);
}
.fi-hk.fis {
  background-image: url(../flags/1x1/hk.svg);
}

.fi-hm {
  background-image: url(../flags/4x3/hm.svg);
}
.fi-hm.fis {
  background-image: url(../flags/1x1/hm.svg);
}

.fi-hn {
  background-image: url(../flags/4x3/hn.svg);
}
.fi-hn.fis {
  background-image: url(../flags/1x1/hn.svg);
}

.fi-hr {
  background-image: url(../flags/4x3/hr.svg);
}
.fi-hr.fis {
  background-image: url(../flags/1x1/hr.svg);
}

.fi-ht {
  background-image: url(../flags/4x3/ht.svg);
}
.fi-ht.fis {
  background-image: url(../flags/1x1/ht.svg);
}

.fi-hu {
  background-image: url(../flags/4x3/hu.svg);
}
.fi-hu.fis {
  background-image: url(../flags/1x1/hu.svg);
}

.fi-id {
  background-image: url(../flags/4x3/id.svg);
}
.fi-id.fis {
  background-image: url(../flags/1x1/id.svg);
}

.fi-ie {
  background-image: url(../flags/4x3/ie.svg);
}
.fi-ie.fis {
  background-image: url(../flags/1x1/ie.svg);
}

.fi-il {
  background-image: url(../flags/4x3/il.svg);
}
.fi-il.fis {
  background-image: url(../flags/1x1/il.svg);
}

.fi-im {
  background-image: url(../flags/4x3/im.svg);
}
.fi-im.fis {
  background-image: url(../flags/1x1/im.svg);
}

.fi-in {
  background-image: url(../flags/4x3/in.svg);
}
.fi-in.fis {
  background-image: url(../flags/1x1/in.svg);
}

.fi-io {
  background-image: url(../flags/4x3/io.svg);
}
.fi-io.fis {
  background-image: url(../flags/1x1/io.svg);
}

.fi-iq {
  background-image: url(../flags/4x3/iq.svg);
}
.fi-iq.fis {
  background-image: url(../flags/1x1/iq.svg);
}

.fi-ir {
  background-image: url(../flags/4x3/ir.svg);
}
.fi-ir.fis {
  background-image: url(../flags/1x1/ir.svg);
}

.fi-is {
  background-image: url(../flags/4x3/is.svg);
}
.fi-is.fis {
  background-image: url(../flags/1x1/is.svg);
}

.fi-it {
  background-image: url(../flags/4x3/it.svg);
}
.fi-it.fis {
  background-image: url(../flags/1x1/it.svg);
}

.fi-je {
  background-image: url(../flags/4x3/je.svg);
}
.fi-je.fis {
  background-image: url(../flags/1x1/je.svg);
}

.fi-jm {
  background-image: url(../flags/4x3/jm.svg);
}
.fi-jm.fis {
  background-image: url(../flags/1x1/jm.svg);
}

.fi-jo {
  background-image: url(../flags/4x3/jo.svg);
}
.fi-jo.fis {
  background-image: url(../flags/1x1/jo.svg);
}

.fi-jp {
  background-image: url(../flags/4x3/jp.svg);
}
.fi-jp.fis {
  background-image: url(../flags/1x1/jp.svg);
}

.fi-ke {
  background-image: url(../flags/4x3/ke.svg);
}
.fi-ke.fis {
  background-image: url(../flags/1x1/ke.svg);
}

.fi-kg {
  background-image: url(../flags/4x3/kg.svg);
}
.fi-kg.fis {
  background-image: url(../flags/1x1/kg.svg);
}

.fi-kh {
  background-image: url(../flags/4x3/kh.svg);
}
.fi-kh.fis {
  background-image: url(../flags/1x1/kh.svg);
}

.fi-ki {
  background-image: url(../flags/4x3/ki.svg);
}
.fi-ki.fis {
  background-image: url(../flags/1x1/ki.svg);
}

.fi-km {
  background-image: url(../flags/4x3/km.svg);
}
.fi-km.fis {
  background-image: url(../flags/1x1/km.svg);
}

.fi-kn {
  background-image: url(../flags/4x3/kn.svg);
}
.fi-kn.fis {
  background-image: url(../flags/1x1/kn.svg);
}

.fi-kp {
  background-image: url(../flags/4x3/kp.svg);
}
.fi-kp.fis {
  background-image: url(../flags/1x1/kp.svg);
}

.fi-kr {
  background-image: url(../flags/4x3/kr.svg);
}
.fi-kr.fis {
  background-image: url(../flags/1x1/kr.svg);
}

.fi-kw {
  background-image: url(../flags/4x3/kw.svg);
}
.fi-kw.fis {
  background-image: url(../flags/1x1/kw.svg);
}

.fi-ky {
  background-image: url(../flags/4x3/ky.svg);
}
.fi-ky.fis {
  background-image: url(../flags/1x1/ky.svg);
}

.fi-kz {
  background-image: url(../flags/4x3/kz.svg);
}
.fi-kz.fis {
  background-image: url(../flags/1x1/kz.svg);
}

.fi-la {
  background-image: url(../flags/4x3/la.svg);
}
.fi-la.fis {
  background-image: url(../flags/1x1/la.svg);
}

.fi-lb {
  background-image: url(../flags/4x3/lb.svg);
}
.fi-lb.fis {
  background-image: url(../flags/1x1/lb.svg);
}

.fi-lc {
  background-image: url(../flags/4x3/lc.svg);
}
.fi-lc.fis {
  background-image: url(../flags/1x1/lc.svg);
}

.fi-li {
  background-image: url(../flags/4x3/li.svg);
}
.fi-li.fis {
  background-image: url(../flags/1x1/li.svg);
}

.fi-lk {
  background-image: url(../flags/4x3/lk.svg);
}
.fi-lk.fis {
  background-image: url(../flags/1x1/lk.svg);
}

.fi-lr {
  background-image: url(../flags/4x3/lr.svg);
}
.fi-lr.fis {
  background-image: url(../flags/1x1/lr.svg);
}

.fi-ls {
  background-image: url(../flags/4x3/ls.svg);
}
.fi-ls.fis {
  background-image: url(../flags/1x1/ls.svg);
}

.fi-lt {
  background-image: url(../flags/4x3/lt.svg);
}
.fi-lt.fis {
  background-image: url(../flags/1x1/lt.svg);
}

.fi-lu {
  background-image: url(../flags/4x3/lu.svg);
}
.fi-lu.fis {
  background-image: url(../flags/1x1/lu.svg);
}

.fi-lv {
  background-image: url(../flags/4x3/lv.svg);
}
.fi-lv.fis {
  background-image: url(../flags/1x1/lv.svg);
}

.fi-ly {
  background-image: url(../flags/4x3/ly.svg);
}
.fi-ly.fis {
  background-image: url(../flags/1x1/ly.svg);
}

.fi-ma {
  background-image: url(../flags/4x3/ma.svg);
}
.fi-ma.fis {
  background-image: url(../flags/1x1/ma.svg);
}

.fi-mc {
  background-image: url(../flags/4x3/mc.svg);
}
.fi-mc.fis {
  background-image: url(../flags/1x1/mc.svg);
}

.fi-md {
  background-image: url(../flags/4x3/md.svg);
}
.fi-md.fis {
  background-image: url(../flags/1x1/md.svg);
}

.fi-me {
  background-image: url(../flags/4x3/me.svg);
}
.fi-me.fis {
  background-image: url(../flags/1x1/me.svg);
}

.fi-mf {
  background-image: url(../flags/4x3/mf.svg);
}
.fi-mf.fis {
  background-image: url(../flags/1x1/mf.svg);
}

.fi-mg {
  background-image: url(../flags/4x3/mg.svg);
}
.fi-mg.fis {
  background-image: url(../flags/1x1/mg.svg);
}

.fi-mh {
  background-image: url(../flags/4x3/mh.svg);
}
.fi-mh.fis {
  background-image: url(../flags/1x1/mh.svg);
}

.fi-mk {
  background-image: url(../flags/4x3/mk.svg);
}
.fi-mk.fis {
  background-image: url(../flags/1x1/mk.svg);
}

.fi-ml {
  background-image: url(../flags/4x3/ml.svg);
}
.fi-ml.fis {
  background-image: url(../flags/1x1/ml.svg);
}

.fi-mm {
  background-image: url(../flags/4x3/mm.svg);
}
.fi-mm.fis {
  background-image: url(../flags/1x1/mm.svg);
}

.fi-mn {
  background-image: url(../flags/4x3/mn.svg);
}
.fi-mn.fis {
  background-image: url(../flags/1x1/mn.svg);
}

.fi-mo {
  background-image: url(../flags/4x3/mo.svg);
}
.fi-mo.fis {
  background-image: url(../flags/1x1/mo.svg);
}

.fi-mp {
  background-image: url(../flags/4x3/mp.svg);
}
.fi-mp.fis {
  background-image: url(../flags/1x1/mp.svg);
}

.fi-mq {
  background-image: url(../flags/4x3/mq.svg);
}
.fi-mq.fis {
  background-image: url(../flags/1x1/mq.svg);
}

.fi-mr {
  background-image: url(../flags/4x3/mr.svg);
}
.fi-mr.fis {
  background-image: url(../flags/1x1/mr.svg);
}

.fi-ms {
  background-image: url(../flags/4x3/ms.svg);
}
.fi-ms.fis {
  background-image: url(../flags/1x1/ms.svg);
}

.fi-mt {
  background-image: url(../flags/4x3/mt.svg);
}
.fi-mt.fis {
  background-image: url(../flags/1x1/mt.svg);
}

.fi-mu {
  background-image: url(../flags/4x3/mu.svg);
}
.fi-mu.fis {
  background-image: url(../flags/1x1/mu.svg);
}

.fi-mv {
  background-image: url(../flags/4x3/mv.svg);
}
.fi-mv.fis {
  background-image: url(../flags/1x1/mv.svg);
}

.fi-mw {
  background-image: url(../flags/4x3/mw.svg);
}
.fi-mw.fis {
  background-image: url(../flags/1x1/mw.svg);
}

.fi-mx {
  background-image: url(../flags/4x3/mx.svg);
}
.fi-mx.fis {
  background-image: url(../flags/1x1/mx.svg);
}

.fi-my {
  background-image: url(../flags/4x3/my.svg);
}
.fi-my.fis {
  background-image: url(../flags/1x1/my.svg);
}

.fi-mz {
  background-image: url(../flags/4x3/mz.svg);
}
.fi-mz.fis {
  background-image: url(../flags/1x1/mz.svg);
}

.fi-na {
  background-image: url(../flags/4x3/na.svg);
}
.fi-na.fis {
  background-image: url(../flags/1x1/na.svg);
}

.fi-nc {
  background-image: url(../flags/4x3/nc.svg);
}
.fi-nc.fis {
  background-image: url(../flags/1x1/nc.svg);
}

.fi-ne {
  background-image: url(../flags/4x3/ne.svg);
}
.fi-ne.fis {
  background-image: url(../flags/1x1/ne.svg);
}

.fi-nf {
  background-image: url(../flags/4x3/nf.svg);
}
.fi-nf.fis {
  background-image: url(../flags/1x1/nf.svg);
}

.fi-ng {
  background-image: url(../flags/4x3/ng.svg);
}
.fi-ng.fis {
  background-image: url(../flags/1x1/ng.svg);
}

.fi-ni {
  background-image: url(../flags/4x3/ni.svg);
}
.fi-ni.fis {
  background-image: url(../flags/1x1/ni.svg);
}

.fi-nl {
  background-image: url(../flags/4x3/nl.svg);
}
.fi-nl.fis {
  background-image: url(../flags/1x1/nl.svg);
}

.fi-no {
  background-image: url(../flags/4x3/no.svg);
}
.fi-no.fis {
  background-image: url(../flags/1x1/no.svg);
}

.fi-np {
  background-image: url(../flags/4x3/np.svg);
}
.fi-np.fis {
  background-image: url(../flags/1x1/np.svg);
}

.fi-nr {
  background-image: url(../flags/4x3/nr.svg);
}
.fi-nr.fis {
  background-image: url(../flags/1x1/nr.svg);
}

.fi-nu {
  background-image: url(../flags/4x3/nu.svg);
}
.fi-nu.fis {
  background-image: url(../flags/1x1/nu.svg);
}

.fi-nz {
  background-image: url(../flags/4x3/nz.svg);
}
.fi-nz.fis {
  background-image: url(../flags/1x1/nz.svg);
}

.fi-om {
  background-image: url(../flags/4x3/om.svg);
}
.fi-om.fis {
  background-image: url(../flags/1x1/om.svg);
}

.fi-pa {
  background-image: url(../flags/4x3/pa.svg);
}
.fi-pa.fis {
  background-image: url(../flags/1x1/pa.svg);
}

.fi-pe {
  background-image: url(../flags/4x3/pe.svg);
}
.fi-pe.fis {
  background-image: url(../flags/1x1/pe.svg);
}

.fi-pf {
  background-image: url(../flags/4x3/pf.svg);
}
.fi-pf.fis {
  background-image: url(../flags/1x1/pf.svg);
}

.fi-pg {
  background-image: url(../flags/4x3/pg.svg);
}
.fi-pg.fis {
  background-image: url(../flags/1x1/pg.svg);
}

.fi-ph {
  background-image: url(../flags/4x3/ph.svg);
}
.fi-ph.fis {
  background-image: url(../flags/1x1/ph.svg);
}

.fi-pk {
  background-image: url(../flags/4x3/pk.svg);
}
.fi-pk.fis {
  background-image: url(../flags/1x1/pk.svg);
}

.fi-pl {
  background-image: url(../flags/4x3/pl.svg);
}
.fi-pl.fis {
  background-image: url(../flags/1x1/pl.svg);
}

.fi-pm {
  background-image: url(../flags/4x3/pm.svg);
}
.fi-pm.fis {
  background-image: url(../flags/1x1/pm.svg);
}

.fi-pn {
  background-image: url(../flags/4x3/pn.svg);
}
.fi-pn.fis {
  background-image: url(../flags/1x1/pn.svg);
}

.fi-pr {
  background-image: url(../flags/4x3/pr.svg);
}
.fi-pr.fis {
  background-image: url(../flags/1x1/pr.svg);
}

.fi-ps {
  background-image: url(../flags/4x3/ps.svg);
}
.fi-ps.fis {
  background-image: url(../flags/1x1/ps.svg);
}

.fi-pt {
  background-image: url(../flags/4x3/pt.svg);
}
.fi-pt.fis {
  background-image: url(../flags/1x1/pt.svg);
}

.fi-pw {
  background-image: url(../flags/4x3/pw.svg);
}
.fi-pw.fis {
  background-image: url(../flags/1x1/pw.svg);
}

.fi-py {
  background-image: url(../flags/4x3/py.svg);
}
.fi-py.fis {
  background-image: url(../flags/1x1/py.svg);
}

.fi-qa {
  background-image: url(../flags/4x3/qa.svg);
}
.fi-qa.fis {
  background-image: url(../flags/1x1/qa.svg);
}

.fi-re {
  background-image: url(../flags/4x3/re.svg);
}
.fi-re.fis {
  background-image: url(../flags/1x1/re.svg);
}

.fi-ro {
  background-image: url(../flags/4x3/ro.svg);
}
.fi-ro.fis {
  background-image: url(../flags/1x1/ro.svg);
}

.fi-rs {
  background-image: url(../flags/4x3/rs.svg);
}
.fi-rs.fis {
  background-image: url(../flags/1x1/rs.svg);
}

.fi-ru {
  background-image: url(../flags/4x3/ru.svg);
}
.fi-ru.fis {
  background-image: url(../flags/1x1/ru.svg);
}

.fi-rw {
  background-image: url(../flags/4x3/rw.svg);
}
.fi-rw.fis {
  background-image: url(../flags/1x1/rw.svg);
}

.fi-sa {
  background-image: url(../flags/4x3/sa.svg);
}
.fi-sa.fis {
  background-image: url(../flags/1x1/sa.svg);
}

.fi-sb {
  background-image: url(../flags/4x3/sb.svg);
}
.fi-sb.fis {
  background-image: url(../flags/1x1/sb.svg);
}

.fi-sc {
  background-image: url(../flags/4x3/sc.svg);
}
.fi-sc.fis {
  background-image: url(../flags/1x1/sc.svg);
}

.fi-sd {
  background-image: url(../flags/4x3/sd.svg);
}
.fi-sd.fis {
  background-image: url(../flags/1x1/sd.svg);
}

.fi-se {
  background-image: url(../flags/4x3/se.svg);
}
.fi-se.fis {
  background-image: url(../flags/1x1/se.svg);
}

.fi-sg {
  background-image: url(../flags/4x3/sg.svg);
}
.fi-sg.fis {
  background-image: url(../flags/1x1/sg.svg);
}

.fi-sh {
  background-image: url(../flags/4x3/sh.svg);
}
.fi-sh.fis {
  background-image: url(../flags/1x1/sh.svg);
}

.fi-si {
  background-image: url(../flags/4x3/si.svg);
}
.fi-si.fis {
  background-image: url(../flags/1x1/si.svg);
}

.fi-sj {
  background-image: url(../flags/4x3/sj.svg);
}
.fi-sj.fis {
  background-image: url(../flags/1x1/sj.svg);
}

.fi-sk {
  background-image: url(../flags/4x3/sk.svg);
}
.fi-sk.fis {
  background-image: url(../flags/1x1/sk.svg);
}

.fi-sl {
  background-image: url(../flags/4x3/sl.svg);
}
.fi-sl.fis {
  background-image: url(../flags/1x1/sl.svg);
}

.fi-sm {
  background-image: url(../flags/4x3/sm.svg);
}
.fi-sm.fis {
  background-image: url(../flags/1x1/sm.svg);
}

.fi-sn {
  background-image: url(../flags/4x3/sn.svg);
}
.fi-sn.fis {
  background-image: url(../flags/1x1/sn.svg);
}

.fi-so {
  background-image: url(../flags/4x3/so.svg);
}
.fi-so.fis {
  background-image: url(../flags/1x1/so.svg);
}

.fi-sr {
  background-image: url(../flags/4x3/sr.svg);
}
.fi-sr.fis {
  background-image: url(../flags/1x1/sr.svg);
}

.fi-ss {
  background-image: url(../flags/4x3/ss.svg);
}
.fi-ss.fis {
  background-image: url(../flags/1x1/ss.svg);
}

.fi-st {
  background-image: url(../flags/4x3/st.svg);
}
.fi-st.fis {
  background-image: url(../flags/1x1/st.svg);
}

.fi-sv {
  background-image: url(../flags/4x3/sv.svg);
}
.fi-sv.fis {
  background-image: url(../flags/1x1/sv.svg);
}

.fi-sx {
  background-image: url(../flags/4x3/sx.svg);
}
.fi-sx.fis {
  background-image: url(../flags/1x1/sx.svg);
}

.fi-sy {
  background-image: url(../flags/4x3/sy.svg);
}
.fi-sy.fis {
  background-image: url(../flags/1x1/sy.svg);
}

.fi-sz {
  background-image: url(../flags/4x3/sz.svg);
}
.fi-sz.fis {
  background-image: url(../flags/1x1/sz.svg);
}

.fi-tc {
  background-image: url(../flags/4x3/tc.svg);
}
.fi-tc.fis {
  background-image: url(../flags/1x1/tc.svg);
}

.fi-td {
  background-image: url(../flags/4x3/td.svg);
}
.fi-td.fis {
  background-image: url(../flags/1x1/td.svg);
}

.fi-tf {
  background-image: url(../flags/4x3/tf.svg);
}
.fi-tf.fis {
  background-image: url(../flags/1x1/tf.svg);
}

.fi-tg {
  background-image: url(../flags/4x3/tg.svg);
}
.fi-tg.fis {
  background-image: url(../flags/1x1/tg.svg);
}

.fi-th {
  background-image: url(../flags/4x3/th.svg);
}
.fi-th.fis {
  background-image: url(../flags/1x1/th.svg);
}

.fi-tj {
  background-image: url(../flags/4x3/tj.svg);
}
.fi-tj.fis {
  background-image: url(../flags/1x1/tj.svg);
}

.fi-tk {
  background-image: url(../flags/4x3/tk.svg);
}
.fi-tk.fis {
  background-image: url(../flags/1x1/tk.svg);
}

.fi-tl {
  background-image: url(../flags/4x3/tl.svg);
}
.fi-tl.fis {
  background-image: url(../flags/1x1/tl.svg);
}

.fi-tm {
  background-image: url(../flags/4x3/tm.svg);
}
.fi-tm.fis {
  background-image: url(../flags/1x1/tm.svg);
}

.fi-tn {
  background-image: url(../flags/4x3/tn.svg);
}
.fi-tn.fis {
  background-image: url(../flags/1x1/tn.svg);
}

.fi-to {
  background-image: url(../flags/4x3/to.svg);
}
.fi-to.fis {
  background-image: url(../flags/1x1/to.svg);
}

.fi-tr {
  background-image: url(../flags/4x3/tr.svg);
}
.fi-tr.fis {
  background-image: url(../flags/1x1/tr.svg);
}

.fi-tt {
  background-image: url(../flags/4x3/tt.svg);
}
.fi-tt.fis {
  background-image: url(../flags/1x1/tt.svg);
}

.fi-tv {
  background-image: url(../flags/4x3/tv.svg);
}
.fi-tv.fis {
  background-image: url(../flags/1x1/tv.svg);
}

.fi-tw {
  background-image: url(../flags/4x3/tw.svg);
}
.fi-tw.fis {
  background-image: url(../flags/1x1/tw.svg);
}

.fi-tz {
  background-image: url(../flags/4x3/tz.svg);
}
.fi-tz.fis {
  background-image: url(../flags/1x1/tz.svg);
}

.fi-ua {
  background-image: url(../flags/4x3/ua.svg);
}
.fi-ua.fis {
  background-image: url(../flags/1x1/ua.svg);
}

.fi-ug {
  background-image: url(../flags/4x3/ug.svg);
}
.fi-ug.fis {
  background-image: url(../flags/1x1/ug.svg);
}

.fi-um {
  background-image: url(../flags/4x3/um.svg);
}
.fi-um.fis {
  background-image: url(../flags/1x1/um.svg);
}

.fi-us {
  background-image: url(../flags/4x3/us.svg);
}
.fi-us.fis {
  background-image: url(../flags/1x1/us.svg);
}

.fi-uy {
  background-image: url(../flags/4x3/uy.svg);
}
.fi-uy.fis {
  background-image: url(../flags/1x1/uy.svg);
}

.fi-uz {
  background-image: url(../flags/4x3/uz.svg);
}
.fi-uz.fis {
  background-image: url(../flags/1x1/uz.svg);
}

.fi-va {
  background-image: url(../flags/4x3/va.svg);
}
.fi-va.fis {
  background-image: url(../flags/1x1/va.svg);
}

.fi-vc {
  background-image: url(../flags/4x3/vc.svg);
}
.fi-vc.fis {
  background-image: url(../flags/1x1/vc.svg);
}

.fi-ve {
  background-image: url(../flags/4x3/ve.svg);
}
.fi-ve.fis {
  background-image: url(../flags/1x1/ve.svg);
}

.fi-vg {
  background-image: url(../flags/4x3/vg.svg);
}
.fi-vg.fis {
  background-image: url(../flags/1x1/vg.svg);
}

.fi-vi {
  background-image: url(../flags/4x3/vi.svg);
}
.fi-vi.fis {
  background-image: url(../flags/1x1/vi.svg);
}

.fi-vn {
  background-image: url(../flags/4x3/vn.svg);
}
.fi-vn.fis {
  background-image: url(../flags/1x1/vn.svg);
}

.fi-vu {
  background-image: url(../flags/4x3/vu.svg);
}
.fi-vu.fis {
  background-image: url(../flags/1x1/vu.svg);
}

.fi-wf {
  background-image: url(../flags/4x3/wf.svg);
}
.fi-wf.fis {
  background-image: url(../flags/1x1/wf.svg);
}

.fi-ws {
  background-image: url(../flags/4x3/ws.svg);
}
.fi-ws.fis {
  background-image: url(../flags/1x1/ws.svg);
}

.fi-ye {
  background-image: url(../flags/4x3/ye.svg);
}
.fi-ye.fis {
  background-image: url(../flags/1x1/ye.svg);
}

.fi-yt {
  background-image: url(../flags/4x3/yt.svg);
}
.fi-yt.fis {
  background-image: url(../flags/1x1/yt.svg);
}

.fi-za {
  background-image: url(../flags/4x3/za.svg);
}
.fi-za.fis {
  background-image: url(../flags/1x1/za.svg);
}

.fi-zm {
  background-image: url(../flags/4x3/zm.svg);
}
.fi-zm.fis {
  background-image: url(../flags/1x1/zm.svg);
}

.fi-zw {
  background-image: url(../flags/4x3/zw.svg);
}
.fi-zw.fis {
  background-image: url(../flags/1x1/zw.svg);
}

.fi-ac {
  background-image: url(../flags/4x3/ac.svg);
}
.fi-ac.fis {
  background-image: url(../flags/1x1/ac.svg);
}

.fi-cp {
  background-image: url(../flags/4x3/cp.svg);
}
.fi-cp.fis {
  background-image: url(../flags/1x1/cp.svg);
}

.fi-dg {
  background-image: url(../flags/4x3/dg.svg);
}
.fi-dg.fis {
  background-image: url(../flags/1x1/dg.svg);
}

.fi-ea {
  background-image: url(../flags/4x3/ea.svg);
}
.fi-ea.fis {
  background-image: url(../flags/1x1/ea.svg);
}

.fi-es-ct {
  background-image: url(../flags/4x3/es-ct.svg);
}
.fi-es-ct.fis {
  background-image: url(../flags/1x1/es-ct.svg);
}

.fi-es-ga {
  background-image: url(../flags/4x3/es-ga.svg);
}
.fi-es-ga.fis {
  background-image: url(../flags/1x1/es-ga.svg);
}

.fi-eu {
  background-image: url(../flags/4x3/eu.svg);
}
.fi-eu.fis {
  background-image: url(../flags/1x1/eu.svg);
}

.fi-gb-eng {
  background-image: url(../flags/4x3/gb-eng.svg);
}
.fi-gb-eng.fis {
  background-image: url(../flags/1x1/gb-eng.svg);
}

.fi-gb-nir {
  background-image: url(../flags/4x3/gb-nir.svg);
}
.fi-gb-nir.fis {
  background-image: url(../flags/1x1/gb-nir.svg);
}

.fi-gb-sct {
  background-image: url(../flags/4x3/gb-sct.svg);
}
.fi-gb-sct.fis {
  background-image: url(../flags/1x1/gb-sct.svg);
}

.fi-gb-wls {
  background-image: url(../flags/4x3/gb-wls.svg);
}
.fi-gb-wls.fis {
  background-image: url(../flags/1x1/gb-wls.svg);
}

.fi-ic {
  background-image: url(../flags/4x3/ic.svg);
}
.fi-ic.fis {
  background-image: url(../flags/1x1/ic.svg);
}

.fi-ta {
  background-image: url(../flags/4x3/ta.svg);
}
.fi-ta.fis {
  background-image: url(../flags/1x1/ta.svg);
}

.fi-un {
  background-image: url(../flags/4x3/un.svg);
}
.fi-un.fis {
  background-image: url(../flags/1x1/un.svg);
}

.fi-xk {
  background-image: url(../flags/4x3/xk.svg);
}
.fi-xk.fis {
  background-image: url(../flags/1x1/xk.svg);
}

.fi {
  box-shadow: 0 0 1px 1px rgba(0, 0, 0, 0.2);
  background-color: rgba(0, 0, 0, 0.2);
}

.shimmer {
  animation: shimmer 2s infinite;
  background: linear-gradient(90deg, #ffe5cc 0%, #fddbbb 20%, #ffe5cc 40%, #ffe5cc 100%);
  background-size: 1000px 100%;
}

@keyframes shimmer {
  0% {
    background-position: -1000px 0;
  }
  100% {
    background-position: 1000px 0;
  }
}
.divider {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.divider::before {
  content: "";
  align-self: stretch;
  border: 1px solid #e8eaed;
}

.row {
  --bs-gutter-x: 1rem;
  --bs-gutter-y: 0;
  display: flex;
  flex-wrap: wrap;
  margin-top: calc(-1 * var(--bs-gutter-y));
  margin-right: calc(-0.5 * var(--bs-gutter-x));
  margin-left: calc(-0.5 * var(--bs-gutter-x));
}
.row > * {
  flex-shrink: 0;
  width: 100%;
  max-width: 100%;
  padding-right: calc(var(--bs-gutter-x) * 0.5);
  padding-left: calc(var(--bs-gutter-x) * 0.5);
  margin-top: var(--bs-gutter-y);
}

.col {
  flex: 1 0 0%;
}

.row-cols-auto > * {
  flex: 0 0 auto;
  width: auto;
}

.row-cols-1 > * {
  flex: 0 0 auto;
  width: 100%;
}

.row-cols-2 > * {
  flex: 0 0 auto;
  width: 50%;
}

.row-cols-3 > * {
  flex: 0 0 auto;
  width: 33.3333333333%;
}

.row-cols-4 > * {
  flex: 0 0 auto;
  width: 25%;
}

.row-cols-5 > * {
  flex: 0 0 auto;
  width: 20%;
}

.row-cols-6 > * {
  flex: 0 0 auto;
  width: 16.6666666667%;
}

.col-auto {
  flex: 0 0 auto;
  width: auto;
}

.col-1 {
  flex: 0 0 auto;
  width: 8.33333333%;
}

.col-2 {
  flex: 0 0 auto;
  width: 16.66666667%;
}

.col-3 {
  flex: 0 0 auto;
  width: 25%;
}

.col-4 {
  flex: 0 0 auto;
  width: 33.33333333%;
}

.col-5 {
  flex: 0 0 auto;
  width: 41.66666667%;
}

.col-6 {
  flex: 0 0 auto;
  width: 50%;
}

.col-7 {
  flex: 0 0 auto;
  width: 58.33333333%;
}

.col-8 {
  flex: 0 0 auto;
  width: 66.66666667%;
}

.col-9 {
  flex: 0 0 auto;
  width: 75%;
}

.col-10 {
  flex: 0 0 auto;
  width: 83.33333333%;
}

.col-11 {
  flex: 0 0 auto;
  width: 91.66666667%;
}

.col-12 {
  flex: 0 0 auto;
  width: 100%;
}

.offset-1 {
  margin-left: 8.33333333%;
}

.offset-2 {
  margin-left: 16.66666667%;
}

.offset-3 {
  margin-left: 25%;
}

.offset-4 {
  margin-left: 33.33333333%;
}

.offset-5 {
  margin-left: 41.66666667%;
}

.offset-6 {
  margin-left: 50%;
}

.offset-7 {
  margin-left: 58.33333333%;
}

.offset-8 {
  margin-left: 66.66666667%;
}

.offset-9 {
  margin-left: 75%;
}

.offset-10 {
  margin-left: 83.33333333%;
}

.offset-11 {
  margin-left: 91.66666667%;
}

.g-0,
.gx-0 {
  --bs-gutter-x: 0;
}

.g-0,
.gy-0 {
  --bs-gutter-y: 0;
}

.g-0-5,
.gx-0-5 {
  --bs-gutter-x: 0.25rem;
}

.g-0-5,
.gy-0-5 {
  --bs-gutter-y: 0.25rem;
}

.g-1,
.gx-1 {
  --bs-gutter-x: 0.5rem;
}

.g-1,
.gy-1 {
  --bs-gutter-y: 0.5rem;
}

.g-1-5,
.gx-1-5 {
  --bs-gutter-x: 0.75rem;
}

.g-1-5,
.gy-1-5 {
  --bs-gutter-y: 0.75rem;
}

.g-2,
.gx-2 {
  --bs-gutter-x: 1rem;
}

.g-2,
.gy-2 {
  --bs-gutter-y: 1rem;
}

.g-2-5,
.gx-2-5 {
  --bs-gutter-x: 1.25rem;
}

.g-2-5,
.gy-2-5 {
  --bs-gutter-y: 1.25rem;
}

.g-3,
.gx-3 {
  --bs-gutter-x: 1.5rem;
}

.g-3,
.gy-3 {
  --bs-gutter-y: 1.5rem;
}

.g-3-5,
.gx-3-5 {
  --bs-gutter-x: 1.75rem;
}

.g-3-5,
.gy-3-5 {
  --bs-gutter-y: 1.75rem;
}

.g-4,
.gx-4 {
  --bs-gutter-x: 2rem;
}

.g-4,
.gy-4 {
  --bs-gutter-y: 2rem;
}

.g-5,
.gx-5 {
  --bs-gutter-x: 2.5rem;
}

.g-5,
.gy-5 {
  --bs-gutter-y: 2.5rem;
}

.g-5-5,
.gx-5-5 {
  --bs-gutter-x: 2.75rem;
}

.g-5-5,
.gy-5-5 {
  --bs-gutter-y: 2.75rem;
}

.g-6,
.gx-6 {
  --bs-gutter-x: 3rem;
}

.g-6,
.gy-6 {
  --bs-gutter-y: 3rem;
}

.g-7,
.gx-7 {
  --bs-gutter-x: 3.5rem;
}

.g-7,
.gy-7 {
  --bs-gutter-y: 3.5rem;
}

.g-7-5,
.gx-7-5 {
  --bs-gutter-x: 3.75rem;
}

.g-7-5,
.gy-7-5 {
  --bs-gutter-y: 3.75rem;
}

.g-8,
.gx-8 {
  --bs-gutter-x: 4rem;
}

.g-8,
.gy-8 {
  --bs-gutter-y: 4rem;
}

.g-9,
.gx-9 {
  --bs-gutter-x: 4.5rem;
}

.g-9,
.gy-9 {
  --bs-gutter-y: 4.5rem;
}

.g-9-5,
.gx-9-5 {
  --bs-gutter-x: 4.75rem;
}

.g-9-5,
.gy-9-5 {
  --bs-gutter-y: 4.75rem;
}

.g-10,
.gx-10 {
  --bs-gutter-x: 5rem;
}

.g-10,
.gy-10 {
  --bs-gutter-y: 5rem;
}

.g-auto,
.gx-auto {
  --bs-gutter-x: auto;
}

.g-auto,
.gy-auto {
  --bs-gutter-y: auto;
}

@media (min-width: 576px) {
  .col-sm {
    flex: 1 0 0%;
  }
  .row-cols-sm-auto > * {
    flex: 0 0 auto;
    width: auto;
  }
  .row-cols-sm-1 > * {
    flex: 0 0 auto;
    width: 100%;
  }
  .row-cols-sm-2 > * {
    flex: 0 0 auto;
    width: 50%;
  }
  .row-cols-sm-3 > * {
    flex: 0 0 auto;
    width: 33.3333333333%;
  }
  .row-cols-sm-4 > * {
    flex: 0 0 auto;
    width: 25%;
  }
  .row-cols-sm-5 > * {
    flex: 0 0 auto;
    width: 20%;
  }
  .row-cols-sm-6 > * {
    flex: 0 0 auto;
    width: 16.6666666667%;
  }
  .col-sm-auto {
    flex: 0 0 auto;
    width: auto;
  }
  .col-sm-1 {
    flex: 0 0 auto;
    width: 8.33333333%;
  }
  .col-sm-2 {
    flex: 0 0 auto;
    width: 16.66666667%;
  }
  .col-sm-3 {
    flex: 0 0 auto;
    width: 25%;
  }
  .col-sm-4 {
    flex: 0 0 auto;
    width: 33.33333333%;
  }
  .col-sm-5 {
    flex: 0 0 auto;
    width: 41.66666667%;
  }
  .col-sm-6 {
    flex: 0 0 auto;
    width: 50%;
  }
  .col-sm-7 {
    flex: 0 0 auto;
    width: 58.33333333%;
  }
  .col-sm-8 {
    flex: 0 0 auto;
    width: 66.66666667%;
  }
  .col-sm-9 {
    flex: 0 0 auto;
    width: 75%;
  }
  .col-sm-10 {
    flex: 0 0 auto;
    width: 83.33333333%;
  }
  .col-sm-11 {
    flex: 0 0 auto;
    width: 91.66666667%;
  }
  .col-sm-12 {
    flex: 0 0 auto;
    width: 100%;
  }
  .offset-sm-0 {
    margin-left: 0;
  }
  .offset-sm-1 {
    margin-left: 8.33333333%;
  }
  .offset-sm-2 {
    margin-left: 16.66666667%;
  }
  .offset-sm-3 {
    margin-left: 25%;
  }
  .offset-sm-4 {
    margin-left: 33.33333333%;
  }
  .offset-sm-5 {
    margin-left: 41.66666667%;
  }
  .offset-sm-6 {
    margin-left: 50%;
  }
  .offset-sm-7 {
    margin-left: 58.33333333%;
  }
  .offset-sm-8 {
    margin-left: 66.66666667%;
  }
  .offset-sm-9 {
    margin-left: 75%;
  }
  .offset-sm-10 {
    margin-left: 83.33333333%;
  }
  .offset-sm-11 {
    margin-left: 91.66666667%;
  }
  .g-sm-0,
  .gx-sm-0 {
    --bs-gutter-x: 0;
  }
  .g-sm-0,
  .gy-sm-0 {
    --bs-gutter-y: 0;
  }
  .g-sm-0-5,
  .gx-sm-0-5 {
    --bs-gutter-x: 0.25rem;
  }
  .g-sm-0-5,
  .gy-sm-0-5 {
    --bs-gutter-y: 0.25rem;
  }
  .g-sm-1,
  .gx-sm-1 {
    --bs-gutter-x: 0.5rem;
  }
  .g-sm-1,
  .gy-sm-1 {
    --bs-gutter-y: 0.5rem;
  }
  .g-sm-1-5,
  .gx-sm-1-5 {
    --bs-gutter-x: 0.75rem;
  }
  .g-sm-1-5,
  .gy-sm-1-5 {
    --bs-gutter-y: 0.75rem;
  }
  .g-sm-2,
  .gx-sm-2 {
    --bs-gutter-x: 1rem;
  }
  .g-sm-2,
  .gy-sm-2 {
    --bs-gutter-y: 1rem;
  }
  .g-sm-2-5,
  .gx-sm-2-5 {
    --bs-gutter-x: 1.25rem;
  }
  .g-sm-2-5,
  .gy-sm-2-5 {
    --bs-gutter-y: 1.25rem;
  }
  .g-sm-3,
  .gx-sm-3 {
    --bs-gutter-x: 1.5rem;
  }
  .g-sm-3,
  .gy-sm-3 {
    --bs-gutter-y: 1.5rem;
  }
  .g-sm-3-5,
  .gx-sm-3-5 {
    --bs-gutter-x: 1.75rem;
  }
  .g-sm-3-5,
  .gy-sm-3-5 {
    --bs-gutter-y: 1.75rem;
  }
  .g-sm-4,
  .gx-sm-4 {
    --bs-gutter-x: 2rem;
  }
  .g-sm-4,
  .gy-sm-4 {
    --bs-gutter-y: 2rem;
  }
  .g-sm-5,
  .gx-sm-5 {
    --bs-gutter-x: 2.5rem;
  }
  .g-sm-5,
  .gy-sm-5 {
    --bs-gutter-y: 2.5rem;
  }
  .g-sm-5-5,
  .gx-sm-5-5 {
    --bs-gutter-x: 2.75rem;
  }
  .g-sm-5-5,
  .gy-sm-5-5 {
    --bs-gutter-y: 2.75rem;
  }
  .g-sm-6,
  .gx-sm-6 {
    --bs-gutter-x: 3rem;
  }
  .g-sm-6,
  .gy-sm-6 {
    --bs-gutter-y: 3rem;
  }
  .g-sm-7,
  .gx-sm-7 {
    --bs-gutter-x: 3.5rem;
  }
  .g-sm-7,
  .gy-sm-7 {
    --bs-gutter-y: 3.5rem;
  }
  .g-sm-7-5,
  .gx-sm-7-5 {
    --bs-gutter-x: 3.75rem;
  }
  .g-sm-7-5,
  .gy-sm-7-5 {
    --bs-gutter-y: 3.75rem;
  }
  .g-sm-8,
  .gx-sm-8 {
    --bs-gutter-x: 4rem;
  }
  .g-sm-8,
  .gy-sm-8 {
    --bs-gutter-y: 4rem;
  }
  .g-sm-9,
  .gx-sm-9 {
    --bs-gutter-x: 4.5rem;
  }
  .g-sm-9,
  .gy-sm-9 {
    --bs-gutter-y: 4.5rem;
  }
  .g-sm-9-5,
  .gx-sm-9-5 {
    --bs-gutter-x: 4.75rem;
  }
  .g-sm-9-5,
  .gy-sm-9-5 {
    --bs-gutter-y: 4.75rem;
  }
  .g-sm-10,
  .gx-sm-10 {
    --bs-gutter-x: 5rem;
  }
  .g-sm-10,
  .gy-sm-10 {
    --bs-gutter-y: 5rem;
  }
  .g-sm-auto,
  .gx-sm-auto {
    --bs-gutter-x: auto;
  }
  .g-sm-auto,
  .gy-sm-auto {
    --bs-gutter-y: auto;
  }
}
@media (min-width: 768px) {
  .col-md {
    flex: 1 0 0%;
  }
  .row-cols-md-auto > * {
    flex: 0 0 auto;
    width: auto;
  }
  .row-cols-md-1 > * {
    flex: 0 0 auto;
    width: 100%;
  }
  .row-cols-md-2 > * {
    flex: 0 0 auto;
    width: 50%;
  }
  .row-cols-md-3 > * {
    flex: 0 0 auto;
    width: 33.3333333333%;
  }
  .row-cols-md-4 > * {
    flex: 0 0 auto;
    width: 25%;
  }
  .row-cols-md-5 > * {
    flex: 0 0 auto;
    width: 20%;
  }
  .row-cols-md-6 > * {
    flex: 0 0 auto;
    width: 16.6666666667%;
  }
  .col-md-auto {
    flex: 0 0 auto;
    width: auto;
  }
  .col-md-1 {
    flex: 0 0 auto;
    width: 8.33333333%;
  }
  .col-md-2 {
    flex: 0 0 auto;
    width: 16.66666667%;
  }
  .col-md-3 {
    flex: 0 0 auto;
    width: 25%;
  }
  .col-md-4 {
    flex: 0 0 auto;
    width: 33.33333333%;
  }
  .col-md-5 {
    flex: 0 0 auto;
    width: 41.66666667%;
  }
  .col-md-6 {
    flex: 0 0 auto;
    width: 50%;
  }
  .col-md-7 {
    flex: 0 0 auto;
    width: 58.33333333%;
  }
  .col-md-8 {
    flex: 0 0 auto;
    width: 66.66666667%;
  }
  .col-md-9 {
    flex: 0 0 auto;
    width: 75%;
  }
  .col-md-10 {
    flex: 0 0 auto;
    width: 83.33333333%;
  }
  .col-md-11 {
    flex: 0 0 auto;
    width: 91.66666667%;
  }
  .col-md-12 {
    flex: 0 0 auto;
    width: 100%;
  }
  .offset-md-0 {
    margin-left: 0;
  }
  .offset-md-1 {
    margin-left: 8.33333333%;
  }
  .offset-md-2 {
    margin-left: 16.66666667%;
  }
  .offset-md-3 {
    margin-left: 25%;
  }
  .offset-md-4 {
    margin-left: 33.33333333%;
  }
  .offset-md-5 {
    margin-left: 41.66666667%;
  }
  .offset-md-6 {
    margin-left: 50%;
  }
  .offset-md-7 {
    margin-left: 58.33333333%;
  }
  .offset-md-8 {
    margin-left: 66.66666667%;
  }
  .offset-md-9 {
    margin-left: 75%;
  }
  .offset-md-10 {
    margin-left: 83.33333333%;
  }
  .offset-md-11 {
    margin-left: 91.66666667%;
  }
  .g-md-0,
  .gx-md-0 {
    --bs-gutter-x: 0;
  }
  .g-md-0,
  .gy-md-0 {
    --bs-gutter-y: 0;
  }
  .g-md-0-5,
  .gx-md-0-5 {
    --bs-gutter-x: 0.25rem;
  }
  .g-md-0-5,
  .gy-md-0-5 {
    --bs-gutter-y: 0.25rem;
  }
  .g-md-1,
  .gx-md-1 {
    --bs-gutter-x: 0.5rem;
  }
  .g-md-1,
  .gy-md-1 {
    --bs-gutter-y: 0.5rem;
  }
  .g-md-1-5,
  .gx-md-1-5 {
    --bs-gutter-x: 0.75rem;
  }
  .g-md-1-5,
  .gy-md-1-5 {
    --bs-gutter-y: 0.75rem;
  }
  .g-md-2,
  .gx-md-2 {
    --bs-gutter-x: 1rem;
  }
  .g-md-2,
  .gy-md-2 {
    --bs-gutter-y: 1rem;
  }
  .g-md-2-5,
  .gx-md-2-5 {
    --bs-gutter-x: 1.25rem;
  }
  .g-md-2-5,
  .gy-md-2-5 {
    --bs-gutter-y: 1.25rem;
  }
  .g-md-3,
  .gx-md-3 {
    --bs-gutter-x: 1.5rem;
  }
  .g-md-3,
  .gy-md-3 {
    --bs-gutter-y: 1.5rem;
  }
  .g-md-3-5,
  .gx-md-3-5 {
    --bs-gutter-x: 1.75rem;
  }
  .g-md-3-5,
  .gy-md-3-5 {
    --bs-gutter-y: 1.75rem;
  }
  .g-md-4,
  .gx-md-4 {
    --bs-gutter-x: 2rem;
  }
  .g-md-4,
  .gy-md-4 {
    --bs-gutter-y: 2rem;
  }
  .g-md-5,
  .gx-md-5 {
    --bs-gutter-x: 2.5rem;
  }
  .g-md-5,
  .gy-md-5 {
    --bs-gutter-y: 2.5rem;
  }
  .g-md-5-5,
  .gx-md-5-5 {
    --bs-gutter-x: 2.75rem;
  }
  .g-md-5-5,
  .gy-md-5-5 {
    --bs-gutter-y: 2.75rem;
  }
  .g-md-6,
  .gx-md-6 {
    --bs-gutter-x: 3rem;
  }
  .g-md-6,
  .gy-md-6 {
    --bs-gutter-y: 3rem;
  }
  .g-md-7,
  .gx-md-7 {
    --bs-gutter-x: 3.5rem;
  }
  .g-md-7,
  .gy-md-7 {
    --bs-gutter-y: 3.5rem;
  }
  .g-md-7-5,
  .gx-md-7-5 {
    --bs-gutter-x: 3.75rem;
  }
  .g-md-7-5,
  .gy-md-7-5 {
    --bs-gutter-y: 3.75rem;
  }
  .g-md-8,
  .gx-md-8 {
    --bs-gutter-x: 4rem;
  }
  .g-md-8,
  .gy-md-8 {
    --bs-gutter-y: 4rem;
  }
  .g-md-9,
  .gx-md-9 {
    --bs-gutter-x: 4.5rem;
  }
  .g-md-9,
  .gy-md-9 {
    --bs-gutter-y: 4.5rem;
  }
  .g-md-9-5,
  .gx-md-9-5 {
    --bs-gutter-x: 4.75rem;
  }
  .g-md-9-5,
  .gy-md-9-5 {
    --bs-gutter-y: 4.75rem;
  }
  .g-md-10,
  .gx-md-10 {
    --bs-gutter-x: 5rem;
  }
  .g-md-10,
  .gy-md-10 {
    --bs-gutter-y: 5rem;
  }
  .g-md-auto,
  .gx-md-auto {
    --bs-gutter-x: auto;
  }
  .g-md-auto,
  .gy-md-auto {
    --bs-gutter-y: auto;
  }
}
@media (min-width: 992px) {
  .col-lg {
    flex: 1 0 0%;
  }
  .row-cols-lg-auto > * {
    flex: 0 0 auto;
    width: auto;
  }
  .row-cols-lg-1 > * {
    flex: 0 0 auto;
    width: 100%;
  }
  .row-cols-lg-2 > * {
    flex: 0 0 auto;
    width: 50%;
  }
  .row-cols-lg-3 > * {
    flex: 0 0 auto;
    width: 33.3333333333%;
  }
  .row-cols-lg-4 > * {
    flex: 0 0 auto;
    width: 25%;
  }
  .row-cols-lg-5 > * {
    flex: 0 0 auto;
    width: 20%;
  }
  .row-cols-lg-6 > * {
    flex: 0 0 auto;
    width: 16.6666666667%;
  }
  .col-lg-auto {
    flex: 0 0 auto;
    width: auto;
  }
  .col-lg-1 {
    flex: 0 0 auto;
    width: 8.33333333%;
  }
  .col-lg-2 {
    flex: 0 0 auto;
    width: 16.66666667%;
  }
  .col-lg-3 {
    flex: 0 0 auto;
    width: 25%;
  }
  .col-lg-4 {
    flex: 0 0 auto;
    width: 33.33333333%;
  }
  .col-lg-5 {
    flex: 0 0 auto;
    width: 41.66666667%;
  }
  .col-lg-6 {
    flex: 0 0 auto;
    width: 50%;
  }
  .col-lg-7 {
    flex: 0 0 auto;
    width: 58.33333333%;
  }
  .col-lg-8 {
    flex: 0 0 auto;
    width: 66.66666667%;
  }
  .col-lg-9 {
    flex: 0 0 auto;
    width: 75%;
  }
  .col-lg-10 {
    flex: 0 0 auto;
    width: 83.33333333%;
  }
  .col-lg-11 {
    flex: 0 0 auto;
    width: 91.66666667%;
  }
  .col-lg-12 {
    flex: 0 0 auto;
    width: 100%;
  }
  .offset-lg-0 {
    margin-left: 0;
  }
  .offset-lg-1 {
    margin-left: 8.33333333%;
  }
  .offset-lg-2 {
    margin-left: 16.66666667%;
  }
  .offset-lg-3 {
    margin-left: 25%;
  }
  .offset-lg-4 {
    margin-left: 33.33333333%;
  }
  .offset-lg-5 {
    margin-left: 41.66666667%;
  }
  .offset-lg-6 {
    margin-left: 50%;
  }
  .offset-lg-7 {
    margin-left: 58.33333333%;
  }
  .offset-lg-8 {
    margin-left: 66.66666667%;
  }
  .offset-lg-9 {
    margin-left: 75%;
  }
  .offset-lg-10 {
    margin-left: 83.33333333%;
  }
  .offset-lg-11 {
    margin-left: 91.66666667%;
  }
  .g-lg-0,
  .gx-lg-0 {
    --bs-gutter-x: 0;
  }
  .g-lg-0,
  .gy-lg-0 {
    --bs-gutter-y: 0;
  }
  .g-lg-0-5,
  .gx-lg-0-5 {
    --bs-gutter-x: 0.25rem;
  }
  .g-lg-0-5,
  .gy-lg-0-5 {
    --bs-gutter-y: 0.25rem;
  }
  .g-lg-1,
  .gx-lg-1 {
    --bs-gutter-x: 0.5rem;
  }
  .g-lg-1,
  .gy-lg-1 {
    --bs-gutter-y: 0.5rem;
  }
  .g-lg-1-5,
  .gx-lg-1-5 {
    --bs-gutter-x: 0.75rem;
  }
  .g-lg-1-5,
  .gy-lg-1-5 {
    --bs-gutter-y: 0.75rem;
  }
  .g-lg-2,
  .gx-lg-2 {
    --bs-gutter-x: 1rem;
  }
  .g-lg-2,
  .gy-lg-2 {
    --bs-gutter-y: 1rem;
  }
  .g-lg-2-5,
  .gx-lg-2-5 {
    --bs-gutter-x: 1.25rem;
  }
  .g-lg-2-5,
  .gy-lg-2-5 {
    --bs-gutter-y: 1.25rem;
  }
  .g-lg-3,
  .gx-lg-3 {
    --bs-gutter-x: 1.5rem;
  }
  .g-lg-3,
  .gy-lg-3 {
    --bs-gutter-y: 1.5rem;
  }
  .g-lg-3-5,
  .gx-lg-3-5 {
    --bs-gutter-x: 1.75rem;
  }
  .g-lg-3-5,
  .gy-lg-3-5 {
    --bs-gutter-y: 1.75rem;
  }
  .g-lg-4,
  .gx-lg-4 {
    --bs-gutter-x: 2rem;
  }
  .g-lg-4,
  .gy-lg-4 {
    --bs-gutter-y: 2rem;
  }
  .g-lg-5,
  .gx-lg-5 {
    --bs-gutter-x: 2.5rem;
  }
  .g-lg-5,
  .gy-lg-5 {
    --bs-gutter-y: 2.5rem;
  }
  .g-lg-5-5,
  .gx-lg-5-5 {
    --bs-gutter-x: 2.75rem;
  }
  .g-lg-5-5,
  .gy-lg-5-5 {
    --bs-gutter-y: 2.75rem;
  }
  .g-lg-6,
  .gx-lg-6 {
    --bs-gutter-x: 3rem;
  }
  .g-lg-6,
  .gy-lg-6 {
    --bs-gutter-y: 3rem;
  }
  .g-lg-7,
  .gx-lg-7 {
    --bs-gutter-x: 3.5rem;
  }
  .g-lg-7,
  .gy-lg-7 {
    --bs-gutter-y: 3.5rem;
  }
  .g-lg-7-5,
  .gx-lg-7-5 {
    --bs-gutter-x: 3.75rem;
  }
  .g-lg-7-5,
  .gy-lg-7-5 {
    --bs-gutter-y: 3.75rem;
  }
  .g-lg-8,
  .gx-lg-8 {
    --bs-gutter-x: 4rem;
  }
  .g-lg-8,
  .gy-lg-8 {
    --bs-gutter-y: 4rem;
  }
  .g-lg-9,
  .gx-lg-9 {
    --bs-gutter-x: 4.5rem;
  }
  .g-lg-9,
  .gy-lg-9 {
    --bs-gutter-y: 4.5rem;
  }
  .g-lg-9-5,
  .gx-lg-9-5 {
    --bs-gutter-x: 4.75rem;
  }
  .g-lg-9-5,
  .gy-lg-9-5 {
    --bs-gutter-y: 4.75rem;
  }
  .g-lg-10,
  .gx-lg-10 {
    --bs-gutter-x: 5rem;
  }
  .g-lg-10,
  .gy-lg-10 {
    --bs-gutter-y: 5rem;
  }
  .g-lg-auto,
  .gx-lg-auto {
    --bs-gutter-x: auto;
  }
  .g-lg-auto,
  .gy-lg-auto {
    --bs-gutter-y: auto;
  }
}
@media (min-width: 1200px) {
  .col-xl {
    flex: 1 0 0%;
  }
  .row-cols-xl-auto > * {
    flex: 0 0 auto;
    width: auto;
  }
  .row-cols-xl-1 > * {
    flex: 0 0 auto;
    width: 100%;
  }
  .row-cols-xl-2 > * {
    flex: 0 0 auto;
    width: 50%;
  }
  .row-cols-xl-3 > * {
    flex: 0 0 auto;
    width: 33.3333333333%;
  }
  .row-cols-xl-4 > * {
    flex: 0 0 auto;
    width: 25%;
  }
  .row-cols-xl-5 > * {
    flex: 0 0 auto;
    width: 20%;
  }
  .row-cols-xl-6 > * {
    flex: 0 0 auto;
    width: 16.6666666667%;
  }
  .col-xl-auto {
    flex: 0 0 auto;
    width: auto;
  }
  .col-xl-1 {
    flex: 0 0 auto;
    width: 8.33333333%;
  }
  .col-xl-2 {
    flex: 0 0 auto;
    width: 16.66666667%;
  }
  .col-xl-3 {
    flex: 0 0 auto;
    width: 25%;
  }
  .col-xl-4 {
    flex: 0 0 auto;
    width: 33.33333333%;
  }
  .col-xl-5 {
    flex: 0 0 auto;
    width: 41.66666667%;
  }
  .col-xl-6 {
    flex: 0 0 auto;
    width: 50%;
  }
  .col-xl-7 {
    flex: 0 0 auto;
    width: 58.33333333%;
  }
  .col-xl-8 {
    flex: 0 0 auto;
    width: 66.66666667%;
  }
  .col-xl-9 {
    flex: 0 0 auto;
    width: 75%;
  }
  .col-xl-10 {
    flex: 0 0 auto;
    width: 83.33333333%;
  }
  .col-xl-11 {
    flex: 0 0 auto;
    width: 91.66666667%;
  }
  .col-xl-12 {
    flex: 0 0 auto;
    width: 100%;
  }
  .offset-xl-0 {
    margin-left: 0;
  }
  .offset-xl-1 {
    margin-left: 8.33333333%;
  }
  .offset-xl-2 {
    margin-left: 16.66666667%;
  }
  .offset-xl-3 {
    margin-left: 25%;
  }
  .offset-xl-4 {
    margin-left: 33.33333333%;
  }
  .offset-xl-5 {
    margin-left: 41.66666667%;
  }
  .offset-xl-6 {
    margin-left: 50%;
  }
  .offset-xl-7 {
    margin-left: 58.33333333%;
  }
  .offset-xl-8 {
    margin-left: 66.66666667%;
  }
  .offset-xl-9 {
    margin-left: 75%;
  }
  .offset-xl-10 {
    margin-left: 83.33333333%;
  }
  .offset-xl-11 {
    margin-left: 91.66666667%;
  }
  .g-xl-0,
  .gx-xl-0 {
    --bs-gutter-x: 0;
  }
  .g-xl-0,
  .gy-xl-0 {
    --bs-gutter-y: 0;
  }
  .g-xl-0-5,
  .gx-xl-0-5 {
    --bs-gutter-x: 0.25rem;
  }
  .g-xl-0-5,
  .gy-xl-0-5 {
    --bs-gutter-y: 0.25rem;
  }
  .g-xl-1,
  .gx-xl-1 {
    --bs-gutter-x: 0.5rem;
  }
  .g-xl-1,
  .gy-xl-1 {
    --bs-gutter-y: 0.5rem;
  }
  .g-xl-1-5,
  .gx-xl-1-5 {
    --bs-gutter-x: 0.75rem;
  }
  .g-xl-1-5,
  .gy-xl-1-5 {
    --bs-gutter-y: 0.75rem;
  }
  .g-xl-2,
  .gx-xl-2 {
    --bs-gutter-x: 1rem;
  }
  .g-xl-2,
  .gy-xl-2 {
    --bs-gutter-y: 1rem;
  }
  .g-xl-2-5,
  .gx-xl-2-5 {
    --bs-gutter-x: 1.25rem;
  }
  .g-xl-2-5,
  .gy-xl-2-5 {
    --bs-gutter-y: 1.25rem;
  }
  .g-xl-3,
  .gx-xl-3 {
    --bs-gutter-x: 1.5rem;
  }
  .g-xl-3,
  .gy-xl-3 {
    --bs-gutter-y: 1.5rem;
  }
  .g-xl-3-5,
  .gx-xl-3-5 {
    --bs-gutter-x: 1.75rem;
  }
  .g-xl-3-5,
  .gy-xl-3-5 {
    --bs-gutter-y: 1.75rem;
  }
  .g-xl-4,
  .gx-xl-4 {
    --bs-gutter-x: 2rem;
  }
  .g-xl-4,
  .gy-xl-4 {
    --bs-gutter-y: 2rem;
  }
  .g-xl-5,
  .gx-xl-5 {
    --bs-gutter-x: 2.5rem;
  }
  .g-xl-5,
  .gy-xl-5 {
    --bs-gutter-y: 2.5rem;
  }
  .g-xl-5-5,
  .gx-xl-5-5 {
    --bs-gutter-x: 2.75rem;
  }
  .g-xl-5-5,
  .gy-xl-5-5 {
    --bs-gutter-y: 2.75rem;
  }
  .g-xl-6,
  .gx-xl-6 {
    --bs-gutter-x: 3rem;
  }
  .g-xl-6,
  .gy-xl-6 {
    --bs-gutter-y: 3rem;
  }
  .g-xl-7,
  .gx-xl-7 {
    --bs-gutter-x: 3.5rem;
  }
  .g-xl-7,
  .gy-xl-7 {
    --bs-gutter-y: 3.5rem;
  }
  .g-xl-7-5,
  .gx-xl-7-5 {
    --bs-gutter-x: 3.75rem;
  }
  .g-xl-7-5,
  .gy-xl-7-5 {
    --bs-gutter-y: 3.75rem;
  }
  .g-xl-8,
  .gx-xl-8 {
    --bs-gutter-x: 4rem;
  }
  .g-xl-8,
  .gy-xl-8 {
    --bs-gutter-y: 4rem;
  }
  .g-xl-9,
  .gx-xl-9 {
    --bs-gutter-x: 4.5rem;
  }
  .g-xl-9,
  .gy-xl-9 {
    --bs-gutter-y: 4.5rem;
  }
  .g-xl-9-5,
  .gx-xl-9-5 {
    --bs-gutter-x: 4.75rem;
  }
  .g-xl-9-5,
  .gy-xl-9-5 {
    --bs-gutter-y: 4.75rem;
  }
  .g-xl-10,
  .gx-xl-10 {
    --bs-gutter-x: 5rem;
  }
  .g-xl-10,
  .gy-xl-10 {
    --bs-gutter-y: 5rem;
  }
  .g-xl-auto,
  .gx-xl-auto {
    --bs-gutter-x: auto;
  }
  .g-xl-auto,
  .gy-xl-auto {
    --bs-gutter-y: auto;
  }
}
@media (min-width: 1400px) {
  .col-xxl {
    flex: 1 0 0%;
  }
  .row-cols-xxl-auto > * {
    flex: 0 0 auto;
    width: auto;
  }
  .row-cols-xxl-1 > * {
    flex: 0 0 auto;
    width: 100%;
  }
  .row-cols-xxl-2 > * {
    flex: 0 0 auto;
    width: 50%;
  }
  .row-cols-xxl-3 > * {
    flex: 0 0 auto;
    width: 33.3333333333%;
  }
  .row-cols-xxl-4 > * {
    flex: 0 0 auto;
    width: 25%;
  }
  .row-cols-xxl-5 > * {
    flex: 0 0 auto;
    width: 20%;
  }
  .row-cols-xxl-6 > * {
    flex: 0 0 auto;
    width: 16.6666666667%;
  }
  .col-xxl-auto {
    flex: 0 0 auto;
    width: auto;
  }
  .col-xxl-1 {
    flex: 0 0 auto;
    width: 8.33333333%;
  }
  .col-xxl-2 {
    flex: 0 0 auto;
    width: 16.66666667%;
  }
  .col-xxl-3 {
    flex: 0 0 auto;
    width: 25%;
  }
  .col-xxl-4 {
    flex: 0 0 auto;
    width: 33.33333333%;
  }
  .col-xxl-5 {
    flex: 0 0 auto;
    width: 41.66666667%;
  }
  .col-xxl-6 {
    flex: 0 0 auto;
    width: 50%;
  }
  .col-xxl-7 {
    flex: 0 0 auto;
    width: 58.33333333%;
  }
  .col-xxl-8 {
    flex: 0 0 auto;
    width: 66.66666667%;
  }
  .col-xxl-9 {
    flex: 0 0 auto;
    width: 75%;
  }
  .col-xxl-10 {
    flex: 0 0 auto;
    width: 83.33333333%;
  }
  .col-xxl-11 {
    flex: 0 0 auto;
    width: 91.66666667%;
  }
  .col-xxl-12 {
    flex: 0 0 auto;
    width: 100%;
  }
  .offset-xxl-0 {
    margin-left: 0;
  }
  .offset-xxl-1 {
    margin-left: 8.33333333%;
  }
  .offset-xxl-2 {
    margin-left: 16.66666667%;
  }
  .offset-xxl-3 {
    margin-left: 25%;
  }
  .offset-xxl-4 {
    margin-left: 33.33333333%;
  }
  .offset-xxl-5 {
    margin-left: 41.66666667%;
  }
  .offset-xxl-6 {
    margin-left: 50%;
  }
  .offset-xxl-7 {
    margin-left: 58.33333333%;
  }
  .offset-xxl-8 {
    margin-left: 66.66666667%;
  }
  .offset-xxl-9 {
    margin-left: 75%;
  }
  .offset-xxl-10 {
    margin-left: 83.33333333%;
  }
  .offset-xxl-11 {
    margin-left: 91.66666667%;
  }
  .g-xxl-0,
  .gx-xxl-0 {
    --bs-gutter-x: 0;
  }
  .g-xxl-0,
  .gy-xxl-0 {
    --bs-gutter-y: 0;
  }
  .g-xxl-0-5,
  .gx-xxl-0-5 {
    --bs-gutter-x: 0.25rem;
  }
  .g-xxl-0-5,
  .gy-xxl-0-5 {
    --bs-gutter-y: 0.25rem;
  }
  .g-xxl-1,
  .gx-xxl-1 {
    --bs-gutter-x: 0.5rem;
  }
  .g-xxl-1,
  .gy-xxl-1 {
    --bs-gutter-y: 0.5rem;
  }
  .g-xxl-1-5,
  .gx-xxl-1-5 {
    --bs-gutter-x: 0.75rem;
  }
  .g-xxl-1-5,
  .gy-xxl-1-5 {
    --bs-gutter-y: 0.75rem;
  }
  .g-xxl-2,
  .gx-xxl-2 {
    --bs-gutter-x: 1rem;
  }
  .g-xxl-2,
  .gy-xxl-2 {
    --bs-gutter-y: 1rem;
  }
  .g-xxl-2-5,
  .gx-xxl-2-5 {
    --bs-gutter-x: 1.25rem;
  }
  .g-xxl-2-5,
  .gy-xxl-2-5 {
    --bs-gutter-y: 1.25rem;
  }
  .g-xxl-3,
  .gx-xxl-3 {
    --bs-gutter-x: 1.5rem;
  }
  .g-xxl-3,
  .gy-xxl-3 {
    --bs-gutter-y: 1.5rem;
  }
  .g-xxl-3-5,
  .gx-xxl-3-5 {
    --bs-gutter-x: 1.75rem;
  }
  .g-xxl-3-5,
  .gy-xxl-3-5 {
    --bs-gutter-y: 1.75rem;
  }
  .g-xxl-4,
  .gx-xxl-4 {
    --bs-gutter-x: 2rem;
  }
  .g-xxl-4,
  .gy-xxl-4 {
    --bs-gutter-y: 2rem;
  }
  .g-xxl-5,
  .gx-xxl-5 {
    --bs-gutter-x: 2.5rem;
  }
  .g-xxl-5,
  .gy-xxl-5 {
    --bs-gutter-y: 2.5rem;
  }
  .g-xxl-5-5,
  .gx-xxl-5-5 {
    --bs-gutter-x: 2.75rem;
  }
  .g-xxl-5-5,
  .gy-xxl-5-5 {
    --bs-gutter-y: 2.75rem;
  }
  .g-xxl-6,
  .gx-xxl-6 {
    --bs-gutter-x: 3rem;
  }
  .g-xxl-6,
  .gy-xxl-6 {
    --bs-gutter-y: 3rem;
  }
  .g-xxl-7,
  .gx-xxl-7 {
    --bs-gutter-x: 3.5rem;
  }
  .g-xxl-7,
  .gy-xxl-7 {
    --bs-gutter-y: 3.5rem;
  }
  .g-xxl-7-5,
  .gx-xxl-7-5 {
    --bs-gutter-x: 3.75rem;
  }
  .g-xxl-7-5,
  .gy-xxl-7-5 {
    --bs-gutter-y: 3.75rem;
  }
  .g-xxl-8,
  .gx-xxl-8 {
    --bs-gutter-x: 4rem;
  }
  .g-xxl-8,
  .gy-xxl-8 {
    --bs-gutter-y: 4rem;
  }
  .g-xxl-9,
  .gx-xxl-9 {
    --bs-gutter-x: 4.5rem;
  }
  .g-xxl-9,
  .gy-xxl-9 {
    --bs-gutter-y: 4.5rem;
  }
  .g-xxl-9-5,
  .gx-xxl-9-5 {
    --bs-gutter-x: 4.75rem;
  }
  .g-xxl-9-5,
  .gy-xxl-9-5 {
    --bs-gutter-y: 4.75rem;
  }
  .g-xxl-10,
  .gx-xxl-10 {
    --bs-gutter-x: 5rem;
  }
  .g-xxl-10,
  .gy-xxl-10 {
    --bs-gutter-y: 5rem;
  }
  .g-xxl-auto,
  .gx-xxl-auto {
    --bs-gutter-x: auto;
  }
  .g-xxl-auto,
  .gy-xxl-auto {
    --bs-gutter-y: auto;
  }
}
.visually-hidden,
.visually-hidden-focusable:not(:focus):not(:focus-within) {
  position: absolute !important;
  width: 1px !important;
  height: 1px !important;
  padding: 0 !important;
  margin: -1px !important;
  overflow: hidden !important;
  clip: rect(0, 0, 0, 0) !important;
  white-space: nowrap !important;
  border: 0 !important;
}

.text-truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.vr {
  display: inline-block;
  align-self: stretch;
  width: 1px;
  min-height: 1em;
  background-color: currentcolor;
  opacity: 1;
}

.blur-2 {
  filter: blur(2px);
}

.blur-6 {
  filter: blur(6px);
}

.offset-border {
  --offset-size: 1rem;
  position: relative;
  margin: 0 var(--offset-size) var(--offset-size) 0;
  z-index: 0;
}
.offset-border::after {
  content: "";
  position: absolute;
  border: 1px solid #42649b;
  border-radius: inherit;
  right: calc(var(--offset-size) * -1);
  bottom: calc(var(--offset-size) * -1);
  width: 100%;
  height: 100%;
  pointer-events: none;
}
.offset-border.offset-border-behind::after {
  z-index: -1;
}
.offset-border.offset-border-left {
  margin: 0 0 var(--offset-size) var(--offset-size);
}
.offset-border.offset-border-left::after {
  left: calc(var(--offset-size) * -1);
  bottom: calc(var(--offset-size) * -1);
}

@media (min-width: 768px) {
  .offset-border-lg {
    --offset-size: 1.5rem;
  }
}

@media (max-width: 575.98px) {
  .offset-border-sm {
    --offset-size: 0.5rem;
  }
}

/* stylelint-disable max-nesting-depth */
.align-baseline {
  vertical-align: baseline;
}

.align-top {
  vertical-align: top;
}

.align-middle {
  vertical-align: middle;
}

.align-bottom {
  vertical-align: bottom;
}

.align-text-bottom {
  vertical-align: text-bottom;
}

.align-text-top {
  vertical-align: text-top;
}

.float-start {
  float: left;
}

.float-end {
  float: right;
}

.float-none {
  float: none;
}

.opacity-0 {
  opacity: 0;
}

.opacity-25 {
  opacity: 0.25;
}

.opacity-50 {
  opacity: 0.5;
}

.opacity-75 {
  opacity: 0.75;
}

.opacity-100 {
  opacity: 1;
}

.overflow-auto {
  overflow: auto;
}

.overflow-hidden {
  overflow: hidden;
}

.overflow-visible {
  overflow: visible;
}

.overflow-scroll {
  overflow: scroll;
}

.d-inline {
  display: inline;
}

.d-inline-block {
  display: inline-block;
}

.d-block {
  display: block;
}

.d-grid {
  display: grid;
}

.d-table {
  display: table;
}

.d-table-row {
  display: table-row;
}

.d-table-cell {
  display: table-cell;
}

.d-flex {
  display: flex;
}

.d-inline-flex {
  display: inline-flex;
}

.d-none {
  display: none;
}

.shadow {
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15);
}

.shadow-sm {
  box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075);
}

.shadow-lg {
  box-shadow: 0 1rem 3rem rgba(0, 0, 0, 0.175);
}

.shadow-none {
  box-shadow: none;
}

.position-static {
  position: static;
}

.position-relative {
  position: relative;
}

.position-absolute {
  position: absolute;
}

.position-fixed {
  position: fixed;
}

.position-sticky {
  position: sticky;
}

.top-0 {
  top: 0;
}

.top-50 {
  top: 50%;
}

.top-100 {
  top: 100%;
}

.bottom-0 {
  bottom: 0;
}

.bottom-50 {
  bottom: 50%;
}

.bottom-100 {
  bottom: 100%;
}

.start-0 {
  left: 0;
}

.start-50 {
  left: 50%;
}

.start-100 {
  left: 100%;
}

.end-0 {
  right: 0;
}

.end-50 {
  right: 50%;
}

.end-100 {
  right: 100%;
}

.translate-middle {
  transform: translate(-50%, -50%);
}

.translate-middle-x {
  transform: translateX(-50%);
}

.translate-middle-y {
  transform: translateY(-50%);
}

.border {
  border: var(--bs-border-width) var(--bs-border-style) var(--bs-border-color);
}

.border-0 {
  border: 0;
}

.border-top {
  border-top: var(--bs-border-width) var(--bs-border-style) var(--bs-border-color);
}

.border-top-0 {
  border-top: 0;
}

.border-end {
  border-right: var(--bs-border-width) var(--bs-border-style) var(--bs-border-color);
}

.border-end-0 {
  border-right: 0;
}

.border-bottom {
  border-bottom: var(--bs-border-width) var(--bs-border-style) var(--bs-border-color);
}

.border-bottom-0 {
  border-bottom: 0;
}

.border-start {
  border-left: var(--bs-border-width) var(--bs-border-style) var(--bs-border-color);
}

.border-start-0 {
  border-left: 0;
}

.border-primary {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-primary-rgb), var(--bs-border-opacity));
}

.border-secondary {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-secondary-rgb), var(--bs-border-opacity));
}

.border-success {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-success-rgb), var(--bs-border-opacity));
}

.border-info {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-info-rgb), var(--bs-border-opacity));
}

.border-warning {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-warning-rgb), var(--bs-border-opacity));
}

.border-danger {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-danger-rgb), var(--bs-border-opacity));
}

.border-light {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-light-rgb), var(--bs-border-opacity));
}

.border-dark {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-dark-rgb), var(--bs-border-opacity));
}

.border-white {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-white-rgb), var(--bs-border-opacity));
}

.border-1 {
  --bs-border-width: 1px;
}

.border-2 {
  --bs-border-width: 2px;
}

.border-3 {
  --bs-border-width: 3px;
}

.border-4 {
  --bs-border-width: 4px;
}

.border-5 {
  --bs-border-width: 5px;
}

.border-opacity-10 {
  --bs-border-opacity: 0.1;
}

.border-opacity-25 {
  --bs-border-opacity: 0.25;
}

.border-opacity-50 {
  --bs-border-opacity: 0.5;
}

.border-opacity-75 {
  --bs-border-opacity: 0.75;
}

.border-opacity-100 {
  --bs-border-opacity: 1;
}

.w-25 {
  width: 25%;
}

.w-50 {
  width: 50%;
}

.w-75 {
  width: 75%;
}

.w-100 {
  width: 100%;
}

.w-auto {
  width: auto;
}

.mw-100 {
  max-width: 100%;
}

.vw-100 {
  width: 100vw;
}

.min-vw-100 {
  min-width: 100vw;
}

.h-25 {
  height: 25%;
}

.h-50 {
  height: 50%;
}

.h-75 {
  height: 75%;
}

.h-100 {
  height: 100%;
}

.h-auto {
  height: auto;
}

.mh-100 {
  max-height: 100%;
}

.vh-100 {
  height: 100vh;
}

.min-vh-100 {
  min-height: 100vh;
}

.flex-fill {
  flex: 1 1 auto;
}

.flex-row {
  flex-direction: row;
}

.flex-column {
  flex-direction: column;
}

.flex-row-reverse {
  flex-direction: row-reverse;
}

.flex-column-reverse {
  flex-direction: column-reverse;
}

.flex-grow-0 {
  flex-grow: 0;
}

.flex-grow-1 {
  flex-grow: 1;
}

.flex-shrink-0 {
  flex-shrink: 0;
}

.flex-shrink-1 {
  flex-shrink: 1;
}

.flex-wrap {
  flex-wrap: wrap;
}

.flex-nowrap {
  flex-wrap: nowrap;
}

.flex-wrap-reverse {
  flex-wrap: wrap-reverse;
}

.justify-content-start {
  justify-content: flex-start;
}

.justify-content-end {
  justify-content: flex-end;
}

.justify-content-center {
  justify-content: center;
}

.justify-content-between {
  justify-content: space-between;
}

.justify-content-around {
  justify-content: space-around;
}

.justify-content-evenly {
  justify-content: space-evenly;
}

.align-items-start {
  align-items: flex-start;
}

.align-items-end {
  align-items: flex-end;
}

.align-items-center {
  align-items: center;
}

.align-items-baseline {
  align-items: baseline;
}

.align-items-stretch {
  align-items: stretch;
}

.align-content-start {
  align-content: flex-start;
}

.align-content-end {
  align-content: flex-end;
}

.align-content-center {
  align-content: center;
}

.align-content-between {
  align-content: space-between;
}

.align-content-around {
  align-content: space-around;
}

.align-content-stretch {
  align-content: stretch;
}

.align-self-auto {
  align-self: auto;
}

.align-self-start {
  align-self: flex-start;
}

.align-self-end {
  align-self: flex-end;
}

.align-self-center {
  align-self: center;
}

.align-self-baseline {
  align-self: baseline;
}

.align-self-stretch {
  align-self: stretch;
}

.order-first {
  order: -1;
}

.order-0 {
  order: 0;
}

.order-1 {
  order: 1;
}

.order-2 {
  order: 2;
}

.order-3 {
  order: 3;
}

.order-4 {
  order: 4;
}

.order-5 {
  order: 5;
}

.order-last {
  order: 6;
}

.m-0 {
  margin: 0;
}

.m-0-5 {
  margin: 0.25rem;
}

.m-1 {
  margin: 0.5rem;
}

.m-1-5 {
  margin: 0.75rem;
}

.m-2 {
  margin: 1rem;
}

.m-2-5 {
  margin: 1.25rem;
}

.m-3 {
  margin: 1.5rem;
}

.m-3-5 {
  margin: 1.75rem;
}

.m-4 {
  margin: 2rem;
}

.m-5 {
  margin: 2.5rem;
}

.m-5-5 {
  margin: 2.75rem;
}

.m-6 {
  margin: 3rem;
}

.m-7 {
  margin: 3.5rem;
}

.m-7-5 {
  margin: 3.75rem;
}

.m-8 {
  margin: 4rem;
}

.m-9 {
  margin: 4.5rem;
}

.m-9-5 {
  margin: 4.75rem;
}

.m-10 {
  margin: 5rem;
}

.m-auto {
  margin: auto;
}

.mx-0 {
  margin-right: 0;
  margin-left: 0;
}

.mx-0-5 {
  margin-right: 0.25rem;
  margin-left: 0.25rem;
}

.mx-1 {
  margin-right: 0.5rem;
  margin-left: 0.5rem;
}

.mx-1-5 {
  margin-right: 0.75rem;
  margin-left: 0.75rem;
}

.mx-2 {
  margin-right: 1rem;
  margin-left: 1rem;
}

.mx-2-5 {
  margin-right: 1.25rem;
  margin-left: 1.25rem;
}

.mx-3 {
  margin-right: 1.5rem;
  margin-left: 1.5rem;
}

.mx-3-5 {
  margin-right: 1.75rem;
  margin-left: 1.75rem;
}

.mx-4 {
  margin-right: 2rem;
  margin-left: 2rem;
}

.mx-5 {
  margin-right: 2.5rem;
  margin-left: 2.5rem;
}

.mx-5-5 {
  margin-right: 2.75rem;
  margin-left: 2.75rem;
}

.mx-6 {
  margin-right: 3rem;
  margin-left: 3rem;
}

.mx-7 {
  margin-right: 3.5rem;
  margin-left: 3.5rem;
}

.mx-7-5 {
  margin-right: 3.75rem;
  margin-left: 3.75rem;
}

.mx-8 {
  margin-right: 4rem;
  margin-left: 4rem;
}

.mx-9 {
  margin-right: 4.5rem;
  margin-left: 4.5rem;
}

.mx-9-5 {
  margin-right: 4.75rem;
  margin-left: 4.75rem;
}

.mx-10 {
  margin-right: 5rem;
  margin-left: 5rem;
}

.mx-auto {
  margin-right: auto;
  margin-left: auto;
}

.my-0 {
  margin-top: 0;
  margin-bottom: 0;
}

.my-0-5 {
  margin-top: 0.25rem;
  margin-bottom: 0.25rem;
}

.my-1 {
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
}

.my-1-5 {
  margin-top: 0.75rem;
  margin-bottom: 0.75rem;
}

.my-2 {
  margin-top: 1rem;
  margin-bottom: 1rem;
}

.my-2-5 {
  margin-top: 1.25rem;
  margin-bottom: 1.25rem;
}

.my-3 {
  margin-top: 1.5rem;
  margin-bottom: 1.5rem;
}

.my-3-5 {
  margin-top: 1.75rem;
  margin-bottom: 1.75rem;
}

.my-4 {
  margin-top: 2rem;
  margin-bottom: 2rem;
}

.my-5 {
  margin-top: 2.5rem;
  margin-bottom: 2.5rem;
}

.my-5-5 {
  margin-top: 2.75rem;
  margin-bottom: 2.75rem;
}

.my-6 {
  margin-top: 3rem;
  margin-bottom: 3rem;
}

.my-7 {
  margin-top: 3.5rem;
  margin-bottom: 3.5rem;
}

.my-7-5 {
  margin-top: 3.75rem;
  margin-bottom: 3.75rem;
}

.my-8 {
  margin-top: 4rem;
  margin-bottom: 4rem;
}

.my-9 {
  margin-top: 4.5rem;
  margin-bottom: 4.5rem;
}

.my-9-5 {
  margin-top: 4.75rem;
  margin-bottom: 4.75rem;
}

.my-10 {
  margin-top: 5rem;
  margin-bottom: 5rem;
}

.my-auto {
  margin-top: auto;
  margin-bottom: auto;
}

.mt-0 {
  margin-top: 0;
}

.mt-0-5 {
  margin-top: 0.25rem;
}

.mt-1 {
  margin-top: 0.5rem;
}

.mt-1-5 {
  margin-top: 0.75rem;
}

.mt-2 {
  margin-top: 1rem;
}

.mt-2-5 {
  margin-top: 1.25rem;
}

.mt-3 {
  margin-top: 1.5rem;
}

.mt-3-5 {
  margin-top: 1.75rem;
}

.mt-4 {
  margin-top: 2rem;
}

.mt-5 {
  margin-top: 2.5rem;
}

.mt-5-5 {
  margin-top: 2.75rem;
}

.mt-6 {
  margin-top: 3rem;
}

.mt-7 {
  margin-top: 3.5rem;
}

.mt-7-5 {
  margin-top: 3.75rem;
}

.mt-8 {
  margin-top: 4rem;
}

.mt-9 {
  margin-top: 4.5rem;
}

.mt-9-5 {
  margin-top: 4.75rem;
}

.mt-10 {
  margin-top: 5rem;
}

.mt-auto {
  margin-top: auto;
}

.me-0 {
  margin-right: 0;
}

.me-0-5 {
  margin-right: 0.25rem;
}

.me-1 {
  margin-right: 0.5rem;
}

.me-1-5 {
  margin-right: 0.75rem;
}

.me-2 {
  margin-right: 1rem;
}

.me-2-5 {
  margin-right: 1.25rem;
}

.me-3 {
  margin-right: 1.5rem;
}

.me-3-5 {
  margin-right: 1.75rem;
}

.me-4 {
  margin-right: 2rem;
}

.me-5 {
  margin-right: 2.5rem;
}

.me-5-5 {
  margin-right: 2.75rem;
}

.me-6 {
  margin-right: 3rem;
}

.me-7 {
  margin-right: 3.5rem;
}

.me-7-5 {
  margin-right: 3.75rem;
}

.me-8 {
  margin-right: 4rem;
}

.me-9 {
  margin-right: 4.5rem;
}

.me-9-5 {
  margin-right: 4.75rem;
}

.me-10 {
  margin-right: 5rem;
}

.me-auto {
  margin-right: auto;
}

.mb-0 {
  margin-bottom: 0;
}

.mb-0-5 {
  margin-bottom: 0.25rem;
}

.mb-1 {
  margin-bottom: 0.5rem;
}

.mb-1-5 {
  margin-bottom: 0.75rem;
}

.mb-2 {
  margin-bottom: 1rem;
}

.mb-2-5 {
  margin-bottom: 1.25rem;
}

.mb-3 {
  margin-bottom: 1.5rem;
}

.mb-3-5 {
  margin-bottom: 1.75rem;
}

.mb-4 {
  margin-bottom: 2rem;
}

.mb-5 {
  margin-bottom: 2.5rem;
}

.mb-5-5 {
  margin-bottom: 2.75rem;
}

.mb-6 {
  margin-bottom: 3rem;
}

.mb-7 {
  margin-bottom: 3.5rem;
}

.mb-7-5 {
  margin-bottom: 3.75rem;
}

.mb-8 {
  margin-bottom: 4rem;
}

.mb-9 {
  margin-bottom: 4.5rem;
}

.mb-9-5 {
  margin-bottom: 4.75rem;
}

.mb-10 {
  margin-bottom: 5rem;
}

.mb-auto {
  margin-bottom: auto;
}

.ms-0 {
  margin-left: 0;
}

.ms-0-5 {
  margin-left: 0.25rem;
}

.ms-1 {
  margin-left: 0.5rem;
}

.ms-1-5 {
  margin-left: 0.75rem;
}

.ms-2 {
  margin-left: 1rem;
}

.ms-2-5 {
  margin-left: 1.25rem;
}

.ms-3 {
  margin-left: 1.5rem;
}

.ms-3-5 {
  margin-left: 1.75rem;
}

.ms-4 {
  margin-left: 2rem;
}

.ms-5 {
  margin-left: 2.5rem;
}

.ms-5-5 {
  margin-left: 2.75rem;
}

.ms-6 {
  margin-left: 3rem;
}

.ms-7 {
  margin-left: 3.5rem;
}

.ms-7-5 {
  margin-left: 3.75rem;
}

.ms-8 {
  margin-left: 4rem;
}

.ms-9 {
  margin-left: 4.5rem;
}

.ms-9-5 {
  margin-left: 4.75rem;
}

.ms-10 {
  margin-left: 5rem;
}

.ms-auto {
  margin-left: auto;
}

.m-n0 {
  margin: 0;
}

.m-n0-5 {
  margin: -0.25rem;
}

.m-n1 {
  margin: -0.5rem;
}

.m-n1-5 {
  margin: -0.75rem;
}

.m-n2 {
  margin: -1rem;
}

.m-n2-5 {
  margin: -1.25rem;
}

.m-n3 {
  margin: -1.5rem;
}

.m-n3-5 {
  margin: -1.75rem;
}

.m-n4 {
  margin: -2rem;
}

.m-n5 {
  margin: -2.5rem;
}

.m-n5-5 {
  margin: -2.75rem;
}

.m-n6 {
  margin: -3rem;
}

.m-n7 {
  margin: -3.5rem;
}

.m-n7-5 {
  margin: -3.75rem;
}

.m-n8 {
  margin: -4rem;
}

.m-n9 {
  margin: -4.5rem;
}

.m-n9-5 {
  margin: -4.75rem;
}

.m-n10 {
  margin: -5rem;
}

.m-nauto {
  margin: -"auto";
}

.mx-n0 {
  margin-right: 0;
  margin-left: 0;
}

.mx-n0-5 {
  margin-right: -0.25rem;
  margin-left: -0.25rem;
}

.mx-n1 {
  margin-right: -0.5rem;
  margin-left: -0.5rem;
}

.mx-n1-5 {
  margin-right: -0.75rem;
  margin-left: -0.75rem;
}

.mx-n2 {
  margin-right: -1rem;
  margin-left: -1rem;
}

.mx-n2-5 {
  margin-right: -1.25rem;
  margin-left: -1.25rem;
}

.mx-n3 {
  margin-right: -1.5rem;
  margin-left: -1.5rem;
}

.mx-n3-5 {
  margin-right: -1.75rem;
  margin-left: -1.75rem;
}

.mx-n4 {
  margin-right: -2rem;
  margin-left: -2rem;
}

.mx-n5 {
  margin-right: -2.5rem;
  margin-left: -2.5rem;
}

.mx-n5-5 {
  margin-right: -2.75rem;
  margin-left: -2.75rem;
}

.mx-n6 {
  margin-right: -3rem;
  margin-left: -3rem;
}

.mx-n7 {
  margin-right: -3.5rem;
  margin-left: -3.5rem;
}

.mx-n7-5 {
  margin-right: -3.75rem;
  margin-left: -3.75rem;
}

.mx-n8 {
  margin-right: -4rem;
  margin-left: -4rem;
}

.mx-n9 {
  margin-right: -4.5rem;
  margin-left: -4.5rem;
}

.mx-n9-5 {
  margin-right: -4.75rem;
  margin-left: -4.75rem;
}

.mx-n10 {
  margin-right: -5rem;
  margin-left: -5rem;
}

.mx-nauto {
  margin-right: -"auto";
  margin-left: -"auto";
}

.my-n0 {
  margin-top: 0;
  margin-bottom: 0;
}

.my-n0-5 {
  margin-top: -0.25rem;
  margin-bottom: -0.25rem;
}

.my-n1 {
  margin-top: -0.5rem;
  margin-bottom: -0.5rem;
}

.my-n1-5 {
  margin-top: -0.75rem;
  margin-bottom: -0.75rem;
}

.my-n2 {
  margin-top: -1rem;
  margin-bottom: -1rem;
}

.my-n2-5 {
  margin-top: -1.25rem;
  margin-bottom: -1.25rem;
}

.my-n3 {
  margin-top: -1.5rem;
  margin-bottom: -1.5rem;
}

.my-n3-5 {
  margin-top: -1.75rem;
  margin-bottom: -1.75rem;
}

.my-n4 {
  margin-top: -2rem;
  margin-bottom: -2rem;
}

.my-n5 {
  margin-top: -2.5rem;
  margin-bottom: -2.5rem;
}

.my-n5-5 {
  margin-top: -2.75rem;
  margin-bottom: -2.75rem;
}

.my-n6 {
  margin-top: -3rem;
  margin-bottom: -3rem;
}

.my-n7 {
  margin-top: -3.5rem;
  margin-bottom: -3.5rem;
}

.my-n7-5 {
  margin-top: -3.75rem;
  margin-bottom: -3.75rem;
}

.my-n8 {
  margin-top: -4rem;
  margin-bottom: -4rem;
}

.my-n9 {
  margin-top: -4.5rem;
  margin-bottom: -4.5rem;
}

.my-n9-5 {
  margin-top: -4.75rem;
  margin-bottom: -4.75rem;
}

.my-n10 {
  margin-top: -5rem;
  margin-bottom: -5rem;
}

.my-nauto {
  margin-top: -"auto";
  margin-bottom: -"auto";
}

.mt-n0 {
  margin-top: 0;
}

.mt-n0-5 {
  margin-top: -0.25rem;
}

.mt-n1 {
  margin-top: -0.5rem;
}

.mt-n1-5 {
  margin-top: -0.75rem;
}

.mt-n2 {
  margin-top: -1rem;
}

.mt-n2-5 {
  margin-top: -1.25rem;
}

.mt-n3 {
  margin-top: -1.5rem;
}

.mt-n3-5 {
  margin-top: -1.75rem;
}

.mt-n4 {
  margin-top: -2rem;
}

.mt-n5 {
  margin-top: -2.5rem;
}

.mt-n5-5 {
  margin-top: -2.75rem;
}

.mt-n6 {
  margin-top: -3rem;
}

.mt-n7 {
  margin-top: -3.5rem;
}

.mt-n7-5 {
  margin-top: -3.75rem;
}

.mt-n8 {
  margin-top: -4rem;
}

.mt-n9 {
  margin-top: -4.5rem;
}

.mt-n9-5 {
  margin-top: -4.75rem;
}

.mt-n10 {
  margin-top: -5rem;
}

.mt-nauto {
  margin-top: -"auto";
}

.me-n0 {
  margin-right: 0;
}

.me-n0-5 {
  margin-right: -0.25rem;
}

.me-n1 {
  margin-right: -0.5rem;
}

.me-n1-5 {
  margin-right: -0.75rem;
}

.me-n2 {
  margin-right: -1rem;
}

.me-n2-5 {
  margin-right: -1.25rem;
}

.me-n3 {
  margin-right: -1.5rem;
}

.me-n3-5 {
  margin-right: -1.75rem;
}

.me-n4 {
  margin-right: -2rem;
}

.me-n5 {
  margin-right: -2.5rem;
}

.me-n5-5 {
  margin-right: -2.75rem;
}

.me-n6 {
  margin-right: -3rem;
}

.me-n7 {
  margin-right: -3.5rem;
}

.me-n7-5 {
  margin-right: -3.75rem;
}

.me-n8 {
  margin-right: -4rem;
}

.me-n9 {
  margin-right: -4.5rem;
}

.me-n9-5 {
  margin-right: -4.75rem;
}

.me-n10 {
  margin-right: -5rem;
}

.me-nauto {
  margin-right: -"auto";
}

.mb-n0 {
  margin-bottom: 0;
}

.mb-n0-5 {
  margin-bottom: -0.25rem;
}

.mb-n1 {
  margin-bottom: -0.5rem;
}

.mb-n1-5 {
  margin-bottom: -0.75rem;
}

.mb-n2 {
  margin-bottom: -1rem;
}

.mb-n2-5 {
  margin-bottom: -1.25rem;
}

.mb-n3 {
  margin-bottom: -1.5rem;
}

.mb-n3-5 {
  margin-bottom: -1.75rem;
}

.mb-n4 {
  margin-bottom: -2rem;
}

.mb-n5 {
  margin-bottom: -2.5rem;
}

.mb-n5-5 {
  margin-bottom: -2.75rem;
}

.mb-n6 {
  margin-bottom: -3rem;
}

.mb-n7 {
  margin-bottom: -3.5rem;
}

.mb-n7-5 {
  margin-bottom: -3.75rem;
}

.mb-n8 {
  margin-bottom: -4rem;
}

.mb-n9 {
  margin-bottom: -4.5rem;
}

.mb-n9-5 {
  margin-bottom: -4.75rem;
}

.mb-n10 {
  margin-bottom: -5rem;
}

.mb-nauto {
  margin-bottom: -"auto";
}

.ms-n0 {
  margin-left: 0;
}

.ms-n0-5 {
  margin-left: -0.25rem;
}

.ms-n1 {
  margin-left: -0.5rem;
}

.ms-n1-5 {
  margin-left: -0.75rem;
}

.ms-n2 {
  margin-left: -1rem;
}

.ms-n2-5 {
  margin-left: -1.25rem;
}

.ms-n3 {
  margin-left: -1.5rem;
}

.ms-n3-5 {
  margin-left: -1.75rem;
}

.ms-n4 {
  margin-left: -2rem;
}

.ms-n5 {
  margin-left: -2.5rem;
}

.ms-n5-5 {
  margin-left: -2.75rem;
}

.ms-n6 {
  margin-left: -3rem;
}

.ms-n7 {
  margin-left: -3.5rem;
}

.ms-n7-5 {
  margin-left: -3.75rem;
}

.ms-n8 {
  margin-left: -4rem;
}

.ms-n9 {
  margin-left: -4.5rem;
}

.ms-n9-5 {
  margin-left: -4.75rem;
}

.ms-n10 {
  margin-left: -5rem;
}

.ms-nauto {
  margin-left: -"auto";
}

.p-0 {
  padding: 0;
}

.p-0-5 {
  padding: 0.25rem;
}

.p-1 {
  padding: 0.5rem;
}

.p-1-5 {
  padding: 0.75rem;
}

.p-2 {
  padding: 1rem;
}

.p-2-5 {
  padding: 1.25rem;
}

.p-3 {
  padding: 1.5rem;
}

.p-3-5 {
  padding: 1.75rem;
}

.p-4 {
  padding: 2rem;
}

.p-5 {
  padding: 2.5rem;
}

.p-5-5 {
  padding: 2.75rem;
}

.p-6 {
  padding: 3rem;
}

.p-7 {
  padding: 3.5rem;
}

.p-7-5 {
  padding: 3.75rem;
}

.p-8 {
  padding: 4rem;
}

.p-9 {
  padding: 4.5rem;
}

.p-9-5 {
  padding: 4.75rem;
}

.p-10 {
  padding: 5rem;
}

.p-auto {
  padding: "auto";
}

.px-0 {
  padding-right: 0;
  padding-left: 0;
}

.px-0-5 {
  padding-right: 0.25rem;
  padding-left: 0.25rem;
}

.px-1 {
  padding-right: 0.5rem;
  padding-left: 0.5rem;
}

.px-1-5 {
  padding-right: 0.75rem;
  padding-left: 0.75rem;
}

.px-2 {
  padding-right: 1rem;
  padding-left: 1rem;
}

.px-2-5 {
  padding-right: 1.25rem;
  padding-left: 1.25rem;
}

.px-3 {
  padding-right: 1.5rem;
  padding-left: 1.5rem;
}

.px-3-5 {
  padding-right: 1.75rem;
  padding-left: 1.75rem;
}

.px-4 {
  padding-right: 2rem;
  padding-left: 2rem;
}

.px-5 {
  padding-right: 2.5rem;
  padding-left: 2.5rem;
}

.px-5-5 {
  padding-right: 2.75rem;
  padding-left: 2.75rem;
}

.px-6 {
  padding-right: 3rem;
  padding-left: 3rem;
}

.px-7 {
  padding-right: 3.5rem;
  padding-left: 3.5rem;
}

.px-7-5 {
  padding-right: 3.75rem;
  padding-left: 3.75rem;
}

.px-8 {
  padding-right: 4rem;
  padding-left: 4rem;
}

.px-9 {
  padding-right: 4.5rem;
  padding-left: 4.5rem;
}

.px-9-5 {
  padding-right: 4.75rem;
  padding-left: 4.75rem;
}

.px-10 {
  padding-right: 5rem;
  padding-left: 5rem;
}

.px-auto {
  padding-right: "auto";
  padding-left: "auto";
}

.py-0 {
  padding-top: 0;
  padding-bottom: 0;
}

.py-0-5 {
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
}

.py-1 {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}

.py-1-5 {
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
}

.py-2 {
  padding-top: 1rem;
  padding-bottom: 1rem;
}

.py-2-5 {
  padding-top: 1.25rem;
  padding-bottom: 1.25rem;
}

.py-3 {
  padding-top: 1.5rem;
  padding-bottom: 1.5rem;
}

.py-3-5 {
  padding-top: 1.75rem;
  padding-bottom: 1.75rem;
}

.py-4 {
  padding-top: 2rem;
  padding-bottom: 2rem;
}

.py-5 {
  padding-top: 2.5rem;
  padding-bottom: 2.5rem;
}

.py-5-5 {
  padding-top: 2.75rem;
  padding-bottom: 2.75rem;
}

.py-6 {
  padding-top: 3rem;
  padding-bottom: 3rem;
}

.py-7 {
  padding-top: 3.5rem;
  padding-bottom: 3.5rem;
}

.py-7-5 {
  padding-top: 3.75rem;
  padding-bottom: 3.75rem;
}

.py-8 {
  padding-top: 4rem;
  padding-bottom: 4rem;
}

.py-9 {
  padding-top: 4.5rem;
  padding-bottom: 4.5rem;
}

.py-9-5 {
  padding-top: 4.75rem;
  padding-bottom: 4.75rem;
}

.py-10 {
  padding-top: 5rem;
  padding-bottom: 5rem;
}

.py-auto {
  padding-top: "auto";
  padding-bottom: "auto";
}

.pt-0 {
  padding-top: 0;
}

.pt-0-5 {
  padding-top: 0.25rem;
}

.pt-1 {
  padding-top: 0.5rem;
}

.pt-1-5 {
  padding-top: 0.75rem;
}

.pt-2 {
  padding-top: 1rem;
}

.pt-2-5 {
  padding-top: 1.25rem;
}

.pt-3 {
  padding-top: 1.5rem;
}

.pt-3-5 {
  padding-top: 1.75rem;
}

.pt-4 {
  padding-top: 2rem;
}

.pt-5 {
  padding-top: 2.5rem;
}

.pt-5-5 {
  padding-top: 2.75rem;
}

.pt-6 {
  padding-top: 3rem;
}

.pt-7 {
  padding-top: 3.5rem;
}

.pt-7-5 {
  padding-top: 3.75rem;
}

.pt-8 {
  padding-top: 4rem;
}

.pt-9 {
  padding-top: 4.5rem;
}

.pt-9-5 {
  padding-top: 4.75rem;
}

.pt-10 {
  padding-top: 5rem;
}

.pt-auto {
  padding-top: "auto";
}

.pe-0 {
  padding-right: 0;
}

.pe-0-5 {
  padding-right: 0.25rem;
}

.pe-1 {
  padding-right: 0.5rem;
}

.pe-1-5 {
  padding-right: 0.75rem;
}

.pe-2 {
  padding-right: 1rem;
}

.pe-2-5 {
  padding-right: 1.25rem;
}

.pe-3 {
  padding-right: 1.5rem;
}

.pe-3-5 {
  padding-right: 1.75rem;
}

.pe-4 {
  padding-right: 2rem;
}

.pe-5 {
  padding-right: 2.5rem;
}

.pe-5-5 {
  padding-right: 2.75rem;
}

.pe-6 {
  padding-right: 3rem;
}

.pe-7 {
  padding-right: 3.5rem;
}

.pe-7-5 {
  padding-right: 3.75rem;
}

.pe-8 {
  padding-right: 4rem;
}

.pe-9 {
  padding-right: 4.5rem;
}

.pe-9-5 {
  padding-right: 4.75rem;
}

.pe-10 {
  padding-right: 5rem;
}

.pe-auto {
  padding-right: "auto";
}

.pb-0 {
  padding-bottom: 0;
}

.pb-0-5 {
  padding-bottom: 0.25rem;
}

.pb-1 {
  padding-bottom: 0.5rem;
}

.pb-1-5 {
  padding-bottom: 0.75rem;
}

.pb-2 {
  padding-bottom: 1rem;
}

.pb-2-5 {
  padding-bottom: 1.25rem;
}

.pb-3 {
  padding-bottom: 1.5rem;
}

.pb-3-5 {
  padding-bottom: 1.75rem;
}

.pb-4 {
  padding-bottom: 2rem;
}

.pb-5 {
  padding-bottom: 2.5rem;
}

.pb-5-5 {
  padding-bottom: 2.75rem;
}

.pb-6 {
  padding-bottom: 3rem;
}

.pb-7 {
  padding-bottom: 3.5rem;
}

.pb-7-5 {
  padding-bottom: 3.75rem;
}

.pb-8 {
  padding-bottom: 4rem;
}

.pb-9 {
  padding-bottom: 4.5rem;
}

.pb-9-5 {
  padding-bottom: 4.75rem;
}

.pb-10 {
  padding-bottom: 5rem;
}

.pb-auto {
  padding-bottom: "auto";
}

.ps-0 {
  padding-left: 0;
}

.ps-0-5 {
  padding-left: 0.25rem;
}

.ps-1 {
  padding-left: 0.5rem;
}

.ps-1-5 {
  padding-left: 0.75rem;
}

.ps-2 {
  padding-left: 1rem;
}

.ps-2-5 {
  padding-left: 1.25rem;
}

.ps-3 {
  padding-left: 1.5rem;
}

.ps-3-5 {
  padding-left: 1.75rem;
}

.ps-4 {
  padding-left: 2rem;
}

.ps-5 {
  padding-left: 2.5rem;
}

.ps-5-5 {
  padding-left: 2.75rem;
}

.ps-6 {
  padding-left: 3rem;
}

.ps-7 {
  padding-left: 3.5rem;
}

.ps-7-5 {
  padding-left: 3.75rem;
}

.ps-8 {
  padding-left: 4rem;
}

.ps-9 {
  padding-left: 4.5rem;
}

.ps-9-5 {
  padding-left: 4.75rem;
}

.ps-10 {
  padding-left: 5rem;
}

.ps-auto {
  padding-left: "auto";
}

.gap-0 {
  gap: 0;
}

.gap-0-5 {
  gap: 0.25rem;
}

.gap-1 {
  gap: 0.5rem;
}

.gap-1-5 {
  gap: 0.75rem;
}

.gap-2 {
  gap: 1rem;
}

.gap-2-5 {
  gap: 1.25rem;
}

.gap-3 {
  gap: 1.5rem;
}

.gap-3-5 {
  gap: 1.75rem;
}

.gap-4 {
  gap: 2rem;
}

.gap-5 {
  gap: 2.5rem;
}

.gap-5-5 {
  gap: 2.75rem;
}

.gap-6 {
  gap: 3rem;
}

.gap-7 {
  gap: 3.5rem;
}

.gap-7-5 {
  gap: 3.75rem;
}

.gap-8 {
  gap: 4rem;
}

.gap-9 {
  gap: 4.5rem;
}

.gap-9-5 {
  gap: 4.75rem;
}

.gap-10 {
  gap: 5rem;
}

.gap-auto {
  gap: "auto";
}

.font-monospace {
  font-family: var(--bs-font-monospace);
}

.fs-1 {
  font-size: calc(1.375rem + 1.5vw);
}

.fs-2 {
  font-size: calc(1.325rem + 0.9vw);
}

.fs-3 {
  font-size: calc(1.3rem + 0.6vw);
}

.fs-4 {
  font-size: calc(1.275rem + 0.3vw);
}

.fs-5 {
  font-size: 1.25rem;
}

.fs-6 {
  font-size: 1rem;
}

.fst-italic {
  font-style: italic;
}

.fst-normal {
  font-style: normal;
}

.fw-light {
  font-weight: 300;
}

.fw-lighter {
  font-weight: lighter;
}

.fw-normal {
  font-weight: 400;
}

.fw-bold {
  font-weight: 700;
}

.fw-semibold {
  font-weight: 600;
}

.fw-bolder {
  font-weight: bolder;
}

.lh-1 {
  line-height: 1;
}

.lh-sm {
  line-height: 1.25;
}

.lh-base {
  line-height: 1.5;
}

.lh-lg {
  line-height: 2;
}

.text-start {
  text-align: left;
}

.text-end {
  text-align: right;
}

.text-center {
  text-align: center;
}

.text-decoration-none {
  text-decoration: none;
}

.text-decoration-underline {
  text-decoration: underline;
}

.text-decoration-line-through {
  text-decoration: line-through;
}

.text-lowercase {
  text-transform: lowercase;
}

.text-uppercase {
  text-transform: uppercase;
}

.text-capitalize {
  text-transform: capitalize;
}

.text-wrap {
  white-space: normal;
}

.text-nowrap {
  white-space: nowrap;
}

/* rtl:begin:remove */
.text-break {
  word-wrap: break-word;
  word-break: break-word;
}

/* rtl:end:remove */
.text-primary {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-primary-rgb), var(--bs-text-opacity));
}

.text-secondary {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-secondary-rgb), var(--bs-text-opacity));
}

.text-success {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-success-rgb), var(--bs-text-opacity));
}

.text-info {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-info-rgb), var(--bs-text-opacity));
}

.text-warning {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-warning-rgb), var(--bs-text-opacity));
}

.text-danger {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-danger-rgb), var(--bs-text-opacity));
}

.text-light {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-light-rgb), var(--bs-text-opacity));
}

.text-dark {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-dark-rgb), var(--bs-text-opacity));
}

.text-black {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-black-rgb), var(--bs-text-opacity));
}

.text-white {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-white-rgb), var(--bs-text-opacity));
}

.text-body {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-body-color-rgb), var(--bs-text-opacity));
}

.text-muted {
  --bs-text-opacity: 1;
  color: #6c757d;
}

.text-black-50 {
  --bs-text-opacity: 1;
  color: rgba(0, 0, 0, 0.5);
}

.text-white-50 {
  --bs-text-opacity: 1;
  color: rgba(255, 255, 255, 0.5);
}

.text-reset {
  --bs-text-opacity: 1;
  color: inherit;
}

.text-opacity-25 {
  --bs-text-opacity: 0.25;
}

.text-opacity-50 {
  --bs-text-opacity: 0.5;
}

.text-opacity-75 {
  --bs-text-opacity: 0.75;
}

.text-opacity-100 {
  --bs-text-opacity: 1;
}

.bg-primary {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-primary-rgb), var(--bs-bg-opacity));
}

.bg-secondary {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-secondary-rgb), var(--bs-bg-opacity));
}

.bg-success {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-success-rgb), var(--bs-bg-opacity));
}

.bg-info {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-info-rgb), var(--bs-bg-opacity));
}

.bg-warning {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-warning-rgb), var(--bs-bg-opacity));
}

.bg-danger {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-danger-rgb), var(--bs-bg-opacity));
}

.bg-light {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-light-rgb), var(--bs-bg-opacity));
}

.bg-dark {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-dark-rgb), var(--bs-bg-opacity));
}

.bg-black {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-black-rgb), var(--bs-bg-opacity));
}

.bg-white {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-white-rgb), var(--bs-bg-opacity));
}

.bg-body {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-body-bg-rgb), var(--bs-bg-opacity));
}

.bg-transparent {
  --bs-bg-opacity: 1;
  background-color: transparent;
}

.bg-opacity-10 {
  --bs-bg-opacity: 0.1;
}

.bg-opacity-25 {
  --bs-bg-opacity: 0.25;
}

.bg-opacity-50 {
  --bs-bg-opacity: 0.5;
}

.bg-opacity-75 {
  --bs-bg-opacity: 0.75;
}

.bg-opacity-100 {
  --bs-bg-opacity: 1;
}

.bg-gradient {
  background-image: var(--bs-gradient);
}

.user-select-all {
  user-select: all;
}

.user-select-auto {
  user-select: auto;
}

.user-select-none {
  user-select: none;
}

.pe-none {
  pointer-events: none;
}

.pe-auto {
  pointer-events: auto;
}

.rounded {
  border-radius: var(--bs-border-radius);
}

.rounded-0 {
  border-radius: 0;
}

.rounded-1 {
  border-radius: var(--bs-border-radius-sm);
}

.rounded-2 {
  border-radius: var(--bs-border-radius);
}

.rounded-3 {
  border-radius: var(--bs-border-radius-lg);
}

.rounded-4 {
  border-radius: var(--bs-border-radius-xl);
}

.rounded-5 {
  border-radius: var(--bs-border-radius-2xl);
}

.rounded-circle {
  border-radius: 50%;
}

.rounded-pill {
  border-radius: var(--bs-border-radius-pill);
}

.rounded-top {
  border-top-left-radius: var(--bs-border-radius);
  border-top-right-radius: var(--bs-border-radius);
}

.rounded-end {
  border-top-right-radius: var(--bs-border-radius);
  border-bottom-right-radius: var(--bs-border-radius);
}

.rounded-bottom {
  border-bottom-right-radius: var(--bs-border-radius);
  border-bottom-left-radius: var(--bs-border-radius);
}

.rounded-start {
  border-bottom-left-radius: var(--bs-border-radius);
  border-top-left-radius: var(--bs-border-radius);
}

.visible {
  visibility: visible;
}

.invisible {
  visibility: hidden;
}

@media (min-width: 576px) {
  .float-sm-start {
    float: left;
  }
  .float-sm-end {
    float: right;
  }
  .float-sm-none {
    float: none;
  }
  .d-sm-inline {
    display: inline;
  }
  .d-sm-inline-block {
    display: inline-block;
  }
  .d-sm-block {
    display: block;
  }
  .d-sm-grid {
    display: grid;
  }
  .d-sm-table {
    display: table;
  }
  .d-sm-table-row {
    display: table-row;
  }
  .d-sm-table-cell {
    display: table-cell;
  }
  .d-sm-flex {
    display: flex;
  }
  .d-sm-inline-flex {
    display: inline-flex;
  }
  .d-sm-none {
    display: none;
  }
  .flex-sm-fill {
    flex: 1 1 auto;
  }
  .flex-sm-row {
    flex-direction: row;
  }
  .flex-sm-column {
    flex-direction: column;
  }
  .flex-sm-row-reverse {
    flex-direction: row-reverse;
  }
  .flex-sm-column-reverse {
    flex-direction: column-reverse;
  }
  .flex-sm-grow-0 {
    flex-grow: 0;
  }
  .flex-sm-grow-1 {
    flex-grow: 1;
  }
  .flex-sm-shrink-0 {
    flex-shrink: 0;
  }
  .flex-sm-shrink-1 {
    flex-shrink: 1;
  }
  .flex-sm-wrap {
    flex-wrap: wrap;
  }
  .flex-sm-nowrap {
    flex-wrap: nowrap;
  }
  .flex-sm-wrap-reverse {
    flex-wrap: wrap-reverse;
  }
  .justify-content-sm-start {
    justify-content: flex-start;
  }
  .justify-content-sm-end {
    justify-content: flex-end;
  }
  .justify-content-sm-center {
    justify-content: center;
  }
  .justify-content-sm-between {
    justify-content: space-between;
  }
  .justify-content-sm-around {
    justify-content: space-around;
  }
  .justify-content-sm-evenly {
    justify-content: space-evenly;
  }
  .align-items-sm-start {
    align-items: flex-start;
  }
  .align-items-sm-end {
    align-items: flex-end;
  }
  .align-items-sm-center {
    align-items: center;
  }
  .align-items-sm-baseline {
    align-items: baseline;
  }
  .align-items-sm-stretch {
    align-items: stretch;
  }
  .align-content-sm-start {
    align-content: flex-start;
  }
  .align-content-sm-end {
    align-content: flex-end;
  }
  .align-content-sm-center {
    align-content: center;
  }
  .align-content-sm-between {
    align-content: space-between;
  }
  .align-content-sm-around {
    align-content: space-around;
  }
  .align-content-sm-stretch {
    align-content: stretch;
  }
  .align-self-sm-auto {
    align-self: auto;
  }
  .align-self-sm-start {
    align-self: flex-start;
  }
  .align-self-sm-end {
    align-self: flex-end;
  }
  .align-self-sm-center {
    align-self: center;
  }
  .align-self-sm-baseline {
    align-self: baseline;
  }
  .align-self-sm-stretch {
    align-self: stretch;
  }
  .order-sm-first {
    order: -1;
  }
  .order-sm-0 {
    order: 0;
  }
  .order-sm-1 {
    order: 1;
  }
  .order-sm-2 {
    order: 2;
  }
  .order-sm-3 {
    order: 3;
  }
  .order-sm-4 {
    order: 4;
  }
  .order-sm-5 {
    order: 5;
  }
  .order-sm-last {
    order: 6;
  }
  .m-sm-0 {
    margin: 0;
  }
  .m-sm-0-5 {
    margin: 0.25rem;
  }
  .m-sm-1 {
    margin: 0.5rem;
  }
  .m-sm-1-5 {
    margin: 0.75rem;
  }
  .m-sm-2 {
    margin: 1rem;
  }
  .m-sm-2-5 {
    margin: 1.25rem;
  }
  .m-sm-3 {
    margin: 1.5rem;
  }
  .m-sm-3-5 {
    margin: 1.75rem;
  }
  .m-sm-4 {
    margin: 2rem;
  }
  .m-sm-5 {
    margin: 2.5rem;
  }
  .m-sm-5-5 {
    margin: 2.75rem;
  }
  .m-sm-6 {
    margin: 3rem;
  }
  .m-sm-7 {
    margin: 3.5rem;
  }
  .m-sm-7-5 {
    margin: 3.75rem;
  }
  .m-sm-8 {
    margin: 4rem;
  }
  .m-sm-9 {
    margin: 4.5rem;
  }
  .m-sm-9-5 {
    margin: 4.75rem;
  }
  .m-sm-10 {
    margin: 5rem;
  }
  .m-sm-auto {
    margin: auto;
  }
  .mx-sm-0 {
    margin-right: 0;
    margin-left: 0;
  }
  .mx-sm-0-5 {
    margin-right: 0.25rem;
    margin-left: 0.25rem;
  }
  .mx-sm-1 {
    margin-right: 0.5rem;
    margin-left: 0.5rem;
  }
  .mx-sm-1-5 {
    margin-right: 0.75rem;
    margin-left: 0.75rem;
  }
  .mx-sm-2 {
    margin-right: 1rem;
    margin-left: 1rem;
  }
  .mx-sm-2-5 {
    margin-right: 1.25rem;
    margin-left: 1.25rem;
  }
  .mx-sm-3 {
    margin-right: 1.5rem;
    margin-left: 1.5rem;
  }
  .mx-sm-3-5 {
    margin-right: 1.75rem;
    margin-left: 1.75rem;
  }
  .mx-sm-4 {
    margin-right: 2rem;
    margin-left: 2rem;
  }
  .mx-sm-5 {
    margin-right: 2.5rem;
    margin-left: 2.5rem;
  }
  .mx-sm-5-5 {
    margin-right: 2.75rem;
    margin-left: 2.75rem;
  }
  .mx-sm-6 {
    margin-right: 3rem;
    margin-left: 3rem;
  }
  .mx-sm-7 {
    margin-right: 3.5rem;
    margin-left: 3.5rem;
  }
  .mx-sm-7-5 {
    margin-right: 3.75rem;
    margin-left: 3.75rem;
  }
  .mx-sm-8 {
    margin-right: 4rem;
    margin-left: 4rem;
  }
  .mx-sm-9 {
    margin-right: 4.5rem;
    margin-left: 4.5rem;
  }
  .mx-sm-9-5 {
    margin-right: 4.75rem;
    margin-left: 4.75rem;
  }
  .mx-sm-10 {
    margin-right: 5rem;
    margin-left: 5rem;
  }
  .mx-sm-auto {
    margin-right: auto;
    margin-left: auto;
  }
  .my-sm-0 {
    margin-top: 0;
    margin-bottom: 0;
  }
  .my-sm-0-5 {
    margin-top: 0.25rem;
    margin-bottom: 0.25rem;
  }
  .my-sm-1 {
    margin-top: 0.5rem;
    margin-bottom: 0.5rem;
  }
  .my-sm-1-5 {
    margin-top: 0.75rem;
    margin-bottom: 0.75rem;
  }
  .my-sm-2 {
    margin-top: 1rem;
    margin-bottom: 1rem;
  }
  .my-sm-2-5 {
    margin-top: 1.25rem;
    margin-bottom: 1.25rem;
  }
  .my-sm-3 {
    margin-top: 1.5rem;
    margin-bottom: 1.5rem;
  }
  .my-sm-3-5 {
    margin-top: 1.75rem;
    margin-bottom: 1.75rem;
  }
  .my-sm-4 {
    margin-top: 2rem;
    margin-bottom: 2rem;
  }
  .my-sm-5 {
    margin-top: 2.5rem;
    margin-bottom: 2.5rem;
  }
  .my-sm-5-5 {
    margin-top: 2.75rem;
    margin-bottom: 2.75rem;
  }
  .my-sm-6 {
    margin-top: 3rem;
    margin-bottom: 3rem;
  }
  .my-sm-7 {
    margin-top: 3.5rem;
    margin-bottom: 3.5rem;
  }
  .my-sm-7-5 {
    margin-top: 3.75rem;
    margin-bottom: 3.75rem;
  }
  .my-sm-8 {
    margin-top: 4rem;
    margin-bottom: 4rem;
  }
  .my-sm-9 {
    margin-top: 4.5rem;
    margin-bottom: 4.5rem;
  }
  .my-sm-9-5 {
    margin-top: 4.75rem;
    margin-bottom: 4.75rem;
  }
  .my-sm-10 {
    margin-top: 5rem;
    margin-bottom: 5rem;
  }
  .my-sm-auto {
    margin-top: auto;
    margin-bottom: auto;
  }
  .mt-sm-0 {
    margin-top: 0;
  }
  .mt-sm-0-5 {
    margin-top: 0.25rem;
  }
  .mt-sm-1 {
    margin-top: 0.5rem;
  }
  .mt-sm-1-5 {
    margin-top: 0.75rem;
  }
  .mt-sm-2 {
    margin-top: 1rem;
  }
  .mt-sm-2-5 {
    margin-top: 1.25rem;
  }
  .mt-sm-3 {
    margin-top: 1.5rem;
  }
  .mt-sm-3-5 {
    margin-top: 1.75rem;
  }
  .mt-sm-4 {
    margin-top: 2rem;
  }
  .mt-sm-5 {
    margin-top: 2.5rem;
  }
  .mt-sm-5-5 {
    margin-top: 2.75rem;
  }
  .mt-sm-6 {
    margin-top: 3rem;
  }
  .mt-sm-7 {
    margin-top: 3.5rem;
  }
  .mt-sm-7-5 {
    margin-top: 3.75rem;
  }
  .mt-sm-8 {
    margin-top: 4rem;
  }
  .mt-sm-9 {
    margin-top: 4.5rem;
  }
  .mt-sm-9-5 {
    margin-top: 4.75rem;
  }
  .mt-sm-10 {
    margin-top: 5rem;
  }
  .mt-sm-auto {
    margin-top: auto;
  }
  .me-sm-0 {
    margin-right: 0;
  }
  .me-sm-0-5 {
    margin-right: 0.25rem;
  }
  .me-sm-1 {
    margin-right: 0.5rem;
  }
  .me-sm-1-5 {
    margin-right: 0.75rem;
  }
  .me-sm-2 {
    margin-right: 1rem;
  }
  .me-sm-2-5 {
    margin-right: 1.25rem;
  }
  .me-sm-3 {
    margin-right: 1.5rem;
  }
  .me-sm-3-5 {
    margin-right: 1.75rem;
  }
  .me-sm-4 {
    margin-right: 2rem;
  }
  .me-sm-5 {
    margin-right: 2.5rem;
  }
  .me-sm-5-5 {
    margin-right: 2.75rem;
  }
  .me-sm-6 {
    margin-right: 3rem;
  }
  .me-sm-7 {
    margin-right: 3.5rem;
  }
  .me-sm-7-5 {
    margin-right: 3.75rem;
  }
  .me-sm-8 {
    margin-right: 4rem;
  }
  .me-sm-9 {
    margin-right: 4.5rem;
  }
  .me-sm-9-5 {
    margin-right: 4.75rem;
  }
  .me-sm-10 {
    margin-right: 5rem;
  }
  .me-sm-auto {
    margin-right: auto;
  }
  .mb-sm-0 {
    margin-bottom: 0;
  }
  .mb-sm-0-5 {
    margin-bottom: 0.25rem;
  }
  .mb-sm-1 {
    margin-bottom: 0.5rem;
  }
  .mb-sm-1-5 {
    margin-bottom: 0.75rem;
  }
  .mb-sm-2 {
    margin-bottom: 1rem;
  }
  .mb-sm-2-5 {
    margin-bottom: 1.25rem;
  }
  .mb-sm-3 {
    margin-bottom: 1.5rem;
  }
  .mb-sm-3-5 {
    margin-bottom: 1.75rem;
  }
  .mb-sm-4 {
    margin-bottom: 2rem;
  }
  .mb-sm-5 {
    margin-bottom: 2.5rem;
  }
  .mb-sm-5-5 {
    margin-bottom: 2.75rem;
  }
  .mb-sm-6 {
    margin-bottom: 3rem;
  }
  .mb-sm-7 {
    margin-bottom: 3.5rem;
  }
  .mb-sm-7-5 {
    margin-bottom: 3.75rem;
  }
  .mb-sm-8 {
    margin-bottom: 4rem;
  }
  .mb-sm-9 {
    margin-bottom: 4.5rem;
  }
  .mb-sm-9-5 {
    margin-bottom: 4.75rem;
  }
  .mb-sm-10 {
    margin-bottom: 5rem;
  }
  .mb-sm-auto {
    margin-bottom: auto;
  }
  .ms-sm-0 {
    margin-left: 0;
  }
  .ms-sm-0-5 {
    margin-left: 0.25rem;
  }
  .ms-sm-1 {
    margin-left: 0.5rem;
  }
  .ms-sm-1-5 {
    margin-left: 0.75rem;
  }
  .ms-sm-2 {
    margin-left: 1rem;
  }
  .ms-sm-2-5 {
    margin-left: 1.25rem;
  }
  .ms-sm-3 {
    margin-left: 1.5rem;
  }
  .ms-sm-3-5 {
    margin-left: 1.75rem;
  }
  .ms-sm-4 {
    margin-left: 2rem;
  }
  .ms-sm-5 {
    margin-left: 2.5rem;
  }
  .ms-sm-5-5 {
    margin-left: 2.75rem;
  }
  .ms-sm-6 {
    margin-left: 3rem;
  }
  .ms-sm-7 {
    margin-left: 3.5rem;
  }
  .ms-sm-7-5 {
    margin-left: 3.75rem;
  }
  .ms-sm-8 {
    margin-left: 4rem;
  }
  .ms-sm-9 {
    margin-left: 4.5rem;
  }
  .ms-sm-9-5 {
    margin-left: 4.75rem;
  }
  .ms-sm-10 {
    margin-left: 5rem;
  }
  .ms-sm-auto {
    margin-left: auto;
  }
  .m-sm-n0 {
    margin: 0;
  }
  .m-sm-n0-5 {
    margin: -0.25rem;
  }
  .m-sm-n1 {
    margin: -0.5rem;
  }
  .m-sm-n1-5 {
    margin: -0.75rem;
  }
  .m-sm-n2 {
    margin: -1rem;
  }
  .m-sm-n2-5 {
    margin: -1.25rem;
  }
  .m-sm-n3 {
    margin: -1.5rem;
  }
  .m-sm-n3-5 {
    margin: -1.75rem;
  }
  .m-sm-n4 {
    margin: -2rem;
  }
  .m-sm-n5 {
    margin: -2.5rem;
  }
  .m-sm-n5-5 {
    margin: -2.75rem;
  }
  .m-sm-n6 {
    margin: -3rem;
  }
  .m-sm-n7 {
    margin: -3.5rem;
  }
  .m-sm-n7-5 {
    margin: -3.75rem;
  }
  .m-sm-n8 {
    margin: -4rem;
  }
  .m-sm-n9 {
    margin: -4.5rem;
  }
  .m-sm-n9-5 {
    margin: -4.75rem;
  }
  .m-sm-n10 {
    margin: -5rem;
  }
  .m-sm-nauto {
    margin: -"auto";
  }
  .mx-sm-n0 {
    margin-right: 0;
    margin-left: 0;
  }
  .mx-sm-n0-5 {
    margin-right: -0.25rem;
    margin-left: -0.25rem;
  }
  .mx-sm-n1 {
    margin-right: -0.5rem;
    margin-left: -0.5rem;
  }
  .mx-sm-n1-5 {
    margin-right: -0.75rem;
    margin-left: -0.75rem;
  }
  .mx-sm-n2 {
    margin-right: -1rem;
    margin-left: -1rem;
  }
  .mx-sm-n2-5 {
    margin-right: -1.25rem;
    margin-left: -1.25rem;
  }
  .mx-sm-n3 {
    margin-right: -1.5rem;
    margin-left: -1.5rem;
  }
  .mx-sm-n3-5 {
    margin-right: -1.75rem;
    margin-left: -1.75rem;
  }
  .mx-sm-n4 {
    margin-right: -2rem;
    margin-left: -2rem;
  }
  .mx-sm-n5 {
    margin-right: -2.5rem;
    margin-left: -2.5rem;
  }
  .mx-sm-n5-5 {
    margin-right: -2.75rem;
    margin-left: -2.75rem;
  }
  .mx-sm-n6 {
    margin-right: -3rem;
    margin-left: -3rem;
  }
  .mx-sm-n7 {
    margin-right: -3.5rem;
    margin-left: -3.5rem;
  }
  .mx-sm-n7-5 {
    margin-right: -3.75rem;
    margin-left: -3.75rem;
  }
  .mx-sm-n8 {
    margin-right: -4rem;
    margin-left: -4rem;
  }
  .mx-sm-n9 {
    margin-right: -4.5rem;
    margin-left: -4.5rem;
  }
  .mx-sm-n9-5 {
    margin-right: -4.75rem;
    margin-left: -4.75rem;
  }
  .mx-sm-n10 {
    margin-right: -5rem;
    margin-left: -5rem;
  }
  .mx-sm-nauto {
    margin-right: -"auto";
    margin-left: -"auto";
  }
  .my-sm-n0 {
    margin-top: 0;
    margin-bottom: 0;
  }
  .my-sm-n0-5 {
    margin-top: -0.25rem;
    margin-bottom: -0.25rem;
  }
  .my-sm-n1 {
    margin-top: -0.5rem;
    margin-bottom: -0.5rem;
  }
  .my-sm-n1-5 {
    margin-top: -0.75rem;
    margin-bottom: -0.75rem;
  }
  .my-sm-n2 {
    margin-top: -1rem;
    margin-bottom: -1rem;
  }
  .my-sm-n2-5 {
    margin-top: -1.25rem;
    margin-bottom: -1.25rem;
  }
  .my-sm-n3 {
    margin-top: -1.5rem;
    margin-bottom: -1.5rem;
  }
  .my-sm-n3-5 {
    margin-top: -1.75rem;
    margin-bottom: -1.75rem;
  }
  .my-sm-n4 {
    margin-top: -2rem;
    margin-bottom: -2rem;
  }
  .my-sm-n5 {
    margin-top: -2.5rem;
    margin-bottom: -2.5rem;
  }
  .my-sm-n5-5 {
    margin-top: -2.75rem;
    margin-bottom: -2.75rem;
  }
  .my-sm-n6 {
    margin-top: -3rem;
    margin-bottom: -3rem;
  }
  .my-sm-n7 {
    margin-top: -3.5rem;
    margin-bottom: -3.5rem;
  }
  .my-sm-n7-5 {
    margin-top: -3.75rem;
    margin-bottom: -3.75rem;
  }
  .my-sm-n8 {
    margin-top: -4rem;
    margin-bottom: -4rem;
  }
  .my-sm-n9 {
    margin-top: -4.5rem;
    margin-bottom: -4.5rem;
  }
  .my-sm-n9-5 {
    margin-top: -4.75rem;
    margin-bottom: -4.75rem;
  }
  .my-sm-n10 {
    margin-top: -5rem;
    margin-bottom: -5rem;
  }
  .my-sm-nauto {
    margin-top: -"auto";
    margin-bottom: -"auto";
  }
  .mt-sm-n0 {
    margin-top: 0;
  }
  .mt-sm-n0-5 {
    margin-top: -0.25rem;
  }
  .mt-sm-n1 {
    margin-top: -0.5rem;
  }
  .mt-sm-n1-5 {
    margin-top: -0.75rem;
  }
  .mt-sm-n2 {
    margin-top: -1rem;
  }
  .mt-sm-n2-5 {
    margin-top: -1.25rem;
  }
  .mt-sm-n3 {
    margin-top: -1.5rem;
  }
  .mt-sm-n3-5 {
    margin-top: -1.75rem;
  }
  .mt-sm-n4 {
    margin-top: -2rem;
  }
  .mt-sm-n5 {
    margin-top: -2.5rem;
  }
  .mt-sm-n5-5 {
    margin-top: -2.75rem;
  }
  .mt-sm-n6 {
    margin-top: -3rem;
  }
  .mt-sm-n7 {
    margin-top: -3.5rem;
  }
  .mt-sm-n7-5 {
    margin-top: -3.75rem;
  }
  .mt-sm-n8 {
    margin-top: -4rem;
  }
  .mt-sm-n9 {
    margin-top: -4.5rem;
  }
  .mt-sm-n9-5 {
    margin-top: -4.75rem;
  }
  .mt-sm-n10 {
    margin-top: -5rem;
  }
  .mt-sm-nauto {
    margin-top: -"auto";
  }
  .me-sm-n0 {
    margin-right: 0;
  }
  .me-sm-n0-5 {
    margin-right: -0.25rem;
  }
  .me-sm-n1 {
    margin-right: -0.5rem;
  }
  .me-sm-n1-5 {
    margin-right: -0.75rem;
  }
  .me-sm-n2 {
    margin-right: -1rem;
  }
  .me-sm-n2-5 {
    margin-right: -1.25rem;
  }
  .me-sm-n3 {
    margin-right: -1.5rem;
  }
  .me-sm-n3-5 {
    margin-right: -1.75rem;
  }
  .me-sm-n4 {
    margin-right: -2rem;
  }
  .me-sm-n5 {
    margin-right: -2.5rem;
  }
  .me-sm-n5-5 {
    margin-right: -2.75rem;
  }
  .me-sm-n6 {
    margin-right: -3rem;
  }
  .me-sm-n7 {
    margin-right: -3.5rem;
  }
  .me-sm-n7-5 {
    margin-right: -3.75rem;
  }
  .me-sm-n8 {
    margin-right: -4rem;
  }
  .me-sm-n9 {
    margin-right: -4.5rem;
  }
  .me-sm-n9-5 {
    margin-right: -4.75rem;
  }
  .me-sm-n10 {
    margin-right: -5rem;
  }
  .me-sm-nauto {
    margin-right: -"auto";
  }
  .mb-sm-n0 {
    margin-bottom: 0;
  }
  .mb-sm-n0-5 {
    margin-bottom: -0.25rem;
  }
  .mb-sm-n1 {
    margin-bottom: -0.5rem;
  }
  .mb-sm-n1-5 {
    margin-bottom: -0.75rem;
  }
  .mb-sm-n2 {
    margin-bottom: -1rem;
  }
  .mb-sm-n2-5 {
    margin-bottom: -1.25rem;
  }
  .mb-sm-n3 {
    margin-bottom: -1.5rem;
  }
  .mb-sm-n3-5 {
    margin-bottom: -1.75rem;
  }
  .mb-sm-n4 {
    margin-bottom: -2rem;
  }
  .mb-sm-n5 {
    margin-bottom: -2.5rem;
  }
  .mb-sm-n5-5 {
    margin-bottom: -2.75rem;
  }
  .mb-sm-n6 {
    margin-bottom: -3rem;
  }
  .mb-sm-n7 {
    margin-bottom: -3.5rem;
  }
  .mb-sm-n7-5 {
    margin-bottom: -3.75rem;
  }
  .mb-sm-n8 {
    margin-bottom: -4rem;
  }
  .mb-sm-n9 {
    margin-bottom: -4.5rem;
  }
  .mb-sm-n9-5 {
    margin-bottom: -4.75rem;
  }
  .mb-sm-n10 {
    margin-bottom: -5rem;
  }
  .mb-sm-nauto {
    margin-bottom: -"auto";
  }
  .ms-sm-n0 {
    margin-left: 0;
  }
  .ms-sm-n0-5 {
    margin-left: -0.25rem;
  }
  .ms-sm-n1 {
    margin-left: -0.5rem;
  }
  .ms-sm-n1-5 {
    margin-left: -0.75rem;
  }
  .ms-sm-n2 {
    margin-left: -1rem;
  }
  .ms-sm-n2-5 {
    margin-left: -1.25rem;
  }
  .ms-sm-n3 {
    margin-left: -1.5rem;
  }
  .ms-sm-n3-5 {
    margin-left: -1.75rem;
  }
  .ms-sm-n4 {
    margin-left: -2rem;
  }
  .ms-sm-n5 {
    margin-left: -2.5rem;
  }
  .ms-sm-n5-5 {
    margin-left: -2.75rem;
  }
  .ms-sm-n6 {
    margin-left: -3rem;
  }
  .ms-sm-n7 {
    margin-left: -3.5rem;
  }
  .ms-sm-n7-5 {
    margin-left: -3.75rem;
  }
  .ms-sm-n8 {
    margin-left: -4rem;
  }
  .ms-sm-n9 {
    margin-left: -4.5rem;
  }
  .ms-sm-n9-5 {
    margin-left: -4.75rem;
  }
  .ms-sm-n10 {
    margin-left: -5rem;
  }
  .ms-sm-nauto {
    margin-left: -"auto";
  }
  .p-sm-0 {
    padding: 0;
  }
  .p-sm-0-5 {
    padding: 0.25rem;
  }
  .p-sm-1 {
    padding: 0.5rem;
  }
  .p-sm-1-5 {
    padding: 0.75rem;
  }
  .p-sm-2 {
    padding: 1rem;
  }
  .p-sm-2-5 {
    padding: 1.25rem;
  }
  .p-sm-3 {
    padding: 1.5rem;
  }
  .p-sm-3-5 {
    padding: 1.75rem;
  }
  .p-sm-4 {
    padding: 2rem;
  }
  .p-sm-5 {
    padding: 2.5rem;
  }
  .p-sm-5-5 {
    padding: 2.75rem;
  }
  .p-sm-6 {
    padding: 3rem;
  }
  .p-sm-7 {
    padding: 3.5rem;
  }
  .p-sm-7-5 {
    padding: 3.75rem;
  }
  .p-sm-8 {
    padding: 4rem;
  }
  .p-sm-9 {
    padding: 4.5rem;
  }
  .p-sm-9-5 {
    padding: 4.75rem;
  }
  .p-sm-10 {
    padding: 5rem;
  }
  .p-sm-auto {
    padding: "auto";
  }
  .px-sm-0 {
    padding-right: 0;
    padding-left: 0;
  }
  .px-sm-0-5 {
    padding-right: 0.25rem;
    padding-left: 0.25rem;
  }
  .px-sm-1 {
    padding-right: 0.5rem;
    padding-left: 0.5rem;
  }
  .px-sm-1-5 {
    padding-right: 0.75rem;
    padding-left: 0.75rem;
  }
  .px-sm-2 {
    padding-right: 1rem;
    padding-left: 1rem;
  }
  .px-sm-2-5 {
    padding-right: 1.25rem;
    padding-left: 1.25rem;
  }
  .px-sm-3 {
    padding-right: 1.5rem;
    padding-left: 1.5rem;
  }
  .px-sm-3-5 {
    padding-right: 1.75rem;
    padding-left: 1.75rem;
  }
  .px-sm-4 {
    padding-right: 2rem;
    padding-left: 2rem;
  }
  .px-sm-5 {
    padding-right: 2.5rem;
    padding-left: 2.5rem;
  }
  .px-sm-5-5 {
    padding-right: 2.75rem;
    padding-left: 2.75rem;
  }
  .px-sm-6 {
    padding-right: 3rem;
    padding-left: 3rem;
  }
  .px-sm-7 {
    padding-right: 3.5rem;
    padding-left: 3.5rem;
  }
  .px-sm-7-5 {
    padding-right: 3.75rem;
    padding-left: 3.75rem;
  }
  .px-sm-8 {
    padding-right: 4rem;
    padding-left: 4rem;
  }
  .px-sm-9 {
    padding-right: 4.5rem;
    padding-left: 4.5rem;
  }
  .px-sm-9-5 {
    padding-right: 4.75rem;
    padding-left: 4.75rem;
  }
  .px-sm-10 {
    padding-right: 5rem;
    padding-left: 5rem;
  }
  .px-sm-auto {
    padding-right: "auto";
    padding-left: "auto";
  }
  .py-sm-0 {
    padding-top: 0;
    padding-bottom: 0;
  }
  .py-sm-0-5 {
    padding-top: 0.25rem;
    padding-bottom: 0.25rem;
  }
  .py-sm-1 {
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
  }
  .py-sm-1-5 {
    padding-top: 0.75rem;
    padding-bottom: 0.75rem;
  }
  .py-sm-2 {
    padding-top: 1rem;
    padding-bottom: 1rem;
  }
  .py-sm-2-5 {
    padding-top: 1.25rem;
    padding-bottom: 1.25rem;
  }
  .py-sm-3 {
    padding-top: 1.5rem;
    padding-bottom: 1.5rem;
  }
  .py-sm-3-5 {
    padding-top: 1.75rem;
    padding-bottom: 1.75rem;
  }
  .py-sm-4 {
    padding-top: 2rem;
    padding-bottom: 2rem;
  }
  .py-sm-5 {
    padding-top: 2.5rem;
    padding-bottom: 2.5rem;
  }
  .py-sm-5-5 {
    padding-top: 2.75rem;
    padding-bottom: 2.75rem;
  }
  .py-sm-6 {
    padding-top: 3rem;
    padding-bottom: 3rem;
  }
  .py-sm-7 {
    padding-top: 3.5rem;
    padding-bottom: 3.5rem;
  }
  .py-sm-7-5 {
    padding-top: 3.75rem;
    padding-bottom: 3.75rem;
  }
  .py-sm-8 {
    padding-top: 4rem;
    padding-bottom: 4rem;
  }
  .py-sm-9 {
    padding-top: 4.5rem;
    padding-bottom: 4.5rem;
  }
  .py-sm-9-5 {
    padding-top: 4.75rem;
    padding-bottom: 4.75rem;
  }
  .py-sm-10 {
    padding-top: 5rem;
    padding-bottom: 5rem;
  }
  .py-sm-auto {
    padding-top: "auto";
    padding-bottom: "auto";
  }
  .pt-sm-0 {
    padding-top: 0;
  }
  .pt-sm-0-5 {
    padding-top: 0.25rem;
  }
  .pt-sm-1 {
    padding-top: 0.5rem;
  }
  .pt-sm-1-5 {
    padding-top: 0.75rem;
  }
  .pt-sm-2 {
    padding-top: 1rem;
  }
  .pt-sm-2-5 {
    padding-top: 1.25rem;
  }
  .pt-sm-3 {
    padding-top: 1.5rem;
  }
  .pt-sm-3-5 {
    padding-top: 1.75rem;
  }
  .pt-sm-4 {
    padding-top: 2rem;
  }
  .pt-sm-5 {
    padding-top: 2.5rem;
  }
  .pt-sm-5-5 {
    padding-top: 2.75rem;
  }
  .pt-sm-6 {
    padding-top: 3rem;
  }
  .pt-sm-7 {
    padding-top: 3.5rem;
  }
  .pt-sm-7-5 {
    padding-top: 3.75rem;
  }
  .pt-sm-8 {
    padding-top: 4rem;
  }
  .pt-sm-9 {
    padding-top: 4.5rem;
  }
  .pt-sm-9-5 {
    padding-top: 4.75rem;
  }
  .pt-sm-10 {
    padding-top: 5rem;
  }
  .pt-sm-auto {
    padding-top: "auto";
  }
  .pe-sm-0 {
    padding-right: 0;
  }
  .pe-sm-0-5 {
    padding-right: 0.25rem;
  }
  .pe-sm-1 {
    padding-right: 0.5rem;
  }
  .pe-sm-1-5 {
    padding-right: 0.75rem;
  }
  .pe-sm-2 {
    padding-right: 1rem;
  }
  .pe-sm-2-5 {
    padding-right: 1.25rem;
  }
  .pe-sm-3 {
    padding-right: 1.5rem;
  }
  .pe-sm-3-5 {
    padding-right: 1.75rem;
  }
  .pe-sm-4 {
    padding-right: 2rem;
  }
  .pe-sm-5 {
    padding-right: 2.5rem;
  }
  .pe-sm-5-5 {
    padding-right: 2.75rem;
  }
  .pe-sm-6 {
    padding-right: 3rem;
  }
  .pe-sm-7 {
    padding-right: 3.5rem;
  }
  .pe-sm-7-5 {
    padding-right: 3.75rem;
  }
  .pe-sm-8 {
    padding-right: 4rem;
  }
  .pe-sm-9 {
    padding-right: 4.5rem;
  }
  .pe-sm-9-5 {
    padding-right: 4.75rem;
  }
  .pe-sm-10 {
    padding-right: 5rem;
  }
  .pe-sm-auto {
    padding-right: "auto";
  }
  .pb-sm-0 {
    padding-bottom: 0;
  }
  .pb-sm-0-5 {
    padding-bottom: 0.25rem;
  }
  .pb-sm-1 {
    padding-bottom: 0.5rem;
  }
  .pb-sm-1-5 {
    padding-bottom: 0.75rem;
  }
  .pb-sm-2 {
    padding-bottom: 1rem;
  }
  .pb-sm-2-5 {
    padding-bottom: 1.25rem;
  }
  .pb-sm-3 {
    padding-bottom: 1.5rem;
  }
  .pb-sm-3-5 {
    padding-bottom: 1.75rem;
  }
  .pb-sm-4 {
    padding-bottom: 2rem;
  }
  .pb-sm-5 {
    padding-bottom: 2.5rem;
  }
  .pb-sm-5-5 {
    padding-bottom: 2.75rem;
  }
  .pb-sm-6 {
    padding-bottom: 3rem;
  }
  .pb-sm-7 {
    padding-bottom: 3.5rem;
  }
  .pb-sm-7-5 {
    padding-bottom: 3.75rem;
  }
  .pb-sm-8 {
    padding-bottom: 4rem;
  }
  .pb-sm-9 {
    padding-bottom: 4.5rem;
  }
  .pb-sm-9-5 {
    padding-bottom: 4.75rem;
  }
  .pb-sm-10 {
    padding-bottom: 5rem;
  }
  .pb-sm-auto {
    padding-bottom: "auto";
  }
  .ps-sm-0 {
    padding-left: 0;
  }
  .ps-sm-0-5 {
    padding-left: 0.25rem;
  }
  .ps-sm-1 {
    padding-left: 0.5rem;
  }
  .ps-sm-1-5 {
    padding-left: 0.75rem;
  }
  .ps-sm-2 {
    padding-left: 1rem;
  }
  .ps-sm-2-5 {
    padding-left: 1.25rem;
  }
  .ps-sm-3 {
    padding-left: 1.5rem;
  }
  .ps-sm-3-5 {
    padding-left: 1.75rem;
  }
  .ps-sm-4 {
    padding-left: 2rem;
  }
  .ps-sm-5 {
    padding-left: 2.5rem;
  }
  .ps-sm-5-5 {
    padding-left: 2.75rem;
  }
  .ps-sm-6 {
    padding-left: 3rem;
  }
  .ps-sm-7 {
    padding-left: 3.5rem;
  }
  .ps-sm-7-5 {
    padding-left: 3.75rem;
  }
  .ps-sm-8 {
    padding-left: 4rem;
  }
  .ps-sm-9 {
    padding-left: 4.5rem;
  }
  .ps-sm-9-5 {
    padding-left: 4.75rem;
  }
  .ps-sm-10 {
    padding-left: 5rem;
  }
  .ps-sm-auto {
    padding-left: "auto";
  }
  .gap-sm-0 {
    gap: 0;
  }
  .gap-sm-0-5 {
    gap: 0.25rem;
  }
  .gap-sm-1 {
    gap: 0.5rem;
  }
  .gap-sm-1-5 {
    gap: 0.75rem;
  }
  .gap-sm-2 {
    gap: 1rem;
  }
  .gap-sm-2-5 {
    gap: 1.25rem;
  }
  .gap-sm-3 {
    gap: 1.5rem;
  }
  .gap-sm-3-5 {
    gap: 1.75rem;
  }
  .gap-sm-4 {
    gap: 2rem;
  }
  .gap-sm-5 {
    gap: 2.5rem;
  }
  .gap-sm-5-5 {
    gap: 2.75rem;
  }
  .gap-sm-6 {
    gap: 3rem;
  }
  .gap-sm-7 {
    gap: 3.5rem;
  }
  .gap-sm-7-5 {
    gap: 3.75rem;
  }
  .gap-sm-8 {
    gap: 4rem;
  }
  .gap-sm-9 {
    gap: 4.5rem;
  }
  .gap-sm-9-5 {
    gap: 4.75rem;
  }
  .gap-sm-10 {
    gap: 5rem;
  }
  .gap-sm-auto {
    gap: "auto";
  }
  .text-sm-start {
    text-align: left;
  }
  .text-sm-end {
    text-align: right;
  }
  .text-sm-center {
    text-align: center;
  }
}
@media (min-width: 768px) {
  .float-md-start {
    float: left;
  }
  .float-md-end {
    float: right;
  }
  .float-md-none {
    float: none;
  }
  .d-md-inline {
    display: inline;
  }
  .d-md-inline-block {
    display: inline-block;
  }
  .d-md-block {
    display: block;
  }
  .d-md-grid {
    display: grid;
  }
  .d-md-table {
    display: table;
  }
  .d-md-table-row {
    display: table-row;
  }
  .d-md-table-cell {
    display: table-cell;
  }
  .d-md-flex {
    display: flex;
  }
  .d-md-inline-flex {
    display: inline-flex;
  }
  .d-md-none {
    display: none;
  }
  .flex-md-fill {
    flex: 1 1 auto;
  }
  .flex-md-row {
    flex-direction: row;
  }
  .flex-md-column {
    flex-direction: column;
  }
  .flex-md-row-reverse {
    flex-direction: row-reverse;
  }
  .flex-md-column-reverse {
    flex-direction: column-reverse;
  }
  .flex-md-grow-0 {
    flex-grow: 0;
  }
  .flex-md-grow-1 {
    flex-grow: 1;
  }
  .flex-md-shrink-0 {
    flex-shrink: 0;
  }
  .flex-md-shrink-1 {
    flex-shrink: 1;
  }
  .flex-md-wrap {
    flex-wrap: wrap;
  }
  .flex-md-nowrap {
    flex-wrap: nowrap;
  }
  .flex-md-wrap-reverse {
    flex-wrap: wrap-reverse;
  }
  .justify-content-md-start {
    justify-content: flex-start;
  }
  .justify-content-md-end {
    justify-content: flex-end;
  }
  .justify-content-md-center {
    justify-content: center;
  }
  .justify-content-md-between {
    justify-content: space-between;
  }
  .justify-content-md-around {
    justify-content: space-around;
  }
  .justify-content-md-evenly {
    justify-content: space-evenly;
  }
  .align-items-md-start {
    align-items: flex-start;
  }
  .align-items-md-end {
    align-items: flex-end;
  }
  .align-items-md-center {
    align-items: center;
  }
  .align-items-md-baseline {
    align-items: baseline;
  }
  .align-items-md-stretch {
    align-items: stretch;
  }
  .align-content-md-start {
    align-content: flex-start;
  }
  .align-content-md-end {
    align-content: flex-end;
  }
  .align-content-md-center {
    align-content: center;
  }
  .align-content-md-between {
    align-content: space-between;
  }
  .align-content-md-around {
    align-content: space-around;
  }
  .align-content-md-stretch {
    align-content: stretch;
  }
  .align-self-md-auto {
    align-self: auto;
  }
  .align-self-md-start {
    align-self: flex-start;
  }
  .align-self-md-end {
    align-self: flex-end;
  }
  .align-self-md-center {
    align-self: center;
  }
  .align-self-md-baseline {
    align-self: baseline;
  }
  .align-self-md-stretch {
    align-self: stretch;
  }
  .order-md-first {
    order: -1;
  }
  .order-md-0 {
    order: 0;
  }
  .order-md-1 {
    order: 1;
  }
  .order-md-2 {
    order: 2;
  }
  .order-md-3 {
    order: 3;
  }
  .order-md-4 {
    order: 4;
  }
  .order-md-5 {
    order: 5;
  }
  .order-md-last {
    order: 6;
  }
  .m-md-0 {
    margin: 0;
  }
  .m-md-0-5 {
    margin: 0.25rem;
  }
  .m-md-1 {
    margin: 0.5rem;
  }
  .m-md-1-5 {
    margin: 0.75rem;
  }
  .m-md-2 {
    margin: 1rem;
  }
  .m-md-2-5 {
    margin: 1.25rem;
  }
  .m-md-3 {
    margin: 1.5rem;
  }
  .m-md-3-5 {
    margin: 1.75rem;
  }
  .m-md-4 {
    margin: 2rem;
  }
  .m-md-5 {
    margin: 2.5rem;
  }
  .m-md-5-5 {
    margin: 2.75rem;
  }
  .m-md-6 {
    margin: 3rem;
  }
  .m-md-7 {
    margin: 3.5rem;
  }
  .m-md-7-5 {
    margin: 3.75rem;
  }
  .m-md-8 {
    margin: 4rem;
  }
  .m-md-9 {
    margin: 4.5rem;
  }
  .m-md-9-5 {
    margin: 4.75rem;
  }
  .m-md-10 {
    margin: 5rem;
  }
  .m-md-auto {
    margin: auto;
  }
  .mx-md-0 {
    margin-right: 0;
    margin-left: 0;
  }
  .mx-md-0-5 {
    margin-right: 0.25rem;
    margin-left: 0.25rem;
  }
  .mx-md-1 {
    margin-right: 0.5rem;
    margin-left: 0.5rem;
  }
  .mx-md-1-5 {
    margin-right: 0.75rem;
    margin-left: 0.75rem;
  }
  .mx-md-2 {
    margin-right: 1rem;
    margin-left: 1rem;
  }
  .mx-md-2-5 {
    margin-right: 1.25rem;
    margin-left: 1.25rem;
  }
  .mx-md-3 {
    margin-right: 1.5rem;
    margin-left: 1.5rem;
  }
  .mx-md-3-5 {
    margin-right: 1.75rem;
    margin-left: 1.75rem;
  }
  .mx-md-4 {
    margin-right: 2rem;
    margin-left: 2rem;
  }
  .mx-md-5 {
    margin-right: 2.5rem;
    margin-left: 2.5rem;
  }
  .mx-md-5-5 {
    margin-right: 2.75rem;
    margin-left: 2.75rem;
  }
  .mx-md-6 {
    margin-right: 3rem;
    margin-left: 3rem;
  }
  .mx-md-7 {
    margin-right: 3.5rem;
    margin-left: 3.5rem;
  }
  .mx-md-7-5 {
    margin-right: 3.75rem;
    margin-left: 3.75rem;
  }
  .mx-md-8 {
    margin-right: 4rem;
    margin-left: 4rem;
  }
  .mx-md-9 {
    margin-right: 4.5rem;
    margin-left: 4.5rem;
  }
  .mx-md-9-5 {
    margin-right: 4.75rem;
    margin-left: 4.75rem;
  }
  .mx-md-10 {
    margin-right: 5rem;
    margin-left: 5rem;
  }
  .mx-md-auto {
    margin-right: auto;
    margin-left: auto;
  }
  .my-md-0 {
    margin-top: 0;
    margin-bottom: 0;
  }
  .my-md-0-5 {
    margin-top: 0.25rem;
    margin-bottom: 0.25rem;
  }
  .my-md-1 {
    margin-top: 0.5rem;
    margin-bottom: 0.5rem;
  }
  .my-md-1-5 {
    margin-top: 0.75rem;
    margin-bottom: 0.75rem;
  }
  .my-md-2 {
    margin-top: 1rem;
    margin-bottom: 1rem;
  }
  .my-md-2-5 {
    margin-top: 1.25rem;
    margin-bottom: 1.25rem;
  }
  .my-md-3 {
    margin-top: 1.5rem;
    margin-bottom: 1.5rem;
  }
  .my-md-3-5 {
    margin-top: 1.75rem;
    margin-bottom: 1.75rem;
  }
  .my-md-4 {
    margin-top: 2rem;
    margin-bottom: 2rem;
  }
  .my-md-5 {
    margin-top: 2.5rem;
    margin-bottom: 2.5rem;
  }
  .my-md-5-5 {
    margin-top: 2.75rem;
    margin-bottom: 2.75rem;
  }
  .my-md-6 {
    margin-top: 3rem;
    margin-bottom: 3rem;
  }
  .my-md-7 {
    margin-top: 3.5rem;
    margin-bottom: 3.5rem;
  }
  .my-md-7-5 {
    margin-top: 3.75rem;
    margin-bottom: 3.75rem;
  }
  .my-md-8 {
    margin-top: 4rem;
    margin-bottom: 4rem;
  }
  .my-md-9 {
    margin-top: 4.5rem;
    margin-bottom: 4.5rem;
  }
  .my-md-9-5 {
    margin-top: 4.75rem;
    margin-bottom: 4.75rem;
  }
  .my-md-10 {
    margin-top: 5rem;
    margin-bottom: 5rem;
  }
  .my-md-auto {
    margin-top: auto;
    margin-bottom: auto;
  }
  .mt-md-0 {
    margin-top: 0;
  }
  .mt-md-0-5 {
    margin-top: 0.25rem;
  }
  .mt-md-1 {
    margin-top: 0.5rem;
  }
  .mt-md-1-5 {
    margin-top: 0.75rem;
  }
  .mt-md-2 {
    margin-top: 1rem;
  }
  .mt-md-2-5 {
    margin-top: 1.25rem;
  }
  .mt-md-3 {
    margin-top: 1.5rem;
  }
  .mt-md-3-5 {
    margin-top: 1.75rem;
  }
  .mt-md-4 {
    margin-top: 2rem;
  }
  .mt-md-5 {
    margin-top: 2.5rem;
  }
  .mt-md-5-5 {
    margin-top: 2.75rem;
  }
  .mt-md-6 {
    margin-top: 3rem;
  }
  .mt-md-7 {
    margin-top: 3.5rem;
  }
  .mt-md-7-5 {
    margin-top: 3.75rem;
  }
  .mt-md-8 {
    margin-top: 4rem;
  }
  .mt-md-9 {
    margin-top: 4.5rem;
  }
  .mt-md-9-5 {
    margin-top: 4.75rem;
  }
  .mt-md-10 {
    margin-top: 5rem;
  }
  .mt-md-auto {
    margin-top: auto;
  }
  .me-md-0 {
    margin-right: 0;
  }
  .me-md-0-5 {
    margin-right: 0.25rem;
  }
  .me-md-1 {
    margin-right: 0.5rem;
  }
  .me-md-1-5 {
    margin-right: 0.75rem;
  }
  .me-md-2 {
    margin-right: 1rem;
  }
  .me-md-2-5 {
    margin-right: 1.25rem;
  }
  .me-md-3 {
    margin-right: 1.5rem;
  }
  .me-md-3-5 {
    margin-right: 1.75rem;
  }
  .me-md-4 {
    margin-right: 2rem;
  }
  .me-md-5 {
    margin-right: 2.5rem;
  }
  .me-md-5-5 {
    margin-right: 2.75rem;
  }
  .me-md-6 {
    margin-right: 3rem;
  }
  .me-md-7 {
    margin-right: 3.5rem;
  }
  .me-md-7-5 {
    margin-right: 3.75rem;
  }
  .me-md-8 {
    margin-right: 4rem;
  }
  .me-md-9 {
    margin-right: 4.5rem;
  }
  .me-md-9-5 {
    margin-right: 4.75rem;
  }
  .me-md-10 {
    margin-right: 5rem;
  }
  .me-md-auto {
    margin-right: auto;
  }
  .mb-md-0 {
    margin-bottom: 0;
  }
  .mb-md-0-5 {
    margin-bottom: 0.25rem;
  }
  .mb-md-1 {
    margin-bottom: 0.5rem;
  }
  .mb-md-1-5 {
    margin-bottom: 0.75rem;
  }
  .mb-md-2 {
    margin-bottom: 1rem;
  }
  .mb-md-2-5 {
    margin-bottom: 1.25rem;
  }
  .mb-md-3 {
    margin-bottom: 1.5rem;
  }
  .mb-md-3-5 {
    margin-bottom: 1.75rem;
  }
  .mb-md-4 {
    margin-bottom: 2rem;
  }
  .mb-md-5 {
    margin-bottom: 2.5rem;
  }
  .mb-md-5-5 {
    margin-bottom: 2.75rem;
  }
  .mb-md-6 {
    margin-bottom: 3rem;
  }
  .mb-md-7 {
    margin-bottom: 3.5rem;
  }
  .mb-md-7-5 {
    margin-bottom: 3.75rem;
  }
  .mb-md-8 {
    margin-bottom: 4rem;
  }
  .mb-md-9 {
    margin-bottom: 4.5rem;
  }
  .mb-md-9-5 {
    margin-bottom: 4.75rem;
  }
  .mb-md-10 {
    margin-bottom: 5rem;
  }
  .mb-md-auto {
    margin-bottom: auto;
  }
  .ms-md-0 {
    margin-left: 0;
  }
  .ms-md-0-5 {
    margin-left: 0.25rem;
  }
  .ms-md-1 {
    margin-left: 0.5rem;
  }
  .ms-md-1-5 {
    margin-left: 0.75rem;
  }
  .ms-md-2 {
    margin-left: 1rem;
  }
  .ms-md-2-5 {
    margin-left: 1.25rem;
  }
  .ms-md-3 {
    margin-left: 1.5rem;
  }
  .ms-md-3-5 {
    margin-left: 1.75rem;
  }
  .ms-md-4 {
    margin-left: 2rem;
  }
  .ms-md-5 {
    margin-left: 2.5rem;
  }
  .ms-md-5-5 {
    margin-left: 2.75rem;
  }
  .ms-md-6 {
    margin-left: 3rem;
  }
  .ms-md-7 {
    margin-left: 3.5rem;
  }
  .ms-md-7-5 {
    margin-left: 3.75rem;
  }
  .ms-md-8 {
    margin-left: 4rem;
  }
  .ms-md-9 {
    margin-left: 4.5rem;
  }
  .ms-md-9-5 {
    margin-left: 4.75rem;
  }
  .ms-md-10 {
    margin-left: 5rem;
  }
  .ms-md-auto {
    margin-left: auto;
  }
  .m-md-n0 {
    margin: 0;
  }
  .m-md-n0-5 {
    margin: -0.25rem;
  }
  .m-md-n1 {
    margin: -0.5rem;
  }
  .m-md-n1-5 {
    margin: -0.75rem;
  }
  .m-md-n2 {
    margin: -1rem;
  }
  .m-md-n2-5 {
    margin: -1.25rem;
  }
  .m-md-n3 {
    margin: -1.5rem;
  }
  .m-md-n3-5 {
    margin: -1.75rem;
  }
  .m-md-n4 {
    margin: -2rem;
  }
  .m-md-n5 {
    margin: -2.5rem;
  }
  .m-md-n5-5 {
    margin: -2.75rem;
  }
  .m-md-n6 {
    margin: -3rem;
  }
  .m-md-n7 {
    margin: -3.5rem;
  }
  .m-md-n7-5 {
    margin: -3.75rem;
  }
  .m-md-n8 {
    margin: -4rem;
  }
  .m-md-n9 {
    margin: -4.5rem;
  }
  .m-md-n9-5 {
    margin: -4.75rem;
  }
  .m-md-n10 {
    margin: -5rem;
  }
  .m-md-nauto {
    margin: -"auto";
  }
  .mx-md-n0 {
    margin-right: 0;
    margin-left: 0;
  }
  .mx-md-n0-5 {
    margin-right: -0.25rem;
    margin-left: -0.25rem;
  }
  .mx-md-n1 {
    margin-right: -0.5rem;
    margin-left: -0.5rem;
  }
  .mx-md-n1-5 {
    margin-right: -0.75rem;
    margin-left: -0.75rem;
  }
  .mx-md-n2 {
    margin-right: -1rem;
    margin-left: -1rem;
  }
  .mx-md-n2-5 {
    margin-right: -1.25rem;
    margin-left: -1.25rem;
  }
  .mx-md-n3 {
    margin-right: -1.5rem;
    margin-left: -1.5rem;
  }
  .mx-md-n3-5 {
    margin-right: -1.75rem;
    margin-left: -1.75rem;
  }
  .mx-md-n4 {
    margin-right: -2rem;
    margin-left: -2rem;
  }
  .mx-md-n5 {
    margin-right: -2.5rem;
    margin-left: -2.5rem;
  }
  .mx-md-n5-5 {
    margin-right: -2.75rem;
    margin-left: -2.75rem;
  }
  .mx-md-n6 {
    margin-right: -3rem;
    margin-left: -3rem;
  }
  .mx-md-n7 {
    margin-right: -3.5rem;
    margin-left: -3.5rem;
  }
  .mx-md-n7-5 {
    margin-right: -3.75rem;
    margin-left: -3.75rem;
  }
  .mx-md-n8 {
    margin-right: -4rem;
    margin-left: -4rem;
  }
  .mx-md-n9 {
    margin-right: -4.5rem;
    margin-left: -4.5rem;
  }
  .mx-md-n9-5 {
    margin-right: -4.75rem;
    margin-left: -4.75rem;
  }
  .mx-md-n10 {
    margin-right: -5rem;
    margin-left: -5rem;
  }
  .mx-md-nauto {
    margin-right: -"auto";
    margin-left: -"auto";
  }
  .my-md-n0 {
    margin-top: 0;
    margin-bottom: 0;
  }
  .my-md-n0-5 {
    margin-top: -0.25rem;
    margin-bottom: -0.25rem;
  }
  .my-md-n1 {
    margin-top: -0.5rem;
    margin-bottom: -0.5rem;
  }
  .my-md-n1-5 {
    margin-top: -0.75rem;
    margin-bottom: -0.75rem;
  }
  .my-md-n2 {
    margin-top: -1rem;
    margin-bottom: -1rem;
  }
  .my-md-n2-5 {
    margin-top: -1.25rem;
    margin-bottom: -1.25rem;
  }
  .my-md-n3 {
    margin-top: -1.5rem;
    margin-bottom: -1.5rem;
  }
  .my-md-n3-5 {
    margin-top: -1.75rem;
    margin-bottom: -1.75rem;
  }
  .my-md-n4 {
    margin-top: -2rem;
    margin-bottom: -2rem;
  }
  .my-md-n5 {
    margin-top: -2.5rem;
    margin-bottom: -2.5rem;
  }
  .my-md-n5-5 {
    margin-top: -2.75rem;
    margin-bottom: -2.75rem;
  }
  .my-md-n6 {
    margin-top: -3rem;
    margin-bottom: -3rem;
  }
  .my-md-n7 {
    margin-top: -3.5rem;
    margin-bottom: -3.5rem;
  }
  .my-md-n7-5 {
    margin-top: -3.75rem;
    margin-bottom: -3.75rem;
  }
  .my-md-n8 {
    margin-top: -4rem;
    margin-bottom: -4rem;
  }
  .my-md-n9 {
    margin-top: -4.5rem;
    margin-bottom: -4.5rem;
  }
  .my-md-n9-5 {
    margin-top: -4.75rem;
    margin-bottom: -4.75rem;
  }
  .my-md-n10 {
    margin-top: -5rem;
    margin-bottom: -5rem;
  }
  .my-md-nauto {
    margin-top: -"auto";
    margin-bottom: -"auto";
  }
  .mt-md-n0 {
    margin-top: 0;
  }
  .mt-md-n0-5 {
    margin-top: -0.25rem;
  }
  .mt-md-n1 {
    margin-top: -0.5rem;
  }
  .mt-md-n1-5 {
    margin-top: -0.75rem;
  }
  .mt-md-n2 {
    margin-top: -1rem;
  }
  .mt-md-n2-5 {
    margin-top: -1.25rem;
  }
  .mt-md-n3 {
    margin-top: -1.5rem;
  }
  .mt-md-n3-5 {
    margin-top: -1.75rem;
  }
  .mt-md-n4 {
    margin-top: -2rem;
  }
  .mt-md-n5 {
    margin-top: -2.5rem;
  }
  .mt-md-n5-5 {
    margin-top: -2.75rem;
  }
  .mt-md-n6 {
    margin-top: -3rem;
  }
  .mt-md-n7 {
    margin-top: -3.5rem;
  }
  .mt-md-n7-5 {
    margin-top: -3.75rem;
  }
  .mt-md-n8 {
    margin-top: -4rem;
  }
  .mt-md-n9 {
    margin-top: -4.5rem;
  }
  .mt-md-n9-5 {
    margin-top: -4.75rem;
  }
  .mt-md-n10 {
    margin-top: -5rem;
  }
  .mt-md-nauto {
    margin-top: -"auto";
  }
  .me-md-n0 {
    margin-right: 0;
  }
  .me-md-n0-5 {
    margin-right: -0.25rem;
  }
  .me-md-n1 {
    margin-right: -0.5rem;
  }
  .me-md-n1-5 {
    margin-right: -0.75rem;
  }
  .me-md-n2 {
    margin-right: -1rem;
  }
  .me-md-n2-5 {
    margin-right: -1.25rem;
  }
  .me-md-n3 {
    margin-right: -1.5rem;
  }
  .me-md-n3-5 {
    margin-right: -1.75rem;
  }
  .me-md-n4 {
    margin-right: -2rem;
  }
  .me-md-n5 {
    margin-right: -2.5rem;
  }
  .me-md-n5-5 {
    margin-right: -2.75rem;
  }
  .me-md-n6 {
    margin-right: -3rem;
  }
  .me-md-n7 {
    margin-right: -3.5rem;
  }
  .me-md-n7-5 {
    margin-right: -3.75rem;
  }
  .me-md-n8 {
    margin-right: -4rem;
  }
  .me-md-n9 {
    margin-right: -4.5rem;
  }
  .me-md-n9-5 {
    margin-right: -4.75rem;
  }
  .me-md-n10 {
    margin-right: -5rem;
  }
  .me-md-nauto {
    margin-right: -"auto";
  }
  .mb-md-n0 {
    margin-bottom: 0;
  }
  .mb-md-n0-5 {
    margin-bottom: -0.25rem;
  }
  .mb-md-n1 {
    margin-bottom: -0.5rem;
  }
  .mb-md-n1-5 {
    margin-bottom: -0.75rem;
  }
  .mb-md-n2 {
    margin-bottom: -1rem;
  }
  .mb-md-n2-5 {
    margin-bottom: -1.25rem;
  }
  .mb-md-n3 {
    margin-bottom: -1.5rem;
  }
  .mb-md-n3-5 {
    margin-bottom: -1.75rem;
  }
  .mb-md-n4 {
    margin-bottom: -2rem;
  }
  .mb-md-n5 {
    margin-bottom: -2.5rem;
  }
  .mb-md-n5-5 {
    margin-bottom: -2.75rem;
  }
  .mb-md-n6 {
    margin-bottom: -3rem;
  }
  .mb-md-n7 {
    margin-bottom: -3.5rem;
  }
  .mb-md-n7-5 {
    margin-bottom: -3.75rem;
  }
  .mb-md-n8 {
    margin-bottom: -4rem;
  }
  .mb-md-n9 {
    margin-bottom: -4.5rem;
  }
  .mb-md-n9-5 {
    margin-bottom: -4.75rem;
  }
  .mb-md-n10 {
    margin-bottom: -5rem;
  }
  .mb-md-nauto {
    margin-bottom: -"auto";
  }
  .ms-md-n0 {
    margin-left: 0;
  }
  .ms-md-n0-5 {
    margin-left: -0.25rem;
  }
  .ms-md-n1 {
    margin-left: -0.5rem;
  }
  .ms-md-n1-5 {
    margin-left: -0.75rem;
  }
  .ms-md-n2 {
    margin-left: -1rem;
  }
  .ms-md-n2-5 {
    margin-left: -1.25rem;
  }
  .ms-md-n3 {
    margin-left: -1.5rem;
  }
  .ms-md-n3-5 {
    margin-left: -1.75rem;
  }
  .ms-md-n4 {
    margin-left: -2rem;
  }
  .ms-md-n5 {
    margin-left: -2.5rem;
  }
  .ms-md-n5-5 {
    margin-left: -2.75rem;
  }
  .ms-md-n6 {
    margin-left: -3rem;
  }
  .ms-md-n7 {
    margin-left: -3.5rem;
  }
  .ms-md-n7-5 {
    margin-left: -3.75rem;
  }
  .ms-md-n8 {
    margin-left: -4rem;
  }
  .ms-md-n9 {
    margin-left: -4.5rem;
  }
  .ms-md-n9-5 {
    margin-left: -4.75rem;
  }
  .ms-md-n10 {
    margin-left: -5rem;
  }
  .ms-md-nauto {
    margin-left: -"auto";
  }
  .p-md-0 {
    padding: 0;
  }
  .p-md-0-5 {
    padding: 0.25rem;
  }
  .p-md-1 {
    padding: 0.5rem;
  }
  .p-md-1-5 {
    padding: 0.75rem;
  }
  .p-md-2 {
    padding: 1rem;
  }
  .p-md-2-5 {
    padding: 1.25rem;
  }
  .p-md-3 {
    padding: 1.5rem;
  }
  .p-md-3-5 {
    padding: 1.75rem;
  }
  .p-md-4 {
    padding: 2rem;
  }
  .p-md-5 {
    padding: 2.5rem;
  }
  .p-md-5-5 {
    padding: 2.75rem;
  }
  .p-md-6 {
    padding: 3rem;
  }
  .p-md-7 {
    padding: 3.5rem;
  }
  .p-md-7-5 {
    padding: 3.75rem;
  }
  .p-md-8 {
    padding: 4rem;
  }
  .p-md-9 {
    padding: 4.5rem;
  }
  .p-md-9-5 {
    padding: 4.75rem;
  }
  .p-md-10 {
    padding: 5rem;
  }
  .p-md-auto {
    padding: "auto";
  }
  .px-md-0 {
    padding-right: 0;
    padding-left: 0;
  }
  .px-md-0-5 {
    padding-right: 0.25rem;
    padding-left: 0.25rem;
  }
  .px-md-1 {
    padding-right: 0.5rem;
    padding-left: 0.5rem;
  }
  .px-md-1-5 {
    padding-right: 0.75rem;
    padding-left: 0.75rem;
  }
  .px-md-2 {
    padding-right: 1rem;
    padding-left: 1rem;
  }
  .px-md-2-5 {
    padding-right: 1.25rem;
    padding-left: 1.25rem;
  }
  .px-md-3 {
    padding-right: 1.5rem;
    padding-left: 1.5rem;
  }
  .px-md-3-5 {
    padding-right: 1.75rem;
    padding-left: 1.75rem;
  }
  .px-md-4 {
    padding-right: 2rem;
    padding-left: 2rem;
  }
  .px-md-5 {
    padding-right: 2.5rem;
    padding-left: 2.5rem;
  }
  .px-md-5-5 {
    padding-right: 2.75rem;
    padding-left: 2.75rem;
  }
  .px-md-6 {
    padding-right: 3rem;
    padding-left: 3rem;
  }
  .px-md-7 {
    padding-right: 3.5rem;
    padding-left: 3.5rem;
  }
  .px-md-7-5 {
    padding-right: 3.75rem;
    padding-left: 3.75rem;
  }
  .px-md-8 {
    padding-right: 4rem;
    padding-left: 4rem;
  }
  .px-md-9 {
    padding-right: 4.5rem;
    padding-left: 4.5rem;
  }
  .px-md-9-5 {
    padding-right: 4.75rem;
    padding-left: 4.75rem;
  }
  .px-md-10 {
    padding-right: 5rem;
    padding-left: 5rem;
  }
  .px-md-auto {
    padding-right: "auto";
    padding-left: "auto";
  }
  .py-md-0 {
    padding-top: 0;
    padding-bottom: 0;
  }
  .py-md-0-5 {
    padding-top: 0.25rem;
    padding-bottom: 0.25rem;
  }
  .py-md-1 {
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
  }
  .py-md-1-5 {
    padding-top: 0.75rem;
    padding-bottom: 0.75rem;
  }
  .py-md-2 {
    padding-top: 1rem;
    padding-bottom: 1rem;
  }
  .py-md-2-5 {
    padding-top: 1.25rem;
    padding-bottom: 1.25rem;
  }
  .py-md-3 {
    padding-top: 1.5rem;
    padding-bottom: 1.5rem;
  }
  .py-md-3-5 {
    padding-top: 1.75rem;
    padding-bottom: 1.75rem;
  }
  .py-md-4 {
    padding-top: 2rem;
    padding-bottom: 2rem;
  }
  .py-md-5 {
    padding-top: 2.5rem;
    padding-bottom: 2.5rem;
  }
  .py-md-5-5 {
    padding-top: 2.75rem;
    padding-bottom: 2.75rem;
  }
  .py-md-6 {
    padding-top: 3rem;
    padding-bottom: 3rem;
  }
  .py-md-7 {
    padding-top: 3.5rem;
    padding-bottom: 3.5rem;
  }
  .py-md-7-5 {
    padding-top: 3.75rem;
    padding-bottom: 3.75rem;
  }
  .py-md-8 {
    padding-top: 4rem;
    padding-bottom: 4rem;
  }
  .py-md-9 {
    padding-top: 4.5rem;
    padding-bottom: 4.5rem;
  }
  .py-md-9-5 {
    padding-top: 4.75rem;
    padding-bottom: 4.75rem;
  }
  .py-md-10 {
    padding-top: 5rem;
    padding-bottom: 5rem;
  }
  .py-md-auto {
    padding-top: "auto";
    padding-bottom: "auto";
  }
  .pt-md-0 {
    padding-top: 0;
  }
  .pt-md-0-5 {
    padding-top: 0.25rem;
  }
  .pt-md-1 {
    padding-top: 0.5rem;
  }
  .pt-md-1-5 {
    padding-top: 0.75rem;
  }
  .pt-md-2 {
    padding-top: 1rem;
  }
  .pt-md-2-5 {
    padding-top: 1.25rem;
  }
  .pt-md-3 {
    padding-top: 1.5rem;
  }
  .pt-md-3-5 {
    padding-top: 1.75rem;
  }
  .pt-md-4 {
    padding-top: 2rem;
  }
  .pt-md-5 {
    padding-top: 2.5rem;
  }
  .pt-md-5-5 {
    padding-top: 2.75rem;
  }
  .pt-md-6 {
    padding-top: 3rem;
  }
  .pt-md-7 {
    padding-top: 3.5rem;
  }
  .pt-md-7-5 {
    padding-top: 3.75rem;
  }
  .pt-md-8 {
    padding-top: 4rem;
  }
  .pt-md-9 {
    padding-top: 4.5rem;
  }
  .pt-md-9-5 {
    padding-top: 4.75rem;
  }
  .pt-md-10 {
    padding-top: 5rem;
  }
  .pt-md-auto {
    padding-top: "auto";
  }
  .pe-md-0 {
    padding-right: 0;
  }
  .pe-md-0-5 {
    padding-right: 0.25rem;
  }
  .pe-md-1 {
    padding-right: 0.5rem;
  }
  .pe-md-1-5 {
    padding-right: 0.75rem;
  }
  .pe-md-2 {
    padding-right: 1rem;
  }
  .pe-md-2-5 {
    padding-right: 1.25rem;
  }
  .pe-md-3 {
    padding-right: 1.5rem;
  }
  .pe-md-3-5 {
    padding-right: 1.75rem;
  }
  .pe-md-4 {
    padding-right: 2rem;
  }
  .pe-md-5 {
    padding-right: 2.5rem;
  }
  .pe-md-5-5 {
    padding-right: 2.75rem;
  }
  .pe-md-6 {
    padding-right: 3rem;
  }
  .pe-md-7 {
    padding-right: 3.5rem;
  }
  .pe-md-7-5 {
    padding-right: 3.75rem;
  }
  .pe-md-8 {
    padding-right: 4rem;
  }
  .pe-md-9 {
    padding-right: 4.5rem;
  }
  .pe-md-9-5 {
    padding-right: 4.75rem;
  }
  .pe-md-10 {
    padding-right: 5rem;
  }
  .pe-md-auto {
    padding-right: "auto";
  }
  .pb-md-0 {
    padding-bottom: 0;
  }
  .pb-md-0-5 {
    padding-bottom: 0.25rem;
  }
  .pb-md-1 {
    padding-bottom: 0.5rem;
  }
  .pb-md-1-5 {
    padding-bottom: 0.75rem;
  }
  .pb-md-2 {
    padding-bottom: 1rem;
  }
  .pb-md-2-5 {
    padding-bottom: 1.25rem;
  }
  .pb-md-3 {
    padding-bottom: 1.5rem;
  }
  .pb-md-3-5 {
    padding-bottom: 1.75rem;
  }
  .pb-md-4 {
    padding-bottom: 2rem;
  }
  .pb-md-5 {
    padding-bottom: 2.5rem;
  }
  .pb-md-5-5 {
    padding-bottom: 2.75rem;
  }
  .pb-md-6 {
    padding-bottom: 3rem;
  }
  .pb-md-7 {
    padding-bottom: 3.5rem;
  }
  .pb-md-7-5 {
    padding-bottom: 3.75rem;
  }
  .pb-md-8 {
    padding-bottom: 4rem;
  }
  .pb-md-9 {
    padding-bottom: 4.5rem;
  }
  .pb-md-9-5 {
    padding-bottom: 4.75rem;
  }
  .pb-md-10 {
    padding-bottom: 5rem;
  }
  .pb-md-auto {
    padding-bottom: "auto";
  }
  .ps-md-0 {
    padding-left: 0;
  }
  .ps-md-0-5 {
    padding-left: 0.25rem;
  }
  .ps-md-1 {
    padding-left: 0.5rem;
  }
  .ps-md-1-5 {
    padding-left: 0.75rem;
  }
  .ps-md-2 {
    padding-left: 1rem;
  }
  .ps-md-2-5 {
    padding-left: 1.25rem;
  }
  .ps-md-3 {
    padding-left: 1.5rem;
  }
  .ps-md-3-5 {
    padding-left: 1.75rem;
  }
  .ps-md-4 {
    padding-left: 2rem;
  }
  .ps-md-5 {
    padding-left: 2.5rem;
  }
  .ps-md-5-5 {
    padding-left: 2.75rem;
  }
  .ps-md-6 {
    padding-left: 3rem;
  }
  .ps-md-7 {
    padding-left: 3.5rem;
  }
  .ps-md-7-5 {
    padding-left: 3.75rem;
  }
  .ps-md-8 {
    padding-left: 4rem;
  }
  .ps-md-9 {
    padding-left: 4.5rem;
  }
  .ps-md-9-5 {
    padding-left: 4.75rem;
  }
  .ps-md-10 {
    padding-left: 5rem;
  }
  .ps-md-auto {
    padding-left: "auto";
  }
  .gap-md-0 {
    gap: 0;
  }
  .gap-md-0-5 {
    gap: 0.25rem;
  }
  .gap-md-1 {
    gap: 0.5rem;
  }
  .gap-md-1-5 {
    gap: 0.75rem;
  }
  .gap-md-2 {
    gap: 1rem;
  }
  .gap-md-2-5 {
    gap: 1.25rem;
  }
  .gap-md-3 {
    gap: 1.5rem;
  }
  .gap-md-3-5 {
    gap: 1.75rem;
  }
  .gap-md-4 {
    gap: 2rem;
  }
  .gap-md-5 {
    gap: 2.5rem;
  }
  .gap-md-5-5 {
    gap: 2.75rem;
  }
  .gap-md-6 {
    gap: 3rem;
  }
  .gap-md-7 {
    gap: 3.5rem;
  }
  .gap-md-7-5 {
    gap: 3.75rem;
  }
  .gap-md-8 {
    gap: 4rem;
  }
  .gap-md-9 {
    gap: 4.5rem;
  }
  .gap-md-9-5 {
    gap: 4.75rem;
  }
  .gap-md-10 {
    gap: 5rem;
  }
  .gap-md-auto {
    gap: "auto";
  }
  .text-md-start {
    text-align: left;
  }
  .text-md-end {
    text-align: right;
  }
  .text-md-center {
    text-align: center;
  }
}
@media (min-width: 992px) {
  .float-lg-start {
    float: left;
  }
  .float-lg-end {
    float: right;
  }
  .float-lg-none {
    float: none;
  }
  .d-lg-inline {
    display: inline;
  }
  .d-lg-inline-block {
    display: inline-block;
  }
  .d-lg-block {
    display: block;
  }
  .d-lg-grid {
    display: grid;
  }
  .d-lg-table {
    display: table;
  }
  .d-lg-table-row {
    display: table-row;
  }
  .d-lg-table-cell {
    display: table-cell;
  }
  .d-lg-flex {
    display: flex;
  }
  .d-lg-inline-flex {
    display: inline-flex;
  }
  .d-lg-none {
    display: none;
  }
  .flex-lg-fill {
    flex: 1 1 auto;
  }
  .flex-lg-row {
    flex-direction: row;
  }
  .flex-lg-column {
    flex-direction: column;
  }
  .flex-lg-row-reverse {
    flex-direction: row-reverse;
  }
  .flex-lg-column-reverse {
    flex-direction: column-reverse;
  }
  .flex-lg-grow-0 {
    flex-grow: 0;
  }
  .flex-lg-grow-1 {
    flex-grow: 1;
  }
  .flex-lg-shrink-0 {
    flex-shrink: 0;
  }
  .flex-lg-shrink-1 {
    flex-shrink: 1;
  }
  .flex-lg-wrap {
    flex-wrap: wrap;
  }
  .flex-lg-nowrap {
    flex-wrap: nowrap;
  }
  .flex-lg-wrap-reverse {
    flex-wrap: wrap-reverse;
  }
  .justify-content-lg-start {
    justify-content: flex-start;
  }
  .justify-content-lg-end {
    justify-content: flex-end;
  }
  .justify-content-lg-center {
    justify-content: center;
  }
  .justify-content-lg-between {
    justify-content: space-between;
  }
  .justify-content-lg-around {
    justify-content: space-around;
  }
  .justify-content-lg-evenly {
    justify-content: space-evenly;
  }
  .align-items-lg-start {
    align-items: flex-start;
  }
  .align-items-lg-end {
    align-items: flex-end;
  }
  .align-items-lg-center {
    align-items: center;
  }
  .align-items-lg-baseline {
    align-items: baseline;
  }
  .align-items-lg-stretch {
    align-items: stretch;
  }
  .align-content-lg-start {
    align-content: flex-start;
  }
  .align-content-lg-end {
    align-content: flex-end;
  }
  .align-content-lg-center {
    align-content: center;
  }
  .align-content-lg-between {
    align-content: space-between;
  }
  .align-content-lg-around {
    align-content: space-around;
  }
  .align-content-lg-stretch {
    align-content: stretch;
  }
  .align-self-lg-auto {
    align-self: auto;
  }
  .align-self-lg-start {
    align-self: flex-start;
  }
  .align-self-lg-end {
    align-self: flex-end;
  }
  .align-self-lg-center {
    align-self: center;
  }
  .align-self-lg-baseline {
    align-self: baseline;
  }
  .align-self-lg-stretch {
    align-self: stretch;
  }
  .order-lg-first {
    order: -1;
  }
  .order-lg-0 {
    order: 0;
  }
  .order-lg-1 {
    order: 1;
  }
  .order-lg-2 {
    order: 2;
  }
  .order-lg-3 {
    order: 3;
  }
  .order-lg-4 {
    order: 4;
  }
  .order-lg-5 {
    order: 5;
  }
  .order-lg-last {
    order: 6;
  }
  .m-lg-0 {
    margin: 0;
  }
  .m-lg-0-5 {
    margin: 0.25rem;
  }
  .m-lg-1 {
    margin: 0.5rem;
  }
  .m-lg-1-5 {
    margin: 0.75rem;
  }
  .m-lg-2 {
    margin: 1rem;
  }
  .m-lg-2-5 {
    margin: 1.25rem;
  }
  .m-lg-3 {
    margin: 1.5rem;
  }
  .m-lg-3-5 {
    margin: 1.75rem;
  }
  .m-lg-4 {
    margin: 2rem;
  }
  .m-lg-5 {
    margin: 2.5rem;
  }
  .m-lg-5-5 {
    margin: 2.75rem;
  }
  .m-lg-6 {
    margin: 3rem;
  }
  .m-lg-7 {
    margin: 3.5rem;
  }
  .m-lg-7-5 {
    margin: 3.75rem;
  }
  .m-lg-8 {
    margin: 4rem;
  }
  .m-lg-9 {
    margin: 4.5rem;
  }
  .m-lg-9-5 {
    margin: 4.75rem;
  }
  .m-lg-10 {
    margin: 5rem;
  }
  .m-lg-auto {
    margin: auto;
  }
  .mx-lg-0 {
    margin-right: 0;
    margin-left: 0;
  }
  .mx-lg-0-5 {
    margin-right: 0.25rem;
    margin-left: 0.25rem;
  }
  .mx-lg-1 {
    margin-right: 0.5rem;
    margin-left: 0.5rem;
  }
  .mx-lg-1-5 {
    margin-right: 0.75rem;
    margin-left: 0.75rem;
  }
  .mx-lg-2 {
    margin-right: 1rem;
    margin-left: 1rem;
  }
  .mx-lg-2-5 {
    margin-right: 1.25rem;
    margin-left: 1.25rem;
  }
  .mx-lg-3 {
    margin-right: 1.5rem;
    margin-left: 1.5rem;
  }
  .mx-lg-3-5 {
    margin-right: 1.75rem;
    margin-left: 1.75rem;
  }
  .mx-lg-4 {
    margin-right: 2rem;
    margin-left: 2rem;
  }
  .mx-lg-5 {
    margin-right: 2.5rem;
    margin-left: 2.5rem;
  }
  .mx-lg-5-5 {
    margin-right: 2.75rem;
    margin-left: 2.75rem;
  }
  .mx-lg-6 {
    margin-right: 3rem;
    margin-left: 3rem;
  }
  .mx-lg-7 {
    margin-right: 3.5rem;
    margin-left: 3.5rem;
  }
  .mx-lg-7-5 {
    margin-right: 3.75rem;
    margin-left: 3.75rem;
  }
  .mx-lg-8 {
    margin-right: 4rem;
    margin-left: 4rem;
  }
  .mx-lg-9 {
    margin-right: 4.5rem;
    margin-left: 4.5rem;
  }
  .mx-lg-9-5 {
    margin-right: 4.75rem;
    margin-left: 4.75rem;
  }
  .mx-lg-10 {
    margin-right: 5rem;
    margin-left: 5rem;
  }
  .mx-lg-auto {
    margin-right: auto;
    margin-left: auto;
  }
  .my-lg-0 {
    margin-top: 0;
    margin-bottom: 0;
  }
  .my-lg-0-5 {
    margin-top: 0.25rem;
    margin-bottom: 0.25rem;
  }
  .my-lg-1 {
    margin-top: 0.5rem;
    margin-bottom: 0.5rem;
  }
  .my-lg-1-5 {
    margin-top: 0.75rem;
    margin-bottom: 0.75rem;
  }
  .my-lg-2 {
    margin-top: 1rem;
    margin-bottom: 1rem;
  }
  .my-lg-2-5 {
    margin-top: 1.25rem;
    margin-bottom: 1.25rem;
  }
  .my-lg-3 {
    margin-top: 1.5rem;
    margin-bottom: 1.5rem;
  }
  .my-lg-3-5 {
    margin-top: 1.75rem;
    margin-bottom: 1.75rem;
  }
  .my-lg-4 {
    margin-top: 2rem;
    margin-bottom: 2rem;
  }
  .my-lg-5 {
    margin-top: 2.5rem;
    margin-bottom: 2.5rem;
  }
  .my-lg-5-5 {
    margin-top: 2.75rem;
    margin-bottom: 2.75rem;
  }
  .my-lg-6 {
    margin-top: 3rem;
    margin-bottom: 3rem;
  }
  .my-lg-7 {
    margin-top: 3.5rem;
    margin-bottom: 3.5rem;
  }
  .my-lg-7-5 {
    margin-top: 3.75rem;
    margin-bottom: 3.75rem;
  }
  .my-lg-8 {
    margin-top: 4rem;
    margin-bottom: 4rem;
  }
  .my-lg-9 {
    margin-top: 4.5rem;
    margin-bottom: 4.5rem;
  }
  .my-lg-9-5 {
    margin-top: 4.75rem;
    margin-bottom: 4.75rem;
  }
  .my-lg-10 {
    margin-top: 5rem;
    margin-bottom: 5rem;
  }
  .my-lg-auto {
    margin-top: auto;
    margin-bottom: auto;
  }
  .mt-lg-0 {
    margin-top: 0;
  }
  .mt-lg-0-5 {
    margin-top: 0.25rem;
  }
  .mt-lg-1 {
    margin-top: 0.5rem;
  }
  .mt-lg-1-5 {
    margin-top: 0.75rem;
  }
  .mt-lg-2 {
    margin-top: 1rem;
  }
  .mt-lg-2-5 {
    margin-top: 1.25rem;
  }
  .mt-lg-3 {
    margin-top: 1.5rem;
  }
  .mt-lg-3-5 {
    margin-top: 1.75rem;
  }
  .mt-lg-4 {
    margin-top: 2rem;
  }
  .mt-lg-5 {
    margin-top: 2.5rem;
  }
  .mt-lg-5-5 {
    margin-top: 2.75rem;
  }
  .mt-lg-6 {
    margin-top: 3rem;
  }
  .mt-lg-7 {
    margin-top: 3.5rem;
  }
  .mt-lg-7-5 {
    margin-top: 3.75rem;
  }
  .mt-lg-8 {
    margin-top: 4rem;
  }
  .mt-lg-9 {
    margin-top: 4.5rem;
  }
  .mt-lg-9-5 {
    margin-top: 4.75rem;
  }
  .mt-lg-10 {
    margin-top: 5rem;
  }
  .mt-lg-auto {
    margin-top: auto;
  }
  .me-lg-0 {
    margin-right: 0;
  }
  .me-lg-0-5 {
    margin-right: 0.25rem;
  }
  .me-lg-1 {
    margin-right: 0.5rem;
  }
  .me-lg-1-5 {
    margin-right: 0.75rem;
  }
  .me-lg-2 {
    margin-right: 1rem;
  }
  .me-lg-2-5 {
    margin-right: 1.25rem;
  }
  .me-lg-3 {
    margin-right: 1.5rem;
  }
  .me-lg-3-5 {
    margin-right: 1.75rem;
  }
  .me-lg-4 {
    margin-right: 2rem;
  }
  .me-lg-5 {
    margin-right: 2.5rem;
  }
  .me-lg-5-5 {
    margin-right: 2.75rem;
  }
  .me-lg-6 {
    margin-right: 3rem;
  }
  .me-lg-7 {
    margin-right: 3.5rem;
  }
  .me-lg-7-5 {
    margin-right: 3.75rem;
  }
  .me-lg-8 {
    margin-right: 4rem;
  }
  .me-lg-9 {
    margin-right: 4.5rem;
  }
  .me-lg-9-5 {
    margin-right: 4.75rem;
  }
  .me-lg-10 {
    margin-right: 5rem;
  }
  .me-lg-auto {
    margin-right: auto;
  }
  .mb-lg-0 {
    margin-bottom: 0;
  }
  .mb-lg-0-5 {
    margin-bottom: 0.25rem;
  }
  .mb-lg-1 {
    margin-bottom: 0.5rem;
  }
  .mb-lg-1-5 {
    margin-bottom: 0.75rem;
  }
  .mb-lg-2 {
    margin-bottom: 1rem;
  }
  .mb-lg-2-5 {
    margin-bottom: 1.25rem;
  }
  .mb-lg-3 {
    margin-bottom: 1.5rem;
  }
  .mb-lg-3-5 {
    margin-bottom: 1.75rem;
  }
  .mb-lg-4 {
    margin-bottom: 2rem;
  }
  .mb-lg-5 {
    margin-bottom: 2.5rem;
  }
  .mb-lg-5-5 {
    margin-bottom: 2.75rem;
  }
  .mb-lg-6 {
    margin-bottom: 3rem;
  }
  .mb-lg-7 {
    margin-bottom: 3.5rem;
  }
  .mb-lg-7-5 {
    margin-bottom: 3.75rem;
  }
  .mb-lg-8 {
    margin-bottom: 4rem;
  }
  .mb-lg-9 {
    margin-bottom: 4.5rem;
  }
  .mb-lg-9-5 {
    margin-bottom: 4.75rem;
  }
  .mb-lg-10 {
    margin-bottom: 5rem;
  }
  .mb-lg-auto {
    margin-bottom: auto;
  }
  .ms-lg-0 {
    margin-left: 0;
  }
  .ms-lg-0-5 {
    margin-left: 0.25rem;
  }
  .ms-lg-1 {
    margin-left: 0.5rem;
  }
  .ms-lg-1-5 {
    margin-left: 0.75rem;
  }
  .ms-lg-2 {
    margin-left: 1rem;
  }
  .ms-lg-2-5 {
    margin-left: 1.25rem;
  }
  .ms-lg-3 {
    margin-left: 1.5rem;
  }
  .ms-lg-3-5 {
    margin-left: 1.75rem;
  }
  .ms-lg-4 {
    margin-left: 2rem;
  }
  .ms-lg-5 {
    margin-left: 2.5rem;
  }
  .ms-lg-5-5 {
    margin-left: 2.75rem;
  }
  .ms-lg-6 {
    margin-left: 3rem;
  }
  .ms-lg-7 {
    margin-left: 3.5rem;
  }
  .ms-lg-7-5 {
    margin-left: 3.75rem;
  }
  .ms-lg-8 {
    margin-left: 4rem;
  }
  .ms-lg-9 {
    margin-left: 4.5rem;
  }
  .ms-lg-9-5 {
    margin-left: 4.75rem;
  }
  .ms-lg-10 {
    margin-left: 5rem;
  }
  .ms-lg-auto {
    margin-left: auto;
  }
  .m-lg-n0 {
    margin: 0;
  }
  .m-lg-n0-5 {
    margin: -0.25rem;
  }
  .m-lg-n1 {
    margin: -0.5rem;
  }
  .m-lg-n1-5 {
    margin: -0.75rem;
  }
  .m-lg-n2 {
    margin: -1rem;
  }
  .m-lg-n2-5 {
    margin: -1.25rem;
  }
  .m-lg-n3 {
    margin: -1.5rem;
  }
  .m-lg-n3-5 {
    margin: -1.75rem;
  }
  .m-lg-n4 {
    margin: -2rem;
  }
  .m-lg-n5 {
    margin: -2.5rem;
  }
  .m-lg-n5-5 {
    margin: -2.75rem;
  }
  .m-lg-n6 {
    margin: -3rem;
  }
  .m-lg-n7 {
    margin: -3.5rem;
  }
  .m-lg-n7-5 {
    margin: -3.75rem;
  }
  .m-lg-n8 {
    margin: -4rem;
  }
  .m-lg-n9 {
    margin: -4.5rem;
  }
  .m-lg-n9-5 {
    margin: -4.75rem;
  }
  .m-lg-n10 {
    margin: -5rem;
  }
  .m-lg-nauto {
    margin: -"auto";
  }
  .mx-lg-n0 {
    margin-right: 0;
    margin-left: 0;
  }
  .mx-lg-n0-5 {
    margin-right: -0.25rem;
    margin-left: -0.25rem;
  }
  .mx-lg-n1 {
    margin-right: -0.5rem;
    margin-left: -0.5rem;
  }
  .mx-lg-n1-5 {
    margin-right: -0.75rem;
    margin-left: -0.75rem;
  }
  .mx-lg-n2 {
    margin-right: -1rem;
    margin-left: -1rem;
  }
  .mx-lg-n2-5 {
    margin-right: -1.25rem;
    margin-left: -1.25rem;
  }
  .mx-lg-n3 {
    margin-right: -1.5rem;
    margin-left: -1.5rem;
  }
  .mx-lg-n3-5 {
    margin-right: -1.75rem;
    margin-left: -1.75rem;
  }
  .mx-lg-n4 {
    margin-right: -2rem;
    margin-left: -2rem;
  }
  .mx-lg-n5 {
    margin-right: -2.5rem;
    margin-left: -2.5rem;
  }
  .mx-lg-n5-5 {
    margin-right: -2.75rem;
    margin-left: -2.75rem;
  }
  .mx-lg-n6 {
    margin-right: -3rem;
    margin-left: -3rem;
  }
  .mx-lg-n7 {
    margin-right: -3.5rem;
    margin-left: -3.5rem;
  }
  .mx-lg-n7-5 {
    margin-right: -3.75rem;
    margin-left: -3.75rem;
  }
  .mx-lg-n8 {
    margin-right: -4rem;
    margin-left: -4rem;
  }
  .mx-lg-n9 {
    margin-right: -4.5rem;
    margin-left: -4.5rem;
  }
  .mx-lg-n9-5 {
    margin-right: -4.75rem;
    margin-left: -4.75rem;
  }
  .mx-lg-n10 {
    margin-right: -5rem;
    margin-left: -5rem;
  }
  .mx-lg-nauto {
    margin-right: -"auto";
    margin-left: -"auto";
  }
  .my-lg-n0 {
    margin-top: 0;
    margin-bottom: 0;
  }
  .my-lg-n0-5 {
    margin-top: -0.25rem;
    margin-bottom: -0.25rem;
  }
  .my-lg-n1 {
    margin-top: -0.5rem;
    margin-bottom: -0.5rem;
  }
  .my-lg-n1-5 {
    margin-top: -0.75rem;
    margin-bottom: -0.75rem;
  }
  .my-lg-n2 {
    margin-top: -1rem;
    margin-bottom: -1rem;
  }
  .my-lg-n2-5 {
    margin-top: -1.25rem;
    margin-bottom: -1.25rem;
  }
  .my-lg-n3 {
    margin-top: -1.5rem;
    margin-bottom: -1.5rem;
  }
  .my-lg-n3-5 {
    margin-top: -1.75rem;
    margin-bottom: -1.75rem;
  }
  .my-lg-n4 {
    margin-top: -2rem;
    margin-bottom: -2rem;
  }
  .my-lg-n5 {
    margin-top: -2.5rem;
    margin-bottom: -2.5rem;
  }
  .my-lg-n5-5 {
    margin-top: -2.75rem;
    margin-bottom: -2.75rem;
  }
  .my-lg-n6 {
    margin-top: -3rem;
    margin-bottom: -3rem;
  }
  .my-lg-n7 {
    margin-top: -3.5rem;
    margin-bottom: -3.5rem;
  }
  .my-lg-n7-5 {
    margin-top: -3.75rem;
    margin-bottom: -3.75rem;
  }
  .my-lg-n8 {
    margin-top: -4rem;
    margin-bottom: -4rem;
  }
  .my-lg-n9 {
    margin-top: -4.5rem;
    margin-bottom: -4.5rem;
  }
  .my-lg-n9-5 {
    margin-top: -4.75rem;
    margin-bottom: -4.75rem;
  }
  .my-lg-n10 {
    margin-top: -5rem;
    margin-bottom: -5rem;
  }
  .my-lg-nauto {
    margin-top: -"auto";
    margin-bottom: -"auto";
  }
  .mt-lg-n0 {
    margin-top: 0;
  }
  .mt-lg-n0-5 {
    margin-top: -0.25rem;
  }
  .mt-lg-n1 {
    margin-top: -0.5rem;
  }
  .mt-lg-n1-5 {
    margin-top: -0.75rem;
  }
  .mt-lg-n2 {
    margin-top: -1rem;
  }
  .mt-lg-n2-5 {
    margin-top: -1.25rem;
  }
  .mt-lg-n3 {
    margin-top: -1.5rem;
  }
  .mt-lg-n3-5 {
    margin-top: -1.75rem;
  }
  .mt-lg-n4 {
    margin-top: -2rem;
  }
  .mt-lg-n5 {
    margin-top: -2.5rem;
  }
  .mt-lg-n5-5 {
    margin-top: -2.75rem;
  }
  .mt-lg-n6 {
    margin-top: -3rem;
  }
  .mt-lg-n7 {
    margin-top: -3.5rem;
  }
  .mt-lg-n7-5 {
    margin-top: -3.75rem;
  }
  .mt-lg-n8 {
    margin-top: -4rem;
  }
  .mt-lg-n9 {
    margin-top: -4.5rem;
  }
  .mt-lg-n9-5 {
    margin-top: -4.75rem;
  }
  .mt-lg-n10 {
    margin-top: -5rem;
  }
  .mt-lg-nauto {
    margin-top: -"auto";
  }
  .me-lg-n0 {
    margin-right: 0;
  }
  .me-lg-n0-5 {
    margin-right: -0.25rem;
  }
  .me-lg-n1 {
    margin-right: -0.5rem;
  }
  .me-lg-n1-5 {
    margin-right: -0.75rem;
  }
  .me-lg-n2 {
    margin-right: -1rem;
  }
  .me-lg-n2-5 {
    margin-right: -1.25rem;
  }
  .me-lg-n3 {
    margin-right: -1.5rem;
  }
  .me-lg-n3-5 {
    margin-right: -1.75rem;
  }
  .me-lg-n4 {
    margin-right: -2rem;
  }
  .me-lg-n5 {
    margin-right: -2.5rem;
  }
  .me-lg-n5-5 {
    margin-right: -2.75rem;
  }
  .me-lg-n6 {
    margin-right: -3rem;
  }
  .me-lg-n7 {
    margin-right: -3.5rem;
  }
  .me-lg-n7-5 {
    margin-right: -3.75rem;
  }
  .me-lg-n8 {
    margin-right: -4rem;
  }
  .me-lg-n9 {
    margin-right: -4.5rem;
  }
  .me-lg-n9-5 {
    margin-right: -4.75rem;
  }
  .me-lg-n10 {
    margin-right: -5rem;
  }
  .me-lg-nauto {
    margin-right: -"auto";
  }
  .mb-lg-n0 {
    margin-bottom: 0;
  }
  .mb-lg-n0-5 {
    margin-bottom: -0.25rem;
  }
  .mb-lg-n1 {
    margin-bottom: -0.5rem;
  }
  .mb-lg-n1-5 {
    margin-bottom: -0.75rem;
  }
  .mb-lg-n2 {
    margin-bottom: -1rem;
  }
  .mb-lg-n2-5 {
    margin-bottom: -1.25rem;
  }
  .mb-lg-n3 {
    margin-bottom: -1.5rem;
  }
  .mb-lg-n3-5 {
    margin-bottom: -1.75rem;
  }
  .mb-lg-n4 {
    margin-bottom: -2rem;
  }
  .mb-lg-n5 {
    margin-bottom: -2.5rem;
  }
  .mb-lg-n5-5 {
    margin-bottom: -2.75rem;
  }
  .mb-lg-n6 {
    margin-bottom: -3rem;
  }
  .mb-lg-n7 {
    margin-bottom: -3.5rem;
  }
  .mb-lg-n7-5 {
    margin-bottom: -3.75rem;
  }
  .mb-lg-n8 {
    margin-bottom: -4rem;
  }
  .mb-lg-n9 {
    margin-bottom: -4.5rem;
  }
  .mb-lg-n9-5 {
    margin-bottom: -4.75rem;
  }
  .mb-lg-n10 {
    margin-bottom: -5rem;
  }
  .mb-lg-nauto {
    margin-bottom: -"auto";
  }
  .ms-lg-n0 {
    margin-left: 0;
  }
  .ms-lg-n0-5 {
    margin-left: -0.25rem;
  }
  .ms-lg-n1 {
    margin-left: -0.5rem;
  }
  .ms-lg-n1-5 {
    margin-left: -0.75rem;
  }
  .ms-lg-n2 {
    margin-left: -1rem;
  }
  .ms-lg-n2-5 {
    margin-left: -1.25rem;
  }
  .ms-lg-n3 {
    margin-left: -1.5rem;
  }
  .ms-lg-n3-5 {
    margin-left: -1.75rem;
  }
  .ms-lg-n4 {
    margin-left: -2rem;
  }
  .ms-lg-n5 {
    margin-left: -2.5rem;
  }
  .ms-lg-n5-5 {
    margin-left: -2.75rem;
  }
  .ms-lg-n6 {
    margin-left: -3rem;
  }
  .ms-lg-n7 {
    margin-left: -3.5rem;
  }
  .ms-lg-n7-5 {
    margin-left: -3.75rem;
  }
  .ms-lg-n8 {
    margin-left: -4rem;
  }
  .ms-lg-n9 {
    margin-left: -4.5rem;
  }
  .ms-lg-n9-5 {
    margin-left: -4.75rem;
  }
  .ms-lg-n10 {
    margin-left: -5rem;
  }
  .ms-lg-nauto {
    margin-left: -"auto";
  }
  .p-lg-0 {
    padding: 0;
  }
  .p-lg-0-5 {
    padding: 0.25rem;
  }
  .p-lg-1 {
    padding: 0.5rem;
  }
  .p-lg-1-5 {
    padding: 0.75rem;
  }
  .p-lg-2 {
    padding: 1rem;
  }
  .p-lg-2-5 {
    padding: 1.25rem;
  }
  .p-lg-3 {
    padding: 1.5rem;
  }
  .p-lg-3-5 {
    padding: 1.75rem;
  }
  .p-lg-4 {
    padding: 2rem;
  }
  .p-lg-5 {
    padding: 2.5rem;
  }
  .p-lg-5-5 {
    padding: 2.75rem;
  }
  .p-lg-6 {
    padding: 3rem;
  }
  .p-lg-7 {
    padding: 3.5rem;
  }
  .p-lg-7-5 {
    padding: 3.75rem;
  }
  .p-lg-8 {
    padding: 4rem;
  }
  .p-lg-9 {
    padding: 4.5rem;
  }
  .p-lg-9-5 {
    padding: 4.75rem;
  }
  .p-lg-10 {
    padding: 5rem;
  }
  .p-lg-auto {
    padding: "auto";
  }
  .px-lg-0 {
    padding-right: 0;
    padding-left: 0;
  }
  .px-lg-0-5 {
    padding-right: 0.25rem;
    padding-left: 0.25rem;
  }
  .px-lg-1 {
    padding-right: 0.5rem;
    padding-left: 0.5rem;
  }
  .px-lg-1-5 {
    padding-right: 0.75rem;
    padding-left: 0.75rem;
  }
  .px-lg-2 {
    padding-right: 1rem;
    padding-left: 1rem;
  }
  .px-lg-2-5 {
    padding-right: 1.25rem;
    padding-left: 1.25rem;
  }
  .px-lg-3 {
    padding-right: 1.5rem;
    padding-left: 1.5rem;
  }
  .px-lg-3-5 {
    padding-right: 1.75rem;
    padding-left: 1.75rem;
  }
  .px-lg-4 {
    padding-right: 2rem;
    padding-left: 2rem;
  }
  .px-lg-5 {
    padding-right: 2.5rem;
    padding-left: 2.5rem;
  }
  .px-lg-5-5 {
    padding-right: 2.75rem;
    padding-left: 2.75rem;
  }
  .px-lg-6 {
    padding-right: 3rem;
    padding-left: 3rem;
  }
  .px-lg-7 {
    padding-right: 3.5rem;
    padding-left: 3.5rem;
  }
  .px-lg-7-5 {
    padding-right: 3.75rem;
    padding-left: 3.75rem;
  }
  .px-lg-8 {
    padding-right: 4rem;
    padding-left: 4rem;
  }
  .px-lg-9 {
    padding-right: 4.5rem;
    padding-left: 4.5rem;
  }
  .px-lg-9-5 {
    padding-right: 4.75rem;
    padding-left: 4.75rem;
  }
  .px-lg-10 {
    padding-right: 5rem;
    padding-left: 5rem;
  }
  .px-lg-auto {
    padding-right: "auto";
    padding-left: "auto";
  }
  .py-lg-0 {
    padding-top: 0;
    padding-bottom: 0;
  }
  .py-lg-0-5 {
    padding-top: 0.25rem;
    padding-bottom: 0.25rem;
  }
  .py-lg-1 {
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
  }
  .py-lg-1-5 {
    padding-top: 0.75rem;
    padding-bottom: 0.75rem;
  }
  .py-lg-2 {
    padding-top: 1rem;
    padding-bottom: 1rem;
  }
  .py-lg-2-5 {
    padding-top: 1.25rem;
    padding-bottom: 1.25rem;
  }
  .py-lg-3 {
    padding-top: 1.5rem;
    padding-bottom: 1.5rem;
  }
  .py-lg-3-5 {
    padding-top: 1.75rem;
    padding-bottom: 1.75rem;
  }
  .py-lg-4 {
    padding-top: 2rem;
    padding-bottom: 2rem;
  }
  .py-lg-5 {
    padding-top: 2.5rem;
    padding-bottom: 2.5rem;
  }
  .py-lg-5-5 {
    padding-top: 2.75rem;
    padding-bottom: 2.75rem;
  }
  .py-lg-6 {
    padding-top: 3rem;
    padding-bottom: 3rem;
  }
  .py-lg-7 {
    padding-top: 3.5rem;
    padding-bottom: 3.5rem;
  }
  .py-lg-7-5 {
    padding-top: 3.75rem;
    padding-bottom: 3.75rem;
  }
  .py-lg-8 {
    padding-top: 4rem;
    padding-bottom: 4rem;
  }
  .py-lg-9 {
    padding-top: 4.5rem;
    padding-bottom: 4.5rem;
  }
  .py-lg-9-5 {
    padding-top: 4.75rem;
    padding-bottom: 4.75rem;
  }
  .py-lg-10 {
    padding-top: 5rem;
    padding-bottom: 5rem;
  }
  .py-lg-auto {
    padding-top: "auto";
    padding-bottom: "auto";
  }
  .pt-lg-0 {
    padding-top: 0;
  }
  .pt-lg-0-5 {
    padding-top: 0.25rem;
  }
  .pt-lg-1 {
    padding-top: 0.5rem;
  }
  .pt-lg-1-5 {
    padding-top: 0.75rem;
  }
  .pt-lg-2 {
    padding-top: 1rem;
  }
  .pt-lg-2-5 {
    padding-top: 1.25rem;
  }
  .pt-lg-3 {
    padding-top: 1.5rem;
  }
  .pt-lg-3-5 {
    padding-top: 1.75rem;
  }
  .pt-lg-4 {
    padding-top: 2rem;
  }
  .pt-lg-5 {
    padding-top: 2.5rem;
  }
  .pt-lg-5-5 {
    padding-top: 2.75rem;
  }
  .pt-lg-6 {
    padding-top: 3rem;
  }
  .pt-lg-7 {
    padding-top: 3.5rem;
  }
  .pt-lg-7-5 {
    padding-top: 3.75rem;
  }
  .pt-lg-8 {
    padding-top: 4rem;
  }
  .pt-lg-9 {
    padding-top: 4.5rem;
  }
  .pt-lg-9-5 {
    padding-top: 4.75rem;
  }
  .pt-lg-10 {
    padding-top: 5rem;
  }
  .pt-lg-auto {
    padding-top: "auto";
  }
  .pe-lg-0 {
    padding-right: 0;
  }
  .pe-lg-0-5 {
    padding-right: 0.25rem;
  }
  .pe-lg-1 {
    padding-right: 0.5rem;
  }
  .pe-lg-1-5 {
    padding-right: 0.75rem;
  }
  .pe-lg-2 {
    padding-right: 1rem;
  }
  .pe-lg-2-5 {
    padding-right: 1.25rem;
  }
  .pe-lg-3 {
    padding-right: 1.5rem;
  }
  .pe-lg-3-5 {
    padding-right: 1.75rem;
  }
  .pe-lg-4 {
    padding-right: 2rem;
  }
  .pe-lg-5 {
    padding-right: 2.5rem;
  }
  .pe-lg-5-5 {
    padding-right: 2.75rem;
  }
  .pe-lg-6 {
    padding-right: 3rem;
  }
  .pe-lg-7 {
    padding-right: 3.5rem;
  }
  .pe-lg-7-5 {
    padding-right: 3.75rem;
  }
  .pe-lg-8 {
    padding-right: 4rem;
  }
  .pe-lg-9 {
    padding-right: 4.5rem;
  }
  .pe-lg-9-5 {
    padding-right: 4.75rem;
  }
  .pe-lg-10 {
    padding-right: 5rem;
  }
  .pe-lg-auto {
    padding-right: "auto";
  }
  .pb-lg-0 {
    padding-bottom: 0;
  }
  .pb-lg-0-5 {
    padding-bottom: 0.25rem;
  }
  .pb-lg-1 {
    padding-bottom: 0.5rem;
  }
  .pb-lg-1-5 {
    padding-bottom: 0.75rem;
  }
  .pb-lg-2 {
    padding-bottom: 1rem;
  }
  .pb-lg-2-5 {
    padding-bottom: 1.25rem;
  }
  .pb-lg-3 {
    padding-bottom: 1.5rem;
  }
  .pb-lg-3-5 {
    padding-bottom: 1.75rem;
  }
  .pb-lg-4 {
    padding-bottom: 2rem;
  }
  .pb-lg-5 {
    padding-bottom: 2.5rem;
  }
  .pb-lg-5-5 {
    padding-bottom: 2.75rem;
  }
  .pb-lg-6 {
    padding-bottom: 3rem;
  }
  .pb-lg-7 {
    padding-bottom: 3.5rem;
  }
  .pb-lg-7-5 {
    padding-bottom: 3.75rem;
  }
  .pb-lg-8 {
    padding-bottom: 4rem;
  }
  .pb-lg-9 {
    padding-bottom: 4.5rem;
  }
  .pb-lg-9-5 {
    padding-bottom: 4.75rem;
  }
  .pb-lg-10 {
    padding-bottom: 5rem;
  }
  .pb-lg-auto {
    padding-bottom: "auto";
  }
  .ps-lg-0 {
    padding-left: 0;
  }
  .ps-lg-0-5 {
    padding-left: 0.25rem;
  }
  .ps-lg-1 {
    padding-left: 0.5rem;
  }
  .ps-lg-1-5 {
    padding-left: 0.75rem;
  }
  .ps-lg-2 {
    padding-left: 1rem;
  }
  .ps-lg-2-5 {
    padding-left: 1.25rem;
  }
  .ps-lg-3 {
    padding-left: 1.5rem;
  }
  .ps-lg-3-5 {
    padding-left: 1.75rem;
  }
  .ps-lg-4 {
    padding-left: 2rem;
  }
  .ps-lg-5 {
    padding-left: 2.5rem;
  }
  .ps-lg-5-5 {
    padding-left: 2.75rem;
  }
  .ps-lg-6 {
    padding-left: 3rem;
  }
  .ps-lg-7 {
    padding-left: 3.5rem;
  }
  .ps-lg-7-5 {
    padding-left: 3.75rem;
  }
  .ps-lg-8 {
    padding-left: 4rem;
  }
  .ps-lg-9 {
    padding-left: 4.5rem;
  }
  .ps-lg-9-5 {
    padding-left: 4.75rem;
  }
  .ps-lg-10 {
    padding-left: 5rem;
  }
  .ps-lg-auto {
    padding-left: "auto";
  }
  .gap-lg-0 {
    gap: 0;
  }
  .gap-lg-0-5 {
    gap: 0.25rem;
  }
  .gap-lg-1 {
    gap: 0.5rem;
  }
  .gap-lg-1-5 {
    gap: 0.75rem;
  }
  .gap-lg-2 {
    gap: 1rem;
  }
  .gap-lg-2-5 {
    gap: 1.25rem;
  }
  .gap-lg-3 {
    gap: 1.5rem;
  }
  .gap-lg-3-5 {
    gap: 1.75rem;
  }
  .gap-lg-4 {
    gap: 2rem;
  }
  .gap-lg-5 {
    gap: 2.5rem;
  }
  .gap-lg-5-5 {
    gap: 2.75rem;
  }
  .gap-lg-6 {
    gap: 3rem;
  }
  .gap-lg-7 {
    gap: 3.5rem;
  }
  .gap-lg-7-5 {
    gap: 3.75rem;
  }
  .gap-lg-8 {
    gap: 4rem;
  }
  .gap-lg-9 {
    gap: 4.5rem;
  }
  .gap-lg-9-5 {
    gap: 4.75rem;
  }
  .gap-lg-10 {
    gap: 5rem;
  }
  .gap-lg-auto {
    gap: "auto";
  }
  .text-lg-start {
    text-align: left;
  }
  .text-lg-end {
    text-align: right;
  }
  .text-lg-center {
    text-align: center;
  }
}
@media (min-width: 1200px) {
  .float-xl-start {
    float: left;
  }
  .float-xl-end {
    float: right;
  }
  .float-xl-none {
    float: none;
  }
  .d-xl-inline {
    display: inline;
  }
  .d-xl-inline-block {
    display: inline-block;
  }
  .d-xl-block {
    display: block;
  }
  .d-xl-grid {
    display: grid;
  }
  .d-xl-table {
    display: table;
  }
  .d-xl-table-row {
    display: table-row;
  }
  .d-xl-table-cell {
    display: table-cell;
  }
  .d-xl-flex {
    display: flex;
  }
  .d-xl-inline-flex {
    display: inline-flex;
  }
  .d-xl-none {
    display: none;
  }
  .flex-xl-fill {
    flex: 1 1 auto;
  }
  .flex-xl-row {
    flex-direction: row;
  }
  .flex-xl-column {
    flex-direction: column;
  }
  .flex-xl-row-reverse {
    flex-direction: row-reverse;
  }
  .flex-xl-column-reverse {
    flex-direction: column-reverse;
  }
  .flex-xl-grow-0 {
    flex-grow: 0;
  }
  .flex-xl-grow-1 {
    flex-grow: 1;
  }
  .flex-xl-shrink-0 {
    flex-shrink: 0;
  }
  .flex-xl-shrink-1 {
    flex-shrink: 1;
  }
  .flex-xl-wrap {
    flex-wrap: wrap;
  }
  .flex-xl-nowrap {
    flex-wrap: nowrap;
  }
  .flex-xl-wrap-reverse {
    flex-wrap: wrap-reverse;
  }
  .justify-content-xl-start {
    justify-content: flex-start;
  }
  .justify-content-xl-end {
    justify-content: flex-end;
  }
  .justify-content-xl-center {
    justify-content: center;
  }
  .justify-content-xl-between {
    justify-content: space-between;
  }
  .justify-content-xl-around {
    justify-content: space-around;
  }
  .justify-content-xl-evenly {
    justify-content: space-evenly;
  }
  .align-items-xl-start {
    align-items: flex-start;
  }
  .align-items-xl-end {
    align-items: flex-end;
  }
  .align-items-xl-center {
    align-items: center;
  }
  .align-items-xl-baseline {
    align-items: baseline;
  }
  .align-items-xl-stretch {
    align-items: stretch;
  }
  .align-content-xl-start {
    align-content: flex-start;
  }
  .align-content-xl-end {
    align-content: flex-end;
  }
  .align-content-xl-center {
    align-content: center;
  }
  .align-content-xl-between {
    align-content: space-between;
  }
  .align-content-xl-around {
    align-content: space-around;
  }
  .align-content-xl-stretch {
    align-content: stretch;
  }
  .align-self-xl-auto {
    align-self: auto;
  }
  .align-self-xl-start {
    align-self: flex-start;
  }
  .align-self-xl-end {
    align-self: flex-end;
  }
  .align-self-xl-center {
    align-self: center;
  }
  .align-self-xl-baseline {
    align-self: baseline;
  }
  .align-self-xl-stretch {
    align-self: stretch;
  }
  .order-xl-first {
    order: -1;
  }
  .order-xl-0 {
    order: 0;
  }
  .order-xl-1 {
    order: 1;
  }
  .order-xl-2 {
    order: 2;
  }
  .order-xl-3 {
    order: 3;
  }
  .order-xl-4 {
    order: 4;
  }
  .order-xl-5 {
    order: 5;
  }
  .order-xl-last {
    order: 6;
  }
  .m-xl-0 {
    margin: 0;
  }
  .m-xl-0-5 {
    margin: 0.25rem;
  }
  .m-xl-1 {
    margin: 0.5rem;
  }
  .m-xl-1-5 {
    margin: 0.75rem;
  }
  .m-xl-2 {
    margin: 1rem;
  }
  .m-xl-2-5 {
    margin: 1.25rem;
  }
  .m-xl-3 {
    margin: 1.5rem;
  }
  .m-xl-3-5 {
    margin: 1.75rem;
  }
  .m-xl-4 {
    margin: 2rem;
  }
  .m-xl-5 {
    margin: 2.5rem;
  }
  .m-xl-5-5 {
    margin: 2.75rem;
  }
  .m-xl-6 {
    margin: 3rem;
  }
  .m-xl-7 {
    margin: 3.5rem;
  }
  .m-xl-7-5 {
    margin: 3.75rem;
  }
  .m-xl-8 {
    margin: 4rem;
  }
  .m-xl-9 {
    margin: 4.5rem;
  }
  .m-xl-9-5 {
    margin: 4.75rem;
  }
  .m-xl-10 {
    margin: 5rem;
  }
  .m-xl-auto {
    margin: auto;
  }
  .mx-xl-0 {
    margin-right: 0;
    margin-left: 0;
  }
  .mx-xl-0-5 {
    margin-right: 0.25rem;
    margin-left: 0.25rem;
  }
  .mx-xl-1 {
    margin-right: 0.5rem;
    margin-left: 0.5rem;
  }
  .mx-xl-1-5 {
    margin-right: 0.75rem;
    margin-left: 0.75rem;
  }
  .mx-xl-2 {
    margin-right: 1rem;
    margin-left: 1rem;
  }
  .mx-xl-2-5 {
    margin-right: 1.25rem;
    margin-left: 1.25rem;
  }
  .mx-xl-3 {
    margin-right: 1.5rem;
    margin-left: 1.5rem;
  }
  .mx-xl-3-5 {
    margin-right: 1.75rem;
    margin-left: 1.75rem;
  }
  .mx-xl-4 {
    margin-right: 2rem;
    margin-left: 2rem;
  }
  .mx-xl-5 {
    margin-right: 2.5rem;
    margin-left: 2.5rem;
  }
  .mx-xl-5-5 {
    margin-right: 2.75rem;
    margin-left: 2.75rem;
  }
  .mx-xl-6 {
    margin-right: 3rem;
    margin-left: 3rem;
  }
  .mx-xl-7 {
    margin-right: 3.5rem;
    margin-left: 3.5rem;
  }
  .mx-xl-7-5 {
    margin-right: 3.75rem;
    margin-left: 3.75rem;
  }
  .mx-xl-8 {
    margin-right: 4rem;
    margin-left: 4rem;
  }
  .mx-xl-9 {
    margin-right: 4.5rem;
    margin-left: 4.5rem;
  }
  .mx-xl-9-5 {
    margin-right: 4.75rem;
    margin-left: 4.75rem;
  }
  .mx-xl-10 {
    margin-right: 5rem;
    margin-left: 5rem;
  }
  .mx-xl-auto {
    margin-right: auto;
    margin-left: auto;
  }
  .my-xl-0 {
    margin-top: 0;
    margin-bottom: 0;
  }
  .my-xl-0-5 {
    margin-top: 0.25rem;
    margin-bottom: 0.25rem;
  }
  .my-xl-1 {
    margin-top: 0.5rem;
    margin-bottom: 0.5rem;
  }
  .my-xl-1-5 {
    margin-top: 0.75rem;
    margin-bottom: 0.75rem;
  }
  .my-xl-2 {
    margin-top: 1rem;
    margin-bottom: 1rem;
  }
  .my-xl-2-5 {
    margin-top: 1.25rem;
    margin-bottom: 1.25rem;
  }
  .my-xl-3 {
    margin-top: 1.5rem;
    margin-bottom: 1.5rem;
  }
  .my-xl-3-5 {
    margin-top: 1.75rem;
    margin-bottom: 1.75rem;
  }
  .my-xl-4 {
    margin-top: 2rem;
    margin-bottom: 2rem;
  }
  .my-xl-5 {
    margin-top: 2.5rem;
    margin-bottom: 2.5rem;
  }
  .my-xl-5-5 {
    margin-top: 2.75rem;
    margin-bottom: 2.75rem;
  }
  .my-xl-6 {
    margin-top: 3rem;
    margin-bottom: 3rem;
  }
  .my-xl-7 {
    margin-top: 3.5rem;
    margin-bottom: 3.5rem;
  }
  .my-xl-7-5 {
    margin-top: 3.75rem;
    margin-bottom: 3.75rem;
  }
  .my-xl-8 {
    margin-top: 4rem;
    margin-bottom: 4rem;
  }
  .my-xl-9 {
    margin-top: 4.5rem;
    margin-bottom: 4.5rem;
  }
  .my-xl-9-5 {
    margin-top: 4.75rem;
    margin-bottom: 4.75rem;
  }
  .my-xl-10 {
    margin-top: 5rem;
    margin-bottom: 5rem;
  }
  .my-xl-auto {
    margin-top: auto;
    margin-bottom: auto;
  }
  .mt-xl-0 {
    margin-top: 0;
  }
  .mt-xl-0-5 {
    margin-top: 0.25rem;
  }
  .mt-xl-1 {
    margin-top: 0.5rem;
  }
  .mt-xl-1-5 {
    margin-top: 0.75rem;
  }
  .mt-xl-2 {
    margin-top: 1rem;
  }
  .mt-xl-2-5 {
    margin-top: 1.25rem;
  }
  .mt-xl-3 {
    margin-top: 1.5rem;
  }
  .mt-xl-3-5 {
    margin-top: 1.75rem;
  }
  .mt-xl-4 {
    margin-top: 2rem;
  }
  .mt-xl-5 {
    margin-top: 2.5rem;
  }
  .mt-xl-5-5 {
    margin-top: 2.75rem;
  }
  .mt-xl-6 {
    margin-top: 3rem;
  }
  .mt-xl-7 {
    margin-top: 3.5rem;
  }
  .mt-xl-7-5 {
    margin-top: 3.75rem;
  }
  .mt-xl-8 {
    margin-top: 4rem;
  }
  .mt-xl-9 {
    margin-top: 4.5rem;
  }
  .mt-xl-9-5 {
    margin-top: 4.75rem;
  }
  .mt-xl-10 {
    margin-top: 5rem;
  }
  .mt-xl-auto {
    margin-top: auto;
  }
  .me-xl-0 {
    margin-right: 0;
  }
  .me-xl-0-5 {
    margin-right: 0.25rem;
  }
  .me-xl-1 {
    margin-right: 0.5rem;
  }
  .me-xl-1-5 {
    margin-right: 0.75rem;
  }
  .me-xl-2 {
    margin-right: 1rem;
  }
  .me-xl-2-5 {
    margin-right: 1.25rem;
  }
  .me-xl-3 {
    margin-right: 1.5rem;
  }
  .me-xl-3-5 {
    margin-right: 1.75rem;
  }
  .me-xl-4 {
    margin-right: 2rem;
  }
  .me-xl-5 {
    margin-right: 2.5rem;
  }
  .me-xl-5-5 {
    margin-right: 2.75rem;
  }
  .me-xl-6 {
    margin-right: 3rem;
  }
  .me-xl-7 {
    margin-right: 3.5rem;
  }
  .me-xl-7-5 {
    margin-right: 3.75rem;
  }
  .me-xl-8 {
    margin-right: 4rem;
  }
  .me-xl-9 {
    margin-right: 4.5rem;
  }
  .me-xl-9-5 {
    margin-right: 4.75rem;
  }
  .me-xl-10 {
    margin-right: 5rem;
  }
  .me-xl-auto {
    margin-right: auto;
  }
  .mb-xl-0 {
    margin-bottom: 0;
  }
  .mb-xl-0-5 {
    margin-bottom: 0.25rem;
  }
  .mb-xl-1 {
    margin-bottom: 0.5rem;
  }
  .mb-xl-1-5 {
    margin-bottom: 0.75rem;
  }
  .mb-xl-2 {
    margin-bottom: 1rem;
  }
  .mb-xl-2-5 {
    margin-bottom: 1.25rem;
  }
  .mb-xl-3 {
    margin-bottom: 1.5rem;
  }
  .mb-xl-3-5 {
    margin-bottom: 1.75rem;
  }
  .mb-xl-4 {
    margin-bottom: 2rem;
  }
  .mb-xl-5 {
    margin-bottom: 2.5rem;
  }
  .mb-xl-5-5 {
    margin-bottom: 2.75rem;
  }
  .mb-xl-6 {
    margin-bottom: 3rem;
  }
  .mb-xl-7 {
    margin-bottom: 3.5rem;
  }
  .mb-xl-7-5 {
    margin-bottom: 3.75rem;
  }
  .mb-xl-8 {
    margin-bottom: 4rem;
  }
  .mb-xl-9 {
    margin-bottom: 4.5rem;
  }
  .mb-xl-9-5 {
    margin-bottom: 4.75rem;
  }
  .mb-xl-10 {
    margin-bottom: 5rem;
  }
  .mb-xl-auto {
    margin-bottom: auto;
  }
  .ms-xl-0 {
    margin-left: 0;
  }
  .ms-xl-0-5 {
    margin-left: 0.25rem;
  }
  .ms-xl-1 {
    margin-left: 0.5rem;
  }
  .ms-xl-1-5 {
    margin-left: 0.75rem;
  }
  .ms-xl-2 {
    margin-left: 1rem;
  }
  .ms-xl-2-5 {
    margin-left: 1.25rem;
  }
  .ms-xl-3 {
    margin-left: 1.5rem;
  }
  .ms-xl-3-5 {
    margin-left: 1.75rem;
  }
  .ms-xl-4 {
    margin-left: 2rem;
  }
  .ms-xl-5 {
    margin-left: 2.5rem;
  }
  .ms-xl-5-5 {
    margin-left: 2.75rem;
  }
  .ms-xl-6 {
    margin-left: 3rem;
  }
  .ms-xl-7 {
    margin-left: 3.5rem;
  }
  .ms-xl-7-5 {
    margin-left: 3.75rem;
  }
  .ms-xl-8 {
    margin-left: 4rem;
  }
  .ms-xl-9 {
    margin-left: 4.5rem;
  }
  .ms-xl-9-5 {
    margin-left: 4.75rem;
  }
  .ms-xl-10 {
    margin-left: 5rem;
  }
  .ms-xl-auto {
    margin-left: auto;
  }
  .m-xl-n0 {
    margin: 0;
  }
  .m-xl-n0-5 {
    margin: -0.25rem;
  }
  .m-xl-n1 {
    margin: -0.5rem;
  }
  .m-xl-n1-5 {
    margin: -0.75rem;
  }
  .m-xl-n2 {
    margin: -1rem;
  }
  .m-xl-n2-5 {
    margin: -1.25rem;
  }
  .m-xl-n3 {
    margin: -1.5rem;
  }
  .m-xl-n3-5 {
    margin: -1.75rem;
  }
  .m-xl-n4 {
    margin: -2rem;
  }
  .m-xl-n5 {
    margin: -2.5rem;
  }
  .m-xl-n5-5 {
    margin: -2.75rem;
  }
  .m-xl-n6 {
    margin: -3rem;
  }
  .m-xl-n7 {
    margin: -3.5rem;
  }
  .m-xl-n7-5 {
    margin: -3.75rem;
  }
  .m-xl-n8 {
    margin: -4rem;
  }
  .m-xl-n9 {
    margin: -4.5rem;
  }
  .m-xl-n9-5 {
    margin: -4.75rem;
  }
  .m-xl-n10 {
    margin: -5rem;
  }
  .m-xl-nauto {
    margin: -"auto";
  }
  .mx-xl-n0 {
    margin-right: 0;
    margin-left: 0;
  }
  .mx-xl-n0-5 {
    margin-right: -0.25rem;
    margin-left: -0.25rem;
  }
  .mx-xl-n1 {
    margin-right: -0.5rem;
    margin-left: -0.5rem;
  }
  .mx-xl-n1-5 {
    margin-right: -0.75rem;
    margin-left: -0.75rem;
  }
  .mx-xl-n2 {
    margin-right: -1rem;
    margin-left: -1rem;
  }
  .mx-xl-n2-5 {
    margin-right: -1.25rem;
    margin-left: -1.25rem;
  }
  .mx-xl-n3 {
    margin-right: -1.5rem;
    margin-left: -1.5rem;
  }
  .mx-xl-n3-5 {
    margin-right: -1.75rem;
    margin-left: -1.75rem;
  }
  .mx-xl-n4 {
    margin-right: -2rem;
    margin-left: -2rem;
  }
  .mx-xl-n5 {
    margin-right: -2.5rem;
    margin-left: -2.5rem;
  }
  .mx-xl-n5-5 {
    margin-right: -2.75rem;
    margin-left: -2.75rem;
  }
  .mx-xl-n6 {
    margin-right: -3rem;
    margin-left: -3rem;
  }
  .mx-xl-n7 {
    margin-right: -3.5rem;
    margin-left: -3.5rem;
  }
  .mx-xl-n7-5 {
    margin-right: -3.75rem;
    margin-left: -3.75rem;
  }
  .mx-xl-n8 {
    margin-right: -4rem;
    margin-left: -4rem;
  }
  .mx-xl-n9 {
    margin-right: -4.5rem;
    margin-left: -4.5rem;
  }
  .mx-xl-n9-5 {
    margin-right: -4.75rem;
    margin-left: -4.75rem;
  }
  .mx-xl-n10 {
    margin-right: -5rem;
    margin-left: -5rem;
  }
  .mx-xl-nauto {
    margin-right: -"auto";
    margin-left: -"auto";
  }
  .my-xl-n0 {
    margin-top: 0;
    margin-bottom: 0;
  }
  .my-xl-n0-5 {
    margin-top: -0.25rem;
    margin-bottom: -0.25rem;
  }
  .my-xl-n1 {
    margin-top: -0.5rem;
    margin-bottom: -0.5rem;
  }
  .my-xl-n1-5 {
    margin-top: -0.75rem;
    margin-bottom: -0.75rem;
  }
  .my-xl-n2 {
    margin-top: -1rem;
    margin-bottom: -1rem;
  }
  .my-xl-n2-5 {
    margin-top: -1.25rem;
    margin-bottom: -1.25rem;
  }
  .my-xl-n3 {
    margin-top: -1.5rem;
    margin-bottom: -1.5rem;
  }
  .my-xl-n3-5 {
    margin-top: -1.75rem;
    margin-bottom: -1.75rem;
  }
  .my-xl-n4 {
    margin-top: -2rem;
    margin-bottom: -2rem;
  }
  .my-xl-n5 {
    margin-top: -2.5rem;
    margin-bottom: -2.5rem;
  }
  .my-xl-n5-5 {
    margin-top: -2.75rem;
    margin-bottom: -2.75rem;
  }
  .my-xl-n6 {
    margin-top: -3rem;
    margin-bottom: -3rem;
  }
  .my-xl-n7 {
    margin-top: -3.5rem;
    margin-bottom: -3.5rem;
  }
  .my-xl-n7-5 {
    margin-top: -3.75rem;
    margin-bottom: -3.75rem;
  }
  .my-xl-n8 {
    margin-top: -4rem;
    margin-bottom: -4rem;
  }
  .my-xl-n9 {
    margin-top: -4.5rem;
    margin-bottom: -4.5rem;
  }
  .my-xl-n9-5 {
    margin-top: -4.75rem;
    margin-bottom: -4.75rem;
  }
  .my-xl-n10 {
    margin-top: -5rem;
    margin-bottom: -5rem;
  }
  .my-xl-nauto {
    margin-top: -"auto";
    margin-bottom: -"auto";
  }
  .mt-xl-n0 {
    margin-top: 0;
  }
  .mt-xl-n0-5 {
    margin-top: -0.25rem;
  }
  .mt-xl-n1 {
    margin-top: -0.5rem;
  }
  .mt-xl-n1-5 {
    margin-top: -0.75rem;
  }
  .mt-xl-n2 {
    margin-top: -1rem;
  }
  .mt-xl-n2-5 {
    margin-top: -1.25rem;
  }
  .mt-xl-n3 {
    margin-top: -1.5rem;
  }
  .mt-xl-n3-5 {
    margin-top: -1.75rem;
  }
  .mt-xl-n4 {
    margin-top: -2rem;
  }
  .mt-xl-n5 {
    margin-top: -2.5rem;
  }
  .mt-xl-n5-5 {
    margin-top: -2.75rem;
  }
  .mt-xl-n6 {
    margin-top: -3rem;
  }
  .mt-xl-n7 {
    margin-top: -3.5rem;
  }
  .mt-xl-n7-5 {
    margin-top: -3.75rem;
  }
  .mt-xl-n8 {
    margin-top: -4rem;
  }
  .mt-xl-n9 {
    margin-top: -4.5rem;
  }
  .mt-xl-n9-5 {
    margin-top: -4.75rem;
  }
  .mt-xl-n10 {
    margin-top: -5rem;
  }
  .mt-xl-nauto {
    margin-top: -"auto";
  }
  .me-xl-n0 {
    margin-right: 0;
  }
  .me-xl-n0-5 {
    margin-right: -0.25rem;
  }
  .me-xl-n1 {
    margin-right: -0.5rem;
  }
  .me-xl-n1-5 {
    margin-right: -0.75rem;
  }
  .me-xl-n2 {
    margin-right: -1rem;
  }
  .me-xl-n2-5 {
    margin-right: -1.25rem;
  }
  .me-xl-n3 {
    margin-right: -1.5rem;
  }
  .me-xl-n3-5 {
    margin-right: -1.75rem;
  }
  .me-xl-n4 {
    margin-right: -2rem;
  }
  .me-xl-n5 {
    margin-right: -2.5rem;
  }
  .me-xl-n5-5 {
    margin-right: -2.75rem;
  }
  .me-xl-n6 {
    margin-right: -3rem;
  }
  .me-xl-n7 {
    margin-right: -3.5rem;
  }
  .me-xl-n7-5 {
    margin-right: -3.75rem;
  }
  .me-xl-n8 {
    margin-right: -4rem;
  }
  .me-xl-n9 {
    margin-right: -4.5rem;
  }
  .me-xl-n9-5 {
    margin-right: -4.75rem;
  }
  .me-xl-n10 {
    margin-right: -5rem;
  }
  .me-xl-nauto {
    margin-right: -"auto";
  }
  .mb-xl-n0 {
    margin-bottom: 0;
  }
  .mb-xl-n0-5 {
    margin-bottom: -0.25rem;
  }
  .mb-xl-n1 {
    margin-bottom: -0.5rem;
  }
  .mb-xl-n1-5 {
    margin-bottom: -0.75rem;
  }
  .mb-xl-n2 {
    margin-bottom: -1rem;
  }
  .mb-xl-n2-5 {
    margin-bottom: -1.25rem;
  }
  .mb-xl-n3 {
    margin-bottom: -1.5rem;
  }
  .mb-xl-n3-5 {
    margin-bottom: -1.75rem;
  }
  .mb-xl-n4 {
    margin-bottom: -2rem;
  }
  .mb-xl-n5 {
    margin-bottom: -2.5rem;
  }
  .mb-xl-n5-5 {
    margin-bottom: -2.75rem;
  }
  .mb-xl-n6 {
    margin-bottom: -3rem;
  }
  .mb-xl-n7 {
    margin-bottom: -3.5rem;
  }
  .mb-xl-n7-5 {
    margin-bottom: -3.75rem;
  }
  .mb-xl-n8 {
    margin-bottom: -4rem;
  }
  .mb-xl-n9 {
    margin-bottom: -4.5rem;
  }
  .mb-xl-n9-5 {
    margin-bottom: -4.75rem;
  }
  .mb-xl-n10 {
    margin-bottom: -5rem;
  }
  .mb-xl-nauto {
    margin-bottom: -"auto";
  }
  .ms-xl-n0 {
    margin-left: 0;
  }
  .ms-xl-n0-5 {
    margin-left: -0.25rem;
  }
  .ms-xl-n1 {
    margin-left: -0.5rem;
  }
  .ms-xl-n1-5 {
    margin-left: -0.75rem;
  }
  .ms-xl-n2 {
    margin-left: -1rem;
  }
  .ms-xl-n2-5 {
    margin-left: -1.25rem;
  }
  .ms-xl-n3 {
    margin-left: -1.5rem;
  }
  .ms-xl-n3-5 {
    margin-left: -1.75rem;
  }
  .ms-xl-n4 {
    margin-left: -2rem;
  }
  .ms-xl-n5 {
    margin-left: -2.5rem;
  }
  .ms-xl-n5-5 {
    margin-left: -2.75rem;
  }
  .ms-xl-n6 {
    margin-left: -3rem;
  }
  .ms-xl-n7 {
    margin-left: -3.5rem;
  }
  .ms-xl-n7-5 {
    margin-left: -3.75rem;
  }
  .ms-xl-n8 {
    margin-left: -4rem;
  }
  .ms-xl-n9 {
    margin-left: -4.5rem;
  }
  .ms-xl-n9-5 {
    margin-left: -4.75rem;
  }
  .ms-xl-n10 {
    margin-left: -5rem;
  }
  .ms-xl-nauto {
    margin-left: -"auto";
  }
  .p-xl-0 {
    padding: 0;
  }
  .p-xl-0-5 {
    padding: 0.25rem;
  }
  .p-xl-1 {
    padding: 0.5rem;
  }
  .p-xl-1-5 {
    padding: 0.75rem;
  }
  .p-xl-2 {
    padding: 1rem;
  }
  .p-xl-2-5 {
    padding: 1.25rem;
  }
  .p-xl-3 {
    padding: 1.5rem;
  }
  .p-xl-3-5 {
    padding: 1.75rem;
  }
  .p-xl-4 {
    padding: 2rem;
  }
  .p-xl-5 {
    padding: 2.5rem;
  }
  .p-xl-5-5 {
    padding: 2.75rem;
  }
  .p-xl-6 {
    padding: 3rem;
  }
  .p-xl-7 {
    padding: 3.5rem;
  }
  .p-xl-7-5 {
    padding: 3.75rem;
  }
  .p-xl-8 {
    padding: 4rem;
  }
  .p-xl-9 {
    padding: 4.5rem;
  }
  .p-xl-9-5 {
    padding: 4.75rem;
  }
  .p-xl-10 {
    padding: 5rem;
  }
  .p-xl-auto {
    padding: "auto";
  }
  .px-xl-0 {
    padding-right: 0;
    padding-left: 0;
  }
  .px-xl-0-5 {
    padding-right: 0.25rem;
    padding-left: 0.25rem;
  }
  .px-xl-1 {
    padding-right: 0.5rem;
    padding-left: 0.5rem;
  }
  .px-xl-1-5 {
    padding-right: 0.75rem;
    padding-left: 0.75rem;
  }
  .px-xl-2 {
    padding-right: 1rem;
    padding-left: 1rem;
  }
  .px-xl-2-5 {
    padding-right: 1.25rem;
    padding-left: 1.25rem;
  }
  .px-xl-3 {
    padding-right: 1.5rem;
    padding-left: 1.5rem;
  }
  .px-xl-3-5 {
    padding-right: 1.75rem;
    padding-left: 1.75rem;
  }
  .px-xl-4 {
    padding-right: 2rem;
    padding-left: 2rem;
  }
  .px-xl-5 {
    padding-right: 2.5rem;
    padding-left: 2.5rem;
  }
  .px-xl-5-5 {
    padding-right: 2.75rem;
    padding-left: 2.75rem;
  }
  .px-xl-6 {
    padding-right: 3rem;
    padding-left: 3rem;
  }
  .px-xl-7 {
    padding-right: 3.5rem;
    padding-left: 3.5rem;
  }
  .px-xl-7-5 {
    padding-right: 3.75rem;
    padding-left: 3.75rem;
  }
  .px-xl-8 {
    padding-right: 4rem;
    padding-left: 4rem;
  }
  .px-xl-9 {
    padding-right: 4.5rem;
    padding-left: 4.5rem;
  }
  .px-xl-9-5 {
    padding-right: 4.75rem;
    padding-left: 4.75rem;
  }
  .px-xl-10 {
    padding-right: 5rem;
    padding-left: 5rem;
  }
  .px-xl-auto {
    padding-right: "auto";
    padding-left: "auto";
  }
  .py-xl-0 {
    padding-top: 0;
    padding-bottom: 0;
  }
  .py-xl-0-5 {
    padding-top: 0.25rem;
    padding-bottom: 0.25rem;
  }
  .py-xl-1 {
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
  }
  .py-xl-1-5 {
    padding-top: 0.75rem;
    padding-bottom: 0.75rem;
  }
  .py-xl-2 {
    padding-top: 1rem;
    padding-bottom: 1rem;
  }
  .py-xl-2-5 {
    padding-top: 1.25rem;
    padding-bottom: 1.25rem;
  }
  .py-xl-3 {
    padding-top: 1.5rem;
    padding-bottom: 1.5rem;
  }
  .py-xl-3-5 {
    padding-top: 1.75rem;
    padding-bottom: 1.75rem;
  }
  .py-xl-4 {
    padding-top: 2rem;
    padding-bottom: 2rem;
  }
  .py-xl-5 {
    padding-top: 2.5rem;
    padding-bottom: 2.5rem;
  }
  .py-xl-5-5 {
    padding-top: 2.75rem;
    padding-bottom: 2.75rem;
  }
  .py-xl-6 {
    padding-top: 3rem;
    padding-bottom: 3rem;
  }
  .py-xl-7 {
    padding-top: 3.5rem;
    padding-bottom: 3.5rem;
  }
  .py-xl-7-5 {
    padding-top: 3.75rem;
    padding-bottom: 3.75rem;
  }
  .py-xl-8 {
    padding-top: 4rem;
    padding-bottom: 4rem;
  }
  .py-xl-9 {
    padding-top: 4.5rem;
    padding-bottom: 4.5rem;
  }
  .py-xl-9-5 {
    padding-top: 4.75rem;
    padding-bottom: 4.75rem;
  }
  .py-xl-10 {
    padding-top: 5rem;
    padding-bottom: 5rem;
  }
  .py-xl-auto {
    padding-top: "auto";
    padding-bottom: "auto";
  }
  .pt-xl-0 {
    padding-top: 0;
  }
  .pt-xl-0-5 {
    padding-top: 0.25rem;
  }
  .pt-xl-1 {
    padding-top: 0.5rem;
  }
  .pt-xl-1-5 {
    padding-top: 0.75rem;
  }
  .pt-xl-2 {
    padding-top: 1rem;
  }
  .pt-xl-2-5 {
    padding-top: 1.25rem;
  }
  .pt-xl-3 {
    padding-top: 1.5rem;
  }
  .pt-xl-3-5 {
    padding-top: 1.75rem;
  }
  .pt-xl-4 {
    padding-top: 2rem;
  }
  .pt-xl-5 {
    padding-top: 2.5rem;
  }
  .pt-xl-5-5 {
    padding-top: 2.75rem;
  }
  .pt-xl-6 {
    padding-top: 3rem;
  }
  .pt-xl-7 {
    padding-top: 3.5rem;
  }
  .pt-xl-7-5 {
    padding-top: 3.75rem;
  }
  .pt-xl-8 {
    padding-top: 4rem;
  }
  .pt-xl-9 {
    padding-top: 4.5rem;
  }
  .pt-xl-9-5 {
    padding-top: 4.75rem;
  }
  .pt-xl-10 {
    padding-top: 5rem;
  }
  .pt-xl-auto {
    padding-top: "auto";
  }
  .pe-xl-0 {
    padding-right: 0;
  }
  .pe-xl-0-5 {
    padding-right: 0.25rem;
  }
  .pe-xl-1 {
    padding-right: 0.5rem;
  }
  .pe-xl-1-5 {
    padding-right: 0.75rem;
  }
  .pe-xl-2 {
    padding-right: 1rem;
  }
  .pe-xl-2-5 {
    padding-right: 1.25rem;
  }
  .pe-xl-3 {
    padding-right: 1.5rem;
  }
  .pe-xl-3-5 {
    padding-right: 1.75rem;
  }
  .pe-xl-4 {
    padding-right: 2rem;
  }
  .pe-xl-5 {
    padding-right: 2.5rem;
  }
  .pe-xl-5-5 {
    padding-right: 2.75rem;
  }
  .pe-xl-6 {
    padding-right: 3rem;
  }
  .pe-xl-7 {
    padding-right: 3.5rem;
  }
  .pe-xl-7-5 {
    padding-right: 3.75rem;
  }
  .pe-xl-8 {
    padding-right: 4rem;
  }
  .pe-xl-9 {
    padding-right: 4.5rem;
  }
  .pe-xl-9-5 {
    padding-right: 4.75rem;
  }
  .pe-xl-10 {
    padding-right: 5rem;
  }
  .pe-xl-auto {
    padding-right: "auto";
  }
  .pb-xl-0 {
    padding-bottom: 0;
  }
  .pb-xl-0-5 {
    padding-bottom: 0.25rem;
  }
  .pb-xl-1 {
    padding-bottom: 0.5rem;
  }
  .pb-xl-1-5 {
    padding-bottom: 0.75rem;
  }
  .pb-xl-2 {
    padding-bottom: 1rem;
  }
  .pb-xl-2-5 {
    padding-bottom: 1.25rem;
  }
  .pb-xl-3 {
    padding-bottom: 1.5rem;
  }
  .pb-xl-3-5 {
    padding-bottom: 1.75rem;
  }
  .pb-xl-4 {
    padding-bottom: 2rem;
  }
  .pb-xl-5 {
    padding-bottom: 2.5rem;
  }
  .pb-xl-5-5 {
    padding-bottom: 2.75rem;
  }
  .pb-xl-6 {
    padding-bottom: 3rem;
  }
  .pb-xl-7 {
    padding-bottom: 3.5rem;
  }
  .pb-xl-7-5 {
    padding-bottom: 3.75rem;
  }
  .pb-xl-8 {
    padding-bottom: 4rem;
  }
  .pb-xl-9 {
    padding-bottom: 4.5rem;
  }
  .pb-xl-9-5 {
    padding-bottom: 4.75rem;
  }
  .pb-xl-10 {
    padding-bottom: 5rem;
  }
  .pb-xl-auto {
    padding-bottom: "auto";
  }
  .ps-xl-0 {
    padding-left: 0;
  }
  .ps-xl-0-5 {
    padding-left: 0.25rem;
  }
  .ps-xl-1 {
    padding-left: 0.5rem;
  }
  .ps-xl-1-5 {
    padding-left: 0.75rem;
  }
  .ps-xl-2 {
    padding-left: 1rem;
  }
  .ps-xl-2-5 {
    padding-left: 1.25rem;
  }
  .ps-xl-3 {
    padding-left: 1.5rem;
  }
  .ps-xl-3-5 {
    padding-left: 1.75rem;
  }
  .ps-xl-4 {
    padding-left: 2rem;
  }
  .ps-xl-5 {
    padding-left: 2.5rem;
  }
  .ps-xl-5-5 {
    padding-left: 2.75rem;
  }
  .ps-xl-6 {
    padding-left: 3rem;
  }
  .ps-xl-7 {
    padding-left: 3.5rem;
  }
  .ps-xl-7-5 {
    padding-left: 3.75rem;
  }
  .ps-xl-8 {
    padding-left: 4rem;
  }
  .ps-xl-9 {
    padding-left: 4.5rem;
  }
  .ps-xl-9-5 {
    padding-left: 4.75rem;
  }
  .ps-xl-10 {
    padding-left: 5rem;
  }
  .ps-xl-auto {
    padding-left: "auto";
  }
  .gap-xl-0 {
    gap: 0;
  }
  .gap-xl-0-5 {
    gap: 0.25rem;
  }
  .gap-xl-1 {
    gap: 0.5rem;
  }
  .gap-xl-1-5 {
    gap: 0.75rem;
  }
  .gap-xl-2 {
    gap: 1rem;
  }
  .gap-xl-2-5 {
    gap: 1.25rem;
  }
  .gap-xl-3 {
    gap: 1.5rem;
  }
  .gap-xl-3-5 {
    gap: 1.75rem;
  }
  .gap-xl-4 {
    gap: 2rem;
  }
  .gap-xl-5 {
    gap: 2.5rem;
  }
  .gap-xl-5-5 {
    gap: 2.75rem;
  }
  .gap-xl-6 {
    gap: 3rem;
  }
  .gap-xl-7 {
    gap: 3.5rem;
  }
  .gap-xl-7-5 {
    gap: 3.75rem;
  }
  .gap-xl-8 {
    gap: 4rem;
  }
  .gap-xl-9 {
    gap: 4.5rem;
  }
  .gap-xl-9-5 {
    gap: 4.75rem;
  }
  .gap-xl-10 {
    gap: 5rem;
  }
  .gap-xl-auto {
    gap: "auto";
  }
  .text-xl-start {
    text-align: left;
  }
  .text-xl-end {
    text-align: right;
  }
  .text-xl-center {
    text-align: center;
  }
}
@media (min-width: 1400px) {
  .float-xxl-start {
    float: left;
  }
  .float-xxl-end {
    float: right;
  }
  .float-xxl-none {
    float: none;
  }
  .d-xxl-inline {
    display: inline;
  }
  .d-xxl-inline-block {
    display: inline-block;
  }
  .d-xxl-block {
    display: block;
  }
  .d-xxl-grid {
    display: grid;
  }
  .d-xxl-table {
    display: table;
  }
  .d-xxl-table-row {
    display: table-row;
  }
  .d-xxl-table-cell {
    display: table-cell;
  }
  .d-xxl-flex {
    display: flex;
  }
  .d-xxl-inline-flex {
    display: inline-flex;
  }
  .d-xxl-none {
    display: none;
  }
  .flex-xxl-fill {
    flex: 1 1 auto;
  }
  .flex-xxl-row {
    flex-direction: row;
  }
  .flex-xxl-column {
    flex-direction: column;
  }
  .flex-xxl-row-reverse {
    flex-direction: row-reverse;
  }
  .flex-xxl-column-reverse {
    flex-direction: column-reverse;
  }
  .flex-xxl-grow-0 {
    flex-grow: 0;
  }
  .flex-xxl-grow-1 {
    flex-grow: 1;
  }
  .flex-xxl-shrink-0 {
    flex-shrink: 0;
  }
  .flex-xxl-shrink-1 {
    flex-shrink: 1;
  }
  .flex-xxl-wrap {
    flex-wrap: wrap;
  }
  .flex-xxl-nowrap {
    flex-wrap: nowrap;
  }
  .flex-xxl-wrap-reverse {
    flex-wrap: wrap-reverse;
  }
  .justify-content-xxl-start {
    justify-content: flex-start;
  }
  .justify-content-xxl-end {
    justify-content: flex-end;
  }
  .justify-content-xxl-center {
    justify-content: center;
  }
  .justify-content-xxl-between {
    justify-content: space-between;
  }
  .justify-content-xxl-around {
    justify-content: space-around;
  }
  .justify-content-xxl-evenly {
    justify-content: space-evenly;
  }
  .align-items-xxl-start {
    align-items: flex-start;
  }
  .align-items-xxl-end {
    align-items: flex-end;
  }
  .align-items-xxl-center {
    align-items: center;
  }
  .align-items-xxl-baseline {
    align-items: baseline;
  }
  .align-items-xxl-stretch {
    align-items: stretch;
  }
  .align-content-xxl-start {
    align-content: flex-start;
  }
  .align-content-xxl-end {
    align-content: flex-end;
  }
  .align-content-xxl-center {
    align-content: center;
  }
  .align-content-xxl-between {
    align-content: space-between;
  }
  .align-content-xxl-around {
    align-content: space-around;
  }
  .align-content-xxl-stretch {
    align-content: stretch;
  }
  .align-self-xxl-auto {
    align-self: auto;
  }
  .align-self-xxl-start {
    align-self: flex-start;
  }
  .align-self-xxl-end {
    align-self: flex-end;
  }
  .align-self-xxl-center {
    align-self: center;
  }
  .align-self-xxl-baseline {
    align-self: baseline;
  }
  .align-self-xxl-stretch {
    align-self: stretch;
  }
  .order-xxl-first {
    order: -1;
  }
  .order-xxl-0 {
    order: 0;
  }
  .order-xxl-1 {
    order: 1;
  }
  .order-xxl-2 {
    order: 2;
  }
  .order-xxl-3 {
    order: 3;
  }
  .order-xxl-4 {
    order: 4;
  }
  .order-xxl-5 {
    order: 5;
  }
  .order-xxl-last {
    order: 6;
  }
  .m-xxl-0 {
    margin: 0;
  }
  .m-xxl-0-5 {
    margin: 0.25rem;
  }
  .m-xxl-1 {
    margin: 0.5rem;
  }
  .m-xxl-1-5 {
    margin: 0.75rem;
  }
  .m-xxl-2 {
    margin: 1rem;
  }
  .m-xxl-2-5 {
    margin: 1.25rem;
  }
  .m-xxl-3 {
    margin: 1.5rem;
  }
  .m-xxl-3-5 {
    margin: 1.75rem;
  }
  .m-xxl-4 {
    margin: 2rem;
  }
  .m-xxl-5 {
    margin: 2.5rem;
  }
  .m-xxl-5-5 {
    margin: 2.75rem;
  }
  .m-xxl-6 {
    margin: 3rem;
  }
  .m-xxl-7 {
    margin: 3.5rem;
  }
  .m-xxl-7-5 {
    margin: 3.75rem;
  }
  .m-xxl-8 {
    margin: 4rem;
  }
  .m-xxl-9 {
    margin: 4.5rem;
  }
  .m-xxl-9-5 {
    margin: 4.75rem;
  }
  .m-xxl-10 {
    margin: 5rem;
  }
  .m-xxl-auto {
    margin: auto;
  }
  .mx-xxl-0 {
    margin-right: 0;
    margin-left: 0;
  }
  .mx-xxl-0-5 {
    margin-right: 0.25rem;
    margin-left: 0.25rem;
  }
  .mx-xxl-1 {
    margin-right: 0.5rem;
    margin-left: 0.5rem;
  }
  .mx-xxl-1-5 {
    margin-right: 0.75rem;
    margin-left: 0.75rem;
  }
  .mx-xxl-2 {
    margin-right: 1rem;
    margin-left: 1rem;
  }
  .mx-xxl-2-5 {
    margin-right: 1.25rem;
    margin-left: 1.25rem;
  }
  .mx-xxl-3 {
    margin-right: 1.5rem;
    margin-left: 1.5rem;
  }
  .mx-xxl-3-5 {
    margin-right: 1.75rem;
    margin-left: 1.75rem;
  }
  .mx-xxl-4 {
    margin-right: 2rem;
    margin-left: 2rem;
  }
  .mx-xxl-5 {
    margin-right: 2.5rem;
    margin-left: 2.5rem;
  }
  .mx-xxl-5-5 {
    margin-right: 2.75rem;
    margin-left: 2.75rem;
  }
  .mx-xxl-6 {
    margin-right: 3rem;
    margin-left: 3rem;
  }
  .mx-xxl-7 {
    margin-right: 3.5rem;
    margin-left: 3.5rem;
  }
  .mx-xxl-7-5 {
    margin-right: 3.75rem;
    margin-left: 3.75rem;
  }
  .mx-xxl-8 {
    margin-right: 4rem;
    margin-left: 4rem;
  }
  .mx-xxl-9 {
    margin-right: 4.5rem;
    margin-left: 4.5rem;
  }
  .mx-xxl-9-5 {
    margin-right: 4.75rem;
    margin-left: 4.75rem;
  }
  .mx-xxl-10 {
    margin-right: 5rem;
    margin-left: 5rem;
  }
  .mx-xxl-auto {
    margin-right: auto;
    margin-left: auto;
  }
  .my-xxl-0 {
    margin-top: 0;
    margin-bottom: 0;
  }
  .my-xxl-0-5 {
    margin-top: 0.25rem;
    margin-bottom: 0.25rem;
  }
  .my-xxl-1 {
    margin-top: 0.5rem;
    margin-bottom: 0.5rem;
  }
  .my-xxl-1-5 {
    margin-top: 0.75rem;
    margin-bottom: 0.75rem;
  }
  .my-xxl-2 {
    margin-top: 1rem;
    margin-bottom: 1rem;
  }
  .my-xxl-2-5 {
    margin-top: 1.25rem;
    margin-bottom: 1.25rem;
  }
  .my-xxl-3 {
    margin-top: 1.5rem;
    margin-bottom: 1.5rem;
  }
  .my-xxl-3-5 {
    margin-top: 1.75rem;
    margin-bottom: 1.75rem;
  }
  .my-xxl-4 {
    margin-top: 2rem;
    margin-bottom: 2rem;
  }
  .my-xxl-5 {
    margin-top: 2.5rem;
    margin-bottom: 2.5rem;
  }
  .my-xxl-5-5 {
    margin-top: 2.75rem;
    margin-bottom: 2.75rem;
  }
  .my-xxl-6 {
    margin-top: 3rem;
    margin-bottom: 3rem;
  }
  .my-xxl-7 {
    margin-top: 3.5rem;
    margin-bottom: 3.5rem;
  }
  .my-xxl-7-5 {
    margin-top: 3.75rem;
    margin-bottom: 3.75rem;
  }
  .my-xxl-8 {
    margin-top: 4rem;
    margin-bottom: 4rem;
  }
  .my-xxl-9 {
    margin-top: 4.5rem;
    margin-bottom: 4.5rem;
  }
  .my-xxl-9-5 {
    margin-top: 4.75rem;
    margin-bottom: 4.75rem;
  }
  .my-xxl-10 {
    margin-top: 5rem;
    margin-bottom: 5rem;
  }
  .my-xxl-auto {
    margin-top: auto;
    margin-bottom: auto;
  }
  .mt-xxl-0 {
    margin-top: 0;
  }
  .mt-xxl-0-5 {
    margin-top: 0.25rem;
  }
  .mt-xxl-1 {
    margin-top: 0.5rem;
  }
  .mt-xxl-1-5 {
    margin-top: 0.75rem;
  }
  .mt-xxl-2 {
    margin-top: 1rem;
  }
  .mt-xxl-2-5 {
    margin-top: 1.25rem;
  }
  .mt-xxl-3 {
    margin-top: 1.5rem;
  }
  .mt-xxl-3-5 {
    margin-top: 1.75rem;
  }
  .mt-xxl-4 {
    margin-top: 2rem;
  }
  .mt-xxl-5 {
    margin-top: 2.5rem;
  }
  .mt-xxl-5-5 {
    margin-top: 2.75rem;
  }
  .mt-xxl-6 {
    margin-top: 3rem;
  }
  .mt-xxl-7 {
    margin-top: 3.5rem;
  }
  .mt-xxl-7-5 {
    margin-top: 3.75rem;
  }
  .mt-xxl-8 {
    margin-top: 4rem;
  }
  .mt-xxl-9 {
    margin-top: 4.5rem;
  }
  .mt-xxl-9-5 {
    margin-top: 4.75rem;
  }
  .mt-xxl-10 {
    margin-top: 5rem;
  }
  .mt-xxl-auto {
    margin-top: auto;
  }
  .me-xxl-0 {
    margin-right: 0;
  }
  .me-xxl-0-5 {
    margin-right: 0.25rem;
  }
  .me-xxl-1 {
    margin-right: 0.5rem;
  }
  .me-xxl-1-5 {
    margin-right: 0.75rem;
  }
  .me-xxl-2 {
    margin-right: 1rem;
  }
  .me-xxl-2-5 {
    margin-right: 1.25rem;
  }
  .me-xxl-3 {
    margin-right: 1.5rem;
  }
  .me-xxl-3-5 {
    margin-right: 1.75rem;
  }
  .me-xxl-4 {
    margin-right: 2rem;
  }
  .me-xxl-5 {
    margin-right: 2.5rem;
  }
  .me-xxl-5-5 {
    margin-right: 2.75rem;
  }
  .me-xxl-6 {
    margin-right: 3rem;
  }
  .me-xxl-7 {
    margin-right: 3.5rem;
  }
  .me-xxl-7-5 {
    margin-right: 3.75rem;
  }
  .me-xxl-8 {
    margin-right: 4rem;
  }
  .me-xxl-9 {
    margin-right: 4.5rem;
  }
  .me-xxl-9-5 {
    margin-right: 4.75rem;
  }
  .me-xxl-10 {
    margin-right: 5rem;
  }
  .me-xxl-auto {
    margin-right: auto;
  }
  .mb-xxl-0 {
    margin-bottom: 0;
  }
  .mb-xxl-0-5 {
    margin-bottom: 0.25rem;
  }
  .mb-xxl-1 {
    margin-bottom: 0.5rem;
  }
  .mb-xxl-1-5 {
    margin-bottom: 0.75rem;
  }
  .mb-xxl-2 {
    margin-bottom: 1rem;
  }
  .mb-xxl-2-5 {
    margin-bottom: 1.25rem;
  }
  .mb-xxl-3 {
    margin-bottom: 1.5rem;
  }
  .mb-xxl-3-5 {
    margin-bottom: 1.75rem;
  }
  .mb-xxl-4 {
    margin-bottom: 2rem;
  }
  .mb-xxl-5 {
    margin-bottom: 2.5rem;
  }
  .mb-xxl-5-5 {
    margin-bottom: 2.75rem;
  }
  .mb-xxl-6 {
    margin-bottom: 3rem;
  }
  .mb-xxl-7 {
    margin-bottom: 3.5rem;
  }
  .mb-xxl-7-5 {
    margin-bottom: 3.75rem;
  }
  .mb-xxl-8 {
    margin-bottom: 4rem;
  }
  .mb-xxl-9 {
    margin-bottom: 4.5rem;
  }
  .mb-xxl-9-5 {
    margin-bottom: 4.75rem;
  }
  .mb-xxl-10 {
    margin-bottom: 5rem;
  }
  .mb-xxl-auto {
    margin-bottom: auto;
  }
  .ms-xxl-0 {
    margin-left: 0;
  }
  .ms-xxl-0-5 {
    margin-left: 0.25rem;
  }
  .ms-xxl-1 {
    margin-left: 0.5rem;
  }
  .ms-xxl-1-5 {
    margin-left: 0.75rem;
  }
  .ms-xxl-2 {
    margin-left: 1rem;
  }
  .ms-xxl-2-5 {
    margin-left: 1.25rem;
  }
  .ms-xxl-3 {
    margin-left: 1.5rem;
  }
  .ms-xxl-3-5 {
    margin-left: 1.75rem;
  }
  .ms-xxl-4 {
    margin-left: 2rem;
  }
  .ms-xxl-5 {
    margin-left: 2.5rem;
  }
  .ms-xxl-5-5 {
    margin-left: 2.75rem;
  }
  .ms-xxl-6 {
    margin-left: 3rem;
  }
  .ms-xxl-7 {
    margin-left: 3.5rem;
  }
  .ms-xxl-7-5 {
    margin-left: 3.75rem;
  }
  .ms-xxl-8 {
    margin-left: 4rem;
  }
  .ms-xxl-9 {
    margin-left: 4.5rem;
  }
  .ms-xxl-9-5 {
    margin-left: 4.75rem;
  }
  .ms-xxl-10 {
    margin-left: 5rem;
  }
  .ms-xxl-auto {
    margin-left: auto;
  }
  .m-xxl-n0 {
    margin: 0;
  }
  .m-xxl-n0-5 {
    margin: -0.25rem;
  }
  .m-xxl-n1 {
    margin: -0.5rem;
  }
  .m-xxl-n1-5 {
    margin: -0.75rem;
  }
  .m-xxl-n2 {
    margin: -1rem;
  }
  .m-xxl-n2-5 {
    margin: -1.25rem;
  }
  .m-xxl-n3 {
    margin: -1.5rem;
  }
  .m-xxl-n3-5 {
    margin: -1.75rem;
  }
  .m-xxl-n4 {
    margin: -2rem;
  }
  .m-xxl-n5 {
    margin: -2.5rem;
  }
  .m-xxl-n5-5 {
    margin: -2.75rem;
  }
  .m-xxl-n6 {
    margin: -3rem;
  }
  .m-xxl-n7 {
    margin: -3.5rem;
  }
  .m-xxl-n7-5 {
    margin: -3.75rem;
  }
  .m-xxl-n8 {
    margin: -4rem;
  }
  .m-xxl-n9 {
    margin: -4.5rem;
  }
  .m-xxl-n9-5 {
    margin: -4.75rem;
  }
  .m-xxl-n10 {
    margin: -5rem;
  }
  .m-xxl-nauto {
    margin: -"auto";
  }
  .mx-xxl-n0 {
    margin-right: 0;
    margin-left: 0;
  }
  .mx-xxl-n0-5 {
    margin-right: -0.25rem;
    margin-left: -0.25rem;
  }
  .mx-xxl-n1 {
    margin-right: -0.5rem;
    margin-left: -0.5rem;
  }
  .mx-xxl-n1-5 {
    margin-right: -0.75rem;
    margin-left: -0.75rem;
  }
  .mx-xxl-n2 {
    margin-right: -1rem;
    margin-left: -1rem;
  }
  .mx-xxl-n2-5 {
    margin-right: -1.25rem;
    margin-left: -1.25rem;
  }
  .mx-xxl-n3 {
    margin-right: -1.5rem;
    margin-left: -1.5rem;
  }
  .mx-xxl-n3-5 {
    margin-right: -1.75rem;
    margin-left: -1.75rem;
  }
  .mx-xxl-n4 {
    margin-right: -2rem;
    margin-left: -2rem;
  }
  .mx-xxl-n5 {
    margin-right: -2.5rem;
    margin-left: -2.5rem;
  }
  .mx-xxl-n5-5 {
    margin-right: -2.75rem;
    margin-left: -2.75rem;
  }
  .mx-xxl-n6 {
    margin-right: -3rem;
    margin-left: -3rem;
  }
  .mx-xxl-n7 {
    margin-right: -3.5rem;
    margin-left: -3.5rem;
  }
  .mx-xxl-n7-5 {
    margin-right: -3.75rem;
    margin-left: -3.75rem;
  }
  .mx-xxl-n8 {
    margin-right: -4rem;
    margin-left: -4rem;
  }
  .mx-xxl-n9 {
    margin-right: -4.5rem;
    margin-left: -4.5rem;
  }
  .mx-xxl-n9-5 {
    margin-right: -4.75rem;
    margin-left: -4.75rem;
  }
  .mx-xxl-n10 {
    margin-right: -5rem;
    margin-left: -5rem;
  }
  .mx-xxl-nauto {
    margin-right: -"auto";
    margin-left: -"auto";
  }
  .my-xxl-n0 {
    margin-top: 0;
    margin-bottom: 0;
  }
  .my-xxl-n0-5 {
    margin-top: -0.25rem;
    margin-bottom: -0.25rem;
  }
  .my-xxl-n1 {
    margin-top: -0.5rem;
    margin-bottom: -0.5rem;
  }
  .my-xxl-n1-5 {
    margin-top: -0.75rem;
    margin-bottom: -0.75rem;
  }
  .my-xxl-n2 {
    margin-top: -1rem;
    margin-bottom: -1rem;
  }
  .my-xxl-n2-5 {
    margin-top: -1.25rem;
    margin-bottom: -1.25rem;
  }
  .my-xxl-n3 {
    margin-top: -1.5rem;
    margin-bottom: -1.5rem;
  }
  .my-xxl-n3-5 {
    margin-top: -1.75rem;
    margin-bottom: -1.75rem;
  }
  .my-xxl-n4 {
    margin-top: -2rem;
    margin-bottom: -2rem;
  }
  .my-xxl-n5 {
    margin-top: -2.5rem;
    margin-bottom: -2.5rem;
  }
  .my-xxl-n5-5 {
    margin-top: -2.75rem;
    margin-bottom: -2.75rem;
  }
  .my-xxl-n6 {
    margin-top: -3rem;
    margin-bottom: -3rem;
  }
  .my-xxl-n7 {
    margin-top: -3.5rem;
    margin-bottom: -3.5rem;
  }
  .my-xxl-n7-5 {
    margin-top: -3.75rem;
    margin-bottom: -3.75rem;
  }
  .my-xxl-n8 {
    margin-top: -4rem;
    margin-bottom: -4rem;
  }
  .my-xxl-n9 {
    margin-top: -4.5rem;
    margin-bottom: -4.5rem;
  }
  .my-xxl-n9-5 {
    margin-top: -4.75rem;
    margin-bottom: -4.75rem;
  }
  .my-xxl-n10 {
    margin-top: -5rem;
    margin-bottom: -5rem;
  }
  .my-xxl-nauto {
    margin-top: -"auto";
    margin-bottom: -"auto";
  }
  .mt-xxl-n0 {
    margin-top: 0;
  }
  .mt-xxl-n0-5 {
    margin-top: -0.25rem;
  }
  .mt-xxl-n1 {
    margin-top: -0.5rem;
  }
  .mt-xxl-n1-5 {
    margin-top: -0.75rem;
  }
  .mt-xxl-n2 {
    margin-top: -1rem;
  }
  .mt-xxl-n2-5 {
    margin-top: -1.25rem;
  }
  .mt-xxl-n3 {
    margin-top: -1.5rem;
  }
  .mt-xxl-n3-5 {
    margin-top: -1.75rem;
  }
  .mt-xxl-n4 {
    margin-top: -2rem;
  }
  .mt-xxl-n5 {
    margin-top: -2.5rem;
  }
  .mt-xxl-n5-5 {
    margin-top: -2.75rem;
  }
  .mt-xxl-n6 {
    margin-top: -3rem;
  }
  .mt-xxl-n7 {
    margin-top: -3.5rem;
  }
  .mt-xxl-n7-5 {
    margin-top: -3.75rem;
  }
  .mt-xxl-n8 {
    margin-top: -4rem;
  }
  .mt-xxl-n9 {
    margin-top: -4.5rem;
  }
  .mt-xxl-n9-5 {
    margin-top: -4.75rem;
  }
  .mt-xxl-n10 {
    margin-top: -5rem;
  }
  .mt-xxl-nauto {
    margin-top: -"auto";
  }
  .me-xxl-n0 {
    margin-right: 0;
  }
  .me-xxl-n0-5 {
    margin-right: -0.25rem;
  }
  .me-xxl-n1 {
    margin-right: -0.5rem;
  }
  .me-xxl-n1-5 {
    margin-right: -0.75rem;
  }
  .me-xxl-n2 {
    margin-right: -1rem;
  }
  .me-xxl-n2-5 {
    margin-right: -1.25rem;
  }
  .me-xxl-n3 {
    margin-right: -1.5rem;
  }
  .me-xxl-n3-5 {
    margin-right: -1.75rem;
  }
  .me-xxl-n4 {
    margin-right: -2rem;
  }
  .me-xxl-n5 {
    margin-right: -2.5rem;
  }
  .me-xxl-n5-5 {
    margin-right: -2.75rem;
  }
  .me-xxl-n6 {
    margin-right: -3rem;
  }
  .me-xxl-n7 {
    margin-right: -3.5rem;
  }
  .me-xxl-n7-5 {
    margin-right: -3.75rem;
  }
  .me-xxl-n8 {
    margin-right: -4rem;
  }
  .me-xxl-n9 {
    margin-right: -4.5rem;
  }
  .me-xxl-n9-5 {
    margin-right: -4.75rem;
  }
  .me-xxl-n10 {
    margin-right: -5rem;
  }
  .me-xxl-nauto {
    margin-right: -"auto";
  }
  .mb-xxl-n0 {
    margin-bottom: 0;
  }
  .mb-xxl-n0-5 {
    margin-bottom: -0.25rem;
  }
  .mb-xxl-n1 {
    margin-bottom: -0.5rem;
  }
  .mb-xxl-n1-5 {
    margin-bottom: -0.75rem;
  }
  .mb-xxl-n2 {
    margin-bottom: -1rem;
  }
  .mb-xxl-n2-5 {
    margin-bottom: -1.25rem;
  }
  .mb-xxl-n3 {
    margin-bottom: -1.5rem;
  }
  .mb-xxl-n3-5 {
    margin-bottom: -1.75rem;
  }
  .mb-xxl-n4 {
    margin-bottom: -2rem;
  }
  .mb-xxl-n5 {
    margin-bottom: -2.5rem;
  }
  .mb-xxl-n5-5 {
    margin-bottom: -2.75rem;
  }
  .mb-xxl-n6 {
    margin-bottom: -3rem;
  }
  .mb-xxl-n7 {
    margin-bottom: -3.5rem;
  }
  .mb-xxl-n7-5 {
    margin-bottom: -3.75rem;
  }
  .mb-xxl-n8 {
    margin-bottom: -4rem;
  }
  .mb-xxl-n9 {
    margin-bottom: -4.5rem;
  }
  .mb-xxl-n9-5 {
    margin-bottom: -4.75rem;
  }
  .mb-xxl-n10 {
    margin-bottom: -5rem;
  }
  .mb-xxl-nauto {
    margin-bottom: -"auto";
  }
  .ms-xxl-n0 {
    margin-left: 0;
  }
  .ms-xxl-n0-5 {
    margin-left: -0.25rem;
  }
  .ms-xxl-n1 {
    margin-left: -0.5rem;
  }
  .ms-xxl-n1-5 {
    margin-left: -0.75rem;
  }
  .ms-xxl-n2 {
    margin-left: -1rem;
  }
  .ms-xxl-n2-5 {
    margin-left: -1.25rem;
  }
  .ms-xxl-n3 {
    margin-left: -1.5rem;
  }
  .ms-xxl-n3-5 {
    margin-left: -1.75rem;
  }
  .ms-xxl-n4 {
    margin-left: -2rem;
  }
  .ms-xxl-n5 {
    margin-left: -2.5rem;
  }
  .ms-xxl-n5-5 {
    margin-left: -2.75rem;
  }
  .ms-xxl-n6 {
    margin-left: -3rem;
  }
  .ms-xxl-n7 {
    margin-left: -3.5rem;
  }
  .ms-xxl-n7-5 {
    margin-left: -3.75rem;
  }
  .ms-xxl-n8 {
    margin-left: -4rem;
  }
  .ms-xxl-n9 {
    margin-left: -4.5rem;
  }
  .ms-xxl-n9-5 {
    margin-left: -4.75rem;
  }
  .ms-xxl-n10 {
    margin-left: -5rem;
  }
  .ms-xxl-nauto {
    margin-left: -"auto";
  }
  .p-xxl-0 {
    padding: 0;
  }
  .p-xxl-0-5 {
    padding: 0.25rem;
  }
  .p-xxl-1 {
    padding: 0.5rem;
  }
  .p-xxl-1-5 {
    padding: 0.75rem;
  }
  .p-xxl-2 {
    padding: 1rem;
  }
  .p-xxl-2-5 {
    padding: 1.25rem;
  }
  .p-xxl-3 {
    padding: 1.5rem;
  }
  .p-xxl-3-5 {
    padding: 1.75rem;
  }
  .p-xxl-4 {
    padding: 2rem;
  }
  .p-xxl-5 {
    padding: 2.5rem;
  }
  .p-xxl-5-5 {
    padding: 2.75rem;
  }
  .p-xxl-6 {
    padding: 3rem;
  }
  .p-xxl-7 {
    padding: 3.5rem;
  }
  .p-xxl-7-5 {
    padding: 3.75rem;
  }
  .p-xxl-8 {
    padding: 4rem;
  }
  .p-xxl-9 {
    padding: 4.5rem;
  }
  .p-xxl-9-5 {
    padding: 4.75rem;
  }
  .p-xxl-10 {
    padding: 5rem;
  }
  .p-xxl-auto {
    padding: "auto";
  }
  .px-xxl-0 {
    padding-right: 0;
    padding-left: 0;
  }
  .px-xxl-0-5 {
    padding-right: 0.25rem;
    padding-left: 0.25rem;
  }
  .px-xxl-1 {
    padding-right: 0.5rem;
    padding-left: 0.5rem;
  }
  .px-xxl-1-5 {
    padding-right: 0.75rem;
    padding-left: 0.75rem;
  }
  .px-xxl-2 {
    padding-right: 1rem;
    padding-left: 1rem;
  }
  .px-xxl-2-5 {
    padding-right: 1.25rem;
    padding-left: 1.25rem;
  }
  .px-xxl-3 {
    padding-right: 1.5rem;
    padding-left: 1.5rem;
  }
  .px-xxl-3-5 {
    padding-right: 1.75rem;
    padding-left: 1.75rem;
  }
  .px-xxl-4 {
    padding-right: 2rem;
    padding-left: 2rem;
  }
  .px-xxl-5 {
    padding-right: 2.5rem;
    padding-left: 2.5rem;
  }
  .px-xxl-5-5 {
    padding-right: 2.75rem;
    padding-left: 2.75rem;
  }
  .px-xxl-6 {
    padding-right: 3rem;
    padding-left: 3rem;
  }
  .px-xxl-7 {
    padding-right: 3.5rem;
    padding-left: 3.5rem;
  }
  .px-xxl-7-5 {
    padding-right: 3.75rem;
    padding-left: 3.75rem;
  }
  .px-xxl-8 {
    padding-right: 4rem;
    padding-left: 4rem;
  }
  .px-xxl-9 {
    padding-right: 4.5rem;
    padding-left: 4.5rem;
  }
  .px-xxl-9-5 {
    padding-right: 4.75rem;
    padding-left: 4.75rem;
  }
  .px-xxl-10 {
    padding-right: 5rem;
    padding-left: 5rem;
  }
  .px-xxl-auto {
    padding-right: "auto";
    padding-left: "auto";
  }
  .py-xxl-0 {
    padding-top: 0;
    padding-bottom: 0;
  }
  .py-xxl-0-5 {
    padding-top: 0.25rem;
    padding-bottom: 0.25rem;
  }
  .py-xxl-1 {
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
  }
  .py-xxl-1-5 {
    padding-top: 0.75rem;
    padding-bottom: 0.75rem;
  }
  .py-xxl-2 {
    padding-top: 1rem;
    padding-bottom: 1rem;
  }
  .py-xxl-2-5 {
    padding-top: 1.25rem;
    padding-bottom: 1.25rem;
  }
  .py-xxl-3 {
    padding-top: 1.5rem;
    padding-bottom: 1.5rem;
  }
  .py-xxl-3-5 {
    padding-top: 1.75rem;
    padding-bottom: 1.75rem;
  }
  .py-xxl-4 {
    padding-top: 2rem;
    padding-bottom: 2rem;
  }
  .py-xxl-5 {
    padding-top: 2.5rem;
    padding-bottom: 2.5rem;
  }
  .py-xxl-5-5 {
    padding-top: 2.75rem;
    padding-bottom: 2.75rem;
  }
  .py-xxl-6 {
    padding-top: 3rem;
    padding-bottom: 3rem;
  }
  .py-xxl-7 {
    padding-top: 3.5rem;
    padding-bottom: 3.5rem;
  }
  .py-xxl-7-5 {
    padding-top: 3.75rem;
    padding-bottom: 3.75rem;
  }
  .py-xxl-8 {
    padding-top: 4rem;
    padding-bottom: 4rem;
  }
  .py-xxl-9 {
    padding-top: 4.5rem;
    padding-bottom: 4.5rem;
  }
  .py-xxl-9-5 {
    padding-top: 4.75rem;
    padding-bottom: 4.75rem;
  }
  .py-xxl-10 {
    padding-top: 5rem;
    padding-bottom: 5rem;
  }
  .py-xxl-auto {
    padding-top: "auto";
    padding-bottom: "auto";
  }
  .pt-xxl-0 {
    padding-top: 0;
  }
  .pt-xxl-0-5 {
    padding-top: 0.25rem;
  }
  .pt-xxl-1 {
    padding-top: 0.5rem;
  }
  .pt-xxl-1-5 {
    padding-top: 0.75rem;
  }
  .pt-xxl-2 {
    padding-top: 1rem;
  }
  .pt-xxl-2-5 {
    padding-top: 1.25rem;
  }
  .pt-xxl-3 {
    padding-top: 1.5rem;
  }
  .pt-xxl-3-5 {
    padding-top: 1.75rem;
  }
  .pt-xxl-4 {
    padding-top: 2rem;
  }
  .pt-xxl-5 {
    padding-top: 2.5rem;
  }
  .pt-xxl-5-5 {
    padding-top: 2.75rem;
  }
  .pt-xxl-6 {
    padding-top: 3rem;
  }
  .pt-xxl-7 {
    padding-top: 3.5rem;
  }
  .pt-xxl-7-5 {
    padding-top: 3.75rem;
  }
  .pt-xxl-8 {
    padding-top: 4rem;
  }
  .pt-xxl-9 {
    padding-top: 4.5rem;
  }
  .pt-xxl-9-5 {
    padding-top: 4.75rem;
  }
  .pt-xxl-10 {
    padding-top: 5rem;
  }
  .pt-xxl-auto {
    padding-top: "auto";
  }
  .pe-xxl-0 {
    padding-right: 0;
  }
  .pe-xxl-0-5 {
    padding-right: 0.25rem;
  }
  .pe-xxl-1 {
    padding-right: 0.5rem;
  }
  .pe-xxl-1-5 {
    padding-right: 0.75rem;
  }
  .pe-xxl-2 {
    padding-right: 1rem;
  }
  .pe-xxl-2-5 {
    padding-right: 1.25rem;
  }
  .pe-xxl-3 {
    padding-right: 1.5rem;
  }
  .pe-xxl-3-5 {
    padding-right: 1.75rem;
  }
  .pe-xxl-4 {
    padding-right: 2rem;
  }
  .pe-xxl-5 {
    padding-right: 2.5rem;
  }
  .pe-xxl-5-5 {
    padding-right: 2.75rem;
  }
  .pe-xxl-6 {
    padding-right: 3rem;
  }
  .pe-xxl-7 {
    padding-right: 3.5rem;
  }
  .pe-xxl-7-5 {
    padding-right: 3.75rem;
  }
  .pe-xxl-8 {
    padding-right: 4rem;
  }
  .pe-xxl-9 {
    padding-right: 4.5rem;
  }
  .pe-xxl-9-5 {
    padding-right: 4.75rem;
  }
  .pe-xxl-10 {
    padding-right: 5rem;
  }
  .pe-xxl-auto {
    padding-right: "auto";
  }
  .pb-xxl-0 {
    padding-bottom: 0;
  }
  .pb-xxl-0-5 {
    padding-bottom: 0.25rem;
  }
  .pb-xxl-1 {
    padding-bottom: 0.5rem;
  }
  .pb-xxl-1-5 {
    padding-bottom: 0.75rem;
  }
  .pb-xxl-2 {
    padding-bottom: 1rem;
  }
  .pb-xxl-2-5 {
    padding-bottom: 1.25rem;
  }
  .pb-xxl-3 {
    padding-bottom: 1.5rem;
  }
  .pb-xxl-3-5 {
    padding-bottom: 1.75rem;
  }
  .pb-xxl-4 {
    padding-bottom: 2rem;
  }
  .pb-xxl-5 {
    padding-bottom: 2.5rem;
  }
  .pb-xxl-5-5 {
    padding-bottom: 2.75rem;
  }
  .pb-xxl-6 {
    padding-bottom: 3rem;
  }
  .pb-xxl-7 {
    padding-bottom: 3.5rem;
  }
  .pb-xxl-7-5 {
    padding-bottom: 3.75rem;
  }
  .pb-xxl-8 {
    padding-bottom: 4rem;
  }
  .pb-xxl-9 {
    padding-bottom: 4.5rem;
  }
  .pb-xxl-9-5 {
    padding-bottom: 4.75rem;
  }
  .pb-xxl-10 {
    padding-bottom: 5rem;
  }
  .pb-xxl-auto {
    padding-bottom: "auto";
  }
  .ps-xxl-0 {
    padding-left: 0;
  }
  .ps-xxl-0-5 {
    padding-left: 0.25rem;
  }
  .ps-xxl-1 {
    padding-left: 0.5rem;
  }
  .ps-xxl-1-5 {
    padding-left: 0.75rem;
  }
  .ps-xxl-2 {
    padding-left: 1rem;
  }
  .ps-xxl-2-5 {
    padding-left: 1.25rem;
  }
  .ps-xxl-3 {
    padding-left: 1.5rem;
  }
  .ps-xxl-3-5 {
    padding-left: 1.75rem;
  }
  .ps-xxl-4 {
    padding-left: 2rem;
  }
  .ps-xxl-5 {
    padding-left: 2.5rem;
  }
  .ps-xxl-5-5 {
    padding-left: 2.75rem;
  }
  .ps-xxl-6 {
    padding-left: 3rem;
  }
  .ps-xxl-7 {
    padding-left: 3.5rem;
  }
  .ps-xxl-7-5 {
    padding-left: 3.75rem;
  }
  .ps-xxl-8 {
    padding-left: 4rem;
  }
  .ps-xxl-9 {
    padding-left: 4.5rem;
  }
  .ps-xxl-9-5 {
    padding-left: 4.75rem;
  }
  .ps-xxl-10 {
    padding-left: 5rem;
  }
  .ps-xxl-auto {
    padding-left: "auto";
  }
  .gap-xxl-0 {
    gap: 0;
  }
  .gap-xxl-0-5 {
    gap: 0.25rem;
  }
  .gap-xxl-1 {
    gap: 0.5rem;
  }
  .gap-xxl-1-5 {
    gap: 0.75rem;
  }
  .gap-xxl-2 {
    gap: 1rem;
  }
  .gap-xxl-2-5 {
    gap: 1.25rem;
  }
  .gap-xxl-3 {
    gap: 1.5rem;
  }
  .gap-xxl-3-5 {
    gap: 1.75rem;
  }
  .gap-xxl-4 {
    gap: 2rem;
  }
  .gap-xxl-5 {
    gap: 2.5rem;
  }
  .gap-xxl-5-5 {
    gap: 2.75rem;
  }
  .gap-xxl-6 {
    gap: 3rem;
  }
  .gap-xxl-7 {
    gap: 3.5rem;
  }
  .gap-xxl-7-5 {
    gap: 3.75rem;
  }
  .gap-xxl-8 {
    gap: 4rem;
  }
  .gap-xxl-9 {
    gap: 4.5rem;
  }
  .gap-xxl-9-5 {
    gap: 4.75rem;
  }
  .gap-xxl-10 {
    gap: 5rem;
  }
  .gap-xxl-auto {
    gap: "auto";
  }
  .text-xxl-start {
    text-align: left;
  }
  .text-xxl-end {
    text-align: right;
  }
  .text-xxl-center {
    text-align: center;
  }
}
.header-h1-thin {
  font-size: 4.75rem;
  font-weight: 100;
  line-height: 5rem;
}

.header-h1-thin-italic {
  font-size: 4.75rem;
  font-style: italic;
  font-weight: 100;
  line-height: 5rem;
}

.header-h1-extra-light {
  font-size: 4.75rem;
  font-weight: 200;
  line-height: 5rem;
}

.header-h1-extra-light-italic {
  font-size: 4.75rem;
  font-style: italic;
  font-weight: 200;
  line-height: 5rem;
}

.header-h1-light {
  font-size: 4.75rem;
  font-weight: 300;
  line-height: 5rem;
}

.header-h1-light-italic {
  font-size: 4.75rem;
  font-style: italic;
  font-weight: 300;
  line-height: 5rem;
}

.header-h1-regular {
  font-size: 4.75rem;
  font-weight: 400;
  line-height: 5rem;
}

.header-h1-regular-italic {
  font-size: 4.75rem;
  font-style: italic;
  font-weight: 400;
  line-height: 5rem;
}

.header-h1-medium {
  font-size: 4.75rem;
  font-weight: 500;
  line-height: 5rem;
}

.header-h1-medium-italic {
  font-size: 4.75rem;
  font-style: italic;
  font-weight: 500;
  line-height: 5rem;
}

.header-h1-semibold {
  font-size: 4.75rem;
  font-weight: 600;
  line-height: 5rem;
}

.header-h1-semibold-italic {
  font-size: 4.75rem;
  font-style: italic;
  font-weight: 600;
  line-height: 5rem;
}

.header-h1-bold {
  font-size: 4.75rem;
  font-weight: 700;
  line-height: 5rem;
}

.header-h1-bold-italic {
  font-size: 4.75rem;
  font-style: italic;
  font-weight: 700;
  line-height: 5rem;
}

.header-h1 {
  font-size: 4.75rem;
  font-weight: 700;
  line-height: 5rem;
}

.header-h1-italic {
  font-size: 4.75rem;
  font-style: italic;
  font-weight: 700;
  line-height: 5rem;
}

.header-h1-extra-bold {
  font-size: 4.75rem;
  font-weight: 800;
  line-height: 5rem;
}

.header-h1-extra-bold-italic {
  font-size: 4.75rem;
  font-style: italic;
  font-weight: 800;
  line-height: 5rem;
}

.header-h1-black {
  font-size: 4.75rem;
  font-weight: 900;
  line-height: 5rem;
}

.header-h1-black-italic {
  font-size: 4.75rem;
  font-style: italic;
  font-weight: 900;
  line-height: 5rem;
}

.header-h2-thin {
  font-size: 3.813rem;
  font-weight: 100;
  line-height: 4.188rem;
}

.header-h2-thin-italic {
  font-size: 3.813rem;
  font-style: italic;
  font-weight: 100;
  line-height: 4.188rem;
}

.header-h2-extra-light {
  font-size: 3.813rem;
  font-weight: 200;
  line-height: 4.188rem;
}

.header-h2-extra-light-italic {
  font-size: 3.813rem;
  font-style: italic;
  font-weight: 200;
  line-height: 4.188rem;
}

.header-h2-light {
  font-size: 3.813rem;
  font-weight: 300;
  line-height: 4.188rem;
}

.header-h2-light-italic {
  font-size: 3.813rem;
  font-style: italic;
  font-weight: 300;
  line-height: 4.188rem;
}

.header-h2-regular {
  font-size: 3.813rem;
  font-weight: 400;
  line-height: 4.188rem;
}

.header-h2-regular-italic {
  font-size: 3.813rem;
  font-style: italic;
  font-weight: 400;
  line-height: 4.188rem;
}

.header-h2-medium {
  font-size: 3.813rem;
  font-weight: 500;
  line-height: 4.188rem;
}

.header-h2-medium-italic {
  font-size: 3.813rem;
  font-style: italic;
  font-weight: 500;
  line-height: 4.188rem;
}

.header-h2-semibold {
  font-size: 3.813rem;
  font-weight: 600;
  line-height: 4.188rem;
}

.header-h2-semibold-italic {
  font-size: 3.813rem;
  font-style: italic;
  font-weight: 600;
  line-height: 4.188rem;
}

.header-h2-bold {
  font-size: 3.813rem;
  font-weight: 700;
  line-height: 4.188rem;
}

.header-h2-bold-italic {
  font-size: 3.813rem;
  font-style: italic;
  font-weight: 700;
  line-height: 4.188rem;
}

.header-h2 {
  font-size: 3.813rem;
  font-weight: 700;
  line-height: 4.188rem;
}

.header-h2-italic {
  font-size: 3.813rem;
  font-style: italic;
  font-weight: 700;
  line-height: 4.188rem;
}

.header-h2-extra-bold {
  font-size: 3.813rem;
  font-weight: 800;
  line-height: 4.188rem;
}

.header-h2-extra-bold-italic {
  font-size: 3.813rem;
  font-style: italic;
  font-weight: 800;
  line-height: 4.188rem;
}

.header-h2-black {
  font-size: 3.813rem;
  font-weight: 900;
  line-height: 4.188rem;
}

.header-h2-black-italic {
  font-size: 3.813rem;
  font-style: italic;
  font-weight: 900;
  line-height: 4.188rem;
}

.header-h3-thin {
  font-size: 3.063rem;
  font-weight: 100;
  line-height: 3.438rem;
}

.header-h3-thin-italic {
  font-size: 3.063rem;
  font-style: italic;
  font-weight: 100;
  line-height: 3.438rem;
}

.header-h3-extra-light {
  font-size: 3.063rem;
  font-weight: 200;
  line-height: 3.438rem;
}

.header-h3-extra-light-italic {
  font-size: 3.063rem;
  font-style: italic;
  font-weight: 200;
  line-height: 3.438rem;
}

.header-h3-light {
  font-size: 3.063rem;
  font-weight: 300;
  line-height: 3.438rem;
}

.header-h3-light-italic {
  font-size: 3.063rem;
  font-style: italic;
  font-weight: 300;
  line-height: 3.438rem;
}

.header-h3-regular {
  font-size: 3.063rem;
  font-weight: 400;
  line-height: 3.438rem;
}

.header-h3-regular-italic {
  font-size: 3.063rem;
  font-style: italic;
  font-weight: 400;
  line-height: 3.438rem;
}

.header-h3-medium {
  font-size: 3.063rem;
  font-weight: 500;
  line-height: 3.438rem;
}

.header-h3-medium-italic {
  font-size: 3.063rem;
  font-style: italic;
  font-weight: 500;
  line-height: 3.438rem;
}

.header-h3-semibold {
  font-size: 3.063rem;
  font-weight: 600;
  line-height: 3.438rem;
}

.header-h3-semibold-italic {
  font-size: 3.063rem;
  font-style: italic;
  font-weight: 600;
  line-height: 3.438rem;
}

.header-h3-bold {
  font-size: 3.063rem;
  font-weight: 700;
  line-height: 3.438rem;
}

.header-h3-bold-italic {
  font-size: 3.063rem;
  font-style: italic;
  font-weight: 700;
  line-height: 3.438rem;
}

.header-h3 {
  font-size: 3.063rem;
  font-weight: 700;
  line-height: 3.438rem;
}

.header-h3-italic {
  font-size: 3.063rem;
  font-style: italic;
  font-weight: 700;
  line-height: 3.438rem;
}

.header-h3-extra-bold {
  font-size: 3.063rem;
  font-weight: 800;
  line-height: 3.438rem;
}

.header-h3-extra-bold-italic {
  font-size: 3.063rem;
  font-style: italic;
  font-weight: 800;
  line-height: 3.438rem;
}

.header-h3-black {
  font-size: 3.063rem;
  font-weight: 900;
  line-height: 3.438rem;
}

.header-h3-black-italic {
  font-size: 3.063rem;
  font-style: italic;
  font-weight: 900;
  line-height: 3.438rem;
}

.header-h4-thin {
  font-size: 2.438rem;
  font-weight: 100;
  line-height: 2.875rem;
}

.header-h4-thin-italic {
  font-size: 2.438rem;
  font-style: italic;
  font-weight: 100;
  line-height: 2.875rem;
}

.header-h4-extra-light {
  font-size: 2.438rem;
  font-weight: 200;
  line-height: 2.875rem;
}

.header-h4-extra-light-italic {
  font-size: 2.438rem;
  font-style: italic;
  font-weight: 200;
  line-height: 2.875rem;
}

.header-h4-light {
  font-size: 2.438rem;
  font-weight: 300;
  line-height: 2.875rem;
}

.header-h4-light-italic {
  font-size: 2.438rem;
  font-style: italic;
  font-weight: 300;
  line-height: 2.875rem;
}

.header-h4-regular {
  font-size: 2.438rem;
  font-weight: 400;
  line-height: 2.875rem;
}

.header-h4-regular-italic {
  font-size: 2.438rem;
  font-style: italic;
  font-weight: 400;
  line-height: 2.875rem;
}

.header-h4-medium {
  font-size: 2.438rem;
  font-weight: 500;
  line-height: 2.875rem;
}

.header-h4-medium-italic {
  font-size: 2.438rem;
  font-style: italic;
  font-weight: 500;
  line-height: 2.875rem;
}

.header-h4-semibold {
  font-size: 2.438rem;
  font-weight: 600;
  line-height: 2.875rem;
}

.header-h4-semibold-italic {
  font-size: 2.438rem;
  font-style: italic;
  font-weight: 600;
  line-height: 2.875rem;
}

.header-h4-bold {
  font-size: 2.438rem;
  font-weight: 700;
  line-height: 2.875rem;
}

.header-h4-bold-italic {
  font-size: 2.438rem;
  font-style: italic;
  font-weight: 700;
  line-height: 2.875rem;
}

.header-h4 {
  font-size: 2.438rem;
  font-weight: 700;
  line-height: 2.875rem;
}

.header-h4-italic {
  font-size: 2.438rem;
  font-style: italic;
  font-weight: 700;
  line-height: 2.875rem;
}

.header-h4-extra-bold {
  font-size: 2.438rem;
  font-weight: 800;
  line-height: 2.875rem;
}

.header-h4-extra-bold-italic {
  font-size: 2.438rem;
  font-style: italic;
  font-weight: 800;
  line-height: 2.875rem;
}

.header-h4-black {
  font-size: 2.438rem;
  font-weight: 900;
  line-height: 2.875rem;
}

.header-h4-black-italic {
  font-size: 2.438rem;
  font-style: italic;
  font-weight: 900;
  line-height: 2.875rem;
}

.header-h5-thin {
  font-size: 1.938rem;
  font-weight: 100;
  line-height: 2rem;
}

.header-h5-thin-italic {
  font-size: 1.938rem;
  font-style: italic;
  font-weight: 100;
  line-height: 2rem;
}

.header-h5-extra-light {
  font-size: 1.938rem;
  font-weight: 200;
  line-height: 2rem;
}

.header-h5-extra-light-italic {
  font-size: 1.938rem;
  font-style: italic;
  font-weight: 200;
  line-height: 2rem;
}

.header-h5-light {
  font-size: 1.938rem;
  font-weight: 300;
  line-height: 2rem;
}

.header-h5-light-italic {
  font-size: 1.938rem;
  font-style: italic;
  font-weight: 300;
  line-height: 2rem;
}

.header-h5-regular {
  font-size: 1.938rem;
  font-weight: 400;
  line-height: 2rem;
}

.header-h5-regular-italic {
  font-size: 1.938rem;
  font-style: italic;
  font-weight: 400;
  line-height: 2rem;
}

.header-h5-medium {
  font-size: 1.938rem;
  font-weight: 500;
  line-height: 2rem;
}

.header-h5-medium-italic {
  font-size: 1.938rem;
  font-style: italic;
  font-weight: 500;
  line-height: 2rem;
}

.header-h5-semibold {
  font-size: 1.938rem;
  font-weight: 600;
  line-height: 2rem;
}

.header-h5-semibold-italic {
  font-size: 1.938rem;
  font-style: italic;
  font-weight: 600;
  line-height: 2rem;
}

.header-h5-bold {
  font-size: 1.938rem;
  font-weight: 700;
  line-height: 2rem;
}

.header-h5-bold-italic {
  font-size: 1.938rem;
  font-style: italic;
  font-weight: 700;
  line-height: 2rem;
}

.header-h5 {
  font-size: 1.938rem;
  font-weight: 700;
  line-height: 2rem;
}

.header-h5-italic {
  font-size: 1.938rem;
  font-style: italic;
  font-weight: 700;
  line-height: 2rem;
}

.header-h5-extra-bold {
  font-size: 1.938rem;
  font-weight: 800;
  line-height: 2rem;
}

.header-h5-extra-bold-italic {
  font-size: 1.938rem;
  font-style: italic;
  font-weight: 800;
  line-height: 2rem;
}

.header-h5-black {
  font-size: 1.938rem;
  font-weight: 900;
  line-height: 2rem;
}

.header-h5-black-italic {
  font-size: 1.938rem;
  font-style: italic;
  font-weight: 900;
  line-height: 2rem;
}

.header-h6-thin {
  font-size: 1.563rem;
  font-weight: 100;
  line-height: 2rem;
}

.header-h6-thin-italic {
  font-size: 1.563rem;
  font-style: italic;
  font-weight: 100;
  line-height: 2rem;
}

.header-h6-extra-light {
  font-size: 1.563rem;
  font-weight: 200;
  line-height: 2rem;
}

.header-h6-extra-light-italic {
  font-size: 1.563rem;
  font-style: italic;
  font-weight: 200;
  line-height: 2rem;
}

.header-h6-light {
  font-size: 1.563rem;
  font-weight: 300;
  line-height: 2rem;
}

.header-h6-light-italic {
  font-size: 1.563rem;
  font-style: italic;
  font-weight: 300;
  line-height: 2rem;
}

.header-h6-regular {
  font-size: 1.563rem;
  font-weight: 400;
  line-height: 2rem;
}

.header-h6-regular-italic {
  font-size: 1.563rem;
  font-style: italic;
  font-weight: 400;
  line-height: 2rem;
}

.header-h6-medium {
  font-size: 1.563rem;
  font-weight: 500;
  line-height: 2rem;
}

.header-h6-medium-italic {
  font-size: 1.563rem;
  font-style: italic;
  font-weight: 500;
  line-height: 2rem;
}

.header-h6-semibold {
  font-size: 1.563rem;
  font-weight: 600;
  line-height: 2rem;
}

.header-h6-semibold-italic {
  font-size: 1.563rem;
  font-style: italic;
  font-weight: 600;
  line-height: 2rem;
}

.header-h6-bold {
  font-size: 1.563rem;
  font-weight: 700;
  line-height: 2rem;
}

.header-h6-bold-italic {
  font-size: 1.563rem;
  font-style: italic;
  font-weight: 700;
  line-height: 2rem;
}

.header-h6, .layout-title {
  font-size: 1.563rem;
  font-weight: 700;
  line-height: 2rem;
}

.header-h6-italic {
  font-size: 1.563rem;
  font-style: italic;
  font-weight: 700;
  line-height: 2rem;
}

.header-h6-extra-bold {
  font-size: 1.563rem;
  font-weight: 800;
  line-height: 2rem;
}

.header-h6-extra-bold-italic {
  font-size: 1.563rem;
  font-style: italic;
  font-weight: 800;
  line-height: 2rem;
}

.header-h6-black {
  font-size: 1.563rem;
  font-weight: 900;
  line-height: 2rem;
}

.header-h6-black-italic {
  font-size: 1.563rem;
  font-style: italic;
  font-weight: 900;
  line-height: 2rem;
}

.body-large-thin {
  font-size: 1.25rem;
  font-weight: 100;
  line-height: 1.75rem;
}

.body-large-thin-italic {
  font-size: 1.25rem;
  font-style: italic;
  font-weight: 100;
  line-height: 1.75rem;
}

.body-large-extra-light {
  font-size: 1.25rem;
  font-weight: 200;
  line-height: 1.75rem;
}

.body-large-extra-light-italic {
  font-size: 1.25rem;
  font-style: italic;
  font-weight: 200;
  line-height: 1.75rem;
}

.body-large-light {
  font-size: 1.25rem;
  font-weight: 300;
  line-height: 1.75rem;
}

.body-large-light-italic {
  font-size: 1.25rem;
  font-style: italic;
  font-weight: 300;
  line-height: 1.75rem;
}

.body-large-regular {
  font-size: 1.25rem;
  font-weight: 400;
  line-height: 1.75rem;
}

.body-large-regular-italic {
  font-size: 1.25rem;
  font-style: italic;
  font-weight: 400;
  line-height: 1.75rem;
}

.body-large {
  font-size: 1.25rem;
  font-weight: 400;
  line-height: 1.75rem;
}

.body-large-italic {
  font-size: 1.25rem;
  font-style: italic;
  font-weight: 400;
  line-height: 1.75rem;
}

.body-large-medium {
  font-size: 1.25rem;
  font-weight: 500;
  line-height: 1.75rem;
}

.body-large-medium-italic {
  font-size: 1.25rem;
  font-style: italic;
  font-weight: 500;
  line-height: 1.75rem;
}

.body-large-semibold {
  font-size: 1.25rem;
  font-weight: 600;
  line-height: 1.75rem;
}

.body-large-semibold-italic {
  font-size: 1.25rem;
  font-style: italic;
  font-weight: 600;
  line-height: 1.75rem;
}

.body-large-bold, .layout-text h2 {
  font-size: 1.25rem;
  font-weight: 700;
  line-height: 1.75rem;
}

.body-large-bold-italic {
  font-size: 1.25rem;
  font-style: italic;
  font-weight: 700;
  line-height: 1.75rem;
}

.body-large-extra-bold {
  font-size: 1.25rem;
  font-weight: 800;
  line-height: 1.75rem;
}

.body-large-extra-bold-italic {
  font-size: 1.25rem;
  font-style: italic;
  font-weight: 800;
  line-height: 1.75rem;
}

.body-large-black {
  font-size: 1.25rem;
  font-weight: 900;
  line-height: 1.75rem;
}

.body-large-black-italic {
  font-size: 1.25rem;
  font-style: italic;
  font-weight: 900;
  line-height: 1.75rem;
}

.body-normal-thin {
  font-size: 1rem;
  font-weight: 100;
  line-height: 1.5rem;
}

.body-normal-thin-italic {
  font-size: 1rem;
  font-style: italic;
  font-weight: 100;
  line-height: 1.5rem;
}

.body-normal-extra-light {
  font-size: 1rem;
  font-weight: 200;
  line-height: 1.5rem;
}

.body-normal-extra-light-italic {
  font-size: 1rem;
  font-style: italic;
  font-weight: 200;
  line-height: 1.5rem;
}

.body-normal-light {
  font-size: 1rem;
  font-weight: 300;
  line-height: 1.5rem;
}

.body-normal-light-italic {
  font-size: 1rem;
  font-style: italic;
  font-weight: 300;
  line-height: 1.5rem;
}

.body-normal-regular {
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5rem;
}

.body-normal-regular-italic {
  font-size: 1rem;
  font-style: italic;
  font-weight: 400;
  line-height: 1.5rem;
}

.body-normal {
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5rem;
}

.body-normal-italic {
  font-size: 1rem;
  font-style: italic;
  font-weight: 400;
  line-height: 1.5rem;
}

.body-normal-medium {
  font-size: 1rem;
  font-weight: 500;
  line-height: 1.5rem;
}

.body-normal-medium-italic {
  font-size: 1rem;
  font-style: italic;
  font-weight: 500;
  line-height: 1.5rem;
}

.body-normal-semibold {
  font-size: 1rem;
  font-weight: 600;
  line-height: 1.5rem;
}

.body-normal-semibold-italic {
  font-size: 1rem;
  font-style: italic;
  font-weight: 600;
  line-height: 1.5rem;
}

.body-normal-bold {
  font-size: 1rem;
  font-weight: 700;
  line-height: 1.5rem;
}

.body-normal-bold-italic {
  font-size: 1rem;
  font-style: italic;
  font-weight: 700;
  line-height: 1.5rem;
}

.body-normal-extra-bold {
  font-size: 1rem;
  font-weight: 800;
  line-height: 1.5rem;
}

.body-normal-extra-bold-italic {
  font-size: 1rem;
  font-style: italic;
  font-weight: 800;
  line-height: 1.5rem;
}

.body-normal-black {
  font-size: 1rem;
  font-weight: 900;
  line-height: 1.5rem;
}

.body-normal-black-italic {
  font-size: 1rem;
  font-style: italic;
  font-weight: 900;
  line-height: 1.5rem;
}

.body-small-thin {
  font-size: 0.875rem;
  font-weight: 100;
  line-height: 1.313rem;
}

.body-small-thin-italic {
  font-size: 0.875rem;
  font-style: italic;
  font-weight: 100;
  line-height: 1.313rem;
}

.body-small-extra-light {
  font-size: 0.875rem;
  font-weight: 200;
  line-height: 1.313rem;
}

.body-small-extra-light-italic {
  font-size: 0.875rem;
  font-style: italic;
  font-weight: 200;
  line-height: 1.313rem;
}

.body-small-light {
  font-size: 0.875rem;
  font-weight: 300;
  line-height: 1.313rem;
}

.body-small-light-italic {
  font-size: 0.875rem;
  font-style: italic;
  font-weight: 300;
  line-height: 1.313rem;
}

.body-small-regular {
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 1.313rem;
}

.body-small-regular-italic {
  font-size: 0.875rem;
  font-style: italic;
  font-weight: 400;
  line-height: 1.313rem;
}

.body-small {
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 1.313rem;
}

.body-small-italic {
  font-size: 0.875rem;
  font-style: italic;
  font-weight: 400;
  line-height: 1.313rem;
}

.body-small-medium {
  font-size: 0.875rem;
  font-weight: 500;
  line-height: 1.313rem;
}

.body-small-medium-italic {
  font-size: 0.875rem;
  font-style: italic;
  font-weight: 500;
  line-height: 1.313rem;
}

.body-small-semibold {
  font-size: 0.875rem;
  font-weight: 600;
  line-height: 1.313rem;
}

.body-small-semibold-italic {
  font-size: 0.875rem;
  font-style: italic;
  font-weight: 600;
  line-height: 1.313rem;
}

.body-small-bold {
  font-size: 0.875rem;
  font-weight: 700;
  line-height: 1.313rem;
}

.body-small-bold-italic {
  font-size: 0.875rem;
  font-style: italic;
  font-weight: 700;
  line-height: 1.313rem;
}

.body-small-extra-bold {
  font-size: 0.875rem;
  font-weight: 800;
  line-height: 1.313rem;
}

.body-small-extra-bold-italic {
  font-size: 0.875rem;
  font-style: italic;
  font-weight: 800;
  line-height: 1.313rem;
}

.body-small-black {
  font-size: 0.875rem;
  font-weight: 900;
  line-height: 1.313rem;
}

.body-small-black-italic {
  font-size: 0.875rem;
  font-style: italic;
  font-weight: 900;
  line-height: 1.313rem;
}

.body-tiny-thin {
  font-size: 0.75rem;
  font-weight: 100;
  line-height: 1rem;
}

.body-tiny-thin-italic {
  font-size: 0.75rem;
  font-style: italic;
  font-weight: 100;
  line-height: 1rem;
}

.body-tiny-extra-light {
  font-size: 0.75rem;
  font-weight: 200;
  line-height: 1rem;
}

.body-tiny-extra-light-italic {
  font-size: 0.75rem;
  font-style: italic;
  font-weight: 200;
  line-height: 1rem;
}

.body-tiny-light {
  font-size: 0.75rem;
  font-weight: 300;
  line-height: 1rem;
}

.body-tiny-light-italic {
  font-size: 0.75rem;
  font-style: italic;
  font-weight: 300;
  line-height: 1rem;
}

.body-tiny-regular {
  font-size: 0.75rem;
  font-weight: 400;
  line-height: 1rem;
}

.body-tiny-regular-italic {
  font-size: 0.75rem;
  font-style: italic;
  font-weight: 400;
  line-height: 1rem;
}

.body-tiny {
  font-size: 0.75rem;
  font-weight: 400;
  line-height: 1rem;
}

.body-tiny-italic {
  font-size: 0.75rem;
  font-style: italic;
  font-weight: 400;
  line-height: 1rem;
}

.body-tiny-medium {
  font-size: 0.75rem;
  font-weight: 500;
  line-height: 1rem;
}

.body-tiny-medium-italic {
  font-size: 0.75rem;
  font-style: italic;
  font-weight: 500;
  line-height: 1rem;
}

.body-tiny-semibold {
  font-size: 0.75rem;
  font-weight: 600;
  line-height: 1rem;
}

.body-tiny-semibold-italic {
  font-size: 0.75rem;
  font-style: italic;
  font-weight: 600;
  line-height: 1rem;
}

.body-tiny-bold {
  font-size: 0.75rem;
  font-weight: 700;
  line-height: 1rem;
}

.body-tiny-bold-italic {
  font-size: 0.75rem;
  font-style: italic;
  font-weight: 700;
  line-height: 1rem;
}

.body-tiny-extra-bold {
  font-size: 0.75rem;
  font-weight: 800;
  line-height: 1rem;
}

.body-tiny-extra-bold-italic {
  font-size: 0.75rem;
  font-style: italic;
  font-weight: 800;
  line-height: 1rem;
}

.body-tiny-black {
  font-size: 0.75rem;
  font-weight: 900;
  line-height: 1rem;
}

.body-tiny-black-italic {
  font-size: 0.75rem;
  font-style: italic;
  font-weight: 900;
  line-height: 1rem;
}

.body-xs-thin {
  font-size: 0.625rem;
  font-weight: 100;
  line-height: 1.313rem;
}

.body-xs-thin-italic {
  font-size: 0.625rem;
  font-style: italic;
  font-weight: 100;
  line-height: 1.313rem;
}

.body-xs-extra-light {
  font-size: 0.625rem;
  font-weight: 200;
  line-height: 1.313rem;
}

.body-xs-extra-light-italic {
  font-size: 0.625rem;
  font-style: italic;
  font-weight: 200;
  line-height: 1.313rem;
}

.body-xs-light {
  font-size: 0.625rem;
  font-weight: 300;
  line-height: 1.313rem;
}

.body-xs-light-italic {
  font-size: 0.625rem;
  font-style: italic;
  font-weight: 300;
  line-height: 1.313rem;
}

.body-xs-regular {
  font-size: 0.625rem;
  font-weight: 400;
  line-height: 1.313rem;
}

.body-xs-regular-italic {
  font-size: 0.625rem;
  font-style: italic;
  font-weight: 400;
  line-height: 1.313rem;
}

.body-xs {
  font-size: 0.625rem;
  font-weight: 400;
  line-height: 1.313rem;
}

.body-xs-italic {
  font-size: 0.625rem;
  font-style: italic;
  font-weight: 400;
  line-height: 1.313rem;
}

.body-xs-medium {
  font-size: 0.625rem;
  font-weight: 500;
  line-height: 1.313rem;
}

.body-xs-medium-italic {
  font-size: 0.625rem;
  font-style: italic;
  font-weight: 500;
  line-height: 1.313rem;
}

.body-xs-semibold {
  font-size: 0.625rem;
  font-weight: 600;
  line-height: 1.313rem;
}

.body-xs-semibold-italic {
  font-size: 0.625rem;
  font-style: italic;
  font-weight: 600;
  line-height: 1.313rem;
}

.body-xs-bold {
  font-size: 0.625rem;
  font-weight: 700;
  line-height: 1.313rem;
}

.body-xs-bold-italic {
  font-size: 0.625rem;
  font-style: italic;
  font-weight: 700;
  line-height: 1.313rem;
}

.body-xs-extra-bold {
  font-size: 0.625rem;
  font-weight: 800;
  line-height: 1.313rem;
}

.body-xs-extra-bold-italic {
  font-size: 0.625rem;
  font-style: italic;
  font-weight: 800;
  line-height: 1.313rem;
}

.body-xs-black {
  font-size: 0.625rem;
  font-weight: 900;
  line-height: 1.313rem;
}

.body-xs-black-italic {
  font-size: 0.625rem;
  font-style: italic;
  font-weight: 900;
  line-height: 1.313rem;
}

@media (min-width: 576px) {
  .header-h1-thin-sm {
    font-size: 4.75rem;
    font-weight: 100;
    line-height: 5rem;
  }
}

@media (min-width: 576px) {
  .header-h1-thin-italic-sm {
    font-size: 4.75rem;
    font-style: italic;
    font-weight: 100;
    line-height: 5rem;
  }
}

@media (min-width: 576px) {
  .header-h1-extra-light-sm {
    font-size: 4.75rem;
    font-weight: 200;
    line-height: 5rem;
  }
}

@media (min-width: 576px) {
  .header-h1-extra-light-italic-sm {
    font-size: 4.75rem;
    font-style: italic;
    font-weight: 200;
    line-height: 5rem;
  }
}

@media (min-width: 576px) {
  .header-h1-light-sm {
    font-size: 4.75rem;
    font-weight: 300;
    line-height: 5rem;
  }
}

@media (min-width: 576px) {
  .header-h1-light-italic-sm {
    font-size: 4.75rem;
    font-style: italic;
    font-weight: 300;
    line-height: 5rem;
  }
}

@media (min-width: 576px) {
  .header-h1-regular-sm {
    font-size: 4.75rem;
    font-weight: 400;
    line-height: 5rem;
  }
}

@media (min-width: 576px) {
  .header-h1-regular-italic-sm {
    font-size: 4.75rem;
    font-style: italic;
    font-weight: 400;
    line-height: 5rem;
  }
}

@media (min-width: 576px) {
  .header-h1-medium-sm {
    font-size: 4.75rem;
    font-weight: 500;
    line-height: 5rem;
  }
}

@media (min-width: 576px) {
  .header-h1-medium-italic-sm {
    font-size: 4.75rem;
    font-style: italic;
    font-weight: 500;
    line-height: 5rem;
  }
}

@media (min-width: 576px) {
  .header-h1-semibold-sm {
    font-size: 4.75rem;
    font-weight: 600;
    line-height: 5rem;
  }
}

@media (min-width: 576px) {
  .header-h1-semibold-italic-sm {
    font-size: 4.75rem;
    font-style: italic;
    font-weight: 600;
    line-height: 5rem;
  }
}

@media (min-width: 576px) {
  .header-h1-bold-sm {
    font-size: 4.75rem;
    font-weight: 700;
    line-height: 5rem;
  }
}

@media (min-width: 576px) {
  .header-h1-bold-italic-sm {
    font-size: 4.75rem;
    font-style: italic;
    font-weight: 700;
    line-height: 5rem;
  }
}

@media (min-width: 576px) {
  .header-h1-sm {
    font-size: 4.75rem;
    font-weight: 700;
    line-height: 5rem;
  }
}

@media (min-width: 576px) {
  .header-h1-italic-sm {
    font-size: 4.75rem;
    font-style: italic;
    font-weight: 700;
    line-height: 5rem;
  }
}

@media (min-width: 576px) {
  .header-h1-extra-bold-sm {
    font-size: 4.75rem;
    font-weight: 800;
    line-height: 5rem;
  }
}

@media (min-width: 576px) {
  .header-h1-extra-bold-italic-sm {
    font-size: 4.75rem;
    font-style: italic;
    font-weight: 800;
    line-height: 5rem;
  }
}

@media (min-width: 576px) {
  .header-h1-black-sm {
    font-size: 4.75rem;
    font-weight: 900;
    line-height: 5rem;
  }
}

@media (min-width: 576px) {
  .header-h1-black-italic-sm {
    font-size: 4.75rem;
    font-style: italic;
    font-weight: 900;
    line-height: 5rem;
  }
}

@media (min-width: 576px) {
  .header-h2-thin-sm {
    font-size: 3.813rem;
    font-weight: 100;
    line-height: 4.188rem;
  }
}

@media (min-width: 576px) {
  .header-h2-thin-italic-sm {
    font-size: 3.813rem;
    font-style: italic;
    font-weight: 100;
    line-height: 4.188rem;
  }
}

@media (min-width: 576px) {
  .header-h2-extra-light-sm {
    font-size: 3.813rem;
    font-weight: 200;
    line-height: 4.188rem;
  }
}

@media (min-width: 576px) {
  .header-h2-extra-light-italic-sm {
    font-size: 3.813rem;
    font-style: italic;
    font-weight: 200;
    line-height: 4.188rem;
  }
}

@media (min-width: 576px) {
  .header-h2-light-sm {
    font-size: 3.813rem;
    font-weight: 300;
    line-height: 4.188rem;
  }
}

@media (min-width: 576px) {
  .header-h2-light-italic-sm {
    font-size: 3.813rem;
    font-style: italic;
    font-weight: 300;
    line-height: 4.188rem;
  }
}

@media (min-width: 576px) {
  .header-h2-regular-sm {
    font-size: 3.813rem;
    font-weight: 400;
    line-height: 4.188rem;
  }
}

@media (min-width: 576px) {
  .header-h2-regular-italic-sm {
    font-size: 3.813rem;
    font-style: italic;
    font-weight: 400;
    line-height: 4.188rem;
  }
}

@media (min-width: 576px) {
  .header-h2-medium-sm {
    font-size: 3.813rem;
    font-weight: 500;
    line-height: 4.188rem;
  }
}

@media (min-width: 576px) {
  .header-h2-medium-italic-sm {
    font-size: 3.813rem;
    font-style: italic;
    font-weight: 500;
    line-height: 4.188rem;
  }
}

@media (min-width: 576px) {
  .header-h2-semibold-sm {
    font-size: 3.813rem;
    font-weight: 600;
    line-height: 4.188rem;
  }
}

@media (min-width: 576px) {
  .header-h2-semibold-italic-sm {
    font-size: 3.813rem;
    font-style: italic;
    font-weight: 600;
    line-height: 4.188rem;
  }
}

@media (min-width: 576px) {
  .header-h2-bold-sm {
    font-size: 3.813rem;
    font-weight: 700;
    line-height: 4.188rem;
  }
}

@media (min-width: 576px) {
  .header-h2-bold-italic-sm {
    font-size: 3.813rem;
    font-style: italic;
    font-weight: 700;
    line-height: 4.188rem;
  }
}

@media (min-width: 576px) {
  .header-h2-sm {
    font-size: 3.813rem;
    font-weight: 700;
    line-height: 4.188rem;
  }
}

@media (min-width: 576px) {
  .header-h2-italic-sm {
    font-size: 3.813rem;
    font-style: italic;
    font-weight: 700;
    line-height: 4.188rem;
  }
}

@media (min-width: 576px) {
  .header-h2-extra-bold-sm {
    font-size: 3.813rem;
    font-weight: 800;
    line-height: 4.188rem;
  }
}

@media (min-width: 576px) {
  .header-h2-extra-bold-italic-sm {
    font-size: 3.813rem;
    font-style: italic;
    font-weight: 800;
    line-height: 4.188rem;
  }
}

@media (min-width: 576px) {
  .header-h2-black-sm {
    font-size: 3.813rem;
    font-weight: 900;
    line-height: 4.188rem;
  }
}

@media (min-width: 576px) {
  .header-h2-black-italic-sm {
    font-size: 3.813rem;
    font-style: italic;
    font-weight: 900;
    line-height: 4.188rem;
  }
}

@media (min-width: 576px) {
  .header-h3-thin-sm {
    font-size: 3.063rem;
    font-weight: 100;
    line-height: 3.438rem;
  }
}

@media (min-width: 576px) {
  .header-h3-thin-italic-sm {
    font-size: 3.063rem;
    font-style: italic;
    font-weight: 100;
    line-height: 3.438rem;
  }
}

@media (min-width: 576px) {
  .header-h3-extra-light-sm {
    font-size: 3.063rem;
    font-weight: 200;
    line-height: 3.438rem;
  }
}

@media (min-width: 576px) {
  .header-h3-extra-light-italic-sm {
    font-size: 3.063rem;
    font-style: italic;
    font-weight: 200;
    line-height: 3.438rem;
  }
}

@media (min-width: 576px) {
  .header-h3-light-sm {
    font-size: 3.063rem;
    font-weight: 300;
    line-height: 3.438rem;
  }
}

@media (min-width: 576px) {
  .header-h3-light-italic-sm {
    font-size: 3.063rem;
    font-style: italic;
    font-weight: 300;
    line-height: 3.438rem;
  }
}

@media (min-width: 576px) {
  .header-h3-regular-sm {
    font-size: 3.063rem;
    font-weight: 400;
    line-height: 3.438rem;
  }
}

@media (min-width: 576px) {
  .header-h3-regular-italic-sm {
    font-size: 3.063rem;
    font-style: italic;
    font-weight: 400;
    line-height: 3.438rem;
  }
}

@media (min-width: 576px) {
  .header-h3-medium-sm {
    font-size: 3.063rem;
    font-weight: 500;
    line-height: 3.438rem;
  }
}

@media (min-width: 576px) {
  .header-h3-medium-italic-sm {
    font-size: 3.063rem;
    font-style: italic;
    font-weight: 500;
    line-height: 3.438rem;
  }
}

@media (min-width: 576px) {
  .header-h3-semibold-sm {
    font-size: 3.063rem;
    font-weight: 600;
    line-height: 3.438rem;
  }
}

@media (min-width: 576px) {
  .header-h3-semibold-italic-sm {
    font-size: 3.063rem;
    font-style: italic;
    font-weight: 600;
    line-height: 3.438rem;
  }
}

@media (min-width: 576px) {
  .header-h3-bold-sm {
    font-size: 3.063rem;
    font-weight: 700;
    line-height: 3.438rem;
  }
}

@media (min-width: 576px) {
  .header-h3-bold-italic-sm {
    font-size: 3.063rem;
    font-style: italic;
    font-weight: 700;
    line-height: 3.438rem;
  }
}

@media (min-width: 576px) {
  .header-h3-sm {
    font-size: 3.063rem;
    font-weight: 700;
    line-height: 3.438rem;
  }
}

@media (min-width: 576px) {
  .header-h3-italic-sm {
    font-size: 3.063rem;
    font-style: italic;
    font-weight: 700;
    line-height: 3.438rem;
  }
}

@media (min-width: 576px) {
  .header-h3-extra-bold-sm {
    font-size: 3.063rem;
    font-weight: 800;
    line-height: 3.438rem;
  }
}

@media (min-width: 576px) {
  .header-h3-extra-bold-italic-sm {
    font-size: 3.063rem;
    font-style: italic;
    font-weight: 800;
    line-height: 3.438rem;
  }
}

@media (min-width: 576px) {
  .header-h3-black-sm {
    font-size: 3.063rem;
    font-weight: 900;
    line-height: 3.438rem;
  }
}

@media (min-width: 576px) {
  .header-h3-black-italic-sm {
    font-size: 3.063rem;
    font-style: italic;
    font-weight: 900;
    line-height: 3.438rem;
  }
}

@media (min-width: 576px) {
  .header-h4-thin-sm {
    font-size: 2.438rem;
    font-weight: 100;
    line-height: 2.875rem;
  }
}

@media (min-width: 576px) {
  .header-h4-thin-italic-sm {
    font-size: 2.438rem;
    font-style: italic;
    font-weight: 100;
    line-height: 2.875rem;
  }
}

@media (min-width: 576px) {
  .header-h4-extra-light-sm {
    font-size: 2.438rem;
    font-weight: 200;
    line-height: 2.875rem;
  }
}

@media (min-width: 576px) {
  .header-h4-extra-light-italic-sm {
    font-size: 2.438rem;
    font-style: italic;
    font-weight: 200;
    line-height: 2.875rem;
  }
}

@media (min-width: 576px) {
  .header-h4-light-sm {
    font-size: 2.438rem;
    font-weight: 300;
    line-height: 2.875rem;
  }
}

@media (min-width: 576px) {
  .header-h4-light-italic-sm {
    font-size: 2.438rem;
    font-style: italic;
    font-weight: 300;
    line-height: 2.875rem;
  }
}

@media (min-width: 576px) {
  .header-h4-regular-sm {
    font-size: 2.438rem;
    font-weight: 400;
    line-height: 2.875rem;
  }
}

@media (min-width: 576px) {
  .header-h4-regular-italic-sm {
    font-size: 2.438rem;
    font-style: italic;
    font-weight: 400;
    line-height: 2.875rem;
  }
}

@media (min-width: 576px) {
  .header-h4-medium-sm {
    font-size: 2.438rem;
    font-weight: 500;
    line-height: 2.875rem;
  }
}

@media (min-width: 576px) {
  .header-h4-medium-italic-sm {
    font-size: 2.438rem;
    font-style: italic;
    font-weight: 500;
    line-height: 2.875rem;
  }
}

@media (min-width: 576px) {
  .header-h4-semibold-sm {
    font-size: 2.438rem;
    font-weight: 600;
    line-height: 2.875rem;
  }
}

@media (min-width: 576px) {
  .header-h4-semibold-italic-sm {
    font-size: 2.438rem;
    font-style: italic;
    font-weight: 600;
    line-height: 2.875rem;
  }
}

@media (min-width: 576px) {
  .header-h4-bold-sm {
    font-size: 2.438rem;
    font-weight: 700;
    line-height: 2.875rem;
  }
}

@media (min-width: 576px) {
  .header-h4-bold-italic-sm {
    font-size: 2.438rem;
    font-style: italic;
    font-weight: 700;
    line-height: 2.875rem;
  }
}

@media (min-width: 576px) {
  .header-h4-sm {
    font-size: 2.438rem;
    font-weight: 700;
    line-height: 2.875rem;
  }
}

@media (min-width: 576px) {
  .header-h4-italic-sm {
    font-size: 2.438rem;
    font-style: italic;
    font-weight: 700;
    line-height: 2.875rem;
  }
}

@media (min-width: 576px) {
  .header-h4-extra-bold-sm {
    font-size: 2.438rem;
    font-weight: 800;
    line-height: 2.875rem;
  }
}

@media (min-width: 576px) {
  .header-h4-extra-bold-italic-sm {
    font-size: 2.438rem;
    font-style: italic;
    font-weight: 800;
    line-height: 2.875rem;
  }
}

@media (min-width: 576px) {
  .header-h4-black-sm {
    font-size: 2.438rem;
    font-weight: 900;
    line-height: 2.875rem;
  }
}

@media (min-width: 576px) {
  .header-h4-black-italic-sm {
    font-size: 2.438rem;
    font-style: italic;
    font-weight: 900;
    line-height: 2.875rem;
  }
}

@media (min-width: 576px) {
  .header-h5-thin-sm {
    font-size: 1.938rem;
    font-weight: 100;
    line-height: 2rem;
  }
}

@media (min-width: 576px) {
  .header-h5-thin-italic-sm {
    font-size: 1.938rem;
    font-style: italic;
    font-weight: 100;
    line-height: 2rem;
  }
}

@media (min-width: 576px) {
  .header-h5-extra-light-sm {
    font-size: 1.938rem;
    font-weight: 200;
    line-height: 2rem;
  }
}

@media (min-width: 576px) {
  .header-h5-extra-light-italic-sm {
    font-size: 1.938rem;
    font-style: italic;
    font-weight: 200;
    line-height: 2rem;
  }
}

@media (min-width: 576px) {
  .header-h5-light-sm {
    font-size: 1.938rem;
    font-weight: 300;
    line-height: 2rem;
  }
}

@media (min-width: 576px) {
  .header-h5-light-italic-sm {
    font-size: 1.938rem;
    font-style: italic;
    font-weight: 300;
    line-height: 2rem;
  }
}

@media (min-width: 576px) {
  .header-h5-regular-sm {
    font-size: 1.938rem;
    font-weight: 400;
    line-height: 2rem;
  }
}

@media (min-width: 576px) {
  .header-h5-regular-italic-sm {
    font-size: 1.938rem;
    font-style: italic;
    font-weight: 400;
    line-height: 2rem;
  }
}

@media (min-width: 576px) {
  .header-h5-medium-sm {
    font-size: 1.938rem;
    font-weight: 500;
    line-height: 2rem;
  }
}

@media (min-width: 576px) {
  .header-h5-medium-italic-sm {
    font-size: 1.938rem;
    font-style: italic;
    font-weight: 500;
    line-height: 2rem;
  }
}

@media (min-width: 576px) {
  .header-h5-semibold-sm {
    font-size: 1.938rem;
    font-weight: 600;
    line-height: 2rem;
  }
}

@media (min-width: 576px) {
  .header-h5-semibold-italic-sm {
    font-size: 1.938rem;
    font-style: italic;
    font-weight: 600;
    line-height: 2rem;
  }
}

@media (min-width: 576px) {
  .header-h5-bold-sm {
    font-size: 1.938rem;
    font-weight: 700;
    line-height: 2rem;
  }
}

@media (min-width: 576px) {
  .header-h5-bold-italic-sm {
    font-size: 1.938rem;
    font-style: italic;
    font-weight: 700;
    line-height: 2rem;
  }
}

@media (min-width: 576px) {
  .header-h5-sm {
    font-size: 1.938rem;
    font-weight: 700;
    line-height: 2rem;
  }
}

@media (min-width: 576px) {
  .header-h5-italic-sm {
    font-size: 1.938rem;
    font-style: italic;
    font-weight: 700;
    line-height: 2rem;
  }
}

@media (min-width: 576px) {
  .header-h5-extra-bold-sm {
    font-size: 1.938rem;
    font-weight: 800;
    line-height: 2rem;
  }
}

@media (min-width: 576px) {
  .header-h5-extra-bold-italic-sm {
    font-size: 1.938rem;
    font-style: italic;
    font-weight: 800;
    line-height: 2rem;
  }
}

@media (min-width: 576px) {
  .header-h5-black-sm {
    font-size: 1.938rem;
    font-weight: 900;
    line-height: 2rem;
  }
}

@media (min-width: 576px) {
  .header-h5-black-italic-sm {
    font-size: 1.938rem;
    font-style: italic;
    font-weight: 900;
    line-height: 2rem;
  }
}

@media (min-width: 576px) {
  .header-h6-thin-sm {
    font-size: 1.563rem;
    font-weight: 100;
    line-height: 2rem;
  }
}

@media (min-width: 576px) {
  .header-h6-thin-italic-sm {
    font-size: 1.563rem;
    font-style: italic;
    font-weight: 100;
    line-height: 2rem;
  }
}

@media (min-width: 576px) {
  .header-h6-extra-light-sm {
    font-size: 1.563rem;
    font-weight: 200;
    line-height: 2rem;
  }
}

@media (min-width: 576px) {
  .header-h6-extra-light-italic-sm {
    font-size: 1.563rem;
    font-style: italic;
    font-weight: 200;
    line-height: 2rem;
  }
}

@media (min-width: 576px) {
  .header-h6-light-sm {
    font-size: 1.563rem;
    font-weight: 300;
    line-height: 2rem;
  }
}

@media (min-width: 576px) {
  .header-h6-light-italic-sm {
    font-size: 1.563rem;
    font-style: italic;
    font-weight: 300;
    line-height: 2rem;
  }
}

@media (min-width: 576px) {
  .header-h6-regular-sm {
    font-size: 1.563rem;
    font-weight: 400;
    line-height: 2rem;
  }
}

@media (min-width: 576px) {
  .header-h6-regular-italic-sm {
    font-size: 1.563rem;
    font-style: italic;
    font-weight: 400;
    line-height: 2rem;
  }
}

@media (min-width: 576px) {
  .header-h6-medium-sm {
    font-size: 1.563rem;
    font-weight: 500;
    line-height: 2rem;
  }
}

@media (min-width: 576px) {
  .header-h6-medium-italic-sm {
    font-size: 1.563rem;
    font-style: italic;
    font-weight: 500;
    line-height: 2rem;
  }
}

@media (min-width: 576px) {
  .header-h6-semibold-sm {
    font-size: 1.563rem;
    font-weight: 600;
    line-height: 2rem;
  }
}

@media (min-width: 576px) {
  .header-h6-semibold-italic-sm {
    font-size: 1.563rem;
    font-style: italic;
    font-weight: 600;
    line-height: 2rem;
  }
}

@media (min-width: 576px) {
  .header-h6-bold-sm {
    font-size: 1.563rem;
    font-weight: 700;
    line-height: 2rem;
  }
}

@media (min-width: 576px) {
  .header-h6-bold-italic-sm {
    font-size: 1.563rem;
    font-style: italic;
    font-weight: 700;
    line-height: 2rem;
  }
}

@media (min-width: 576px) {
  .header-h6-sm {
    font-size: 1.563rem;
    font-weight: 700;
    line-height: 2rem;
  }
}

@media (min-width: 576px) {
  .header-h6-italic-sm {
    font-size: 1.563rem;
    font-style: italic;
    font-weight: 700;
    line-height: 2rem;
  }
}

@media (min-width: 576px) {
  .header-h6-extra-bold-sm {
    font-size: 1.563rem;
    font-weight: 800;
    line-height: 2rem;
  }
}

@media (min-width: 576px) {
  .header-h6-extra-bold-italic-sm {
    font-size: 1.563rem;
    font-style: italic;
    font-weight: 800;
    line-height: 2rem;
  }
}

@media (min-width: 576px) {
  .header-h6-black-sm {
    font-size: 1.563rem;
    font-weight: 900;
    line-height: 2rem;
  }
}

@media (min-width: 576px) {
  .header-h6-black-italic-sm {
    font-size: 1.563rem;
    font-style: italic;
    font-weight: 900;
    line-height: 2rem;
  }
}

@media (min-width: 576px) {
  .body-large-thin-sm {
    font-size: 1.25rem;
    font-weight: 100;
    line-height: 1.75rem;
  }
}

@media (min-width: 576px) {
  .body-large-thin-italic-sm {
    font-size: 1.25rem;
    font-style: italic;
    font-weight: 100;
    line-height: 1.75rem;
  }
}

@media (min-width: 576px) {
  .body-large-extra-light-sm {
    font-size: 1.25rem;
    font-weight: 200;
    line-height: 1.75rem;
  }
}

@media (min-width: 576px) {
  .body-large-extra-light-italic-sm {
    font-size: 1.25rem;
    font-style: italic;
    font-weight: 200;
    line-height: 1.75rem;
  }
}

@media (min-width: 576px) {
  .body-large-light-sm {
    font-size: 1.25rem;
    font-weight: 300;
    line-height: 1.75rem;
  }
}

@media (min-width: 576px) {
  .body-large-light-italic-sm {
    font-size: 1.25rem;
    font-style: italic;
    font-weight: 300;
    line-height: 1.75rem;
  }
}

@media (min-width: 576px) {
  .body-large-regular-sm {
    font-size: 1.25rem;
    font-weight: 400;
    line-height: 1.75rem;
  }
}

@media (min-width: 576px) {
  .body-large-regular-italic-sm {
    font-size: 1.25rem;
    font-style: italic;
    font-weight: 400;
    line-height: 1.75rem;
  }
}

@media (min-width: 576px) {
  .body-large-sm {
    font-size: 1.25rem;
    font-weight: 400;
    line-height: 1.75rem;
  }
}

@media (min-width: 576px) {
  .body-large-italic-sm {
    font-size: 1.25rem;
    font-style: italic;
    font-weight: 400;
    line-height: 1.75rem;
  }
}

@media (min-width: 576px) {
  .body-large-medium-sm {
    font-size: 1.25rem;
    font-weight: 500;
    line-height: 1.75rem;
  }
}

@media (min-width: 576px) {
  .body-large-medium-italic-sm {
    font-size: 1.25rem;
    font-style: italic;
    font-weight: 500;
    line-height: 1.75rem;
  }
}

@media (min-width: 576px) {
  .body-large-semibold-sm {
    font-size: 1.25rem;
    font-weight: 600;
    line-height: 1.75rem;
  }
}

@media (min-width: 576px) {
  .body-large-semibold-italic-sm {
    font-size: 1.25rem;
    font-style: italic;
    font-weight: 600;
    line-height: 1.75rem;
  }
}

@media (min-width: 576px) {
  .body-large-bold-sm {
    font-size: 1.25rem;
    font-weight: 700;
    line-height: 1.75rem;
  }
}

@media (min-width: 576px) {
  .body-large-bold-italic-sm {
    font-size: 1.25rem;
    font-style: italic;
    font-weight: 700;
    line-height: 1.75rem;
  }
}

@media (min-width: 576px) {
  .body-large-extra-bold-sm {
    font-size: 1.25rem;
    font-weight: 800;
    line-height: 1.75rem;
  }
}

@media (min-width: 576px) {
  .body-large-extra-bold-italic-sm {
    font-size: 1.25rem;
    font-style: italic;
    font-weight: 800;
    line-height: 1.75rem;
  }
}

@media (min-width: 576px) {
  .body-large-black-sm {
    font-size: 1.25rem;
    font-weight: 900;
    line-height: 1.75rem;
  }
}

@media (min-width: 576px) {
  .body-large-black-italic-sm {
    font-size: 1.25rem;
    font-style: italic;
    font-weight: 900;
    line-height: 1.75rem;
  }
}

@media (min-width: 576px) {
  .body-normal-thin-sm {
    font-size: 1rem;
    font-weight: 100;
    line-height: 1.5rem;
  }
}

@media (min-width: 576px) {
  .body-normal-thin-italic-sm {
    font-size: 1rem;
    font-style: italic;
    font-weight: 100;
    line-height: 1.5rem;
  }
}

@media (min-width: 576px) {
  .body-normal-extra-light-sm {
    font-size: 1rem;
    font-weight: 200;
    line-height: 1.5rem;
  }
}

@media (min-width: 576px) {
  .body-normal-extra-light-italic-sm {
    font-size: 1rem;
    font-style: italic;
    font-weight: 200;
    line-height: 1.5rem;
  }
}

@media (min-width: 576px) {
  .body-normal-light-sm {
    font-size: 1rem;
    font-weight: 300;
    line-height: 1.5rem;
  }
}

@media (min-width: 576px) {
  .body-normal-light-italic-sm {
    font-size: 1rem;
    font-style: italic;
    font-weight: 300;
    line-height: 1.5rem;
  }
}

@media (min-width: 576px) {
  .body-normal-regular-sm {
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5rem;
  }
}

@media (min-width: 576px) {
  .body-normal-regular-italic-sm {
    font-size: 1rem;
    font-style: italic;
    font-weight: 400;
    line-height: 1.5rem;
  }
}

@media (min-width: 576px) {
  .body-normal-sm {
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5rem;
  }
}

@media (min-width: 576px) {
  .body-normal-italic-sm {
    font-size: 1rem;
    font-style: italic;
    font-weight: 400;
    line-height: 1.5rem;
  }
}

@media (min-width: 576px) {
  .body-normal-medium-sm {
    font-size: 1rem;
    font-weight: 500;
    line-height: 1.5rem;
  }
}

@media (min-width: 576px) {
  .body-normal-medium-italic-sm {
    font-size: 1rem;
    font-style: italic;
    font-weight: 500;
    line-height: 1.5rem;
  }
}

@media (min-width: 576px) {
  .body-normal-semibold-sm {
    font-size: 1rem;
    font-weight: 600;
    line-height: 1.5rem;
  }
}

@media (min-width: 576px) {
  .body-normal-semibold-italic-sm {
    font-size: 1rem;
    font-style: italic;
    font-weight: 600;
    line-height: 1.5rem;
  }
}

@media (min-width: 576px) {
  .body-normal-bold-sm {
    font-size: 1rem;
    font-weight: 700;
    line-height: 1.5rem;
  }
}

@media (min-width: 576px) {
  .body-normal-bold-italic-sm {
    font-size: 1rem;
    font-style: italic;
    font-weight: 700;
    line-height: 1.5rem;
  }
}

@media (min-width: 576px) {
  .body-normal-extra-bold-sm {
    font-size: 1rem;
    font-weight: 800;
    line-height: 1.5rem;
  }
}

@media (min-width: 576px) {
  .body-normal-extra-bold-italic-sm {
    font-size: 1rem;
    font-style: italic;
    font-weight: 800;
    line-height: 1.5rem;
  }
}

@media (min-width: 576px) {
  .body-normal-black-sm {
    font-size: 1rem;
    font-weight: 900;
    line-height: 1.5rem;
  }
}

@media (min-width: 576px) {
  .body-normal-black-italic-sm {
    font-size: 1rem;
    font-style: italic;
    font-weight: 900;
    line-height: 1.5rem;
  }
}

@media (min-width: 576px) {
  .body-small-thin-sm {
    font-size: 0.875rem;
    font-weight: 100;
    line-height: 1.313rem;
  }
}

@media (min-width: 576px) {
  .body-small-thin-italic-sm {
    font-size: 0.875rem;
    font-style: italic;
    font-weight: 100;
    line-height: 1.313rem;
  }
}

@media (min-width: 576px) {
  .body-small-extra-light-sm {
    font-size: 0.875rem;
    font-weight: 200;
    line-height: 1.313rem;
  }
}

@media (min-width: 576px) {
  .body-small-extra-light-italic-sm {
    font-size: 0.875rem;
    font-style: italic;
    font-weight: 200;
    line-height: 1.313rem;
  }
}

@media (min-width: 576px) {
  .body-small-light-sm {
    font-size: 0.875rem;
    font-weight: 300;
    line-height: 1.313rem;
  }
}

@media (min-width: 576px) {
  .body-small-light-italic-sm {
    font-size: 0.875rem;
    font-style: italic;
    font-weight: 300;
    line-height: 1.313rem;
  }
}

@media (min-width: 576px) {
  .body-small-regular-sm {
    font-size: 0.875rem;
    font-weight: 400;
    line-height: 1.313rem;
  }
}

@media (min-width: 576px) {
  .body-small-regular-italic-sm {
    font-size: 0.875rem;
    font-style: italic;
    font-weight: 400;
    line-height: 1.313rem;
  }
}

@media (min-width: 576px) {
  .body-small-sm {
    font-size: 0.875rem;
    font-weight: 400;
    line-height: 1.313rem;
  }
}

@media (min-width: 576px) {
  .body-small-italic-sm {
    font-size: 0.875rem;
    font-style: italic;
    font-weight: 400;
    line-height: 1.313rem;
  }
}

@media (min-width: 576px) {
  .body-small-medium-sm {
    font-size: 0.875rem;
    font-weight: 500;
    line-height: 1.313rem;
  }
}

@media (min-width: 576px) {
  .body-small-medium-italic-sm {
    font-size: 0.875rem;
    font-style: italic;
    font-weight: 500;
    line-height: 1.313rem;
  }
}

@media (min-width: 576px) {
  .body-small-semibold-sm {
    font-size: 0.875rem;
    font-weight: 600;
    line-height: 1.313rem;
  }
}

@media (min-width: 576px) {
  .body-small-semibold-italic-sm {
    font-size: 0.875rem;
    font-style: italic;
    font-weight: 600;
    line-height: 1.313rem;
  }
}

@media (min-width: 576px) {
  .body-small-bold-sm {
    font-size: 0.875rem;
    font-weight: 700;
    line-height: 1.313rem;
  }
}

@media (min-width: 576px) {
  .body-small-bold-italic-sm {
    font-size: 0.875rem;
    font-style: italic;
    font-weight: 700;
    line-height: 1.313rem;
  }
}

@media (min-width: 576px) {
  .body-small-extra-bold-sm {
    font-size: 0.875rem;
    font-weight: 800;
    line-height: 1.313rem;
  }
}

@media (min-width: 576px) {
  .body-small-extra-bold-italic-sm {
    font-size: 0.875rem;
    font-style: italic;
    font-weight: 800;
    line-height: 1.313rem;
  }
}

@media (min-width: 576px) {
  .body-small-black-sm {
    font-size: 0.875rem;
    font-weight: 900;
    line-height: 1.313rem;
  }
}

@media (min-width: 576px) {
  .body-small-black-italic-sm {
    font-size: 0.875rem;
    font-style: italic;
    font-weight: 900;
    line-height: 1.313rem;
  }
}

@media (min-width: 576px) {
  .body-tiny-thin-sm {
    font-size: 0.75rem;
    font-weight: 100;
    line-height: 1rem;
  }
}

@media (min-width: 576px) {
  .body-tiny-thin-italic-sm {
    font-size: 0.75rem;
    font-style: italic;
    font-weight: 100;
    line-height: 1rem;
  }
}

@media (min-width: 576px) {
  .body-tiny-extra-light-sm {
    font-size: 0.75rem;
    font-weight: 200;
    line-height: 1rem;
  }
}

@media (min-width: 576px) {
  .body-tiny-extra-light-italic-sm {
    font-size: 0.75rem;
    font-style: italic;
    font-weight: 200;
    line-height: 1rem;
  }
}

@media (min-width: 576px) {
  .body-tiny-light-sm {
    font-size: 0.75rem;
    font-weight: 300;
    line-height: 1rem;
  }
}

@media (min-width: 576px) {
  .body-tiny-light-italic-sm {
    font-size: 0.75rem;
    font-style: italic;
    font-weight: 300;
    line-height: 1rem;
  }
}

@media (min-width: 576px) {
  .body-tiny-regular-sm {
    font-size: 0.75rem;
    font-weight: 400;
    line-height: 1rem;
  }
}

@media (min-width: 576px) {
  .body-tiny-regular-italic-sm {
    font-size: 0.75rem;
    font-style: italic;
    font-weight: 400;
    line-height: 1rem;
  }
}

@media (min-width: 576px) {
  .body-tiny-sm {
    font-size: 0.75rem;
    font-weight: 400;
    line-height: 1rem;
  }
}

@media (min-width: 576px) {
  .body-tiny-italic-sm {
    font-size: 0.75rem;
    font-style: italic;
    font-weight: 400;
    line-height: 1rem;
  }
}

@media (min-width: 576px) {
  .body-tiny-medium-sm {
    font-size: 0.75rem;
    font-weight: 500;
    line-height: 1rem;
  }
}

@media (min-width: 576px) {
  .body-tiny-medium-italic-sm {
    font-size: 0.75rem;
    font-style: italic;
    font-weight: 500;
    line-height: 1rem;
  }
}

@media (min-width: 576px) {
  .body-tiny-semibold-sm {
    font-size: 0.75rem;
    font-weight: 600;
    line-height: 1rem;
  }
}

@media (min-width: 576px) {
  .body-tiny-semibold-italic-sm {
    font-size: 0.75rem;
    font-style: italic;
    font-weight: 600;
    line-height: 1rem;
  }
}

@media (min-width: 576px) {
  .body-tiny-bold-sm {
    font-size: 0.75rem;
    font-weight: 700;
    line-height: 1rem;
  }
}

@media (min-width: 576px) {
  .body-tiny-bold-italic-sm {
    font-size: 0.75rem;
    font-style: italic;
    font-weight: 700;
    line-height: 1rem;
  }
}

@media (min-width: 576px) {
  .body-tiny-extra-bold-sm {
    font-size: 0.75rem;
    font-weight: 800;
    line-height: 1rem;
  }
}

@media (min-width: 576px) {
  .body-tiny-extra-bold-italic-sm {
    font-size: 0.75rem;
    font-style: italic;
    font-weight: 800;
    line-height: 1rem;
  }
}

@media (min-width: 576px) {
  .body-tiny-black-sm {
    font-size: 0.75rem;
    font-weight: 900;
    line-height: 1rem;
  }
}

@media (min-width: 576px) {
  .body-tiny-black-italic-sm {
    font-size: 0.75rem;
    font-style: italic;
    font-weight: 900;
    line-height: 1rem;
  }
}

@media (min-width: 576px) {
  .body-xs-thin-sm {
    font-size: 0.625rem;
    font-weight: 100;
    line-height: 1.313rem;
  }
}

@media (min-width: 576px) {
  .body-xs-thin-italic-sm {
    font-size: 0.625rem;
    font-style: italic;
    font-weight: 100;
    line-height: 1.313rem;
  }
}

@media (min-width: 576px) {
  .body-xs-extra-light-sm {
    font-size: 0.625rem;
    font-weight: 200;
    line-height: 1.313rem;
  }
}

@media (min-width: 576px) {
  .body-xs-extra-light-italic-sm {
    font-size: 0.625rem;
    font-style: italic;
    font-weight: 200;
    line-height: 1.313rem;
  }
}

@media (min-width: 576px) {
  .body-xs-light-sm {
    font-size: 0.625rem;
    font-weight: 300;
    line-height: 1.313rem;
  }
}

@media (min-width: 576px) {
  .body-xs-light-italic-sm {
    font-size: 0.625rem;
    font-style: italic;
    font-weight: 300;
    line-height: 1.313rem;
  }
}

@media (min-width: 576px) {
  .body-xs-regular-sm {
    font-size: 0.625rem;
    font-weight: 400;
    line-height: 1.313rem;
  }
}

@media (min-width: 576px) {
  .body-xs-regular-italic-sm {
    font-size: 0.625rem;
    font-style: italic;
    font-weight: 400;
    line-height: 1.313rem;
  }
}

@media (min-width: 576px) {
  .body-xs-sm {
    font-size: 0.625rem;
    font-weight: 400;
    line-height: 1.313rem;
  }
}

@media (min-width: 576px) {
  .body-xs-italic-sm {
    font-size: 0.625rem;
    font-style: italic;
    font-weight: 400;
    line-height: 1.313rem;
  }
}

@media (min-width: 576px) {
  .body-xs-medium-sm {
    font-size: 0.625rem;
    font-weight: 500;
    line-height: 1.313rem;
  }
}

@media (min-width: 576px) {
  .body-xs-medium-italic-sm {
    font-size: 0.625rem;
    font-style: italic;
    font-weight: 500;
    line-height: 1.313rem;
  }
}

@media (min-width: 576px) {
  .body-xs-semibold-sm {
    font-size: 0.625rem;
    font-weight: 600;
    line-height: 1.313rem;
  }
}

@media (min-width: 576px) {
  .body-xs-semibold-italic-sm {
    font-size: 0.625rem;
    font-style: italic;
    font-weight: 600;
    line-height: 1.313rem;
  }
}

@media (min-width: 576px) {
  .body-xs-bold-sm {
    font-size: 0.625rem;
    font-weight: 700;
    line-height: 1.313rem;
  }
}

@media (min-width: 576px) {
  .body-xs-bold-italic-sm {
    font-size: 0.625rem;
    font-style: italic;
    font-weight: 700;
    line-height: 1.313rem;
  }
}

@media (min-width: 576px) {
  .body-xs-extra-bold-sm {
    font-size: 0.625rem;
    font-weight: 800;
    line-height: 1.313rem;
  }
}

@media (min-width: 576px) {
  .body-xs-extra-bold-italic-sm {
    font-size: 0.625rem;
    font-style: italic;
    font-weight: 800;
    line-height: 1.313rem;
  }
}

@media (min-width: 576px) {
  .body-xs-black-sm {
    font-size: 0.625rem;
    font-weight: 900;
    line-height: 1.313rem;
  }
}

@media (min-width: 576px) {
  .body-xs-black-italic-sm {
    font-size: 0.625rem;
    font-style: italic;
    font-weight: 900;
    line-height: 1.313rem;
  }
}

@media (min-width: 768px) {
  .header-h1-thin-md {
    font-size: 4.75rem;
    font-weight: 100;
    line-height: 5rem;
  }
}

@media (min-width: 768px) {
  .header-h1-thin-italic-md {
    font-size: 4.75rem;
    font-style: italic;
    font-weight: 100;
    line-height: 5rem;
  }
}

@media (min-width: 768px) {
  .header-h1-extra-light-md {
    font-size: 4.75rem;
    font-weight: 200;
    line-height: 5rem;
  }
}

@media (min-width: 768px) {
  .header-h1-extra-light-italic-md {
    font-size: 4.75rem;
    font-style: italic;
    font-weight: 200;
    line-height: 5rem;
  }
}

@media (min-width: 768px) {
  .header-h1-light-md {
    font-size: 4.75rem;
    font-weight: 300;
    line-height: 5rem;
  }
}

@media (min-width: 768px) {
  .header-h1-light-italic-md {
    font-size: 4.75rem;
    font-style: italic;
    font-weight: 300;
    line-height: 5rem;
  }
}

@media (min-width: 768px) {
  .header-h1-regular-md {
    font-size: 4.75rem;
    font-weight: 400;
    line-height: 5rem;
  }
}

@media (min-width: 768px) {
  .header-h1-regular-italic-md {
    font-size: 4.75rem;
    font-style: italic;
    font-weight: 400;
    line-height: 5rem;
  }
}

@media (min-width: 768px) {
  .header-h1-medium-md {
    font-size: 4.75rem;
    font-weight: 500;
    line-height: 5rem;
  }
}

@media (min-width: 768px) {
  .header-h1-medium-italic-md {
    font-size: 4.75rem;
    font-style: italic;
    font-weight: 500;
    line-height: 5rem;
  }
}

@media (min-width: 768px) {
  .header-h1-semibold-md {
    font-size: 4.75rem;
    font-weight: 600;
    line-height: 5rem;
  }
}

@media (min-width: 768px) {
  .header-h1-semibold-italic-md {
    font-size: 4.75rem;
    font-style: italic;
    font-weight: 600;
    line-height: 5rem;
  }
}

@media (min-width: 768px) {
  .header-h1-bold-md {
    font-size: 4.75rem;
    font-weight: 700;
    line-height: 5rem;
  }
}

@media (min-width: 768px) {
  .header-h1-bold-italic-md {
    font-size: 4.75rem;
    font-style: italic;
    font-weight: 700;
    line-height: 5rem;
  }
}

@media (min-width: 768px) {
  .header-h1-md {
    font-size: 4.75rem;
    font-weight: 700;
    line-height: 5rem;
  }
}

@media (min-width: 768px) {
  .header-h1-italic-md {
    font-size: 4.75rem;
    font-style: italic;
    font-weight: 700;
    line-height: 5rem;
  }
}

@media (min-width: 768px) {
  .header-h1-extra-bold-md {
    font-size: 4.75rem;
    font-weight: 800;
    line-height: 5rem;
  }
}

@media (min-width: 768px) {
  .header-h1-extra-bold-italic-md {
    font-size: 4.75rem;
    font-style: italic;
    font-weight: 800;
    line-height: 5rem;
  }
}

@media (min-width: 768px) {
  .header-h1-black-md {
    font-size: 4.75rem;
    font-weight: 900;
    line-height: 5rem;
  }
}

@media (min-width: 768px) {
  .header-h1-black-italic-md {
    font-size: 4.75rem;
    font-style: italic;
    font-weight: 900;
    line-height: 5rem;
  }
}

@media (min-width: 768px) {
  .header-h2-thin-md {
    font-size: 3.813rem;
    font-weight: 100;
    line-height: 4.188rem;
  }
}

@media (min-width: 768px) {
  .header-h2-thin-italic-md {
    font-size: 3.813rem;
    font-style: italic;
    font-weight: 100;
    line-height: 4.188rem;
  }
}

@media (min-width: 768px) {
  .header-h2-extra-light-md {
    font-size: 3.813rem;
    font-weight: 200;
    line-height: 4.188rem;
  }
}

@media (min-width: 768px) {
  .header-h2-extra-light-italic-md {
    font-size: 3.813rem;
    font-style: italic;
    font-weight: 200;
    line-height: 4.188rem;
  }
}

@media (min-width: 768px) {
  .header-h2-light-md {
    font-size: 3.813rem;
    font-weight: 300;
    line-height: 4.188rem;
  }
}

@media (min-width: 768px) {
  .header-h2-light-italic-md {
    font-size: 3.813rem;
    font-style: italic;
    font-weight: 300;
    line-height: 4.188rem;
  }
}

@media (min-width: 768px) {
  .header-h2-regular-md {
    font-size: 3.813rem;
    font-weight: 400;
    line-height: 4.188rem;
  }
}

@media (min-width: 768px) {
  .header-h2-regular-italic-md {
    font-size: 3.813rem;
    font-style: italic;
    font-weight: 400;
    line-height: 4.188rem;
  }
}

@media (min-width: 768px) {
  .header-h2-medium-md {
    font-size: 3.813rem;
    font-weight: 500;
    line-height: 4.188rem;
  }
}

@media (min-width: 768px) {
  .header-h2-medium-italic-md {
    font-size: 3.813rem;
    font-style: italic;
    font-weight: 500;
    line-height: 4.188rem;
  }
}

@media (min-width: 768px) {
  .header-h2-semibold-md {
    font-size: 3.813rem;
    font-weight: 600;
    line-height: 4.188rem;
  }
}

@media (min-width: 768px) {
  .header-h2-semibold-italic-md {
    font-size: 3.813rem;
    font-style: italic;
    font-weight: 600;
    line-height: 4.188rem;
  }
}

@media (min-width: 768px) {
  .header-h2-bold-md {
    font-size: 3.813rem;
    font-weight: 700;
    line-height: 4.188rem;
  }
}

@media (min-width: 768px) {
  .header-h2-bold-italic-md {
    font-size: 3.813rem;
    font-style: italic;
    font-weight: 700;
    line-height: 4.188rem;
  }
}

@media (min-width: 768px) {
  .header-h2-md {
    font-size: 3.813rem;
    font-weight: 700;
    line-height: 4.188rem;
  }
}

@media (min-width: 768px) {
  .header-h2-italic-md {
    font-size: 3.813rem;
    font-style: italic;
    font-weight: 700;
    line-height: 4.188rem;
  }
}

@media (min-width: 768px) {
  .header-h2-extra-bold-md {
    font-size: 3.813rem;
    font-weight: 800;
    line-height: 4.188rem;
  }
}

@media (min-width: 768px) {
  .header-h2-extra-bold-italic-md {
    font-size: 3.813rem;
    font-style: italic;
    font-weight: 800;
    line-height: 4.188rem;
  }
}

@media (min-width: 768px) {
  .header-h2-black-md {
    font-size: 3.813rem;
    font-weight: 900;
    line-height: 4.188rem;
  }
}

@media (min-width: 768px) {
  .header-h2-black-italic-md {
    font-size: 3.813rem;
    font-style: italic;
    font-weight: 900;
    line-height: 4.188rem;
  }
}

@media (min-width: 768px) {
  .header-h3-thin-md {
    font-size: 3.063rem;
    font-weight: 100;
    line-height: 3.438rem;
  }
}

@media (min-width: 768px) {
  .header-h3-thin-italic-md {
    font-size: 3.063rem;
    font-style: italic;
    font-weight: 100;
    line-height: 3.438rem;
  }
}

@media (min-width: 768px) {
  .header-h3-extra-light-md {
    font-size: 3.063rem;
    font-weight: 200;
    line-height: 3.438rem;
  }
}

@media (min-width: 768px) {
  .header-h3-extra-light-italic-md {
    font-size: 3.063rem;
    font-style: italic;
    font-weight: 200;
    line-height: 3.438rem;
  }
}

@media (min-width: 768px) {
  .header-h3-light-md {
    font-size: 3.063rem;
    font-weight: 300;
    line-height: 3.438rem;
  }
}

@media (min-width: 768px) {
  .header-h3-light-italic-md {
    font-size: 3.063rem;
    font-style: italic;
    font-weight: 300;
    line-height: 3.438rem;
  }
}

@media (min-width: 768px) {
  .header-h3-regular-md {
    font-size: 3.063rem;
    font-weight: 400;
    line-height: 3.438rem;
  }
}

@media (min-width: 768px) {
  .header-h3-regular-italic-md {
    font-size: 3.063rem;
    font-style: italic;
    font-weight: 400;
    line-height: 3.438rem;
  }
}

@media (min-width: 768px) {
  .header-h3-medium-md {
    font-size: 3.063rem;
    font-weight: 500;
    line-height: 3.438rem;
  }
}

@media (min-width: 768px) {
  .header-h3-medium-italic-md {
    font-size: 3.063rem;
    font-style: italic;
    font-weight: 500;
    line-height: 3.438rem;
  }
}

@media (min-width: 768px) {
  .header-h3-semibold-md {
    font-size: 3.063rem;
    font-weight: 600;
    line-height: 3.438rem;
  }
}

@media (min-width: 768px) {
  .header-h3-semibold-italic-md {
    font-size: 3.063rem;
    font-style: italic;
    font-weight: 600;
    line-height: 3.438rem;
  }
}

@media (min-width: 768px) {
  .header-h3-bold-md {
    font-size: 3.063rem;
    font-weight: 700;
    line-height: 3.438rem;
  }
}

@media (min-width: 768px) {
  .header-h3-bold-italic-md {
    font-size: 3.063rem;
    font-style: italic;
    font-weight: 700;
    line-height: 3.438rem;
  }
}

@media (min-width: 768px) {
  .header-h3-md {
    font-size: 3.063rem;
    font-weight: 700;
    line-height: 3.438rem;
  }
}

@media (min-width: 768px) {
  .header-h3-italic-md {
    font-size: 3.063rem;
    font-style: italic;
    font-weight: 700;
    line-height: 3.438rem;
  }
}

@media (min-width: 768px) {
  .header-h3-extra-bold-md {
    font-size: 3.063rem;
    font-weight: 800;
    line-height: 3.438rem;
  }
}

@media (min-width: 768px) {
  .header-h3-extra-bold-italic-md {
    font-size: 3.063rem;
    font-style: italic;
    font-weight: 800;
    line-height: 3.438rem;
  }
}

@media (min-width: 768px) {
  .header-h3-black-md {
    font-size: 3.063rem;
    font-weight: 900;
    line-height: 3.438rem;
  }
}

@media (min-width: 768px) {
  .header-h3-black-italic-md {
    font-size: 3.063rem;
    font-style: italic;
    font-weight: 900;
    line-height: 3.438rem;
  }
}

@media (min-width: 768px) {
  .header-h4-thin-md {
    font-size: 2.438rem;
    font-weight: 100;
    line-height: 2.875rem;
  }
}

@media (min-width: 768px) {
  .header-h4-thin-italic-md {
    font-size: 2.438rem;
    font-style: italic;
    font-weight: 100;
    line-height: 2.875rem;
  }
}

@media (min-width: 768px) {
  .header-h4-extra-light-md {
    font-size: 2.438rem;
    font-weight: 200;
    line-height: 2.875rem;
  }
}

@media (min-width: 768px) {
  .header-h4-extra-light-italic-md {
    font-size: 2.438rem;
    font-style: italic;
    font-weight: 200;
    line-height: 2.875rem;
  }
}

@media (min-width: 768px) {
  .header-h4-light-md {
    font-size: 2.438rem;
    font-weight: 300;
    line-height: 2.875rem;
  }
}

@media (min-width: 768px) {
  .header-h4-light-italic-md {
    font-size: 2.438rem;
    font-style: italic;
    font-weight: 300;
    line-height: 2.875rem;
  }
}

@media (min-width: 768px) {
  .header-h4-regular-md {
    font-size: 2.438rem;
    font-weight: 400;
    line-height: 2.875rem;
  }
}

@media (min-width: 768px) {
  .header-h4-regular-italic-md {
    font-size: 2.438rem;
    font-style: italic;
    font-weight: 400;
    line-height: 2.875rem;
  }
}

@media (min-width: 768px) {
  .header-h4-medium-md {
    font-size: 2.438rem;
    font-weight: 500;
    line-height: 2.875rem;
  }
}

@media (min-width: 768px) {
  .header-h4-medium-italic-md {
    font-size: 2.438rem;
    font-style: italic;
    font-weight: 500;
    line-height: 2.875rem;
  }
}

@media (min-width: 768px) {
  .header-h4-semibold-md {
    font-size: 2.438rem;
    font-weight: 600;
    line-height: 2.875rem;
  }
}

@media (min-width: 768px) {
  .header-h4-semibold-italic-md {
    font-size: 2.438rem;
    font-style: italic;
    font-weight: 600;
    line-height: 2.875rem;
  }
}

@media (min-width: 768px) {
  .header-h4-bold-md {
    font-size: 2.438rem;
    font-weight: 700;
    line-height: 2.875rem;
  }
}

@media (min-width: 768px) {
  .header-h4-bold-italic-md {
    font-size: 2.438rem;
    font-style: italic;
    font-weight: 700;
    line-height: 2.875rem;
  }
}

@media (min-width: 768px) {
  .header-h4-md {
    font-size: 2.438rem;
    font-weight: 700;
    line-height: 2.875rem;
  }
}

@media (min-width: 768px) {
  .header-h4-italic-md {
    font-size: 2.438rem;
    font-style: italic;
    font-weight: 700;
    line-height: 2.875rem;
  }
}

@media (min-width: 768px) {
  .header-h4-extra-bold-md {
    font-size: 2.438rem;
    font-weight: 800;
    line-height: 2.875rem;
  }
}

@media (min-width: 768px) {
  .header-h4-extra-bold-italic-md {
    font-size: 2.438rem;
    font-style: italic;
    font-weight: 800;
    line-height: 2.875rem;
  }
}

@media (min-width: 768px) {
  .header-h4-black-md {
    font-size: 2.438rem;
    font-weight: 900;
    line-height: 2.875rem;
  }
}

@media (min-width: 768px) {
  .header-h4-black-italic-md {
    font-size: 2.438rem;
    font-style: italic;
    font-weight: 900;
    line-height: 2.875rem;
  }
}

@media (min-width: 768px) {
  .header-h5-thin-md {
    font-size: 1.938rem;
    font-weight: 100;
    line-height: 2rem;
  }
}

@media (min-width: 768px) {
  .header-h5-thin-italic-md {
    font-size: 1.938rem;
    font-style: italic;
    font-weight: 100;
    line-height: 2rem;
  }
}

@media (min-width: 768px) {
  .header-h5-extra-light-md {
    font-size: 1.938rem;
    font-weight: 200;
    line-height: 2rem;
  }
}

@media (min-width: 768px) {
  .header-h5-extra-light-italic-md {
    font-size: 1.938rem;
    font-style: italic;
    font-weight: 200;
    line-height: 2rem;
  }
}

@media (min-width: 768px) {
  .header-h5-light-md {
    font-size: 1.938rem;
    font-weight: 300;
    line-height: 2rem;
  }
}

@media (min-width: 768px) {
  .header-h5-light-italic-md {
    font-size: 1.938rem;
    font-style: italic;
    font-weight: 300;
    line-height: 2rem;
  }
}

@media (min-width: 768px) {
  .header-h5-regular-md {
    font-size: 1.938rem;
    font-weight: 400;
    line-height: 2rem;
  }
}

@media (min-width: 768px) {
  .header-h5-regular-italic-md {
    font-size: 1.938rem;
    font-style: italic;
    font-weight: 400;
    line-height: 2rem;
  }
}

@media (min-width: 768px) {
  .header-h5-medium-md {
    font-size: 1.938rem;
    font-weight: 500;
    line-height: 2rem;
  }
}

@media (min-width: 768px) {
  .header-h5-medium-italic-md {
    font-size: 1.938rem;
    font-style: italic;
    font-weight: 500;
    line-height: 2rem;
  }
}

@media (min-width: 768px) {
  .header-h5-semibold-md {
    font-size: 1.938rem;
    font-weight: 600;
    line-height: 2rem;
  }
}

@media (min-width: 768px) {
  .header-h5-semibold-italic-md {
    font-size: 1.938rem;
    font-style: italic;
    font-weight: 600;
    line-height: 2rem;
  }
}

@media (min-width: 768px) {
  .header-h5-bold-md {
    font-size: 1.938rem;
    font-weight: 700;
    line-height: 2rem;
  }
}

@media (min-width: 768px) {
  .header-h5-bold-italic-md {
    font-size: 1.938rem;
    font-style: italic;
    font-weight: 700;
    line-height: 2rem;
  }
}

@media (min-width: 768px) {
  .header-h5-md, .layout-title {
    font-size: 1.938rem;
    font-weight: 700;
    line-height: 2rem;
  }
}

@media (min-width: 768px) {
  .header-h5-italic-md {
    font-size: 1.938rem;
    font-style: italic;
    font-weight: 700;
    line-height: 2rem;
  }
}

@media (min-width: 768px) {
  .header-h5-extra-bold-md {
    font-size: 1.938rem;
    font-weight: 800;
    line-height: 2rem;
  }
}

@media (min-width: 768px) {
  .header-h5-extra-bold-italic-md {
    font-size: 1.938rem;
    font-style: italic;
    font-weight: 800;
    line-height: 2rem;
  }
}

@media (min-width: 768px) {
  .header-h5-black-md {
    font-size: 1.938rem;
    font-weight: 900;
    line-height: 2rem;
  }
}

@media (min-width: 768px) {
  .header-h5-black-italic-md {
    font-size: 1.938rem;
    font-style: italic;
    font-weight: 900;
    line-height: 2rem;
  }
}

@media (min-width: 768px) {
  .header-h6-thin-md {
    font-size: 1.563rem;
    font-weight: 100;
    line-height: 2rem;
  }
}

@media (min-width: 768px) {
  .header-h6-thin-italic-md {
    font-size: 1.563rem;
    font-style: italic;
    font-weight: 100;
    line-height: 2rem;
  }
}

@media (min-width: 768px) {
  .header-h6-extra-light-md {
    font-size: 1.563rem;
    font-weight: 200;
    line-height: 2rem;
  }
}

@media (min-width: 768px) {
  .header-h6-extra-light-italic-md {
    font-size: 1.563rem;
    font-style: italic;
    font-weight: 200;
    line-height: 2rem;
  }
}

@media (min-width: 768px) {
  .header-h6-light-md {
    font-size: 1.563rem;
    font-weight: 300;
    line-height: 2rem;
  }
}

@media (min-width: 768px) {
  .header-h6-light-italic-md {
    font-size: 1.563rem;
    font-style: italic;
    font-weight: 300;
    line-height: 2rem;
  }
}

@media (min-width: 768px) {
  .header-h6-regular-md {
    font-size: 1.563rem;
    font-weight: 400;
    line-height: 2rem;
  }
}

@media (min-width: 768px) {
  .header-h6-regular-italic-md {
    font-size: 1.563rem;
    font-style: italic;
    font-weight: 400;
    line-height: 2rem;
  }
}

@media (min-width: 768px) {
  .header-h6-medium-md {
    font-size: 1.563rem;
    font-weight: 500;
    line-height: 2rem;
  }
}

@media (min-width: 768px) {
  .header-h6-medium-italic-md {
    font-size: 1.563rem;
    font-style: italic;
    font-weight: 500;
    line-height: 2rem;
  }
}

@media (min-width: 768px) {
  .header-h6-semibold-md {
    font-size: 1.563rem;
    font-weight: 600;
    line-height: 2rem;
  }
}

@media (min-width: 768px) {
  .header-h6-semibold-italic-md {
    font-size: 1.563rem;
    font-style: italic;
    font-weight: 600;
    line-height: 2rem;
  }
}

@media (min-width: 768px) {
  .header-h6-bold-md {
    font-size: 1.563rem;
    font-weight: 700;
    line-height: 2rem;
  }
}

@media (min-width: 768px) {
  .header-h6-bold-italic-md {
    font-size: 1.563rem;
    font-style: italic;
    font-weight: 700;
    line-height: 2rem;
  }
}

@media (min-width: 768px) {
  .header-h6-md {
    font-size: 1.563rem;
    font-weight: 700;
    line-height: 2rem;
  }
}

@media (min-width: 768px) {
  .header-h6-italic-md {
    font-size: 1.563rem;
    font-style: italic;
    font-weight: 700;
    line-height: 2rem;
  }
}

@media (min-width: 768px) {
  .header-h6-extra-bold-md {
    font-size: 1.563rem;
    font-weight: 800;
    line-height: 2rem;
  }
}

@media (min-width: 768px) {
  .header-h6-extra-bold-italic-md {
    font-size: 1.563rem;
    font-style: italic;
    font-weight: 800;
    line-height: 2rem;
  }
}

@media (min-width: 768px) {
  .header-h6-black-md {
    font-size: 1.563rem;
    font-weight: 900;
    line-height: 2rem;
  }
}

@media (min-width: 768px) {
  .header-h6-black-italic-md {
    font-size: 1.563rem;
    font-style: italic;
    font-weight: 900;
    line-height: 2rem;
  }
}

@media (min-width: 768px) {
  .body-large-thin-md {
    font-size: 1.25rem;
    font-weight: 100;
    line-height: 1.75rem;
  }
}

@media (min-width: 768px) {
  .body-large-thin-italic-md {
    font-size: 1.25rem;
    font-style: italic;
    font-weight: 100;
    line-height: 1.75rem;
  }
}

@media (min-width: 768px) {
  .body-large-extra-light-md {
    font-size: 1.25rem;
    font-weight: 200;
    line-height: 1.75rem;
  }
}

@media (min-width: 768px) {
  .body-large-extra-light-italic-md {
    font-size: 1.25rem;
    font-style: italic;
    font-weight: 200;
    line-height: 1.75rem;
  }
}

@media (min-width: 768px) {
  .body-large-light-md {
    font-size: 1.25rem;
    font-weight: 300;
    line-height: 1.75rem;
  }
}

@media (min-width: 768px) {
  .body-large-light-italic-md {
    font-size: 1.25rem;
    font-style: italic;
    font-weight: 300;
    line-height: 1.75rem;
  }
}

@media (min-width: 768px) {
  .body-large-regular-md {
    font-size: 1.25rem;
    font-weight: 400;
    line-height: 1.75rem;
  }
}

@media (min-width: 768px) {
  .body-large-regular-italic-md {
    font-size: 1.25rem;
    font-style: italic;
    font-weight: 400;
    line-height: 1.75rem;
  }
}

@media (min-width: 768px) {
  .body-large-md {
    font-size: 1.25rem;
    font-weight: 400;
    line-height: 1.75rem;
  }
}

@media (min-width: 768px) {
  .body-large-italic-md {
    font-size: 1.25rem;
    font-style: italic;
    font-weight: 400;
    line-height: 1.75rem;
  }
}

@media (min-width: 768px) {
  .body-large-medium-md {
    font-size: 1.25rem;
    font-weight: 500;
    line-height: 1.75rem;
  }
}

@media (min-width: 768px) {
  .body-large-medium-italic-md {
    font-size: 1.25rem;
    font-style: italic;
    font-weight: 500;
    line-height: 1.75rem;
  }
}

@media (min-width: 768px) {
  .body-large-semibold-md {
    font-size: 1.25rem;
    font-weight: 600;
    line-height: 1.75rem;
  }
}

@media (min-width: 768px) {
  .body-large-semibold-italic-md {
    font-size: 1.25rem;
    font-style: italic;
    font-weight: 600;
    line-height: 1.75rem;
  }
}

@media (min-width: 768px) {
  .body-large-bold-md {
    font-size: 1.25rem;
    font-weight: 700;
    line-height: 1.75rem;
  }
}

@media (min-width: 768px) {
  .body-large-bold-italic-md {
    font-size: 1.25rem;
    font-style: italic;
    font-weight: 700;
    line-height: 1.75rem;
  }
}

@media (min-width: 768px) {
  .body-large-extra-bold-md {
    font-size: 1.25rem;
    font-weight: 800;
    line-height: 1.75rem;
  }
}

@media (min-width: 768px) {
  .body-large-extra-bold-italic-md {
    font-size: 1.25rem;
    font-style: italic;
    font-weight: 800;
    line-height: 1.75rem;
  }
}

@media (min-width: 768px) {
  .body-large-black-md {
    font-size: 1.25rem;
    font-weight: 900;
    line-height: 1.75rem;
  }
}

@media (min-width: 768px) {
  .body-large-black-italic-md {
    font-size: 1.25rem;
    font-style: italic;
    font-weight: 900;
    line-height: 1.75rem;
  }
}

@media (min-width: 768px) {
  .body-normal-thin-md {
    font-size: 1rem;
    font-weight: 100;
    line-height: 1.5rem;
  }
}

@media (min-width: 768px) {
  .body-normal-thin-italic-md {
    font-size: 1rem;
    font-style: italic;
    font-weight: 100;
    line-height: 1.5rem;
  }
}

@media (min-width: 768px) {
  .body-normal-extra-light-md {
    font-size: 1rem;
    font-weight: 200;
    line-height: 1.5rem;
  }
}

@media (min-width: 768px) {
  .body-normal-extra-light-italic-md {
    font-size: 1rem;
    font-style: italic;
    font-weight: 200;
    line-height: 1.5rem;
  }
}

@media (min-width: 768px) {
  .body-normal-light-md {
    font-size: 1rem;
    font-weight: 300;
    line-height: 1.5rem;
  }
}

@media (min-width: 768px) {
  .body-normal-light-italic-md {
    font-size: 1rem;
    font-style: italic;
    font-weight: 300;
    line-height: 1.5rem;
  }
}

@media (min-width: 768px) {
  .body-normal-regular-md {
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5rem;
  }
}

@media (min-width: 768px) {
  .body-normal-regular-italic-md {
    font-size: 1rem;
    font-style: italic;
    font-weight: 400;
    line-height: 1.5rem;
  }
}

@media (min-width: 768px) {
  .body-normal-md {
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5rem;
  }
}

@media (min-width: 768px) {
  .body-normal-italic-md {
    font-size: 1rem;
    font-style: italic;
    font-weight: 400;
    line-height: 1.5rem;
  }
}

@media (min-width: 768px) {
  .body-normal-medium-md {
    font-size: 1rem;
    font-weight: 500;
    line-height: 1.5rem;
  }
}

@media (min-width: 768px) {
  .body-normal-medium-italic-md {
    font-size: 1rem;
    font-style: italic;
    font-weight: 500;
    line-height: 1.5rem;
  }
}

@media (min-width: 768px) {
  .body-normal-semibold-md {
    font-size: 1rem;
    font-weight: 600;
    line-height: 1.5rem;
  }
}

@media (min-width: 768px) {
  .body-normal-semibold-italic-md {
    font-size: 1rem;
    font-style: italic;
    font-weight: 600;
    line-height: 1.5rem;
  }
}

@media (min-width: 768px) {
  .body-normal-bold-md {
    font-size: 1rem;
    font-weight: 700;
    line-height: 1.5rem;
  }
}

@media (min-width: 768px) {
  .body-normal-bold-italic-md {
    font-size: 1rem;
    font-style: italic;
    font-weight: 700;
    line-height: 1.5rem;
  }
}

@media (min-width: 768px) {
  .body-normal-extra-bold-md {
    font-size: 1rem;
    font-weight: 800;
    line-height: 1.5rem;
  }
}

@media (min-width: 768px) {
  .body-normal-extra-bold-italic-md {
    font-size: 1rem;
    font-style: italic;
    font-weight: 800;
    line-height: 1.5rem;
  }
}

@media (min-width: 768px) {
  .body-normal-black-md {
    font-size: 1rem;
    font-weight: 900;
    line-height: 1.5rem;
  }
}

@media (min-width: 768px) {
  .body-normal-black-italic-md {
    font-size: 1rem;
    font-style: italic;
    font-weight: 900;
    line-height: 1.5rem;
  }
}

@media (min-width: 768px) {
  .body-small-thin-md {
    font-size: 0.875rem;
    font-weight: 100;
    line-height: 1.313rem;
  }
}

@media (min-width: 768px) {
  .body-small-thin-italic-md {
    font-size: 0.875rem;
    font-style: italic;
    font-weight: 100;
    line-height: 1.313rem;
  }
}

@media (min-width: 768px) {
  .body-small-extra-light-md {
    font-size: 0.875rem;
    font-weight: 200;
    line-height: 1.313rem;
  }
}

@media (min-width: 768px) {
  .body-small-extra-light-italic-md {
    font-size: 0.875rem;
    font-style: italic;
    font-weight: 200;
    line-height: 1.313rem;
  }
}

@media (min-width: 768px) {
  .body-small-light-md {
    font-size: 0.875rem;
    font-weight: 300;
    line-height: 1.313rem;
  }
}

@media (min-width: 768px) {
  .body-small-light-italic-md {
    font-size: 0.875rem;
    font-style: italic;
    font-weight: 300;
    line-height: 1.313rem;
  }
}

@media (min-width: 768px) {
  .body-small-regular-md {
    font-size: 0.875rem;
    font-weight: 400;
    line-height: 1.313rem;
  }
}

@media (min-width: 768px) {
  .body-small-regular-italic-md {
    font-size: 0.875rem;
    font-style: italic;
    font-weight: 400;
    line-height: 1.313rem;
  }
}

@media (min-width: 768px) {
  .body-small-md {
    font-size: 0.875rem;
    font-weight: 400;
    line-height: 1.313rem;
  }
}

@media (min-width: 768px) {
  .body-small-italic-md {
    font-size: 0.875rem;
    font-style: italic;
    font-weight: 400;
    line-height: 1.313rem;
  }
}

@media (min-width: 768px) {
  .body-small-medium-md {
    font-size: 0.875rem;
    font-weight: 500;
    line-height: 1.313rem;
  }
}

@media (min-width: 768px) {
  .body-small-medium-italic-md {
    font-size: 0.875rem;
    font-style: italic;
    font-weight: 500;
    line-height: 1.313rem;
  }
}

@media (min-width: 768px) {
  .body-small-semibold-md {
    font-size: 0.875rem;
    font-weight: 600;
    line-height: 1.313rem;
  }
}

@media (min-width: 768px) {
  .body-small-semibold-italic-md {
    font-size: 0.875rem;
    font-style: italic;
    font-weight: 600;
    line-height: 1.313rem;
  }
}

@media (min-width: 768px) {
  .body-small-bold-md {
    font-size: 0.875rem;
    font-weight: 700;
    line-height: 1.313rem;
  }
}

@media (min-width: 768px) {
  .body-small-bold-italic-md {
    font-size: 0.875rem;
    font-style: italic;
    font-weight: 700;
    line-height: 1.313rem;
  }
}

@media (min-width: 768px) {
  .body-small-extra-bold-md {
    font-size: 0.875rem;
    font-weight: 800;
    line-height: 1.313rem;
  }
}

@media (min-width: 768px) {
  .body-small-extra-bold-italic-md {
    font-size: 0.875rem;
    font-style: italic;
    font-weight: 800;
    line-height: 1.313rem;
  }
}

@media (min-width: 768px) {
  .body-small-black-md {
    font-size: 0.875rem;
    font-weight: 900;
    line-height: 1.313rem;
  }
}

@media (min-width: 768px) {
  .body-small-black-italic-md {
    font-size: 0.875rem;
    font-style: italic;
    font-weight: 900;
    line-height: 1.313rem;
  }
}

@media (min-width: 768px) {
  .body-tiny-thin-md {
    font-size: 0.75rem;
    font-weight: 100;
    line-height: 1rem;
  }
}

@media (min-width: 768px) {
  .body-tiny-thin-italic-md {
    font-size: 0.75rem;
    font-style: italic;
    font-weight: 100;
    line-height: 1rem;
  }
}

@media (min-width: 768px) {
  .body-tiny-extra-light-md {
    font-size: 0.75rem;
    font-weight: 200;
    line-height: 1rem;
  }
}

@media (min-width: 768px) {
  .body-tiny-extra-light-italic-md {
    font-size: 0.75rem;
    font-style: italic;
    font-weight: 200;
    line-height: 1rem;
  }
}

@media (min-width: 768px) {
  .body-tiny-light-md {
    font-size: 0.75rem;
    font-weight: 300;
    line-height: 1rem;
  }
}

@media (min-width: 768px) {
  .body-tiny-light-italic-md {
    font-size: 0.75rem;
    font-style: italic;
    font-weight: 300;
    line-height: 1rem;
  }
}

@media (min-width: 768px) {
  .body-tiny-regular-md {
    font-size: 0.75rem;
    font-weight: 400;
    line-height: 1rem;
  }
}

@media (min-width: 768px) {
  .body-tiny-regular-italic-md {
    font-size: 0.75rem;
    font-style: italic;
    font-weight: 400;
    line-height: 1rem;
  }
}

@media (min-width: 768px) {
  .body-tiny-md {
    font-size: 0.75rem;
    font-weight: 400;
    line-height: 1rem;
  }
}

@media (min-width: 768px) {
  .body-tiny-italic-md {
    font-size: 0.75rem;
    font-style: italic;
    font-weight: 400;
    line-height: 1rem;
  }
}

@media (min-width: 768px) {
  .body-tiny-medium-md {
    font-size: 0.75rem;
    font-weight: 500;
    line-height: 1rem;
  }
}

@media (min-width: 768px) {
  .body-tiny-medium-italic-md {
    font-size: 0.75rem;
    font-style: italic;
    font-weight: 500;
    line-height: 1rem;
  }
}

@media (min-width: 768px) {
  .body-tiny-semibold-md {
    font-size: 0.75rem;
    font-weight: 600;
    line-height: 1rem;
  }
}

@media (min-width: 768px) {
  .body-tiny-semibold-italic-md {
    font-size: 0.75rem;
    font-style: italic;
    font-weight: 600;
    line-height: 1rem;
  }
}

@media (min-width: 768px) {
  .body-tiny-bold-md {
    font-size: 0.75rem;
    font-weight: 700;
    line-height: 1rem;
  }
}

@media (min-width: 768px) {
  .body-tiny-bold-italic-md {
    font-size: 0.75rem;
    font-style: italic;
    font-weight: 700;
    line-height: 1rem;
  }
}

@media (min-width: 768px) {
  .body-tiny-extra-bold-md {
    font-size: 0.75rem;
    font-weight: 800;
    line-height: 1rem;
  }
}

@media (min-width: 768px) {
  .body-tiny-extra-bold-italic-md {
    font-size: 0.75rem;
    font-style: italic;
    font-weight: 800;
    line-height: 1rem;
  }
}

@media (min-width: 768px) {
  .body-tiny-black-md {
    font-size: 0.75rem;
    font-weight: 900;
    line-height: 1rem;
  }
}

@media (min-width: 768px) {
  .body-tiny-black-italic-md {
    font-size: 0.75rem;
    font-style: italic;
    font-weight: 900;
    line-height: 1rem;
  }
}

@media (min-width: 768px) {
  .body-xs-thin-md {
    font-size: 0.625rem;
    font-weight: 100;
    line-height: 1.313rem;
  }
}

@media (min-width: 768px) {
  .body-xs-thin-italic-md {
    font-size: 0.625rem;
    font-style: italic;
    font-weight: 100;
    line-height: 1.313rem;
  }
}

@media (min-width: 768px) {
  .body-xs-extra-light-md {
    font-size: 0.625rem;
    font-weight: 200;
    line-height: 1.313rem;
  }
}

@media (min-width: 768px) {
  .body-xs-extra-light-italic-md {
    font-size: 0.625rem;
    font-style: italic;
    font-weight: 200;
    line-height: 1.313rem;
  }
}

@media (min-width: 768px) {
  .body-xs-light-md {
    font-size: 0.625rem;
    font-weight: 300;
    line-height: 1.313rem;
  }
}

@media (min-width: 768px) {
  .body-xs-light-italic-md {
    font-size: 0.625rem;
    font-style: italic;
    font-weight: 300;
    line-height: 1.313rem;
  }
}

@media (min-width: 768px) {
  .body-xs-regular-md {
    font-size: 0.625rem;
    font-weight: 400;
    line-height: 1.313rem;
  }
}

@media (min-width: 768px) {
  .body-xs-regular-italic-md {
    font-size: 0.625rem;
    font-style: italic;
    font-weight: 400;
    line-height: 1.313rem;
  }
}

@media (min-width: 768px) {
  .body-xs-md {
    font-size: 0.625rem;
    font-weight: 400;
    line-height: 1.313rem;
  }
}

@media (min-width: 768px) {
  .body-xs-italic-md {
    font-size: 0.625rem;
    font-style: italic;
    font-weight: 400;
    line-height: 1.313rem;
  }
}

@media (min-width: 768px) {
  .body-xs-medium-md {
    font-size: 0.625rem;
    font-weight: 500;
    line-height: 1.313rem;
  }
}

@media (min-width: 768px) {
  .body-xs-medium-italic-md {
    font-size: 0.625rem;
    font-style: italic;
    font-weight: 500;
    line-height: 1.313rem;
  }
}

@media (min-width: 768px) {
  .body-xs-semibold-md {
    font-size: 0.625rem;
    font-weight: 600;
    line-height: 1.313rem;
  }
}

@media (min-width: 768px) {
  .body-xs-semibold-italic-md {
    font-size: 0.625rem;
    font-style: italic;
    font-weight: 600;
    line-height: 1.313rem;
  }
}

@media (min-width: 768px) {
  .body-xs-bold-md {
    font-size: 0.625rem;
    font-weight: 700;
    line-height: 1.313rem;
  }
}

@media (min-width: 768px) {
  .body-xs-bold-italic-md {
    font-size: 0.625rem;
    font-style: italic;
    font-weight: 700;
    line-height: 1.313rem;
  }
}

@media (min-width: 768px) {
  .body-xs-extra-bold-md {
    font-size: 0.625rem;
    font-weight: 800;
    line-height: 1.313rem;
  }
}

@media (min-width: 768px) {
  .body-xs-extra-bold-italic-md {
    font-size: 0.625rem;
    font-style: italic;
    font-weight: 800;
    line-height: 1.313rem;
  }
}

@media (min-width: 768px) {
  .body-xs-black-md {
    font-size: 0.625rem;
    font-weight: 900;
    line-height: 1.313rem;
  }
}

@media (min-width: 768px) {
  .body-xs-black-italic-md {
    font-size: 0.625rem;
    font-style: italic;
    font-weight: 900;
    line-height: 1.313rem;
  }
}

@media (min-width: 992px) {
  .header-h1-thin-lg {
    font-size: 4.75rem;
    font-weight: 100;
    line-height: 5rem;
  }
}

@media (min-width: 992px) {
  .header-h1-thin-italic-lg {
    font-size: 4.75rem;
    font-style: italic;
    font-weight: 100;
    line-height: 5rem;
  }
}

@media (min-width: 992px) {
  .header-h1-extra-light-lg {
    font-size: 4.75rem;
    font-weight: 200;
    line-height: 5rem;
  }
}

@media (min-width: 992px) {
  .header-h1-extra-light-italic-lg {
    font-size: 4.75rem;
    font-style: italic;
    font-weight: 200;
    line-height: 5rem;
  }
}

@media (min-width: 992px) {
  .header-h1-light-lg {
    font-size: 4.75rem;
    font-weight: 300;
    line-height: 5rem;
  }
}

@media (min-width: 992px) {
  .header-h1-light-italic-lg {
    font-size: 4.75rem;
    font-style: italic;
    font-weight: 300;
    line-height: 5rem;
  }
}

@media (min-width: 992px) {
  .header-h1-regular-lg {
    font-size: 4.75rem;
    font-weight: 400;
    line-height: 5rem;
  }
}

@media (min-width: 992px) {
  .header-h1-regular-italic-lg {
    font-size: 4.75rem;
    font-style: italic;
    font-weight: 400;
    line-height: 5rem;
  }
}

@media (min-width: 992px) {
  .header-h1-medium-lg {
    font-size: 4.75rem;
    font-weight: 500;
    line-height: 5rem;
  }
}

@media (min-width: 992px) {
  .header-h1-medium-italic-lg {
    font-size: 4.75rem;
    font-style: italic;
    font-weight: 500;
    line-height: 5rem;
  }
}

@media (min-width: 992px) {
  .header-h1-semibold-lg {
    font-size: 4.75rem;
    font-weight: 600;
    line-height: 5rem;
  }
}

@media (min-width: 992px) {
  .header-h1-semibold-italic-lg {
    font-size: 4.75rem;
    font-style: italic;
    font-weight: 600;
    line-height: 5rem;
  }
}

@media (min-width: 992px) {
  .header-h1-bold-lg {
    font-size: 4.75rem;
    font-weight: 700;
    line-height: 5rem;
  }
}

@media (min-width: 992px) {
  .header-h1-bold-italic-lg {
    font-size: 4.75rem;
    font-style: italic;
    font-weight: 700;
    line-height: 5rem;
  }
}

@media (min-width: 992px) {
  .header-h1-lg {
    font-size: 4.75rem;
    font-weight: 700;
    line-height: 5rem;
  }
}

@media (min-width: 992px) {
  .header-h1-italic-lg {
    font-size: 4.75rem;
    font-style: italic;
    font-weight: 700;
    line-height: 5rem;
  }
}

@media (min-width: 992px) {
  .header-h1-extra-bold-lg {
    font-size: 4.75rem;
    font-weight: 800;
    line-height: 5rem;
  }
}

@media (min-width: 992px) {
  .header-h1-extra-bold-italic-lg {
    font-size: 4.75rem;
    font-style: italic;
    font-weight: 800;
    line-height: 5rem;
  }
}

@media (min-width: 992px) {
  .header-h1-black-lg {
    font-size: 4.75rem;
    font-weight: 900;
    line-height: 5rem;
  }
}

@media (min-width: 992px) {
  .header-h1-black-italic-lg {
    font-size: 4.75rem;
    font-style: italic;
    font-weight: 900;
    line-height: 5rem;
  }
}

@media (min-width: 992px) {
  .header-h2-thin-lg {
    font-size: 3.813rem;
    font-weight: 100;
    line-height: 4.188rem;
  }
}

@media (min-width: 992px) {
  .header-h2-thin-italic-lg {
    font-size: 3.813rem;
    font-style: italic;
    font-weight: 100;
    line-height: 4.188rem;
  }
}

@media (min-width: 992px) {
  .header-h2-extra-light-lg {
    font-size: 3.813rem;
    font-weight: 200;
    line-height: 4.188rem;
  }
}

@media (min-width: 992px) {
  .header-h2-extra-light-italic-lg {
    font-size: 3.813rem;
    font-style: italic;
    font-weight: 200;
    line-height: 4.188rem;
  }
}

@media (min-width: 992px) {
  .header-h2-light-lg {
    font-size: 3.813rem;
    font-weight: 300;
    line-height: 4.188rem;
  }
}

@media (min-width: 992px) {
  .header-h2-light-italic-lg {
    font-size: 3.813rem;
    font-style: italic;
    font-weight: 300;
    line-height: 4.188rem;
  }
}

@media (min-width: 992px) {
  .header-h2-regular-lg {
    font-size: 3.813rem;
    font-weight: 400;
    line-height: 4.188rem;
  }
}

@media (min-width: 992px) {
  .header-h2-regular-italic-lg {
    font-size: 3.813rem;
    font-style: italic;
    font-weight: 400;
    line-height: 4.188rem;
  }
}

@media (min-width: 992px) {
  .header-h2-medium-lg {
    font-size: 3.813rem;
    font-weight: 500;
    line-height: 4.188rem;
  }
}

@media (min-width: 992px) {
  .header-h2-medium-italic-lg {
    font-size: 3.813rem;
    font-style: italic;
    font-weight: 500;
    line-height: 4.188rem;
  }
}

@media (min-width: 992px) {
  .header-h2-semibold-lg {
    font-size: 3.813rem;
    font-weight: 600;
    line-height: 4.188rem;
  }
}

@media (min-width: 992px) {
  .header-h2-semibold-italic-lg {
    font-size: 3.813rem;
    font-style: italic;
    font-weight: 600;
    line-height: 4.188rem;
  }
}

@media (min-width: 992px) {
  .header-h2-bold-lg {
    font-size: 3.813rem;
    font-weight: 700;
    line-height: 4.188rem;
  }
}

@media (min-width: 992px) {
  .header-h2-bold-italic-lg {
    font-size: 3.813rem;
    font-style: italic;
    font-weight: 700;
    line-height: 4.188rem;
  }
}

@media (min-width: 992px) {
  .header-h2-lg {
    font-size: 3.813rem;
    font-weight: 700;
    line-height: 4.188rem;
  }
}

@media (min-width: 992px) {
  .header-h2-italic-lg {
    font-size: 3.813rem;
    font-style: italic;
    font-weight: 700;
    line-height: 4.188rem;
  }
}

@media (min-width: 992px) {
  .header-h2-extra-bold-lg {
    font-size: 3.813rem;
    font-weight: 800;
    line-height: 4.188rem;
  }
}

@media (min-width: 992px) {
  .header-h2-extra-bold-italic-lg {
    font-size: 3.813rem;
    font-style: italic;
    font-weight: 800;
    line-height: 4.188rem;
  }
}

@media (min-width: 992px) {
  .header-h2-black-lg {
    font-size: 3.813rem;
    font-weight: 900;
    line-height: 4.188rem;
  }
}

@media (min-width: 992px) {
  .header-h2-black-italic-lg {
    font-size: 3.813rem;
    font-style: italic;
    font-weight: 900;
    line-height: 4.188rem;
  }
}

@media (min-width: 992px) {
  .header-h3-thin-lg {
    font-size: 3.063rem;
    font-weight: 100;
    line-height: 3.438rem;
  }
}

@media (min-width: 992px) {
  .header-h3-thin-italic-lg {
    font-size: 3.063rem;
    font-style: italic;
    font-weight: 100;
    line-height: 3.438rem;
  }
}

@media (min-width: 992px) {
  .header-h3-extra-light-lg {
    font-size: 3.063rem;
    font-weight: 200;
    line-height: 3.438rem;
  }
}

@media (min-width: 992px) {
  .header-h3-extra-light-italic-lg {
    font-size: 3.063rem;
    font-style: italic;
    font-weight: 200;
    line-height: 3.438rem;
  }
}

@media (min-width: 992px) {
  .header-h3-light-lg {
    font-size: 3.063rem;
    font-weight: 300;
    line-height: 3.438rem;
  }
}

@media (min-width: 992px) {
  .header-h3-light-italic-lg {
    font-size: 3.063rem;
    font-style: italic;
    font-weight: 300;
    line-height: 3.438rem;
  }
}

@media (min-width: 992px) {
  .header-h3-regular-lg {
    font-size: 3.063rem;
    font-weight: 400;
    line-height: 3.438rem;
  }
}

@media (min-width: 992px) {
  .header-h3-regular-italic-lg {
    font-size: 3.063rem;
    font-style: italic;
    font-weight: 400;
    line-height: 3.438rem;
  }
}

@media (min-width: 992px) {
  .header-h3-medium-lg {
    font-size: 3.063rem;
    font-weight: 500;
    line-height: 3.438rem;
  }
}

@media (min-width: 992px) {
  .header-h3-medium-italic-lg {
    font-size: 3.063rem;
    font-style: italic;
    font-weight: 500;
    line-height: 3.438rem;
  }
}

@media (min-width: 992px) {
  .header-h3-semibold-lg {
    font-size: 3.063rem;
    font-weight: 600;
    line-height: 3.438rem;
  }
}

@media (min-width: 992px) {
  .header-h3-semibold-italic-lg {
    font-size: 3.063rem;
    font-style: italic;
    font-weight: 600;
    line-height: 3.438rem;
  }
}

@media (min-width: 992px) {
  .header-h3-bold-lg {
    font-size: 3.063rem;
    font-weight: 700;
    line-height: 3.438rem;
  }
}

@media (min-width: 992px) {
  .header-h3-bold-italic-lg {
    font-size: 3.063rem;
    font-style: italic;
    font-weight: 700;
    line-height: 3.438rem;
  }
}

@media (min-width: 992px) {
  .header-h3-lg {
    font-size: 3.063rem;
    font-weight: 700;
    line-height: 3.438rem;
  }
}

@media (min-width: 992px) {
  .header-h3-italic-lg {
    font-size: 3.063rem;
    font-style: italic;
    font-weight: 700;
    line-height: 3.438rem;
  }
}

@media (min-width: 992px) {
  .header-h3-extra-bold-lg {
    font-size: 3.063rem;
    font-weight: 800;
    line-height: 3.438rem;
  }
}

@media (min-width: 992px) {
  .header-h3-extra-bold-italic-lg {
    font-size: 3.063rem;
    font-style: italic;
    font-weight: 800;
    line-height: 3.438rem;
  }
}

@media (min-width: 992px) {
  .header-h3-black-lg {
    font-size: 3.063rem;
    font-weight: 900;
    line-height: 3.438rem;
  }
}

@media (min-width: 992px) {
  .header-h3-black-italic-lg {
    font-size: 3.063rem;
    font-style: italic;
    font-weight: 900;
    line-height: 3.438rem;
  }
}

@media (min-width: 992px) {
  .header-h4-thin-lg {
    font-size: 2.438rem;
    font-weight: 100;
    line-height: 2.875rem;
  }
}

@media (min-width: 992px) {
  .header-h4-thin-italic-lg {
    font-size: 2.438rem;
    font-style: italic;
    font-weight: 100;
    line-height: 2.875rem;
  }
}

@media (min-width: 992px) {
  .header-h4-extra-light-lg {
    font-size: 2.438rem;
    font-weight: 200;
    line-height: 2.875rem;
  }
}

@media (min-width: 992px) {
  .header-h4-extra-light-italic-lg {
    font-size: 2.438rem;
    font-style: italic;
    font-weight: 200;
    line-height: 2.875rem;
  }
}

@media (min-width: 992px) {
  .header-h4-light-lg {
    font-size: 2.438rem;
    font-weight: 300;
    line-height: 2.875rem;
  }
}

@media (min-width: 992px) {
  .header-h4-light-italic-lg {
    font-size: 2.438rem;
    font-style: italic;
    font-weight: 300;
    line-height: 2.875rem;
  }
}

@media (min-width: 992px) {
  .header-h4-regular-lg {
    font-size: 2.438rem;
    font-weight: 400;
    line-height: 2.875rem;
  }
}

@media (min-width: 992px) {
  .header-h4-regular-italic-lg {
    font-size: 2.438rem;
    font-style: italic;
    font-weight: 400;
    line-height: 2.875rem;
  }
}

@media (min-width: 992px) {
  .header-h4-medium-lg {
    font-size: 2.438rem;
    font-weight: 500;
    line-height: 2.875rem;
  }
}

@media (min-width: 992px) {
  .header-h4-medium-italic-lg {
    font-size: 2.438rem;
    font-style: italic;
    font-weight: 500;
    line-height: 2.875rem;
  }
}

@media (min-width: 992px) {
  .header-h4-semibold-lg {
    font-size: 2.438rem;
    font-weight: 600;
    line-height: 2.875rem;
  }
}

@media (min-width: 992px) {
  .header-h4-semibold-italic-lg {
    font-size: 2.438rem;
    font-style: italic;
    font-weight: 600;
    line-height: 2.875rem;
  }
}

@media (min-width: 992px) {
  .header-h4-bold-lg {
    font-size: 2.438rem;
    font-weight: 700;
    line-height: 2.875rem;
  }
}

@media (min-width: 992px) {
  .header-h4-bold-italic-lg {
    font-size: 2.438rem;
    font-style: italic;
    font-weight: 700;
    line-height: 2.875rem;
  }
}

@media (min-width: 992px) {
  .header-h4-lg, .layout-title {
    font-size: 2.438rem;
    font-weight: 700;
    line-height: 2.875rem;
  }
}

@media (min-width: 992px) {
  .header-h4-italic-lg {
    font-size: 2.438rem;
    font-style: italic;
    font-weight: 700;
    line-height: 2.875rem;
  }
}

@media (min-width: 992px) {
  .header-h4-extra-bold-lg {
    font-size: 2.438rem;
    font-weight: 800;
    line-height: 2.875rem;
  }
}

@media (min-width: 992px) {
  .header-h4-extra-bold-italic-lg {
    font-size: 2.438rem;
    font-style: italic;
    font-weight: 800;
    line-height: 2.875rem;
  }
}

@media (min-width: 992px) {
  .header-h4-black-lg {
    font-size: 2.438rem;
    font-weight: 900;
    line-height: 2.875rem;
  }
}

@media (min-width: 992px) {
  .header-h4-black-italic-lg {
    font-size: 2.438rem;
    font-style: italic;
    font-weight: 900;
    line-height: 2.875rem;
  }
}

@media (min-width: 992px) {
  .header-h5-thin-lg {
    font-size: 1.938rem;
    font-weight: 100;
    line-height: 2rem;
  }
}

@media (min-width: 992px) {
  .header-h5-thin-italic-lg {
    font-size: 1.938rem;
    font-style: italic;
    font-weight: 100;
    line-height: 2rem;
  }
}

@media (min-width: 992px) {
  .header-h5-extra-light-lg {
    font-size: 1.938rem;
    font-weight: 200;
    line-height: 2rem;
  }
}

@media (min-width: 992px) {
  .header-h5-extra-light-italic-lg {
    font-size: 1.938rem;
    font-style: italic;
    font-weight: 200;
    line-height: 2rem;
  }
}

@media (min-width: 992px) {
  .header-h5-light-lg {
    font-size: 1.938rem;
    font-weight: 300;
    line-height: 2rem;
  }
}

@media (min-width: 992px) {
  .header-h5-light-italic-lg {
    font-size: 1.938rem;
    font-style: italic;
    font-weight: 300;
    line-height: 2rem;
  }
}

@media (min-width: 992px) {
  .header-h5-regular-lg {
    font-size: 1.938rem;
    font-weight: 400;
    line-height: 2rem;
  }
}

@media (min-width: 992px) {
  .header-h5-regular-italic-lg {
    font-size: 1.938rem;
    font-style: italic;
    font-weight: 400;
    line-height: 2rem;
  }
}

@media (min-width: 992px) {
  .header-h5-medium-lg {
    font-size: 1.938rem;
    font-weight: 500;
    line-height: 2rem;
  }
}

@media (min-width: 992px) {
  .header-h5-medium-italic-lg {
    font-size: 1.938rem;
    font-style: italic;
    font-weight: 500;
    line-height: 2rem;
  }
}

@media (min-width: 992px) {
  .header-h5-semibold-lg {
    font-size: 1.938rem;
    font-weight: 600;
    line-height: 2rem;
  }
}

@media (min-width: 992px) {
  .header-h5-semibold-italic-lg {
    font-size: 1.938rem;
    font-style: italic;
    font-weight: 600;
    line-height: 2rem;
  }
}

@media (min-width: 992px) {
  .header-h5-bold-lg {
    font-size: 1.938rem;
    font-weight: 700;
    line-height: 2rem;
  }
}

@media (min-width: 992px) {
  .header-h5-bold-italic-lg {
    font-size: 1.938rem;
    font-style: italic;
    font-weight: 700;
    line-height: 2rem;
  }
}

@media (min-width: 992px) {
  .header-h5-lg {
    font-size: 1.938rem;
    font-weight: 700;
    line-height: 2rem;
  }
}

@media (min-width: 992px) {
  .header-h5-italic-lg {
    font-size: 1.938rem;
    font-style: italic;
    font-weight: 700;
    line-height: 2rem;
  }
}

@media (min-width: 992px) {
  .header-h5-extra-bold-lg {
    font-size: 1.938rem;
    font-weight: 800;
    line-height: 2rem;
  }
}

@media (min-width: 992px) {
  .header-h5-extra-bold-italic-lg {
    font-size: 1.938rem;
    font-style: italic;
    font-weight: 800;
    line-height: 2rem;
  }
}

@media (min-width: 992px) {
  .header-h5-black-lg {
    font-size: 1.938rem;
    font-weight: 900;
    line-height: 2rem;
  }
}

@media (min-width: 992px) {
  .header-h5-black-italic-lg {
    font-size: 1.938rem;
    font-style: italic;
    font-weight: 900;
    line-height: 2rem;
  }
}

@media (min-width: 992px) {
  .header-h6-thin-lg {
    font-size: 1.563rem;
    font-weight: 100;
    line-height: 2rem;
  }
}

@media (min-width: 992px) {
  .header-h6-thin-italic-lg {
    font-size: 1.563rem;
    font-style: italic;
    font-weight: 100;
    line-height: 2rem;
  }
}

@media (min-width: 992px) {
  .header-h6-extra-light-lg {
    font-size: 1.563rem;
    font-weight: 200;
    line-height: 2rem;
  }
}

@media (min-width: 992px) {
  .header-h6-extra-light-italic-lg {
    font-size: 1.563rem;
    font-style: italic;
    font-weight: 200;
    line-height: 2rem;
  }
}

@media (min-width: 992px) {
  .header-h6-light-lg {
    font-size: 1.563rem;
    font-weight: 300;
    line-height: 2rem;
  }
}

@media (min-width: 992px) {
  .header-h6-light-italic-lg {
    font-size: 1.563rem;
    font-style: italic;
    font-weight: 300;
    line-height: 2rem;
  }
}

@media (min-width: 992px) {
  .header-h6-regular-lg {
    font-size: 1.563rem;
    font-weight: 400;
    line-height: 2rem;
  }
}

@media (min-width: 992px) {
  .header-h6-regular-italic-lg {
    font-size: 1.563rem;
    font-style: italic;
    font-weight: 400;
    line-height: 2rem;
  }
}

@media (min-width: 992px) {
  .header-h6-medium-lg {
    font-size: 1.563rem;
    font-weight: 500;
    line-height: 2rem;
  }
}

@media (min-width: 992px) {
  .header-h6-medium-italic-lg {
    font-size: 1.563rem;
    font-style: italic;
    font-weight: 500;
    line-height: 2rem;
  }
}

@media (min-width: 992px) {
  .header-h6-semibold-lg {
    font-size: 1.563rem;
    font-weight: 600;
    line-height: 2rem;
  }
}

@media (min-width: 992px) {
  .header-h6-semibold-italic-lg {
    font-size: 1.563rem;
    font-style: italic;
    font-weight: 600;
    line-height: 2rem;
  }
}

@media (min-width: 992px) {
  .header-h6-bold-lg {
    font-size: 1.563rem;
    font-weight: 700;
    line-height: 2rem;
  }
}

@media (min-width: 992px) {
  .header-h6-bold-italic-lg {
    font-size: 1.563rem;
    font-style: italic;
    font-weight: 700;
    line-height: 2rem;
  }
}

@media (min-width: 992px) {
  .header-h6-lg {
    font-size: 1.563rem;
    font-weight: 700;
    line-height: 2rem;
  }
}

@media (min-width: 992px) {
  .header-h6-italic-lg {
    font-size: 1.563rem;
    font-style: italic;
    font-weight: 700;
    line-height: 2rem;
  }
}

@media (min-width: 992px) {
  .header-h6-extra-bold-lg {
    font-size: 1.563rem;
    font-weight: 800;
    line-height: 2rem;
  }
}

@media (min-width: 992px) {
  .header-h6-extra-bold-italic-lg {
    font-size: 1.563rem;
    font-style: italic;
    font-weight: 800;
    line-height: 2rem;
  }
}

@media (min-width: 992px) {
  .header-h6-black-lg {
    font-size: 1.563rem;
    font-weight: 900;
    line-height: 2rem;
  }
}

@media (min-width: 992px) {
  .header-h6-black-italic-lg {
    font-size: 1.563rem;
    font-style: italic;
    font-weight: 900;
    line-height: 2rem;
  }
}

@media (min-width: 992px) {
  .body-large-thin-lg {
    font-size: 1.25rem;
    font-weight: 100;
    line-height: 1.75rem;
  }
}

@media (min-width: 992px) {
  .body-large-thin-italic-lg {
    font-size: 1.25rem;
    font-style: italic;
    font-weight: 100;
    line-height: 1.75rem;
  }
}

@media (min-width: 992px) {
  .body-large-extra-light-lg {
    font-size: 1.25rem;
    font-weight: 200;
    line-height: 1.75rem;
  }
}

@media (min-width: 992px) {
  .body-large-extra-light-italic-lg {
    font-size: 1.25rem;
    font-style: italic;
    font-weight: 200;
    line-height: 1.75rem;
  }
}

@media (min-width: 992px) {
  .body-large-light-lg {
    font-size: 1.25rem;
    font-weight: 300;
    line-height: 1.75rem;
  }
}

@media (min-width: 992px) {
  .body-large-light-italic-lg {
    font-size: 1.25rem;
    font-style: italic;
    font-weight: 300;
    line-height: 1.75rem;
  }
}

@media (min-width: 992px) {
  .body-large-regular-lg {
    font-size: 1.25rem;
    font-weight: 400;
    line-height: 1.75rem;
  }
}

@media (min-width: 992px) {
  .body-large-regular-italic-lg {
    font-size: 1.25rem;
    font-style: italic;
    font-weight: 400;
    line-height: 1.75rem;
  }
}

@media (min-width: 992px) {
  .body-large-lg {
    font-size: 1.25rem;
    font-weight: 400;
    line-height: 1.75rem;
  }
}

@media (min-width: 992px) {
  .body-large-italic-lg {
    font-size: 1.25rem;
    font-style: italic;
    font-weight: 400;
    line-height: 1.75rem;
  }
}

@media (min-width: 992px) {
  .body-large-medium-lg {
    font-size: 1.25rem;
    font-weight: 500;
    line-height: 1.75rem;
  }
}

@media (min-width: 992px) {
  .body-large-medium-italic-lg {
    font-size: 1.25rem;
    font-style: italic;
    font-weight: 500;
    line-height: 1.75rem;
  }
}

@media (min-width: 992px) {
  .body-large-semibold-lg {
    font-size: 1.25rem;
    font-weight: 600;
    line-height: 1.75rem;
  }
}

@media (min-width: 992px) {
  .body-large-semibold-italic-lg {
    font-size: 1.25rem;
    font-style: italic;
    font-weight: 600;
    line-height: 1.75rem;
  }
}

@media (min-width: 992px) {
  .body-large-bold-lg {
    font-size: 1.25rem;
    font-weight: 700;
    line-height: 1.75rem;
  }
}

@media (min-width: 992px) {
  .body-large-bold-italic-lg {
    font-size: 1.25rem;
    font-style: italic;
    font-weight: 700;
    line-height: 1.75rem;
  }
}

@media (min-width: 992px) {
  .body-large-extra-bold-lg {
    font-size: 1.25rem;
    font-weight: 800;
    line-height: 1.75rem;
  }
}

@media (min-width: 992px) {
  .body-large-extra-bold-italic-lg {
    font-size: 1.25rem;
    font-style: italic;
    font-weight: 800;
    line-height: 1.75rem;
  }
}

@media (min-width: 992px) {
  .body-large-black-lg {
    font-size: 1.25rem;
    font-weight: 900;
    line-height: 1.75rem;
  }
}

@media (min-width: 992px) {
  .body-large-black-italic-lg {
    font-size: 1.25rem;
    font-style: italic;
    font-weight: 900;
    line-height: 1.75rem;
  }
}

@media (min-width: 992px) {
  .body-normal-thin-lg {
    font-size: 1rem;
    font-weight: 100;
    line-height: 1.5rem;
  }
}

@media (min-width: 992px) {
  .body-normal-thin-italic-lg {
    font-size: 1rem;
    font-style: italic;
    font-weight: 100;
    line-height: 1.5rem;
  }
}

@media (min-width: 992px) {
  .body-normal-extra-light-lg {
    font-size: 1rem;
    font-weight: 200;
    line-height: 1.5rem;
  }
}

@media (min-width: 992px) {
  .body-normal-extra-light-italic-lg {
    font-size: 1rem;
    font-style: italic;
    font-weight: 200;
    line-height: 1.5rem;
  }
}

@media (min-width: 992px) {
  .body-normal-light-lg {
    font-size: 1rem;
    font-weight: 300;
    line-height: 1.5rem;
  }
}

@media (min-width: 992px) {
  .body-normal-light-italic-lg {
    font-size: 1rem;
    font-style: italic;
    font-weight: 300;
    line-height: 1.5rem;
  }
}

@media (min-width: 992px) {
  .body-normal-regular-lg {
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5rem;
  }
}

@media (min-width: 992px) {
  .body-normal-regular-italic-lg {
    font-size: 1rem;
    font-style: italic;
    font-weight: 400;
    line-height: 1.5rem;
  }
}

@media (min-width: 992px) {
  .body-normal-lg {
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5rem;
  }
}

@media (min-width: 992px) {
  .body-normal-italic-lg {
    font-size: 1rem;
    font-style: italic;
    font-weight: 400;
    line-height: 1.5rem;
  }
}

@media (min-width: 992px) {
  .body-normal-medium-lg {
    font-size: 1rem;
    font-weight: 500;
    line-height: 1.5rem;
  }
}

@media (min-width: 992px) {
  .body-normal-medium-italic-lg {
    font-size: 1rem;
    font-style: italic;
    font-weight: 500;
    line-height: 1.5rem;
  }
}

@media (min-width: 992px) {
  .body-normal-semibold-lg {
    font-size: 1rem;
    font-weight: 600;
    line-height: 1.5rem;
  }
}

@media (min-width: 992px) {
  .body-normal-semibold-italic-lg {
    font-size: 1rem;
    font-style: italic;
    font-weight: 600;
    line-height: 1.5rem;
  }
}

@media (min-width: 992px) {
  .body-normal-bold-lg {
    font-size: 1rem;
    font-weight: 700;
    line-height: 1.5rem;
  }
}

@media (min-width: 992px) {
  .body-normal-bold-italic-lg {
    font-size: 1rem;
    font-style: italic;
    font-weight: 700;
    line-height: 1.5rem;
  }
}

@media (min-width: 992px) {
  .body-normal-extra-bold-lg {
    font-size: 1rem;
    font-weight: 800;
    line-height: 1.5rem;
  }
}

@media (min-width: 992px) {
  .body-normal-extra-bold-italic-lg {
    font-size: 1rem;
    font-style: italic;
    font-weight: 800;
    line-height: 1.5rem;
  }
}

@media (min-width: 992px) {
  .body-normal-black-lg {
    font-size: 1rem;
    font-weight: 900;
    line-height: 1.5rem;
  }
}

@media (min-width: 992px) {
  .body-normal-black-italic-lg {
    font-size: 1rem;
    font-style: italic;
    font-weight: 900;
    line-height: 1.5rem;
  }
}

@media (min-width: 992px) {
  .body-small-thin-lg {
    font-size: 0.875rem;
    font-weight: 100;
    line-height: 1.313rem;
  }
}

@media (min-width: 992px) {
  .body-small-thin-italic-lg {
    font-size: 0.875rem;
    font-style: italic;
    font-weight: 100;
    line-height: 1.313rem;
  }
}

@media (min-width: 992px) {
  .body-small-extra-light-lg {
    font-size: 0.875rem;
    font-weight: 200;
    line-height: 1.313rem;
  }
}

@media (min-width: 992px) {
  .body-small-extra-light-italic-lg {
    font-size: 0.875rem;
    font-style: italic;
    font-weight: 200;
    line-height: 1.313rem;
  }
}

@media (min-width: 992px) {
  .body-small-light-lg {
    font-size: 0.875rem;
    font-weight: 300;
    line-height: 1.313rem;
  }
}

@media (min-width: 992px) {
  .body-small-light-italic-lg {
    font-size: 0.875rem;
    font-style: italic;
    font-weight: 300;
    line-height: 1.313rem;
  }
}

@media (min-width: 992px) {
  .body-small-regular-lg {
    font-size: 0.875rem;
    font-weight: 400;
    line-height: 1.313rem;
  }
}

@media (min-width: 992px) {
  .body-small-regular-italic-lg {
    font-size: 0.875rem;
    font-style: italic;
    font-weight: 400;
    line-height: 1.313rem;
  }
}

@media (min-width: 992px) {
  .body-small-lg {
    font-size: 0.875rem;
    font-weight: 400;
    line-height: 1.313rem;
  }
}

@media (min-width: 992px) {
  .body-small-italic-lg {
    font-size: 0.875rem;
    font-style: italic;
    font-weight: 400;
    line-height: 1.313rem;
  }
}

@media (min-width: 992px) {
  .body-small-medium-lg {
    font-size: 0.875rem;
    font-weight: 500;
    line-height: 1.313rem;
  }
}

@media (min-width: 992px) {
  .body-small-medium-italic-lg {
    font-size: 0.875rem;
    font-style: italic;
    font-weight: 500;
    line-height: 1.313rem;
  }
}

@media (min-width: 992px) {
  .body-small-semibold-lg {
    font-size: 0.875rem;
    font-weight: 600;
    line-height: 1.313rem;
  }
}

@media (min-width: 992px) {
  .body-small-semibold-italic-lg {
    font-size: 0.875rem;
    font-style: italic;
    font-weight: 600;
    line-height: 1.313rem;
  }
}

@media (min-width: 992px) {
  .body-small-bold-lg {
    font-size: 0.875rem;
    font-weight: 700;
    line-height: 1.313rem;
  }
}

@media (min-width: 992px) {
  .body-small-bold-italic-lg {
    font-size: 0.875rem;
    font-style: italic;
    font-weight: 700;
    line-height: 1.313rem;
  }
}

@media (min-width: 992px) {
  .body-small-extra-bold-lg {
    font-size: 0.875rem;
    font-weight: 800;
    line-height: 1.313rem;
  }
}

@media (min-width: 992px) {
  .body-small-extra-bold-italic-lg {
    font-size: 0.875rem;
    font-style: italic;
    font-weight: 800;
    line-height: 1.313rem;
  }
}

@media (min-width: 992px) {
  .body-small-black-lg {
    font-size: 0.875rem;
    font-weight: 900;
    line-height: 1.313rem;
  }
}

@media (min-width: 992px) {
  .body-small-black-italic-lg {
    font-size: 0.875rem;
    font-style: italic;
    font-weight: 900;
    line-height: 1.313rem;
  }
}

@media (min-width: 992px) {
  .body-tiny-thin-lg {
    font-size: 0.75rem;
    font-weight: 100;
    line-height: 1rem;
  }
}

@media (min-width: 992px) {
  .body-tiny-thin-italic-lg {
    font-size: 0.75rem;
    font-style: italic;
    font-weight: 100;
    line-height: 1rem;
  }
}

@media (min-width: 992px) {
  .body-tiny-extra-light-lg {
    font-size: 0.75rem;
    font-weight: 200;
    line-height: 1rem;
  }
}

@media (min-width: 992px) {
  .body-tiny-extra-light-italic-lg {
    font-size: 0.75rem;
    font-style: italic;
    font-weight: 200;
    line-height: 1rem;
  }
}

@media (min-width: 992px) {
  .body-tiny-light-lg {
    font-size: 0.75rem;
    font-weight: 300;
    line-height: 1rem;
  }
}

@media (min-width: 992px) {
  .body-tiny-light-italic-lg {
    font-size: 0.75rem;
    font-style: italic;
    font-weight: 300;
    line-height: 1rem;
  }
}

@media (min-width: 992px) {
  .body-tiny-regular-lg {
    font-size: 0.75rem;
    font-weight: 400;
    line-height: 1rem;
  }
}

@media (min-width: 992px) {
  .body-tiny-regular-italic-lg {
    font-size: 0.75rem;
    font-style: italic;
    font-weight: 400;
    line-height: 1rem;
  }
}

@media (min-width: 992px) {
  .body-tiny-lg {
    font-size: 0.75rem;
    font-weight: 400;
    line-height: 1rem;
  }
}

@media (min-width: 992px) {
  .body-tiny-italic-lg {
    font-size: 0.75rem;
    font-style: italic;
    font-weight: 400;
    line-height: 1rem;
  }
}

@media (min-width: 992px) {
  .body-tiny-medium-lg {
    font-size: 0.75rem;
    font-weight: 500;
    line-height: 1rem;
  }
}

@media (min-width: 992px) {
  .body-tiny-medium-italic-lg {
    font-size: 0.75rem;
    font-style: italic;
    font-weight: 500;
    line-height: 1rem;
  }
}

@media (min-width: 992px) {
  .body-tiny-semibold-lg {
    font-size: 0.75rem;
    font-weight: 600;
    line-height: 1rem;
  }
}

@media (min-width: 992px) {
  .body-tiny-semibold-italic-lg {
    font-size: 0.75rem;
    font-style: italic;
    font-weight: 600;
    line-height: 1rem;
  }
}

@media (min-width: 992px) {
  .body-tiny-bold-lg {
    font-size: 0.75rem;
    font-weight: 700;
    line-height: 1rem;
  }
}

@media (min-width: 992px) {
  .body-tiny-bold-italic-lg {
    font-size: 0.75rem;
    font-style: italic;
    font-weight: 700;
    line-height: 1rem;
  }
}

@media (min-width: 992px) {
  .body-tiny-extra-bold-lg {
    font-size: 0.75rem;
    font-weight: 800;
    line-height: 1rem;
  }
}

@media (min-width: 992px) {
  .body-tiny-extra-bold-italic-lg {
    font-size: 0.75rem;
    font-style: italic;
    font-weight: 800;
    line-height: 1rem;
  }
}

@media (min-width: 992px) {
  .body-tiny-black-lg {
    font-size: 0.75rem;
    font-weight: 900;
    line-height: 1rem;
  }
}

@media (min-width: 992px) {
  .body-tiny-black-italic-lg {
    font-size: 0.75rem;
    font-style: italic;
    font-weight: 900;
    line-height: 1rem;
  }
}

@media (min-width: 992px) {
  .body-xs-thin-lg {
    font-size: 0.625rem;
    font-weight: 100;
    line-height: 1.313rem;
  }
}

@media (min-width: 992px) {
  .body-xs-thin-italic-lg {
    font-size: 0.625rem;
    font-style: italic;
    font-weight: 100;
    line-height: 1.313rem;
  }
}

@media (min-width: 992px) {
  .body-xs-extra-light-lg {
    font-size: 0.625rem;
    font-weight: 200;
    line-height: 1.313rem;
  }
}

@media (min-width: 992px) {
  .body-xs-extra-light-italic-lg {
    font-size: 0.625rem;
    font-style: italic;
    font-weight: 200;
    line-height: 1.313rem;
  }
}

@media (min-width: 992px) {
  .body-xs-light-lg {
    font-size: 0.625rem;
    font-weight: 300;
    line-height: 1.313rem;
  }
}

@media (min-width: 992px) {
  .body-xs-light-italic-lg {
    font-size: 0.625rem;
    font-style: italic;
    font-weight: 300;
    line-height: 1.313rem;
  }
}

@media (min-width: 992px) {
  .body-xs-regular-lg {
    font-size: 0.625rem;
    font-weight: 400;
    line-height: 1.313rem;
  }
}

@media (min-width: 992px) {
  .body-xs-regular-italic-lg {
    font-size: 0.625rem;
    font-style: italic;
    font-weight: 400;
    line-height: 1.313rem;
  }
}

@media (min-width: 992px) {
  .body-xs-lg {
    font-size: 0.625rem;
    font-weight: 400;
    line-height: 1.313rem;
  }
}

@media (min-width: 992px) {
  .body-xs-italic-lg {
    font-size: 0.625rem;
    font-style: italic;
    font-weight: 400;
    line-height: 1.313rem;
  }
}

@media (min-width: 992px) {
  .body-xs-medium-lg {
    font-size: 0.625rem;
    font-weight: 500;
    line-height: 1.313rem;
  }
}

@media (min-width: 992px) {
  .body-xs-medium-italic-lg {
    font-size: 0.625rem;
    font-style: italic;
    font-weight: 500;
    line-height: 1.313rem;
  }
}

@media (min-width: 992px) {
  .body-xs-semibold-lg {
    font-size: 0.625rem;
    font-weight: 600;
    line-height: 1.313rem;
  }
}

@media (min-width: 992px) {
  .body-xs-semibold-italic-lg {
    font-size: 0.625rem;
    font-style: italic;
    font-weight: 600;
    line-height: 1.313rem;
  }
}

@media (min-width: 992px) {
  .body-xs-bold-lg {
    font-size: 0.625rem;
    font-weight: 700;
    line-height: 1.313rem;
  }
}

@media (min-width: 992px) {
  .body-xs-bold-italic-lg {
    font-size: 0.625rem;
    font-style: italic;
    font-weight: 700;
    line-height: 1.313rem;
  }
}

@media (min-width: 992px) {
  .body-xs-extra-bold-lg {
    font-size: 0.625rem;
    font-weight: 800;
    line-height: 1.313rem;
  }
}

@media (min-width: 992px) {
  .body-xs-extra-bold-italic-lg {
    font-size: 0.625rem;
    font-style: italic;
    font-weight: 800;
    line-height: 1.313rem;
  }
}

@media (min-width: 992px) {
  .body-xs-black-lg {
    font-size: 0.625rem;
    font-weight: 900;
    line-height: 1.313rem;
  }
}

@media (min-width: 992px) {
  .body-xs-black-italic-lg {
    font-size: 0.625rem;
    font-style: italic;
    font-weight: 900;
    line-height: 1.313rem;
  }
}

@media (min-width: 1200px) {
  .header-h1-thin-xl {
    font-size: 4.75rem;
    font-weight: 100;
    line-height: 5rem;
  }
}

@media (min-width: 1200px) {
  .header-h1-thin-italic-xl {
    font-size: 4.75rem;
    font-style: italic;
    font-weight: 100;
    line-height: 5rem;
  }
}

@media (min-width: 1200px) {
  .header-h1-extra-light-xl {
    font-size: 4.75rem;
    font-weight: 200;
    line-height: 5rem;
  }
}

@media (min-width: 1200px) {
  .header-h1-extra-light-italic-xl {
    font-size: 4.75rem;
    font-style: italic;
    font-weight: 200;
    line-height: 5rem;
  }
}

@media (min-width: 1200px) {
  .header-h1-light-xl {
    font-size: 4.75rem;
    font-weight: 300;
    line-height: 5rem;
  }
}

@media (min-width: 1200px) {
  .header-h1-light-italic-xl {
    font-size: 4.75rem;
    font-style: italic;
    font-weight: 300;
    line-height: 5rem;
  }
}

@media (min-width: 1200px) {
  .header-h1-regular-xl {
    font-size: 4.75rem;
    font-weight: 400;
    line-height: 5rem;
  }
}

@media (min-width: 1200px) {
  .header-h1-regular-italic-xl {
    font-size: 4.75rem;
    font-style: italic;
    font-weight: 400;
    line-height: 5rem;
  }
}

@media (min-width: 1200px) {
  .header-h1-medium-xl {
    font-size: 4.75rem;
    font-weight: 500;
    line-height: 5rem;
  }
}

@media (min-width: 1200px) {
  .header-h1-medium-italic-xl {
    font-size: 4.75rem;
    font-style: italic;
    font-weight: 500;
    line-height: 5rem;
  }
}

@media (min-width: 1200px) {
  .header-h1-semibold-xl {
    font-size: 4.75rem;
    font-weight: 600;
    line-height: 5rem;
  }
}

@media (min-width: 1200px) {
  .header-h1-semibold-italic-xl {
    font-size: 4.75rem;
    font-style: italic;
    font-weight: 600;
    line-height: 5rem;
  }
}

@media (min-width: 1200px) {
  .header-h1-bold-xl {
    font-size: 4.75rem;
    font-weight: 700;
    line-height: 5rem;
  }
}

@media (min-width: 1200px) {
  .header-h1-bold-italic-xl {
    font-size: 4.75rem;
    font-style: italic;
    font-weight: 700;
    line-height: 5rem;
  }
}

@media (min-width: 1200px) {
  .header-h1-xl {
    font-size: 4.75rem;
    font-weight: 700;
    line-height: 5rem;
  }
}

@media (min-width: 1200px) {
  .header-h1-italic-xl {
    font-size: 4.75rem;
    font-style: italic;
    font-weight: 700;
    line-height: 5rem;
  }
}

@media (min-width: 1200px) {
  .header-h1-extra-bold-xl {
    font-size: 4.75rem;
    font-weight: 800;
    line-height: 5rem;
  }
}

@media (min-width: 1200px) {
  .header-h1-extra-bold-italic-xl {
    font-size: 4.75rem;
    font-style: italic;
    font-weight: 800;
    line-height: 5rem;
  }
}

@media (min-width: 1200px) {
  .header-h1-black-xl {
    font-size: 4.75rem;
    font-weight: 900;
    line-height: 5rem;
  }
}

@media (min-width: 1200px) {
  .header-h1-black-italic-xl {
    font-size: 4.75rem;
    font-style: italic;
    font-weight: 900;
    line-height: 5rem;
  }
}

@media (min-width: 1200px) {
  .header-h2-thin-xl {
    font-size: 3.813rem;
    font-weight: 100;
    line-height: 4.188rem;
  }
}

@media (min-width: 1200px) {
  .header-h2-thin-italic-xl {
    font-size: 3.813rem;
    font-style: italic;
    font-weight: 100;
    line-height: 4.188rem;
  }
}

@media (min-width: 1200px) {
  .header-h2-extra-light-xl {
    font-size: 3.813rem;
    font-weight: 200;
    line-height: 4.188rem;
  }
}

@media (min-width: 1200px) {
  .header-h2-extra-light-italic-xl {
    font-size: 3.813rem;
    font-style: italic;
    font-weight: 200;
    line-height: 4.188rem;
  }
}

@media (min-width: 1200px) {
  .header-h2-light-xl {
    font-size: 3.813rem;
    font-weight: 300;
    line-height: 4.188rem;
  }
}

@media (min-width: 1200px) {
  .header-h2-light-italic-xl {
    font-size: 3.813rem;
    font-style: italic;
    font-weight: 300;
    line-height: 4.188rem;
  }
}

@media (min-width: 1200px) {
  .header-h2-regular-xl {
    font-size: 3.813rem;
    font-weight: 400;
    line-height: 4.188rem;
  }
}

@media (min-width: 1200px) {
  .header-h2-regular-italic-xl {
    font-size: 3.813rem;
    font-style: italic;
    font-weight: 400;
    line-height: 4.188rem;
  }
}

@media (min-width: 1200px) {
  .header-h2-medium-xl {
    font-size: 3.813rem;
    font-weight: 500;
    line-height: 4.188rem;
  }
}

@media (min-width: 1200px) {
  .header-h2-medium-italic-xl {
    font-size: 3.813rem;
    font-style: italic;
    font-weight: 500;
    line-height: 4.188rem;
  }
}

@media (min-width: 1200px) {
  .header-h2-semibold-xl {
    font-size: 3.813rem;
    font-weight: 600;
    line-height: 4.188rem;
  }
}

@media (min-width: 1200px) {
  .header-h2-semibold-italic-xl {
    font-size: 3.813rem;
    font-style: italic;
    font-weight: 600;
    line-height: 4.188rem;
  }
}

@media (min-width: 1200px) {
  .header-h2-bold-xl {
    font-size: 3.813rem;
    font-weight: 700;
    line-height: 4.188rem;
  }
}

@media (min-width: 1200px) {
  .header-h2-bold-italic-xl {
    font-size: 3.813rem;
    font-style: italic;
    font-weight: 700;
    line-height: 4.188rem;
  }
}

@media (min-width: 1200px) {
  .header-h2-xl {
    font-size: 3.813rem;
    font-weight: 700;
    line-height: 4.188rem;
  }
}

@media (min-width: 1200px) {
  .header-h2-italic-xl {
    font-size: 3.813rem;
    font-style: italic;
    font-weight: 700;
    line-height: 4.188rem;
  }
}

@media (min-width: 1200px) {
  .header-h2-extra-bold-xl {
    font-size: 3.813rem;
    font-weight: 800;
    line-height: 4.188rem;
  }
}

@media (min-width: 1200px) {
  .header-h2-extra-bold-italic-xl {
    font-size: 3.813rem;
    font-style: italic;
    font-weight: 800;
    line-height: 4.188rem;
  }
}

@media (min-width: 1200px) {
  .header-h2-black-xl {
    font-size: 3.813rem;
    font-weight: 900;
    line-height: 4.188rem;
  }
}

@media (min-width: 1200px) {
  .header-h2-black-italic-xl {
    font-size: 3.813rem;
    font-style: italic;
    font-weight: 900;
    line-height: 4.188rem;
  }
}

@media (min-width: 1200px) {
  .header-h3-thin-xl {
    font-size: 3.063rem;
    font-weight: 100;
    line-height: 3.438rem;
  }
}

@media (min-width: 1200px) {
  .header-h3-thin-italic-xl {
    font-size: 3.063rem;
    font-style: italic;
    font-weight: 100;
    line-height: 3.438rem;
  }
}

@media (min-width: 1200px) {
  .header-h3-extra-light-xl {
    font-size: 3.063rem;
    font-weight: 200;
    line-height: 3.438rem;
  }
}

@media (min-width: 1200px) {
  .header-h3-extra-light-italic-xl {
    font-size: 3.063rem;
    font-style: italic;
    font-weight: 200;
    line-height: 3.438rem;
  }
}

@media (min-width: 1200px) {
  .header-h3-light-xl {
    font-size: 3.063rem;
    font-weight: 300;
    line-height: 3.438rem;
  }
}

@media (min-width: 1200px) {
  .header-h3-light-italic-xl {
    font-size: 3.063rem;
    font-style: italic;
    font-weight: 300;
    line-height: 3.438rem;
  }
}

@media (min-width: 1200px) {
  .header-h3-regular-xl {
    font-size: 3.063rem;
    font-weight: 400;
    line-height: 3.438rem;
  }
}

@media (min-width: 1200px) {
  .header-h3-regular-italic-xl {
    font-size: 3.063rem;
    font-style: italic;
    font-weight: 400;
    line-height: 3.438rem;
  }
}

@media (min-width: 1200px) {
  .header-h3-medium-xl {
    font-size: 3.063rem;
    font-weight: 500;
    line-height: 3.438rem;
  }
}

@media (min-width: 1200px) {
  .header-h3-medium-italic-xl {
    font-size: 3.063rem;
    font-style: italic;
    font-weight: 500;
    line-height: 3.438rem;
  }
}

@media (min-width: 1200px) {
  .header-h3-semibold-xl {
    font-size: 3.063rem;
    font-weight: 600;
    line-height: 3.438rem;
  }
}

@media (min-width: 1200px) {
  .header-h3-semibold-italic-xl {
    font-size: 3.063rem;
    font-style: italic;
    font-weight: 600;
    line-height: 3.438rem;
  }
}

@media (min-width: 1200px) {
  .header-h3-bold-xl {
    font-size: 3.063rem;
    font-weight: 700;
    line-height: 3.438rem;
  }
}

@media (min-width: 1200px) {
  .header-h3-bold-italic-xl {
    font-size: 3.063rem;
    font-style: italic;
    font-weight: 700;
    line-height: 3.438rem;
  }
}

@media (min-width: 1200px) {
  .header-h3-xl {
    font-size: 3.063rem;
    font-weight: 700;
    line-height: 3.438rem;
  }
}

@media (min-width: 1200px) {
  .header-h3-italic-xl {
    font-size: 3.063rem;
    font-style: italic;
    font-weight: 700;
    line-height: 3.438rem;
  }
}

@media (min-width: 1200px) {
  .header-h3-extra-bold-xl {
    font-size: 3.063rem;
    font-weight: 800;
    line-height: 3.438rem;
  }
}

@media (min-width: 1200px) {
  .header-h3-extra-bold-italic-xl {
    font-size: 3.063rem;
    font-style: italic;
    font-weight: 800;
    line-height: 3.438rem;
  }
}

@media (min-width: 1200px) {
  .header-h3-black-xl {
    font-size: 3.063rem;
    font-weight: 900;
    line-height: 3.438rem;
  }
}

@media (min-width: 1200px) {
  .header-h3-black-italic-xl {
    font-size: 3.063rem;
    font-style: italic;
    font-weight: 900;
    line-height: 3.438rem;
  }
}

@media (min-width: 1200px) {
  .header-h4-thin-xl {
    font-size: 2.438rem;
    font-weight: 100;
    line-height: 2.875rem;
  }
}

@media (min-width: 1200px) {
  .header-h4-thin-italic-xl {
    font-size: 2.438rem;
    font-style: italic;
    font-weight: 100;
    line-height: 2.875rem;
  }
}

@media (min-width: 1200px) {
  .header-h4-extra-light-xl {
    font-size: 2.438rem;
    font-weight: 200;
    line-height: 2.875rem;
  }
}

@media (min-width: 1200px) {
  .header-h4-extra-light-italic-xl {
    font-size: 2.438rem;
    font-style: italic;
    font-weight: 200;
    line-height: 2.875rem;
  }
}

@media (min-width: 1200px) {
  .header-h4-light-xl {
    font-size: 2.438rem;
    font-weight: 300;
    line-height: 2.875rem;
  }
}

@media (min-width: 1200px) {
  .header-h4-light-italic-xl {
    font-size: 2.438rem;
    font-style: italic;
    font-weight: 300;
    line-height: 2.875rem;
  }
}

@media (min-width: 1200px) {
  .header-h4-regular-xl {
    font-size: 2.438rem;
    font-weight: 400;
    line-height: 2.875rem;
  }
}

@media (min-width: 1200px) {
  .header-h4-regular-italic-xl {
    font-size: 2.438rem;
    font-style: italic;
    font-weight: 400;
    line-height: 2.875rem;
  }
}

@media (min-width: 1200px) {
  .header-h4-medium-xl {
    font-size: 2.438rem;
    font-weight: 500;
    line-height: 2.875rem;
  }
}

@media (min-width: 1200px) {
  .header-h4-medium-italic-xl {
    font-size: 2.438rem;
    font-style: italic;
    font-weight: 500;
    line-height: 2.875rem;
  }
}

@media (min-width: 1200px) {
  .header-h4-semibold-xl {
    font-size: 2.438rem;
    font-weight: 600;
    line-height: 2.875rem;
  }
}

@media (min-width: 1200px) {
  .header-h4-semibold-italic-xl {
    font-size: 2.438rem;
    font-style: italic;
    font-weight: 600;
    line-height: 2.875rem;
  }
}

@media (min-width: 1200px) {
  .header-h4-bold-xl {
    font-size: 2.438rem;
    font-weight: 700;
    line-height: 2.875rem;
  }
}

@media (min-width: 1200px) {
  .header-h4-bold-italic-xl {
    font-size: 2.438rem;
    font-style: italic;
    font-weight: 700;
    line-height: 2.875rem;
  }
}

@media (min-width: 1200px) {
  .header-h4-xl {
    font-size: 2.438rem;
    font-weight: 700;
    line-height: 2.875rem;
  }
}

@media (min-width: 1200px) {
  .header-h4-italic-xl {
    font-size: 2.438rem;
    font-style: italic;
    font-weight: 700;
    line-height: 2.875rem;
  }
}

@media (min-width: 1200px) {
  .header-h4-extra-bold-xl {
    font-size: 2.438rem;
    font-weight: 800;
    line-height: 2.875rem;
  }
}

@media (min-width: 1200px) {
  .header-h4-extra-bold-italic-xl {
    font-size: 2.438rem;
    font-style: italic;
    font-weight: 800;
    line-height: 2.875rem;
  }
}

@media (min-width: 1200px) {
  .header-h4-black-xl {
    font-size: 2.438rem;
    font-weight: 900;
    line-height: 2.875rem;
  }
}

@media (min-width: 1200px) {
  .header-h4-black-italic-xl {
    font-size: 2.438rem;
    font-style: italic;
    font-weight: 900;
    line-height: 2.875rem;
  }
}

@media (min-width: 1200px) {
  .header-h5-thin-xl {
    font-size: 1.938rem;
    font-weight: 100;
    line-height: 2rem;
  }
}

@media (min-width: 1200px) {
  .header-h5-thin-italic-xl {
    font-size: 1.938rem;
    font-style: italic;
    font-weight: 100;
    line-height: 2rem;
  }
}

@media (min-width: 1200px) {
  .header-h5-extra-light-xl {
    font-size: 1.938rem;
    font-weight: 200;
    line-height: 2rem;
  }
}

@media (min-width: 1200px) {
  .header-h5-extra-light-italic-xl {
    font-size: 1.938rem;
    font-style: italic;
    font-weight: 200;
    line-height: 2rem;
  }
}

@media (min-width: 1200px) {
  .header-h5-light-xl {
    font-size: 1.938rem;
    font-weight: 300;
    line-height: 2rem;
  }
}

@media (min-width: 1200px) {
  .header-h5-light-italic-xl {
    font-size: 1.938rem;
    font-style: italic;
    font-weight: 300;
    line-height: 2rem;
  }
}

@media (min-width: 1200px) {
  .header-h5-regular-xl {
    font-size: 1.938rem;
    font-weight: 400;
    line-height: 2rem;
  }
}

@media (min-width: 1200px) {
  .header-h5-regular-italic-xl {
    font-size: 1.938rem;
    font-style: italic;
    font-weight: 400;
    line-height: 2rem;
  }
}

@media (min-width: 1200px) {
  .header-h5-medium-xl {
    font-size: 1.938rem;
    font-weight: 500;
    line-height: 2rem;
  }
}

@media (min-width: 1200px) {
  .header-h5-medium-italic-xl {
    font-size: 1.938rem;
    font-style: italic;
    font-weight: 500;
    line-height: 2rem;
  }
}

@media (min-width: 1200px) {
  .header-h5-semibold-xl {
    font-size: 1.938rem;
    font-weight: 600;
    line-height: 2rem;
  }
}

@media (min-width: 1200px) {
  .header-h5-semibold-italic-xl {
    font-size: 1.938rem;
    font-style: italic;
    font-weight: 600;
    line-height: 2rem;
  }
}

@media (min-width: 1200px) {
  .header-h5-bold-xl {
    font-size: 1.938rem;
    font-weight: 700;
    line-height: 2rem;
  }
}

@media (min-width: 1200px) {
  .header-h5-bold-italic-xl {
    font-size: 1.938rem;
    font-style: italic;
    font-weight: 700;
    line-height: 2rem;
  }
}

@media (min-width: 1200px) {
  .header-h5-xl {
    font-size: 1.938rem;
    font-weight: 700;
    line-height: 2rem;
  }
}

@media (min-width: 1200px) {
  .header-h5-italic-xl {
    font-size: 1.938rem;
    font-style: italic;
    font-weight: 700;
    line-height: 2rem;
  }
}

@media (min-width: 1200px) {
  .header-h5-extra-bold-xl {
    font-size: 1.938rem;
    font-weight: 800;
    line-height: 2rem;
  }
}

@media (min-width: 1200px) {
  .header-h5-extra-bold-italic-xl {
    font-size: 1.938rem;
    font-style: italic;
    font-weight: 800;
    line-height: 2rem;
  }
}

@media (min-width: 1200px) {
  .header-h5-black-xl {
    font-size: 1.938rem;
    font-weight: 900;
    line-height: 2rem;
  }
}

@media (min-width: 1200px) {
  .header-h5-black-italic-xl {
    font-size: 1.938rem;
    font-style: italic;
    font-weight: 900;
    line-height: 2rem;
  }
}

@media (min-width: 1200px) {
  .header-h6-thin-xl {
    font-size: 1.563rem;
    font-weight: 100;
    line-height: 2rem;
  }
}

@media (min-width: 1200px) {
  .header-h6-thin-italic-xl {
    font-size: 1.563rem;
    font-style: italic;
    font-weight: 100;
    line-height: 2rem;
  }
}

@media (min-width: 1200px) {
  .header-h6-extra-light-xl {
    font-size: 1.563rem;
    font-weight: 200;
    line-height: 2rem;
  }
}

@media (min-width: 1200px) {
  .header-h6-extra-light-italic-xl {
    font-size: 1.563rem;
    font-style: italic;
    font-weight: 200;
    line-height: 2rem;
  }
}

@media (min-width: 1200px) {
  .header-h6-light-xl {
    font-size: 1.563rem;
    font-weight: 300;
    line-height: 2rem;
  }
}

@media (min-width: 1200px) {
  .header-h6-light-italic-xl {
    font-size: 1.563rem;
    font-style: italic;
    font-weight: 300;
    line-height: 2rem;
  }
}

@media (min-width: 1200px) {
  .header-h6-regular-xl {
    font-size: 1.563rem;
    font-weight: 400;
    line-height: 2rem;
  }
}

@media (min-width: 1200px) {
  .header-h6-regular-italic-xl {
    font-size: 1.563rem;
    font-style: italic;
    font-weight: 400;
    line-height: 2rem;
  }
}

@media (min-width: 1200px) {
  .header-h6-medium-xl {
    font-size: 1.563rem;
    font-weight: 500;
    line-height: 2rem;
  }
}

@media (min-width: 1200px) {
  .header-h6-medium-italic-xl {
    font-size: 1.563rem;
    font-style: italic;
    font-weight: 500;
    line-height: 2rem;
  }
}

@media (min-width: 1200px) {
  .header-h6-semibold-xl {
    font-size: 1.563rem;
    font-weight: 600;
    line-height: 2rem;
  }
}

@media (min-width: 1200px) {
  .header-h6-semibold-italic-xl {
    font-size: 1.563rem;
    font-style: italic;
    font-weight: 600;
    line-height: 2rem;
  }
}

@media (min-width: 1200px) {
  .header-h6-bold-xl {
    font-size: 1.563rem;
    font-weight: 700;
    line-height: 2rem;
  }
}

@media (min-width: 1200px) {
  .header-h6-bold-italic-xl {
    font-size: 1.563rem;
    font-style: italic;
    font-weight: 700;
    line-height: 2rem;
  }
}

@media (min-width: 1200px) {
  .header-h6-xl {
    font-size: 1.563rem;
    font-weight: 700;
    line-height: 2rem;
  }
}

@media (min-width: 1200px) {
  .header-h6-italic-xl {
    font-size: 1.563rem;
    font-style: italic;
    font-weight: 700;
    line-height: 2rem;
  }
}

@media (min-width: 1200px) {
  .header-h6-extra-bold-xl {
    font-size: 1.563rem;
    font-weight: 800;
    line-height: 2rem;
  }
}

@media (min-width: 1200px) {
  .header-h6-extra-bold-italic-xl {
    font-size: 1.563rem;
    font-style: italic;
    font-weight: 800;
    line-height: 2rem;
  }
}

@media (min-width: 1200px) {
  .header-h6-black-xl {
    font-size: 1.563rem;
    font-weight: 900;
    line-height: 2rem;
  }
}

@media (min-width: 1200px) {
  .header-h6-black-italic-xl {
    font-size: 1.563rem;
    font-style: italic;
    font-weight: 900;
    line-height: 2rem;
  }
}

@media (min-width: 1200px) {
  .body-large-thin-xl {
    font-size: 1.25rem;
    font-weight: 100;
    line-height: 1.75rem;
  }
}

@media (min-width: 1200px) {
  .body-large-thin-italic-xl {
    font-size: 1.25rem;
    font-style: italic;
    font-weight: 100;
    line-height: 1.75rem;
  }
}

@media (min-width: 1200px) {
  .body-large-extra-light-xl {
    font-size: 1.25rem;
    font-weight: 200;
    line-height: 1.75rem;
  }
}

@media (min-width: 1200px) {
  .body-large-extra-light-italic-xl {
    font-size: 1.25rem;
    font-style: italic;
    font-weight: 200;
    line-height: 1.75rem;
  }
}

@media (min-width: 1200px) {
  .body-large-light-xl {
    font-size: 1.25rem;
    font-weight: 300;
    line-height: 1.75rem;
  }
}

@media (min-width: 1200px) {
  .body-large-light-italic-xl {
    font-size: 1.25rem;
    font-style: italic;
    font-weight: 300;
    line-height: 1.75rem;
  }
}

@media (min-width: 1200px) {
  .body-large-regular-xl {
    font-size: 1.25rem;
    font-weight: 400;
    line-height: 1.75rem;
  }
}

@media (min-width: 1200px) {
  .body-large-regular-italic-xl {
    font-size: 1.25rem;
    font-style: italic;
    font-weight: 400;
    line-height: 1.75rem;
  }
}

@media (min-width: 1200px) {
  .body-large-xl {
    font-size: 1.25rem;
    font-weight: 400;
    line-height: 1.75rem;
  }
}

@media (min-width: 1200px) {
  .body-large-italic-xl {
    font-size: 1.25rem;
    font-style: italic;
    font-weight: 400;
    line-height: 1.75rem;
  }
}

@media (min-width: 1200px) {
  .body-large-medium-xl {
    font-size: 1.25rem;
    font-weight: 500;
    line-height: 1.75rem;
  }
}

@media (min-width: 1200px) {
  .body-large-medium-italic-xl {
    font-size: 1.25rem;
    font-style: italic;
    font-weight: 500;
    line-height: 1.75rem;
  }
}

@media (min-width: 1200px) {
  .body-large-semibold-xl {
    font-size: 1.25rem;
    font-weight: 600;
    line-height: 1.75rem;
  }
}

@media (min-width: 1200px) {
  .body-large-semibold-italic-xl {
    font-size: 1.25rem;
    font-style: italic;
    font-weight: 600;
    line-height: 1.75rem;
  }
}

@media (min-width: 1200px) {
  .body-large-bold-xl {
    font-size: 1.25rem;
    font-weight: 700;
    line-height: 1.75rem;
  }
}

@media (min-width: 1200px) {
  .body-large-bold-italic-xl {
    font-size: 1.25rem;
    font-style: italic;
    font-weight: 700;
    line-height: 1.75rem;
  }
}

@media (min-width: 1200px) {
  .body-large-extra-bold-xl {
    font-size: 1.25rem;
    font-weight: 800;
    line-height: 1.75rem;
  }
}

@media (min-width: 1200px) {
  .body-large-extra-bold-italic-xl {
    font-size: 1.25rem;
    font-style: italic;
    font-weight: 800;
    line-height: 1.75rem;
  }
}

@media (min-width: 1200px) {
  .body-large-black-xl {
    font-size: 1.25rem;
    font-weight: 900;
    line-height: 1.75rem;
  }
}

@media (min-width: 1200px) {
  .body-large-black-italic-xl {
    font-size: 1.25rem;
    font-style: italic;
    font-weight: 900;
    line-height: 1.75rem;
  }
}

@media (min-width: 1200px) {
  .body-normal-thin-xl {
    font-size: 1rem;
    font-weight: 100;
    line-height: 1.5rem;
  }
}

@media (min-width: 1200px) {
  .body-normal-thin-italic-xl {
    font-size: 1rem;
    font-style: italic;
    font-weight: 100;
    line-height: 1.5rem;
  }
}

@media (min-width: 1200px) {
  .body-normal-extra-light-xl {
    font-size: 1rem;
    font-weight: 200;
    line-height: 1.5rem;
  }
}

@media (min-width: 1200px) {
  .body-normal-extra-light-italic-xl {
    font-size: 1rem;
    font-style: italic;
    font-weight: 200;
    line-height: 1.5rem;
  }
}

@media (min-width: 1200px) {
  .body-normal-light-xl {
    font-size: 1rem;
    font-weight: 300;
    line-height: 1.5rem;
  }
}

@media (min-width: 1200px) {
  .body-normal-light-italic-xl {
    font-size: 1rem;
    font-style: italic;
    font-weight: 300;
    line-height: 1.5rem;
  }
}

@media (min-width: 1200px) {
  .body-normal-regular-xl {
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5rem;
  }
}

@media (min-width: 1200px) {
  .body-normal-regular-italic-xl {
    font-size: 1rem;
    font-style: italic;
    font-weight: 400;
    line-height: 1.5rem;
  }
}

@media (min-width: 1200px) {
  .body-normal-xl {
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5rem;
  }
}

@media (min-width: 1200px) {
  .body-normal-italic-xl {
    font-size: 1rem;
    font-style: italic;
    font-weight: 400;
    line-height: 1.5rem;
  }
}

@media (min-width: 1200px) {
  .body-normal-medium-xl {
    font-size: 1rem;
    font-weight: 500;
    line-height: 1.5rem;
  }
}

@media (min-width: 1200px) {
  .body-normal-medium-italic-xl {
    font-size: 1rem;
    font-style: italic;
    font-weight: 500;
    line-height: 1.5rem;
  }
}

@media (min-width: 1200px) {
  .body-normal-semibold-xl {
    font-size: 1rem;
    font-weight: 600;
    line-height: 1.5rem;
  }
}

@media (min-width: 1200px) {
  .body-normal-semibold-italic-xl {
    font-size: 1rem;
    font-style: italic;
    font-weight: 600;
    line-height: 1.5rem;
  }
}

@media (min-width: 1200px) {
  .body-normal-bold-xl {
    font-size: 1rem;
    font-weight: 700;
    line-height: 1.5rem;
  }
}

@media (min-width: 1200px) {
  .body-normal-bold-italic-xl {
    font-size: 1rem;
    font-style: italic;
    font-weight: 700;
    line-height: 1.5rem;
  }
}

@media (min-width: 1200px) {
  .body-normal-extra-bold-xl {
    font-size: 1rem;
    font-weight: 800;
    line-height: 1.5rem;
  }
}

@media (min-width: 1200px) {
  .body-normal-extra-bold-italic-xl {
    font-size: 1rem;
    font-style: italic;
    font-weight: 800;
    line-height: 1.5rem;
  }
}

@media (min-width: 1200px) {
  .body-normal-black-xl {
    font-size: 1rem;
    font-weight: 900;
    line-height: 1.5rem;
  }
}

@media (min-width: 1200px) {
  .body-normal-black-italic-xl {
    font-size: 1rem;
    font-style: italic;
    font-weight: 900;
    line-height: 1.5rem;
  }
}

@media (min-width: 1200px) {
  .body-small-thin-xl {
    font-size: 0.875rem;
    font-weight: 100;
    line-height: 1.313rem;
  }
}

@media (min-width: 1200px) {
  .body-small-thin-italic-xl {
    font-size: 0.875rem;
    font-style: italic;
    font-weight: 100;
    line-height: 1.313rem;
  }
}

@media (min-width: 1200px) {
  .body-small-extra-light-xl {
    font-size: 0.875rem;
    font-weight: 200;
    line-height: 1.313rem;
  }
}

@media (min-width: 1200px) {
  .body-small-extra-light-italic-xl {
    font-size: 0.875rem;
    font-style: italic;
    font-weight: 200;
    line-height: 1.313rem;
  }
}

@media (min-width: 1200px) {
  .body-small-light-xl {
    font-size: 0.875rem;
    font-weight: 300;
    line-height: 1.313rem;
  }
}

@media (min-width: 1200px) {
  .body-small-light-italic-xl {
    font-size: 0.875rem;
    font-style: italic;
    font-weight: 300;
    line-height: 1.313rem;
  }
}

@media (min-width: 1200px) {
  .body-small-regular-xl {
    font-size: 0.875rem;
    font-weight: 400;
    line-height: 1.313rem;
  }
}

@media (min-width: 1200px) {
  .body-small-regular-italic-xl {
    font-size: 0.875rem;
    font-style: italic;
    font-weight: 400;
    line-height: 1.313rem;
  }
}

@media (min-width: 1200px) {
  .body-small-xl {
    font-size: 0.875rem;
    font-weight: 400;
    line-height: 1.313rem;
  }
}

@media (min-width: 1200px) {
  .body-small-italic-xl {
    font-size: 0.875rem;
    font-style: italic;
    font-weight: 400;
    line-height: 1.313rem;
  }
}

@media (min-width: 1200px) {
  .body-small-medium-xl {
    font-size: 0.875rem;
    font-weight: 500;
    line-height: 1.313rem;
  }
}

@media (min-width: 1200px) {
  .body-small-medium-italic-xl {
    font-size: 0.875rem;
    font-style: italic;
    font-weight: 500;
    line-height: 1.313rem;
  }
}

@media (min-width: 1200px) {
  .body-small-semibold-xl {
    font-size: 0.875rem;
    font-weight: 600;
    line-height: 1.313rem;
  }
}

@media (min-width: 1200px) {
  .body-small-semibold-italic-xl {
    font-size: 0.875rem;
    font-style: italic;
    font-weight: 600;
    line-height: 1.313rem;
  }
}

@media (min-width: 1200px) {
  .body-small-bold-xl {
    font-size: 0.875rem;
    font-weight: 700;
    line-height: 1.313rem;
  }
}

@media (min-width: 1200px) {
  .body-small-bold-italic-xl {
    font-size: 0.875rem;
    font-style: italic;
    font-weight: 700;
    line-height: 1.313rem;
  }
}

@media (min-width: 1200px) {
  .body-small-extra-bold-xl {
    font-size: 0.875rem;
    font-weight: 800;
    line-height: 1.313rem;
  }
}

@media (min-width: 1200px) {
  .body-small-extra-bold-italic-xl {
    font-size: 0.875rem;
    font-style: italic;
    font-weight: 800;
    line-height: 1.313rem;
  }
}

@media (min-width: 1200px) {
  .body-small-black-xl {
    font-size: 0.875rem;
    font-weight: 900;
    line-height: 1.313rem;
  }
}

@media (min-width: 1200px) {
  .body-small-black-italic-xl {
    font-size: 0.875rem;
    font-style: italic;
    font-weight: 900;
    line-height: 1.313rem;
  }
}

@media (min-width: 1200px) {
  .body-tiny-thin-xl {
    font-size: 0.75rem;
    font-weight: 100;
    line-height: 1rem;
  }
}

@media (min-width: 1200px) {
  .body-tiny-thin-italic-xl {
    font-size: 0.75rem;
    font-style: italic;
    font-weight: 100;
    line-height: 1rem;
  }
}

@media (min-width: 1200px) {
  .body-tiny-extra-light-xl {
    font-size: 0.75rem;
    font-weight: 200;
    line-height: 1rem;
  }
}

@media (min-width: 1200px) {
  .body-tiny-extra-light-italic-xl {
    font-size: 0.75rem;
    font-style: italic;
    font-weight: 200;
    line-height: 1rem;
  }
}

@media (min-width: 1200px) {
  .body-tiny-light-xl {
    font-size: 0.75rem;
    font-weight: 300;
    line-height: 1rem;
  }
}

@media (min-width: 1200px) {
  .body-tiny-light-italic-xl {
    font-size: 0.75rem;
    font-style: italic;
    font-weight: 300;
    line-height: 1rem;
  }
}

@media (min-width: 1200px) {
  .body-tiny-regular-xl {
    font-size: 0.75rem;
    font-weight: 400;
    line-height: 1rem;
  }
}

@media (min-width: 1200px) {
  .body-tiny-regular-italic-xl {
    font-size: 0.75rem;
    font-style: italic;
    font-weight: 400;
    line-height: 1rem;
  }
}

@media (min-width: 1200px) {
  .body-tiny-xl {
    font-size: 0.75rem;
    font-weight: 400;
    line-height: 1rem;
  }
}

@media (min-width: 1200px) {
  .body-tiny-italic-xl {
    font-size: 0.75rem;
    font-style: italic;
    font-weight: 400;
    line-height: 1rem;
  }
}

@media (min-width: 1200px) {
  .body-tiny-medium-xl {
    font-size: 0.75rem;
    font-weight: 500;
    line-height: 1rem;
  }
}

@media (min-width: 1200px) {
  .body-tiny-medium-italic-xl {
    font-size: 0.75rem;
    font-style: italic;
    font-weight: 500;
    line-height: 1rem;
  }
}

@media (min-width: 1200px) {
  .body-tiny-semibold-xl {
    font-size: 0.75rem;
    font-weight: 600;
    line-height: 1rem;
  }
}

@media (min-width: 1200px) {
  .body-tiny-semibold-italic-xl {
    font-size: 0.75rem;
    font-style: italic;
    font-weight: 600;
    line-height: 1rem;
  }
}

@media (min-width: 1200px) {
  .body-tiny-bold-xl {
    font-size: 0.75rem;
    font-weight: 700;
    line-height: 1rem;
  }
}

@media (min-width: 1200px) {
  .body-tiny-bold-italic-xl {
    font-size: 0.75rem;
    font-style: italic;
    font-weight: 700;
    line-height: 1rem;
  }
}

@media (min-width: 1200px) {
  .body-tiny-extra-bold-xl {
    font-size: 0.75rem;
    font-weight: 800;
    line-height: 1rem;
  }
}

@media (min-width: 1200px) {
  .body-tiny-extra-bold-italic-xl {
    font-size: 0.75rem;
    font-style: italic;
    font-weight: 800;
    line-height: 1rem;
  }
}

@media (min-width: 1200px) {
  .body-tiny-black-xl {
    font-size: 0.75rem;
    font-weight: 900;
    line-height: 1rem;
  }
}

@media (min-width: 1200px) {
  .body-tiny-black-italic-xl {
    font-size: 0.75rem;
    font-style: italic;
    font-weight: 900;
    line-height: 1rem;
  }
}

@media (min-width: 1200px) {
  .body-xs-thin-xl {
    font-size: 0.625rem;
    font-weight: 100;
    line-height: 1.313rem;
  }
}

@media (min-width: 1200px) {
  .body-xs-thin-italic-xl {
    font-size: 0.625rem;
    font-style: italic;
    font-weight: 100;
    line-height: 1.313rem;
  }
}

@media (min-width: 1200px) {
  .body-xs-extra-light-xl {
    font-size: 0.625rem;
    font-weight: 200;
    line-height: 1.313rem;
  }
}

@media (min-width: 1200px) {
  .body-xs-extra-light-italic-xl {
    font-size: 0.625rem;
    font-style: italic;
    font-weight: 200;
    line-height: 1.313rem;
  }
}

@media (min-width: 1200px) {
  .body-xs-light-xl {
    font-size: 0.625rem;
    font-weight: 300;
    line-height: 1.313rem;
  }
}

@media (min-width: 1200px) {
  .body-xs-light-italic-xl {
    font-size: 0.625rem;
    font-style: italic;
    font-weight: 300;
    line-height: 1.313rem;
  }
}

@media (min-width: 1200px) {
  .body-xs-regular-xl {
    font-size: 0.625rem;
    font-weight: 400;
    line-height: 1.313rem;
  }
}

@media (min-width: 1200px) {
  .body-xs-regular-italic-xl {
    font-size: 0.625rem;
    font-style: italic;
    font-weight: 400;
    line-height: 1.313rem;
  }
}

@media (min-width: 1200px) {
  .body-xs-xl {
    font-size: 0.625rem;
    font-weight: 400;
    line-height: 1.313rem;
  }
}

@media (min-width: 1200px) {
  .body-xs-italic-xl {
    font-size: 0.625rem;
    font-style: italic;
    font-weight: 400;
    line-height: 1.313rem;
  }
}

@media (min-width: 1200px) {
  .body-xs-medium-xl {
    font-size: 0.625rem;
    font-weight: 500;
    line-height: 1.313rem;
  }
}

@media (min-width: 1200px) {
  .body-xs-medium-italic-xl {
    font-size: 0.625rem;
    font-style: italic;
    font-weight: 500;
    line-height: 1.313rem;
  }
}

@media (min-width: 1200px) {
  .body-xs-semibold-xl {
    font-size: 0.625rem;
    font-weight: 600;
    line-height: 1.313rem;
  }
}

@media (min-width: 1200px) {
  .body-xs-semibold-italic-xl {
    font-size: 0.625rem;
    font-style: italic;
    font-weight: 600;
    line-height: 1.313rem;
  }
}

@media (min-width: 1200px) {
  .body-xs-bold-xl {
    font-size: 0.625rem;
    font-weight: 700;
    line-height: 1.313rem;
  }
}

@media (min-width: 1200px) {
  .body-xs-bold-italic-xl {
    font-size: 0.625rem;
    font-style: italic;
    font-weight: 700;
    line-height: 1.313rem;
  }
}

@media (min-width: 1200px) {
  .body-xs-extra-bold-xl {
    font-size: 0.625rem;
    font-weight: 800;
    line-height: 1.313rem;
  }
}

@media (min-width: 1200px) {
  .body-xs-extra-bold-italic-xl {
    font-size: 0.625rem;
    font-style: italic;
    font-weight: 800;
    line-height: 1.313rem;
  }
}

@media (min-width: 1200px) {
  .body-xs-black-xl {
    font-size: 0.625rem;
    font-weight: 900;
    line-height: 1.313rem;
  }
}

@media (min-width: 1200px) {
  .body-xs-black-italic-xl {
    font-size: 0.625rem;
    font-style: italic;
    font-weight: 900;
    line-height: 1.313rem;
  }
}

@media (min-width: 1400px) {
  .header-h1-thin-xxl {
    font-size: 4.75rem;
    font-weight: 100;
    line-height: 5rem;
  }
}

@media (min-width: 1400px) {
  .header-h1-thin-italic-xxl {
    font-size: 4.75rem;
    font-style: italic;
    font-weight: 100;
    line-height: 5rem;
  }
}

@media (min-width: 1400px) {
  .header-h1-extra-light-xxl {
    font-size: 4.75rem;
    font-weight: 200;
    line-height: 5rem;
  }
}

@media (min-width: 1400px) {
  .header-h1-extra-light-italic-xxl {
    font-size: 4.75rem;
    font-style: italic;
    font-weight: 200;
    line-height: 5rem;
  }
}

@media (min-width: 1400px) {
  .header-h1-light-xxl {
    font-size: 4.75rem;
    font-weight: 300;
    line-height: 5rem;
  }
}

@media (min-width: 1400px) {
  .header-h1-light-italic-xxl {
    font-size: 4.75rem;
    font-style: italic;
    font-weight: 300;
    line-height: 5rem;
  }
}

@media (min-width: 1400px) {
  .header-h1-regular-xxl {
    font-size: 4.75rem;
    font-weight: 400;
    line-height: 5rem;
  }
}

@media (min-width: 1400px) {
  .header-h1-regular-italic-xxl {
    font-size: 4.75rem;
    font-style: italic;
    font-weight: 400;
    line-height: 5rem;
  }
}

@media (min-width: 1400px) {
  .header-h1-medium-xxl {
    font-size: 4.75rem;
    font-weight: 500;
    line-height: 5rem;
  }
}

@media (min-width: 1400px) {
  .header-h1-medium-italic-xxl {
    font-size: 4.75rem;
    font-style: italic;
    font-weight: 500;
    line-height: 5rem;
  }
}

@media (min-width: 1400px) {
  .header-h1-semibold-xxl {
    font-size: 4.75rem;
    font-weight: 600;
    line-height: 5rem;
  }
}

@media (min-width: 1400px) {
  .header-h1-semibold-italic-xxl {
    font-size: 4.75rem;
    font-style: italic;
    font-weight: 600;
    line-height: 5rem;
  }
}

@media (min-width: 1400px) {
  .header-h1-bold-xxl {
    font-size: 4.75rem;
    font-weight: 700;
    line-height: 5rem;
  }
}

@media (min-width: 1400px) {
  .header-h1-bold-italic-xxl {
    font-size: 4.75rem;
    font-style: italic;
    font-weight: 700;
    line-height: 5rem;
  }
}

@media (min-width: 1400px) {
  .header-h1-xxl {
    font-size: 4.75rem;
    font-weight: 700;
    line-height: 5rem;
  }
}

@media (min-width: 1400px) {
  .header-h1-italic-xxl {
    font-size: 4.75rem;
    font-style: italic;
    font-weight: 700;
    line-height: 5rem;
  }
}

@media (min-width: 1400px) {
  .header-h1-extra-bold-xxl {
    font-size: 4.75rem;
    font-weight: 800;
    line-height: 5rem;
  }
}

@media (min-width: 1400px) {
  .header-h1-extra-bold-italic-xxl {
    font-size: 4.75rem;
    font-style: italic;
    font-weight: 800;
    line-height: 5rem;
  }
}

@media (min-width: 1400px) {
  .header-h1-black-xxl {
    font-size: 4.75rem;
    font-weight: 900;
    line-height: 5rem;
  }
}

@media (min-width: 1400px) {
  .header-h1-black-italic-xxl {
    font-size: 4.75rem;
    font-style: italic;
    font-weight: 900;
    line-height: 5rem;
  }
}

@media (min-width: 1400px) {
  .header-h2-thin-xxl {
    font-size: 3.813rem;
    font-weight: 100;
    line-height: 4.188rem;
  }
}

@media (min-width: 1400px) {
  .header-h2-thin-italic-xxl {
    font-size: 3.813rem;
    font-style: italic;
    font-weight: 100;
    line-height: 4.188rem;
  }
}

@media (min-width: 1400px) {
  .header-h2-extra-light-xxl {
    font-size: 3.813rem;
    font-weight: 200;
    line-height: 4.188rem;
  }
}

@media (min-width: 1400px) {
  .header-h2-extra-light-italic-xxl {
    font-size: 3.813rem;
    font-style: italic;
    font-weight: 200;
    line-height: 4.188rem;
  }
}

@media (min-width: 1400px) {
  .header-h2-light-xxl {
    font-size: 3.813rem;
    font-weight: 300;
    line-height: 4.188rem;
  }
}

@media (min-width: 1400px) {
  .header-h2-light-italic-xxl {
    font-size: 3.813rem;
    font-style: italic;
    font-weight: 300;
    line-height: 4.188rem;
  }
}

@media (min-width: 1400px) {
  .header-h2-regular-xxl {
    font-size: 3.813rem;
    font-weight: 400;
    line-height: 4.188rem;
  }
}

@media (min-width: 1400px) {
  .header-h2-regular-italic-xxl {
    font-size: 3.813rem;
    font-style: italic;
    font-weight: 400;
    line-height: 4.188rem;
  }
}

@media (min-width: 1400px) {
  .header-h2-medium-xxl {
    font-size: 3.813rem;
    font-weight: 500;
    line-height: 4.188rem;
  }
}

@media (min-width: 1400px) {
  .header-h2-medium-italic-xxl {
    font-size: 3.813rem;
    font-style: italic;
    font-weight: 500;
    line-height: 4.188rem;
  }
}

@media (min-width: 1400px) {
  .header-h2-semibold-xxl {
    font-size: 3.813rem;
    font-weight: 600;
    line-height: 4.188rem;
  }
}

@media (min-width: 1400px) {
  .header-h2-semibold-italic-xxl {
    font-size: 3.813rem;
    font-style: italic;
    font-weight: 600;
    line-height: 4.188rem;
  }
}

@media (min-width: 1400px) {
  .header-h2-bold-xxl {
    font-size: 3.813rem;
    font-weight: 700;
    line-height: 4.188rem;
  }
}

@media (min-width: 1400px) {
  .header-h2-bold-italic-xxl {
    font-size: 3.813rem;
    font-style: italic;
    font-weight: 700;
    line-height: 4.188rem;
  }
}

@media (min-width: 1400px) {
  .header-h2-xxl {
    font-size: 3.813rem;
    font-weight: 700;
    line-height: 4.188rem;
  }
}

@media (min-width: 1400px) {
  .header-h2-italic-xxl {
    font-size: 3.813rem;
    font-style: italic;
    font-weight: 700;
    line-height: 4.188rem;
  }
}

@media (min-width: 1400px) {
  .header-h2-extra-bold-xxl {
    font-size: 3.813rem;
    font-weight: 800;
    line-height: 4.188rem;
  }
}

@media (min-width: 1400px) {
  .header-h2-extra-bold-italic-xxl {
    font-size: 3.813rem;
    font-style: italic;
    font-weight: 800;
    line-height: 4.188rem;
  }
}

@media (min-width: 1400px) {
  .header-h2-black-xxl {
    font-size: 3.813rem;
    font-weight: 900;
    line-height: 4.188rem;
  }
}

@media (min-width: 1400px) {
  .header-h2-black-italic-xxl {
    font-size: 3.813rem;
    font-style: italic;
    font-weight: 900;
    line-height: 4.188rem;
  }
}

@media (min-width: 1400px) {
  .header-h3-thin-xxl {
    font-size: 3.063rem;
    font-weight: 100;
    line-height: 3.438rem;
  }
}

@media (min-width: 1400px) {
  .header-h3-thin-italic-xxl {
    font-size: 3.063rem;
    font-style: italic;
    font-weight: 100;
    line-height: 3.438rem;
  }
}

@media (min-width: 1400px) {
  .header-h3-extra-light-xxl {
    font-size: 3.063rem;
    font-weight: 200;
    line-height: 3.438rem;
  }
}

@media (min-width: 1400px) {
  .header-h3-extra-light-italic-xxl {
    font-size: 3.063rem;
    font-style: italic;
    font-weight: 200;
    line-height: 3.438rem;
  }
}

@media (min-width: 1400px) {
  .header-h3-light-xxl {
    font-size: 3.063rem;
    font-weight: 300;
    line-height: 3.438rem;
  }
}

@media (min-width: 1400px) {
  .header-h3-light-italic-xxl {
    font-size: 3.063rem;
    font-style: italic;
    font-weight: 300;
    line-height: 3.438rem;
  }
}

@media (min-width: 1400px) {
  .header-h3-regular-xxl {
    font-size: 3.063rem;
    font-weight: 400;
    line-height: 3.438rem;
  }
}

@media (min-width: 1400px) {
  .header-h3-regular-italic-xxl {
    font-size: 3.063rem;
    font-style: italic;
    font-weight: 400;
    line-height: 3.438rem;
  }
}

@media (min-width: 1400px) {
  .header-h3-medium-xxl {
    font-size: 3.063rem;
    font-weight: 500;
    line-height: 3.438rem;
  }
}

@media (min-width: 1400px) {
  .header-h3-medium-italic-xxl {
    font-size: 3.063rem;
    font-style: italic;
    font-weight: 500;
    line-height: 3.438rem;
  }
}

@media (min-width: 1400px) {
  .header-h3-semibold-xxl {
    font-size: 3.063rem;
    font-weight: 600;
    line-height: 3.438rem;
  }
}

@media (min-width: 1400px) {
  .header-h3-semibold-italic-xxl {
    font-size: 3.063rem;
    font-style: italic;
    font-weight: 600;
    line-height: 3.438rem;
  }
}

@media (min-width: 1400px) {
  .header-h3-bold-xxl {
    font-size: 3.063rem;
    font-weight: 700;
    line-height: 3.438rem;
  }
}

@media (min-width: 1400px) {
  .header-h3-bold-italic-xxl {
    font-size: 3.063rem;
    font-style: italic;
    font-weight: 700;
    line-height: 3.438rem;
  }
}

@media (min-width: 1400px) {
  .header-h3-xxl {
    font-size: 3.063rem;
    font-weight: 700;
    line-height: 3.438rem;
  }
}

@media (min-width: 1400px) {
  .header-h3-italic-xxl {
    font-size: 3.063rem;
    font-style: italic;
    font-weight: 700;
    line-height: 3.438rem;
  }
}

@media (min-width: 1400px) {
  .header-h3-extra-bold-xxl {
    font-size: 3.063rem;
    font-weight: 800;
    line-height: 3.438rem;
  }
}

@media (min-width: 1400px) {
  .header-h3-extra-bold-italic-xxl {
    font-size: 3.063rem;
    font-style: italic;
    font-weight: 800;
    line-height: 3.438rem;
  }
}

@media (min-width: 1400px) {
  .header-h3-black-xxl {
    font-size: 3.063rem;
    font-weight: 900;
    line-height: 3.438rem;
  }
}

@media (min-width: 1400px) {
  .header-h3-black-italic-xxl {
    font-size: 3.063rem;
    font-style: italic;
    font-weight: 900;
    line-height: 3.438rem;
  }
}

@media (min-width: 1400px) {
  .header-h4-thin-xxl {
    font-size: 2.438rem;
    font-weight: 100;
    line-height: 2.875rem;
  }
}

@media (min-width: 1400px) {
  .header-h4-thin-italic-xxl {
    font-size: 2.438rem;
    font-style: italic;
    font-weight: 100;
    line-height: 2.875rem;
  }
}

@media (min-width: 1400px) {
  .header-h4-extra-light-xxl {
    font-size: 2.438rem;
    font-weight: 200;
    line-height: 2.875rem;
  }
}

@media (min-width: 1400px) {
  .header-h4-extra-light-italic-xxl {
    font-size: 2.438rem;
    font-style: italic;
    font-weight: 200;
    line-height: 2.875rem;
  }
}

@media (min-width: 1400px) {
  .header-h4-light-xxl {
    font-size: 2.438rem;
    font-weight: 300;
    line-height: 2.875rem;
  }
}

@media (min-width: 1400px) {
  .header-h4-light-italic-xxl {
    font-size: 2.438rem;
    font-style: italic;
    font-weight: 300;
    line-height: 2.875rem;
  }
}

@media (min-width: 1400px) {
  .header-h4-regular-xxl {
    font-size: 2.438rem;
    font-weight: 400;
    line-height: 2.875rem;
  }
}

@media (min-width: 1400px) {
  .header-h4-regular-italic-xxl {
    font-size: 2.438rem;
    font-style: italic;
    font-weight: 400;
    line-height: 2.875rem;
  }
}

@media (min-width: 1400px) {
  .header-h4-medium-xxl {
    font-size: 2.438rem;
    font-weight: 500;
    line-height: 2.875rem;
  }
}

@media (min-width: 1400px) {
  .header-h4-medium-italic-xxl {
    font-size: 2.438rem;
    font-style: italic;
    font-weight: 500;
    line-height: 2.875rem;
  }
}

@media (min-width: 1400px) {
  .header-h4-semibold-xxl {
    font-size: 2.438rem;
    font-weight: 600;
    line-height: 2.875rem;
  }
}

@media (min-width: 1400px) {
  .header-h4-semibold-italic-xxl {
    font-size: 2.438rem;
    font-style: italic;
    font-weight: 600;
    line-height: 2.875rem;
  }
}

@media (min-width: 1400px) {
  .header-h4-bold-xxl {
    font-size: 2.438rem;
    font-weight: 700;
    line-height: 2.875rem;
  }
}

@media (min-width: 1400px) {
  .header-h4-bold-italic-xxl {
    font-size: 2.438rem;
    font-style: italic;
    font-weight: 700;
    line-height: 2.875rem;
  }
}

@media (min-width: 1400px) {
  .header-h4-xxl {
    font-size: 2.438rem;
    font-weight: 700;
    line-height: 2.875rem;
  }
}

@media (min-width: 1400px) {
  .header-h4-italic-xxl {
    font-size: 2.438rem;
    font-style: italic;
    font-weight: 700;
    line-height: 2.875rem;
  }
}

@media (min-width: 1400px) {
  .header-h4-extra-bold-xxl {
    font-size: 2.438rem;
    font-weight: 800;
    line-height: 2.875rem;
  }
}

@media (min-width: 1400px) {
  .header-h4-extra-bold-italic-xxl {
    font-size: 2.438rem;
    font-style: italic;
    font-weight: 800;
    line-height: 2.875rem;
  }
}

@media (min-width: 1400px) {
  .header-h4-black-xxl {
    font-size: 2.438rem;
    font-weight: 900;
    line-height: 2.875rem;
  }
}

@media (min-width: 1400px) {
  .header-h4-black-italic-xxl {
    font-size: 2.438rem;
    font-style: italic;
    font-weight: 900;
    line-height: 2.875rem;
  }
}

@media (min-width: 1400px) {
  .header-h5-thin-xxl {
    font-size: 1.938rem;
    font-weight: 100;
    line-height: 2rem;
  }
}

@media (min-width: 1400px) {
  .header-h5-thin-italic-xxl {
    font-size: 1.938rem;
    font-style: italic;
    font-weight: 100;
    line-height: 2rem;
  }
}

@media (min-width: 1400px) {
  .header-h5-extra-light-xxl {
    font-size: 1.938rem;
    font-weight: 200;
    line-height: 2rem;
  }
}

@media (min-width: 1400px) {
  .header-h5-extra-light-italic-xxl {
    font-size: 1.938rem;
    font-style: italic;
    font-weight: 200;
    line-height: 2rem;
  }
}

@media (min-width: 1400px) {
  .header-h5-light-xxl {
    font-size: 1.938rem;
    font-weight: 300;
    line-height: 2rem;
  }
}

@media (min-width: 1400px) {
  .header-h5-light-italic-xxl {
    font-size: 1.938rem;
    font-style: italic;
    font-weight: 300;
    line-height: 2rem;
  }
}

@media (min-width: 1400px) {
  .header-h5-regular-xxl {
    font-size: 1.938rem;
    font-weight: 400;
    line-height: 2rem;
  }
}

@media (min-width: 1400px) {
  .header-h5-regular-italic-xxl {
    font-size: 1.938rem;
    font-style: italic;
    font-weight: 400;
    line-height: 2rem;
  }
}

@media (min-width: 1400px) {
  .header-h5-medium-xxl {
    font-size: 1.938rem;
    font-weight: 500;
    line-height: 2rem;
  }
}

@media (min-width: 1400px) {
  .header-h5-medium-italic-xxl {
    font-size: 1.938rem;
    font-style: italic;
    font-weight: 500;
    line-height: 2rem;
  }
}

@media (min-width: 1400px) {
  .header-h5-semibold-xxl {
    font-size: 1.938rem;
    font-weight: 600;
    line-height: 2rem;
  }
}

@media (min-width: 1400px) {
  .header-h5-semibold-italic-xxl {
    font-size: 1.938rem;
    font-style: italic;
    font-weight: 600;
    line-height: 2rem;
  }
}

@media (min-width: 1400px) {
  .header-h5-bold-xxl {
    font-size: 1.938rem;
    font-weight: 700;
    line-height: 2rem;
  }
}

@media (min-width: 1400px) {
  .header-h5-bold-italic-xxl {
    font-size: 1.938rem;
    font-style: italic;
    font-weight: 700;
    line-height: 2rem;
  }
}

@media (min-width: 1400px) {
  .header-h5-xxl {
    font-size: 1.938rem;
    font-weight: 700;
    line-height: 2rem;
  }
}

@media (min-width: 1400px) {
  .header-h5-italic-xxl {
    font-size: 1.938rem;
    font-style: italic;
    font-weight: 700;
    line-height: 2rem;
  }
}

@media (min-width: 1400px) {
  .header-h5-extra-bold-xxl {
    font-size: 1.938rem;
    font-weight: 800;
    line-height: 2rem;
  }
}

@media (min-width: 1400px) {
  .header-h5-extra-bold-italic-xxl {
    font-size: 1.938rem;
    font-style: italic;
    font-weight: 800;
    line-height: 2rem;
  }
}

@media (min-width: 1400px) {
  .header-h5-black-xxl {
    font-size: 1.938rem;
    font-weight: 900;
    line-height: 2rem;
  }
}

@media (min-width: 1400px) {
  .header-h5-black-italic-xxl {
    font-size: 1.938rem;
    font-style: italic;
    font-weight: 900;
    line-height: 2rem;
  }
}

@media (min-width: 1400px) {
  .header-h6-thin-xxl {
    font-size: 1.563rem;
    font-weight: 100;
    line-height: 2rem;
  }
}

@media (min-width: 1400px) {
  .header-h6-thin-italic-xxl {
    font-size: 1.563rem;
    font-style: italic;
    font-weight: 100;
    line-height: 2rem;
  }
}

@media (min-width: 1400px) {
  .header-h6-extra-light-xxl {
    font-size: 1.563rem;
    font-weight: 200;
    line-height: 2rem;
  }
}

@media (min-width: 1400px) {
  .header-h6-extra-light-italic-xxl {
    font-size: 1.563rem;
    font-style: italic;
    font-weight: 200;
    line-height: 2rem;
  }
}

@media (min-width: 1400px) {
  .header-h6-light-xxl {
    font-size: 1.563rem;
    font-weight: 300;
    line-height: 2rem;
  }
}

@media (min-width: 1400px) {
  .header-h6-light-italic-xxl {
    font-size: 1.563rem;
    font-style: italic;
    font-weight: 300;
    line-height: 2rem;
  }
}

@media (min-width: 1400px) {
  .header-h6-regular-xxl {
    font-size: 1.563rem;
    font-weight: 400;
    line-height: 2rem;
  }
}

@media (min-width: 1400px) {
  .header-h6-regular-italic-xxl {
    font-size: 1.563rem;
    font-style: italic;
    font-weight: 400;
    line-height: 2rem;
  }
}

@media (min-width: 1400px) {
  .header-h6-medium-xxl {
    font-size: 1.563rem;
    font-weight: 500;
    line-height: 2rem;
  }
}

@media (min-width: 1400px) {
  .header-h6-medium-italic-xxl {
    font-size: 1.563rem;
    font-style: italic;
    font-weight: 500;
    line-height: 2rem;
  }
}

@media (min-width: 1400px) {
  .header-h6-semibold-xxl {
    font-size: 1.563rem;
    font-weight: 600;
    line-height: 2rem;
  }
}

@media (min-width: 1400px) {
  .header-h6-semibold-italic-xxl {
    font-size: 1.563rem;
    font-style: italic;
    font-weight: 600;
    line-height: 2rem;
  }
}

@media (min-width: 1400px) {
  .header-h6-bold-xxl {
    font-size: 1.563rem;
    font-weight: 700;
    line-height: 2rem;
  }
}

@media (min-width: 1400px) {
  .header-h6-bold-italic-xxl {
    font-size: 1.563rem;
    font-style: italic;
    font-weight: 700;
    line-height: 2rem;
  }
}

@media (min-width: 1400px) {
  .header-h6-xxl {
    font-size: 1.563rem;
    font-weight: 700;
    line-height: 2rem;
  }
}

@media (min-width: 1400px) {
  .header-h6-italic-xxl {
    font-size: 1.563rem;
    font-style: italic;
    font-weight: 700;
    line-height: 2rem;
  }
}

@media (min-width: 1400px) {
  .header-h6-extra-bold-xxl {
    font-size: 1.563rem;
    font-weight: 800;
    line-height: 2rem;
  }
}

@media (min-width: 1400px) {
  .header-h6-extra-bold-italic-xxl {
    font-size: 1.563rem;
    font-style: italic;
    font-weight: 800;
    line-height: 2rem;
  }
}

@media (min-width: 1400px) {
  .header-h6-black-xxl {
    font-size: 1.563rem;
    font-weight: 900;
    line-height: 2rem;
  }
}

@media (min-width: 1400px) {
  .header-h6-black-italic-xxl {
    font-size: 1.563rem;
    font-style: italic;
    font-weight: 900;
    line-height: 2rem;
  }
}

@media (min-width: 1400px) {
  .body-large-thin-xxl {
    font-size: 1.25rem;
    font-weight: 100;
    line-height: 1.75rem;
  }
}

@media (min-width: 1400px) {
  .body-large-thin-italic-xxl {
    font-size: 1.25rem;
    font-style: italic;
    font-weight: 100;
    line-height: 1.75rem;
  }
}

@media (min-width: 1400px) {
  .body-large-extra-light-xxl {
    font-size: 1.25rem;
    font-weight: 200;
    line-height: 1.75rem;
  }
}

@media (min-width: 1400px) {
  .body-large-extra-light-italic-xxl {
    font-size: 1.25rem;
    font-style: italic;
    font-weight: 200;
    line-height: 1.75rem;
  }
}

@media (min-width: 1400px) {
  .body-large-light-xxl {
    font-size: 1.25rem;
    font-weight: 300;
    line-height: 1.75rem;
  }
}

@media (min-width: 1400px) {
  .body-large-light-italic-xxl {
    font-size: 1.25rem;
    font-style: italic;
    font-weight: 300;
    line-height: 1.75rem;
  }
}

@media (min-width: 1400px) {
  .body-large-regular-xxl {
    font-size: 1.25rem;
    font-weight: 400;
    line-height: 1.75rem;
  }
}

@media (min-width: 1400px) {
  .body-large-regular-italic-xxl {
    font-size: 1.25rem;
    font-style: italic;
    font-weight: 400;
    line-height: 1.75rem;
  }
}

@media (min-width: 1400px) {
  .body-large-xxl {
    font-size: 1.25rem;
    font-weight: 400;
    line-height: 1.75rem;
  }
}

@media (min-width: 1400px) {
  .body-large-italic-xxl {
    font-size: 1.25rem;
    font-style: italic;
    font-weight: 400;
    line-height: 1.75rem;
  }
}

@media (min-width: 1400px) {
  .body-large-medium-xxl {
    font-size: 1.25rem;
    font-weight: 500;
    line-height: 1.75rem;
  }
}

@media (min-width: 1400px) {
  .body-large-medium-italic-xxl {
    font-size: 1.25rem;
    font-style: italic;
    font-weight: 500;
    line-height: 1.75rem;
  }
}

@media (min-width: 1400px) {
  .body-large-semibold-xxl {
    font-size: 1.25rem;
    font-weight: 600;
    line-height: 1.75rem;
  }
}

@media (min-width: 1400px) {
  .body-large-semibold-italic-xxl {
    font-size: 1.25rem;
    font-style: italic;
    font-weight: 600;
    line-height: 1.75rem;
  }
}

@media (min-width: 1400px) {
  .body-large-bold-xxl {
    font-size: 1.25rem;
    font-weight: 700;
    line-height: 1.75rem;
  }
}

@media (min-width: 1400px) {
  .body-large-bold-italic-xxl {
    font-size: 1.25rem;
    font-style: italic;
    font-weight: 700;
    line-height: 1.75rem;
  }
}

@media (min-width: 1400px) {
  .body-large-extra-bold-xxl {
    font-size: 1.25rem;
    font-weight: 800;
    line-height: 1.75rem;
  }
}

@media (min-width: 1400px) {
  .body-large-extra-bold-italic-xxl {
    font-size: 1.25rem;
    font-style: italic;
    font-weight: 800;
    line-height: 1.75rem;
  }
}

@media (min-width: 1400px) {
  .body-large-black-xxl {
    font-size: 1.25rem;
    font-weight: 900;
    line-height: 1.75rem;
  }
}

@media (min-width: 1400px) {
  .body-large-black-italic-xxl {
    font-size: 1.25rem;
    font-style: italic;
    font-weight: 900;
    line-height: 1.75rem;
  }
}

@media (min-width: 1400px) {
  .body-normal-thin-xxl {
    font-size: 1rem;
    font-weight: 100;
    line-height: 1.5rem;
  }
}

@media (min-width: 1400px) {
  .body-normal-thin-italic-xxl {
    font-size: 1rem;
    font-style: italic;
    font-weight: 100;
    line-height: 1.5rem;
  }
}

@media (min-width: 1400px) {
  .body-normal-extra-light-xxl {
    font-size: 1rem;
    font-weight: 200;
    line-height: 1.5rem;
  }
}

@media (min-width: 1400px) {
  .body-normal-extra-light-italic-xxl {
    font-size: 1rem;
    font-style: italic;
    font-weight: 200;
    line-height: 1.5rem;
  }
}

@media (min-width: 1400px) {
  .body-normal-light-xxl {
    font-size: 1rem;
    font-weight: 300;
    line-height: 1.5rem;
  }
}

@media (min-width: 1400px) {
  .body-normal-light-italic-xxl {
    font-size: 1rem;
    font-style: italic;
    font-weight: 300;
    line-height: 1.5rem;
  }
}

@media (min-width: 1400px) {
  .body-normal-regular-xxl {
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5rem;
  }
}

@media (min-width: 1400px) {
  .body-normal-regular-italic-xxl {
    font-size: 1rem;
    font-style: italic;
    font-weight: 400;
    line-height: 1.5rem;
  }
}

@media (min-width: 1400px) {
  .body-normal-xxl {
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5rem;
  }
}

@media (min-width: 1400px) {
  .body-normal-italic-xxl {
    font-size: 1rem;
    font-style: italic;
    font-weight: 400;
    line-height: 1.5rem;
  }
}

@media (min-width: 1400px) {
  .body-normal-medium-xxl {
    font-size: 1rem;
    font-weight: 500;
    line-height: 1.5rem;
  }
}

@media (min-width: 1400px) {
  .body-normal-medium-italic-xxl {
    font-size: 1rem;
    font-style: italic;
    font-weight: 500;
    line-height: 1.5rem;
  }
}

@media (min-width: 1400px) {
  .body-normal-semibold-xxl {
    font-size: 1rem;
    font-weight: 600;
    line-height: 1.5rem;
  }
}

@media (min-width: 1400px) {
  .body-normal-semibold-italic-xxl {
    font-size: 1rem;
    font-style: italic;
    font-weight: 600;
    line-height: 1.5rem;
  }
}

@media (min-width: 1400px) {
  .body-normal-bold-xxl {
    font-size: 1rem;
    font-weight: 700;
    line-height: 1.5rem;
  }
}

@media (min-width: 1400px) {
  .body-normal-bold-italic-xxl {
    font-size: 1rem;
    font-style: italic;
    font-weight: 700;
    line-height: 1.5rem;
  }
}

@media (min-width: 1400px) {
  .body-normal-extra-bold-xxl {
    font-size: 1rem;
    font-weight: 800;
    line-height: 1.5rem;
  }
}

@media (min-width: 1400px) {
  .body-normal-extra-bold-italic-xxl {
    font-size: 1rem;
    font-style: italic;
    font-weight: 800;
    line-height: 1.5rem;
  }
}

@media (min-width: 1400px) {
  .body-normal-black-xxl {
    font-size: 1rem;
    font-weight: 900;
    line-height: 1.5rem;
  }
}

@media (min-width: 1400px) {
  .body-normal-black-italic-xxl {
    font-size: 1rem;
    font-style: italic;
    font-weight: 900;
    line-height: 1.5rem;
  }
}

@media (min-width: 1400px) {
  .body-small-thin-xxl {
    font-size: 0.875rem;
    font-weight: 100;
    line-height: 1.313rem;
  }
}

@media (min-width: 1400px) {
  .body-small-thin-italic-xxl {
    font-size: 0.875rem;
    font-style: italic;
    font-weight: 100;
    line-height: 1.313rem;
  }
}

@media (min-width: 1400px) {
  .body-small-extra-light-xxl {
    font-size: 0.875rem;
    font-weight: 200;
    line-height: 1.313rem;
  }
}

@media (min-width: 1400px) {
  .body-small-extra-light-italic-xxl {
    font-size: 0.875rem;
    font-style: italic;
    font-weight: 200;
    line-height: 1.313rem;
  }
}

@media (min-width: 1400px) {
  .body-small-light-xxl {
    font-size: 0.875rem;
    font-weight: 300;
    line-height: 1.313rem;
  }
}

@media (min-width: 1400px) {
  .body-small-light-italic-xxl {
    font-size: 0.875rem;
    font-style: italic;
    font-weight: 300;
    line-height: 1.313rem;
  }
}

@media (min-width: 1400px) {
  .body-small-regular-xxl {
    font-size: 0.875rem;
    font-weight: 400;
    line-height: 1.313rem;
  }
}

@media (min-width: 1400px) {
  .body-small-regular-italic-xxl {
    font-size: 0.875rem;
    font-style: italic;
    font-weight: 400;
    line-height: 1.313rem;
  }
}

@media (min-width: 1400px) {
  .body-small-xxl {
    font-size: 0.875rem;
    font-weight: 400;
    line-height: 1.313rem;
  }
}

@media (min-width: 1400px) {
  .body-small-italic-xxl {
    font-size: 0.875rem;
    font-style: italic;
    font-weight: 400;
    line-height: 1.313rem;
  }
}

@media (min-width: 1400px) {
  .body-small-medium-xxl {
    font-size: 0.875rem;
    font-weight: 500;
    line-height: 1.313rem;
  }
}

@media (min-width: 1400px) {
  .body-small-medium-italic-xxl {
    font-size: 0.875rem;
    font-style: italic;
    font-weight: 500;
    line-height: 1.313rem;
  }
}

@media (min-width: 1400px) {
  .body-small-semibold-xxl {
    font-size: 0.875rem;
    font-weight: 600;
    line-height: 1.313rem;
  }
}

@media (min-width: 1400px) {
  .body-small-semibold-italic-xxl {
    font-size: 0.875rem;
    font-style: italic;
    font-weight: 600;
    line-height: 1.313rem;
  }
}

@media (min-width: 1400px) {
  .body-small-bold-xxl {
    font-size: 0.875rem;
    font-weight: 700;
    line-height: 1.313rem;
  }
}

@media (min-width: 1400px) {
  .body-small-bold-italic-xxl {
    font-size: 0.875rem;
    font-style: italic;
    font-weight: 700;
    line-height: 1.313rem;
  }
}

@media (min-width: 1400px) {
  .body-small-extra-bold-xxl {
    font-size: 0.875rem;
    font-weight: 800;
    line-height: 1.313rem;
  }
}

@media (min-width: 1400px) {
  .body-small-extra-bold-italic-xxl {
    font-size: 0.875rem;
    font-style: italic;
    font-weight: 800;
    line-height: 1.313rem;
  }
}

@media (min-width: 1400px) {
  .body-small-black-xxl {
    font-size: 0.875rem;
    font-weight: 900;
    line-height: 1.313rem;
  }
}

@media (min-width: 1400px) {
  .body-small-black-italic-xxl {
    font-size: 0.875rem;
    font-style: italic;
    font-weight: 900;
    line-height: 1.313rem;
  }
}

@media (min-width: 1400px) {
  .body-tiny-thin-xxl {
    font-size: 0.75rem;
    font-weight: 100;
    line-height: 1rem;
  }
}

@media (min-width: 1400px) {
  .body-tiny-thin-italic-xxl {
    font-size: 0.75rem;
    font-style: italic;
    font-weight: 100;
    line-height: 1rem;
  }
}

@media (min-width: 1400px) {
  .body-tiny-extra-light-xxl {
    font-size: 0.75rem;
    font-weight: 200;
    line-height: 1rem;
  }
}

@media (min-width: 1400px) {
  .body-tiny-extra-light-italic-xxl {
    font-size: 0.75rem;
    font-style: italic;
    font-weight: 200;
    line-height: 1rem;
  }
}

@media (min-width: 1400px) {
  .body-tiny-light-xxl {
    font-size: 0.75rem;
    font-weight: 300;
    line-height: 1rem;
  }
}

@media (min-width: 1400px) {
  .body-tiny-light-italic-xxl {
    font-size: 0.75rem;
    font-style: italic;
    font-weight: 300;
    line-height: 1rem;
  }
}

@media (min-width: 1400px) {
  .body-tiny-regular-xxl {
    font-size: 0.75rem;
    font-weight: 400;
    line-height: 1rem;
  }
}

@media (min-width: 1400px) {
  .body-tiny-regular-italic-xxl {
    font-size: 0.75rem;
    font-style: italic;
    font-weight: 400;
    line-height: 1rem;
  }
}

@media (min-width: 1400px) {
  .body-tiny-xxl {
    font-size: 0.75rem;
    font-weight: 400;
    line-height: 1rem;
  }
}

@media (min-width: 1400px) {
  .body-tiny-italic-xxl {
    font-size: 0.75rem;
    font-style: italic;
    font-weight: 400;
    line-height: 1rem;
  }
}

@media (min-width: 1400px) {
  .body-tiny-medium-xxl {
    font-size: 0.75rem;
    font-weight: 500;
    line-height: 1rem;
  }
}

@media (min-width: 1400px) {
  .body-tiny-medium-italic-xxl {
    font-size: 0.75rem;
    font-style: italic;
    font-weight: 500;
    line-height: 1rem;
  }
}

@media (min-width: 1400px) {
  .body-tiny-semibold-xxl {
    font-size: 0.75rem;
    font-weight: 600;
    line-height: 1rem;
  }
}

@media (min-width: 1400px) {
  .body-tiny-semibold-italic-xxl {
    font-size: 0.75rem;
    font-style: italic;
    font-weight: 600;
    line-height: 1rem;
  }
}

@media (min-width: 1400px) {
  .body-tiny-bold-xxl {
    font-size: 0.75rem;
    font-weight: 700;
    line-height: 1rem;
  }
}

@media (min-width: 1400px) {
  .body-tiny-bold-italic-xxl {
    font-size: 0.75rem;
    font-style: italic;
    font-weight: 700;
    line-height: 1rem;
  }
}

@media (min-width: 1400px) {
  .body-tiny-extra-bold-xxl {
    font-size: 0.75rem;
    font-weight: 800;
    line-height: 1rem;
  }
}

@media (min-width: 1400px) {
  .body-tiny-extra-bold-italic-xxl {
    font-size: 0.75rem;
    font-style: italic;
    font-weight: 800;
    line-height: 1rem;
  }
}

@media (min-width: 1400px) {
  .body-tiny-black-xxl {
    font-size: 0.75rem;
    font-weight: 900;
    line-height: 1rem;
  }
}

@media (min-width: 1400px) {
  .body-tiny-black-italic-xxl {
    font-size: 0.75rem;
    font-style: italic;
    font-weight: 900;
    line-height: 1rem;
  }
}

@media (min-width: 1400px) {
  .body-xs-thin-xxl {
    font-size: 0.625rem;
    font-weight: 100;
    line-height: 1.313rem;
  }
}

@media (min-width: 1400px) {
  .body-xs-thin-italic-xxl {
    font-size: 0.625rem;
    font-style: italic;
    font-weight: 100;
    line-height: 1.313rem;
  }
}

@media (min-width: 1400px) {
  .body-xs-extra-light-xxl {
    font-size: 0.625rem;
    font-weight: 200;
    line-height: 1.313rem;
  }
}

@media (min-width: 1400px) {
  .body-xs-extra-light-italic-xxl {
    font-size: 0.625rem;
    font-style: italic;
    font-weight: 200;
    line-height: 1.313rem;
  }
}

@media (min-width: 1400px) {
  .body-xs-light-xxl {
    font-size: 0.625rem;
    font-weight: 300;
    line-height: 1.313rem;
  }
}

@media (min-width: 1400px) {
  .body-xs-light-italic-xxl {
    font-size: 0.625rem;
    font-style: italic;
    font-weight: 300;
    line-height: 1.313rem;
  }
}

@media (min-width: 1400px) {
  .body-xs-regular-xxl {
    font-size: 0.625rem;
    font-weight: 400;
    line-height: 1.313rem;
  }
}

@media (min-width: 1400px) {
  .body-xs-regular-italic-xxl {
    font-size: 0.625rem;
    font-style: italic;
    font-weight: 400;
    line-height: 1.313rem;
  }
}

@media (min-width: 1400px) {
  .body-xs-xxl {
    font-size: 0.625rem;
    font-weight: 400;
    line-height: 1.313rem;
  }
}

@media (min-width: 1400px) {
  .body-xs-italic-xxl {
    font-size: 0.625rem;
    font-style: italic;
    font-weight: 400;
    line-height: 1.313rem;
  }
}

@media (min-width: 1400px) {
  .body-xs-medium-xxl {
    font-size: 0.625rem;
    font-weight: 500;
    line-height: 1.313rem;
  }
}

@media (min-width: 1400px) {
  .body-xs-medium-italic-xxl {
    font-size: 0.625rem;
    font-style: italic;
    font-weight: 500;
    line-height: 1.313rem;
  }
}

@media (min-width: 1400px) {
  .body-xs-semibold-xxl {
    font-size: 0.625rem;
    font-weight: 600;
    line-height: 1.313rem;
  }
}

@media (min-width: 1400px) {
  .body-xs-semibold-italic-xxl {
    font-size: 0.625rem;
    font-style: italic;
    font-weight: 600;
    line-height: 1.313rem;
  }
}

@media (min-width: 1400px) {
  .body-xs-bold-xxl {
    font-size: 0.625rem;
    font-weight: 700;
    line-height: 1.313rem;
  }
}

@media (min-width: 1400px) {
  .body-xs-bold-italic-xxl {
    font-size: 0.625rem;
    font-style: italic;
    font-weight: 700;
    line-height: 1.313rem;
  }
}

@media (min-width: 1400px) {
  .body-xs-extra-bold-xxl {
    font-size: 0.625rem;
    font-weight: 800;
    line-height: 1.313rem;
  }
}

@media (min-width: 1400px) {
  .body-xs-extra-bold-italic-xxl {
    font-size: 0.625rem;
    font-style: italic;
    font-weight: 800;
    line-height: 1.313rem;
  }
}

@media (min-width: 1400px) {
  .body-xs-black-xxl {
    font-size: 0.625rem;
    font-weight: 900;
    line-height: 1.313rem;
  }
}

@media (min-width: 1400px) {
  .body-xs-black-italic-xxl {
    font-size: 0.625rem;
    font-style: italic;
    font-weight: 900;
    line-height: 1.313rem;
  }
}

.font-weight-thin {
  font-weight: 100;
}

.font-weight-xs-thin {
  font-weight: 100;
}

@media (min-width: 576px) {
  .font-weight-sm-thin {
    font-weight: 100;
  }
}
@media (min-width: 768px) {
  .font-weight-md-thin {
    font-weight: 100;
  }
}
@media (min-width: 992px) {
  .font-weight-lg-thin {
    font-weight: 100;
  }
}
@media (min-width: 1200px) {
  .font-weight-xl-thin {
    font-weight: 100;
  }
}
@media (min-width: 1400px) {
  .font-weight-xxl-thin {
    font-weight: 100;
  }
}
.font-weight-extra-light {
  font-weight: 200;
}

.font-weight-xs-extra-light {
  font-weight: 200;
}

@media (min-width: 576px) {
  .font-weight-sm-extra-light {
    font-weight: 200;
  }
}
@media (min-width: 768px) {
  .font-weight-md-extra-light {
    font-weight: 200;
  }
}
@media (min-width: 992px) {
  .font-weight-lg-extra-light {
    font-weight: 200;
  }
}
@media (min-width: 1200px) {
  .font-weight-xl-extra-light {
    font-weight: 200;
  }
}
@media (min-width: 1400px) {
  .font-weight-xxl-extra-light {
    font-weight: 200;
  }
}
.font-weight-light {
  font-weight: 300;
}

.font-weight-xs-light {
  font-weight: 300;
}

@media (min-width: 576px) {
  .font-weight-sm-light {
    font-weight: 300;
  }
}
@media (min-width: 768px) {
  .font-weight-md-light {
    font-weight: 300;
  }
}
@media (min-width: 992px) {
  .font-weight-lg-light {
    font-weight: 300;
  }
}
@media (min-width: 1200px) {
  .font-weight-xl-light {
    font-weight: 300;
  }
}
@media (min-width: 1400px) {
  .font-weight-xxl-light {
    font-weight: 300;
  }
}
.font-weight-regular {
  font-weight: 400;
}

.font-weight-xs-regular {
  font-weight: 400;
}

@media (min-width: 576px) {
  .font-weight-sm-regular {
    font-weight: 400;
  }
}
@media (min-width: 768px) {
  .font-weight-md-regular {
    font-weight: 400;
  }
}
@media (min-width: 992px) {
  .font-weight-lg-regular {
    font-weight: 400;
  }
}
@media (min-width: 1200px) {
  .font-weight-xl-regular {
    font-weight: 400;
  }
}
@media (min-width: 1400px) {
  .font-weight-xxl-regular {
    font-weight: 400;
  }
}
.font-weight-medium {
  font-weight: 500;
}

.font-weight-xs-medium {
  font-weight: 500;
}

@media (min-width: 576px) {
  .font-weight-sm-medium {
    font-weight: 500;
  }
}
@media (min-width: 768px) {
  .font-weight-md-medium {
    font-weight: 500;
  }
}
@media (min-width: 992px) {
  .font-weight-lg-medium {
    font-weight: 500;
  }
}
@media (min-width: 1200px) {
  .font-weight-xl-medium {
    font-weight: 500;
  }
}
@media (min-width: 1400px) {
  .font-weight-xxl-medium {
    font-weight: 500;
  }
}
.font-weight-semibold {
  font-weight: 600;
}

.font-weight-xs-semibold {
  font-weight: 600;
}

@media (min-width: 576px) {
  .font-weight-sm-semibold {
    font-weight: 600;
  }
}
@media (min-width: 768px) {
  .font-weight-md-semibold {
    font-weight: 600;
  }
}
@media (min-width: 992px) {
  .font-weight-lg-semibold {
    font-weight: 600;
  }
}
@media (min-width: 1200px) {
  .font-weight-xl-semibold {
    font-weight: 600;
  }
}
@media (min-width: 1400px) {
  .font-weight-xxl-semibold {
    font-weight: 600;
  }
}
.font-weight-bold {
  font-weight: 700;
}

.font-weight-xs-bold {
  font-weight: 700;
}

@media (min-width: 576px) {
  .font-weight-sm-bold {
    font-weight: 700;
  }
}
@media (min-width: 768px) {
  .font-weight-md-bold {
    font-weight: 700;
  }
}
@media (min-width: 992px) {
  .font-weight-lg-bold {
    font-weight: 700;
  }
}
@media (min-width: 1200px) {
  .font-weight-xl-bold {
    font-weight: 700;
  }
}
@media (min-width: 1400px) {
  .font-weight-xxl-bold {
    font-weight: 700;
  }
}
.font-weight-extra-bold {
  font-weight: 800;
}

.font-weight-xs-extra-bold {
  font-weight: 800;
}

@media (min-width: 576px) {
  .font-weight-sm-extra-bold {
    font-weight: 800;
  }
}
@media (min-width: 768px) {
  .font-weight-md-extra-bold {
    font-weight: 800;
  }
}
@media (min-width: 992px) {
  .font-weight-lg-extra-bold {
    font-weight: 800;
  }
}
@media (min-width: 1200px) {
  .font-weight-xl-extra-bold {
    font-weight: 800;
  }
}
@media (min-width: 1400px) {
  .font-weight-xxl-extra-bold {
    font-weight: 800;
  }
}
.font-weight-black {
  font-weight: 900;
}

.font-weight-xs-black {
  font-weight: 900;
}

@media (min-width: 576px) {
  .font-weight-sm-black {
    font-weight: 900;
  }
}
@media (min-width: 768px) {
  .font-weight-md-black {
    font-weight: 900;
  }
}
@media (min-width: 992px) {
  .font-weight-lg-black {
    font-weight: 900;
  }
}
@media (min-width: 1200px) {
  .font-weight-xl-black {
    font-weight: 900;
  }
}
@media (min-width: 1400px) {
  .font-weight-xxl-black {
    font-weight: 900;
  }
}
.color-primary100 {
  color: #f2f4ff;
}

.background-primary100 {
  background-color: #f2f4ff;
}

.color-primary200 {
  color: #e7eaff;
}

.background-primary200 {
  background-color: #e7eaff;
}

.color-primary300 {
  color: #b3bdf9;
}

.background-primary300 {
  background-color: #b3bdf9;
}

.color-primary400 {
  color: #6a7ffc;
}

.background-primary400 {
  background-color: #6a7ffc;
}

.color-primary500 {
  color: #556cf2;
}

.background-primary500 {
  background-color: #556cf2;
}

.color-primary600 {
  color: #4359d9;
}

.background-primary600 {
  background-color: #4359d9;
}

.color-primary700 {
  color: #293a9c;
}

.background-primary700 {
  background-color: #293a9c;
}

.color-bg100 {
  color: #fff;
}

.background-bg100 {
  background-color: #fff;
}

.color-bg200 {
  color: #fffbf7;
}

.background-bg200 {
  background-color: #fffbf7;
}

.color-bg300 {
  color: #fff6ed;
}

.background-bg300 {
  background-color: #fff6ed;
}

.color-bg400 {
  color: #fff1e3;
}

.background-bg400 {
  background-color: #fff1e3;
}

.color-bg500 {
  color: #ffe5cc;
}

.background-bg500 {
  background-color: #ffe5cc;
}

.color-bg600 {
  color: #fddbbb;
}

.background-bg600 {
  background-color: #fddbbb;
}

.color-bg700 {
  color: #ffc195;
}

.background-bg700 {
  background-color: #ffc195;
}

.color-neutral100 {
  color: #f3f7fd;
}

.background-neutral100 {
  background-color: #f3f7fd;
}

.color-neutral200 {
  color: #e8f0fd;
}

.background-neutral200 {
  background-color: #e8f0fd;
}

.color-neutral250 {
  color: #cdddf7;
}

.background-neutral250 {
  background-color: #cdddf7;
}

.color-neutral300 {
  color: #b5cbee;
}

.background-neutral300 {
  background-color: #b5cbee;
}

.color-neutral400 {
  color: #6289ca;
}

.background-neutral400 {
  background-color: #6289ca;
}

.color-neutral500 {
  color: #42649b;
}

.background-neutral500 {
  background-color: #42649b;
}

.color-neutral600 {
  color: #35517d;
}

.background-neutral600 {
  background-color: #35517d;
}

.color-neutral700 {
  color: #293f61;
}

.background-neutral700 {
  background-color: #293f61;
}

.color-neutral800 {
  color: #1d2c44;
}

.background-neutral800 {
  background-color: #1d2c44;
}

.color-neutral900 {
  color: #111927;
}

.background-neutral900 {
  background-color: #111927;
}

.color-text100 {
  color: #fff;
}

.background-text100 {
  background-color: #fff;
}

.color-text200 {
  color: #e8eaed;
}

.background-text200 {
  background-color: #e8eaed;
}

.color-text300 {
  color: #778192;
}

.background-text300 {
  background-color: #778192;
}

.color-text400 {
  color: #45546e;
}

.background-text400 {
  background-color: #45546e;
}

.color-text500 {
  color: #0e2852;
}

.background-text500 {
  background-color: #0e2852;
}

.color-success300 {
  color: #adf3c1;
}

.background-success300 {
  background-color: #adf3c1;
}

.color-success400 {
  color: #98eaae;
}

.background-success400 {
  background-color: #98eaae;
}

.color-success500 {
  color: #74d68f;
}

.background-success500 {
  background-color: #74d68f;
}

.color-success600 {
  color: #67c180;
}

.background-success600 {
  background-color: #67c180;
}

.color-warning300 {
  color: #faddb9;
}

.background-warning300 {
  background-color: #faddb9;
}

.color-warning400 {
  color: #facb91;
}

.background-warning400 {
  background-color: #facb91;
}

.color-warning500 {
  color: #faba6b;
}

.background-warning500 {
  background-color: #faba6b;
}

.color-warning600 {
  color: #f0a64c;
}

.background-warning600 {
  background-color: #f0a64c;
}

.color-error300 {
  color: #f6dfdd;
}

.background-error300 {
  background-color: #f6dfdd;
}

.color-error400 {
  color: #f6bab6;
}

.background-error400 {
  background-color: #f6bab6;
}

.color-error500 {
  color: #f67971;
}

.background-error500 {
  background-color: #f67971;
}

.color-error600 {
  color: #ec5a51;
}

.background-error600 {
  background-color: #ec5a51;
}

.shadow-normal {
  box-shadow: 0 7px 48px -8px rgba(8, 13, 37, 0.11);
}

@media (min-width: 576px) {
  .shadow-sm-normal {
    box-shadow: 0 7px 48px -8px rgba(8, 13, 37, 0.11);
  }
}

.shadow-orange {
  box-shadow: 0 40px 32px -32px rgba(255, 193, 149, 0.6);
}

@media (max-width: 767.98px) {
  .mt-header {
    margin-top: 66px;
  }
}

.flex-1 {
  flex: 1;
}

.flex-1-5 {
  flex: 1.5;
}

.flex-2 {
  flex: 2;
}

.flex-3 {
  flex: 3;
}

.flex-4 {
  flex: 4;
}

.flex-5 {
  flex: 5;
}

.flex-6 {
  flex: 6;
}

.flex-7 {
  flex: 7;
}

.pt-header {
  padding-top: 64px;
}
@media (min-width: 768px) {
  .pt-header {
    padding-top: 88px;
  }
}

.whitespace-nowrap {
  white-space: nowrap;
}

.whitespace-prewrap {
  white-space: pre-wrap;
}

.whitespace-preline {
  white-space: pre-line;
}

.align-baseline {
  vertical-align: baseline;
}

.align-top {
  vertical-align: top;
}

.align-middle {
  vertical-align: middle;
}

.align-bottom {
  vertical-align: bottom;
}

.align-text-bottom {
  vertical-align: text-bottom;
}

.align-text-top {
  vertical-align: text-top;
}

.float-start {
  float: left;
}

.float-end {
  float: right;
}

.float-none {
  float: none;
}

.opacity-0 {
  opacity: 0;
}

.opacity-25 {
  opacity: 0.25;
}

.opacity-50 {
  opacity: 0.5;
}

.opacity-75 {
  opacity: 0.75;
}

.opacity-100 {
  opacity: 1;
}

.overflow-auto {
  overflow: auto;
}

.overflow-hidden {
  overflow: hidden;
}

.overflow-visible {
  overflow: visible;
}

.overflow-scroll {
  overflow: scroll;
}

.d-inline {
  display: inline;
}

.d-inline-block {
  display: inline-block;
}

.d-block {
  display: block;
}

.d-grid {
  display: grid;
}

.d-table {
  display: table;
}

.d-table-row {
  display: table-row;
}

.d-table-cell {
  display: table-cell;
}

.d-flex {
  display: flex;
}

.d-inline-flex {
  display: inline-flex;
}

.d-none {
  display: none;
}

.shadow {
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15);
}

.shadow-sm {
  box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075);
}

.shadow-lg {
  box-shadow: 0 1rem 3rem rgba(0, 0, 0, 0.175);
}

.shadow-none {
  box-shadow: none;
}

.position-static {
  position: static;
}

.position-relative {
  position: relative;
}

.position-absolute {
  position: absolute;
}

.position-fixed {
  position: fixed;
}

.position-sticky {
  position: sticky;
}

.top-0 {
  top: 0;
}

.top-50 {
  top: 50%;
}

.top-100 {
  top: 100%;
}

.bottom-0 {
  bottom: 0;
}

.bottom-50 {
  bottom: 50%;
}

.bottom-100 {
  bottom: 100%;
}

.start-0 {
  left: 0;
}

.start-50 {
  left: 50%;
}

.start-100 {
  left: 100%;
}

.end-0 {
  right: 0;
}

.end-50 {
  right: 50%;
}

.end-100 {
  right: 100%;
}

.translate-middle {
  transform: translate(-50%, -50%);
}

.translate-middle-x {
  transform: translateX(-50%);
}

.translate-middle-y {
  transform: translateY(-50%);
}

.border {
  border: var(--bs-border-width) var(--bs-border-style) var(--bs-border-color);
}

.border-0 {
  border: 0;
}

.border-top {
  border-top: var(--bs-border-width) var(--bs-border-style) var(--bs-border-color);
}

.border-top-0 {
  border-top: 0;
}

.border-end {
  border-right: var(--bs-border-width) var(--bs-border-style) var(--bs-border-color);
}

.border-end-0 {
  border-right: 0;
}

.border-bottom {
  border-bottom: var(--bs-border-width) var(--bs-border-style) var(--bs-border-color);
}

.border-bottom-0 {
  border-bottom: 0;
}

.border-start {
  border-left: var(--bs-border-width) var(--bs-border-style) var(--bs-border-color);
}

.border-start-0 {
  border-left: 0;
}

.border-primary {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-primary-rgb), var(--bs-border-opacity));
}

.border-secondary {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-secondary-rgb), var(--bs-border-opacity));
}

.border-success {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-success-rgb), var(--bs-border-opacity));
}

.border-info {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-info-rgb), var(--bs-border-opacity));
}

.border-warning {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-warning-rgb), var(--bs-border-opacity));
}

.border-danger {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-danger-rgb), var(--bs-border-opacity));
}

.border-light {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-light-rgb), var(--bs-border-opacity));
}

.border-dark {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-dark-rgb), var(--bs-border-opacity));
}

.border-white {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-white-rgb), var(--bs-border-opacity));
}

.border-1 {
  --bs-border-width: 1px;
}

.border-2 {
  --bs-border-width: 2px;
}

.border-3 {
  --bs-border-width: 3px;
}

.border-4 {
  --bs-border-width: 4px;
}

.border-5 {
  --bs-border-width: 5px;
}

.border-opacity-10 {
  --bs-border-opacity: 0.1;
}

.border-opacity-25 {
  --bs-border-opacity: 0.25;
}

.border-opacity-50 {
  --bs-border-opacity: 0.5;
}

.border-opacity-75 {
  --bs-border-opacity: 0.75;
}

.border-opacity-100 {
  --bs-border-opacity: 1;
}

.w-25 {
  width: 25%;
}

.w-50 {
  width: 50%;
}

.w-75 {
  width: 75%;
}

.w-100 {
  width: 100%;
}

.w-auto {
  width: auto;
}

.mw-100 {
  max-width: 100%;
}

.vw-100 {
  width: 100vw;
}

.min-vw-100 {
  min-width: 100vw;
}

.h-25 {
  height: 25%;
}

.h-50 {
  height: 50%;
}

.h-75 {
  height: 75%;
}

.h-100 {
  height: 100%;
}

.h-auto {
  height: auto;
}

.mh-100 {
  max-height: 100%;
}

.vh-100 {
  height: 100vh;
}

.min-vh-100 {
  min-height: 100vh;
}

.flex-fill {
  flex: 1 1 auto;
}

.flex-row {
  flex-direction: row;
}

.flex-column {
  flex-direction: column;
}

.flex-row-reverse {
  flex-direction: row-reverse;
}

.flex-column-reverse {
  flex-direction: column-reverse;
}

.flex-grow-0 {
  flex-grow: 0;
}

.flex-grow-1 {
  flex-grow: 1;
}

.flex-shrink-0 {
  flex-shrink: 0;
}

.flex-shrink-1 {
  flex-shrink: 1;
}

.flex-wrap {
  flex-wrap: wrap;
}

.flex-nowrap {
  flex-wrap: nowrap;
}

.flex-wrap-reverse {
  flex-wrap: wrap-reverse;
}

.justify-content-start {
  justify-content: flex-start;
}

.justify-content-end {
  justify-content: flex-end;
}

.justify-content-center {
  justify-content: center;
}

.justify-content-between {
  justify-content: space-between;
}

.justify-content-around {
  justify-content: space-around;
}

.justify-content-evenly {
  justify-content: space-evenly;
}

.align-items-start {
  align-items: flex-start;
}

.align-items-end {
  align-items: flex-end;
}

.align-items-center {
  align-items: center;
}

.align-items-baseline {
  align-items: baseline;
}

.align-items-stretch {
  align-items: stretch;
}

.align-content-start {
  align-content: flex-start;
}

.align-content-end {
  align-content: flex-end;
}

.align-content-center {
  align-content: center;
}

.align-content-between {
  align-content: space-between;
}

.align-content-around {
  align-content: space-around;
}

.align-content-stretch {
  align-content: stretch;
}

.align-self-auto {
  align-self: auto;
}

.align-self-start {
  align-self: flex-start;
}

.align-self-end {
  align-self: flex-end;
}

.align-self-center {
  align-self: center;
}

.align-self-baseline {
  align-self: baseline;
}

.align-self-stretch {
  align-self: stretch;
}

.order-first {
  order: -1;
}

.order-0 {
  order: 0;
}

.order-1 {
  order: 1;
}

.order-2 {
  order: 2;
}

.order-3 {
  order: 3;
}

.order-4 {
  order: 4;
}

.order-5 {
  order: 5;
}

.order-last {
  order: 6;
}

.m-0 {
  margin: 0;
}

.m-0-5 {
  margin: 0.25rem;
}

.m-1 {
  margin: 0.5rem;
}

.m-1-5 {
  margin: 0.75rem;
}

.m-2 {
  margin: 1rem;
}

.m-2-5 {
  margin: 1.25rem;
}

.m-3 {
  margin: 1.5rem;
}

.m-3-5 {
  margin: 1.75rem;
}

.m-4 {
  margin: 2rem;
}

.m-5 {
  margin: 2.5rem;
}

.m-5-5 {
  margin: 2.75rem;
}

.m-6 {
  margin: 3rem;
}

.m-7 {
  margin: 3.5rem;
}

.m-7-5 {
  margin: 3.75rem;
}

.m-8 {
  margin: 4rem;
}

.m-9 {
  margin: 4.5rem;
}

.m-9-5 {
  margin: 4.75rem;
}

.m-10 {
  margin: 5rem;
}

.m-auto {
  margin: auto;
}

.mx-0 {
  margin-right: 0;
  margin-left: 0;
}

.mx-0-5 {
  margin-right: 0.25rem;
  margin-left: 0.25rem;
}

.mx-1 {
  margin-right: 0.5rem;
  margin-left: 0.5rem;
}

.mx-1-5 {
  margin-right: 0.75rem;
  margin-left: 0.75rem;
}

.mx-2 {
  margin-right: 1rem;
  margin-left: 1rem;
}

.mx-2-5 {
  margin-right: 1.25rem;
  margin-left: 1.25rem;
}

.mx-3 {
  margin-right: 1.5rem;
  margin-left: 1.5rem;
}

.mx-3-5 {
  margin-right: 1.75rem;
  margin-left: 1.75rem;
}

.mx-4 {
  margin-right: 2rem;
  margin-left: 2rem;
}

.mx-5 {
  margin-right: 2.5rem;
  margin-left: 2.5rem;
}

.mx-5-5 {
  margin-right: 2.75rem;
  margin-left: 2.75rem;
}

.mx-6 {
  margin-right: 3rem;
  margin-left: 3rem;
}

.mx-7 {
  margin-right: 3.5rem;
  margin-left: 3.5rem;
}

.mx-7-5 {
  margin-right: 3.75rem;
  margin-left: 3.75rem;
}

.mx-8 {
  margin-right: 4rem;
  margin-left: 4rem;
}

.mx-9 {
  margin-right: 4.5rem;
  margin-left: 4.5rem;
}

.mx-9-5 {
  margin-right: 4.75rem;
  margin-left: 4.75rem;
}

.mx-10 {
  margin-right: 5rem;
  margin-left: 5rem;
}

.mx-auto {
  margin-right: auto;
  margin-left: auto;
}

.my-0 {
  margin-top: 0;
  margin-bottom: 0;
}

.my-0-5 {
  margin-top: 0.25rem;
  margin-bottom: 0.25rem;
}

.my-1 {
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
}

.my-1-5 {
  margin-top: 0.75rem;
  margin-bottom: 0.75rem;
}

.my-2 {
  margin-top: 1rem;
  margin-bottom: 1rem;
}

.my-2-5 {
  margin-top: 1.25rem;
  margin-bottom: 1.25rem;
}

.my-3 {
  margin-top: 1.5rem;
  margin-bottom: 1.5rem;
}

.my-3-5 {
  margin-top: 1.75rem;
  margin-bottom: 1.75rem;
}

.my-4 {
  margin-top: 2rem;
  margin-bottom: 2rem;
}

.my-5 {
  margin-top: 2.5rem;
  margin-bottom: 2.5rem;
}

.my-5-5 {
  margin-top: 2.75rem;
  margin-bottom: 2.75rem;
}

.my-6 {
  margin-top: 3rem;
  margin-bottom: 3rem;
}

.my-7 {
  margin-top: 3.5rem;
  margin-bottom: 3.5rem;
}

.my-7-5 {
  margin-top: 3.75rem;
  margin-bottom: 3.75rem;
}

.my-8 {
  margin-top: 4rem;
  margin-bottom: 4rem;
}

.my-9 {
  margin-top: 4.5rem;
  margin-bottom: 4.5rem;
}

.my-9-5 {
  margin-top: 4.75rem;
  margin-bottom: 4.75rem;
}

.my-10 {
  margin-top: 5rem;
  margin-bottom: 5rem;
}

.my-auto {
  margin-top: auto;
  margin-bottom: auto;
}

.mt-0 {
  margin-top: 0;
}

.mt-0-5 {
  margin-top: 0.25rem;
}

.mt-1 {
  margin-top: 0.5rem;
}

.mt-1-5 {
  margin-top: 0.75rem;
}

.mt-2 {
  margin-top: 1rem;
}

.mt-2-5 {
  margin-top: 1.25rem;
}

.mt-3 {
  margin-top: 1.5rem;
}

.mt-3-5 {
  margin-top: 1.75rem;
}

.mt-4 {
  margin-top: 2rem;
}

.mt-5 {
  margin-top: 2.5rem;
}

.mt-5-5 {
  margin-top: 2.75rem;
}

.mt-6 {
  margin-top: 3rem;
}

.mt-7 {
  margin-top: 3.5rem;
}

.mt-7-5 {
  margin-top: 3.75rem;
}

.mt-8 {
  margin-top: 4rem;
}

.mt-9 {
  margin-top: 4.5rem;
}

.mt-9-5 {
  margin-top: 4.75rem;
}

.mt-10 {
  margin-top: 5rem;
}

.mt-auto {
  margin-top: auto;
}

.me-0 {
  margin-right: 0;
}

.me-0-5 {
  margin-right: 0.25rem;
}

.me-1 {
  margin-right: 0.5rem;
}

.me-1-5 {
  margin-right: 0.75rem;
}

.me-2 {
  margin-right: 1rem;
}

.me-2-5 {
  margin-right: 1.25rem;
}

.me-3 {
  margin-right: 1.5rem;
}

.me-3-5 {
  margin-right: 1.75rem;
}

.me-4 {
  margin-right: 2rem;
}

.me-5 {
  margin-right: 2.5rem;
}

.me-5-5 {
  margin-right: 2.75rem;
}

.me-6 {
  margin-right: 3rem;
}

.me-7 {
  margin-right: 3.5rem;
}

.me-7-5 {
  margin-right: 3.75rem;
}

.me-8 {
  margin-right: 4rem;
}

.me-9 {
  margin-right: 4.5rem;
}

.me-9-5 {
  margin-right: 4.75rem;
}

.me-10 {
  margin-right: 5rem;
}

.me-auto {
  margin-right: auto;
}

.mb-0 {
  margin-bottom: 0;
}

.mb-0-5 {
  margin-bottom: 0.25rem;
}

.mb-1 {
  margin-bottom: 0.5rem;
}

.mb-1-5 {
  margin-bottom: 0.75rem;
}

.mb-2 {
  margin-bottom: 1rem;
}

.mb-2-5 {
  margin-bottom: 1.25rem;
}

.mb-3 {
  margin-bottom: 1.5rem;
}

.mb-3-5 {
  margin-bottom: 1.75rem;
}

.mb-4 {
  margin-bottom: 2rem;
}

.mb-5 {
  margin-bottom: 2.5rem;
}

.mb-5-5 {
  margin-bottom: 2.75rem;
}

.mb-6 {
  margin-bottom: 3rem;
}

.mb-7 {
  margin-bottom: 3.5rem;
}

.mb-7-5 {
  margin-bottom: 3.75rem;
}

.mb-8 {
  margin-bottom: 4rem;
}

.mb-9 {
  margin-bottom: 4.5rem;
}

.mb-9-5 {
  margin-bottom: 4.75rem;
}

.mb-10 {
  margin-bottom: 5rem;
}

.mb-auto {
  margin-bottom: auto;
}

.ms-0 {
  margin-left: 0;
}

.ms-0-5 {
  margin-left: 0.25rem;
}

.ms-1 {
  margin-left: 0.5rem;
}

.ms-1-5 {
  margin-left: 0.75rem;
}

.ms-2 {
  margin-left: 1rem;
}

.ms-2-5 {
  margin-left: 1.25rem;
}

.ms-3 {
  margin-left: 1.5rem;
}

.ms-3-5 {
  margin-left: 1.75rem;
}

.ms-4 {
  margin-left: 2rem;
}

.ms-5 {
  margin-left: 2.5rem;
}

.ms-5-5 {
  margin-left: 2.75rem;
}

.ms-6 {
  margin-left: 3rem;
}

.ms-7 {
  margin-left: 3.5rem;
}

.ms-7-5 {
  margin-left: 3.75rem;
}

.ms-8 {
  margin-left: 4rem;
}

.ms-9 {
  margin-left: 4.5rem;
}

.ms-9-5 {
  margin-left: 4.75rem;
}

.ms-10 {
  margin-left: 5rem;
}

.ms-auto {
  margin-left: auto;
}

.m-n0 {
  margin: 0;
}

.m-n0-5 {
  margin: -0.25rem;
}

.m-n1 {
  margin: -0.5rem;
}

.m-n1-5 {
  margin: -0.75rem;
}

.m-n2 {
  margin: -1rem;
}

.m-n2-5 {
  margin: -1.25rem;
}

.m-n3 {
  margin: -1.5rem;
}

.m-n3-5 {
  margin: -1.75rem;
}

.m-n4 {
  margin: -2rem;
}

.m-n5 {
  margin: -2.5rem;
}

.m-n5-5 {
  margin: -2.75rem;
}

.m-n6 {
  margin: -3rem;
}

.m-n7 {
  margin: -3.5rem;
}

.m-n7-5 {
  margin: -3.75rem;
}

.m-n8 {
  margin: -4rem;
}

.m-n9 {
  margin: -4.5rem;
}

.m-n9-5 {
  margin: -4.75rem;
}

.m-n10 {
  margin: -5rem;
}

.m-nauto {
  margin: -"auto";
}

.mx-n0 {
  margin-right: 0;
  margin-left: 0;
}

.mx-n0-5 {
  margin-right: -0.25rem;
  margin-left: -0.25rem;
}

.mx-n1 {
  margin-right: -0.5rem;
  margin-left: -0.5rem;
}

.mx-n1-5 {
  margin-right: -0.75rem;
  margin-left: -0.75rem;
}

.mx-n2 {
  margin-right: -1rem;
  margin-left: -1rem;
}

.mx-n2-5 {
  margin-right: -1.25rem;
  margin-left: -1.25rem;
}

.mx-n3 {
  margin-right: -1.5rem;
  margin-left: -1.5rem;
}

.mx-n3-5 {
  margin-right: -1.75rem;
  margin-left: -1.75rem;
}

.mx-n4 {
  margin-right: -2rem;
  margin-left: -2rem;
}

.mx-n5 {
  margin-right: -2.5rem;
  margin-left: -2.5rem;
}

.mx-n5-5 {
  margin-right: -2.75rem;
  margin-left: -2.75rem;
}

.mx-n6 {
  margin-right: -3rem;
  margin-left: -3rem;
}

.mx-n7 {
  margin-right: -3.5rem;
  margin-left: -3.5rem;
}

.mx-n7-5 {
  margin-right: -3.75rem;
  margin-left: -3.75rem;
}

.mx-n8 {
  margin-right: -4rem;
  margin-left: -4rem;
}

.mx-n9 {
  margin-right: -4.5rem;
  margin-left: -4.5rem;
}

.mx-n9-5 {
  margin-right: -4.75rem;
  margin-left: -4.75rem;
}

.mx-n10 {
  margin-right: -5rem;
  margin-left: -5rem;
}

.mx-nauto {
  margin-right: -"auto";
  margin-left: -"auto";
}

.my-n0 {
  margin-top: 0;
  margin-bottom: 0;
}

.my-n0-5 {
  margin-top: -0.25rem;
  margin-bottom: -0.25rem;
}

.my-n1 {
  margin-top: -0.5rem;
  margin-bottom: -0.5rem;
}

.my-n1-5 {
  margin-top: -0.75rem;
  margin-bottom: -0.75rem;
}

.my-n2 {
  margin-top: -1rem;
  margin-bottom: -1rem;
}

.my-n2-5 {
  margin-top: -1.25rem;
  margin-bottom: -1.25rem;
}

.my-n3 {
  margin-top: -1.5rem;
  margin-bottom: -1.5rem;
}

.my-n3-5 {
  margin-top: -1.75rem;
  margin-bottom: -1.75rem;
}

.my-n4 {
  margin-top: -2rem;
  margin-bottom: -2rem;
}

.my-n5 {
  margin-top: -2.5rem;
  margin-bottom: -2.5rem;
}

.my-n5-5 {
  margin-top: -2.75rem;
  margin-bottom: -2.75rem;
}

.my-n6 {
  margin-top: -3rem;
  margin-bottom: -3rem;
}

.my-n7 {
  margin-top: -3.5rem;
  margin-bottom: -3.5rem;
}

.my-n7-5 {
  margin-top: -3.75rem;
  margin-bottom: -3.75rem;
}

.my-n8 {
  margin-top: -4rem;
  margin-bottom: -4rem;
}

.my-n9 {
  margin-top: -4.5rem;
  margin-bottom: -4.5rem;
}

.my-n9-5 {
  margin-top: -4.75rem;
  margin-bottom: -4.75rem;
}

.my-n10 {
  margin-top: -5rem;
  margin-bottom: -5rem;
}

.my-nauto {
  margin-top: -"auto";
  margin-bottom: -"auto";
}

.mt-n0 {
  margin-top: 0;
}

.mt-n0-5 {
  margin-top: -0.25rem;
}

.mt-n1 {
  margin-top: -0.5rem;
}

.mt-n1-5 {
  margin-top: -0.75rem;
}

.mt-n2 {
  margin-top: -1rem;
}

.mt-n2-5 {
  margin-top: -1.25rem;
}

.mt-n3 {
  margin-top: -1.5rem;
}

.mt-n3-5 {
  margin-top: -1.75rem;
}

.mt-n4 {
  margin-top: -2rem;
}

.mt-n5 {
  margin-top: -2.5rem;
}

.mt-n5-5 {
  margin-top: -2.75rem;
}

.mt-n6 {
  margin-top: -3rem;
}

.mt-n7 {
  margin-top: -3.5rem;
}

.mt-n7-5 {
  margin-top: -3.75rem;
}

.mt-n8 {
  margin-top: -4rem;
}

.mt-n9 {
  margin-top: -4.5rem;
}

.mt-n9-5 {
  margin-top: -4.75rem;
}

.mt-n10 {
  margin-top: -5rem;
}

.mt-nauto {
  margin-top: -"auto";
}

.me-n0 {
  margin-right: 0;
}

.me-n0-5 {
  margin-right: -0.25rem;
}

.me-n1 {
  margin-right: -0.5rem;
}

.me-n1-5 {
  margin-right: -0.75rem;
}

.me-n2 {
  margin-right: -1rem;
}

.me-n2-5 {
  margin-right: -1.25rem;
}

.me-n3 {
  margin-right: -1.5rem;
}

.me-n3-5 {
  margin-right: -1.75rem;
}

.me-n4 {
  margin-right: -2rem;
}

.me-n5 {
  margin-right: -2.5rem;
}

.me-n5-5 {
  margin-right: -2.75rem;
}

.me-n6 {
  margin-right: -3rem;
}

.me-n7 {
  margin-right: -3.5rem;
}

.me-n7-5 {
  margin-right: -3.75rem;
}

.me-n8 {
  margin-right: -4rem;
}

.me-n9 {
  margin-right: -4.5rem;
}

.me-n9-5 {
  margin-right: -4.75rem;
}

.me-n10 {
  margin-right: -5rem;
}

.me-nauto {
  margin-right: -"auto";
}

.mb-n0 {
  margin-bottom: 0;
}

.mb-n0-5 {
  margin-bottom: -0.25rem;
}

.mb-n1 {
  margin-bottom: -0.5rem;
}

.mb-n1-5 {
  margin-bottom: -0.75rem;
}

.mb-n2 {
  margin-bottom: -1rem;
}

.mb-n2-5 {
  margin-bottom: -1.25rem;
}

.mb-n3 {
  margin-bottom: -1.5rem;
}

.mb-n3-5 {
  margin-bottom: -1.75rem;
}

.mb-n4 {
  margin-bottom: -2rem;
}

.mb-n5 {
  margin-bottom: -2.5rem;
}

.mb-n5-5 {
  margin-bottom: -2.75rem;
}

.mb-n6 {
  margin-bottom: -3rem;
}

.mb-n7 {
  margin-bottom: -3.5rem;
}

.mb-n7-5 {
  margin-bottom: -3.75rem;
}

.mb-n8 {
  margin-bottom: -4rem;
}

.mb-n9 {
  margin-bottom: -4.5rem;
}

.mb-n9-5 {
  margin-bottom: -4.75rem;
}

.mb-n10 {
  margin-bottom: -5rem;
}

.mb-nauto {
  margin-bottom: -"auto";
}

.ms-n0 {
  margin-left: 0;
}

.ms-n0-5 {
  margin-left: -0.25rem;
}

.ms-n1 {
  margin-left: -0.5rem;
}

.ms-n1-5 {
  margin-left: -0.75rem;
}

.ms-n2 {
  margin-left: -1rem;
}

.ms-n2-5 {
  margin-left: -1.25rem;
}

.ms-n3 {
  margin-left: -1.5rem;
}

.ms-n3-5 {
  margin-left: -1.75rem;
}

.ms-n4 {
  margin-left: -2rem;
}

.ms-n5 {
  margin-left: -2.5rem;
}

.ms-n5-5 {
  margin-left: -2.75rem;
}

.ms-n6 {
  margin-left: -3rem;
}

.ms-n7 {
  margin-left: -3.5rem;
}

.ms-n7-5 {
  margin-left: -3.75rem;
}

.ms-n8 {
  margin-left: -4rem;
}

.ms-n9 {
  margin-left: -4.5rem;
}

.ms-n9-5 {
  margin-left: -4.75rem;
}

.ms-n10 {
  margin-left: -5rem;
}

.ms-nauto {
  margin-left: -"auto";
}

.p-0 {
  padding: 0;
}

.p-0-5 {
  padding: 0.25rem;
}

.p-1 {
  padding: 0.5rem;
}

.p-1-5 {
  padding: 0.75rem;
}

.p-2 {
  padding: 1rem;
}

.p-2-5 {
  padding: 1.25rem;
}

.p-3 {
  padding: 1.5rem;
}

.p-3-5 {
  padding: 1.75rem;
}

.p-4 {
  padding: 2rem;
}

.p-5 {
  padding: 2.5rem;
}

.p-5-5 {
  padding: 2.75rem;
}

.p-6 {
  padding: 3rem;
}

.p-7 {
  padding: 3.5rem;
}

.p-7-5 {
  padding: 3.75rem;
}

.p-8 {
  padding: 4rem;
}

.p-9 {
  padding: 4.5rem;
}

.p-9-5 {
  padding: 4.75rem;
}

.p-10 {
  padding: 5rem;
}

.p-auto {
  padding: "auto";
}

.px-0 {
  padding-right: 0;
  padding-left: 0;
}

.px-0-5 {
  padding-right: 0.25rem;
  padding-left: 0.25rem;
}

.px-1 {
  padding-right: 0.5rem;
  padding-left: 0.5rem;
}

.px-1-5 {
  padding-right: 0.75rem;
  padding-left: 0.75rem;
}

.px-2 {
  padding-right: 1rem;
  padding-left: 1rem;
}

.px-2-5 {
  padding-right: 1.25rem;
  padding-left: 1.25rem;
}

.px-3 {
  padding-right: 1.5rem;
  padding-left: 1.5rem;
}

.px-3-5 {
  padding-right: 1.75rem;
  padding-left: 1.75rem;
}

.px-4 {
  padding-right: 2rem;
  padding-left: 2rem;
}

.px-5 {
  padding-right: 2.5rem;
  padding-left: 2.5rem;
}

.px-5-5 {
  padding-right: 2.75rem;
  padding-left: 2.75rem;
}

.px-6 {
  padding-right: 3rem;
  padding-left: 3rem;
}

.px-7 {
  padding-right: 3.5rem;
  padding-left: 3.5rem;
}

.px-7-5 {
  padding-right: 3.75rem;
  padding-left: 3.75rem;
}

.px-8 {
  padding-right: 4rem;
  padding-left: 4rem;
}

.px-9 {
  padding-right: 4.5rem;
  padding-left: 4.5rem;
}

.px-9-5 {
  padding-right: 4.75rem;
  padding-left: 4.75rem;
}

.px-10 {
  padding-right: 5rem;
  padding-left: 5rem;
}

.px-auto {
  padding-right: "auto";
  padding-left: "auto";
}

.py-0 {
  padding-top: 0;
  padding-bottom: 0;
}

.py-0-5 {
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
}

.py-1 {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}

.py-1-5 {
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
}

.py-2 {
  padding-top: 1rem;
  padding-bottom: 1rem;
}

.py-2-5 {
  padding-top: 1.25rem;
  padding-bottom: 1.25rem;
}

.py-3 {
  padding-top: 1.5rem;
  padding-bottom: 1.5rem;
}

.py-3-5 {
  padding-top: 1.75rem;
  padding-bottom: 1.75rem;
}

.py-4 {
  padding-top: 2rem;
  padding-bottom: 2rem;
}

.py-5 {
  padding-top: 2.5rem;
  padding-bottom: 2.5rem;
}

.py-5-5 {
  padding-top: 2.75rem;
  padding-bottom: 2.75rem;
}

.py-6 {
  padding-top: 3rem;
  padding-bottom: 3rem;
}

.py-7 {
  padding-top: 3.5rem;
  padding-bottom: 3.5rem;
}

.py-7-5 {
  padding-top: 3.75rem;
  padding-bottom: 3.75rem;
}

.py-8 {
  padding-top: 4rem;
  padding-bottom: 4rem;
}

.py-9 {
  padding-top: 4.5rem;
  padding-bottom: 4.5rem;
}

.py-9-5 {
  padding-top: 4.75rem;
  padding-bottom: 4.75rem;
}

.py-10 {
  padding-top: 5rem;
  padding-bottom: 5rem;
}

.py-auto {
  padding-top: "auto";
  padding-bottom: "auto";
}

.pt-0 {
  padding-top: 0;
}

.pt-0-5 {
  padding-top: 0.25rem;
}

.pt-1 {
  padding-top: 0.5rem;
}

.pt-1-5 {
  padding-top: 0.75rem;
}

.pt-2 {
  padding-top: 1rem;
}

.pt-2-5 {
  padding-top: 1.25rem;
}

.pt-3 {
  padding-top: 1.5rem;
}

.pt-3-5 {
  padding-top: 1.75rem;
}

.pt-4 {
  padding-top: 2rem;
}

.pt-5 {
  padding-top: 2.5rem;
}

.pt-5-5 {
  padding-top: 2.75rem;
}

.pt-6 {
  padding-top: 3rem;
}

.pt-7 {
  padding-top: 3.5rem;
}

.pt-7-5 {
  padding-top: 3.75rem;
}

.pt-8 {
  padding-top: 4rem;
}

.pt-9 {
  padding-top: 4.5rem;
}

.pt-9-5 {
  padding-top: 4.75rem;
}

.pt-10 {
  padding-top: 5rem;
}

.pt-auto {
  padding-top: "auto";
}

.pe-0 {
  padding-right: 0;
}

.pe-0-5 {
  padding-right: 0.25rem;
}

.pe-1 {
  padding-right: 0.5rem;
}

.pe-1-5 {
  padding-right: 0.75rem;
}

.pe-2 {
  padding-right: 1rem;
}

.pe-2-5 {
  padding-right: 1.25rem;
}

.pe-3 {
  padding-right: 1.5rem;
}

.pe-3-5 {
  padding-right: 1.75rem;
}

.pe-4 {
  padding-right: 2rem;
}

.pe-5 {
  padding-right: 2.5rem;
}

.pe-5-5 {
  padding-right: 2.75rem;
}

.pe-6 {
  padding-right: 3rem;
}

.pe-7 {
  padding-right: 3.5rem;
}

.pe-7-5 {
  padding-right: 3.75rem;
}

.pe-8 {
  padding-right: 4rem;
}

.pe-9 {
  padding-right: 4.5rem;
}

.pe-9-5 {
  padding-right: 4.75rem;
}

.pe-10 {
  padding-right: 5rem;
}

.pe-auto {
  padding-right: "auto";
}

.pb-0 {
  padding-bottom: 0;
}

.pb-0-5 {
  padding-bottom: 0.25rem;
}

.pb-1 {
  padding-bottom: 0.5rem;
}

.pb-1-5 {
  padding-bottom: 0.75rem;
}

.pb-2 {
  padding-bottom: 1rem;
}

.pb-2-5 {
  padding-bottom: 1.25rem;
}

.pb-3 {
  padding-bottom: 1.5rem;
}

.pb-3-5 {
  padding-bottom: 1.75rem;
}

.pb-4 {
  padding-bottom: 2rem;
}

.pb-5 {
  padding-bottom: 2.5rem;
}

.pb-5-5 {
  padding-bottom: 2.75rem;
}

.pb-6 {
  padding-bottom: 3rem;
}

.pb-7 {
  padding-bottom: 3.5rem;
}

.pb-7-5 {
  padding-bottom: 3.75rem;
}

.pb-8 {
  padding-bottom: 4rem;
}

.pb-9 {
  padding-bottom: 4.5rem;
}

.pb-9-5 {
  padding-bottom: 4.75rem;
}

.pb-10 {
  padding-bottom: 5rem;
}

.pb-auto {
  padding-bottom: "auto";
}

.ps-0 {
  padding-left: 0;
}

.ps-0-5 {
  padding-left: 0.25rem;
}

.ps-1 {
  padding-left: 0.5rem;
}

.ps-1-5 {
  padding-left: 0.75rem;
}

.ps-2 {
  padding-left: 1rem;
}

.ps-2-5 {
  padding-left: 1.25rem;
}

.ps-3 {
  padding-left: 1.5rem;
}

.ps-3-5 {
  padding-left: 1.75rem;
}

.ps-4 {
  padding-left: 2rem;
}

.ps-5 {
  padding-left: 2.5rem;
}

.ps-5-5 {
  padding-left: 2.75rem;
}

.ps-6 {
  padding-left: 3rem;
}

.ps-7 {
  padding-left: 3.5rem;
}

.ps-7-5 {
  padding-left: 3.75rem;
}

.ps-8 {
  padding-left: 4rem;
}

.ps-9 {
  padding-left: 4.5rem;
}

.ps-9-5 {
  padding-left: 4.75rem;
}

.ps-10 {
  padding-left: 5rem;
}

.ps-auto {
  padding-left: "auto";
}

.gap-0 {
  gap: 0;
}

.gap-0-5 {
  gap: 0.25rem;
}

.gap-1 {
  gap: 0.5rem;
}

.gap-1-5 {
  gap: 0.75rem;
}

.gap-2 {
  gap: 1rem;
}

.gap-2-5 {
  gap: 1.25rem;
}

.gap-3 {
  gap: 1.5rem;
}

.gap-3-5 {
  gap: 1.75rem;
}

.gap-4 {
  gap: 2rem;
}

.gap-5 {
  gap: 2.5rem;
}

.gap-5-5 {
  gap: 2.75rem;
}

.gap-6 {
  gap: 3rem;
}

.gap-7 {
  gap: 3.5rem;
}

.gap-7-5 {
  gap: 3.75rem;
}

.gap-8 {
  gap: 4rem;
}

.gap-9 {
  gap: 4.5rem;
}

.gap-9-5 {
  gap: 4.75rem;
}

.gap-10 {
  gap: 5rem;
}

.gap-auto {
  gap: "auto";
}

.font-monospace {
  font-family: var(--bs-font-monospace);
}

.fs-1 {
  font-size: calc(1.375rem + 1.5vw);
}

.fs-2 {
  font-size: calc(1.325rem + 0.9vw);
}

.fs-3 {
  font-size: calc(1.3rem + 0.6vw);
}

.fs-4 {
  font-size: calc(1.275rem + 0.3vw);
}

.fs-5 {
  font-size: 1.25rem;
}

.fs-6 {
  font-size: 1rem;
}

.fst-italic {
  font-style: italic;
}

.fst-normal {
  font-style: normal;
}

.fw-light {
  font-weight: 300;
}

.fw-lighter {
  font-weight: lighter;
}

.fw-normal {
  font-weight: 400;
}

.fw-bold {
  font-weight: 700;
}

.fw-semibold {
  font-weight: 600;
}

.fw-bolder {
  font-weight: bolder;
}

.lh-1 {
  line-height: 1;
}

.lh-sm {
  line-height: 1.25;
}

.lh-base {
  line-height: 1.5;
}

.lh-lg {
  line-height: 2;
}

.text-start {
  text-align: left;
}

.text-end {
  text-align: right;
}

.text-center {
  text-align: center;
}

.text-decoration-none {
  text-decoration: none;
}

.text-decoration-underline {
  text-decoration: underline;
}

.text-decoration-line-through {
  text-decoration: line-through;
}

.text-lowercase {
  text-transform: lowercase;
}

.text-uppercase {
  text-transform: uppercase;
}

.text-capitalize {
  text-transform: capitalize;
}

.text-wrap {
  white-space: normal;
}

.text-nowrap {
  white-space: nowrap;
}

/* rtl:begin:remove */
.text-break {
  word-wrap: break-word;
  word-break: break-word;
}

/* rtl:end:remove */
.text-primary {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-primary-rgb), var(--bs-text-opacity));
}

.text-secondary {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-secondary-rgb), var(--bs-text-opacity));
}

.text-success {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-success-rgb), var(--bs-text-opacity));
}

.text-info {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-info-rgb), var(--bs-text-opacity));
}

.text-warning {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-warning-rgb), var(--bs-text-opacity));
}

.text-danger {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-danger-rgb), var(--bs-text-opacity));
}

.text-light {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-light-rgb), var(--bs-text-opacity));
}

.text-dark {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-dark-rgb), var(--bs-text-opacity));
}

.text-black {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-black-rgb), var(--bs-text-opacity));
}

.text-white {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-white-rgb), var(--bs-text-opacity));
}

.text-body {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-body-color-rgb), var(--bs-text-opacity));
}

.text-muted {
  --bs-text-opacity: 1;
  color: #6c757d;
}

.text-black-50 {
  --bs-text-opacity: 1;
  color: rgba(0, 0, 0, 0.5);
}

.text-white-50 {
  --bs-text-opacity: 1;
  color: rgba(255, 255, 255, 0.5);
}

.text-reset {
  --bs-text-opacity: 1;
  color: inherit;
}

.text-opacity-25 {
  --bs-text-opacity: 0.25;
}

.text-opacity-50 {
  --bs-text-opacity: 0.5;
}

.text-opacity-75 {
  --bs-text-opacity: 0.75;
}

.text-opacity-100 {
  --bs-text-opacity: 1;
}

.bg-primary {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-primary-rgb), var(--bs-bg-opacity));
}

.bg-secondary {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-secondary-rgb), var(--bs-bg-opacity));
}

.bg-success {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-success-rgb), var(--bs-bg-opacity));
}

.bg-info {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-info-rgb), var(--bs-bg-opacity));
}

.bg-warning {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-warning-rgb), var(--bs-bg-opacity));
}

.bg-danger {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-danger-rgb), var(--bs-bg-opacity));
}

.bg-light {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-light-rgb), var(--bs-bg-opacity));
}

.bg-dark {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-dark-rgb), var(--bs-bg-opacity));
}

.bg-black {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-black-rgb), var(--bs-bg-opacity));
}

.bg-white {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-white-rgb), var(--bs-bg-opacity));
}

.bg-body {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-body-bg-rgb), var(--bs-bg-opacity));
}

.bg-transparent {
  --bs-bg-opacity: 1;
  background-color: transparent;
}

.bg-opacity-10 {
  --bs-bg-opacity: 0.1;
}

.bg-opacity-25 {
  --bs-bg-opacity: 0.25;
}

.bg-opacity-50 {
  --bs-bg-opacity: 0.5;
}

.bg-opacity-75 {
  --bs-bg-opacity: 0.75;
}

.bg-opacity-100 {
  --bs-bg-opacity: 1;
}

.bg-gradient {
  background-image: var(--bs-gradient);
}

.user-select-all {
  user-select: all;
}

.user-select-auto {
  user-select: auto;
}

.user-select-none {
  user-select: none;
}

.pointer-events-auto {
  pointer-events: auto;
}

.pointer-events-none {
  pointer-events: none;
}

.rounded {
  border-radius: var(--bs-border-radius);
}

.rounded-0 {
  border-radius: 0;
}

.rounded-1 {
  border-radius: var(--bs-border-radius-sm);
}

.rounded-2 {
  border-radius: var(--bs-border-radius);
}

.rounded-3 {
  border-radius: var(--bs-border-radius-lg);
}

.rounded-4 {
  border-radius: var(--bs-border-radius-xl);
}

.rounded-5 {
  border-radius: var(--bs-border-radius-2xl);
}

.rounded-circle {
  border-radius: 50%;
}

.rounded-pill {
  border-radius: var(--bs-border-radius-pill);
}

.rounded-top {
  border-top-left-radius: var(--bs-border-radius);
  border-top-right-radius: var(--bs-border-radius);
}

.rounded-end {
  border-top-right-radius: var(--bs-border-radius);
  border-bottom-right-radius: var(--bs-border-radius);
}

.rounded-bottom {
  border-bottom-right-radius: var(--bs-border-radius);
  border-bottom-left-radius: var(--bs-border-radius);
}

.rounded-start {
  border-bottom-left-radius: var(--bs-border-radius);
  border-top-left-radius: var(--bs-border-radius);
}

.visible {
  visibility: visible;
}

.invisible {
  visibility: hidden;
}

.cursor-auto {
  cursor: auto;
}

.cursor-pointer {
  cursor: pointer;
}

@media (min-width: 576px) {
  .float-sm-start {
    float: left;
  }
  .float-sm-end {
    float: right;
  }
  .float-sm-none {
    float: none;
  }
  .d-sm-inline {
    display: inline;
  }
  .d-sm-inline-block {
    display: inline-block;
  }
  .d-sm-block {
    display: block;
  }
  .d-sm-grid {
    display: grid;
  }
  .d-sm-table {
    display: table;
  }
  .d-sm-table-row {
    display: table-row;
  }
  .d-sm-table-cell {
    display: table-cell;
  }
  .d-sm-flex {
    display: flex;
  }
  .d-sm-inline-flex {
    display: inline-flex;
  }
  .d-sm-none {
    display: none;
  }
  .w-sm-25 {
    width: 25%;
  }
  .w-sm-50 {
    width: 50%;
  }
  .w-sm-75 {
    width: 75%;
  }
  .w-sm-100 {
    width: 100%;
  }
  .w-sm-auto {
    width: auto;
  }
  .flex-sm-fill {
    flex: 1 1 auto;
  }
  .flex-sm-row {
    flex-direction: row;
  }
  .flex-sm-column {
    flex-direction: column;
  }
  .flex-sm-row-reverse {
    flex-direction: row-reverse;
  }
  .flex-sm-column-reverse {
    flex-direction: column-reverse;
  }
  .flex-sm-grow-0 {
    flex-grow: 0;
  }
  .flex-sm-grow-1 {
    flex-grow: 1;
  }
  .flex-sm-shrink-0 {
    flex-shrink: 0;
  }
  .flex-sm-shrink-1 {
    flex-shrink: 1;
  }
  .flex-sm-wrap {
    flex-wrap: wrap;
  }
  .flex-sm-nowrap {
    flex-wrap: nowrap;
  }
  .flex-sm-wrap-reverse {
    flex-wrap: wrap-reverse;
  }
  .justify-content-sm-start {
    justify-content: flex-start;
  }
  .justify-content-sm-end {
    justify-content: flex-end;
  }
  .justify-content-sm-center {
    justify-content: center;
  }
  .justify-content-sm-between {
    justify-content: space-between;
  }
  .justify-content-sm-around {
    justify-content: space-around;
  }
  .justify-content-sm-evenly {
    justify-content: space-evenly;
  }
  .align-items-sm-start {
    align-items: flex-start;
  }
  .align-items-sm-end {
    align-items: flex-end;
  }
  .align-items-sm-center {
    align-items: center;
  }
  .align-items-sm-baseline {
    align-items: baseline;
  }
  .align-items-sm-stretch {
    align-items: stretch;
  }
  .align-content-sm-start {
    align-content: flex-start;
  }
  .align-content-sm-end {
    align-content: flex-end;
  }
  .align-content-sm-center {
    align-content: center;
  }
  .align-content-sm-between {
    align-content: space-between;
  }
  .align-content-sm-around {
    align-content: space-around;
  }
  .align-content-sm-stretch {
    align-content: stretch;
  }
  .align-self-sm-auto {
    align-self: auto;
  }
  .align-self-sm-start {
    align-self: flex-start;
  }
  .align-self-sm-end {
    align-self: flex-end;
  }
  .align-self-sm-center {
    align-self: center;
  }
  .align-self-sm-baseline {
    align-self: baseline;
  }
  .align-self-sm-stretch {
    align-self: stretch;
  }
  .order-sm-first {
    order: -1;
  }
  .order-sm-0 {
    order: 0;
  }
  .order-sm-1 {
    order: 1;
  }
  .order-sm-2 {
    order: 2;
  }
  .order-sm-3 {
    order: 3;
  }
  .order-sm-4 {
    order: 4;
  }
  .order-sm-5 {
    order: 5;
  }
  .order-sm-last {
    order: 6;
  }
  .m-sm-0 {
    margin: 0;
  }
  .m-sm-0-5 {
    margin: 0.25rem;
  }
  .m-sm-1 {
    margin: 0.5rem;
  }
  .m-sm-1-5 {
    margin: 0.75rem;
  }
  .m-sm-2 {
    margin: 1rem;
  }
  .m-sm-2-5 {
    margin: 1.25rem;
  }
  .m-sm-3 {
    margin: 1.5rem;
  }
  .m-sm-3-5 {
    margin: 1.75rem;
  }
  .m-sm-4 {
    margin: 2rem;
  }
  .m-sm-5 {
    margin: 2.5rem;
  }
  .m-sm-5-5 {
    margin: 2.75rem;
  }
  .m-sm-6 {
    margin: 3rem;
  }
  .m-sm-7 {
    margin: 3.5rem;
  }
  .m-sm-7-5 {
    margin: 3.75rem;
  }
  .m-sm-8 {
    margin: 4rem;
  }
  .m-sm-9 {
    margin: 4.5rem;
  }
  .m-sm-9-5 {
    margin: 4.75rem;
  }
  .m-sm-10 {
    margin: 5rem;
  }
  .m-sm-auto {
    margin: auto;
  }
  .mx-sm-0 {
    margin-right: 0;
    margin-left: 0;
  }
  .mx-sm-0-5 {
    margin-right: 0.25rem;
    margin-left: 0.25rem;
  }
  .mx-sm-1 {
    margin-right: 0.5rem;
    margin-left: 0.5rem;
  }
  .mx-sm-1-5 {
    margin-right: 0.75rem;
    margin-left: 0.75rem;
  }
  .mx-sm-2 {
    margin-right: 1rem;
    margin-left: 1rem;
  }
  .mx-sm-2-5 {
    margin-right: 1.25rem;
    margin-left: 1.25rem;
  }
  .mx-sm-3 {
    margin-right: 1.5rem;
    margin-left: 1.5rem;
  }
  .mx-sm-3-5 {
    margin-right: 1.75rem;
    margin-left: 1.75rem;
  }
  .mx-sm-4 {
    margin-right: 2rem;
    margin-left: 2rem;
  }
  .mx-sm-5 {
    margin-right: 2.5rem;
    margin-left: 2.5rem;
  }
  .mx-sm-5-5 {
    margin-right: 2.75rem;
    margin-left: 2.75rem;
  }
  .mx-sm-6 {
    margin-right: 3rem;
    margin-left: 3rem;
  }
  .mx-sm-7 {
    margin-right: 3.5rem;
    margin-left: 3.5rem;
  }
  .mx-sm-7-5 {
    margin-right: 3.75rem;
    margin-left: 3.75rem;
  }
  .mx-sm-8 {
    margin-right: 4rem;
    margin-left: 4rem;
  }
  .mx-sm-9 {
    margin-right: 4.5rem;
    margin-left: 4.5rem;
  }
  .mx-sm-9-5 {
    margin-right: 4.75rem;
    margin-left: 4.75rem;
  }
  .mx-sm-10 {
    margin-right: 5rem;
    margin-left: 5rem;
  }
  .mx-sm-auto {
    margin-right: auto;
    margin-left: auto;
  }
  .my-sm-0 {
    margin-top: 0;
    margin-bottom: 0;
  }
  .my-sm-0-5 {
    margin-top: 0.25rem;
    margin-bottom: 0.25rem;
  }
  .my-sm-1 {
    margin-top: 0.5rem;
    margin-bottom: 0.5rem;
  }
  .my-sm-1-5 {
    margin-top: 0.75rem;
    margin-bottom: 0.75rem;
  }
  .my-sm-2 {
    margin-top: 1rem;
    margin-bottom: 1rem;
  }
  .my-sm-2-5 {
    margin-top: 1.25rem;
    margin-bottom: 1.25rem;
  }
  .my-sm-3 {
    margin-top: 1.5rem;
    margin-bottom: 1.5rem;
  }
  .my-sm-3-5 {
    margin-top: 1.75rem;
    margin-bottom: 1.75rem;
  }
  .my-sm-4 {
    margin-top: 2rem;
    margin-bottom: 2rem;
  }
  .my-sm-5 {
    margin-top: 2.5rem;
    margin-bottom: 2.5rem;
  }
  .my-sm-5-5 {
    margin-top: 2.75rem;
    margin-bottom: 2.75rem;
  }
  .my-sm-6 {
    margin-top: 3rem;
    margin-bottom: 3rem;
  }
  .my-sm-7 {
    margin-top: 3.5rem;
    margin-bottom: 3.5rem;
  }
  .my-sm-7-5 {
    margin-top: 3.75rem;
    margin-bottom: 3.75rem;
  }
  .my-sm-8 {
    margin-top: 4rem;
    margin-bottom: 4rem;
  }
  .my-sm-9 {
    margin-top: 4.5rem;
    margin-bottom: 4.5rem;
  }
  .my-sm-9-5 {
    margin-top: 4.75rem;
    margin-bottom: 4.75rem;
  }
  .my-sm-10 {
    margin-top: 5rem;
    margin-bottom: 5rem;
  }
  .my-sm-auto {
    margin-top: auto;
    margin-bottom: auto;
  }
  .mt-sm-0 {
    margin-top: 0;
  }
  .mt-sm-0-5 {
    margin-top: 0.25rem;
  }
  .mt-sm-1 {
    margin-top: 0.5rem;
  }
  .mt-sm-1-5 {
    margin-top: 0.75rem;
  }
  .mt-sm-2 {
    margin-top: 1rem;
  }
  .mt-sm-2-5 {
    margin-top: 1.25rem;
  }
  .mt-sm-3 {
    margin-top: 1.5rem;
  }
  .mt-sm-3-5 {
    margin-top: 1.75rem;
  }
  .mt-sm-4 {
    margin-top: 2rem;
  }
  .mt-sm-5 {
    margin-top: 2.5rem;
  }
  .mt-sm-5-5 {
    margin-top: 2.75rem;
  }
  .mt-sm-6 {
    margin-top: 3rem;
  }
  .mt-sm-7 {
    margin-top: 3.5rem;
  }
  .mt-sm-7-5 {
    margin-top: 3.75rem;
  }
  .mt-sm-8 {
    margin-top: 4rem;
  }
  .mt-sm-9 {
    margin-top: 4.5rem;
  }
  .mt-sm-9-5 {
    margin-top: 4.75rem;
  }
  .mt-sm-10 {
    margin-top: 5rem;
  }
  .mt-sm-auto {
    margin-top: auto;
  }
  .me-sm-0 {
    margin-right: 0;
  }
  .me-sm-0-5 {
    margin-right: 0.25rem;
  }
  .me-sm-1 {
    margin-right: 0.5rem;
  }
  .me-sm-1-5 {
    margin-right: 0.75rem;
  }
  .me-sm-2 {
    margin-right: 1rem;
  }
  .me-sm-2-5 {
    margin-right: 1.25rem;
  }
  .me-sm-3 {
    margin-right: 1.5rem;
  }
  .me-sm-3-5 {
    margin-right: 1.75rem;
  }
  .me-sm-4 {
    margin-right: 2rem;
  }
  .me-sm-5 {
    margin-right: 2.5rem;
  }
  .me-sm-5-5 {
    margin-right: 2.75rem;
  }
  .me-sm-6 {
    margin-right: 3rem;
  }
  .me-sm-7 {
    margin-right: 3.5rem;
  }
  .me-sm-7-5 {
    margin-right: 3.75rem;
  }
  .me-sm-8 {
    margin-right: 4rem;
  }
  .me-sm-9 {
    margin-right: 4.5rem;
  }
  .me-sm-9-5 {
    margin-right: 4.75rem;
  }
  .me-sm-10 {
    margin-right: 5rem;
  }
  .me-sm-auto {
    margin-right: auto;
  }
  .mb-sm-0 {
    margin-bottom: 0;
  }
  .mb-sm-0-5 {
    margin-bottom: 0.25rem;
  }
  .mb-sm-1 {
    margin-bottom: 0.5rem;
  }
  .mb-sm-1-5 {
    margin-bottom: 0.75rem;
  }
  .mb-sm-2 {
    margin-bottom: 1rem;
  }
  .mb-sm-2-5 {
    margin-bottom: 1.25rem;
  }
  .mb-sm-3 {
    margin-bottom: 1.5rem;
  }
  .mb-sm-3-5 {
    margin-bottom: 1.75rem;
  }
  .mb-sm-4 {
    margin-bottom: 2rem;
  }
  .mb-sm-5 {
    margin-bottom: 2.5rem;
  }
  .mb-sm-5-5 {
    margin-bottom: 2.75rem;
  }
  .mb-sm-6 {
    margin-bottom: 3rem;
  }
  .mb-sm-7 {
    margin-bottom: 3.5rem;
  }
  .mb-sm-7-5 {
    margin-bottom: 3.75rem;
  }
  .mb-sm-8 {
    margin-bottom: 4rem;
  }
  .mb-sm-9 {
    margin-bottom: 4.5rem;
  }
  .mb-sm-9-5 {
    margin-bottom: 4.75rem;
  }
  .mb-sm-10 {
    margin-bottom: 5rem;
  }
  .mb-sm-auto {
    margin-bottom: auto;
  }
  .ms-sm-0 {
    margin-left: 0;
  }
  .ms-sm-0-5 {
    margin-left: 0.25rem;
  }
  .ms-sm-1 {
    margin-left: 0.5rem;
  }
  .ms-sm-1-5 {
    margin-left: 0.75rem;
  }
  .ms-sm-2 {
    margin-left: 1rem;
  }
  .ms-sm-2-5 {
    margin-left: 1.25rem;
  }
  .ms-sm-3 {
    margin-left: 1.5rem;
  }
  .ms-sm-3-5 {
    margin-left: 1.75rem;
  }
  .ms-sm-4 {
    margin-left: 2rem;
  }
  .ms-sm-5 {
    margin-left: 2.5rem;
  }
  .ms-sm-5-5 {
    margin-left: 2.75rem;
  }
  .ms-sm-6 {
    margin-left: 3rem;
  }
  .ms-sm-7 {
    margin-left: 3.5rem;
  }
  .ms-sm-7-5 {
    margin-left: 3.75rem;
  }
  .ms-sm-8 {
    margin-left: 4rem;
  }
  .ms-sm-9 {
    margin-left: 4.5rem;
  }
  .ms-sm-9-5 {
    margin-left: 4.75rem;
  }
  .ms-sm-10 {
    margin-left: 5rem;
  }
  .ms-sm-auto {
    margin-left: auto;
  }
  .m-sm-n0 {
    margin: 0;
  }
  .m-sm-n0-5 {
    margin: -0.25rem;
  }
  .m-sm-n1 {
    margin: -0.5rem;
  }
  .m-sm-n1-5 {
    margin: -0.75rem;
  }
  .m-sm-n2 {
    margin: -1rem;
  }
  .m-sm-n2-5 {
    margin: -1.25rem;
  }
  .m-sm-n3 {
    margin: -1.5rem;
  }
  .m-sm-n3-5 {
    margin: -1.75rem;
  }
  .m-sm-n4 {
    margin: -2rem;
  }
  .m-sm-n5 {
    margin: -2.5rem;
  }
  .m-sm-n5-5 {
    margin: -2.75rem;
  }
  .m-sm-n6 {
    margin: -3rem;
  }
  .m-sm-n7 {
    margin: -3.5rem;
  }
  .m-sm-n7-5 {
    margin: -3.75rem;
  }
  .m-sm-n8 {
    margin: -4rem;
  }
  .m-sm-n9 {
    margin: -4.5rem;
  }
  .m-sm-n9-5 {
    margin: -4.75rem;
  }
  .m-sm-n10 {
    margin: -5rem;
  }
  .m-sm-nauto {
    margin: -"auto";
  }
  .mx-sm-n0 {
    margin-right: 0;
    margin-left: 0;
  }
  .mx-sm-n0-5 {
    margin-right: -0.25rem;
    margin-left: -0.25rem;
  }
  .mx-sm-n1 {
    margin-right: -0.5rem;
    margin-left: -0.5rem;
  }
  .mx-sm-n1-5 {
    margin-right: -0.75rem;
    margin-left: -0.75rem;
  }
  .mx-sm-n2 {
    margin-right: -1rem;
    margin-left: -1rem;
  }
  .mx-sm-n2-5 {
    margin-right: -1.25rem;
    margin-left: -1.25rem;
  }
  .mx-sm-n3 {
    margin-right: -1.5rem;
    margin-left: -1.5rem;
  }
  .mx-sm-n3-5 {
    margin-right: -1.75rem;
    margin-left: -1.75rem;
  }
  .mx-sm-n4 {
    margin-right: -2rem;
    margin-left: -2rem;
  }
  .mx-sm-n5 {
    margin-right: -2.5rem;
    margin-left: -2.5rem;
  }
  .mx-sm-n5-5 {
    margin-right: -2.75rem;
    margin-left: -2.75rem;
  }
  .mx-sm-n6 {
    margin-right: -3rem;
    margin-left: -3rem;
  }
  .mx-sm-n7 {
    margin-right: -3.5rem;
    margin-left: -3.5rem;
  }
  .mx-sm-n7-5 {
    margin-right: -3.75rem;
    margin-left: -3.75rem;
  }
  .mx-sm-n8 {
    margin-right: -4rem;
    margin-left: -4rem;
  }
  .mx-sm-n9 {
    margin-right: -4.5rem;
    margin-left: -4.5rem;
  }
  .mx-sm-n9-5 {
    margin-right: -4.75rem;
    margin-left: -4.75rem;
  }
  .mx-sm-n10 {
    margin-right: -5rem;
    margin-left: -5rem;
  }
  .mx-sm-nauto {
    margin-right: -"auto";
    margin-left: -"auto";
  }
  .my-sm-n0 {
    margin-top: 0;
    margin-bottom: 0;
  }
  .my-sm-n0-5 {
    margin-top: -0.25rem;
    margin-bottom: -0.25rem;
  }
  .my-sm-n1 {
    margin-top: -0.5rem;
    margin-bottom: -0.5rem;
  }
  .my-sm-n1-5 {
    margin-top: -0.75rem;
    margin-bottom: -0.75rem;
  }
  .my-sm-n2 {
    margin-top: -1rem;
    margin-bottom: -1rem;
  }
  .my-sm-n2-5 {
    margin-top: -1.25rem;
    margin-bottom: -1.25rem;
  }
  .my-sm-n3 {
    margin-top: -1.5rem;
    margin-bottom: -1.5rem;
  }
  .my-sm-n3-5 {
    margin-top: -1.75rem;
    margin-bottom: -1.75rem;
  }
  .my-sm-n4 {
    margin-top: -2rem;
    margin-bottom: -2rem;
  }
  .my-sm-n5 {
    margin-top: -2.5rem;
    margin-bottom: -2.5rem;
  }
  .my-sm-n5-5 {
    margin-top: -2.75rem;
    margin-bottom: -2.75rem;
  }
  .my-sm-n6 {
    margin-top: -3rem;
    margin-bottom: -3rem;
  }
  .my-sm-n7 {
    margin-top: -3.5rem;
    margin-bottom: -3.5rem;
  }
  .my-sm-n7-5 {
    margin-top: -3.75rem;
    margin-bottom: -3.75rem;
  }
  .my-sm-n8 {
    margin-top: -4rem;
    margin-bottom: -4rem;
  }
  .my-sm-n9 {
    margin-top: -4.5rem;
    margin-bottom: -4.5rem;
  }
  .my-sm-n9-5 {
    margin-top: -4.75rem;
    margin-bottom: -4.75rem;
  }
  .my-sm-n10 {
    margin-top: -5rem;
    margin-bottom: -5rem;
  }
  .my-sm-nauto {
    margin-top: -"auto";
    margin-bottom: -"auto";
  }
  .mt-sm-n0 {
    margin-top: 0;
  }
  .mt-sm-n0-5 {
    margin-top: -0.25rem;
  }
  .mt-sm-n1 {
    margin-top: -0.5rem;
  }
  .mt-sm-n1-5 {
    margin-top: -0.75rem;
  }
  .mt-sm-n2 {
    margin-top: -1rem;
  }
  .mt-sm-n2-5 {
    margin-top: -1.25rem;
  }
  .mt-sm-n3 {
    margin-top: -1.5rem;
  }
  .mt-sm-n3-5 {
    margin-top: -1.75rem;
  }
  .mt-sm-n4 {
    margin-top: -2rem;
  }
  .mt-sm-n5 {
    margin-top: -2.5rem;
  }
  .mt-sm-n5-5 {
    margin-top: -2.75rem;
  }
  .mt-sm-n6 {
    margin-top: -3rem;
  }
  .mt-sm-n7 {
    margin-top: -3.5rem;
  }
  .mt-sm-n7-5 {
    margin-top: -3.75rem;
  }
  .mt-sm-n8 {
    margin-top: -4rem;
  }
  .mt-sm-n9 {
    margin-top: -4.5rem;
  }
  .mt-sm-n9-5 {
    margin-top: -4.75rem;
  }
  .mt-sm-n10 {
    margin-top: -5rem;
  }
  .mt-sm-nauto {
    margin-top: -"auto";
  }
  .me-sm-n0 {
    margin-right: 0;
  }
  .me-sm-n0-5 {
    margin-right: -0.25rem;
  }
  .me-sm-n1 {
    margin-right: -0.5rem;
  }
  .me-sm-n1-5 {
    margin-right: -0.75rem;
  }
  .me-sm-n2 {
    margin-right: -1rem;
  }
  .me-sm-n2-5 {
    margin-right: -1.25rem;
  }
  .me-sm-n3 {
    margin-right: -1.5rem;
  }
  .me-sm-n3-5 {
    margin-right: -1.75rem;
  }
  .me-sm-n4 {
    margin-right: -2rem;
  }
  .me-sm-n5 {
    margin-right: -2.5rem;
  }
  .me-sm-n5-5 {
    margin-right: -2.75rem;
  }
  .me-sm-n6 {
    margin-right: -3rem;
  }
  .me-sm-n7 {
    margin-right: -3.5rem;
  }
  .me-sm-n7-5 {
    margin-right: -3.75rem;
  }
  .me-sm-n8 {
    margin-right: -4rem;
  }
  .me-sm-n9 {
    margin-right: -4.5rem;
  }
  .me-sm-n9-5 {
    margin-right: -4.75rem;
  }
  .me-sm-n10 {
    margin-right: -5rem;
  }
  .me-sm-nauto {
    margin-right: -"auto";
  }
  .mb-sm-n0 {
    margin-bottom: 0;
  }
  .mb-sm-n0-5 {
    margin-bottom: -0.25rem;
  }
  .mb-sm-n1 {
    margin-bottom: -0.5rem;
  }
  .mb-sm-n1-5 {
    margin-bottom: -0.75rem;
  }
  .mb-sm-n2 {
    margin-bottom: -1rem;
  }
  .mb-sm-n2-5 {
    margin-bottom: -1.25rem;
  }
  .mb-sm-n3 {
    margin-bottom: -1.5rem;
  }
  .mb-sm-n3-5 {
    margin-bottom: -1.75rem;
  }
  .mb-sm-n4 {
    margin-bottom: -2rem;
  }
  .mb-sm-n5 {
    margin-bottom: -2.5rem;
  }
  .mb-sm-n5-5 {
    margin-bottom: -2.75rem;
  }
  .mb-sm-n6 {
    margin-bottom: -3rem;
  }
  .mb-sm-n7 {
    margin-bottom: -3.5rem;
  }
  .mb-sm-n7-5 {
    margin-bottom: -3.75rem;
  }
  .mb-sm-n8 {
    margin-bottom: -4rem;
  }
  .mb-sm-n9 {
    margin-bottom: -4.5rem;
  }
  .mb-sm-n9-5 {
    margin-bottom: -4.75rem;
  }
  .mb-sm-n10 {
    margin-bottom: -5rem;
  }
  .mb-sm-nauto {
    margin-bottom: -"auto";
  }
  .ms-sm-n0 {
    margin-left: 0;
  }
  .ms-sm-n0-5 {
    margin-left: -0.25rem;
  }
  .ms-sm-n1 {
    margin-left: -0.5rem;
  }
  .ms-sm-n1-5 {
    margin-left: -0.75rem;
  }
  .ms-sm-n2 {
    margin-left: -1rem;
  }
  .ms-sm-n2-5 {
    margin-left: -1.25rem;
  }
  .ms-sm-n3 {
    margin-left: -1.5rem;
  }
  .ms-sm-n3-5 {
    margin-left: -1.75rem;
  }
  .ms-sm-n4 {
    margin-left: -2rem;
  }
  .ms-sm-n5 {
    margin-left: -2.5rem;
  }
  .ms-sm-n5-5 {
    margin-left: -2.75rem;
  }
  .ms-sm-n6 {
    margin-left: -3rem;
  }
  .ms-sm-n7 {
    margin-left: -3.5rem;
  }
  .ms-sm-n7-5 {
    margin-left: -3.75rem;
  }
  .ms-sm-n8 {
    margin-left: -4rem;
  }
  .ms-sm-n9 {
    margin-left: -4.5rem;
  }
  .ms-sm-n9-5 {
    margin-left: -4.75rem;
  }
  .ms-sm-n10 {
    margin-left: -5rem;
  }
  .ms-sm-nauto {
    margin-left: -"auto";
  }
  .p-sm-0 {
    padding: 0;
  }
  .p-sm-0-5 {
    padding: 0.25rem;
  }
  .p-sm-1 {
    padding: 0.5rem;
  }
  .p-sm-1-5 {
    padding: 0.75rem;
  }
  .p-sm-2 {
    padding: 1rem;
  }
  .p-sm-2-5 {
    padding: 1.25rem;
  }
  .p-sm-3 {
    padding: 1.5rem;
  }
  .p-sm-3-5 {
    padding: 1.75rem;
  }
  .p-sm-4 {
    padding: 2rem;
  }
  .p-sm-5 {
    padding: 2.5rem;
  }
  .p-sm-5-5 {
    padding: 2.75rem;
  }
  .p-sm-6 {
    padding: 3rem;
  }
  .p-sm-7 {
    padding: 3.5rem;
  }
  .p-sm-7-5 {
    padding: 3.75rem;
  }
  .p-sm-8 {
    padding: 4rem;
  }
  .p-sm-9 {
    padding: 4.5rem;
  }
  .p-sm-9-5 {
    padding: 4.75rem;
  }
  .p-sm-10 {
    padding: 5rem;
  }
  .p-sm-auto {
    padding: "auto";
  }
  .px-sm-0 {
    padding-right: 0;
    padding-left: 0;
  }
  .px-sm-0-5 {
    padding-right: 0.25rem;
    padding-left: 0.25rem;
  }
  .px-sm-1 {
    padding-right: 0.5rem;
    padding-left: 0.5rem;
  }
  .px-sm-1-5 {
    padding-right: 0.75rem;
    padding-left: 0.75rem;
  }
  .px-sm-2 {
    padding-right: 1rem;
    padding-left: 1rem;
  }
  .px-sm-2-5 {
    padding-right: 1.25rem;
    padding-left: 1.25rem;
  }
  .px-sm-3 {
    padding-right: 1.5rem;
    padding-left: 1.5rem;
  }
  .px-sm-3-5 {
    padding-right: 1.75rem;
    padding-left: 1.75rem;
  }
  .px-sm-4 {
    padding-right: 2rem;
    padding-left: 2rem;
  }
  .px-sm-5 {
    padding-right: 2.5rem;
    padding-left: 2.5rem;
  }
  .px-sm-5-5 {
    padding-right: 2.75rem;
    padding-left: 2.75rem;
  }
  .px-sm-6 {
    padding-right: 3rem;
    padding-left: 3rem;
  }
  .px-sm-7 {
    padding-right: 3.5rem;
    padding-left: 3.5rem;
  }
  .px-sm-7-5 {
    padding-right: 3.75rem;
    padding-left: 3.75rem;
  }
  .px-sm-8 {
    padding-right: 4rem;
    padding-left: 4rem;
  }
  .px-sm-9 {
    padding-right: 4.5rem;
    padding-left: 4.5rem;
  }
  .px-sm-9-5 {
    padding-right: 4.75rem;
    padding-left: 4.75rem;
  }
  .px-sm-10 {
    padding-right: 5rem;
    padding-left: 5rem;
  }
  .px-sm-auto {
    padding-right: "auto";
    padding-left: "auto";
  }
  .py-sm-0 {
    padding-top: 0;
    padding-bottom: 0;
  }
  .py-sm-0-5 {
    padding-top: 0.25rem;
    padding-bottom: 0.25rem;
  }
  .py-sm-1 {
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
  }
  .py-sm-1-5 {
    padding-top: 0.75rem;
    padding-bottom: 0.75rem;
  }
  .py-sm-2 {
    padding-top: 1rem;
    padding-bottom: 1rem;
  }
  .py-sm-2-5 {
    padding-top: 1.25rem;
    padding-bottom: 1.25rem;
  }
  .py-sm-3 {
    padding-top: 1.5rem;
    padding-bottom: 1.5rem;
  }
  .py-sm-3-5 {
    padding-top: 1.75rem;
    padding-bottom: 1.75rem;
  }
  .py-sm-4 {
    padding-top: 2rem;
    padding-bottom: 2rem;
  }
  .py-sm-5 {
    padding-top: 2.5rem;
    padding-bottom: 2.5rem;
  }
  .py-sm-5-5 {
    padding-top: 2.75rem;
    padding-bottom: 2.75rem;
  }
  .py-sm-6 {
    padding-top: 3rem;
    padding-bottom: 3rem;
  }
  .py-sm-7 {
    padding-top: 3.5rem;
    padding-bottom: 3.5rem;
  }
  .py-sm-7-5 {
    padding-top: 3.75rem;
    padding-bottom: 3.75rem;
  }
  .py-sm-8 {
    padding-top: 4rem;
    padding-bottom: 4rem;
  }
  .py-sm-9 {
    padding-top: 4.5rem;
    padding-bottom: 4.5rem;
  }
  .py-sm-9-5 {
    padding-top: 4.75rem;
    padding-bottom: 4.75rem;
  }
  .py-sm-10 {
    padding-top: 5rem;
    padding-bottom: 5rem;
  }
  .py-sm-auto {
    padding-top: "auto";
    padding-bottom: "auto";
  }
  .pt-sm-0 {
    padding-top: 0;
  }
  .pt-sm-0-5 {
    padding-top: 0.25rem;
  }
  .pt-sm-1 {
    padding-top: 0.5rem;
  }
  .pt-sm-1-5 {
    padding-top: 0.75rem;
  }
  .pt-sm-2 {
    padding-top: 1rem;
  }
  .pt-sm-2-5 {
    padding-top: 1.25rem;
  }
  .pt-sm-3 {
    padding-top: 1.5rem;
  }
  .pt-sm-3-5 {
    padding-top: 1.75rem;
  }
  .pt-sm-4 {
    padding-top: 2rem;
  }
  .pt-sm-5 {
    padding-top: 2.5rem;
  }
  .pt-sm-5-5 {
    padding-top: 2.75rem;
  }
  .pt-sm-6 {
    padding-top: 3rem;
  }
  .pt-sm-7 {
    padding-top: 3.5rem;
  }
  .pt-sm-7-5 {
    padding-top: 3.75rem;
  }
  .pt-sm-8 {
    padding-top: 4rem;
  }
  .pt-sm-9 {
    padding-top: 4.5rem;
  }
  .pt-sm-9-5 {
    padding-top: 4.75rem;
  }
  .pt-sm-10 {
    padding-top: 5rem;
  }
  .pt-sm-auto {
    padding-top: "auto";
  }
  .pe-sm-0 {
    padding-right: 0;
  }
  .pe-sm-0-5 {
    padding-right: 0.25rem;
  }
  .pe-sm-1 {
    padding-right: 0.5rem;
  }
  .pe-sm-1-5 {
    padding-right: 0.75rem;
  }
  .pe-sm-2 {
    padding-right: 1rem;
  }
  .pe-sm-2-5 {
    padding-right: 1.25rem;
  }
  .pe-sm-3 {
    padding-right: 1.5rem;
  }
  .pe-sm-3-5 {
    padding-right: 1.75rem;
  }
  .pe-sm-4 {
    padding-right: 2rem;
  }
  .pe-sm-5 {
    padding-right: 2.5rem;
  }
  .pe-sm-5-5 {
    padding-right: 2.75rem;
  }
  .pe-sm-6 {
    padding-right: 3rem;
  }
  .pe-sm-7 {
    padding-right: 3.5rem;
  }
  .pe-sm-7-5 {
    padding-right: 3.75rem;
  }
  .pe-sm-8 {
    padding-right: 4rem;
  }
  .pe-sm-9 {
    padding-right: 4.5rem;
  }
  .pe-sm-9-5 {
    padding-right: 4.75rem;
  }
  .pe-sm-10 {
    padding-right: 5rem;
  }
  .pe-sm-auto {
    padding-right: "auto";
  }
  .pb-sm-0 {
    padding-bottom: 0;
  }
  .pb-sm-0-5 {
    padding-bottom: 0.25rem;
  }
  .pb-sm-1 {
    padding-bottom: 0.5rem;
  }
  .pb-sm-1-5 {
    padding-bottom: 0.75rem;
  }
  .pb-sm-2 {
    padding-bottom: 1rem;
  }
  .pb-sm-2-5 {
    padding-bottom: 1.25rem;
  }
  .pb-sm-3 {
    padding-bottom: 1.5rem;
  }
  .pb-sm-3-5 {
    padding-bottom: 1.75rem;
  }
  .pb-sm-4 {
    padding-bottom: 2rem;
  }
  .pb-sm-5 {
    padding-bottom: 2.5rem;
  }
  .pb-sm-5-5 {
    padding-bottom: 2.75rem;
  }
  .pb-sm-6 {
    padding-bottom: 3rem;
  }
  .pb-sm-7 {
    padding-bottom: 3.5rem;
  }
  .pb-sm-7-5 {
    padding-bottom: 3.75rem;
  }
  .pb-sm-8 {
    padding-bottom: 4rem;
  }
  .pb-sm-9 {
    padding-bottom: 4.5rem;
  }
  .pb-sm-9-5 {
    padding-bottom: 4.75rem;
  }
  .pb-sm-10 {
    padding-bottom: 5rem;
  }
  .pb-sm-auto {
    padding-bottom: "auto";
  }
  .ps-sm-0 {
    padding-left: 0;
  }
  .ps-sm-0-5 {
    padding-left: 0.25rem;
  }
  .ps-sm-1 {
    padding-left: 0.5rem;
  }
  .ps-sm-1-5 {
    padding-left: 0.75rem;
  }
  .ps-sm-2 {
    padding-left: 1rem;
  }
  .ps-sm-2-5 {
    padding-left: 1.25rem;
  }
  .ps-sm-3 {
    padding-left: 1.5rem;
  }
  .ps-sm-3-5 {
    padding-left: 1.75rem;
  }
  .ps-sm-4 {
    padding-left: 2rem;
  }
  .ps-sm-5 {
    padding-left: 2.5rem;
  }
  .ps-sm-5-5 {
    padding-left: 2.75rem;
  }
  .ps-sm-6 {
    padding-left: 3rem;
  }
  .ps-sm-7 {
    padding-left: 3.5rem;
  }
  .ps-sm-7-5 {
    padding-left: 3.75rem;
  }
  .ps-sm-8 {
    padding-left: 4rem;
  }
  .ps-sm-9 {
    padding-left: 4.5rem;
  }
  .ps-sm-9-5 {
    padding-left: 4.75rem;
  }
  .ps-sm-10 {
    padding-left: 5rem;
  }
  .ps-sm-auto {
    padding-left: "auto";
  }
  .gap-sm-0 {
    gap: 0;
  }
  .gap-sm-0-5 {
    gap: 0.25rem;
  }
  .gap-sm-1 {
    gap: 0.5rem;
  }
  .gap-sm-1-5 {
    gap: 0.75rem;
  }
  .gap-sm-2 {
    gap: 1rem;
  }
  .gap-sm-2-5 {
    gap: 1.25rem;
  }
  .gap-sm-3 {
    gap: 1.5rem;
  }
  .gap-sm-3-5 {
    gap: 1.75rem;
  }
  .gap-sm-4 {
    gap: 2rem;
  }
  .gap-sm-5 {
    gap: 2.5rem;
  }
  .gap-sm-5-5 {
    gap: 2.75rem;
  }
  .gap-sm-6 {
    gap: 3rem;
  }
  .gap-sm-7 {
    gap: 3.5rem;
  }
  .gap-sm-7-5 {
    gap: 3.75rem;
  }
  .gap-sm-8 {
    gap: 4rem;
  }
  .gap-sm-9 {
    gap: 4.5rem;
  }
  .gap-sm-9-5 {
    gap: 4.75rem;
  }
  .gap-sm-10 {
    gap: 5rem;
  }
  .gap-sm-auto {
    gap: "auto";
  }
  .text-sm-start {
    text-align: left;
  }
  .text-sm-end {
    text-align: right;
  }
  .text-sm-center {
    text-align: center;
  }
  .rounded-sm {
    border-radius: var(--bs-border-radius);
  }
  .rounded-sm-0 {
    border-radius: 0;
  }
  .rounded-sm-1 {
    border-radius: var(--bs-border-radius-sm);
  }
  .rounded-sm-2 {
    border-radius: var(--bs-border-radius);
  }
  .rounded-sm-3 {
    border-radius: var(--bs-border-radius-lg);
  }
  .rounded-sm-4 {
    border-radius: var(--bs-border-radius-xl);
  }
  .rounded-sm-5 {
    border-radius: var(--bs-border-radius-2xl);
  }
  .rounded-sm-circle {
    border-radius: 50%;
  }
  .rounded-sm-pill {
    border-radius: var(--bs-border-radius-pill);
  }
}
@media (min-width: 768px) {
  .float-md-start {
    float: left;
  }
  .float-md-end {
    float: right;
  }
  .float-md-none {
    float: none;
  }
  .d-md-inline {
    display: inline;
  }
  .d-md-inline-block {
    display: inline-block;
  }
  .d-md-block {
    display: block;
  }
  .d-md-grid {
    display: grid;
  }
  .d-md-table {
    display: table;
  }
  .d-md-table-row {
    display: table-row;
  }
  .d-md-table-cell {
    display: table-cell;
  }
  .d-md-flex {
    display: flex;
  }
  .d-md-inline-flex {
    display: inline-flex;
  }
  .d-md-none {
    display: none;
  }
  .w-md-25 {
    width: 25%;
  }
  .w-md-50 {
    width: 50%;
  }
  .w-md-75 {
    width: 75%;
  }
  .w-md-100 {
    width: 100%;
  }
  .w-md-auto {
    width: auto;
  }
  .flex-md-fill {
    flex: 1 1 auto;
  }
  .flex-md-row {
    flex-direction: row;
  }
  .flex-md-column {
    flex-direction: column;
  }
  .flex-md-row-reverse {
    flex-direction: row-reverse;
  }
  .flex-md-column-reverse {
    flex-direction: column-reverse;
  }
  .flex-md-grow-0 {
    flex-grow: 0;
  }
  .flex-md-grow-1 {
    flex-grow: 1;
  }
  .flex-md-shrink-0 {
    flex-shrink: 0;
  }
  .flex-md-shrink-1 {
    flex-shrink: 1;
  }
  .flex-md-wrap {
    flex-wrap: wrap;
  }
  .flex-md-nowrap {
    flex-wrap: nowrap;
  }
  .flex-md-wrap-reverse {
    flex-wrap: wrap-reverse;
  }
  .justify-content-md-start {
    justify-content: flex-start;
  }
  .justify-content-md-end {
    justify-content: flex-end;
  }
  .justify-content-md-center {
    justify-content: center;
  }
  .justify-content-md-between {
    justify-content: space-between;
  }
  .justify-content-md-around {
    justify-content: space-around;
  }
  .justify-content-md-evenly {
    justify-content: space-evenly;
  }
  .align-items-md-start {
    align-items: flex-start;
  }
  .align-items-md-end {
    align-items: flex-end;
  }
  .align-items-md-center {
    align-items: center;
  }
  .align-items-md-baseline {
    align-items: baseline;
  }
  .align-items-md-stretch {
    align-items: stretch;
  }
  .align-content-md-start {
    align-content: flex-start;
  }
  .align-content-md-end {
    align-content: flex-end;
  }
  .align-content-md-center {
    align-content: center;
  }
  .align-content-md-between {
    align-content: space-between;
  }
  .align-content-md-around {
    align-content: space-around;
  }
  .align-content-md-stretch {
    align-content: stretch;
  }
  .align-self-md-auto {
    align-self: auto;
  }
  .align-self-md-start {
    align-self: flex-start;
  }
  .align-self-md-end {
    align-self: flex-end;
  }
  .align-self-md-center {
    align-self: center;
  }
  .align-self-md-baseline {
    align-self: baseline;
  }
  .align-self-md-stretch {
    align-self: stretch;
  }
  .order-md-first {
    order: -1;
  }
  .order-md-0 {
    order: 0;
  }
  .order-md-1 {
    order: 1;
  }
  .order-md-2 {
    order: 2;
  }
  .order-md-3 {
    order: 3;
  }
  .order-md-4 {
    order: 4;
  }
  .order-md-5 {
    order: 5;
  }
  .order-md-last {
    order: 6;
  }
  .m-md-0 {
    margin: 0;
  }
  .m-md-0-5 {
    margin: 0.25rem;
  }
  .m-md-1 {
    margin: 0.5rem;
  }
  .m-md-1-5 {
    margin: 0.75rem;
  }
  .m-md-2 {
    margin: 1rem;
  }
  .m-md-2-5 {
    margin: 1.25rem;
  }
  .m-md-3 {
    margin: 1.5rem;
  }
  .m-md-3-5 {
    margin: 1.75rem;
  }
  .m-md-4 {
    margin: 2rem;
  }
  .m-md-5 {
    margin: 2.5rem;
  }
  .m-md-5-5 {
    margin: 2.75rem;
  }
  .m-md-6 {
    margin: 3rem;
  }
  .m-md-7 {
    margin: 3.5rem;
  }
  .m-md-7-5 {
    margin: 3.75rem;
  }
  .m-md-8 {
    margin: 4rem;
  }
  .m-md-9 {
    margin: 4.5rem;
  }
  .m-md-9-5 {
    margin: 4.75rem;
  }
  .m-md-10 {
    margin: 5rem;
  }
  .m-md-auto {
    margin: auto;
  }
  .mx-md-0 {
    margin-right: 0;
    margin-left: 0;
  }
  .mx-md-0-5 {
    margin-right: 0.25rem;
    margin-left: 0.25rem;
  }
  .mx-md-1 {
    margin-right: 0.5rem;
    margin-left: 0.5rem;
  }
  .mx-md-1-5 {
    margin-right: 0.75rem;
    margin-left: 0.75rem;
  }
  .mx-md-2 {
    margin-right: 1rem;
    margin-left: 1rem;
  }
  .mx-md-2-5 {
    margin-right: 1.25rem;
    margin-left: 1.25rem;
  }
  .mx-md-3 {
    margin-right: 1.5rem;
    margin-left: 1.5rem;
  }
  .mx-md-3-5 {
    margin-right: 1.75rem;
    margin-left: 1.75rem;
  }
  .mx-md-4 {
    margin-right: 2rem;
    margin-left: 2rem;
  }
  .mx-md-5 {
    margin-right: 2.5rem;
    margin-left: 2.5rem;
  }
  .mx-md-5-5 {
    margin-right: 2.75rem;
    margin-left: 2.75rem;
  }
  .mx-md-6 {
    margin-right: 3rem;
    margin-left: 3rem;
  }
  .mx-md-7 {
    margin-right: 3.5rem;
    margin-left: 3.5rem;
  }
  .mx-md-7-5 {
    margin-right: 3.75rem;
    margin-left: 3.75rem;
  }
  .mx-md-8 {
    margin-right: 4rem;
    margin-left: 4rem;
  }
  .mx-md-9 {
    margin-right: 4.5rem;
    margin-left: 4.5rem;
  }
  .mx-md-9-5 {
    margin-right: 4.75rem;
    margin-left: 4.75rem;
  }
  .mx-md-10 {
    margin-right: 5rem;
    margin-left: 5rem;
  }
  .mx-md-auto {
    margin-right: auto;
    margin-left: auto;
  }
  .my-md-0 {
    margin-top: 0;
    margin-bottom: 0;
  }
  .my-md-0-5 {
    margin-top: 0.25rem;
    margin-bottom: 0.25rem;
  }
  .my-md-1 {
    margin-top: 0.5rem;
    margin-bottom: 0.5rem;
  }
  .my-md-1-5 {
    margin-top: 0.75rem;
    margin-bottom: 0.75rem;
  }
  .my-md-2 {
    margin-top: 1rem;
    margin-bottom: 1rem;
  }
  .my-md-2-5 {
    margin-top: 1.25rem;
    margin-bottom: 1.25rem;
  }
  .my-md-3 {
    margin-top: 1.5rem;
    margin-bottom: 1.5rem;
  }
  .my-md-3-5 {
    margin-top: 1.75rem;
    margin-bottom: 1.75rem;
  }
  .my-md-4 {
    margin-top: 2rem;
    margin-bottom: 2rem;
  }
  .my-md-5 {
    margin-top: 2.5rem;
    margin-bottom: 2.5rem;
  }
  .my-md-5-5 {
    margin-top: 2.75rem;
    margin-bottom: 2.75rem;
  }
  .my-md-6 {
    margin-top: 3rem;
    margin-bottom: 3rem;
  }
  .my-md-7 {
    margin-top: 3.5rem;
    margin-bottom: 3.5rem;
  }
  .my-md-7-5 {
    margin-top: 3.75rem;
    margin-bottom: 3.75rem;
  }
  .my-md-8 {
    margin-top: 4rem;
    margin-bottom: 4rem;
  }
  .my-md-9 {
    margin-top: 4.5rem;
    margin-bottom: 4.5rem;
  }
  .my-md-9-5 {
    margin-top: 4.75rem;
    margin-bottom: 4.75rem;
  }
  .my-md-10 {
    margin-top: 5rem;
    margin-bottom: 5rem;
  }
  .my-md-auto {
    margin-top: auto;
    margin-bottom: auto;
  }
  .mt-md-0 {
    margin-top: 0;
  }
  .mt-md-0-5 {
    margin-top: 0.25rem;
  }
  .mt-md-1 {
    margin-top: 0.5rem;
  }
  .mt-md-1-5 {
    margin-top: 0.75rem;
  }
  .mt-md-2 {
    margin-top: 1rem;
  }
  .mt-md-2-5 {
    margin-top: 1.25rem;
  }
  .mt-md-3 {
    margin-top: 1.5rem;
  }
  .mt-md-3-5 {
    margin-top: 1.75rem;
  }
  .mt-md-4 {
    margin-top: 2rem;
  }
  .mt-md-5 {
    margin-top: 2.5rem;
  }
  .mt-md-5-5 {
    margin-top: 2.75rem;
  }
  .mt-md-6 {
    margin-top: 3rem;
  }
  .mt-md-7 {
    margin-top: 3.5rem;
  }
  .mt-md-7-5 {
    margin-top: 3.75rem;
  }
  .mt-md-8 {
    margin-top: 4rem;
  }
  .mt-md-9 {
    margin-top: 4.5rem;
  }
  .mt-md-9-5 {
    margin-top: 4.75rem;
  }
  .mt-md-10 {
    margin-top: 5rem;
  }
  .mt-md-auto {
    margin-top: auto;
  }
  .me-md-0 {
    margin-right: 0;
  }
  .me-md-0-5 {
    margin-right: 0.25rem;
  }
  .me-md-1 {
    margin-right: 0.5rem;
  }
  .me-md-1-5 {
    margin-right: 0.75rem;
  }
  .me-md-2 {
    margin-right: 1rem;
  }
  .me-md-2-5 {
    margin-right: 1.25rem;
  }
  .me-md-3 {
    margin-right: 1.5rem;
  }
  .me-md-3-5 {
    margin-right: 1.75rem;
  }
  .me-md-4 {
    margin-right: 2rem;
  }
  .me-md-5 {
    margin-right: 2.5rem;
  }
  .me-md-5-5 {
    margin-right: 2.75rem;
  }
  .me-md-6 {
    margin-right: 3rem;
  }
  .me-md-7 {
    margin-right: 3.5rem;
  }
  .me-md-7-5 {
    margin-right: 3.75rem;
  }
  .me-md-8 {
    margin-right: 4rem;
  }
  .me-md-9 {
    margin-right: 4.5rem;
  }
  .me-md-9-5 {
    margin-right: 4.75rem;
  }
  .me-md-10 {
    margin-right: 5rem;
  }
  .me-md-auto {
    margin-right: auto;
  }
  .mb-md-0 {
    margin-bottom: 0;
  }
  .mb-md-0-5 {
    margin-bottom: 0.25rem;
  }
  .mb-md-1 {
    margin-bottom: 0.5rem;
  }
  .mb-md-1-5 {
    margin-bottom: 0.75rem;
  }
  .mb-md-2 {
    margin-bottom: 1rem;
  }
  .mb-md-2-5 {
    margin-bottom: 1.25rem;
  }
  .mb-md-3 {
    margin-bottom: 1.5rem;
  }
  .mb-md-3-5 {
    margin-bottom: 1.75rem;
  }
  .mb-md-4 {
    margin-bottom: 2rem;
  }
  .mb-md-5 {
    margin-bottom: 2.5rem;
  }
  .mb-md-5-5 {
    margin-bottom: 2.75rem;
  }
  .mb-md-6 {
    margin-bottom: 3rem;
  }
  .mb-md-7 {
    margin-bottom: 3.5rem;
  }
  .mb-md-7-5 {
    margin-bottom: 3.75rem;
  }
  .mb-md-8 {
    margin-bottom: 4rem;
  }
  .mb-md-9 {
    margin-bottom: 4.5rem;
  }
  .mb-md-9-5 {
    margin-bottom: 4.75rem;
  }
  .mb-md-10 {
    margin-bottom: 5rem;
  }
  .mb-md-auto {
    margin-bottom: auto;
  }
  .ms-md-0 {
    margin-left: 0;
  }
  .ms-md-0-5 {
    margin-left: 0.25rem;
  }
  .ms-md-1 {
    margin-left: 0.5rem;
  }
  .ms-md-1-5 {
    margin-left: 0.75rem;
  }
  .ms-md-2 {
    margin-left: 1rem;
  }
  .ms-md-2-5 {
    margin-left: 1.25rem;
  }
  .ms-md-3 {
    margin-left: 1.5rem;
  }
  .ms-md-3-5 {
    margin-left: 1.75rem;
  }
  .ms-md-4 {
    margin-left: 2rem;
  }
  .ms-md-5 {
    margin-left: 2.5rem;
  }
  .ms-md-5-5 {
    margin-left: 2.75rem;
  }
  .ms-md-6 {
    margin-left: 3rem;
  }
  .ms-md-7 {
    margin-left: 3.5rem;
  }
  .ms-md-7-5 {
    margin-left: 3.75rem;
  }
  .ms-md-8 {
    margin-left: 4rem;
  }
  .ms-md-9 {
    margin-left: 4.5rem;
  }
  .ms-md-9-5 {
    margin-left: 4.75rem;
  }
  .ms-md-10 {
    margin-left: 5rem;
  }
  .ms-md-auto {
    margin-left: auto;
  }
  .m-md-n0 {
    margin: 0;
  }
  .m-md-n0-5 {
    margin: -0.25rem;
  }
  .m-md-n1 {
    margin: -0.5rem;
  }
  .m-md-n1-5 {
    margin: -0.75rem;
  }
  .m-md-n2 {
    margin: -1rem;
  }
  .m-md-n2-5 {
    margin: -1.25rem;
  }
  .m-md-n3 {
    margin: -1.5rem;
  }
  .m-md-n3-5 {
    margin: -1.75rem;
  }
  .m-md-n4 {
    margin: -2rem;
  }
  .m-md-n5 {
    margin: -2.5rem;
  }
  .m-md-n5-5 {
    margin: -2.75rem;
  }
  .m-md-n6 {
    margin: -3rem;
  }
  .m-md-n7 {
    margin: -3.5rem;
  }
  .m-md-n7-5 {
    margin: -3.75rem;
  }
  .m-md-n8 {
    margin: -4rem;
  }
  .m-md-n9 {
    margin: -4.5rem;
  }
  .m-md-n9-5 {
    margin: -4.75rem;
  }
  .m-md-n10 {
    margin: -5rem;
  }
  .m-md-nauto {
    margin: -"auto";
  }
  .mx-md-n0 {
    margin-right: 0;
    margin-left: 0;
  }
  .mx-md-n0-5 {
    margin-right: -0.25rem;
    margin-left: -0.25rem;
  }
  .mx-md-n1 {
    margin-right: -0.5rem;
    margin-left: -0.5rem;
  }
  .mx-md-n1-5 {
    margin-right: -0.75rem;
    margin-left: -0.75rem;
  }
  .mx-md-n2 {
    margin-right: -1rem;
    margin-left: -1rem;
  }
  .mx-md-n2-5 {
    margin-right: -1.25rem;
    margin-left: -1.25rem;
  }
  .mx-md-n3 {
    margin-right: -1.5rem;
    margin-left: -1.5rem;
  }
  .mx-md-n3-5 {
    margin-right: -1.75rem;
    margin-left: -1.75rem;
  }
  .mx-md-n4 {
    margin-right: -2rem;
    margin-left: -2rem;
  }
  .mx-md-n5 {
    margin-right: -2.5rem;
    margin-left: -2.5rem;
  }
  .mx-md-n5-5 {
    margin-right: -2.75rem;
    margin-left: -2.75rem;
  }
  .mx-md-n6 {
    margin-right: -3rem;
    margin-left: -3rem;
  }
  .mx-md-n7 {
    margin-right: -3.5rem;
    margin-left: -3.5rem;
  }
  .mx-md-n7-5 {
    margin-right: -3.75rem;
    margin-left: -3.75rem;
  }
  .mx-md-n8 {
    margin-right: -4rem;
    margin-left: -4rem;
  }
  .mx-md-n9 {
    margin-right: -4.5rem;
    margin-left: -4.5rem;
  }
  .mx-md-n9-5 {
    margin-right: -4.75rem;
    margin-left: -4.75rem;
  }
  .mx-md-n10 {
    margin-right: -5rem;
    margin-left: -5rem;
  }
  .mx-md-nauto {
    margin-right: -"auto";
    margin-left: -"auto";
  }
  .my-md-n0 {
    margin-top: 0;
    margin-bottom: 0;
  }
  .my-md-n0-5 {
    margin-top: -0.25rem;
    margin-bottom: -0.25rem;
  }
  .my-md-n1 {
    margin-top: -0.5rem;
    margin-bottom: -0.5rem;
  }
  .my-md-n1-5 {
    margin-top: -0.75rem;
    margin-bottom: -0.75rem;
  }
  .my-md-n2 {
    margin-top: -1rem;
    margin-bottom: -1rem;
  }
  .my-md-n2-5 {
    margin-top: -1.25rem;
    margin-bottom: -1.25rem;
  }
  .my-md-n3 {
    margin-top: -1.5rem;
    margin-bottom: -1.5rem;
  }
  .my-md-n3-5 {
    margin-top: -1.75rem;
    margin-bottom: -1.75rem;
  }
  .my-md-n4 {
    margin-top: -2rem;
    margin-bottom: -2rem;
  }
  .my-md-n5 {
    margin-top: -2.5rem;
    margin-bottom: -2.5rem;
  }
  .my-md-n5-5 {
    margin-top: -2.75rem;
    margin-bottom: -2.75rem;
  }
  .my-md-n6 {
    margin-top: -3rem;
    margin-bottom: -3rem;
  }
  .my-md-n7 {
    margin-top: -3.5rem;
    margin-bottom: -3.5rem;
  }
  .my-md-n7-5 {
    margin-top: -3.75rem;
    margin-bottom: -3.75rem;
  }
  .my-md-n8 {
    margin-top: -4rem;
    margin-bottom: -4rem;
  }
  .my-md-n9 {
    margin-top: -4.5rem;
    margin-bottom: -4.5rem;
  }
  .my-md-n9-5 {
    margin-top: -4.75rem;
    margin-bottom: -4.75rem;
  }
  .my-md-n10 {
    margin-top: -5rem;
    margin-bottom: -5rem;
  }
  .my-md-nauto {
    margin-top: -"auto";
    margin-bottom: -"auto";
  }
  .mt-md-n0 {
    margin-top: 0;
  }
  .mt-md-n0-5 {
    margin-top: -0.25rem;
  }
  .mt-md-n1 {
    margin-top: -0.5rem;
  }
  .mt-md-n1-5 {
    margin-top: -0.75rem;
  }
  .mt-md-n2 {
    margin-top: -1rem;
  }
  .mt-md-n2-5 {
    margin-top: -1.25rem;
  }
  .mt-md-n3 {
    margin-top: -1.5rem;
  }
  .mt-md-n3-5 {
    margin-top: -1.75rem;
  }
  .mt-md-n4 {
    margin-top: -2rem;
  }
  .mt-md-n5 {
    margin-top: -2.5rem;
  }
  .mt-md-n5-5 {
    margin-top: -2.75rem;
  }
  .mt-md-n6 {
    margin-top: -3rem;
  }
  .mt-md-n7 {
    margin-top: -3.5rem;
  }
  .mt-md-n7-5 {
    margin-top: -3.75rem;
  }
  .mt-md-n8 {
    margin-top: -4rem;
  }
  .mt-md-n9 {
    margin-top: -4.5rem;
  }
  .mt-md-n9-5 {
    margin-top: -4.75rem;
  }
  .mt-md-n10 {
    margin-top: -5rem;
  }
  .mt-md-nauto {
    margin-top: -"auto";
  }
  .me-md-n0 {
    margin-right: 0;
  }
  .me-md-n0-5 {
    margin-right: -0.25rem;
  }
  .me-md-n1 {
    margin-right: -0.5rem;
  }
  .me-md-n1-5 {
    margin-right: -0.75rem;
  }
  .me-md-n2 {
    margin-right: -1rem;
  }
  .me-md-n2-5 {
    margin-right: -1.25rem;
  }
  .me-md-n3 {
    margin-right: -1.5rem;
  }
  .me-md-n3-5 {
    margin-right: -1.75rem;
  }
  .me-md-n4 {
    margin-right: -2rem;
  }
  .me-md-n5 {
    margin-right: -2.5rem;
  }
  .me-md-n5-5 {
    margin-right: -2.75rem;
  }
  .me-md-n6 {
    margin-right: -3rem;
  }
  .me-md-n7 {
    margin-right: -3.5rem;
  }
  .me-md-n7-5 {
    margin-right: -3.75rem;
  }
  .me-md-n8 {
    margin-right: -4rem;
  }
  .me-md-n9 {
    margin-right: -4.5rem;
  }
  .me-md-n9-5 {
    margin-right: -4.75rem;
  }
  .me-md-n10 {
    margin-right: -5rem;
  }
  .me-md-nauto {
    margin-right: -"auto";
  }
  .mb-md-n0 {
    margin-bottom: 0;
  }
  .mb-md-n0-5 {
    margin-bottom: -0.25rem;
  }
  .mb-md-n1 {
    margin-bottom: -0.5rem;
  }
  .mb-md-n1-5 {
    margin-bottom: -0.75rem;
  }
  .mb-md-n2 {
    margin-bottom: -1rem;
  }
  .mb-md-n2-5 {
    margin-bottom: -1.25rem;
  }
  .mb-md-n3 {
    margin-bottom: -1.5rem;
  }
  .mb-md-n3-5 {
    margin-bottom: -1.75rem;
  }
  .mb-md-n4 {
    margin-bottom: -2rem;
  }
  .mb-md-n5 {
    margin-bottom: -2.5rem;
  }
  .mb-md-n5-5 {
    margin-bottom: -2.75rem;
  }
  .mb-md-n6 {
    margin-bottom: -3rem;
  }
  .mb-md-n7 {
    margin-bottom: -3.5rem;
  }
  .mb-md-n7-5 {
    margin-bottom: -3.75rem;
  }
  .mb-md-n8 {
    margin-bottom: -4rem;
  }
  .mb-md-n9 {
    margin-bottom: -4.5rem;
  }
  .mb-md-n9-5 {
    margin-bottom: -4.75rem;
  }
  .mb-md-n10 {
    margin-bottom: -5rem;
  }
  .mb-md-nauto {
    margin-bottom: -"auto";
  }
  .ms-md-n0 {
    margin-left: 0;
  }
  .ms-md-n0-5 {
    margin-left: -0.25rem;
  }
  .ms-md-n1 {
    margin-left: -0.5rem;
  }
  .ms-md-n1-5 {
    margin-left: -0.75rem;
  }
  .ms-md-n2 {
    margin-left: -1rem;
  }
  .ms-md-n2-5 {
    margin-left: -1.25rem;
  }
  .ms-md-n3 {
    margin-left: -1.5rem;
  }
  .ms-md-n3-5 {
    margin-left: -1.75rem;
  }
  .ms-md-n4 {
    margin-left: -2rem;
  }
  .ms-md-n5 {
    margin-left: -2.5rem;
  }
  .ms-md-n5-5 {
    margin-left: -2.75rem;
  }
  .ms-md-n6 {
    margin-left: -3rem;
  }
  .ms-md-n7 {
    margin-left: -3.5rem;
  }
  .ms-md-n7-5 {
    margin-left: -3.75rem;
  }
  .ms-md-n8 {
    margin-left: -4rem;
  }
  .ms-md-n9 {
    margin-left: -4.5rem;
  }
  .ms-md-n9-5 {
    margin-left: -4.75rem;
  }
  .ms-md-n10 {
    margin-left: -5rem;
  }
  .ms-md-nauto {
    margin-left: -"auto";
  }
  .p-md-0 {
    padding: 0;
  }
  .p-md-0-5 {
    padding: 0.25rem;
  }
  .p-md-1 {
    padding: 0.5rem;
  }
  .p-md-1-5 {
    padding: 0.75rem;
  }
  .p-md-2 {
    padding: 1rem;
  }
  .p-md-2-5 {
    padding: 1.25rem;
  }
  .p-md-3 {
    padding: 1.5rem;
  }
  .p-md-3-5 {
    padding: 1.75rem;
  }
  .p-md-4 {
    padding: 2rem;
  }
  .p-md-5 {
    padding: 2.5rem;
  }
  .p-md-5-5 {
    padding: 2.75rem;
  }
  .p-md-6 {
    padding: 3rem;
  }
  .p-md-7 {
    padding: 3.5rem;
  }
  .p-md-7-5 {
    padding: 3.75rem;
  }
  .p-md-8 {
    padding: 4rem;
  }
  .p-md-9 {
    padding: 4.5rem;
  }
  .p-md-9-5 {
    padding: 4.75rem;
  }
  .p-md-10 {
    padding: 5rem;
  }
  .p-md-auto {
    padding: "auto";
  }
  .px-md-0 {
    padding-right: 0;
    padding-left: 0;
  }
  .px-md-0-5 {
    padding-right: 0.25rem;
    padding-left: 0.25rem;
  }
  .px-md-1 {
    padding-right: 0.5rem;
    padding-left: 0.5rem;
  }
  .px-md-1-5 {
    padding-right: 0.75rem;
    padding-left: 0.75rem;
  }
  .px-md-2 {
    padding-right: 1rem;
    padding-left: 1rem;
  }
  .px-md-2-5 {
    padding-right: 1.25rem;
    padding-left: 1.25rem;
  }
  .px-md-3 {
    padding-right: 1.5rem;
    padding-left: 1.5rem;
  }
  .px-md-3-5 {
    padding-right: 1.75rem;
    padding-left: 1.75rem;
  }
  .px-md-4 {
    padding-right: 2rem;
    padding-left: 2rem;
  }
  .px-md-5 {
    padding-right: 2.5rem;
    padding-left: 2.5rem;
  }
  .px-md-5-5 {
    padding-right: 2.75rem;
    padding-left: 2.75rem;
  }
  .px-md-6 {
    padding-right: 3rem;
    padding-left: 3rem;
  }
  .px-md-7 {
    padding-right: 3.5rem;
    padding-left: 3.5rem;
  }
  .px-md-7-5 {
    padding-right: 3.75rem;
    padding-left: 3.75rem;
  }
  .px-md-8 {
    padding-right: 4rem;
    padding-left: 4rem;
  }
  .px-md-9 {
    padding-right: 4.5rem;
    padding-left: 4.5rem;
  }
  .px-md-9-5 {
    padding-right: 4.75rem;
    padding-left: 4.75rem;
  }
  .px-md-10 {
    padding-right: 5rem;
    padding-left: 5rem;
  }
  .px-md-auto {
    padding-right: "auto";
    padding-left: "auto";
  }
  .py-md-0 {
    padding-top: 0;
    padding-bottom: 0;
  }
  .py-md-0-5 {
    padding-top: 0.25rem;
    padding-bottom: 0.25rem;
  }
  .py-md-1 {
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
  }
  .py-md-1-5 {
    padding-top: 0.75rem;
    padding-bottom: 0.75rem;
  }
  .py-md-2 {
    padding-top: 1rem;
    padding-bottom: 1rem;
  }
  .py-md-2-5 {
    padding-top: 1.25rem;
    padding-bottom: 1.25rem;
  }
  .py-md-3 {
    padding-top: 1.5rem;
    padding-bottom: 1.5rem;
  }
  .py-md-3-5 {
    padding-top: 1.75rem;
    padding-bottom: 1.75rem;
  }
  .py-md-4 {
    padding-top: 2rem;
    padding-bottom: 2rem;
  }
  .py-md-5 {
    padding-top: 2.5rem;
    padding-bottom: 2.5rem;
  }
  .py-md-5-5 {
    padding-top: 2.75rem;
    padding-bottom: 2.75rem;
  }
  .py-md-6 {
    padding-top: 3rem;
    padding-bottom: 3rem;
  }
  .py-md-7 {
    padding-top: 3.5rem;
    padding-bottom: 3.5rem;
  }
  .py-md-7-5 {
    padding-top: 3.75rem;
    padding-bottom: 3.75rem;
  }
  .py-md-8 {
    padding-top: 4rem;
    padding-bottom: 4rem;
  }
  .py-md-9 {
    padding-top: 4.5rem;
    padding-bottom: 4.5rem;
  }
  .py-md-9-5 {
    padding-top: 4.75rem;
    padding-bottom: 4.75rem;
  }
  .py-md-10 {
    padding-top: 5rem;
    padding-bottom: 5rem;
  }
  .py-md-auto {
    padding-top: "auto";
    padding-bottom: "auto";
  }
  .pt-md-0 {
    padding-top: 0;
  }
  .pt-md-0-5 {
    padding-top: 0.25rem;
  }
  .pt-md-1 {
    padding-top: 0.5rem;
  }
  .pt-md-1-5 {
    padding-top: 0.75rem;
  }
  .pt-md-2 {
    padding-top: 1rem;
  }
  .pt-md-2-5 {
    padding-top: 1.25rem;
  }
  .pt-md-3 {
    padding-top: 1.5rem;
  }
  .pt-md-3-5 {
    padding-top: 1.75rem;
  }
  .pt-md-4 {
    padding-top: 2rem;
  }
  .pt-md-5 {
    padding-top: 2.5rem;
  }
  .pt-md-5-5 {
    padding-top: 2.75rem;
  }
  .pt-md-6 {
    padding-top: 3rem;
  }
  .pt-md-7 {
    padding-top: 3.5rem;
  }
  .pt-md-7-5 {
    padding-top: 3.75rem;
  }
  .pt-md-8 {
    padding-top: 4rem;
  }
  .pt-md-9 {
    padding-top: 4.5rem;
  }
  .pt-md-9-5 {
    padding-top: 4.75rem;
  }
  .pt-md-10 {
    padding-top: 5rem;
  }
  .pt-md-auto {
    padding-top: "auto";
  }
  .pe-md-0 {
    padding-right: 0;
  }
  .pe-md-0-5 {
    padding-right: 0.25rem;
  }
  .pe-md-1 {
    padding-right: 0.5rem;
  }
  .pe-md-1-5 {
    padding-right: 0.75rem;
  }
  .pe-md-2 {
    padding-right: 1rem;
  }
  .pe-md-2-5 {
    padding-right: 1.25rem;
  }
  .pe-md-3 {
    padding-right: 1.5rem;
  }
  .pe-md-3-5 {
    padding-right: 1.75rem;
  }
  .pe-md-4 {
    padding-right: 2rem;
  }
  .pe-md-5 {
    padding-right: 2.5rem;
  }
  .pe-md-5-5 {
    padding-right: 2.75rem;
  }
  .pe-md-6 {
    padding-right: 3rem;
  }
  .pe-md-7 {
    padding-right: 3.5rem;
  }
  .pe-md-7-5 {
    padding-right: 3.75rem;
  }
  .pe-md-8 {
    padding-right: 4rem;
  }
  .pe-md-9 {
    padding-right: 4.5rem;
  }
  .pe-md-9-5 {
    padding-right: 4.75rem;
  }
  .pe-md-10 {
    padding-right: 5rem;
  }
  .pe-md-auto {
    padding-right: "auto";
  }
  .pb-md-0 {
    padding-bottom: 0;
  }
  .pb-md-0-5 {
    padding-bottom: 0.25rem;
  }
  .pb-md-1 {
    padding-bottom: 0.5rem;
  }
  .pb-md-1-5 {
    padding-bottom: 0.75rem;
  }
  .pb-md-2 {
    padding-bottom: 1rem;
  }
  .pb-md-2-5 {
    padding-bottom: 1.25rem;
  }
  .pb-md-3 {
    padding-bottom: 1.5rem;
  }
  .pb-md-3-5 {
    padding-bottom: 1.75rem;
  }
  .pb-md-4 {
    padding-bottom: 2rem;
  }
  .pb-md-5 {
    padding-bottom: 2.5rem;
  }
  .pb-md-5-5 {
    padding-bottom: 2.75rem;
  }
  .pb-md-6 {
    padding-bottom: 3rem;
  }
  .pb-md-7 {
    padding-bottom: 3.5rem;
  }
  .pb-md-7-5 {
    padding-bottom: 3.75rem;
  }
  .pb-md-8 {
    padding-bottom: 4rem;
  }
  .pb-md-9 {
    padding-bottom: 4.5rem;
  }
  .pb-md-9-5 {
    padding-bottom: 4.75rem;
  }
  .pb-md-10 {
    padding-bottom: 5rem;
  }
  .pb-md-auto {
    padding-bottom: "auto";
  }
  .ps-md-0 {
    padding-left: 0;
  }
  .ps-md-0-5 {
    padding-left: 0.25rem;
  }
  .ps-md-1 {
    padding-left: 0.5rem;
  }
  .ps-md-1-5 {
    padding-left: 0.75rem;
  }
  .ps-md-2 {
    padding-left: 1rem;
  }
  .ps-md-2-5 {
    padding-left: 1.25rem;
  }
  .ps-md-3 {
    padding-left: 1.5rem;
  }
  .ps-md-3-5 {
    padding-left: 1.75rem;
  }
  .ps-md-4 {
    padding-left: 2rem;
  }
  .ps-md-5 {
    padding-left: 2.5rem;
  }
  .ps-md-5-5 {
    padding-left: 2.75rem;
  }
  .ps-md-6 {
    padding-left: 3rem;
  }
  .ps-md-7 {
    padding-left: 3.5rem;
  }
  .ps-md-7-5 {
    padding-left: 3.75rem;
  }
  .ps-md-8 {
    padding-left: 4rem;
  }
  .ps-md-9 {
    padding-left: 4.5rem;
  }
  .ps-md-9-5 {
    padding-left: 4.75rem;
  }
  .ps-md-10 {
    padding-left: 5rem;
  }
  .ps-md-auto {
    padding-left: "auto";
  }
  .gap-md-0 {
    gap: 0;
  }
  .gap-md-0-5 {
    gap: 0.25rem;
  }
  .gap-md-1 {
    gap: 0.5rem;
  }
  .gap-md-1-5 {
    gap: 0.75rem;
  }
  .gap-md-2 {
    gap: 1rem;
  }
  .gap-md-2-5 {
    gap: 1.25rem;
  }
  .gap-md-3 {
    gap: 1.5rem;
  }
  .gap-md-3-5 {
    gap: 1.75rem;
  }
  .gap-md-4 {
    gap: 2rem;
  }
  .gap-md-5 {
    gap: 2.5rem;
  }
  .gap-md-5-5 {
    gap: 2.75rem;
  }
  .gap-md-6 {
    gap: 3rem;
  }
  .gap-md-7 {
    gap: 3.5rem;
  }
  .gap-md-7-5 {
    gap: 3.75rem;
  }
  .gap-md-8 {
    gap: 4rem;
  }
  .gap-md-9 {
    gap: 4.5rem;
  }
  .gap-md-9-5 {
    gap: 4.75rem;
  }
  .gap-md-10 {
    gap: 5rem;
  }
  .gap-md-auto {
    gap: "auto";
  }
  .text-md-start {
    text-align: left;
  }
  .text-md-end {
    text-align: right;
  }
  .text-md-center {
    text-align: center;
  }
  .rounded-md {
    border-radius: var(--bs-border-radius);
  }
  .rounded-md-0 {
    border-radius: 0;
  }
  .rounded-md-1 {
    border-radius: var(--bs-border-radius-sm);
  }
  .rounded-md-2 {
    border-radius: var(--bs-border-radius);
  }
  .rounded-md-3 {
    border-radius: var(--bs-border-radius-lg);
  }
  .rounded-md-4 {
    border-radius: var(--bs-border-radius-xl);
  }
  .rounded-md-5 {
    border-radius: var(--bs-border-radius-2xl);
  }
  .rounded-md-circle {
    border-radius: 50%;
  }
  .rounded-md-pill {
    border-radius: var(--bs-border-radius-pill);
  }
}
@media (min-width: 992px) {
  .float-lg-start {
    float: left;
  }
  .float-lg-end {
    float: right;
  }
  .float-lg-none {
    float: none;
  }
  .d-lg-inline {
    display: inline;
  }
  .d-lg-inline-block {
    display: inline-block;
  }
  .d-lg-block {
    display: block;
  }
  .d-lg-grid {
    display: grid;
  }
  .d-lg-table {
    display: table;
  }
  .d-lg-table-row {
    display: table-row;
  }
  .d-lg-table-cell {
    display: table-cell;
  }
  .d-lg-flex {
    display: flex;
  }
  .d-lg-inline-flex {
    display: inline-flex;
  }
  .d-lg-none {
    display: none;
  }
  .w-lg-25 {
    width: 25%;
  }
  .w-lg-50 {
    width: 50%;
  }
  .w-lg-75 {
    width: 75%;
  }
  .w-lg-100 {
    width: 100%;
  }
  .w-lg-auto {
    width: auto;
  }
  .flex-lg-fill {
    flex: 1 1 auto;
  }
  .flex-lg-row {
    flex-direction: row;
  }
  .flex-lg-column {
    flex-direction: column;
  }
  .flex-lg-row-reverse {
    flex-direction: row-reverse;
  }
  .flex-lg-column-reverse {
    flex-direction: column-reverse;
  }
  .flex-lg-grow-0 {
    flex-grow: 0;
  }
  .flex-lg-grow-1 {
    flex-grow: 1;
  }
  .flex-lg-shrink-0 {
    flex-shrink: 0;
  }
  .flex-lg-shrink-1 {
    flex-shrink: 1;
  }
  .flex-lg-wrap {
    flex-wrap: wrap;
  }
  .flex-lg-nowrap {
    flex-wrap: nowrap;
  }
  .flex-lg-wrap-reverse {
    flex-wrap: wrap-reverse;
  }
  .justify-content-lg-start {
    justify-content: flex-start;
  }
  .justify-content-lg-end {
    justify-content: flex-end;
  }
  .justify-content-lg-center {
    justify-content: center;
  }
  .justify-content-lg-between {
    justify-content: space-between;
  }
  .justify-content-lg-around {
    justify-content: space-around;
  }
  .justify-content-lg-evenly {
    justify-content: space-evenly;
  }
  .align-items-lg-start {
    align-items: flex-start;
  }
  .align-items-lg-end {
    align-items: flex-end;
  }
  .align-items-lg-center {
    align-items: center;
  }
  .align-items-lg-baseline {
    align-items: baseline;
  }
  .align-items-lg-stretch {
    align-items: stretch;
  }
  .align-content-lg-start {
    align-content: flex-start;
  }
  .align-content-lg-end {
    align-content: flex-end;
  }
  .align-content-lg-center {
    align-content: center;
  }
  .align-content-lg-between {
    align-content: space-between;
  }
  .align-content-lg-around {
    align-content: space-around;
  }
  .align-content-lg-stretch {
    align-content: stretch;
  }
  .align-self-lg-auto {
    align-self: auto;
  }
  .align-self-lg-start {
    align-self: flex-start;
  }
  .align-self-lg-end {
    align-self: flex-end;
  }
  .align-self-lg-center {
    align-self: center;
  }
  .align-self-lg-baseline {
    align-self: baseline;
  }
  .align-self-lg-stretch {
    align-self: stretch;
  }
  .order-lg-first {
    order: -1;
  }
  .order-lg-0 {
    order: 0;
  }
  .order-lg-1 {
    order: 1;
  }
  .order-lg-2 {
    order: 2;
  }
  .order-lg-3 {
    order: 3;
  }
  .order-lg-4 {
    order: 4;
  }
  .order-lg-5 {
    order: 5;
  }
  .order-lg-last {
    order: 6;
  }
  .m-lg-0 {
    margin: 0;
  }
  .m-lg-0-5 {
    margin: 0.25rem;
  }
  .m-lg-1 {
    margin: 0.5rem;
  }
  .m-lg-1-5 {
    margin: 0.75rem;
  }
  .m-lg-2 {
    margin: 1rem;
  }
  .m-lg-2-5 {
    margin: 1.25rem;
  }
  .m-lg-3 {
    margin: 1.5rem;
  }
  .m-lg-3-5 {
    margin: 1.75rem;
  }
  .m-lg-4 {
    margin: 2rem;
  }
  .m-lg-5 {
    margin: 2.5rem;
  }
  .m-lg-5-5 {
    margin: 2.75rem;
  }
  .m-lg-6 {
    margin: 3rem;
  }
  .m-lg-7 {
    margin: 3.5rem;
  }
  .m-lg-7-5 {
    margin: 3.75rem;
  }
  .m-lg-8 {
    margin: 4rem;
  }
  .m-lg-9 {
    margin: 4.5rem;
  }
  .m-lg-9-5 {
    margin: 4.75rem;
  }
  .m-lg-10 {
    margin: 5rem;
  }
  .m-lg-auto {
    margin: auto;
  }
  .mx-lg-0 {
    margin-right: 0;
    margin-left: 0;
  }
  .mx-lg-0-5 {
    margin-right: 0.25rem;
    margin-left: 0.25rem;
  }
  .mx-lg-1 {
    margin-right: 0.5rem;
    margin-left: 0.5rem;
  }
  .mx-lg-1-5 {
    margin-right: 0.75rem;
    margin-left: 0.75rem;
  }
  .mx-lg-2 {
    margin-right: 1rem;
    margin-left: 1rem;
  }
  .mx-lg-2-5 {
    margin-right: 1.25rem;
    margin-left: 1.25rem;
  }
  .mx-lg-3 {
    margin-right: 1.5rem;
    margin-left: 1.5rem;
  }
  .mx-lg-3-5 {
    margin-right: 1.75rem;
    margin-left: 1.75rem;
  }
  .mx-lg-4 {
    margin-right: 2rem;
    margin-left: 2rem;
  }
  .mx-lg-5 {
    margin-right: 2.5rem;
    margin-left: 2.5rem;
  }
  .mx-lg-5-5 {
    margin-right: 2.75rem;
    margin-left: 2.75rem;
  }
  .mx-lg-6 {
    margin-right: 3rem;
    margin-left: 3rem;
  }
  .mx-lg-7 {
    margin-right: 3.5rem;
    margin-left: 3.5rem;
  }
  .mx-lg-7-5 {
    margin-right: 3.75rem;
    margin-left: 3.75rem;
  }
  .mx-lg-8 {
    margin-right: 4rem;
    margin-left: 4rem;
  }
  .mx-lg-9 {
    margin-right: 4.5rem;
    margin-left: 4.5rem;
  }
  .mx-lg-9-5 {
    margin-right: 4.75rem;
    margin-left: 4.75rem;
  }
  .mx-lg-10 {
    margin-right: 5rem;
    margin-left: 5rem;
  }
  .mx-lg-auto {
    margin-right: auto;
    margin-left: auto;
  }
  .my-lg-0 {
    margin-top: 0;
    margin-bottom: 0;
  }
  .my-lg-0-5 {
    margin-top: 0.25rem;
    margin-bottom: 0.25rem;
  }
  .my-lg-1 {
    margin-top: 0.5rem;
    margin-bottom: 0.5rem;
  }
  .my-lg-1-5 {
    margin-top: 0.75rem;
    margin-bottom: 0.75rem;
  }
  .my-lg-2 {
    margin-top: 1rem;
    margin-bottom: 1rem;
  }
  .my-lg-2-5 {
    margin-top: 1.25rem;
    margin-bottom: 1.25rem;
  }
  .my-lg-3 {
    margin-top: 1.5rem;
    margin-bottom: 1.5rem;
  }
  .my-lg-3-5 {
    margin-top: 1.75rem;
    margin-bottom: 1.75rem;
  }
  .my-lg-4 {
    margin-top: 2rem;
    margin-bottom: 2rem;
  }
  .my-lg-5 {
    margin-top: 2.5rem;
    margin-bottom: 2.5rem;
  }
  .my-lg-5-5 {
    margin-top: 2.75rem;
    margin-bottom: 2.75rem;
  }
  .my-lg-6 {
    margin-top: 3rem;
    margin-bottom: 3rem;
  }
  .my-lg-7 {
    margin-top: 3.5rem;
    margin-bottom: 3.5rem;
  }
  .my-lg-7-5 {
    margin-top: 3.75rem;
    margin-bottom: 3.75rem;
  }
  .my-lg-8 {
    margin-top: 4rem;
    margin-bottom: 4rem;
  }
  .my-lg-9 {
    margin-top: 4.5rem;
    margin-bottom: 4.5rem;
  }
  .my-lg-9-5 {
    margin-top: 4.75rem;
    margin-bottom: 4.75rem;
  }
  .my-lg-10 {
    margin-top: 5rem;
    margin-bottom: 5rem;
  }
  .my-lg-auto {
    margin-top: auto;
    margin-bottom: auto;
  }
  .mt-lg-0 {
    margin-top: 0;
  }
  .mt-lg-0-5 {
    margin-top: 0.25rem;
  }
  .mt-lg-1 {
    margin-top: 0.5rem;
  }
  .mt-lg-1-5 {
    margin-top: 0.75rem;
  }
  .mt-lg-2 {
    margin-top: 1rem;
  }
  .mt-lg-2-5 {
    margin-top: 1.25rem;
  }
  .mt-lg-3 {
    margin-top: 1.5rem;
  }
  .mt-lg-3-5 {
    margin-top: 1.75rem;
  }
  .mt-lg-4 {
    margin-top: 2rem;
  }
  .mt-lg-5 {
    margin-top: 2.5rem;
  }
  .mt-lg-5-5 {
    margin-top: 2.75rem;
  }
  .mt-lg-6 {
    margin-top: 3rem;
  }
  .mt-lg-7 {
    margin-top: 3.5rem;
  }
  .mt-lg-7-5 {
    margin-top: 3.75rem;
  }
  .mt-lg-8 {
    margin-top: 4rem;
  }
  .mt-lg-9 {
    margin-top: 4.5rem;
  }
  .mt-lg-9-5 {
    margin-top: 4.75rem;
  }
  .mt-lg-10 {
    margin-top: 5rem;
  }
  .mt-lg-auto {
    margin-top: auto;
  }
  .me-lg-0 {
    margin-right: 0;
  }
  .me-lg-0-5 {
    margin-right: 0.25rem;
  }
  .me-lg-1 {
    margin-right: 0.5rem;
  }
  .me-lg-1-5 {
    margin-right: 0.75rem;
  }
  .me-lg-2 {
    margin-right: 1rem;
  }
  .me-lg-2-5 {
    margin-right: 1.25rem;
  }
  .me-lg-3 {
    margin-right: 1.5rem;
  }
  .me-lg-3-5 {
    margin-right: 1.75rem;
  }
  .me-lg-4 {
    margin-right: 2rem;
  }
  .me-lg-5 {
    margin-right: 2.5rem;
  }
  .me-lg-5-5 {
    margin-right: 2.75rem;
  }
  .me-lg-6 {
    margin-right: 3rem;
  }
  .me-lg-7 {
    margin-right: 3.5rem;
  }
  .me-lg-7-5 {
    margin-right: 3.75rem;
  }
  .me-lg-8 {
    margin-right: 4rem;
  }
  .me-lg-9 {
    margin-right: 4.5rem;
  }
  .me-lg-9-5 {
    margin-right: 4.75rem;
  }
  .me-lg-10 {
    margin-right: 5rem;
  }
  .me-lg-auto {
    margin-right: auto;
  }
  .mb-lg-0 {
    margin-bottom: 0;
  }
  .mb-lg-0-5 {
    margin-bottom: 0.25rem;
  }
  .mb-lg-1 {
    margin-bottom: 0.5rem;
  }
  .mb-lg-1-5 {
    margin-bottom: 0.75rem;
  }
  .mb-lg-2 {
    margin-bottom: 1rem;
  }
  .mb-lg-2-5 {
    margin-bottom: 1.25rem;
  }
  .mb-lg-3 {
    margin-bottom: 1.5rem;
  }
  .mb-lg-3-5 {
    margin-bottom: 1.75rem;
  }
  .mb-lg-4 {
    margin-bottom: 2rem;
  }
  .mb-lg-5 {
    margin-bottom: 2.5rem;
  }
  .mb-lg-5-5 {
    margin-bottom: 2.75rem;
  }
  .mb-lg-6 {
    margin-bottom: 3rem;
  }
  .mb-lg-7 {
    margin-bottom: 3.5rem;
  }
  .mb-lg-7-5 {
    margin-bottom: 3.75rem;
  }
  .mb-lg-8 {
    margin-bottom: 4rem;
  }
  .mb-lg-9 {
    margin-bottom: 4.5rem;
  }
  .mb-lg-9-5 {
    margin-bottom: 4.75rem;
  }
  .mb-lg-10 {
    margin-bottom: 5rem;
  }
  .mb-lg-auto {
    margin-bottom: auto;
  }
  .ms-lg-0 {
    margin-left: 0;
  }
  .ms-lg-0-5 {
    margin-left: 0.25rem;
  }
  .ms-lg-1 {
    margin-left: 0.5rem;
  }
  .ms-lg-1-5 {
    margin-left: 0.75rem;
  }
  .ms-lg-2 {
    margin-left: 1rem;
  }
  .ms-lg-2-5 {
    margin-left: 1.25rem;
  }
  .ms-lg-3 {
    margin-left: 1.5rem;
  }
  .ms-lg-3-5 {
    margin-left: 1.75rem;
  }
  .ms-lg-4 {
    margin-left: 2rem;
  }
  .ms-lg-5 {
    margin-left: 2.5rem;
  }
  .ms-lg-5-5 {
    margin-left: 2.75rem;
  }
  .ms-lg-6 {
    margin-left: 3rem;
  }
  .ms-lg-7 {
    margin-left: 3.5rem;
  }
  .ms-lg-7-5 {
    margin-left: 3.75rem;
  }
  .ms-lg-8 {
    margin-left: 4rem;
  }
  .ms-lg-9 {
    margin-left: 4.5rem;
  }
  .ms-lg-9-5 {
    margin-left: 4.75rem;
  }
  .ms-lg-10 {
    margin-left: 5rem;
  }
  .ms-lg-auto {
    margin-left: auto;
  }
  .m-lg-n0 {
    margin: 0;
  }
  .m-lg-n0-5 {
    margin: -0.25rem;
  }
  .m-lg-n1 {
    margin: -0.5rem;
  }
  .m-lg-n1-5 {
    margin: -0.75rem;
  }
  .m-lg-n2 {
    margin: -1rem;
  }
  .m-lg-n2-5 {
    margin: -1.25rem;
  }
  .m-lg-n3 {
    margin: -1.5rem;
  }
  .m-lg-n3-5 {
    margin: -1.75rem;
  }
  .m-lg-n4 {
    margin: -2rem;
  }
  .m-lg-n5 {
    margin: -2.5rem;
  }
  .m-lg-n5-5 {
    margin: -2.75rem;
  }
  .m-lg-n6 {
    margin: -3rem;
  }
  .m-lg-n7 {
    margin: -3.5rem;
  }
  .m-lg-n7-5 {
    margin: -3.75rem;
  }
  .m-lg-n8 {
    margin: -4rem;
  }
  .m-lg-n9 {
    margin: -4.5rem;
  }
  .m-lg-n9-5 {
    margin: -4.75rem;
  }
  .m-lg-n10 {
    margin: -5rem;
  }
  .m-lg-nauto {
    margin: -"auto";
  }
  .mx-lg-n0 {
    margin-right: 0;
    margin-left: 0;
  }
  .mx-lg-n0-5 {
    margin-right: -0.25rem;
    margin-left: -0.25rem;
  }
  .mx-lg-n1 {
    margin-right: -0.5rem;
    margin-left: -0.5rem;
  }
  .mx-lg-n1-5 {
    margin-right: -0.75rem;
    margin-left: -0.75rem;
  }
  .mx-lg-n2 {
    margin-right: -1rem;
    margin-left: -1rem;
  }
  .mx-lg-n2-5 {
    margin-right: -1.25rem;
    margin-left: -1.25rem;
  }
  .mx-lg-n3 {
    margin-right: -1.5rem;
    margin-left: -1.5rem;
  }
  .mx-lg-n3-5 {
    margin-right: -1.75rem;
    margin-left: -1.75rem;
  }
  .mx-lg-n4 {
    margin-right: -2rem;
    margin-left: -2rem;
  }
  .mx-lg-n5 {
    margin-right: -2.5rem;
    margin-left: -2.5rem;
  }
  .mx-lg-n5-5 {
    margin-right: -2.75rem;
    margin-left: -2.75rem;
  }
  .mx-lg-n6 {
    margin-right: -3rem;
    margin-left: -3rem;
  }
  .mx-lg-n7 {
    margin-right: -3.5rem;
    margin-left: -3.5rem;
  }
  .mx-lg-n7-5 {
    margin-right: -3.75rem;
    margin-left: -3.75rem;
  }
  .mx-lg-n8 {
    margin-right: -4rem;
    margin-left: -4rem;
  }
  .mx-lg-n9 {
    margin-right: -4.5rem;
    margin-left: -4.5rem;
  }
  .mx-lg-n9-5 {
    margin-right: -4.75rem;
    margin-left: -4.75rem;
  }
  .mx-lg-n10 {
    margin-right: -5rem;
    margin-left: -5rem;
  }
  .mx-lg-nauto {
    margin-right: -"auto";
    margin-left: -"auto";
  }
  .my-lg-n0 {
    margin-top: 0;
    margin-bottom: 0;
  }
  .my-lg-n0-5 {
    margin-top: -0.25rem;
    margin-bottom: -0.25rem;
  }
  .my-lg-n1 {
    margin-top: -0.5rem;
    margin-bottom: -0.5rem;
  }
  .my-lg-n1-5 {
    margin-top: -0.75rem;
    margin-bottom: -0.75rem;
  }
  .my-lg-n2 {
    margin-top: -1rem;
    margin-bottom: -1rem;
  }
  .my-lg-n2-5 {
    margin-top: -1.25rem;
    margin-bottom: -1.25rem;
  }
  .my-lg-n3 {
    margin-top: -1.5rem;
    margin-bottom: -1.5rem;
  }
  .my-lg-n3-5 {
    margin-top: -1.75rem;
    margin-bottom: -1.75rem;
  }
  .my-lg-n4 {
    margin-top: -2rem;
    margin-bottom: -2rem;
  }
  .my-lg-n5 {
    margin-top: -2.5rem;
    margin-bottom: -2.5rem;
  }
  .my-lg-n5-5 {
    margin-top: -2.75rem;
    margin-bottom: -2.75rem;
  }
  .my-lg-n6 {
    margin-top: -3rem;
    margin-bottom: -3rem;
  }
  .my-lg-n7 {
    margin-top: -3.5rem;
    margin-bottom: -3.5rem;
  }
  .my-lg-n7-5 {
    margin-top: -3.75rem;
    margin-bottom: -3.75rem;
  }
  .my-lg-n8 {
    margin-top: -4rem;
    margin-bottom: -4rem;
  }
  .my-lg-n9 {
    margin-top: -4.5rem;
    margin-bottom: -4.5rem;
  }
  .my-lg-n9-5 {
    margin-top: -4.75rem;
    margin-bottom: -4.75rem;
  }
  .my-lg-n10 {
    margin-top: -5rem;
    margin-bottom: -5rem;
  }
  .my-lg-nauto {
    margin-top: -"auto";
    margin-bottom: -"auto";
  }
  .mt-lg-n0 {
    margin-top: 0;
  }
  .mt-lg-n0-5 {
    margin-top: -0.25rem;
  }
  .mt-lg-n1 {
    margin-top: -0.5rem;
  }
  .mt-lg-n1-5 {
    margin-top: -0.75rem;
  }
  .mt-lg-n2 {
    margin-top: -1rem;
  }
  .mt-lg-n2-5 {
    margin-top: -1.25rem;
  }
  .mt-lg-n3 {
    margin-top: -1.5rem;
  }
  .mt-lg-n3-5 {
    margin-top: -1.75rem;
  }
  .mt-lg-n4 {
    margin-top: -2rem;
  }
  .mt-lg-n5 {
    margin-top: -2.5rem;
  }
  .mt-lg-n5-5 {
    margin-top: -2.75rem;
  }
  .mt-lg-n6 {
    margin-top: -3rem;
  }
  .mt-lg-n7 {
    margin-top: -3.5rem;
  }
  .mt-lg-n7-5 {
    margin-top: -3.75rem;
  }
  .mt-lg-n8 {
    margin-top: -4rem;
  }
  .mt-lg-n9 {
    margin-top: -4.5rem;
  }
  .mt-lg-n9-5 {
    margin-top: -4.75rem;
  }
  .mt-lg-n10 {
    margin-top: -5rem;
  }
  .mt-lg-nauto {
    margin-top: -"auto";
  }
  .me-lg-n0 {
    margin-right: 0;
  }
  .me-lg-n0-5 {
    margin-right: -0.25rem;
  }
  .me-lg-n1 {
    margin-right: -0.5rem;
  }
  .me-lg-n1-5 {
    margin-right: -0.75rem;
  }
  .me-lg-n2 {
    margin-right: -1rem;
  }
  .me-lg-n2-5 {
    margin-right: -1.25rem;
  }
  .me-lg-n3 {
    margin-right: -1.5rem;
  }
  .me-lg-n3-5 {
    margin-right: -1.75rem;
  }
  .me-lg-n4 {
    margin-right: -2rem;
  }
  .me-lg-n5 {
    margin-right: -2.5rem;
  }
  .me-lg-n5-5 {
    margin-right: -2.75rem;
  }
  .me-lg-n6 {
    margin-right: -3rem;
  }
  .me-lg-n7 {
    margin-right: -3.5rem;
  }
  .me-lg-n7-5 {
    margin-right: -3.75rem;
  }
  .me-lg-n8 {
    margin-right: -4rem;
  }
  .me-lg-n9 {
    margin-right: -4.5rem;
  }
  .me-lg-n9-5 {
    margin-right: -4.75rem;
  }
  .me-lg-n10 {
    margin-right: -5rem;
  }
  .me-lg-nauto {
    margin-right: -"auto";
  }
  .mb-lg-n0 {
    margin-bottom: 0;
  }
  .mb-lg-n0-5 {
    margin-bottom: -0.25rem;
  }
  .mb-lg-n1 {
    margin-bottom: -0.5rem;
  }
  .mb-lg-n1-5 {
    margin-bottom: -0.75rem;
  }
  .mb-lg-n2 {
    margin-bottom: -1rem;
  }
  .mb-lg-n2-5 {
    margin-bottom: -1.25rem;
  }
  .mb-lg-n3 {
    margin-bottom: -1.5rem;
  }
  .mb-lg-n3-5 {
    margin-bottom: -1.75rem;
  }
  .mb-lg-n4 {
    margin-bottom: -2rem;
  }
  .mb-lg-n5 {
    margin-bottom: -2.5rem;
  }
  .mb-lg-n5-5 {
    margin-bottom: -2.75rem;
  }
  .mb-lg-n6 {
    margin-bottom: -3rem;
  }
  .mb-lg-n7 {
    margin-bottom: -3.5rem;
  }
  .mb-lg-n7-5 {
    margin-bottom: -3.75rem;
  }
  .mb-lg-n8 {
    margin-bottom: -4rem;
  }
  .mb-lg-n9 {
    margin-bottom: -4.5rem;
  }
  .mb-lg-n9-5 {
    margin-bottom: -4.75rem;
  }
  .mb-lg-n10 {
    margin-bottom: -5rem;
  }
  .mb-lg-nauto {
    margin-bottom: -"auto";
  }
  .ms-lg-n0 {
    margin-left: 0;
  }
  .ms-lg-n0-5 {
    margin-left: -0.25rem;
  }
  .ms-lg-n1 {
    margin-left: -0.5rem;
  }
  .ms-lg-n1-5 {
    margin-left: -0.75rem;
  }
  .ms-lg-n2 {
    margin-left: -1rem;
  }
  .ms-lg-n2-5 {
    margin-left: -1.25rem;
  }
  .ms-lg-n3 {
    margin-left: -1.5rem;
  }
  .ms-lg-n3-5 {
    margin-left: -1.75rem;
  }
  .ms-lg-n4 {
    margin-left: -2rem;
  }
  .ms-lg-n5 {
    margin-left: -2.5rem;
  }
  .ms-lg-n5-5 {
    margin-left: -2.75rem;
  }
  .ms-lg-n6 {
    margin-left: -3rem;
  }
  .ms-lg-n7 {
    margin-left: -3.5rem;
  }
  .ms-lg-n7-5 {
    margin-left: -3.75rem;
  }
  .ms-lg-n8 {
    margin-left: -4rem;
  }
  .ms-lg-n9 {
    margin-left: -4.5rem;
  }
  .ms-lg-n9-5 {
    margin-left: -4.75rem;
  }
  .ms-lg-n10 {
    margin-left: -5rem;
  }
  .ms-lg-nauto {
    margin-left: -"auto";
  }
  .p-lg-0 {
    padding: 0;
  }
  .p-lg-0-5 {
    padding: 0.25rem;
  }
  .p-lg-1 {
    padding: 0.5rem;
  }
  .p-lg-1-5 {
    padding: 0.75rem;
  }
  .p-lg-2 {
    padding: 1rem;
  }
  .p-lg-2-5 {
    padding: 1.25rem;
  }
  .p-lg-3 {
    padding: 1.5rem;
  }
  .p-lg-3-5 {
    padding: 1.75rem;
  }
  .p-lg-4 {
    padding: 2rem;
  }
  .p-lg-5 {
    padding: 2.5rem;
  }
  .p-lg-5-5 {
    padding: 2.75rem;
  }
  .p-lg-6 {
    padding: 3rem;
  }
  .p-lg-7 {
    padding: 3.5rem;
  }
  .p-lg-7-5 {
    padding: 3.75rem;
  }
  .p-lg-8 {
    padding: 4rem;
  }
  .p-lg-9 {
    padding: 4.5rem;
  }
  .p-lg-9-5 {
    padding: 4.75rem;
  }
  .p-lg-10 {
    padding: 5rem;
  }
  .p-lg-auto {
    padding: "auto";
  }
  .px-lg-0 {
    padding-right: 0;
    padding-left: 0;
  }
  .px-lg-0-5 {
    padding-right: 0.25rem;
    padding-left: 0.25rem;
  }
  .px-lg-1 {
    padding-right: 0.5rem;
    padding-left: 0.5rem;
  }
  .px-lg-1-5 {
    padding-right: 0.75rem;
    padding-left: 0.75rem;
  }
  .px-lg-2 {
    padding-right: 1rem;
    padding-left: 1rem;
  }
  .px-lg-2-5 {
    padding-right: 1.25rem;
    padding-left: 1.25rem;
  }
  .px-lg-3 {
    padding-right: 1.5rem;
    padding-left: 1.5rem;
  }
  .px-lg-3-5 {
    padding-right: 1.75rem;
    padding-left: 1.75rem;
  }
  .px-lg-4 {
    padding-right: 2rem;
    padding-left: 2rem;
  }
  .px-lg-5 {
    padding-right: 2.5rem;
    padding-left: 2.5rem;
  }
  .px-lg-5-5 {
    padding-right: 2.75rem;
    padding-left: 2.75rem;
  }
  .px-lg-6 {
    padding-right: 3rem;
    padding-left: 3rem;
  }
  .px-lg-7 {
    padding-right: 3.5rem;
    padding-left: 3.5rem;
  }
  .px-lg-7-5 {
    padding-right: 3.75rem;
    padding-left: 3.75rem;
  }
  .px-lg-8 {
    padding-right: 4rem;
    padding-left: 4rem;
  }
  .px-lg-9 {
    padding-right: 4.5rem;
    padding-left: 4.5rem;
  }
  .px-lg-9-5 {
    padding-right: 4.75rem;
    padding-left: 4.75rem;
  }
  .px-lg-10 {
    padding-right: 5rem;
    padding-left: 5rem;
  }
  .px-lg-auto {
    padding-right: "auto";
    padding-left: "auto";
  }
  .py-lg-0 {
    padding-top: 0;
    padding-bottom: 0;
  }
  .py-lg-0-5 {
    padding-top: 0.25rem;
    padding-bottom: 0.25rem;
  }
  .py-lg-1 {
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
  }
  .py-lg-1-5 {
    padding-top: 0.75rem;
    padding-bottom: 0.75rem;
  }
  .py-lg-2 {
    padding-top: 1rem;
    padding-bottom: 1rem;
  }
  .py-lg-2-5 {
    padding-top: 1.25rem;
    padding-bottom: 1.25rem;
  }
  .py-lg-3 {
    padding-top: 1.5rem;
    padding-bottom: 1.5rem;
  }
  .py-lg-3-5 {
    padding-top: 1.75rem;
    padding-bottom: 1.75rem;
  }
  .py-lg-4 {
    padding-top: 2rem;
    padding-bottom: 2rem;
  }
  .py-lg-5 {
    padding-top: 2.5rem;
    padding-bottom: 2.5rem;
  }
  .py-lg-5-5 {
    padding-top: 2.75rem;
    padding-bottom: 2.75rem;
  }
  .py-lg-6 {
    padding-top: 3rem;
    padding-bottom: 3rem;
  }
  .py-lg-7 {
    padding-top: 3.5rem;
    padding-bottom: 3.5rem;
  }
  .py-lg-7-5 {
    padding-top: 3.75rem;
    padding-bottom: 3.75rem;
  }
  .py-lg-8 {
    padding-top: 4rem;
    padding-bottom: 4rem;
  }
  .py-lg-9 {
    padding-top: 4.5rem;
    padding-bottom: 4.5rem;
  }
  .py-lg-9-5 {
    padding-top: 4.75rem;
    padding-bottom: 4.75rem;
  }
  .py-lg-10 {
    padding-top: 5rem;
    padding-bottom: 5rem;
  }
  .py-lg-auto {
    padding-top: "auto";
    padding-bottom: "auto";
  }
  .pt-lg-0 {
    padding-top: 0;
  }
  .pt-lg-0-5 {
    padding-top: 0.25rem;
  }
  .pt-lg-1 {
    padding-top: 0.5rem;
  }
  .pt-lg-1-5 {
    padding-top: 0.75rem;
  }
  .pt-lg-2 {
    padding-top: 1rem;
  }
  .pt-lg-2-5 {
    padding-top: 1.25rem;
  }
  .pt-lg-3 {
    padding-top: 1.5rem;
  }
  .pt-lg-3-5 {
    padding-top: 1.75rem;
  }
  .pt-lg-4 {
    padding-top: 2rem;
  }
  .pt-lg-5 {
    padding-top: 2.5rem;
  }
  .pt-lg-5-5 {
    padding-top: 2.75rem;
  }
  .pt-lg-6 {
    padding-top: 3rem;
  }
  .pt-lg-7 {
    padding-top: 3.5rem;
  }
  .pt-lg-7-5 {
    padding-top: 3.75rem;
  }
  .pt-lg-8 {
    padding-top: 4rem;
  }
  .pt-lg-9 {
    padding-top: 4.5rem;
  }
  .pt-lg-9-5 {
    padding-top: 4.75rem;
  }
  .pt-lg-10 {
    padding-top: 5rem;
  }
  .pt-lg-auto {
    padding-top: "auto";
  }
  .pe-lg-0 {
    padding-right: 0;
  }
  .pe-lg-0-5 {
    padding-right: 0.25rem;
  }
  .pe-lg-1 {
    padding-right: 0.5rem;
  }
  .pe-lg-1-5 {
    padding-right: 0.75rem;
  }
  .pe-lg-2 {
    padding-right: 1rem;
  }
  .pe-lg-2-5 {
    padding-right: 1.25rem;
  }
  .pe-lg-3 {
    padding-right: 1.5rem;
  }
  .pe-lg-3-5 {
    padding-right: 1.75rem;
  }
  .pe-lg-4 {
    padding-right: 2rem;
  }
  .pe-lg-5 {
    padding-right: 2.5rem;
  }
  .pe-lg-5-5 {
    padding-right: 2.75rem;
  }
  .pe-lg-6 {
    padding-right: 3rem;
  }
  .pe-lg-7 {
    padding-right: 3.5rem;
  }
  .pe-lg-7-5 {
    padding-right: 3.75rem;
  }
  .pe-lg-8 {
    padding-right: 4rem;
  }
  .pe-lg-9 {
    padding-right: 4.5rem;
  }
  .pe-lg-9-5 {
    padding-right: 4.75rem;
  }
  .pe-lg-10 {
    padding-right: 5rem;
  }
  .pe-lg-auto {
    padding-right: "auto";
  }
  .pb-lg-0 {
    padding-bottom: 0;
  }
  .pb-lg-0-5 {
    padding-bottom: 0.25rem;
  }
  .pb-lg-1 {
    padding-bottom: 0.5rem;
  }
  .pb-lg-1-5 {
    padding-bottom: 0.75rem;
  }
  .pb-lg-2 {
    padding-bottom: 1rem;
  }
  .pb-lg-2-5 {
    padding-bottom: 1.25rem;
  }
  .pb-lg-3 {
    padding-bottom: 1.5rem;
  }
  .pb-lg-3-5 {
    padding-bottom: 1.75rem;
  }
  .pb-lg-4 {
    padding-bottom: 2rem;
  }
  .pb-lg-5 {
    padding-bottom: 2.5rem;
  }
  .pb-lg-5-5 {
    padding-bottom: 2.75rem;
  }
  .pb-lg-6 {
    padding-bottom: 3rem;
  }
  .pb-lg-7 {
    padding-bottom: 3.5rem;
  }
  .pb-lg-7-5 {
    padding-bottom: 3.75rem;
  }
  .pb-lg-8 {
    padding-bottom: 4rem;
  }
  .pb-lg-9 {
    padding-bottom: 4.5rem;
  }
  .pb-lg-9-5 {
    padding-bottom: 4.75rem;
  }
  .pb-lg-10 {
    padding-bottom: 5rem;
  }
  .pb-lg-auto {
    padding-bottom: "auto";
  }
  .ps-lg-0 {
    padding-left: 0;
  }
  .ps-lg-0-5 {
    padding-left: 0.25rem;
  }
  .ps-lg-1 {
    padding-left: 0.5rem;
  }
  .ps-lg-1-5 {
    padding-left: 0.75rem;
  }
  .ps-lg-2 {
    padding-left: 1rem;
  }
  .ps-lg-2-5 {
    padding-left: 1.25rem;
  }
  .ps-lg-3 {
    padding-left: 1.5rem;
  }
  .ps-lg-3-5 {
    padding-left: 1.75rem;
  }
  .ps-lg-4 {
    padding-left: 2rem;
  }
  .ps-lg-5 {
    padding-left: 2.5rem;
  }
  .ps-lg-5-5 {
    padding-left: 2.75rem;
  }
  .ps-lg-6 {
    padding-left: 3rem;
  }
  .ps-lg-7 {
    padding-left: 3.5rem;
  }
  .ps-lg-7-5 {
    padding-left: 3.75rem;
  }
  .ps-lg-8 {
    padding-left: 4rem;
  }
  .ps-lg-9 {
    padding-left: 4.5rem;
  }
  .ps-lg-9-5 {
    padding-left: 4.75rem;
  }
  .ps-lg-10 {
    padding-left: 5rem;
  }
  .ps-lg-auto {
    padding-left: "auto";
  }
  .gap-lg-0 {
    gap: 0;
  }
  .gap-lg-0-5 {
    gap: 0.25rem;
  }
  .gap-lg-1 {
    gap: 0.5rem;
  }
  .gap-lg-1-5 {
    gap: 0.75rem;
  }
  .gap-lg-2 {
    gap: 1rem;
  }
  .gap-lg-2-5 {
    gap: 1.25rem;
  }
  .gap-lg-3 {
    gap: 1.5rem;
  }
  .gap-lg-3-5 {
    gap: 1.75rem;
  }
  .gap-lg-4 {
    gap: 2rem;
  }
  .gap-lg-5 {
    gap: 2.5rem;
  }
  .gap-lg-5-5 {
    gap: 2.75rem;
  }
  .gap-lg-6 {
    gap: 3rem;
  }
  .gap-lg-7 {
    gap: 3.5rem;
  }
  .gap-lg-7-5 {
    gap: 3.75rem;
  }
  .gap-lg-8 {
    gap: 4rem;
  }
  .gap-lg-9 {
    gap: 4.5rem;
  }
  .gap-lg-9-5 {
    gap: 4.75rem;
  }
  .gap-lg-10 {
    gap: 5rem;
  }
  .gap-lg-auto {
    gap: "auto";
  }
  .text-lg-start {
    text-align: left;
  }
  .text-lg-end {
    text-align: right;
  }
  .text-lg-center {
    text-align: center;
  }
  .rounded-lg {
    border-radius: var(--bs-border-radius);
  }
  .rounded-lg-0 {
    border-radius: 0;
  }
  .rounded-lg-1 {
    border-radius: var(--bs-border-radius-sm);
  }
  .rounded-lg-2 {
    border-radius: var(--bs-border-radius);
  }
  .rounded-lg-3 {
    border-radius: var(--bs-border-radius-lg);
  }
  .rounded-lg-4 {
    border-radius: var(--bs-border-radius-xl);
  }
  .rounded-lg-5 {
    border-radius: var(--bs-border-radius-2xl);
  }
  .rounded-lg-circle {
    border-radius: 50%;
  }
  .rounded-lg-pill {
    border-radius: var(--bs-border-radius-pill);
  }
}
@media (min-width: 1200px) {
  .float-xl-start {
    float: left;
  }
  .float-xl-end {
    float: right;
  }
  .float-xl-none {
    float: none;
  }
  .d-xl-inline {
    display: inline;
  }
  .d-xl-inline-block {
    display: inline-block;
  }
  .d-xl-block {
    display: block;
  }
  .d-xl-grid {
    display: grid;
  }
  .d-xl-table {
    display: table;
  }
  .d-xl-table-row {
    display: table-row;
  }
  .d-xl-table-cell {
    display: table-cell;
  }
  .d-xl-flex {
    display: flex;
  }
  .d-xl-inline-flex {
    display: inline-flex;
  }
  .d-xl-none {
    display: none;
  }
  .w-xl-25 {
    width: 25%;
  }
  .w-xl-50 {
    width: 50%;
  }
  .w-xl-75 {
    width: 75%;
  }
  .w-xl-100 {
    width: 100%;
  }
  .w-xl-auto {
    width: auto;
  }
  .flex-xl-fill {
    flex: 1 1 auto;
  }
  .flex-xl-row {
    flex-direction: row;
  }
  .flex-xl-column {
    flex-direction: column;
  }
  .flex-xl-row-reverse {
    flex-direction: row-reverse;
  }
  .flex-xl-column-reverse {
    flex-direction: column-reverse;
  }
  .flex-xl-grow-0 {
    flex-grow: 0;
  }
  .flex-xl-grow-1 {
    flex-grow: 1;
  }
  .flex-xl-shrink-0 {
    flex-shrink: 0;
  }
  .flex-xl-shrink-1 {
    flex-shrink: 1;
  }
  .flex-xl-wrap {
    flex-wrap: wrap;
  }
  .flex-xl-nowrap {
    flex-wrap: nowrap;
  }
  .flex-xl-wrap-reverse {
    flex-wrap: wrap-reverse;
  }
  .justify-content-xl-start {
    justify-content: flex-start;
  }
  .justify-content-xl-end {
    justify-content: flex-end;
  }
  .justify-content-xl-center {
    justify-content: center;
  }
  .justify-content-xl-between {
    justify-content: space-between;
  }
  .justify-content-xl-around {
    justify-content: space-around;
  }
  .justify-content-xl-evenly {
    justify-content: space-evenly;
  }
  .align-items-xl-start {
    align-items: flex-start;
  }
  .align-items-xl-end {
    align-items: flex-end;
  }
  .align-items-xl-center {
    align-items: center;
  }
  .align-items-xl-baseline {
    align-items: baseline;
  }
  .align-items-xl-stretch {
    align-items: stretch;
  }
  .align-content-xl-start {
    align-content: flex-start;
  }
  .align-content-xl-end {
    align-content: flex-end;
  }
  .align-content-xl-center {
    align-content: center;
  }
  .align-content-xl-between {
    align-content: space-between;
  }
  .align-content-xl-around {
    align-content: space-around;
  }
  .align-content-xl-stretch {
    align-content: stretch;
  }
  .align-self-xl-auto {
    align-self: auto;
  }
  .align-self-xl-start {
    align-self: flex-start;
  }
  .align-self-xl-end {
    align-self: flex-end;
  }
  .align-self-xl-center {
    align-self: center;
  }
  .align-self-xl-baseline {
    align-self: baseline;
  }
  .align-self-xl-stretch {
    align-self: stretch;
  }
  .order-xl-first {
    order: -1;
  }
  .order-xl-0 {
    order: 0;
  }
  .order-xl-1 {
    order: 1;
  }
  .order-xl-2 {
    order: 2;
  }
  .order-xl-3 {
    order: 3;
  }
  .order-xl-4 {
    order: 4;
  }
  .order-xl-5 {
    order: 5;
  }
  .order-xl-last {
    order: 6;
  }
  .m-xl-0 {
    margin: 0;
  }
  .m-xl-0-5 {
    margin: 0.25rem;
  }
  .m-xl-1 {
    margin: 0.5rem;
  }
  .m-xl-1-5 {
    margin: 0.75rem;
  }
  .m-xl-2 {
    margin: 1rem;
  }
  .m-xl-2-5 {
    margin: 1.25rem;
  }
  .m-xl-3 {
    margin: 1.5rem;
  }
  .m-xl-3-5 {
    margin: 1.75rem;
  }
  .m-xl-4 {
    margin: 2rem;
  }
  .m-xl-5 {
    margin: 2.5rem;
  }
  .m-xl-5-5 {
    margin: 2.75rem;
  }
  .m-xl-6 {
    margin: 3rem;
  }
  .m-xl-7 {
    margin: 3.5rem;
  }
  .m-xl-7-5 {
    margin: 3.75rem;
  }
  .m-xl-8 {
    margin: 4rem;
  }
  .m-xl-9 {
    margin: 4.5rem;
  }
  .m-xl-9-5 {
    margin: 4.75rem;
  }
  .m-xl-10 {
    margin: 5rem;
  }
  .m-xl-auto {
    margin: auto;
  }
  .mx-xl-0 {
    margin-right: 0;
    margin-left: 0;
  }
  .mx-xl-0-5 {
    margin-right: 0.25rem;
    margin-left: 0.25rem;
  }
  .mx-xl-1 {
    margin-right: 0.5rem;
    margin-left: 0.5rem;
  }
  .mx-xl-1-5 {
    margin-right: 0.75rem;
    margin-left: 0.75rem;
  }
  .mx-xl-2 {
    margin-right: 1rem;
    margin-left: 1rem;
  }
  .mx-xl-2-5 {
    margin-right: 1.25rem;
    margin-left: 1.25rem;
  }
  .mx-xl-3 {
    margin-right: 1.5rem;
    margin-left: 1.5rem;
  }
  .mx-xl-3-5 {
    margin-right: 1.75rem;
    margin-left: 1.75rem;
  }
  .mx-xl-4 {
    margin-right: 2rem;
    margin-left: 2rem;
  }
  .mx-xl-5 {
    margin-right: 2.5rem;
    margin-left: 2.5rem;
  }
  .mx-xl-5-5 {
    margin-right: 2.75rem;
    margin-left: 2.75rem;
  }
  .mx-xl-6 {
    margin-right: 3rem;
    margin-left: 3rem;
  }
  .mx-xl-7 {
    margin-right: 3.5rem;
    margin-left: 3.5rem;
  }
  .mx-xl-7-5 {
    margin-right: 3.75rem;
    margin-left: 3.75rem;
  }
  .mx-xl-8 {
    margin-right: 4rem;
    margin-left: 4rem;
  }
  .mx-xl-9 {
    margin-right: 4.5rem;
    margin-left: 4.5rem;
  }
  .mx-xl-9-5 {
    margin-right: 4.75rem;
    margin-left: 4.75rem;
  }
  .mx-xl-10 {
    margin-right: 5rem;
    margin-left: 5rem;
  }
  .mx-xl-auto {
    margin-right: auto;
    margin-left: auto;
  }
  .my-xl-0 {
    margin-top: 0;
    margin-bottom: 0;
  }
  .my-xl-0-5 {
    margin-top: 0.25rem;
    margin-bottom: 0.25rem;
  }
  .my-xl-1 {
    margin-top: 0.5rem;
    margin-bottom: 0.5rem;
  }
  .my-xl-1-5 {
    margin-top: 0.75rem;
    margin-bottom: 0.75rem;
  }
  .my-xl-2 {
    margin-top: 1rem;
    margin-bottom: 1rem;
  }
  .my-xl-2-5 {
    margin-top: 1.25rem;
    margin-bottom: 1.25rem;
  }
  .my-xl-3 {
    margin-top: 1.5rem;
    margin-bottom: 1.5rem;
  }
  .my-xl-3-5 {
    margin-top: 1.75rem;
    margin-bottom: 1.75rem;
  }
  .my-xl-4 {
    margin-top: 2rem;
    margin-bottom: 2rem;
  }
  .my-xl-5 {
    margin-top: 2.5rem;
    margin-bottom: 2.5rem;
  }
  .my-xl-5-5 {
    margin-top: 2.75rem;
    margin-bottom: 2.75rem;
  }
  .my-xl-6 {
    margin-top: 3rem;
    margin-bottom: 3rem;
  }
  .my-xl-7 {
    margin-top: 3.5rem;
    margin-bottom: 3.5rem;
  }
  .my-xl-7-5 {
    margin-top: 3.75rem;
    margin-bottom: 3.75rem;
  }
  .my-xl-8 {
    margin-top: 4rem;
    margin-bottom: 4rem;
  }
  .my-xl-9 {
    margin-top: 4.5rem;
    margin-bottom: 4.5rem;
  }
  .my-xl-9-5 {
    margin-top: 4.75rem;
    margin-bottom: 4.75rem;
  }
  .my-xl-10 {
    margin-top: 5rem;
    margin-bottom: 5rem;
  }
  .my-xl-auto {
    margin-top: auto;
    margin-bottom: auto;
  }
  .mt-xl-0 {
    margin-top: 0;
  }
  .mt-xl-0-5 {
    margin-top: 0.25rem;
  }
  .mt-xl-1 {
    margin-top: 0.5rem;
  }
  .mt-xl-1-5 {
    margin-top: 0.75rem;
  }
  .mt-xl-2 {
    margin-top: 1rem;
  }
  .mt-xl-2-5 {
    margin-top: 1.25rem;
  }
  .mt-xl-3 {
    margin-top: 1.5rem;
  }
  .mt-xl-3-5 {
    margin-top: 1.75rem;
  }
  .mt-xl-4 {
    margin-top: 2rem;
  }
  .mt-xl-5 {
    margin-top: 2.5rem;
  }
  .mt-xl-5-5 {
    margin-top: 2.75rem;
  }
  .mt-xl-6 {
    margin-top: 3rem;
  }
  .mt-xl-7 {
    margin-top: 3.5rem;
  }
  .mt-xl-7-5 {
    margin-top: 3.75rem;
  }
  .mt-xl-8 {
    margin-top: 4rem;
  }
  .mt-xl-9 {
    margin-top: 4.5rem;
  }
  .mt-xl-9-5 {
    margin-top: 4.75rem;
  }
  .mt-xl-10 {
    margin-top: 5rem;
  }
  .mt-xl-auto {
    margin-top: auto;
  }
  .me-xl-0 {
    margin-right: 0;
  }
  .me-xl-0-5 {
    margin-right: 0.25rem;
  }
  .me-xl-1 {
    margin-right: 0.5rem;
  }
  .me-xl-1-5 {
    margin-right: 0.75rem;
  }
  .me-xl-2 {
    margin-right: 1rem;
  }
  .me-xl-2-5 {
    margin-right: 1.25rem;
  }
  .me-xl-3 {
    margin-right: 1.5rem;
  }
  .me-xl-3-5 {
    margin-right: 1.75rem;
  }
  .me-xl-4 {
    margin-right: 2rem;
  }
  .me-xl-5 {
    margin-right: 2.5rem;
  }
  .me-xl-5-5 {
    margin-right: 2.75rem;
  }
  .me-xl-6 {
    margin-right: 3rem;
  }
  .me-xl-7 {
    margin-right: 3.5rem;
  }
  .me-xl-7-5 {
    margin-right: 3.75rem;
  }
  .me-xl-8 {
    margin-right: 4rem;
  }
  .me-xl-9 {
    margin-right: 4.5rem;
  }
  .me-xl-9-5 {
    margin-right: 4.75rem;
  }
  .me-xl-10 {
    margin-right: 5rem;
  }
  .me-xl-auto {
    margin-right: auto;
  }
  .mb-xl-0 {
    margin-bottom: 0;
  }
  .mb-xl-0-5 {
    margin-bottom: 0.25rem;
  }
  .mb-xl-1 {
    margin-bottom: 0.5rem;
  }
  .mb-xl-1-5 {
    margin-bottom: 0.75rem;
  }
  .mb-xl-2 {
    margin-bottom: 1rem;
  }
  .mb-xl-2-5 {
    margin-bottom: 1.25rem;
  }
  .mb-xl-3 {
    margin-bottom: 1.5rem;
  }
  .mb-xl-3-5 {
    margin-bottom: 1.75rem;
  }
  .mb-xl-4 {
    margin-bottom: 2rem;
  }
  .mb-xl-5 {
    margin-bottom: 2.5rem;
  }
  .mb-xl-5-5 {
    margin-bottom: 2.75rem;
  }
  .mb-xl-6 {
    margin-bottom: 3rem;
  }
  .mb-xl-7 {
    margin-bottom: 3.5rem;
  }
  .mb-xl-7-5 {
    margin-bottom: 3.75rem;
  }
  .mb-xl-8 {
    margin-bottom: 4rem;
  }
  .mb-xl-9 {
    margin-bottom: 4.5rem;
  }
  .mb-xl-9-5 {
    margin-bottom: 4.75rem;
  }
  .mb-xl-10 {
    margin-bottom: 5rem;
  }
  .mb-xl-auto {
    margin-bottom: auto;
  }
  .ms-xl-0 {
    margin-left: 0;
  }
  .ms-xl-0-5 {
    margin-left: 0.25rem;
  }
  .ms-xl-1 {
    margin-left: 0.5rem;
  }
  .ms-xl-1-5 {
    margin-left: 0.75rem;
  }
  .ms-xl-2 {
    margin-left: 1rem;
  }
  .ms-xl-2-5 {
    margin-left: 1.25rem;
  }
  .ms-xl-3 {
    margin-left: 1.5rem;
  }
  .ms-xl-3-5 {
    margin-left: 1.75rem;
  }
  .ms-xl-4 {
    margin-left: 2rem;
  }
  .ms-xl-5 {
    margin-left: 2.5rem;
  }
  .ms-xl-5-5 {
    margin-left: 2.75rem;
  }
  .ms-xl-6 {
    margin-left: 3rem;
  }
  .ms-xl-7 {
    margin-left: 3.5rem;
  }
  .ms-xl-7-5 {
    margin-left: 3.75rem;
  }
  .ms-xl-8 {
    margin-left: 4rem;
  }
  .ms-xl-9 {
    margin-left: 4.5rem;
  }
  .ms-xl-9-5 {
    margin-left: 4.75rem;
  }
  .ms-xl-10 {
    margin-left: 5rem;
  }
  .ms-xl-auto {
    margin-left: auto;
  }
  .m-xl-n0 {
    margin: 0;
  }
  .m-xl-n0-5 {
    margin: -0.25rem;
  }
  .m-xl-n1 {
    margin: -0.5rem;
  }
  .m-xl-n1-5 {
    margin: -0.75rem;
  }
  .m-xl-n2 {
    margin: -1rem;
  }
  .m-xl-n2-5 {
    margin: -1.25rem;
  }
  .m-xl-n3 {
    margin: -1.5rem;
  }
  .m-xl-n3-5 {
    margin: -1.75rem;
  }
  .m-xl-n4 {
    margin: -2rem;
  }
  .m-xl-n5 {
    margin: -2.5rem;
  }
  .m-xl-n5-5 {
    margin: -2.75rem;
  }
  .m-xl-n6 {
    margin: -3rem;
  }
  .m-xl-n7 {
    margin: -3.5rem;
  }
  .m-xl-n7-5 {
    margin: -3.75rem;
  }
  .m-xl-n8 {
    margin: -4rem;
  }
  .m-xl-n9 {
    margin: -4.5rem;
  }
  .m-xl-n9-5 {
    margin: -4.75rem;
  }
  .m-xl-n10 {
    margin: -5rem;
  }
  .m-xl-nauto {
    margin: -"auto";
  }
  .mx-xl-n0 {
    margin-right: 0;
    margin-left: 0;
  }
  .mx-xl-n0-5 {
    margin-right: -0.25rem;
    margin-left: -0.25rem;
  }
  .mx-xl-n1 {
    margin-right: -0.5rem;
    margin-left: -0.5rem;
  }
  .mx-xl-n1-5 {
    margin-right: -0.75rem;
    margin-left: -0.75rem;
  }
  .mx-xl-n2 {
    margin-right: -1rem;
    margin-left: -1rem;
  }
  .mx-xl-n2-5 {
    margin-right: -1.25rem;
    margin-left: -1.25rem;
  }
  .mx-xl-n3 {
    margin-right: -1.5rem;
    margin-left: -1.5rem;
  }
  .mx-xl-n3-5 {
    margin-right: -1.75rem;
    margin-left: -1.75rem;
  }
  .mx-xl-n4 {
    margin-right: -2rem;
    margin-left: -2rem;
  }
  .mx-xl-n5 {
    margin-right: -2.5rem;
    margin-left: -2.5rem;
  }
  .mx-xl-n5-5 {
    margin-right: -2.75rem;
    margin-left: -2.75rem;
  }
  .mx-xl-n6 {
    margin-right: -3rem;
    margin-left: -3rem;
  }
  .mx-xl-n7 {
    margin-right: -3.5rem;
    margin-left: -3.5rem;
  }
  .mx-xl-n7-5 {
    margin-right: -3.75rem;
    margin-left: -3.75rem;
  }
  .mx-xl-n8 {
    margin-right: -4rem;
    margin-left: -4rem;
  }
  .mx-xl-n9 {
    margin-right: -4.5rem;
    margin-left: -4.5rem;
  }
  .mx-xl-n9-5 {
    margin-right: -4.75rem;
    margin-left: -4.75rem;
  }
  .mx-xl-n10 {
    margin-right: -5rem;
    margin-left: -5rem;
  }
  .mx-xl-nauto {
    margin-right: -"auto";
    margin-left: -"auto";
  }
  .my-xl-n0 {
    margin-top: 0;
    margin-bottom: 0;
  }
  .my-xl-n0-5 {
    margin-top: -0.25rem;
    margin-bottom: -0.25rem;
  }
  .my-xl-n1 {
    margin-top: -0.5rem;
    margin-bottom: -0.5rem;
  }
  .my-xl-n1-5 {
    margin-top: -0.75rem;
    margin-bottom: -0.75rem;
  }
  .my-xl-n2 {
    margin-top: -1rem;
    margin-bottom: -1rem;
  }
  .my-xl-n2-5 {
    margin-top: -1.25rem;
    margin-bottom: -1.25rem;
  }
  .my-xl-n3 {
    margin-top: -1.5rem;
    margin-bottom: -1.5rem;
  }
  .my-xl-n3-5 {
    margin-top: -1.75rem;
    margin-bottom: -1.75rem;
  }
  .my-xl-n4 {
    margin-top: -2rem;
    margin-bottom: -2rem;
  }
  .my-xl-n5 {
    margin-top: -2.5rem;
    margin-bottom: -2.5rem;
  }
  .my-xl-n5-5 {
    margin-top: -2.75rem;
    margin-bottom: -2.75rem;
  }
  .my-xl-n6 {
    margin-top: -3rem;
    margin-bottom: -3rem;
  }
  .my-xl-n7 {
    margin-top: -3.5rem;
    margin-bottom: -3.5rem;
  }
  .my-xl-n7-5 {
    margin-top: -3.75rem;
    margin-bottom: -3.75rem;
  }
  .my-xl-n8 {
    margin-top: -4rem;
    margin-bottom: -4rem;
  }
  .my-xl-n9 {
    margin-top: -4.5rem;
    margin-bottom: -4.5rem;
  }
  .my-xl-n9-5 {
    margin-top: -4.75rem;
    margin-bottom: -4.75rem;
  }
  .my-xl-n10 {
    margin-top: -5rem;
    margin-bottom: -5rem;
  }
  .my-xl-nauto {
    margin-top: -"auto";
    margin-bottom: -"auto";
  }
  .mt-xl-n0 {
    margin-top: 0;
  }
  .mt-xl-n0-5 {
    margin-top: -0.25rem;
  }
  .mt-xl-n1 {
    margin-top: -0.5rem;
  }
  .mt-xl-n1-5 {
    margin-top: -0.75rem;
  }
  .mt-xl-n2 {
    margin-top: -1rem;
  }
  .mt-xl-n2-5 {
    margin-top: -1.25rem;
  }
  .mt-xl-n3 {
    margin-top: -1.5rem;
  }
  .mt-xl-n3-5 {
    margin-top: -1.75rem;
  }
  .mt-xl-n4 {
    margin-top: -2rem;
  }
  .mt-xl-n5 {
    margin-top: -2.5rem;
  }
  .mt-xl-n5-5 {
    margin-top: -2.75rem;
  }
  .mt-xl-n6 {
    margin-top: -3rem;
  }
  .mt-xl-n7 {
    margin-top: -3.5rem;
  }
  .mt-xl-n7-5 {
    margin-top: -3.75rem;
  }
  .mt-xl-n8 {
    margin-top: -4rem;
  }
  .mt-xl-n9 {
    margin-top: -4.5rem;
  }
  .mt-xl-n9-5 {
    margin-top: -4.75rem;
  }
  .mt-xl-n10 {
    margin-top: -5rem;
  }
  .mt-xl-nauto {
    margin-top: -"auto";
  }
  .me-xl-n0 {
    margin-right: 0;
  }
  .me-xl-n0-5 {
    margin-right: -0.25rem;
  }
  .me-xl-n1 {
    margin-right: -0.5rem;
  }
  .me-xl-n1-5 {
    margin-right: -0.75rem;
  }
  .me-xl-n2 {
    margin-right: -1rem;
  }
  .me-xl-n2-5 {
    margin-right: -1.25rem;
  }
  .me-xl-n3 {
    margin-right: -1.5rem;
  }
  .me-xl-n3-5 {
    margin-right: -1.75rem;
  }
  .me-xl-n4 {
    margin-right: -2rem;
  }
  .me-xl-n5 {
    margin-right: -2.5rem;
  }
  .me-xl-n5-5 {
    margin-right: -2.75rem;
  }
  .me-xl-n6 {
    margin-right: -3rem;
  }
  .me-xl-n7 {
    margin-right: -3.5rem;
  }
  .me-xl-n7-5 {
    margin-right: -3.75rem;
  }
  .me-xl-n8 {
    margin-right: -4rem;
  }
  .me-xl-n9 {
    margin-right: -4.5rem;
  }
  .me-xl-n9-5 {
    margin-right: -4.75rem;
  }
  .me-xl-n10 {
    margin-right: -5rem;
  }
  .me-xl-nauto {
    margin-right: -"auto";
  }
  .mb-xl-n0 {
    margin-bottom: 0;
  }
  .mb-xl-n0-5 {
    margin-bottom: -0.25rem;
  }
  .mb-xl-n1 {
    margin-bottom: -0.5rem;
  }
  .mb-xl-n1-5 {
    margin-bottom: -0.75rem;
  }
  .mb-xl-n2 {
    margin-bottom: -1rem;
  }
  .mb-xl-n2-5 {
    margin-bottom: -1.25rem;
  }
  .mb-xl-n3 {
    margin-bottom: -1.5rem;
  }
  .mb-xl-n3-5 {
    margin-bottom: -1.75rem;
  }
  .mb-xl-n4 {
    margin-bottom: -2rem;
  }
  .mb-xl-n5 {
    margin-bottom: -2.5rem;
  }
  .mb-xl-n5-5 {
    margin-bottom: -2.75rem;
  }
  .mb-xl-n6 {
    margin-bottom: -3rem;
  }
  .mb-xl-n7 {
    margin-bottom: -3.5rem;
  }
  .mb-xl-n7-5 {
    margin-bottom: -3.75rem;
  }
  .mb-xl-n8 {
    margin-bottom: -4rem;
  }
  .mb-xl-n9 {
    margin-bottom: -4.5rem;
  }
  .mb-xl-n9-5 {
    margin-bottom: -4.75rem;
  }
  .mb-xl-n10 {
    margin-bottom: -5rem;
  }
  .mb-xl-nauto {
    margin-bottom: -"auto";
  }
  .ms-xl-n0 {
    margin-left: 0;
  }
  .ms-xl-n0-5 {
    margin-left: -0.25rem;
  }
  .ms-xl-n1 {
    margin-left: -0.5rem;
  }
  .ms-xl-n1-5 {
    margin-left: -0.75rem;
  }
  .ms-xl-n2 {
    margin-left: -1rem;
  }
  .ms-xl-n2-5 {
    margin-left: -1.25rem;
  }
  .ms-xl-n3 {
    margin-left: -1.5rem;
  }
  .ms-xl-n3-5 {
    margin-left: -1.75rem;
  }
  .ms-xl-n4 {
    margin-left: -2rem;
  }
  .ms-xl-n5 {
    margin-left: -2.5rem;
  }
  .ms-xl-n5-5 {
    margin-left: -2.75rem;
  }
  .ms-xl-n6 {
    margin-left: -3rem;
  }
  .ms-xl-n7 {
    margin-left: -3.5rem;
  }
  .ms-xl-n7-5 {
    margin-left: -3.75rem;
  }
  .ms-xl-n8 {
    margin-left: -4rem;
  }
  .ms-xl-n9 {
    margin-left: -4.5rem;
  }
  .ms-xl-n9-5 {
    margin-left: -4.75rem;
  }
  .ms-xl-n10 {
    margin-left: -5rem;
  }
  .ms-xl-nauto {
    margin-left: -"auto";
  }
  .p-xl-0 {
    padding: 0;
  }
  .p-xl-0-5 {
    padding: 0.25rem;
  }
  .p-xl-1 {
    padding: 0.5rem;
  }
  .p-xl-1-5 {
    padding: 0.75rem;
  }
  .p-xl-2 {
    padding: 1rem;
  }
  .p-xl-2-5 {
    padding: 1.25rem;
  }
  .p-xl-3 {
    padding: 1.5rem;
  }
  .p-xl-3-5 {
    padding: 1.75rem;
  }
  .p-xl-4 {
    padding: 2rem;
  }
  .p-xl-5 {
    padding: 2.5rem;
  }
  .p-xl-5-5 {
    padding: 2.75rem;
  }
  .p-xl-6 {
    padding: 3rem;
  }
  .p-xl-7 {
    padding: 3.5rem;
  }
  .p-xl-7-5 {
    padding: 3.75rem;
  }
  .p-xl-8 {
    padding: 4rem;
  }
  .p-xl-9 {
    padding: 4.5rem;
  }
  .p-xl-9-5 {
    padding: 4.75rem;
  }
  .p-xl-10 {
    padding: 5rem;
  }
  .p-xl-auto {
    padding: "auto";
  }
  .px-xl-0 {
    padding-right: 0;
    padding-left: 0;
  }
  .px-xl-0-5 {
    padding-right: 0.25rem;
    padding-left: 0.25rem;
  }
  .px-xl-1 {
    padding-right: 0.5rem;
    padding-left: 0.5rem;
  }
  .px-xl-1-5 {
    padding-right: 0.75rem;
    padding-left: 0.75rem;
  }
  .px-xl-2 {
    padding-right: 1rem;
    padding-left: 1rem;
  }
  .px-xl-2-5 {
    padding-right: 1.25rem;
    padding-left: 1.25rem;
  }
  .px-xl-3 {
    padding-right: 1.5rem;
    padding-left: 1.5rem;
  }
  .px-xl-3-5 {
    padding-right: 1.75rem;
    padding-left: 1.75rem;
  }
  .px-xl-4 {
    padding-right: 2rem;
    padding-left: 2rem;
  }
  .px-xl-5 {
    padding-right: 2.5rem;
    padding-left: 2.5rem;
  }
  .px-xl-5-5 {
    padding-right: 2.75rem;
    padding-left: 2.75rem;
  }
  .px-xl-6 {
    padding-right: 3rem;
    padding-left: 3rem;
  }
  .px-xl-7 {
    padding-right: 3.5rem;
    padding-left: 3.5rem;
  }
  .px-xl-7-5 {
    padding-right: 3.75rem;
    padding-left: 3.75rem;
  }
  .px-xl-8 {
    padding-right: 4rem;
    padding-left: 4rem;
  }
  .px-xl-9 {
    padding-right: 4.5rem;
    padding-left: 4.5rem;
  }
  .px-xl-9-5 {
    padding-right: 4.75rem;
    padding-left: 4.75rem;
  }
  .px-xl-10 {
    padding-right: 5rem;
    padding-left: 5rem;
  }
  .px-xl-auto {
    padding-right: "auto";
    padding-left: "auto";
  }
  .py-xl-0 {
    padding-top: 0;
    padding-bottom: 0;
  }
  .py-xl-0-5 {
    padding-top: 0.25rem;
    padding-bottom: 0.25rem;
  }
  .py-xl-1 {
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
  }
  .py-xl-1-5 {
    padding-top: 0.75rem;
    padding-bottom: 0.75rem;
  }
  .py-xl-2 {
    padding-top: 1rem;
    padding-bottom: 1rem;
  }
  .py-xl-2-5 {
    padding-top: 1.25rem;
    padding-bottom: 1.25rem;
  }
  .py-xl-3 {
    padding-top: 1.5rem;
    padding-bottom: 1.5rem;
  }
  .py-xl-3-5 {
    padding-top: 1.75rem;
    padding-bottom: 1.75rem;
  }
  .py-xl-4 {
    padding-top: 2rem;
    padding-bottom: 2rem;
  }
  .py-xl-5 {
    padding-top: 2.5rem;
    padding-bottom: 2.5rem;
  }
  .py-xl-5-5 {
    padding-top: 2.75rem;
    padding-bottom: 2.75rem;
  }
  .py-xl-6 {
    padding-top: 3rem;
    padding-bottom: 3rem;
  }
  .py-xl-7 {
    padding-top: 3.5rem;
    padding-bottom: 3.5rem;
  }
  .py-xl-7-5 {
    padding-top: 3.75rem;
    padding-bottom: 3.75rem;
  }
  .py-xl-8 {
    padding-top: 4rem;
    padding-bottom: 4rem;
  }
  .py-xl-9 {
    padding-top: 4.5rem;
    padding-bottom: 4.5rem;
  }
  .py-xl-9-5 {
    padding-top: 4.75rem;
    padding-bottom: 4.75rem;
  }
  .py-xl-10 {
    padding-top: 5rem;
    padding-bottom: 5rem;
  }
  .py-xl-auto {
    padding-top: "auto";
    padding-bottom: "auto";
  }
  .pt-xl-0 {
    padding-top: 0;
  }
  .pt-xl-0-5 {
    padding-top: 0.25rem;
  }
  .pt-xl-1 {
    padding-top: 0.5rem;
  }
  .pt-xl-1-5 {
    padding-top: 0.75rem;
  }
  .pt-xl-2 {
    padding-top: 1rem;
  }
  .pt-xl-2-5 {
    padding-top: 1.25rem;
  }
  .pt-xl-3 {
    padding-top: 1.5rem;
  }
  .pt-xl-3-5 {
    padding-top: 1.75rem;
  }
  .pt-xl-4 {
    padding-top: 2rem;
  }
  .pt-xl-5 {
    padding-top: 2.5rem;
  }
  .pt-xl-5-5 {
    padding-top: 2.75rem;
  }
  .pt-xl-6 {
    padding-top: 3rem;
  }
  .pt-xl-7 {
    padding-top: 3.5rem;
  }
  .pt-xl-7-5 {
    padding-top: 3.75rem;
  }
  .pt-xl-8 {
    padding-top: 4rem;
  }
  .pt-xl-9 {
    padding-top: 4.5rem;
  }
  .pt-xl-9-5 {
    padding-top: 4.75rem;
  }
  .pt-xl-10 {
    padding-top: 5rem;
  }
  .pt-xl-auto {
    padding-top: "auto";
  }
  .pe-xl-0 {
    padding-right: 0;
  }
  .pe-xl-0-5 {
    padding-right: 0.25rem;
  }
  .pe-xl-1 {
    padding-right: 0.5rem;
  }
  .pe-xl-1-5 {
    padding-right: 0.75rem;
  }
  .pe-xl-2 {
    padding-right: 1rem;
  }
  .pe-xl-2-5 {
    padding-right: 1.25rem;
  }
  .pe-xl-3 {
    padding-right: 1.5rem;
  }
  .pe-xl-3-5 {
    padding-right: 1.75rem;
  }
  .pe-xl-4 {
    padding-right: 2rem;
  }
  .pe-xl-5 {
    padding-right: 2.5rem;
  }
  .pe-xl-5-5 {
    padding-right: 2.75rem;
  }
  .pe-xl-6 {
    padding-right: 3rem;
  }
  .pe-xl-7 {
    padding-right: 3.5rem;
  }
  .pe-xl-7-5 {
    padding-right: 3.75rem;
  }
  .pe-xl-8 {
    padding-right: 4rem;
  }
  .pe-xl-9 {
    padding-right: 4.5rem;
  }
  .pe-xl-9-5 {
    padding-right: 4.75rem;
  }
  .pe-xl-10 {
    padding-right: 5rem;
  }
  .pe-xl-auto {
    padding-right: "auto";
  }
  .pb-xl-0 {
    padding-bottom: 0;
  }
  .pb-xl-0-5 {
    padding-bottom: 0.25rem;
  }
  .pb-xl-1 {
    padding-bottom: 0.5rem;
  }
  .pb-xl-1-5 {
    padding-bottom: 0.75rem;
  }
  .pb-xl-2 {
    padding-bottom: 1rem;
  }
  .pb-xl-2-5 {
    padding-bottom: 1.25rem;
  }
  .pb-xl-3 {
    padding-bottom: 1.5rem;
  }
  .pb-xl-3-5 {
    padding-bottom: 1.75rem;
  }
  .pb-xl-4 {
    padding-bottom: 2rem;
  }
  .pb-xl-5 {
    padding-bottom: 2.5rem;
  }
  .pb-xl-5-5 {
    padding-bottom: 2.75rem;
  }
  .pb-xl-6 {
    padding-bottom: 3rem;
  }
  .pb-xl-7 {
    padding-bottom: 3.5rem;
  }
  .pb-xl-7-5 {
    padding-bottom: 3.75rem;
  }
  .pb-xl-8 {
    padding-bottom: 4rem;
  }
  .pb-xl-9 {
    padding-bottom: 4.5rem;
  }
  .pb-xl-9-5 {
    padding-bottom: 4.75rem;
  }
  .pb-xl-10 {
    padding-bottom: 5rem;
  }
  .pb-xl-auto {
    padding-bottom: "auto";
  }
  .ps-xl-0 {
    padding-left: 0;
  }
  .ps-xl-0-5 {
    padding-left: 0.25rem;
  }
  .ps-xl-1 {
    padding-left: 0.5rem;
  }
  .ps-xl-1-5 {
    padding-left: 0.75rem;
  }
  .ps-xl-2 {
    padding-left: 1rem;
  }
  .ps-xl-2-5 {
    padding-left: 1.25rem;
  }
  .ps-xl-3 {
    padding-left: 1.5rem;
  }
  .ps-xl-3-5 {
    padding-left: 1.75rem;
  }
  .ps-xl-4 {
    padding-left: 2rem;
  }
  .ps-xl-5 {
    padding-left: 2.5rem;
  }
  .ps-xl-5-5 {
    padding-left: 2.75rem;
  }
  .ps-xl-6 {
    padding-left: 3rem;
  }
  .ps-xl-7 {
    padding-left: 3.5rem;
  }
  .ps-xl-7-5 {
    padding-left: 3.75rem;
  }
  .ps-xl-8 {
    padding-left: 4rem;
  }
  .ps-xl-9 {
    padding-left: 4.5rem;
  }
  .ps-xl-9-5 {
    padding-left: 4.75rem;
  }
  .ps-xl-10 {
    padding-left: 5rem;
  }
  .ps-xl-auto {
    padding-left: "auto";
  }
  .gap-xl-0 {
    gap: 0;
  }
  .gap-xl-0-5 {
    gap: 0.25rem;
  }
  .gap-xl-1 {
    gap: 0.5rem;
  }
  .gap-xl-1-5 {
    gap: 0.75rem;
  }
  .gap-xl-2 {
    gap: 1rem;
  }
  .gap-xl-2-5 {
    gap: 1.25rem;
  }
  .gap-xl-3 {
    gap: 1.5rem;
  }
  .gap-xl-3-5 {
    gap: 1.75rem;
  }
  .gap-xl-4 {
    gap: 2rem;
  }
  .gap-xl-5 {
    gap: 2.5rem;
  }
  .gap-xl-5-5 {
    gap: 2.75rem;
  }
  .gap-xl-6 {
    gap: 3rem;
  }
  .gap-xl-7 {
    gap: 3.5rem;
  }
  .gap-xl-7-5 {
    gap: 3.75rem;
  }
  .gap-xl-8 {
    gap: 4rem;
  }
  .gap-xl-9 {
    gap: 4.5rem;
  }
  .gap-xl-9-5 {
    gap: 4.75rem;
  }
  .gap-xl-10 {
    gap: 5rem;
  }
  .gap-xl-auto {
    gap: "auto";
  }
  .text-xl-start {
    text-align: left;
  }
  .text-xl-end {
    text-align: right;
  }
  .text-xl-center {
    text-align: center;
  }
  .rounded-xl {
    border-radius: var(--bs-border-radius);
  }
  .rounded-xl-0 {
    border-radius: 0;
  }
  .rounded-xl-1 {
    border-radius: var(--bs-border-radius-sm);
  }
  .rounded-xl-2 {
    border-radius: var(--bs-border-radius);
  }
  .rounded-xl-3 {
    border-radius: var(--bs-border-radius-lg);
  }
  .rounded-xl-4 {
    border-radius: var(--bs-border-radius-xl);
  }
  .rounded-xl-5 {
    border-radius: var(--bs-border-radius-2xl);
  }
  .rounded-xl-circle {
    border-radius: 50%;
  }
  .rounded-xl-pill {
    border-radius: var(--bs-border-radius-pill);
  }
}
@media (min-width: 1400px) {
  .float-xxl-start {
    float: left;
  }
  .float-xxl-end {
    float: right;
  }
  .float-xxl-none {
    float: none;
  }
  .d-xxl-inline {
    display: inline;
  }
  .d-xxl-inline-block {
    display: inline-block;
  }
  .d-xxl-block {
    display: block;
  }
  .d-xxl-grid {
    display: grid;
  }
  .d-xxl-table {
    display: table;
  }
  .d-xxl-table-row {
    display: table-row;
  }
  .d-xxl-table-cell {
    display: table-cell;
  }
  .d-xxl-flex {
    display: flex;
  }
  .d-xxl-inline-flex {
    display: inline-flex;
  }
  .d-xxl-none {
    display: none;
  }
  .w-xxl-25 {
    width: 25%;
  }
  .w-xxl-50 {
    width: 50%;
  }
  .w-xxl-75 {
    width: 75%;
  }
  .w-xxl-100 {
    width: 100%;
  }
  .w-xxl-auto {
    width: auto;
  }
  .flex-xxl-fill {
    flex: 1 1 auto;
  }
  .flex-xxl-row {
    flex-direction: row;
  }
  .flex-xxl-column {
    flex-direction: column;
  }
  .flex-xxl-row-reverse {
    flex-direction: row-reverse;
  }
  .flex-xxl-column-reverse {
    flex-direction: column-reverse;
  }
  .flex-xxl-grow-0 {
    flex-grow: 0;
  }
  .flex-xxl-grow-1 {
    flex-grow: 1;
  }
  .flex-xxl-shrink-0 {
    flex-shrink: 0;
  }
  .flex-xxl-shrink-1 {
    flex-shrink: 1;
  }
  .flex-xxl-wrap {
    flex-wrap: wrap;
  }
  .flex-xxl-nowrap {
    flex-wrap: nowrap;
  }
  .flex-xxl-wrap-reverse {
    flex-wrap: wrap-reverse;
  }
  .justify-content-xxl-start {
    justify-content: flex-start;
  }
  .justify-content-xxl-end {
    justify-content: flex-end;
  }
  .justify-content-xxl-center {
    justify-content: center;
  }
  .justify-content-xxl-between {
    justify-content: space-between;
  }
  .justify-content-xxl-around {
    justify-content: space-around;
  }
  .justify-content-xxl-evenly {
    justify-content: space-evenly;
  }
  .align-items-xxl-start {
    align-items: flex-start;
  }
  .align-items-xxl-end {
    align-items: flex-end;
  }
  .align-items-xxl-center {
    align-items: center;
  }
  .align-items-xxl-baseline {
    align-items: baseline;
  }
  .align-items-xxl-stretch {
    align-items: stretch;
  }
  .align-content-xxl-start {
    align-content: flex-start;
  }
  .align-content-xxl-end {
    align-content: flex-end;
  }
  .align-content-xxl-center {
    align-content: center;
  }
  .align-content-xxl-between {
    align-content: space-between;
  }
  .align-content-xxl-around {
    align-content: space-around;
  }
  .align-content-xxl-stretch {
    align-content: stretch;
  }
  .align-self-xxl-auto {
    align-self: auto;
  }
  .align-self-xxl-start {
    align-self: flex-start;
  }
  .align-self-xxl-end {
    align-self: flex-end;
  }
  .align-self-xxl-center {
    align-self: center;
  }
  .align-self-xxl-baseline {
    align-self: baseline;
  }
  .align-self-xxl-stretch {
    align-self: stretch;
  }
  .order-xxl-first {
    order: -1;
  }
  .order-xxl-0 {
    order: 0;
  }
  .order-xxl-1 {
    order: 1;
  }
  .order-xxl-2 {
    order: 2;
  }
  .order-xxl-3 {
    order: 3;
  }
  .order-xxl-4 {
    order: 4;
  }
  .order-xxl-5 {
    order: 5;
  }
  .order-xxl-last {
    order: 6;
  }
  .m-xxl-0 {
    margin: 0;
  }
  .m-xxl-0-5 {
    margin: 0.25rem;
  }
  .m-xxl-1 {
    margin: 0.5rem;
  }
  .m-xxl-1-5 {
    margin: 0.75rem;
  }
  .m-xxl-2 {
    margin: 1rem;
  }
  .m-xxl-2-5 {
    margin: 1.25rem;
  }
  .m-xxl-3 {
    margin: 1.5rem;
  }
  .m-xxl-3-5 {
    margin: 1.75rem;
  }
  .m-xxl-4 {
    margin: 2rem;
  }
  .m-xxl-5 {
    margin: 2.5rem;
  }
  .m-xxl-5-5 {
    margin: 2.75rem;
  }
  .m-xxl-6 {
    margin: 3rem;
  }
  .m-xxl-7 {
    margin: 3.5rem;
  }
  .m-xxl-7-5 {
    margin: 3.75rem;
  }
  .m-xxl-8 {
    margin: 4rem;
  }
  .m-xxl-9 {
    margin: 4.5rem;
  }
  .m-xxl-9-5 {
    margin: 4.75rem;
  }
  .m-xxl-10 {
    margin: 5rem;
  }
  .m-xxl-auto {
    margin: auto;
  }
  .mx-xxl-0 {
    margin-right: 0;
    margin-left: 0;
  }
  .mx-xxl-0-5 {
    margin-right: 0.25rem;
    margin-left: 0.25rem;
  }
  .mx-xxl-1 {
    margin-right: 0.5rem;
    margin-left: 0.5rem;
  }
  .mx-xxl-1-5 {
    margin-right: 0.75rem;
    margin-left: 0.75rem;
  }
  .mx-xxl-2 {
    margin-right: 1rem;
    margin-left: 1rem;
  }
  .mx-xxl-2-5 {
    margin-right: 1.25rem;
    margin-left: 1.25rem;
  }
  .mx-xxl-3 {
    margin-right: 1.5rem;
    margin-left: 1.5rem;
  }
  .mx-xxl-3-5 {
    margin-right: 1.75rem;
    margin-left: 1.75rem;
  }
  .mx-xxl-4 {
    margin-right: 2rem;
    margin-left: 2rem;
  }
  .mx-xxl-5 {
    margin-right: 2.5rem;
    margin-left: 2.5rem;
  }
  .mx-xxl-5-5 {
    margin-right: 2.75rem;
    margin-left: 2.75rem;
  }
  .mx-xxl-6 {
    margin-right: 3rem;
    margin-left: 3rem;
  }
  .mx-xxl-7 {
    margin-right: 3.5rem;
    margin-left: 3.5rem;
  }
  .mx-xxl-7-5 {
    margin-right: 3.75rem;
    margin-left: 3.75rem;
  }
  .mx-xxl-8 {
    margin-right: 4rem;
    margin-left: 4rem;
  }
  .mx-xxl-9 {
    margin-right: 4.5rem;
    margin-left: 4.5rem;
  }
  .mx-xxl-9-5 {
    margin-right: 4.75rem;
    margin-left: 4.75rem;
  }
  .mx-xxl-10 {
    margin-right: 5rem;
    margin-left: 5rem;
  }
  .mx-xxl-auto {
    margin-right: auto;
    margin-left: auto;
  }
  .my-xxl-0 {
    margin-top: 0;
    margin-bottom: 0;
  }
  .my-xxl-0-5 {
    margin-top: 0.25rem;
    margin-bottom: 0.25rem;
  }
  .my-xxl-1 {
    margin-top: 0.5rem;
    margin-bottom: 0.5rem;
  }
  .my-xxl-1-5 {
    margin-top: 0.75rem;
    margin-bottom: 0.75rem;
  }
  .my-xxl-2 {
    margin-top: 1rem;
    margin-bottom: 1rem;
  }
  .my-xxl-2-5 {
    margin-top: 1.25rem;
    margin-bottom: 1.25rem;
  }
  .my-xxl-3 {
    margin-top: 1.5rem;
    margin-bottom: 1.5rem;
  }
  .my-xxl-3-5 {
    margin-top: 1.75rem;
    margin-bottom: 1.75rem;
  }
  .my-xxl-4 {
    margin-top: 2rem;
    margin-bottom: 2rem;
  }
  .my-xxl-5 {
    margin-top: 2.5rem;
    margin-bottom: 2.5rem;
  }
  .my-xxl-5-5 {
    margin-top: 2.75rem;
    margin-bottom: 2.75rem;
  }
  .my-xxl-6 {
    margin-top: 3rem;
    margin-bottom: 3rem;
  }
  .my-xxl-7 {
    margin-top: 3.5rem;
    margin-bottom: 3.5rem;
  }
  .my-xxl-7-5 {
    margin-top: 3.75rem;
    margin-bottom: 3.75rem;
  }
  .my-xxl-8 {
    margin-top: 4rem;
    margin-bottom: 4rem;
  }
  .my-xxl-9 {
    margin-top: 4.5rem;
    margin-bottom: 4.5rem;
  }
  .my-xxl-9-5 {
    margin-top: 4.75rem;
    margin-bottom: 4.75rem;
  }
  .my-xxl-10 {
    margin-top: 5rem;
    margin-bottom: 5rem;
  }
  .my-xxl-auto {
    margin-top: auto;
    margin-bottom: auto;
  }
  .mt-xxl-0 {
    margin-top: 0;
  }
  .mt-xxl-0-5 {
    margin-top: 0.25rem;
  }
  .mt-xxl-1 {
    margin-top: 0.5rem;
  }
  .mt-xxl-1-5 {
    margin-top: 0.75rem;
  }
  .mt-xxl-2 {
    margin-top: 1rem;
  }
  .mt-xxl-2-5 {
    margin-top: 1.25rem;
  }
  .mt-xxl-3 {
    margin-top: 1.5rem;
  }
  .mt-xxl-3-5 {
    margin-top: 1.75rem;
  }
  .mt-xxl-4 {
    margin-top: 2rem;
  }
  .mt-xxl-5 {
    margin-top: 2.5rem;
  }
  .mt-xxl-5-5 {
    margin-top: 2.75rem;
  }
  .mt-xxl-6 {
    margin-top: 3rem;
  }
  .mt-xxl-7 {
    margin-top: 3.5rem;
  }
  .mt-xxl-7-5 {
    margin-top: 3.75rem;
  }
  .mt-xxl-8 {
    margin-top: 4rem;
  }
  .mt-xxl-9 {
    margin-top: 4.5rem;
  }
  .mt-xxl-9-5 {
    margin-top: 4.75rem;
  }
  .mt-xxl-10 {
    margin-top: 5rem;
  }
  .mt-xxl-auto {
    margin-top: auto;
  }
  .me-xxl-0 {
    margin-right: 0;
  }
  .me-xxl-0-5 {
    margin-right: 0.25rem;
  }
  .me-xxl-1 {
    margin-right: 0.5rem;
  }
  .me-xxl-1-5 {
    margin-right: 0.75rem;
  }
  .me-xxl-2 {
    margin-right: 1rem;
  }
  .me-xxl-2-5 {
    margin-right: 1.25rem;
  }
  .me-xxl-3 {
    margin-right: 1.5rem;
  }
  .me-xxl-3-5 {
    margin-right: 1.75rem;
  }
  .me-xxl-4 {
    margin-right: 2rem;
  }
  .me-xxl-5 {
    margin-right: 2.5rem;
  }
  .me-xxl-5-5 {
    margin-right: 2.75rem;
  }
  .me-xxl-6 {
    margin-right: 3rem;
  }
  .me-xxl-7 {
    margin-right: 3.5rem;
  }
  .me-xxl-7-5 {
    margin-right: 3.75rem;
  }
  .me-xxl-8 {
    margin-right: 4rem;
  }
  .me-xxl-9 {
    margin-right: 4.5rem;
  }
  .me-xxl-9-5 {
    margin-right: 4.75rem;
  }
  .me-xxl-10 {
    margin-right: 5rem;
  }
  .me-xxl-auto {
    margin-right: auto;
  }
  .mb-xxl-0 {
    margin-bottom: 0;
  }
  .mb-xxl-0-5 {
    margin-bottom: 0.25rem;
  }
  .mb-xxl-1 {
    margin-bottom: 0.5rem;
  }
  .mb-xxl-1-5 {
    margin-bottom: 0.75rem;
  }
  .mb-xxl-2 {
    margin-bottom: 1rem;
  }
  .mb-xxl-2-5 {
    margin-bottom: 1.25rem;
  }
  .mb-xxl-3 {
    margin-bottom: 1.5rem;
  }
  .mb-xxl-3-5 {
    margin-bottom: 1.75rem;
  }
  .mb-xxl-4 {
    margin-bottom: 2rem;
  }
  .mb-xxl-5 {
    margin-bottom: 2.5rem;
  }
  .mb-xxl-5-5 {
    margin-bottom: 2.75rem;
  }
  .mb-xxl-6 {
    margin-bottom: 3rem;
  }
  .mb-xxl-7 {
    margin-bottom: 3.5rem;
  }
  .mb-xxl-7-5 {
    margin-bottom: 3.75rem;
  }
  .mb-xxl-8 {
    margin-bottom: 4rem;
  }
  .mb-xxl-9 {
    margin-bottom: 4.5rem;
  }
  .mb-xxl-9-5 {
    margin-bottom: 4.75rem;
  }
  .mb-xxl-10 {
    margin-bottom: 5rem;
  }
  .mb-xxl-auto {
    margin-bottom: auto;
  }
  .ms-xxl-0 {
    margin-left: 0;
  }
  .ms-xxl-0-5 {
    margin-left: 0.25rem;
  }
  .ms-xxl-1 {
    margin-left: 0.5rem;
  }
  .ms-xxl-1-5 {
    margin-left: 0.75rem;
  }
  .ms-xxl-2 {
    margin-left: 1rem;
  }
  .ms-xxl-2-5 {
    margin-left: 1.25rem;
  }
  .ms-xxl-3 {
    margin-left: 1.5rem;
  }
  .ms-xxl-3-5 {
    margin-left: 1.75rem;
  }
  .ms-xxl-4 {
    margin-left: 2rem;
  }
  .ms-xxl-5 {
    margin-left: 2.5rem;
  }
  .ms-xxl-5-5 {
    margin-left: 2.75rem;
  }
  .ms-xxl-6 {
    margin-left: 3rem;
  }
  .ms-xxl-7 {
    margin-left: 3.5rem;
  }
  .ms-xxl-7-5 {
    margin-left: 3.75rem;
  }
  .ms-xxl-8 {
    margin-left: 4rem;
  }
  .ms-xxl-9 {
    margin-left: 4.5rem;
  }
  .ms-xxl-9-5 {
    margin-left: 4.75rem;
  }
  .ms-xxl-10 {
    margin-left: 5rem;
  }
  .ms-xxl-auto {
    margin-left: auto;
  }
  .m-xxl-n0 {
    margin: 0;
  }
  .m-xxl-n0-5 {
    margin: -0.25rem;
  }
  .m-xxl-n1 {
    margin: -0.5rem;
  }
  .m-xxl-n1-5 {
    margin: -0.75rem;
  }
  .m-xxl-n2 {
    margin: -1rem;
  }
  .m-xxl-n2-5 {
    margin: -1.25rem;
  }
  .m-xxl-n3 {
    margin: -1.5rem;
  }
  .m-xxl-n3-5 {
    margin: -1.75rem;
  }
  .m-xxl-n4 {
    margin: -2rem;
  }
  .m-xxl-n5 {
    margin: -2.5rem;
  }
  .m-xxl-n5-5 {
    margin: -2.75rem;
  }
  .m-xxl-n6 {
    margin: -3rem;
  }
  .m-xxl-n7 {
    margin: -3.5rem;
  }
  .m-xxl-n7-5 {
    margin: -3.75rem;
  }
  .m-xxl-n8 {
    margin: -4rem;
  }
  .m-xxl-n9 {
    margin: -4.5rem;
  }
  .m-xxl-n9-5 {
    margin: -4.75rem;
  }
  .m-xxl-n10 {
    margin: -5rem;
  }
  .m-xxl-nauto {
    margin: -"auto";
  }
  .mx-xxl-n0 {
    margin-right: 0;
    margin-left: 0;
  }
  .mx-xxl-n0-5 {
    margin-right: -0.25rem;
    margin-left: -0.25rem;
  }
  .mx-xxl-n1 {
    margin-right: -0.5rem;
    margin-left: -0.5rem;
  }
  .mx-xxl-n1-5 {
    margin-right: -0.75rem;
    margin-left: -0.75rem;
  }
  .mx-xxl-n2 {
    margin-right: -1rem;
    margin-left: -1rem;
  }
  .mx-xxl-n2-5 {
    margin-right: -1.25rem;
    margin-left: -1.25rem;
  }
  .mx-xxl-n3 {
    margin-right: -1.5rem;
    margin-left: -1.5rem;
  }
  .mx-xxl-n3-5 {
    margin-right: -1.75rem;
    margin-left: -1.75rem;
  }
  .mx-xxl-n4 {
    margin-right: -2rem;
    margin-left: -2rem;
  }
  .mx-xxl-n5 {
    margin-right: -2.5rem;
    margin-left: -2.5rem;
  }
  .mx-xxl-n5-5 {
    margin-right: -2.75rem;
    margin-left: -2.75rem;
  }
  .mx-xxl-n6 {
    margin-right: -3rem;
    margin-left: -3rem;
  }
  .mx-xxl-n7 {
    margin-right: -3.5rem;
    margin-left: -3.5rem;
  }
  .mx-xxl-n7-5 {
    margin-right: -3.75rem;
    margin-left: -3.75rem;
  }
  .mx-xxl-n8 {
    margin-right: -4rem;
    margin-left: -4rem;
  }
  .mx-xxl-n9 {
    margin-right: -4.5rem;
    margin-left: -4.5rem;
  }
  .mx-xxl-n9-5 {
    margin-right: -4.75rem;
    margin-left: -4.75rem;
  }
  .mx-xxl-n10 {
    margin-right: -5rem;
    margin-left: -5rem;
  }
  .mx-xxl-nauto {
    margin-right: -"auto";
    margin-left: -"auto";
  }
  .my-xxl-n0 {
    margin-top: 0;
    margin-bottom: 0;
  }
  .my-xxl-n0-5 {
    margin-top: -0.25rem;
    margin-bottom: -0.25rem;
  }
  .my-xxl-n1 {
    margin-top: -0.5rem;
    margin-bottom: -0.5rem;
  }
  .my-xxl-n1-5 {
    margin-top: -0.75rem;
    margin-bottom: -0.75rem;
  }
  .my-xxl-n2 {
    margin-top: -1rem;
    margin-bottom: -1rem;
  }
  .my-xxl-n2-5 {
    margin-top: -1.25rem;
    margin-bottom: -1.25rem;
  }
  .my-xxl-n3 {
    margin-top: -1.5rem;
    margin-bottom: -1.5rem;
  }
  .my-xxl-n3-5 {
    margin-top: -1.75rem;
    margin-bottom: -1.75rem;
  }
  .my-xxl-n4 {
    margin-top: -2rem;
    margin-bottom: -2rem;
  }
  .my-xxl-n5 {
    margin-top: -2.5rem;
    margin-bottom: -2.5rem;
  }
  .my-xxl-n5-5 {
    margin-top: -2.75rem;
    margin-bottom: -2.75rem;
  }
  .my-xxl-n6 {
    margin-top: -3rem;
    margin-bottom: -3rem;
  }
  .my-xxl-n7 {
    margin-top: -3.5rem;
    margin-bottom: -3.5rem;
  }
  .my-xxl-n7-5 {
    margin-top: -3.75rem;
    margin-bottom: -3.75rem;
  }
  .my-xxl-n8 {
    margin-top: -4rem;
    margin-bottom: -4rem;
  }
  .my-xxl-n9 {
    margin-top: -4.5rem;
    margin-bottom: -4.5rem;
  }
  .my-xxl-n9-5 {
    margin-top: -4.75rem;
    margin-bottom: -4.75rem;
  }
  .my-xxl-n10 {
    margin-top: -5rem;
    margin-bottom: -5rem;
  }
  .my-xxl-nauto {
    margin-top: -"auto";
    margin-bottom: -"auto";
  }
  .mt-xxl-n0 {
    margin-top: 0;
  }
  .mt-xxl-n0-5 {
    margin-top: -0.25rem;
  }
  .mt-xxl-n1 {
    margin-top: -0.5rem;
  }
  .mt-xxl-n1-5 {
    margin-top: -0.75rem;
  }
  .mt-xxl-n2 {
    margin-top: -1rem;
  }
  .mt-xxl-n2-5 {
    margin-top: -1.25rem;
  }
  .mt-xxl-n3 {
    margin-top: -1.5rem;
  }
  .mt-xxl-n3-5 {
    margin-top: -1.75rem;
  }
  .mt-xxl-n4 {
    margin-top: -2rem;
  }
  .mt-xxl-n5 {
    margin-top: -2.5rem;
  }
  .mt-xxl-n5-5 {
    margin-top: -2.75rem;
  }
  .mt-xxl-n6 {
    margin-top: -3rem;
  }
  .mt-xxl-n7 {
    margin-top: -3.5rem;
  }
  .mt-xxl-n7-5 {
    margin-top: -3.75rem;
  }
  .mt-xxl-n8 {
    margin-top: -4rem;
  }
  .mt-xxl-n9 {
    margin-top: -4.5rem;
  }
  .mt-xxl-n9-5 {
    margin-top: -4.75rem;
  }
  .mt-xxl-n10 {
    margin-top: -5rem;
  }
  .mt-xxl-nauto {
    margin-top: -"auto";
  }
  .me-xxl-n0 {
    margin-right: 0;
  }
  .me-xxl-n0-5 {
    margin-right: -0.25rem;
  }
  .me-xxl-n1 {
    margin-right: -0.5rem;
  }
  .me-xxl-n1-5 {
    margin-right: -0.75rem;
  }
  .me-xxl-n2 {
    margin-right: -1rem;
  }
  .me-xxl-n2-5 {
    margin-right: -1.25rem;
  }
  .me-xxl-n3 {
    margin-right: -1.5rem;
  }
  .me-xxl-n3-5 {
    margin-right: -1.75rem;
  }
  .me-xxl-n4 {
    margin-right: -2rem;
  }
  .me-xxl-n5 {
    margin-right: -2.5rem;
  }
  .me-xxl-n5-5 {
    margin-right: -2.75rem;
  }
  .me-xxl-n6 {
    margin-right: -3rem;
  }
  .me-xxl-n7 {
    margin-right: -3.5rem;
  }
  .me-xxl-n7-5 {
    margin-right: -3.75rem;
  }
  .me-xxl-n8 {
    margin-right: -4rem;
  }
  .me-xxl-n9 {
    margin-right: -4.5rem;
  }
  .me-xxl-n9-5 {
    margin-right: -4.75rem;
  }
  .me-xxl-n10 {
    margin-right: -5rem;
  }
  .me-xxl-nauto {
    margin-right: -"auto";
  }
  .mb-xxl-n0 {
    margin-bottom: 0;
  }
  .mb-xxl-n0-5 {
    margin-bottom: -0.25rem;
  }
  .mb-xxl-n1 {
    margin-bottom: -0.5rem;
  }
  .mb-xxl-n1-5 {
    margin-bottom: -0.75rem;
  }
  .mb-xxl-n2 {
    margin-bottom: -1rem;
  }
  .mb-xxl-n2-5 {
    margin-bottom: -1.25rem;
  }
  .mb-xxl-n3 {
    margin-bottom: -1.5rem;
  }
  .mb-xxl-n3-5 {
    margin-bottom: -1.75rem;
  }
  .mb-xxl-n4 {
    margin-bottom: -2rem;
  }
  .mb-xxl-n5 {
    margin-bottom: -2.5rem;
  }
  .mb-xxl-n5-5 {
    margin-bottom: -2.75rem;
  }
  .mb-xxl-n6 {
    margin-bottom: -3rem;
  }
  .mb-xxl-n7 {
    margin-bottom: -3.5rem;
  }
  .mb-xxl-n7-5 {
    margin-bottom: -3.75rem;
  }
  .mb-xxl-n8 {
    margin-bottom: -4rem;
  }
  .mb-xxl-n9 {
    margin-bottom: -4.5rem;
  }
  .mb-xxl-n9-5 {
    margin-bottom: -4.75rem;
  }
  .mb-xxl-n10 {
    margin-bottom: -5rem;
  }
  .mb-xxl-nauto {
    margin-bottom: -"auto";
  }
  .ms-xxl-n0 {
    margin-left: 0;
  }
  .ms-xxl-n0-5 {
    margin-left: -0.25rem;
  }
  .ms-xxl-n1 {
    margin-left: -0.5rem;
  }
  .ms-xxl-n1-5 {
    margin-left: -0.75rem;
  }
  .ms-xxl-n2 {
    margin-left: -1rem;
  }
  .ms-xxl-n2-5 {
    margin-left: -1.25rem;
  }
  .ms-xxl-n3 {
    margin-left: -1.5rem;
  }
  .ms-xxl-n3-5 {
    margin-left: -1.75rem;
  }
  .ms-xxl-n4 {
    margin-left: -2rem;
  }
  .ms-xxl-n5 {
    margin-left: -2.5rem;
  }
  .ms-xxl-n5-5 {
    margin-left: -2.75rem;
  }
  .ms-xxl-n6 {
    margin-left: -3rem;
  }
  .ms-xxl-n7 {
    margin-left: -3.5rem;
  }
  .ms-xxl-n7-5 {
    margin-left: -3.75rem;
  }
  .ms-xxl-n8 {
    margin-left: -4rem;
  }
  .ms-xxl-n9 {
    margin-left: -4.5rem;
  }
  .ms-xxl-n9-5 {
    margin-left: -4.75rem;
  }
  .ms-xxl-n10 {
    margin-left: -5rem;
  }
  .ms-xxl-nauto {
    margin-left: -"auto";
  }
  .p-xxl-0 {
    padding: 0;
  }
  .p-xxl-0-5 {
    padding: 0.25rem;
  }
  .p-xxl-1 {
    padding: 0.5rem;
  }
  .p-xxl-1-5 {
    padding: 0.75rem;
  }
  .p-xxl-2 {
    padding: 1rem;
  }
  .p-xxl-2-5 {
    padding: 1.25rem;
  }
  .p-xxl-3 {
    padding: 1.5rem;
  }
  .p-xxl-3-5 {
    padding: 1.75rem;
  }
  .p-xxl-4 {
    padding: 2rem;
  }
  .p-xxl-5 {
    padding: 2.5rem;
  }
  .p-xxl-5-5 {
    padding: 2.75rem;
  }
  .p-xxl-6 {
    padding: 3rem;
  }
  .p-xxl-7 {
    padding: 3.5rem;
  }
  .p-xxl-7-5 {
    padding: 3.75rem;
  }
  .p-xxl-8 {
    padding: 4rem;
  }
  .p-xxl-9 {
    padding: 4.5rem;
  }
  .p-xxl-9-5 {
    padding: 4.75rem;
  }
  .p-xxl-10 {
    padding: 5rem;
  }
  .p-xxl-auto {
    padding: "auto";
  }
  .px-xxl-0 {
    padding-right: 0;
    padding-left: 0;
  }
  .px-xxl-0-5 {
    padding-right: 0.25rem;
    padding-left: 0.25rem;
  }
  .px-xxl-1 {
    padding-right: 0.5rem;
    padding-left: 0.5rem;
  }
  .px-xxl-1-5 {
    padding-right: 0.75rem;
    padding-left: 0.75rem;
  }
  .px-xxl-2 {
    padding-right: 1rem;
    padding-left: 1rem;
  }
  .px-xxl-2-5 {
    padding-right: 1.25rem;
    padding-left: 1.25rem;
  }
  .px-xxl-3 {
    padding-right: 1.5rem;
    padding-left: 1.5rem;
  }
  .px-xxl-3-5 {
    padding-right: 1.75rem;
    padding-left: 1.75rem;
  }
  .px-xxl-4 {
    padding-right: 2rem;
    padding-left: 2rem;
  }
  .px-xxl-5 {
    padding-right: 2.5rem;
    padding-left: 2.5rem;
  }
  .px-xxl-5-5 {
    padding-right: 2.75rem;
    padding-left: 2.75rem;
  }
  .px-xxl-6 {
    padding-right: 3rem;
    padding-left: 3rem;
  }
  .px-xxl-7 {
    padding-right: 3.5rem;
    padding-left: 3.5rem;
  }
  .px-xxl-7-5 {
    padding-right: 3.75rem;
    padding-left: 3.75rem;
  }
  .px-xxl-8 {
    padding-right: 4rem;
    padding-left: 4rem;
  }
  .px-xxl-9 {
    padding-right: 4.5rem;
    padding-left: 4.5rem;
  }
  .px-xxl-9-5 {
    padding-right: 4.75rem;
    padding-left: 4.75rem;
  }
  .px-xxl-10 {
    padding-right: 5rem;
    padding-left: 5rem;
  }
  .px-xxl-auto {
    padding-right: "auto";
    padding-left: "auto";
  }
  .py-xxl-0 {
    padding-top: 0;
    padding-bottom: 0;
  }
  .py-xxl-0-5 {
    padding-top: 0.25rem;
    padding-bottom: 0.25rem;
  }
  .py-xxl-1 {
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
  }
  .py-xxl-1-5 {
    padding-top: 0.75rem;
    padding-bottom: 0.75rem;
  }
  .py-xxl-2 {
    padding-top: 1rem;
    padding-bottom: 1rem;
  }
  .py-xxl-2-5 {
    padding-top: 1.25rem;
    padding-bottom: 1.25rem;
  }
  .py-xxl-3 {
    padding-top: 1.5rem;
    padding-bottom: 1.5rem;
  }
  .py-xxl-3-5 {
    padding-top: 1.75rem;
    padding-bottom: 1.75rem;
  }
  .py-xxl-4 {
    padding-top: 2rem;
    padding-bottom: 2rem;
  }
  .py-xxl-5 {
    padding-top: 2.5rem;
    padding-bottom: 2.5rem;
  }
  .py-xxl-5-5 {
    padding-top: 2.75rem;
    padding-bottom: 2.75rem;
  }
  .py-xxl-6 {
    padding-top: 3rem;
    padding-bottom: 3rem;
  }
  .py-xxl-7 {
    padding-top: 3.5rem;
    padding-bottom: 3.5rem;
  }
  .py-xxl-7-5 {
    padding-top: 3.75rem;
    padding-bottom: 3.75rem;
  }
  .py-xxl-8 {
    padding-top: 4rem;
    padding-bottom: 4rem;
  }
  .py-xxl-9 {
    padding-top: 4.5rem;
    padding-bottom: 4.5rem;
  }
  .py-xxl-9-5 {
    padding-top: 4.75rem;
    padding-bottom: 4.75rem;
  }
  .py-xxl-10 {
    padding-top: 5rem;
    padding-bottom: 5rem;
  }
  .py-xxl-auto {
    padding-top: "auto";
    padding-bottom: "auto";
  }
  .pt-xxl-0 {
    padding-top: 0;
  }
  .pt-xxl-0-5 {
    padding-top: 0.25rem;
  }
  .pt-xxl-1 {
    padding-top: 0.5rem;
  }
  .pt-xxl-1-5 {
    padding-top: 0.75rem;
  }
  .pt-xxl-2 {
    padding-top: 1rem;
  }
  .pt-xxl-2-5 {
    padding-top: 1.25rem;
  }
  .pt-xxl-3 {
    padding-top: 1.5rem;
  }
  .pt-xxl-3-5 {
    padding-top: 1.75rem;
  }
  .pt-xxl-4 {
    padding-top: 2rem;
  }
  .pt-xxl-5 {
    padding-top: 2.5rem;
  }
  .pt-xxl-5-5 {
    padding-top: 2.75rem;
  }
  .pt-xxl-6 {
    padding-top: 3rem;
  }
  .pt-xxl-7 {
    padding-top: 3.5rem;
  }
  .pt-xxl-7-5 {
    padding-top: 3.75rem;
  }
  .pt-xxl-8 {
    padding-top: 4rem;
  }
  .pt-xxl-9 {
    padding-top: 4.5rem;
  }
  .pt-xxl-9-5 {
    padding-top: 4.75rem;
  }
  .pt-xxl-10 {
    padding-top: 5rem;
  }
  .pt-xxl-auto {
    padding-top: "auto";
  }
  .pe-xxl-0 {
    padding-right: 0;
  }
  .pe-xxl-0-5 {
    padding-right: 0.25rem;
  }
  .pe-xxl-1 {
    padding-right: 0.5rem;
  }
  .pe-xxl-1-5 {
    padding-right: 0.75rem;
  }
  .pe-xxl-2 {
    padding-right: 1rem;
  }
  .pe-xxl-2-5 {
    padding-right: 1.25rem;
  }
  .pe-xxl-3 {
    padding-right: 1.5rem;
  }
  .pe-xxl-3-5 {
    padding-right: 1.75rem;
  }
  .pe-xxl-4 {
    padding-right: 2rem;
  }
  .pe-xxl-5 {
    padding-right: 2.5rem;
  }
  .pe-xxl-5-5 {
    padding-right: 2.75rem;
  }
  .pe-xxl-6 {
    padding-right: 3rem;
  }
  .pe-xxl-7 {
    padding-right: 3.5rem;
  }
  .pe-xxl-7-5 {
    padding-right: 3.75rem;
  }
  .pe-xxl-8 {
    padding-right: 4rem;
  }
  .pe-xxl-9 {
    padding-right: 4.5rem;
  }
  .pe-xxl-9-5 {
    padding-right: 4.75rem;
  }
  .pe-xxl-10 {
    padding-right: 5rem;
  }
  .pe-xxl-auto {
    padding-right: "auto";
  }
  .pb-xxl-0 {
    padding-bottom: 0;
  }
  .pb-xxl-0-5 {
    padding-bottom: 0.25rem;
  }
  .pb-xxl-1 {
    padding-bottom: 0.5rem;
  }
  .pb-xxl-1-5 {
    padding-bottom: 0.75rem;
  }
  .pb-xxl-2 {
    padding-bottom: 1rem;
  }
  .pb-xxl-2-5 {
    padding-bottom: 1.25rem;
  }
  .pb-xxl-3 {
    padding-bottom: 1.5rem;
  }
  .pb-xxl-3-5 {
    padding-bottom: 1.75rem;
  }
  .pb-xxl-4 {
    padding-bottom: 2rem;
  }
  .pb-xxl-5 {
    padding-bottom: 2.5rem;
  }
  .pb-xxl-5-5 {
    padding-bottom: 2.75rem;
  }
  .pb-xxl-6 {
    padding-bottom: 3rem;
  }
  .pb-xxl-7 {
    padding-bottom: 3.5rem;
  }
  .pb-xxl-7-5 {
    padding-bottom: 3.75rem;
  }
  .pb-xxl-8 {
    padding-bottom: 4rem;
  }
  .pb-xxl-9 {
    padding-bottom: 4.5rem;
  }
  .pb-xxl-9-5 {
    padding-bottom: 4.75rem;
  }
  .pb-xxl-10 {
    padding-bottom: 5rem;
  }
  .pb-xxl-auto {
    padding-bottom: "auto";
  }
  .ps-xxl-0 {
    padding-left: 0;
  }
  .ps-xxl-0-5 {
    padding-left: 0.25rem;
  }
  .ps-xxl-1 {
    padding-left: 0.5rem;
  }
  .ps-xxl-1-5 {
    padding-left: 0.75rem;
  }
  .ps-xxl-2 {
    padding-left: 1rem;
  }
  .ps-xxl-2-5 {
    padding-left: 1.25rem;
  }
  .ps-xxl-3 {
    padding-left: 1.5rem;
  }
  .ps-xxl-3-5 {
    padding-left: 1.75rem;
  }
  .ps-xxl-4 {
    padding-left: 2rem;
  }
  .ps-xxl-5 {
    padding-left: 2.5rem;
  }
  .ps-xxl-5-5 {
    padding-left: 2.75rem;
  }
  .ps-xxl-6 {
    padding-left: 3rem;
  }
  .ps-xxl-7 {
    padding-left: 3.5rem;
  }
  .ps-xxl-7-5 {
    padding-left: 3.75rem;
  }
  .ps-xxl-8 {
    padding-left: 4rem;
  }
  .ps-xxl-9 {
    padding-left: 4.5rem;
  }
  .ps-xxl-9-5 {
    padding-left: 4.75rem;
  }
  .ps-xxl-10 {
    padding-left: 5rem;
  }
  .ps-xxl-auto {
    padding-left: "auto";
  }
  .gap-xxl-0 {
    gap: 0;
  }
  .gap-xxl-0-5 {
    gap: 0.25rem;
  }
  .gap-xxl-1 {
    gap: 0.5rem;
  }
  .gap-xxl-1-5 {
    gap: 0.75rem;
  }
  .gap-xxl-2 {
    gap: 1rem;
  }
  .gap-xxl-2-5 {
    gap: 1.25rem;
  }
  .gap-xxl-3 {
    gap: 1.5rem;
  }
  .gap-xxl-3-5 {
    gap: 1.75rem;
  }
  .gap-xxl-4 {
    gap: 2rem;
  }
  .gap-xxl-5 {
    gap: 2.5rem;
  }
  .gap-xxl-5-5 {
    gap: 2.75rem;
  }
  .gap-xxl-6 {
    gap: 3rem;
  }
  .gap-xxl-7 {
    gap: 3.5rem;
  }
  .gap-xxl-7-5 {
    gap: 3.75rem;
  }
  .gap-xxl-8 {
    gap: 4rem;
  }
  .gap-xxl-9 {
    gap: 4.5rem;
  }
  .gap-xxl-9-5 {
    gap: 4.75rem;
  }
  .gap-xxl-10 {
    gap: 5rem;
  }
  .gap-xxl-auto {
    gap: "auto";
  }
  .text-xxl-start {
    text-align: left;
  }
  .text-xxl-end {
    text-align: right;
  }
  .text-xxl-center {
    text-align: center;
  }
  .rounded-xxl {
    border-radius: var(--bs-border-radius);
  }
  .rounded-xxl-0 {
    border-radius: 0;
  }
  .rounded-xxl-1 {
    border-radius: var(--bs-border-radius-sm);
  }
  .rounded-xxl-2 {
    border-radius: var(--bs-border-radius);
  }
  .rounded-xxl-3 {
    border-radius: var(--bs-border-radius-lg);
  }
  .rounded-xxl-4 {
    border-radius: var(--bs-border-radius-xl);
  }
  .rounded-xxl-5 {
    border-radius: var(--bs-border-radius-2xl);
  }
  .rounded-xxl-circle {
    border-radius: 50%;
  }
  .rounded-xxl-pill {
    border-radius: var(--bs-border-radius-pill);
  }
}
@media (min-width: 1200px) {
  .fs-1 {
    font-size: 2.5rem;
  }
  .fs-2 {
    font-size: 2rem;
  }
  .fs-3 {
    font-size: 1.75rem;
  }
  .fs-4 {
    font-size: 1.5rem;
  }
}
@media print {
  .d-print-inline {
    display: inline;
  }
  .d-print-inline-block {
    display: inline-block;
  }
  .d-print-block {
    display: block;
  }
  .d-print-grid {
    display: grid;
  }
  .d-print-table {
    display: table;
  }
  .d-print-table-row {
    display: table-row;
  }
  .d-print-table-cell {
    display: table-cell;
  }
  .d-print-flex {
    display: flex;
  }
  .d-print-inline-flex {
    display: inline-flex;
  }
  .d-print-none {
    display: none;
  }
}