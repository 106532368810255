@use 'abstracts/shared';

.btn-nav {
  border-width: 1px;
  background-color: shared.color('bg100');
  border-color: shared.color('primary500');
  color: shared.color('primary500');

  &.btn-text {
    padding: 11px 16px;

    @include shared.media-breakpoint-up(md) {
      padding: 11px 24px;
    }
  }

  &:hover {
    background-color: shared.color('primary200');
    border-color: transparent;
    color: shared.color('primary600');
  }

  &.opened,
  &:focus-visible {
    background-color: shared.color('primary100');
    border-color: transparent;
    color: shared.color('primary500');
  }

  &:active {
    background-color: shared.color('primary300');
    border-color: transparent;
    color: shared.color('primary700');
  }

  &:disabled,
  &.btn-disabled {
    background-color: shared.color('text200');
    border-color: transparent;
    color: shared.color('text300');
    filter: grayscale(0.5);
  }
}
