.btn-text {
  &.btn-extra-small {
    padding: $button-xs-padding-y $button-xs-padding-x;
  }

  &.btn-small {
    padding: $button-md-padding-y $button-md-padding-x;
  }

  &.btn-normal {
    padding: $button-padding-y $button-padding-x;
  }

  &.btn-large {
    padding: $button-lg-padding-y $button-lg-padding-x;
  }
}
