/* stylelint-disable plugin/no-unsupported-browser-features */
@use 'abstracts/shared';

.form-label {
  color: shared.$input-color;
  background-color: shared.$input-bg-color;
  position: absolute;
  margin-top: -0.45rem;
  margin-left: shared.spacing(3);
  margin-right: shared.spacing(3);
  padding: 0 0.2rem;
  display: block;
  max-width: calc(100% - 2 * shared.spacing(3));
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  cursor: pointer;
  z-index: 1;

  .disabled & {
    color: shared.$input-placeholder-color;
    cursor: not-allowed;
  }
}

.form-input,
.hs-input {
  @include shared.form-input;
}

// TODO: Find a better fix for iOS.
.form-input[type='date'] {
  @include shared.form-input;

  display: flex;
  appearance: none;
  min-height: 56px;
  min-width: 100%;
}

.no-list {
  list-style: none;
  padding: 0;
  margin: 0;
  font-size: 0.75rem;
  font-weight: 400;
  line-height: 1rem;
}

.hs-error-msg {
  display: block;
  padding: 0 shared.spacing(3);
  font-weight: shared.$font-weight-regular;
  margin-top: 0.25rem;
  color: shared.color('error600');
}

.form-hint {
  display: block;
  color: shared.$input-hint-color;
  padding: 0 shared.spacing(3);
  font-weight: shared.$font-weight-regular;
  margin-top: 0.25rem;

  .success & {
    color: shared.color('success600');
  }

  .warning & {
    color: shared.color('warning600');
  }

  .error & {
    color: shared.color('error600');
  }
}

.input-suffix {
  right: shared.$input-padding-x;
}

.input-prefix {
  left: shared.$input-padding-x;
}

.input-prefix,
.input-suffix {
  pointer-events: none;
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.5rem;
  color: shared.$input-placeholder-color;

  .input-sm & {
    top: shared.$input-sm-padding-y + shared.$input-border-width;
  }

  .input-md & {
    top: shared.$input-padding-y + shared.$input-border-width;
  }

  .input-lg & {
    top: shared.$input-lg-padding-y + shared.$input-border-width;
  }

  .disabled & {
    color: shared.$input-border-color-disabled;
  }
}

.input-suffix-button {
  pointer-events: auto;
  background: 0;
  color: inherit;
  border: 0;
  width: shared.$input-prefix-size;
  height: shared.$input-prefix-size;
  padding: 0;
  display: flex;
  justify-content: center;
  align-items: center;

  &:hover {
    color: shared.$input-color;
  }

  .disabled & {
    cursor: not-allowed;

    &:hover {
      color: inherit;
    }
  }
}

::-webkit-calendar-picker-indicator,
::-webkit-search-cancel-button {
  display: none;
}
