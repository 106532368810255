@use 'abstracts/shared';

.btn-fab {
  height: 72px;
  width: 72px;
  border-width: 1.5px;
  background-color: shared.color('bg100');
  border-color: shared.color('primary500');
  color: shared.color('primary500');
  box-shadow: 0 13px 21px -13px rgb(23 32 82 / 60%);

  &:hover {
    background-color: shared.color('primary100');
    border-color: shared.color('primary500');
    color: shared.color('primary500');
  }

  &:focus-visible {
    background-color: shared.color('bg100');
    border-color: shared.color('primary700');
    color: shared.color('primary500');
    border-width: 2px;
  }

  &:active {
    background-color: shared.color('primary500');
    border-color: transparent;
    color: shared.color('text100');
  }

  &:disabled,
  &.btn-disabled {
    background-color: shared.color('text200');
    border-color: transparent;
    color: shared.color('text300');
  }
}
