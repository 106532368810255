@use 'abstracts/shared';
@use 'sass:map';

// Generate responsive versions for width, such as `w-sm-50`.
// @see https://getbootstrap.com/docs/5.2/utilities/api/#enable-responsive
$utilities: map-merge-multiple(
  $utilities,
  (
    'width':
      map.merge(
        map.get($utilities, 'width'),
        (
          responsive: true
        )
      ),
    'rounded':
      map.merge(
        map.get($utilities, 'rounded'),
        (
          responsive: true
        )
      )
  ),
  (
    'cursor': (
      property: cursor,
      class: cursor,
      values: auto pointer
    )
  ),
  (
    'pointer-events': (
      property: pointer-events,
      class: pointer-events,
      values: auto none
    )
  )
);

.mt-header {
  @include shared.media-breakpoint-down(md) {
    margin-top: shared.$header-height-mobile + 2px;
  }
}

.flex-1 {
  flex: 1;
}

.flex-1-5 {
  flex: 1.5;
}

.flex-2 {
  flex: 2;
}

.flex-3 {
  flex: 3;
}

.flex-4 {
  flex: 4;
}

.flex-5 {
  flex: 5;
}

.flex-6 {
  flex: 6;
}

.flex-7 {
  flex: 7;
}

.pt-header {
  padding-top: shared.$header-height-mobile;

  @include shared.media-breakpoint-up(md) {
    padding-top: shared.$header-height;
  }
}

.whitespace-nowrap {
  white-space: nowrap;
}

.whitespace-prewrap {
  white-space: pre-wrap;
}

.whitespace-preline {
  white-space: pre-line;
}
