@use 'abstracts/shared';

.shimmer {
  animation: shimmer 2s infinite;
  background: linear-gradient(
    90deg,
    #{shared.color('bg500')} 0%,
    #{shared.color('bg600')} 20%,
    #{shared.color('bg500')} 40%,
    #{shared.color('bg500')} 100%
  );
  background-size: 1000px 100%;
}

@keyframes shimmer {
  0% {
    background-position: -1000px 0;
  }

  100% {
    background-position: 1000px 0;
  }
}
