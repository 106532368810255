@use 'abstracts/variables';

// TODO: Remove font faces which are not used! The browser will not download it, but it is unnecessary in the CSS.
@font-face {
  font-family: 'Poppins';
  src:
    local('Poppins Thin'),
    local('Poppins-Thin'),
    url('/assets/fonts/Poppins-Thin.woff2') format('woff2');
  font-weight: variables.$font-weight-thin;
  font-display: swap;
}

@font-face {
  font-family: 'Poppins';
  src:
    local('Poppins ExtraLight'),
    local('Poppins-ExtraLight'),
    url('/assets/fonts/Poppins-ExtraLight.woff2') format('woff2');
  font-weight: variables.$font-weight-extralight;
  font-display: swap;
}
@font-face {
  font-family: 'Poppins';
  src:
    local('Poppins Light'),
    local('Poppins-Light'),
    url('/assets/fonts/Poppins-Light.woff2') format('woff2');
  font-weight: variables.$font-weight-light;
  font-display: swap;
}
@font-face {
  font-family: 'Poppins';
  src:
    local('Poppins Regular'),
    local('Poppins-Regular'),
    url('/assets/fonts/Poppins-Regular.woff2') format('woff2');
  font-weight: variables.$font-weight-regular;
  font-display: swap;
}
@font-face {
  font-family: 'Poppins';
  src:
    local('Poppins Medium'),
    local('Poppins-Medium'),
    url('/assets/fonts/Poppins-Medium.woff2') format('woff2');
  font-weight: variables.$font-weight-medium;
  font-display: swap;
}

@font-face {
  font-family: 'Poppins';
  src:
    local('Poppins Bold'),
    local('Poppins-Bold'),
    url('/assets/fonts/Poppins-Bold.woff2') format('woff2');
  font-weight: variables.$font-weight-bold;
  font-display: swap;
}

@font-face {
  font-family: 'Poppins';
  src:
    local('Poppins ExtraBold'),
    local('Poppins-ExtraBold'),
    url('/assets/fonts/Poppins-ExtraBold.woff2') format('woff2');
  font-weight: variables.$font-weight-extrabold;
  font-display: swap;
}

@font-face {
  font-family: 'Poppins';
  src:
    local('Poppins Black'),
    local('Poppins-Black'),
    url('/assets/fonts/Poppins-Black.woff2') format('woff2');
  font-weight: variables.$font-weight-black;
  font-display: swap;
}

// Italic
@font-face {
  font-family: 'Poppins';
  src:
    local('Poppins Thin Italic'),
    local('Poppins-ThinItalic'),
    url('/assets/fonts/Poppins-ThinItalic.woff2') format('woff2');
  font-weight: variables.$font-weight-thin;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Poppins';
  src:
    local('Poppins ExtraLight Italic'),
    local('Poppins-ExtraLightItalic'),
    url('/assets/fonts/Poppins-ExtraLightItalic.woff2') format('woff2');
  font-weight: variables.$font-weight-extralight;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Poppins';
  src:
    local('Poppins Light Italic'),
    local('Poppins-LightItalic'),
    url('/assets/fonts/Poppins-LightItalic.woff2') format('woff2');
  font-weight: variables.$font-weight-light;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Poppins';
  src:
    local('Poppins Italic'),
    local('Poppins-Italic'),
    url('/assets/fonts/Poppins-Italic.woff2') format('woff2');
  font-weight: variables.$font-weight-regular;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Poppins';
  src:
    local('Poppins Medium Italic'),
    local('Poppins-MediumItalic'),
    url('/assets/fonts/Poppins-MediumItalic.woff2') format('woff2');
  font-weight: variables.$font-weight-medium;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Poppins';
  src:
    local('Poppins Bold Italic'),
    local('Poppins-BoldItalic'),
    url('/assets/fonts/Poppins-BoldItalic.woff2') format('woff2');
  font-weight: variables.$font-weight-bold;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Poppins';
  src:
    local('Poppins ExtraBold Italic'),
    local('Poppins-ExtraBoldItalic'),
    url('/assets/fonts/Poppins-ExtraBoldItalic.woff2') format('woff2');
  font-weight: variables.$font-weight-extrabold;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Poppins';
  src:
    local('Poppins Black Italic'),
    local('Poppins-BlackItalic'),
    url('/assets/fonts/Poppins-BlackItalic.woff2') format('woff2');
  font-weight: variables.$font-weight-black;
  font-style: italic;
  font-display: swap;
}
