@use 'abstracts/functions';
@use 'abstracts/variables';

// Options
// @see https://getbootstrap.com/docs/5.2/customize/options/
$enable-important-utilities: false;
$enable-smooth-scroll: false;
$enable-container-classes: false;
$enable-negative-margins: true;

// Font
$font-family-base: 'Poppins', sans-serif;
$font-size-root: 16px;
$font-weight-base: 400;
$line-height-base: 1.5;
$link-decoration: none;

// Colors
$body-color: functions.color('text500');
$link-color: functions.color('primary500');
$code-color: $body-color;

// Grid
$grid-gutter-width: 1rem;
$grid-breakpoints: (
  xs: 0,
  sm: 576px,
  md: 768px,
  lg: 992px,
  xl: 1200px,
  xxl: 1400px
);

// Container
$container-padding-x: 16px;
$full-width-padding-x: 32px;
$full-width-padding-y: 38px;

// Spacing
$spacers: (
  '0': 0,
  '0-5': functions.spacing(0.5),
  '1': functions.spacing(1),
  '1-5': functions.spacing(1.5),
  '2': functions.spacing(2),
  '2-5': functions.spacing(2.5),
  '3': functions.spacing(3),
  '3-5': functions.spacing(3.5),
  '4': functions.spacing(4),
  '5': functions.spacing(5),
  '5-5': functions.spacing(5.5),
  '6': functions.spacing(6),
  '7': functions.spacing(7),
  '7-5': functions.spacing(7.5),
  '8': functions.spacing(8),
  '9': functions.spacing(9),
  '9-5': functions.spacing(9.5),
  '10': functions.spacing(10),
  'auto': 'auto'
);

// Border radius
$border-radius-sm: 0.5rem;
$border-radius: 1rem;
$border-radius-lg: 1.5rem;
$border-radius-xl: 2rem;
$border-radius-2xl: 3rem;

// Components
$hr-opacity: 1;
