@use 'abstracts/shared';

$navbar-dropdown-gap: 2px;

.dd-fullscreen-overlay-lg {
  @include shared.media-breakpoint-down(lg) {
    height: 100%;
  }
}

.dd-fullscreen-dropdown {
  overflow: auto;
  margin-top: shared.$header-height + $navbar-dropdown-gap;

  &::-webkit-scrollbar {
    width: 0;
    background: transparent; /* make scrollbar transparent */
  }

  @include shared.media-breakpoint-down(lg) {
    width: 100vw;
  }

  @include shared.media-breakpoint-down(md) {
    margin-top: shared.$header-height-mobile + $navbar-dropdown-gap;
  }
}

.dd-transparent-backdrop {
  background-color: transparent;
}

.dd-transparent-language-backdrop {
  background-color: transparent;
}

.dd-dark-backdrop {
  background-color: rgb(0 0 0 / 50%);
}

.dd-blur-backdrop {
  background-color: rgb(14 40 82 / 15%);

  @supports (backdrop-filter: none) {
    backdrop-filter: blur(6px);
  }
}

.speech-bubble-portal {
  width: 220px;
}
